import { ToastContainer } from "react-toastify";
import Router from "./Router";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./components/Loading";
import useEnterStore from "./zustand/Enter";

function App() {
    return (
        <>
            <Router />
            <Loading />
            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                draggable
                theme="colored"
            />
        </>
    );
}

export default App;
