const ApiData = {
    //Header
    searchVal: "sigongAdmin/searchVal",

    //zangpanMM
    zangpanMMType: "sigongAdmin/zangpanMMType",

    //시공현황 상담신청
    login: "member/login",
    mbChk: "member/mbChk",
    signCounseling: "sigongAdmin/counselingList",
    sigongAdmincounselingDelete: "sigongAdmin/counselingDelete",
    signCounselingUpdate: "sigongAdmin/counselingUpdate",
    counselingExcel: "sigongAdmin/counselingExcel",
    counselingDelete: "sigongAdmin/counselingDelete",
    counselingDetail: "sigongAdmin/counselingDetail",
    counnselingDetailSave: "sigongAdmin/counselingSave",

    //견적상담등록
    EstimateReg: "sigongAdmin/estimateIn",
    EstimateList: "sigongAdmin/estimateList",
    EstimateDetail: "sigongAdmin/estimateDetail",
    EstimateListDelete: "sigongAdmin/estimateDelete",
    EstimateGoNext: "sigongAdmin/estimateGoSigongInfo",
    EstimateSendMsg: "sigongAdmin/estimateGoMsg",
    EstimateInfoMsgList: "sigongAdmin/estimateInfoMsgList",

    EstimateExcel: "sigongAdmin/estimateExcel",
    EstimateCompanyList: "sigongAdmin/companyList",

    //메모등록
    memoEstimateRegister: "sigongAdmin/estimateMemo",
    memoEstimateList: "sigongAdmin/estimateMemoList",
    memoEstimateDelete: "sigongAdmin/estimateMemoDelete",

    //관리 페이지
    adminIdList: "adminConfig/adminIdList",
    adminRegister: "adminConfig/admRegister",
    adminUpdate: "adminConfig/admUpdate",
    adminDetail: "adminConfig/admDetail",
    adminDelete: "adminConfig/admDelete",
    FaQList: "adminConfig/faqList",
    FaQDetail: "adminConfig/faqDetail",
    FaqSubmit: "adminConfig/faqIn",
    FaqDelete: "adminConfig/faqDelete",
    BannerList: "adminConfig/bannerList",
    BannerDelete: "adminConfig/bannerDelete",
    BannerRegUpdate: "adminConfig/bannerIn",
    PriceUpdate: "adminConfig/priceUpdate",
    PriceDobaeList: "adminConfig/PriceDobaeList",
    PriceZangpanList: "adminConfig/PriceZangpanList",
    PriceWallPaperUpdate: "adminConfig/PriceWallPaperUpdate",
    PriceWallPaper: "adminConfig/PriceWallPaper",

    //문자 페이지
    mailTempList: "adminMsg/msgTemplateList",
    mailTempReg: "adminMsg/msgTemplateIn",
    mailTempDelete: "adminMsg/msgTemplateDelete",

    //시스템 문자관리 리스트
    systemMsgList: "adminMsg/msgSystemList",
    systemMsgReg: "adminMsg/msgSystem",

    //발신
    sendList: "adminMsg/msgBalsinList",
    CallerList: "adminMsg/msgNumberList",
    goingMsg: "adminMsg/goMsg",

    //Worker
    workerManageList: "adminWorker/workerList",
    workerDelete: "adminWorker/workerDelete",
    workerRegister: "adminWorker/workerIn",
    workerDetail: "adminWorker/workerDetail",
    workerfileDelete: "adminWorker/workerFileDelete",

    //자재분류
    materialList: "adminMaterial/materialList",
    materialDelete: "adminMaterial/materialDelete",
    materialUpdate: "adminMaterial/materialIn",
    materialUpdown: "adminMaterial/materialUpDown",

    //자재목록관리
    materialDropDownList: "adminMaterial/materialDropDownList",
    materialDetailList: "adminMaterial/materialDetailList",
    // materialDetailOption: "adminMaterial/materialDetailListOption",
    materialDetail: "adminMaterial/materialDetail",

    materialOption: "adminMaterial/materialOption",
    materialDetailIn: "adminMaterial/materialDetailIn",
    materialDetailDelete: "adminMaterial/materialDetailDelete",
    materialListOption: "adminMaterial/materialListOption",
    // EditorImageUpload: "adminMaterial/EditorImageUpload",
    // EditorImageDelete: "adminMaterial/EditorImageDelete",
    //대리점 관리
    agencyList: "adminMaterial/agencyList",
    agencyDetail: "adminMaterial/agencyDetail",
    agencyMateDetail: "adminMaterial/agencyMateDetail",
    agencyMateDetailIn: "adminMaterial/materialAgencyIn",
    agencyMaterialDetailListOption: "adminMaterial/materialDetailListOption",
    agencyMateList: "adminMaterial/agencyMateList",
    agencyMateIn: "adminMaterial/agencyMateIn",
    agencyMateDelete: "adminMaterial/agencyMateDelete",
    agencyMatePrice: "adminMaterial/agencyMatePrice",
    agencyDelete: "adminMaterial/agencyDelete",
    agencyListThings: "adminMaterial/agencyListThings",

    //자재 재고 관리
    materialMateInChk: "adminMaterial/8amMateInChk",
    materialMateIn: "adminMaterial/8amMateIn",
    materialMateDelete: "adminMaterial/8amMateDelete",
    materialMateList: "adminMaterial/8amMateList",
    agencyList: "adminMaterial/agencyList",
    materialMateReturn: "adminMaterial/8amMateReturn",

    //작업자재고관리
    workerMateList: "adminMaterial/workerMateList",
    workerMateGetList: "adminMaterial/workerMateGetList",
    workerMateIn: "adminMaterial/workerMateIn",
    workerMateDelete: "adminMaterial/workerMateDelete",
    workerMateLogList: "adminMaterial/workerMateLogList",
    workerMateLogMemo: "adminMaterial/workerMateLogMemo",
    workerMateReturn: "adminMaterial/workerMateReturn",

    //반품 관리
    mateReturnList: "adminMaterial/mateReturnList",

    //시공관리
    sigongInfoList: "sigongAdmin2/sigongInfoList",
    sigongWorkerList: "sigongAdmin2/workerList",
    sigongInfoMemoIn: "sigongAdmin2/sigongInfoMemoIn",
    sigongInfoDelete: "sigongAdmin2/sigongInfoDelete",
    sigongInfoDetail: "sigongAdmin2/sigongInfoDetail",
    sigongPopupFilter: "adminMaterial/matePopupFilter",
    sigongInfoMateIn: "sigongAdmin2/sigongInfoMateIn",
    sigongInfoContractMsg: "sigongAdmin2/sigongInfoContractMsg",
    sigongInfoOrderMsg: "sigongAdmin2/sigongInfoOrderMsg",
    sigongInfoIn: "sigongAdmin2/sigongInfoIn",
    sigonginfoMsgList: "sigongAdmin2/sigonginfoMsgList",
    sigongInfoMemoDelete: "sigongAdmin2/sigongInfoMemoDelete",
    sigongOrderIn: "sigongAdmin2/sigongOrderIn",

    //주문관리
    orderDetail: "sigongAdmin3/orderDetail",
    agencyList: "adminMaterial/agencyList",
    orderIn: "sigongAdmin3/orderIn",
    orderList: "sigongAdmin3/orderList",
    orderDelete: "sigongAdmin3/orderDelete",

    //작업종료
    workEndList: "sigongAdmin3/workEndList",
    workEndDetail: "sigongAdmin3/workEndDetail",
    workerList: "sigongAdmin3/workerList",
    workEndMsg: "sigongAdmin3/goMsg",
    workEndChange: "sigongAdmin3/workEndChange",
    workEndIn: "sigongAdmin3/workEndIn",
    workEndMsgList: "sigongAdmin3/workEndMsgList",

    //시공캘린더
    sigongCalendar: "sigongAdmin3/sigongCalendar",

    //방문 캘린더
    visitCalendar: "sigongAdmin3/visitCalendar",
    visitChange: "sigongAdmin3/visitChange",

    //대리점 자재 관리
    agencyMaterialList: "adminMaterial/agencyMaterialList",
    agencyMaterialDetailList: "adminMaterial/agencyMaterialDetailList",

    //AS관리
    asInfoList: "sigongAdmin4/asInfoList",
    asInfoDetail: "sigongAdmin4/asInfoDetail",
    asStatusUpdate: "sigongAdmin4/asStatusUpdate",
    asInfoIn: "sigongAdmin4/asInfoIn",
    asListDelete: "sigongAdmin4/asListDelete",
    asGoMsg: "sigongAdmin4/goMsg",
    asinfoMsgList: "sigongAdmin4/asinfoMsgList",

    //수신 메세지 관리
    apiConnect: "auth/issue-token",
    apiRetryConnect: "auth/reissue-token",
    msgReceiveList: "adminMsg/msgReceiveList",

    //삭제 및 복원
    counselingDeleteList: "sigongAdmin5/counselingDeleteList",
    counselingRestore: "sigongAdmin5/counselingRestore",
    counselingClear: "sigongAdmin5/counselingClear",

    estimateDeleteList: "sigongAdmin5/estimateDeleteList",
    estimateRestore: "sigongAdmin5/estimateRestore",
    estimateClear: "sigongAdmin5/estimateClear",

    sigongDeleteInfoList: "sigongAdmin5/sigongDeleteInfoList",
    sigongRestore: "sigongAdmin5/sigongRestore",
    sigongClear: "sigongAdmin5/sigongClear",

    orderDeleteList: "sigongAdmin5/orderDeleteList",
    OrderRestore: "sigongAdmin5/orderRestore",
    OrderClear: "sigongAdmin5/orderClear",

    asInfoDeleteList: "sigongAdmin5/asInfoDeleteList",
    asInfoRestore: "sigongAdmin5/asInfoRestore",
    asInfoClear: "sigongAdmin5/asInfoClear",
};

export default ApiData;
