import React, { useEffect, useState } from "react";

// import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

// import * as APIS from "../../utils/service";

// import { logIn, logOut } from "../../redux/usersSlice";
// import { open, close } from "../../redux/popupSlice";

import routes from "../../libs/routes";
import images from "../../libs/images";
import consts from "../../libs/consts";
import ApiData from "../../libs/api";

import Input from "../../components/Input";
import { useUser } from "../../zustand/Login";
import { usePostData } from "../../utils/service";
import { toast } from "react-toastify";
// import InputFile from '../../components/InputFile';
// import InputFileMulti from '../../components/InputFileMulti';
// import InputDate from '../../components/InputDate';
// import InputSelect from '../../components/InputSelect';

export default function Login() {
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    // const userData = useSelector((s) => s.usersReducer);

    const [val, setVal] = useState("");
    const [pw, setPw] = useState("");

    const [error, setError] = useState("");
    const { mbData, setMbData } = useUser();
    const updateData = usePostData(ApiData.login);
    useEffect(() => {
        setError("");
    }, [val, pw]);

    const loginFunc = () => {
        let msg = "";

        if (!val) {
            setError("아이디를 입력 해 주세요.");
            return;
        }
        if (!pw) {
            setError("비밀번호를 입력 해 주세요.");
            return;
        }

        // 로그인 API
        var sender = {
            mb_id: val,
            mb_pass: pw,
        };
        // navigate(routes.user);

        updateData
            .mutateAsync(sender)
            .then(({ data }) => {
                let tokens = localStorage.setItem("tokens", data?.token);
                // console.log(data);
                setMbData(data);
            })
            .catch((e) => {
                // console.log("eee", e);
                //setError(e.response.data);
                // toast(e.response.data);
            });
    };

    return (
        <div className="login_container ">
            <div className="login_box">
                <img src={images.logo} alt={consts.imgAlt} />
                <div className="login_input">
                    <p className="sub_title">로그인</p>
                    <Input
                        className="input_text"
                        type="text"
                        style={{ marginBottom: 10 }}
                        placeholder="아이디"
                        name="val"
                        value={val}
                        setValue={setVal}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                loginFunc();
                            }
                        }}
                    />
                    <Input
                        className="input_text"
                        type="password"
                        placeholder="비밀번호"
                        name="val"
                        value={pw}
                        setValue={setPw}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                loginFunc();
                            }
                        }}
                    />
                    <button
                        type="button"
                        className="btn btn1"
                        onClick={() => loginFunc()}
                    >
                        확인
                    </button>
                    <p className="input_error" style={{ textAlign: "center" }}>
                        {error || "ㅤ"}
                    </p>
                    {/* <InputFile type="file" placeholder="입력해주세요." name="ipf1" value={profile} setValue={setprofile} setExt={setlink_ext} onDelete={() => setprofileDelete(true)} valid="doc"/> */}
                </div>
            </div>
        </div>
    );
}
