export function InputRadioBox({
    title,
    Radioes,
    value,
    setValue,
    radioName = "radioGroup",
}) {
    return (
        <div className="ChkBoxWrap search_form">
            {title && <div className="title">{title}</div>}
            <div className="ChkBox">
                {Radioes.map((x, i) => (
                    <div key={i} className="RadioBoxOne">
                        <input
                            className="radio"
                            type="radio"
                            id={radioName + x.label}
                            name={radioName}
                            checked={value === x.value}
                            value={x.value}
                            onChange={(e) => setValue(parseInt(e.target.value))}
                        />
                        <label htmlFor={radioName + x.label}>{x.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}
