import { useEffect, useState } from "react";
import moment from "moment";
import { usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import { PopupComp } from "../../components/PopUp";
import { toast } from "react-toastify";
import useLoadingStore from "../../zustand/Loading";
import { useNavigate } from "react-router-dom";
import routes from "../../libs/routes";

function VisitCalendar() {
    const visitCalendar = usePostData(ApiData.visitCalendar);
    const navigate = useNavigate();
    const visitChange = usePostData(ApiData.visitChange);
    const [visitCalendarData, setVisitCalendarData] = useState([]);
    const [visitCompleteIdx, setVisitCompleteIdx] = useState(0);
    const [openPop, setOpenPop] = useState(false);
    const [currentDate, setCurrentDate] = useState(moment());
    const [weekDates, setWeekDates] = useState(getWeekDates(currentDate));
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");

    const visitCalendarApi = () => {
        let sender = {
            start_date: startOfWeek.format("YYYY-MM-DD"),
        };
        // loadingStart();
        visitCalendar
            .mutateAsync(sender)
            .then(({ data }) => {
                // loadingEnd();
                // console.log("data", data);

                setVisitCalendarData(data);
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
            });
    };
    const formattedStartOfWeek = `${startOfWeek.format(
        "M/D"
    )}(${convertDayToKorean(startOfWeek.day())})`;
    const formattedEndOfWeek = `${endOfWeek.format("M/D")}(${convertDayToKorean(
        endOfWeek.day()
    )})`;
    const handlePrev = () => {
        const newDate = currentDate.clone().subtract(1, "week");
        setCurrentDate(newDate);
        setWeekDates(getWeekDates(newDate));
    };

    const handleNext = () => {
        const newDate = currentDate.clone().add(1, "week");
        setCurrentDate(newDate);
        setWeekDates(getWeekDates(newDate));
    };
    function getWeekDates(date) {
        const weekStart = date.clone().startOf("week");
        const dates = Array(7)
            .fill(0)
            .map((_, i) => {
                const day = weekStart.clone().add(i, "days");
                return `${day.format("M/D")}(${convertDayToKorean(day.day())})`;
            });
        return dates;
    }

    function convertDayToKorean(day) {
        const daysInKorean = ["일", "월", "화", "수", "목", "금", "토"];
        return daysInKorean[day];
    }

    const visitCompleteClk = (idx) => {
        setVisitCompleteIdx(idx);
        setOpenPop(true);
    };
    const goEstimateClk = (idx) => {
        navigate(`${routes.constPreview2}?index=${idx}`);
    };
    const visitComplete = () => {
        // console.log("visitCompleteIdx", visitCompleteIdx);
        visitChange
            .mutateAsync({ estimateIdx: visitCompleteIdx })
            .then(({ data }) => {
                visitCalendarApi();
                toast.success("방문완료처리되었습니다.");
            })
            .catch((e) => {
                toast.error("방문완료처리에 실패하였습니다.");
            });
    };
    useEffect(() => {
        visitCalendarApi();
    }, [currentDate]);
    return (
        <>
            <PopupComp
                popTitle="방문 완료"
                popContent="방문완료 처리하시겠습니까?"
                OpenPop={openPop}
                setOpenPop={setOpenPop}
                SubmitClk={visitComplete}
            />
            <div className="content ">
                <p className="title">방문캘린더</p>

                <div className="WeekDate">
                    <p>{`${formattedStartOfWeek} - ${formattedEndOfWeek}`}</p>
                    <button onClick={handlePrev}>이전</button>
                    <button onClick={handleNext}>다음</button>
                </div>
                <div>
                    <p style={{ fontSize: "18px", marginBottom: "10px" }}>
                        합계 :
                        {visitCalendarData?.reduce((acc, cur) => {
                            return acc + cur.length;
                        }, 0)}
                    </p>
                    <div className="table_scroll">
                        <table>
                            <thead>
                                <tr>
                                    {weekDates.map((item, index) => {
                                        return <th key={index}>{item}</th>;
                                    })}
                                </tr>
                                <tr>
                                    {visitCalendarData?.map((item, index) => {
                                        return (
                                            <th key={index}>{item.length}건</th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody className="visitCalendar">
                                <tr>
                                    {visitCalendarData?.map((item, index) => {
                                        return (
                                            <td key={index}>
                                                {item?.map(
                                                    (subItem, subIndex) => {
                                                        return (
                                                            <div
                                                                className="visitCalendarWrap"
                                                                key={subIndex}
                                                            >
                                                                {subItem.before_date_type ===
                                                                    2 && (
                                                                    <div className="NoClkWrap" />
                                                                )}
                                                                <div className="time">
                                                                    {moment(
                                                                        subItem.before_date
                                                                    ).format(
                                                                        "HH:mm"
                                                                    )}
                                                                </div>
                                                                <div className="job">
                                                                    {subItem.dobae ===
                                                                        1 && (
                                                                        <div
                                                                            style={{
                                                                                background:
                                                                                    "#41B06E",
                                                                                color: "white",
                                                                                width: "100%",
                                                                                fontSize:
                                                                                    "12px",
                                                                            }}
                                                                        >
                                                                            도배
                                                                        </div>
                                                                    )}
                                                                    {subItem.zangpan ===
                                                                        1 && (
                                                                        <div
                                                                            style={{
                                                                                background:
                                                                                    "#F0B053",
                                                                                color: "white",
                                                                                width: "100%",
                                                                                fontSize:
                                                                                    "12px",
                                                                            }}
                                                                        >
                                                                            장판
                                                                        </div>
                                                                    )}
                                                                    {subItem.maru ===
                                                                        1 && (
                                                                        <div
                                                                            style={{
                                                                                background:
                                                                                    "#5BC0DE",
                                                                                color: "white",
                                                                                width: "100%",
                                                                                fontSize:
                                                                                    "12px",
                                                                            }}
                                                                        >
                                                                            마루
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="number">
                                                                    [
                                                                    {subItem.mb_phone.slice(
                                                                        7
                                                                    )}
                                                                    ]
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                        gap: "10px",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <button
                                                                        onClick={() => {
                                                                            visitCompleteClk(
                                                                                subItem.idx
                                                                            );
                                                                        }}
                                                                    >
                                                                        방문완료처리
                                                                    </button>
                                                                    <button
                                                                        onClick={() => {
                                                                            goEstimateClk(
                                                                                subItem.idx
                                                                            );
                                                                        }}
                                                                    >
                                                                        견적상담이동
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
export default VisitCalendar;
