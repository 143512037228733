import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
    BoxAdressInput,
    BoxContent,
    BoxDropdownInput,
    BoxRadioboxInput,
    BoxTextInput,
    BoxDateInput,
    BoxDropdownTwoInput,
    BoxVisitResv,
    BoxTotalPrice,
    BoxDropdownTime,
    BoxDropdownMemoInput,
    BoxJustDropDown,
} from "../../../components/BoxInfo";
import images from "../../../libs/images";
import { HistoryBox, HistoryMessageBox } from "../../../components/HistoryBox";
import { MemoBox } from "../../../components/MemoBox";

import { TableMemo } from "../../../components/Tables";
import consts from "../../../libs/consts";
import routes from "../../../libs/routes";
import ApiData from "../../../libs/api";
import { useGetData, usePostData } from "../../../utils/service";
import { useEffect, useState } from "react";
import InputFile from "../../../components/InputFile";
import { toast } from "react-toastify";
import moment from "moment";
import { useUser } from "../../../zustand/Login";
import { TabComponent } from "../../../components/TabComponent";
import { PopupCalendar, PopupComp } from "../../../components/PopUp";
import useLoadingStore from "../../../zustand/Loading";

function EstimateReqRegister() {
    const navigate = useNavigate();
    const location = useLocation();
    const { mbData } = useUser();
    const [registerPopup, setRegisterPopup] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const historyLook = true;
    const {
        data: ManagerData,
        isLoading,
        isError,
    } = useGetData(ApiData.adminIdList);
    // const { data: companyListData, loading: companyListLoading } = useGetData(
    //     ApiData.EstimateCompanyList
    // );
    const companyListData = usePostData(ApiData.EstimateCompanyList);
    const EstimateRegister = usePostData(ApiData.EstimateReg);
    const EstimateDetail = usePostData(ApiData.EstimateDetail);
    const memoRegister = usePostData(ApiData.memoEstimateRegister);
    const memoList = usePostData(ApiData.memoEstimateList);
    const memoDelete = usePostData(ApiData.memoEstimateDelete);
    const zangpanMMType = usePostData(ApiData.zangpanMMType);
    const [managerSetting, setManagerSetting] = useState([
        { value: 0, text: "담당자 선택" },
    ]);
    const [manager, setManager] = useState(mbData.idx);
    const [customerName, setCustomerName] = useState("");
    const [phone, setPhone] = useState("");
    const [dobaeOn, setDobaeOn] = useState(0);
    const [jangpanOn, setJangpanOn] = useState(0);
    const [maruOn, setMaruOn] = useState(0);
    const [address, setAddress] = useState("");
    const [adressDetail, setAdressDetail] = useState("");
    const [area, setArea] = useState(0);
    const [areaPyeong, setAreaPyeong] = useState(0);
    const [directAreaPyeong, setDirectAreaPyeong] = useState(0);

    const [planUrl, setPlanUrl] = useState("");
    const [planImg, setPlanImg] = useState("");
    const [planImgfileType, setPlanImgFileType] = useState("");
    const [todayStatus, setTodayStatus] = useState(0);
    const [todayMemo, setTodayMemo] = useState("");
    const [veranda, setVeranda] = useState(0);
    const [verandaMemo, setVerandaMemo] = useState("");

    const [dobaeDate, setDobaeDate] = useState("");
    const [dobaeTime, setDobaeTime] = useState("");
    const [dobaeTimeDinput, setDobaeTimeDinput] = useState("");
    const [dobaeRange, setDobaeRange] = useState(0);
    const [dobaeRangeMemo, setDobaeRangeMemo] = useState("");
    const [dobaeCeiling, setDobaeCeiling] = useState(0);
    const [dobaeCreateCompanyName, setDobaeCreateCompanyName] = useState("");
    const [dobaeCompany, setDobaeCompany] = useState([
        { value: "", text: "선택해주세요" },
    ]);
    // const [dobaeDirectCompany, setDobaeDirectCompany] = useState("");
    const [dobaeProduct, setDobaeProduct] = useState(0);
    const [dobaeCnt, setDobaeCnt] = useState(0);
    const [dobaeDirectCnt, setDobaeDirectCnt] = useState(0);
    const [dobaePrice, setDobaePrice] = useState(0);

    const [zangpanDate, setZangpanDate] = useState("");
    const [zangpanTime, setZangpanTime] = useState("");
    const [zangpanTimeDinput, setZangpanTimeDinput] = useState("");
    const [zangpanRange, setZangpanRange] = useState(0);
    const [zangpanRangeMemo, setZangpanRangeMemo] = useState("");
    const [zangpanCreateCompanyName, setZangpanCreateCompanyName] =
        useState("");
    const [zangpanCompany, setZangpanCompany] = useState([
        { value: "", text: "선택해주세요" },
    ]);
    // const [zangpanDirectCompany, setZangpanDirectCompany] = useState("");
    const [zangpanMMList, setZangpanMMList] = useState([]);
    const [zangpanMM, setZangpanMM] = useState("");
    const [zangpanMolding, setZangpanMolding] = useState(0);
    const [zangpanMoldingMemo, setZangpanMoldingMemo] = useState("");
    const [zangpanMoldingRange, setZangpanMoldingRange] = useState(1);
    const [zangpanMoldingRangeMemo, setZangpanMoldingRangeMemo] = useState("");
    const [zangpanThreshold, setZangpanThreshold] = useState(2);
    const [zangpanPrice, setZangpanPrice] = useState(0);

    const [maruDate, setMaruDate] = useState("");
    const [maruTime, setMaruTime] = useState("");
    const [maruTimeDinput, setMaruTimeDinput] = useState("");
    const [maruRange, setMaruRange] = useState(0);
    const [maruRangeMemo, setMaruRangeMemo] = useState("");
    const [maruCreateCompanyName, setMaruCreateCompanyName] = useState("");
    const [maruCompany, setMaruCompany] = useState([
        { value: "", text: "선택해주세요" },
    ]);
    // const [maruDirectCompany, setMaruDirectCompany] = useState("");
    const [maruFlooring, setMaruFlooring] = useState(1);
    const [maruFlooringMemo, setMaruFlooringMemo] = useState("");
    const [maruOldProduct, setMaruOldProduct] = useState("");
    const [maruDownMolding, setMaruDownMolding] = useState(2);
    const [maruThreshold, setMaruThreshold] = useState(2);
    const [maruRepair, setMaruRepair] = useState(2);
    const [maruPrice, setMaruPrice] = useState(0);

    const [demolitionDate, setDemolitionDate] = useState("");
    const [demolitionTime, setDemolitionTime] = useState("");
    const [deolitionTimeDinput, setDemolitionTimeDinput] = useState("");
    const [demolitionRange, setDemolitionRange] = useState("");
    const [demolitionPrice, setDemolitionPrice] = useState(0);

    const [beforeMemo, setBeforeMemo] = useState("");
    const [beforeDate, setBeforeDate] = useState(new Date());
    const [beforeTime, setBeforeTime] = useState(
        moment(new Date()).format("HH:mm")
    );
    const [beforeDateType, setBeforeDateType] = useState(0);

    const [priceAll, setPriceAll] = useState([]);

    // const [summary, setSummary] = useState("");
    const [createDt, setCreateDt] = useState(new Date());

    const [tap, setTap] = useState({});

    const [sigongWant, setSigongWant] = useState("");
    const [consultSummary, setConsultSummary] = useState("");
    const [ContentChange, setContentChange] = useState({});
    const [ContentHistory, setContentHistory] = useState([]);
    const [HistoryList, setHistoryList] = useState([]);
    const [isUpdateData, setIsUpdateData] = useState(false);

    const [memoWrite, setMemoWrite] = useState("");
    const [memoType, setMemoType] = useState(0);
    const [memoListData, setMemoListData] = useState([]);

    const [memoListCommon, setMemoListCommon] = useState([]);
    const [memoListDobae, setMemoListDobae] = useState([]);
    const [memoListZangpan, setMemoListZangpan] = useState([]);
    const [memoListMaru, setMemoListMaru] = useState([]);
    const [memoListSignificant, setMemoListSignificant] = useState([]);

    const [deletePop, setDeletePop] = useState(false);
    const [deleteIdx, setDeleteIdx] = useState(0);

    const [isDelete, setIsDelete] = useState(true);
    const [companyList, setCompanyList] = useState([]);
    const [customerDirectMemo, setCustomerDirectMemo] = useState("");

    // const { loadingStart, loadingEnd } = useLoadingStore();
    const zangpanMMTypeApi = () => {
        zangpanMMType
            .mutateAsync({ company: zangpanCreateCompanyName })
            .then(({ data }) => {
                setZangpanMMList(
                    data?.map((v) => {
                        return { value: v.class1_name, text: v.class1_name };
                    })
                );
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };

    const companyListDataApi = () => {
        companyListData
            .mutateAsync()
            .then(({ data }) => {
                setDobaeCompany(
                    [{ value: "", text: "선택해주세요" }].concat(
                        data?.dobae?.map((v) => {
                            return { value: v, text: v };
                        })
                    )
                );
                setZangpanCompany(
                    [{ value: "", text: "선택해주세요" }].concat(
                        data?.zangpan?.map((v) => {
                            return { value: v, text: v };
                        })
                    )
                );
                setMaruCompany(
                    [{ value: "", text: "선택해주세요" }].concat(
                        data?.maru?.map((v) => {
                            return { value: v, text: v };
                        })
                    )
                );
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    useEffect(() => {
        // console.log("ManagerData", ManagerData);
        // setManagerSetting(
        //     [{ value: 0, text: "담당자 선택" }].concat(
        //         ManagerData?.data?.map((v) => {
        //             return { value: v.idx, text: v.mb_name };
        //         })
        //     )
        // );
        ManagerData?.data?.map((item, index) => {
            setManagerSetting((prev) => [
                ...prev,
                { value: item.idx, text: item.mb_name },
            ]);
        });

        // setManagerSetting();
        return () => {
            setManagerSetting([{ value: 0, text: "담당자 선택" }]); // 상태를 초기화
        };
    }, [ManagerData]);

    const setDetailInfo = (data) => {
        // console.log("DATA!!!!!!!", data);
        setManager(data?.admin_idx || mbData.idx);
        setIsUpdateData(true);
        setCreateDt(data?.create_dt);
        setCustomerName(data?.cutomer_name);
        setPhone(data?.mb_phone);
        setAddress(data?.address);
        setAdressDetail(data?.address_detail);
        setArea(data?.area);
        if (
            consts.pyeong?.some((item) => {
                return item.value === parseInt(data?.area_pyeong);
            })
        ) {
            setAreaPyeong(parseInt(data?.area_pyeong));
        } else {
            setAreaPyeong(0);
            setDirectAreaPyeong(data?.area_pyeong);
        }
        setAreaPyeong(parseInt(data?.area_pyeong || 0));
        setPlanUrl(data?.plan_url);
        setPlanImg(data?.plan_file);
        setTodayStatus(parseInt(data?.today_status || 0));
        setTodayMemo(data?.today_memo);
        setVeranda(parseInt(data?.veranda || 0));
        setVerandaMemo(data?.veranda_memo);
        setDobaeOn(parseInt(data?.dobae));
        setJangpanOn(parseInt(data?.zangpan));
        setMaruOn(parseInt(data?.maru));
        setDobaeDate(data?.dobae_date == null ? new Date() : data?.dobae_date);
        setDobaeTime(data?.dobae_time);
        setDobaeRange(parseInt(data?.dobae_range || 0));
        setDobaeRangeMemo(data?.dobae_range_memo);
        setDobaeCeiling(data?.dobae_ceiling || 0);
        setDobaeCreateCompanyName(data?.dobae_company_name);
        // if (
        //     dobaeCompany?.some((item) => {
        //         return item.value === data?.dobae_company_name;
        //     })
        // ) {
        //     setDobaeCreateCompanyName(data?.dobae_company_name);
        // } else {
        //     // setDobaeCreateCompanyName("");
        //     // setDobaeDirectCompany(data?.dobae_company_name);
        // }
        setDobaeProduct(parseInt(data?.dobae_product || 0));
        setDobaeCnt(parseInt(data?.dobae_cnt || 0));
        setDobaePrice(parseInt(data?.dobae_price || 0));
        setZangpanDate(
            data?.zangpan_date == null ? new Date() : data?.zangpan_date
        );
        setZangpanTime(data?.zangpan_time);
        setZangpanRange(parseInt(data?.zangpan_range || 0));
        setZangpanRangeMemo(data?.zangpan_range_memo);
        setZangpanCreateCompanyName(data?.zangpan_company_name);
        // if (
        //     zangpanCompany?.some((item) => {
        //         return item.value === data?.zangpan_company_name;
        //     })
        // ) {
        //     setZangpanCreateCompanyName(data?.zangpan_company_name);
        // } else {
        //     // setZangpanCreateCompanyName("");
        //     // setZangpanDirectCompany(data?.zangpan_company_name);
        // }
        setZangpanMM(data?.zangpan_mm);
        setZangpanMolding(parseInt(data?.zangpan_molding || 0));
        setZangpanMoldingMemo(data?.zangpan_molding_memo);
        setZangpanMoldingRange(parseInt(data?.zangpan_molding_range || 1));
        setZangpanMoldingRangeMemo(data?.zangpan_molding_range_memo);
        setZangpanThreshold(parseInt(data?.zangpan_threshold || 0));
        setZangpanPrice(parseInt(data?.zangpan_price || 0));
        setMaruDate(data?.maru_date == null ? new Date() : data?.maru_date);
        setMaruTime(data?.maru_time);
        setMaruRange(parseInt(data?.maru_range || 0));
        setMaruRangeMemo(data?.maru_range_memo);
        setMaruFlooring(parseInt(data?.maru_flooring || 1));
        setMaruFlooringMemo(data?.maru_flooring_memo);
        setMaruDownMolding(parseInt(data?.maru_molding || 0));
        setMaruThreshold(parseInt(data?.maru_threshold || 0));
        setMaruRepair(parseInt(data?.maru_cure || 0));
        setMaruPrice(parseInt(data?.maru_price || 0));
        setMaruOldProduct(data?.maru_ori_flooring);
        setMaruCreateCompanyName(data?.maru_company_name);
        // if (
        //     maruCompany?.some((item) => {
        //         return item.value === data?.maru_company_name;
        //     })
        // ) {
        //     setMaruCreateCompanyName(data?.maru_company_name);
        // } else {
        //     // setMaruCreateCompanyName("");
        //     // setMaruDirectCompany(data?.maru_company_name);
        // }
        setDemolitionDate(
            data?.demolition_date == null ? new Date() : data?.demolition_date
        );
        setDemolitionTime(data?.demolition_time);
        setDemolitionRange(parseInt(data?.demolition_range || 0));
        setDemolitionPrice(parseInt(data?.demolition_price || 0));
        setBeforeMemo(data?.before_memo);
        setBeforeDate(
            data?.before_date == null ? new Date() : data?.before_date
        );
        setBeforeTime(
            data?.before_date === null
                ? moment(new Date()).format("HH:mm")
                : moment(data?.before_date).format("HH:mm")
        );
        setBeforeDateType(data?.before_date_type);
        setCustomerDirectMemo(data?.customer_direct_memo);
        // console.log("data", data?.before_date_type);
        setSigongWant(`
        ${
            data?.dobae === 1
                ? `도배 : ${moment(
                      data?.dobae_date ? data?.dobae_date : data?.create_dt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              data?.dobae_date
                                  ? data?.dobae_date
                                  : data?.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }
        ${
            data?.zangpan === 1
                ? `장판 : ${moment(
                      data?.zangpan_date ? data?.zangpan_date : data?.create_dt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              data?.zangpan_date
                                  ? data?.zangpan_date
                                  : data?.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }
        ${
            data?.maru === 1
                ? `마루 : ${moment(
                      data?.maru_date ? data?.maru_date : data?.create_dt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              data?.maru_date
                                  ? data?.maru_date
                                  : data?.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }`);
        let summary = "";

        if (data?.address || data?.address_detail) {
            summary += `${data?.address ? data?.address : ""}${
                data?.address_detail ? data?.address_detail : ""
            }/ `;
        }

        summary += `${data?.area === 1 ? "공급" : "전용"}면적 ${
            data?.area_pyeong || 0
        }평/ `;

        if (data?.veranda == 1) {
            summary += `베란다 확장 있음${
                data?.veranda_memo ? `(${data?.veranda_memo})` : ""
            }/ `;
        } else {
            summary += `베란다 확장 없음${
                data?.veranda_memo ? `(${data?.veranda_memo})` : ""
            }/ `;
        }

        summary += `${
            data?.today_status == 1
                ? "빈집 가구 없음 "
                : data?.today_status == 2
                ? "거주중 가구 있음"
                : "이사 당일"
        }`;

        if (data?.today_memo) {
            summary += `(${data?.today_memo})`;
        }

        setConsultSummary(summary);
    };
    useEffect(() => {
        if (index) {
            setDetailInfo(ContentChange);
        }
    }, [ContentChange]);

    const loadDetailInfo = () => {
        if (index) {
            // loadingStart();
            EstimateDetail.mutateAsync({ estimateIdx: index })
                .then(({ data }) => {
                    // loadingEnd();
                    setContentHistory(data.history);
                    setHistoryList((prev) => [
                        ...prev,
                        {
                            idx: data.idx,
                            create_dt: data.create_dt,
                            admin_name: data.admin_name,
                        },
                    ]);
                    // console.log("data", data);
                    setTap(data.tap);
                    if (data.detail !== null) {
                        // console.log("data.detail", data.detail);
                        setDetailInfo(data?.detail);
                    }
                })
                .catch((e) => {
                    // loadingEnd();
                    toast.error(e.response.data);
                });
        }
    };

    useEffect(() => {
        let newPriceAll = [...priceAll];

        const updateOrAdd = (title, price) => {
            const index = newPriceAll.findIndex((item) => item.title === title);
            if (index !== -1) {
                newPriceAll[index] = { title, price };
            } else {
                newPriceAll.push({ title, price });
            }
        };

        if (dobaePrice != 0) {
            updateOrAdd("도배", dobaePrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "도배");
        }

        if (zangpanPrice != 0) {
            updateOrAdd("장판", zangpanPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "장판");
        }

        if (maruPrice != 0) {
            updateOrAdd("마루", maruPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "마루");
        }

        if (demolitionPrice != 0) {
            updateOrAdd("철거", demolitionPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "철거");
        }

        setPriceAll(newPriceAll);
    }, [dobaePrice, zangpanPrice, maruPrice, demolitionPrice]);
    useEffect(() => {
        if (tap?.estimate_idx) {
            MemoListApi();
        }
    }, [tap]);
    useEffect(() => {
        const common = [];
        const dobae = [];
        const zangpan = [];
        const maru = [];
        const significant = [];

        memoListData?.forEach((item) => {
            switch (item.memo_type) {
                case 1:
                    common.push(item);
                    break;
                case 2:
                    dobae.push(item);
                    break;
                case 3:
                    zangpan.push(item);
                    break;
                case 4:
                    maru.push(item);
                    break;
                case 5:
                    significant.push(item);
                    break;
                default:
                    break;
            }
        });

        setMemoListCommon(common);
        setMemoListDobae(dobae);
        setMemoListZangpan(zangpan);
        setMemoListMaru(maru);
        setMemoListSignificant(significant);
    }, [memoListData]);
    const submitClk = () => {
        const beforeDateObj = new Date(beforeDate);
        const [hours, minutes] = beforeTime.split(":").map(Number);
        beforeDateObj.setHours(hours, minutes);
        const beforeDateString = beforeDateObj.toString();

        let sender = {
            adminIdx: parseInt(manager),
            cutomerName: customerName,
            mb_phone: phone,
            address: address,
            address_detail: adressDetail,
            area: area,
            dobae: parseInt(dobaeOn),
            zangpan: parseInt(jangpanOn),
            maru: parseInt(maruOn),
            area_pyeong:
                areaPyeong !== 0
                    ? parseInt(areaPyeong)
                    : parseInt(directAreaPyeong),
            plan_url: planUrl,
            plan_file: planImg,
            today_status: parseInt(todayStatus),
            today_memo: todayMemo,
            veranda: parseInt(veranda),
            veranda_memo: verandaMemo,
            dobae_date: dobaeDate,
            dobae_time: dobaeTime,
            dobae_range: parseInt(dobaeRange),
            dobae_range_memo: dobaeRangeMemo,
            dobae_ceiling: parseInt(dobaeCeiling),
            dobae_company_name: dobaeCreateCompanyName,
            // dobae_company_name:
            //     dobaeCreateCompanyName === ""
            //         ? dobaeDirectCompany
            //         : dobaeCreateCompanyName,
            dobae_product: parseInt(dobaeProduct),
            dobae_cnt: parseInt(dobaeCnt),
            dobae_price: parseInt(dobaePrice),
            zangpan_date: zangpanDate,
            zangpan_time: zangpanTime,
            zangpan_range: parseInt(zangpanRange),
            zangpan_range_memo: zangpanRangeMemo,
            zangpan_company_name: zangpanCreateCompanyName,
            // zangpan_company_name:
            //     zangpanCreateCompanyName === ""
            //         ? zangpanDirectCompany
            //         : zangpanCreateCompanyName,
            zangpan_mm: zangpanMM,
            zangpan_price: parseInt(zangpanPrice),
            zangpan_molding: parseInt(zangpanMolding),
            zangpan_molding_memo: zangpanMoldingMemo,
            zangpan_molding_range: parseInt(zangpanMoldingRange),
            zangpan_molding_range_memo: zangpanMoldingRangeMemo,
            zangpan_threshold: parseInt(zangpanThreshold),
            maru_date: maruDate,
            maru_time: maruTime,
            maru_range: parseInt(maruRange),
            maru_range_memo: maruRangeMemo,
            maru_company_name: maruCreateCompanyName,
            // maru_company_name:
            //     maruCreateCompanyName === ""
            //         ? maruDirectCompany
            //         : maruCreateCompanyName,

            maru_flooring: parseInt(maruFlooring),
            maru_flooring_memo: maruFlooringMemo,
            maru_ori_flooring: maruOldProduct,
            maru_molding: parseInt(maruDownMolding),
            maru_threshold: parseInt(maruThreshold),
            maru_cure: parseInt(maruRepair),
            maru_price: parseInt(maruPrice),
            demolition_date: demolitionDate,
            demolition_time: demolitionTime,
            demolition_range: demolitionRange,
            demolition_price: parseInt(demolitionPrice),
            before_date: `${beforeDateString}`,
            before_memo: beforeMemo,
            before_date_type: parseInt(beforeDateType),
            customer_direct_memo: customerDirectMemo,
        };

        // console.log("sender", sender);
        if (manager === 0 || manager === undefined || manager === null) {
            toast.error("담당자를 선택해주세요.");
            return;
        }
        // if (
        //     customerName === "" ||
        //     customerName === undefined ||
        //     customerName === null
        // ) {
        //     toast.error("고객명을 입력해주세요.");
        //     return;
        // }
        if (area === 0 || area === undefined) {
            toast.error("기본정보 면적을 선택해주세요.");
            return;
        }
        if (
            (areaPyeong === 0 ||
                areaPyeong === NaN ||
                areaPyeong === undefined) &&
            directAreaPyeong === 0
        ) {
            toast.error("기본정보 면적을 입력해주세요.");
            return;
        } else if (todayStatus === 0 || todayStatus === undefined) {
            toast.error("기본정보 시공당일 주거환경을 선택해주세요.");
            return;
        } else if (veranda === 0 || veranda === undefined) {
            toast.error("기본정보 베란다 확장여부를 선택해주세요.");
            return;
        }
        if (planImg !== "" && planImg !== null && planImg !== undefined) {
            if (!planImg.includes("plan_img")) {
                sender.plan_file_type = 1;
            }
        }
        if (phone === "" || phone === undefined) {
            toast.error("연락처를 입력해주세요.");
            return;
        } else if (phone.includes("-")) {
            sender.mb_phone = phone.replace(/-/g, "");
        } else if (phone.includes(" ")) {
            sender.mb_phone = phone.replace(/ /g, "");
        } else if (!consts.numPattern.test(phone)) {
            toast.error("번호를 제대로 입력해주세요.");
            return;
        }

        //도배가 켜졌을때  아직 제조사 안들어감
        if (
            dobaeOn === 1 &&
            (dobaeTime === "" || dobaeTime === "--:--" || dobaeTime === null) &&
            (dobaeTimeDinput === "" || dobaeTimeDinput === null)
        ) {
            toast.error("도배시공시간을 선택해주세요.");
            return;
        } else if (
            dobaeOn === 1 &&
            (dobaeTime === "" || dobaeTime === "--:--") &&
            dobaeTimeDinput !== ""
        ) {
            sender.dobae_time = dobaeTimeDinput;
        }
        if (dobaeOn === 1 && (dobaeRange === 0 || dobaeRange === null)) {
            toast.error("도배시공범위를 선택해주세요.");
            return;
        } else if (
            dobaeOn === 1 &&
            (dobaeCeiling === 0 || dobaeCeiling === null)
        ) {
            toast.error("도배천장을 선택해주세요.");
            return;
        } else if (
            dobaeOn === 1 &&
            (dobaeCreateCompanyName === "" || dobaeCreateCompanyName === null)
            //     &&
            // (dobaeDirectCompany === "" || dobaeDirectCompany === null)
        ) {
            toast.error("도배 제조사를 선택해주세요.");
            return;
        } else if (
            dobaeOn === 1 &&
            (dobaeProduct === 0 || dobaeProduct === null)
        ) {
            toast.error("도배벽지종류를 선택해주세요.");
            return;
        } else if (
            dobaeOn === 1 &&
            (dobaeCnt === 0 || dobaeCnt === null || dobaeCnt === NaN) &&
            dobaeDirectCnt === 0
        ) {
            toast.error("도배벽지수를 선택해주세요.");
            return;
        } else if (dobaeOn === 1 && (dobaePrice === 0 || dobaePrice === null)) {
            toast.error("도배견적금액을 입력해주세요.");
            return;
        }
        if (
            dobaeOn === 1 &&
            (dobaeCnt === 0 || dobaeCnt === null) &&
            dobaeDirectCnt !== 0
        ) {
            sender.dobae_cnt = dobaeDirectCnt;
        }

        //장판이 켜졌을때 아직 제조사 안들어감
        if (
            jangpanOn === 1 &&
            (zangpanTime === "" ||
                zangpanTime == "--:--" ||
                zangpanTime === null) &&
            (zangpanTimeDinput === "" || zangpanTimeDinput === null)
        ) {
            toast.error("장판시공시간을 입력해주세요.");
            return;
        } else if (
            jangpanOn === 1 &&
            (zangpanRange === 0 || zangpanRange === null)
        ) {
            toast.error("장판시공범위를 선택해주세요.");
            return;
        } else if (
            jangpanOn === 1 &&
            (zangpanCreateCompanyName === "" ||
                zangpanCreateCompanyName === null)
            //     &&
            // (zangpanDirectCompany === "" || zangpanDirectCompany === null)
        ) {
            toast.error("장판 제조사를 선택해주세요.");
            return;
        } else if (jangpanOn === 1 && zangpanMM === "") {
            toast.error("장판두께 입력해주세요.");
            return;
        } else if (
            jangpanOn === 1 &&
            zangpanMolding === 1 &&
            zangpanMoldingMemo === ""
        ) {
            toast.error("하단몰딩 종류를 입력해주세요.");
            return;
        }
        // else if (
        //     jangpanOn === 1 &&
        //     zangpanMoldingRange === 1 &&
        //     (zangpanMoldingRangeMemo === "" || zangpanMoldingRangeMemo === null)
        // ) {
        //     toast.error("장판하단몰딩 범위를 입력해주세요.");
        //     return;
        // }
        else if (jangpanOn === 1 && zangpanThreshold === 2) {
            toast.error("장판문턱을 선택해주세요.");
            return;
        } else if (jangpanOn === 1 && zangpanPrice === 0) {
            toast.error("장판견적금액을 입력해주세요.");
            return;
        }
        if (
            jangpanOn === 1 &&
            (zangpanTime === "" || zangpanTime == "--:--") &&
            zangpanTimeDinput !== ""
        ) {
            sender.zangpan_time = zangpanTimeDinput;
        }
        //마루가 열렸을때 제조사 아직 없음
        if (
            maruOn === 1 &&
            (maruTime === "" || maruTime == "--:--" || maruTime === null) &&
            (maruTimeDinput === "" || maruTimeDinput === null)
        ) {
            toast.error("마루시간을 입력해주세요.");
            return;
        } else if (maruOn === 1 && maruRange === 0) {
            toast.error("마루시공범위를 선택해주세요.");
            return;
        } else if (
            maruOn === 1 &&
            (maruCreateCompanyName === "" || maruCreateCompanyName === null)
            // &&
            // (maruDirectCompany === "" || maruDirectCompany === null)
        ) {
            toast.error("마루 제조사를 선택해주세요.");
            return;
        } else if (
            maruOn === 1 &&
            maruFlooring === 1 &&
            (maruFlooringMemo === "" || maruFlooringMemo === null)
        ) {
            toast.error("마루바닥재를 선택해주세요.");
            return;
        } else if (
            maruOn === 1 &&
            (maruOldProduct === "" || maruOldProduct === null)
        ) {
            toast.error("마루기존 바닥재를 입력해주세요.");
            return;
        } else if (maruOn === 1 && maruDownMolding === 2) {
            toast.error("마루하단몰딩을 선택해주세요.");
            return;
        } else if (maruOn === 1 && maruThreshold == 2) {
            toast.error("마루문턱을 선택해주세요.");
            return;
        } else if (maruOn === 1 && maruRepair == 2) {
            toast.error("마루보양작업을 선택해주세요.");
            return;
        } else if (maruOn === 1 && maruPrice === 0) {
            toast.error("마루견적금액을 입력해주세요.");
            return;
        }
        if (
            maruOn === 1 &&
            (maruTime === "" || maruTime == "--:--") &&
            maruTimeDinput !== ""
        ) {
            sender.maru_time = maruTimeDinput;
        }
        // //철거
        if (
            (demolitionTime === "" || demolitionTime == "--:--") &&
            deolitionTimeDinput !== ""
        ) {
            sender.demolition_time = deolitionTimeDinput;
        }
        if (index !== null) {
            sender.estimateIdx = parseInt(index);
        }

        EstimateRegister.mutateAsync(sender)
            .then(({ data }) => {
                if (index) {
                    navigate(`${routes.constPreview2}?index=${index}`);
                } else {
                    navigate(`${routes.constPreview2}?index=${data}`);
                }

                toast.success("등록에 성공했습니다.");
            })
            .catch((e) => {
                // console.log(e);
                toast.error("등록에 실패했습니다.");
            });
        // console.log("test..=?>?>>>>>>>>나가는값", sender);
    };

    const saveMemo = () => {
        let sender = {
            estimateIdx: tap?.estimate_idx,
            memo_type: memoType,
            memo_content: memoWrite,
        };
        // console.log("sender", sender);
        if (memoType === 0) {
            toast.error("메모 타입을 선택해주세요");
            return;
        } else if (memoWrite === "") {
            toast.error("메모를 입력해주세요");
            return;
        }

        memoRegister
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("저장되었습니다.");
                MemoListApi();
                setMemoWrite("");
                setMemoType(0);
            })
            .catch((e) => {
                // console.log(e);
                toast.error("저장에 실패했습니다.");
            });
    };

    const MemoListApi = () => {
        memoList
            .mutateAsync({ estimateIdx: tap?.estimate_idx })
            .then(({ data }) => {
                setMemoListData(data);
            })
            .catch((e) => {
                toast.error("정보를 가져오지 못했습니다.");
            });
    };

    const DeleteMemo = () => {
        memoDelete
            .mutateAsync({ memoIdx: deleteIdx })
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                MemoListApi();
            })
            .catch((e) => {
                toast.error("삭제에 실패했습니다.");
            });
        // console.log("deleteIdx", deleteIdx);
    };
    const GoBack = () => {
        navigate(routes.constr2);
    };
    const ListGoback = () => {
        // console.log("test", isDelete);
        setIsDelete(false);
        setDeletePop(true);
    };

    // useEffect(() => {
    //     if (index) {
    //         companyListDataApi(); // 제조사 리스트
    //         loadDetailInfo();
    //     }
    // }, [index]);

    useEffect(() => {
        const fetchData = async () => {
            await loadDetailInfo();
            companyListDataApi();
        };

        fetchData();
    }, []);

    useEffect(() => {
        zangpanMMTypeApi();
    }, [zangpanCreateCompanyName]);

    return (
        <>
            <PopupCalendar
                OpenPop={registerPopup}
                setOpenPop={setRegisterPopup}
            />
            <PopupComp
                popTitle={`${isDelete ? "삭제" : "안내"}`}
                popContent={`${
                    isDelete
                        ? "정말로 삭제하시겠습니까?"
                        : "저장 없이 페이지 이동하시겠습니까?"
                }`}
                isYes={true}
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={isDelete ? DeleteMemo : GoBack}
            />
            <div className="content ">
                {index ? (
                    <>
                        <TabComponent
                            index={tap?.counseling_idx}
                            estimate_idx={tap?.estimate_idx}
                            sigonginfo_idx={tap?.sigonginfo_idx}
                            order_idx={tap?.order_idx}
                            workend_idx={tap?.workend_idx}
                            as_tbl={tap?.as_tbl}
                        />
                        <HistoryBox
                            Info={ContentHistory}
                            firstDate={createDt}
                            historyLook={historyLook}
                            setContentChange={setContentChange}
                        ></HistoryBox>
                    </>
                ) : null}
                {index && (
                    <div className="BorderBox">
                        <div className="title">
                            <h1>상담요약</h1>
                        </div>

                        <div className="contentWrap">
                            <BoxContent
                                title="시공희망일"
                                content={sigongWant}
                            />
                            <BoxContent title="내용" content={consultSummary} />
                        </div>
                    </div>
                )}

                <div className="BorderBox">
                    <div className="title">
                        <h1>기본정보</h1>
                    </div>
                    <div className="contentWrap">
                        <BoxJustDropDown
                            title="담당자"
                            value={manager}
                            setValue={setManager}
                            Dropdowns={managerSetting}
                        />
                        <BoxTextInput
                            value={customerName}
                            setValue={setCustomerName}
                            title="고객명"
                        />
                        <BoxTextInput
                            value={phone}
                            setValue={setPhone}
                            title="연락처"
                        />
                        <div className="BoxInfo">
                            <div className="title">시공</div>
                            <div className="sub">
                                <div className="ChkBoxOne">
                                    <input
                                        type="checkbox"
                                        id={"dobaeOn"}
                                        name={"dobaeOn"}
                                        value={isNaN(dobaeOn) ? 0 : dobaeOn}
                                        onChange={(e) => {
                                            setDobaeOn(
                                                e.target.checked ? 1 : 0
                                            );
                                        }}
                                        checked={
                                            isNaN(dobaeOn) ? false : dobaeOn
                                        }
                                    />
                                    <label htmlFor={"dobaeOn"}>도배</label>
                                </div>
                                <div className="ChkBoxOne">
                                    <input
                                        type="checkbox"
                                        id={"jangpanOn"}
                                        name={"jangpanOn"}
                                        value={isNaN(jangpanOn) ? 0 : jangpanOn}
                                        onChange={(e) => {
                                            setJangpanOn(
                                                e.target.checked ? 1 : 0
                                            );
                                        }}
                                        checked={
                                            isNaN(jangpanOn) ? false : jangpanOn
                                        }
                                    />
                                    <label htmlFor={"jangpanOn"}>장판</label>
                                </div>
                                <div className="ChkBoxOne">
                                    <input
                                        type="checkbox"
                                        id={"maruOn"}
                                        name={"maruOn"}
                                        value={isNaN(maruOn) ? 0 : maruOn}
                                        onChange={(e) => {
                                            setMaruOn(e.target.checked ? 1 : 0);
                                        }}
                                        checked={isNaN(maruOn) ? false : maruOn}
                                    />
                                    <label htmlFor={"maruOn"}>마루</label>
                                </div>
                            </div>
                        </div>

                        <BoxAdressInput
                            value={address}
                            setValue={setAddress}
                            detailValue={adressDetail}
                            setDetailValue={setAdressDetail}
                        />
                        <BoxRadioboxInput
                            title="면적"
                            radioValue={area}
                            setRadioValue={setArea}
                            selectedValue={areaPyeong}
                            setSelectedValue={setAreaPyeong}
                            directInput={directAreaPyeong}
                            setDirectInput={setDirectAreaPyeong}
                            directType="number"
                            Radioes={consts.areaBox}
                            Dropdowns={consts.pyeong}
                            TextInputUnit="평"
                            radioNames="area"
                        />
                        <BoxTextInput
                            title="도면 URL"
                            value={planUrl}
                            setValue={setPlanUrl}
                            placeholder="네이버 부동산 내 도면 URL등"
                            inputWidth={70}
                        />
                        <div
                            style={{
                                border: "none",
                                borderBottom: "1px solid #ddd",
                            }}
                            className="ImgInputWrap"
                        >
                            <div className="Imgtitle">도면파일</div>
                            <div className="sub">
                                <InputFile
                                    type="file"
                                    name={"plan_img"}
                                    value={
                                        planImg &&
                                        (planImg.includes("plan_img")
                                            ? `${consts.ImgState}${planImg}`
                                            : `${planImg}`)
                                    }
                                    alt={"plan_img"}
                                    onChange={(idx, name, result) => {
                                        setPlanImg(result);
                                    }}
                                    valid="image"
                                    // style={{ flex: 1.68 }}
                                    imgStyle={{
                                        width: "40%",
                                    }}
                                    imgInnerStyle={{
                                        height: "500px",
                                        maxWidth: "500px",
                                        objectFit: "contain",
                                    }}
                                    setFileType={(type) => {
                                        setPlanImgFileType(type);
                                    }}
                                />
                                <button
                                    type="button"
                                    style={{
                                        fontSize: "12px",
                                        position: "absolute",
                                        bottom: "10px",
                                        right: "10px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setPlanImg("")}
                                >
                                    삭제하기
                                </button>
                            </div>
                        </div>
                        <BoxRadioboxInput
                            title="시공당일 주거환경"
                            Radioes={consts.LivingEnvironmentBox}
                            IsTextInput={true}
                            radioValue={todayStatus}
                            setRadioValue={setTodayStatus}
                            directInput={todayMemo}
                            setDirectInput={setTodayMemo}
                            placeholder="메모입력"
                            TextInputWidth={20}
                            radioNames="todayStatus"
                        />
                        <BoxRadioboxInput
                            title="베란다 확장"
                            Radioes={consts.BarandaExpansion}
                            radioValue={veranda}
                            setRadioValue={setVeranda}
                            directInput={verandaMemo}
                            setDirectInput={setVerandaMemo}
                            IsTextInput={true}
                            placeholder="메모입력"
                            TextInputWidth={20}
                            radioNames="veranda"
                        />
                    </div>
                </div>
                {dobaeOn === 1 && (
                    <div className="title_color_box">
                        <div
                            className="box_title"
                            style={{
                                backgroundColor: "#41b06e",
                                color: "white",
                            }}
                        >
                            <h1>도배</h1>
                        </div>
                        <div className="contentColorWrap">
                            <div className="contentBorderWrap">
                                <BoxDateInput
                                    startvalue={dobaeDate}
                                    setStartValue={setDobaeDate}
                                    title="시공일"
                                />
                                <BoxDropdownTime
                                    title="시공시간"
                                    value={dobaeTime}
                                    setValue={setDobaeTime}
                                    directInput={dobaeTimeDinput}
                                    setDirectInput={setDobaeTimeDinput}
                                />
                                <BoxRadioboxInput
                                    title="시공범위"
                                    IsTextInput={true}
                                    TextInputWidth={20}
                                    placeholder="내용입력"
                                    directInput={dobaeRangeMemo}
                                    setDirectInput={setDobaeRangeMemo}
                                    radioValue={dobaeRange}
                                    setRadioValue={setDobaeRange}
                                    Radioes={consts.ConstructionScope}
                                    radioNames="dobaeRange"
                                />
                                <BoxRadioboxInput
                                    title="천장"
                                    Radioes={consts.Ceiling}
                                    radioValue={dobaeCeiling}
                                    setRadioValue={setDobaeCeiling}
                                    radioNames="dobaeCeiling"
                                />

                                <BoxDropdownInput
                                    title="제조사"
                                    value={dobaeCreateCompanyName}
                                    setValue={setDobaeCreateCompanyName}
                                    Dropdowns={dobaeCompany}
                                    defaultValue={"없음"}
                                    // directInput={dobaeDirectCompany}
                                    // setDirectInput={setDobaeDirectCompany}
                                />
                                <BoxRadioboxInput
                                    title="벽지 종류"
                                    Radioes={consts.wallpaperType}
                                    radioValue={dobaeProduct}
                                    setRadioValue={setDobaeProduct}
                                    extraInputType="number"
                                />
                                <BoxDropdownInput
                                    title="벽지 수"
                                    value={dobaeCnt}
                                    setValue={setDobaeCnt}
                                    Dropdowns={consts.TestEx3}
                                    directInput={dobaeDirectCnt}
                                    setDirectInput={setDobaeDirectCnt}
                                />
                                <BoxTextInput
                                    title="견적금액"
                                    value={dobaePrice}
                                    setValue={setDobaePrice}
                                    unit="원"
                                />
                            </div>
                        </div>
                    </div>
                )}
                {jangpanOn === 1 && (
                    <div className="title_color_box">
                        <div
                            className="box_title"
                            style={{
                                backgroundColor: "#fb8500",
                                color: "white",
                            }}
                        >
                            <h1>장판</h1>
                        </div>
                        <div className="contentColorWrap">
                            <div className="contentBorderWrap">
                                <BoxDateInput
                                    startvalue={zangpanDate}
                                    setStartValue={setZangpanDate}
                                    title="시공일"
                                />
                                <BoxDropdownTime
                                    title="시공시간"
                                    value={zangpanTime}
                                    setValue={setZangpanTime}
                                    directInput={zangpanTimeDinput}
                                    setDirectInput={setZangpanTimeDinput}
                                />
                                <BoxRadioboxInput
                                    title="시공범위"
                                    IsTextInput={true}
                                    TextInputWidth={20}
                                    placeholder="내용입력"
                                    directInput={zangpanRangeMemo}
                                    setDirectInput={setZangpanRangeMemo}
                                    radioValue={zangpanRange}
                                    setRadioValue={setZangpanRange}
                                    Radioes={consts.ConstructionScope}
                                    radioNames="zangpanRange"
                                />
                                <BoxDropdownInput
                                    title="제조사"
                                    Dropdowns={zangpanCompany}
                                    value={zangpanCreateCompanyName}
                                    setValue={setZangpanCreateCompanyName}
                                    defaultValue={"없음"}
                                    // directInput={zangpanDirectCompany}
                                    // setDirectInput={setZangpanDirectCompany}
                                />
                                <BoxDropdownInput
                                    title="장판"
                                    Dropdowns={zangpanMMList}
                                    value={zangpanMM}
                                    setValue={setZangpanMM}
                                    defaultValue={"없음"}
                                    // directInput={zangpanDirectCompany}
                                    // setDirectInput={setZangpanDirectCompany}
                                />

                                <BoxDropdownTwoInput
                                    title="하단몰딩"
                                    value1={zangpanMolding}
                                    setValue1={setZangpanMolding}
                                    value1memo={zangpanMoldingMemo}
                                    setValue1Memo={setZangpanMoldingMemo}
                                    value2={zangpanMoldingRange}
                                    setValue2={setZangpanMoldingRange}
                                    value2memo={zangpanMoldingRangeMemo}
                                    setValue2Memo={setZangpanMoldingRangeMemo}
                                    Dropdowns={consts.molding1}
                                    Dropdowns2={consts.molding2}
                                />
                                <BoxRadioboxInput
                                    title="문턱"
                                    Radioes={consts.threshold}
                                    radioValue={zangpanThreshold}
                                    setRadioValue={setZangpanThreshold}
                                    radioNames="zangpanThreshold"
                                />

                                <BoxTextInput
                                    value={zangpanPrice}
                                    setValue={setZangpanPrice}
                                    title="견적금액"
                                    inputWidth={20}
                                    unit="원"
                                />
                            </div>
                        </div>
                    </div>
                )}
                {maruOn === 1 && (
                    <div className="title_color_box">
                        <div
                            className="box_title"
                            style={{ backgroundColor: "#caf0f8" }}
                        >
                            <h1>마루</h1>
                        </div>
                        <div className="contentColorWrap">
                            <div className="contentBorderWrap">
                                <BoxDateInput
                                    title="시공일"
                                    startvalue={maruDate}
                                    setStartValue={setMaruDate}
                                />
                                <BoxDropdownTime
                                    title="시공시간"
                                    value={maruTime}
                                    setValue={setMaruTime}
                                    directInput={maruTimeDinput}
                                    setDirectInput={setMaruTimeDinput}
                                />
                                <BoxRadioboxInput
                                    title="시공범위"
                                    IsTextInput={true}
                                    TextInputWidth={20}
                                    placeholder="내용입력"
                                    directInput={maruRangeMemo}
                                    setDirectInput={setMaruRangeMemo}
                                    radioValue={maruRange}
                                    setRadioValue={setMaruRange}
                                    Radioes={consts.ConstructionScope}
                                    radioNames="maruRange"
                                />
                                <BoxDropdownInput
                                    title="제조사"
                                    Dropdowns={maruCompany}
                                    value={maruCreateCompanyName}
                                    setValue={setMaruCreateCompanyName}
                                    defaultValue={"없음"}
                                    // directInput={maruDirectCompany}
                                    // setDirectInput={setMaruDirectCompany}
                                />
                                <BoxDropdownMemoInput
                                    title="바닥재"
                                    defaultValue={1}
                                    value={maruFlooring}
                                    setValue={setMaruFlooring}
                                    InputValue={maruFlooringMemo}
                                    setInputValue={setMaruFlooringMemo}
                                    Dropdowns={consts.TestEx}
                                />
                                <BoxTextInput
                                    title="기존 바닥재"
                                    value={maruOldProduct}
                                    setValue={setMaruOldProduct}
                                />

                                <BoxRadioboxInput
                                    title="하단몰딩"
                                    radioValue={maruDownMolding}
                                    setRadioValue={setMaruDownMolding}
                                    Radioes={consts.threshold}
                                    radioNames="maruDownMolding"
                                />
                                <BoxRadioboxInput
                                    title="문턱"
                                    radioValue={maruThreshold}
                                    setRadioValue={setMaruThreshold}
                                    Radioes={consts.threshold}
                                    radioNames="maruThreshold"
                                />
                                <BoxRadioboxInput
                                    title="보양작업"
                                    radioValue={maruRepair}
                                    setRadioValue={setMaruRepair}
                                    Radioes={consts.threshold}
                                    radioNames="maruRepair"
                                />

                                <BoxTextInput
                                    title="견적금액"
                                    inputWidth={20}
                                    value={maruPrice}
                                    setValue={setMaruPrice}
                                    unit="원"
                                />
                            </div>
                        </div>
                        <div
                            className="title_color_box"
                            style={{ border: "0px" }}
                        >
                            <div
                                className="box_title"
                                style={{ backgroundColor: "#ced4da" }}
                            >
                                <h1>철거</h1>
                            </div>
                            <div className="contentColorWrap">
                                <div className="contentBorderWrap">
                                    <BoxDateInput
                                        title="시공일"
                                        startvalue={demolitionDate}
                                        setStartValue={setDemolitionDate}
                                    />
                                    <BoxDropdownTime
                                        title="시공시간"
                                        value={demolitionTime}
                                        setValue={setDemolitionTime}
                                        directInput={deolitionTimeDinput}
                                        setDirectInput={setDemolitionTimeDinput}
                                    />

                                    <BoxTextInput
                                        value={demolitionRange}
                                        setValue={setDemolitionRange}
                                        title="범위"
                                        type="text"
                                        inputWidth={30}
                                    />
                                    <BoxTextInput
                                        title="견적금액"
                                        inputWidth={20}
                                        value={demolitionPrice}
                                        setValue={setDemolitionPrice}
                                        unit="원"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="BorderBox">
                    <div className="title">
                        <h1>부가정보</h1>
                    </div>
                    <div className="contentWrap">
                        <BoxTextInput
                            value={beforeMemo}
                            setValue={setBeforeMemo}
                            title="사전시공"
                            inputWidth={30}
                        />
                        <BoxVisitResv
                            startDate={beforeDate}
                            setStartDate={setBeforeDate}
                            time={beforeTime}
                            setTime={setBeforeTime}
                            visitValue={beforeDateType}
                            setVisitValue={setBeforeDateType}
                            registerPopup={registerPopup}
                            setRegisterPopup={setRegisterPopup}
                        />
                        <BoxTotalPrice Info={priceAll} />
                    </div>
                </div>
                <div className="MemoBoxWrap" style={{ marginTop: "40px" }}>
                    <div className="title">
                        <img src={images.pencil} />
                        <p>고객메모/요청사항</p>
                    </div>

                    <div className="MemoWrap">
                        <textarea
                            className="MemoBox"
                            value={customerDirectMemo}
                            onChange={(e) =>
                                setCustomerDirectMemo(e.target.value)
                            }
                        ></textarea>
                    </div>
                </div>
                <div className="SaveBtn">
                    <button className="btn btn9" onClick={() => submitClk()}>
                        저장
                    </button>
                </div>
                {tap?.estimate_idx && (
                    <>
                        <MemoBox
                            memoWrite={memoWrite}
                            setMemoWrite={setMemoWrite}
                            memoType={memoType}
                            setMemoType={setMemoType}
                            submitClk={saveMemo}
                        />
                        {memoListCommon.length > 0 && (
                            <TableMemo
                                memotype={"일반"}
                                Info={memoListCommon}
                                deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                setDeleteIdx={setDeleteIdx}
                                isDelete={isDelete}
                                setIsDelete={setIsDelete}
                            />
                        )}
                        {memoListDobae.length > 0 && (
                            <TableMemo
                                memotype={"도배"}
                                Info={memoListDobae}
                                deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                setDeleteIdx={setDeleteIdx}
                                isDelete={isDelete}
                                setIsDelete={setIsDelete}
                            />
                        )}

                        {memoListZangpan.length > 0 && (
                            <TableMemo
                                memotype={"장판"}
                                Info={memoListZangpan}
                                deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                setDeleteIdx={setDeleteIdx}
                                isDelete={isDelete}
                                setIsDelete={setIsDelete}
                            />
                        )}
                        {memoListMaru.length > 0 && (
                            <TableMemo
                                memotype={"마루"}
                                Info={memoListMaru}
                                deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                setDeleteIdx={setDeleteIdx}
                                isDelete={isDelete}
                                setIsDelete={setIsDelete}
                            />
                        )}
                        {memoListSignificant.length > 0 && (
                            <TableMemo
                                memotype={"특이사항"}
                                Info={memoListSignificant}
                                deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                isDelete={isDelete}
                                setIsDelete={setIsDelete}
                            />
                        )}
                    </>
                )}
                {index ? (
                    <div className="twoBtn_wrap">
                        <button
                            className="btn btn8"
                            onClick={() => ListGoback()}
                        >
                            목록
                        </button>
                        <button
                            className="btn btn9"
                            onClick={() => submitClk()}
                        >
                            저장
                        </button>
                    </div>
                ) : null}
            </div>
        </>
    );
}

export default EstimateReqRegister;
