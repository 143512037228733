import { useEffect, useState } from "react";
import {
    BoxImgUpLoad,
    BoxMemoInput,
    BoxRadioboxInput,
    BoxTextInput,
} from "../../components/BoxInfo";
import consts from "../../libs/consts";
import images from "../../libs/images";
import { PopupComp } from "../../components/PopUp";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import ApiData from "../../libs/api";
import { usePostData } from "../../utils/service";
import useLoadingStore from "../../zustand/Loading";
import routes from "../../libs/routes";

function WorkerManageDetail() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const workerIdx = searchParams.get("index");
    const location = useLocation();
    const [workerType, setWorkerType] = useState(0);
    const [dobaeChk, setDobaeChk] = useState(0);
    const [jangpanChk, setJangpanChk] = useState(0);
    const [maruChk, setMaruChk] = useState(0);
    const [workerName, setWorkerName] = useState("");
    const [belong, setBelong] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [bankName, setBankName] = useState("");
    const [bankNum, setBankNum] = useState("");
    const [bankOwner, setBankOwner] = useState("");
    const [textMemo, setTextMemo] = useState("");
    const [list, setlist] = useState([{ idx: 0, file: "" }]);
    const [deletePop, setDeletePop] = useState(false);
    const [deleteidxs, setdeleteidxs] = useState([]);
    const [deletClk, setDeleteClk] = useState([]);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const workerRegisterData = usePostData(ApiData.workerRegister);
    const workerDetailData = usePostData(ApiData.workerDetail);
    const fileDelete = usePostData(ApiData.workerfileDelete);

    function isValidJSON(json) {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }
    const WorkerDetailDataApi = () => {
        workerDetailData
            .mutateAsync({ workerIdx: workerIdx })
            .then(({ data }) => {
                // console.log("data", data);
                // loadingEnd();
                setWorkerType(data?.type);
                setDobaeChk(data?.dobae);
                setJangpanChk(data?.zangpan);
                setMaruChk(data?.maru);
                setWorkerName(data?.name);
                setBelong(data?.belong);
                setPhoneNum(data?.phone);
                setBankName(data?.bank_name);
                setBankNum(data?.bank_num);
                setBankOwner(data.bank_mbname);
                setTextMemo(data?.memo);
                // console.log(
                //     "isValidJSON(data?.img_list_json)",
                //     JSON.parse(data?.img_list_json)
                // );
                setlist(
                    isValidJSON(data?.img_list_json)
                        ? [...JSON.parse(data?.img_list_json)]
                        : [{ idx: 0, file: "" }]
                );
            })
            .catch((e) => {
                // loadingEnd();
                // toast.error(e?.response?.data);
                // console.log("!?!?!?!?!?!!?!?!?", e?.response?.data);
            });
    };
    useEffect(() => {
        if (workerIdx) {
            // loadingStart();
            WorkerDetailDataApi();
        }
    }, [workerIdx]);

    const zoneAppend = (type) => {
        //리스트의 제일 마지막 idx값을 가져온다.
        // const idx = list?.length > 0 ? list[list.length - 1].idx + 1 : 1;

        setlist([...list, { idx: list?.length, file: "" }]);
    };
    const SubmitClk = () => {
        if (dobaeChk == 0 && jangpanChk == 0 && maruChk == 0) {
            toast.error("작업을 선택해주세요");
            return;
        } else if (workerType == 0) {
            toast.error("분류를 선택해주세요");
            return;
        } else if (workerName == "") {
            toast.error("이름을 입력해주세요");
            return;
        } else if (belong == "") {
            toast.error("소속을 입력해주세요");
            return;
        } else if (phoneNum == "") {
            toast.error("연락처를 입력해주세요");
            return;
        } else if (phoneNum.includes("-")) {
            sender.phone = phoneNum.replace(/-/g, "");
        } else if (phoneNum.includes(" ")) {
            sender.phone = phoneNum.replace(/ /g, "");
        } else if (!consts.numPattern.test(phoneNum)) {
            toast.error("번호를 제대로 입력해주세요.");
            return;
        } else if (bankName == "") {
            toast.error("은행명을 입력해주세요");
            return;
        } else if (bankNum == "") {
            toast.error("계좌번호를 입력해주세요");
            return;
        } else if (bankOwner == "") {
            toast.error("예금주를 입력해주세요");
            return;
        }
        let sender = {
            workerIdx: workerIdx,
            name: workerName,

            type: workerType,
            belong: belong,
            phone: phoneNum,
            bank_name: bankName,
            bank_num: bankNum,
            bank_mbname: bankOwner,
            file_arr: list
                ?.filter((item) => item.file)
                .map((item, idx) => {
                    if (!item.file.includes("data:image")) {
                        return { ...item, file_type_in: "" };
                    } else {
                        return { ...item, file_type_in: parseInt(1) };
                    }
                }),
            memo: textMemo,
        };
        if (dobaeChk) {
            sender.dobae = dobaeChk;
        }
        if (jangpanChk) {
            sender.zangpan = jangpanChk;
        }
        if (maruChk) {
            sender.maru = maruChk;
        }

        workerRegisterData
            .mutateAsync(sender)
            .then(({ data }) => {
                if (workerIdx) {
                    navigate(`${routes.workerDetail}?index=${workerIdx}`, {
                        state: { data: "view" },
                    });
                } else {
                    navigate(`${routes.workerDetail}?index=${data}`, {
                        state: { data: "view" },
                    });
                }
                WorkerDetailDataApi();
                toast.success("저장되었습니다.");
            })
            .catch((e) => {
                toast.error(e.response?.data);
                // console.log("!!!!");
            });
        // console.log("sender", sender);
    };
    const zoneDelete = () => {
        // console.log("deletClk", deletClk);
        setlist(list?.filter((item, index) => index !== deletClk?.index));
        // if (deletClk?.DelIdx && workerIdx) {
        //     console.log("삭제 될듯??");
        //     fileDelete
        //         .mutateAsync({
        //             workerIdx: workerIdx,
        //             fileIdx: deletClk?.DelIdx,
        //         })
        //         .then(({ data }) => {
        //             toast.success("저장되었습니다.");
        //         })
        //         .catch((e) => {
        //             toast.error(e.response.data);
        //         });
        //     setdeleteidxs([...deleteidxs, deletClk?.DelIdx]);
        // }
    };
    const zoneSetValue = (i, result, val) => {
        setlist((prev) => {
            return prev.map((item, index) => {
                if (i === index) {
                    return { ...item, file: result };
                } else {
                    return item;
                }
            });
        });
    };
    const zoneSetImgFileType = (i, e) => {
        setlist((prev) => {
            return prev.map((item, index) => {
                if (i === index) {
                    return { ...item, file_type: e };
                } else {
                    return item;
                }
            });
        });
    };

    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={zoneDelete}
            />
            <div className="content ">
                <p className="title">작업자 상세</p>
                <div className="BorderBox">
                    <div className="contentWrap">
                        <div className="BoxInfo">
                            <div className="title">작업</div>
                            <div className="sub">
                                <div className="ChkBoxOne">
                                    <input
                                        type="checkbox"
                                        id="dobaeChk"
                                        name="dobaeChk"
                                        checked={dobaeChk}
                                        value={dobaeChk}
                                        disabled={
                                            location?.state?.data == "view"
                                                ? true
                                                : false
                                        }
                                        onChange={() =>
                                            setDobaeChk(dobaeChk === 0 ? 1 : 0)
                                        }
                                    />
                                    <label htmlFor="dobaeChk">도배</label>
                                </div>
                                <div className="ChkBoxOne">
                                    <input
                                        type="checkbox"
                                        id="jangpanChk"
                                        name="jangpanChk"
                                        checked={jangpanChk}
                                        value={jangpanChk}
                                        disabled={
                                            location?.state?.data == "view"
                                                ? true
                                                : false
                                        }
                                        onChange={() =>
                                            setJangpanChk(
                                                jangpanChk === 0 ? 1 : 0
                                            )
                                        }
                                    />
                                    <label htmlFor="jangpanChk">장판</label>
                                </div>
                                <div className="ChkBoxOne">
                                    <input
                                        type="checkbox"
                                        id="maruChk"
                                        name="maruChk"
                                        checked={maruChk}
                                        value={maruChk}
                                        disabled={
                                            location?.state?.data == "view"
                                                ? true
                                                : false
                                        }
                                        onChange={() =>
                                            setMaruChk(maruChk === 0 ? 1 : 0)
                                        }
                                    />
                                    <label htmlFor="maruChk">마루</label>
                                </div>
                            </div>
                        </div>
                        <BoxRadioboxInput
                            radioValue={workerType}
                            setRadioValue={setWorkerType}
                            disabled={
                                location?.state?.data == "view" ? true : false
                            }
                            title="분류"
                            Radioes={consts.worker2}
                        />
                        <BoxTextInput
                            title="이름"
                            value={workerName}
                            setValue={setWorkerName}
                            readOnly={
                                location?.state?.data == "view" ? true : false
                            }
                        />
                        <BoxTextInput
                            title="소속"
                            value={belong}
                            setValue={setBelong}
                            readOnly={
                                location?.state?.data == "view" ? true : false
                            }
                        />
                        <BoxTextInput
                            title="연락처"
                            value={phoneNum}
                            setValue={setPhoneNum}
                            readOnly={
                                location?.state?.data == "view" ? true : false
                            }
                        />
                        <div className="BoxInfo">
                            <div className="title">은행계좌</div>
                            <div className="sub">
                                <input
                                    style={{ width: "20%" }}
                                    type="text"
                                    placeholder="은행명"
                                    value={bankName}
                                    onChange={(e) =>
                                        setBankName(e.target.value)
                                    }
                                    readOnly={
                                        location?.state?.data == "view"
                                            ? true
                                            : false
                                    }
                                />
                                <input
                                    style={{ width: "30%" }}
                                    type="text"
                                    placeholder="계좌번호"
                                    value={bankNum}
                                    onChange={(e) => setBankNum(e.target.value)}
                                    readOnly={
                                        location?.state?.data == "view"
                                            ? true
                                            : false
                                    }
                                />
                                <input
                                    style={{ width: "10%" }}
                                    type="text"
                                    placeholder="예금주"
                                    value={bankOwner}
                                    onChange={(e) =>
                                        setBankOwner(e.target.value)
                                    }
                                    readOnly={
                                        location?.state?.data == "view"
                                            ? true
                                            : false
                                    }
                                />
                            </div>
                        </div>
                        <BoxMemoInput
                            value={textMemo}
                            setValue={setTextMemo}
                            readOnly={
                                location?.state?.data == "view" ? true : false
                            }
                        />
                        {location?.state?.data == "view"
                            ? list?.map((item, idx) => {
                                  return (
                                      <div
                                          key={idx}
                                          className="ImgInputWrap"
                                          style={{
                                              border: "none",
                                              borderBottom: "1px solid #ddd",
                                          }}
                                      >
                                          <div className="Imgtitle">
                                              파일첨부 추가하기
                                          </div>
                                          <div className="sub">
                                              {item.file ? (
                                                  <img
                                                      style={{
                                                          width: "100%",

                                                          height: "100%",
                                                          maxHeight: "500px",

                                                          objectFit: "contain",
                                                      }}
                                                      src={`${consts.ImgState}${item.file}`}
                                                  />
                                              ) : (
                                                  "이미지가 없습니다."
                                              )}
                                          </div>
                                      </div>
                                  );
                              })
                            : list?.map((item, idx) => {
                                  return (
                                      <BoxImgUpLoad
                                          title={`파일첨부${idx + 1}`}
                                          key={idx}
                                          index={idx}
                                          idx={item.idx}
                                          ImgFile={item.file}
                                          altImg="worker"
                                          setImgFile={(result) =>
                                              zoneSetValue(idx, result, "file")
                                          }
                                          setImgFileType={(e) =>
                                              zoneSetImgFileType(idx, e)
                                          }
                                          deletePop={deletePop}
                                          setDeletePop={setDeletePop}
                                          setDeleteClk={setDeleteClk}
                                      />
                                  );
                              })}

                        {location?.state?.data == "view" ? null : (
                            <div className="BoxInfo">
                                <div className="title">파일첨부 추가하기</div>
                                <div className="sub">
                                    <button
                                        onClick={() => zoneAppend()}
                                        type="button"
                                        style={{ width: "35%", height: "90%" }}
                                        className="case_add_btn"
                                    >
                                        <img src={images.add} />
                                        추가하기
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {location?.state?.data == "view" ? (
                    <div className="twoBtn_wrap">
                        <button
                            className="btn btn8"
                            onClick={() => {
                                navigate(`${routes.worker}`);
                            }}
                        >
                            목록
                        </button>
                        <button
                            className="btn btn9"
                            onClick={() => {
                                navigate(
                                    `${routes.workerDetail}?index=${workerIdx}`
                                );
                            }}
                        >
                            변경
                        </button>
                    </div>
                ) : (
                    <div className="BtnEnd">
                        <button
                            className="btn btn9"
                            onClick={() => {
                                SubmitClk();
                            }}
                        >
                            저장
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}

export default WorkerManageDetail;
