import { useEffect, useState } from "react";
import InputDate from "../../components/InputDate";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import { InputRadioBox } from "../../components/InputRadioBox";
import { TableASManagement } from "../../components/Tables";
import consts from "../../libs/consts";
import images from "../../libs/images";
import Pagination from "react-js-pagination";
import XLSX from "xlsx-js-style";
import { useGetData, usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import { da } from "date-fns/locale";
import moment from "moment";
import { toast } from "react-toastify";
import { PopupComp } from "../../components/PopUp";
import { set } from "lodash";

function AsManagement() {
    const { data: workerList } = useGetData(ApiData.workerList);
    const asListDelete = usePostData(ApiData.asListDelete);
    const asInfoList = usePostData(ApiData.asInfoList);
    const [deletePop, setDeletePop] = useState(false);
    const [deleteIdx, setDeleteIdx] = useState(0);
    const [sigongIdx, setsigongIdx] = useState(0);
    const [sdate, setsdate] = useState("");
    const [edate, setedate] = useState("");

    const [searchVal, setSearchVal] = useState("");
    const [manager, setManager] = useState("");
    const [workers, setWorkers] = useState([{ value: 0, text: "전체" }]);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState(0);
    const [dataList, setDataList] = useState([]);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const deleteData = () => {
        let sender = {
            asIdx: deleteIdx,
            sigongIdx: sigongIdx,
        };
        // console.log("sender", sender);
        asListDelete
            .mutateAsync(sender)
            .then(({ data }) => {
                asInfoListApi();
                toast.success("삭제를 완료했습니다.");
            })
            .catch((e) => {
                toast.error("삭제를 하지 못했습니다.");
            });
    };
    const asInfoListApi = (reset = false) => {
        let sender = {
            start_date: sdate,
            end_date: edate,
            searchVal: searchVal,
            status: status,
            admin_idx: Number(manager),
            page: page - 1,
        };
        if (reset) {
            sender = {
                start_date: "",
                end_date: "",
                searchVal: "",
                status: 0,
                admin_idx: 0,
                page: 0,
            };
            setsdate("");
            setedate("");
            setSearchVal("");
            setStatus(0);
            setPage(1);
            setManager(0);
        }
        // console.log("sender", sender);
        asInfoList
            .mutateAsync(sender)
            .then(({ data }) => {
                setDataList(data);
            })
            .catch((e) => {
                // console.log(e.response.data);
            });
    };

    useEffect(() => {
        workerList?.data?.map((item, index) => {
            setWorkers((prev) => [
                ...prev,
                { value: item.idx, text: item.name },
            ]);
        });
        return () => {
            setWorkers([{ value: "", text: "전체" }]); // 상태를 초기화
        };
    }, [workerList]);
    useEffect(() => {
        asInfoListApi();
    }, [page]);
    const excelDown = async () => {
        let sender = {
            start_date: sdate,
            end_date: edate,
            searchVal: searchVal,
            status: status,
            excelDown: 1,
        };
        try {
            // 서버에서 직원 리스트 가져오기
            let excel = await asInfoList
                .mutateAsync(sender)
                .then(({ data }) => {
                    return data;
                })
                .catch((e) => {
                    // console.log(e.response.data);
                });
            const excelData = excel;
            // console.log(excelData);

            // Excel 파일 생성 및 다운로드
            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: {
                    bold: true,
                    color: { rgb: "000000" },
                    name: "함초롱바탕",
                    sz: 13,
                },
                fill: { fgColor: { rgb: "f1faee" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };
            const dataStyle = {
                font: { color: { rgb: "000000" }, name: "함초롱바탕", sz: 11 },
                fill: { fgColor: { rgb: "ffffff" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };

            // 열의 폭을 정의
            const colWidths = [
                80, 80, 150, 110, 150, 130, 80, 80, 80, 130, 130,
            ];

            // cols 속성을 사용하여 각 열의 폭을 조절
            const cols = colWidths.map((width) => ({ wpx: width }));

            const headerRow = [
                { v: "시공", t: "s", s: headerStyle },
                { v: "상태", t: "s", s: headerStyle },
                { v: "지역", t: "s", s: headerStyle },
                { v: "고객", t: "s", s: headerStyle },
                { v: "전화번호", t: "s", s: headerStyle },
                { v: "시공일", t: "s", s: headerStyle },
                { v: "작업자", t: "s", s: headerStyle },
                { v: "사진", t: "s", s: headerStyle },
                { v: "A/S일정", t: "s", s: headerStyle },
                { v: "비용", t: "s", s: headerStyle },
            ];
            const dataRows = excelData.rows.flatMap((emp) => {
                // console.log("dobae_date:", emp.dobae_date);
                // console.log("zangpan_date:", emp.zangpan_date);
                const commonData = [];
                const productData = [
                    {
                        isProduct: emp.dobae_as_chk === 1,
                        product: "도배",
                        status: `${
                            emp.status === 1
                                ? "진행중"
                                : emp.status === 2
                                ? "완료"
                                : emp.status === 3
                                ? "종료"
                                : "-"
                        }`,

                        dateValue: emp.dobae_date || "-",
                        banzang: emp.dobae_banzang || "-",
                        imgCount: emp.imgCount_dobae || 0,
                        as_date:
                            moment(emp.dobae_as_date).format("YYYY-MM-DD") ||
                            "-",
                        as_price:
                            emp.dobae_as_price.toLocaleString() + "원" || "-",
                    },
                    {
                        isProduct: emp.zangpan_as_chk === 1,
                        product: "장판",
                        status: `${
                            emp.status === 1
                                ? "진행중"
                                : emp.status === 2
                                ? "완료"
                                : emp.status === 3
                                ? "종료"
                                : "-"
                        }`,

                        dateValue: emp.zangpan_date || "-",
                        banzang: emp.zangpan_banzang || "-",
                        imgCount: emp.imgCount_zangpan || 0,
                        as_date: emp.zangpan_as_date || "-",
                        as_price:
                            emp.zangpan_as_price.toLocaleString() + "원" || "-",
                    },
                ];

                return productData
                    .filter((pd) => pd.isProduct)
                    .map((pd) => [
                        ...commonData,
                        {
                            v: pd.product,
                            t: "s",
                            s: dataStyle,
                        }, //시공
                        {
                            v: pd.status,
                            t: "s",
                            s: dataStyle,
                        }, //상태

                        {
                            v: `${
                                emp.address !== null &&
                                emp.address_detail !== null
                                    ? emp.address + " " + emp.address_detail
                                    : emp.address !== null &&
                                      emp.address_detail === null
                                    ? emp.address
                                    : emp.address === null &&
                                      emp.address_detail !== null
                                    ? emp.address_detail
                                    : "-"
                            }`,
                            t: "s",
                            s: dataStyle,
                        }, //지역
                        {
                            v: `${emp.cutomer_name}`,
                            t: "s",
                            s: dataStyle,
                        }, //고객
                        {
                            v: `${emp.mb_phone}`,
                            t: "s",
                            s: dataStyle,
                        }, //핸드폰
                        {
                            v: pd.dateValue,
                            t: "s",
                            s: dataStyle,
                        }, //시공일
                        {
                            v: pd.banzang,
                            t: "s",
                            s: dataStyle,
                        }, //반장
                        {
                            v: pd.imgCount,
                            t: "s",
                            s: dataStyle,
                        }, //이미지
                        {
                            v: pd.as_date,
                            t: "s",
                            s: dataStyle,
                        }, //잔금 입금
                        {
                            v: pd.as_price,
                            t: "s",
                            s: dataStyle,
                        }, //금액
                    ]);
            });

            const rows = [headerRow, ...dataRows];

            // 새로운 Sheet 객체 생성
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws["!cols"] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, "상담신청");

            // 파일 다운로드
            XLSX.writeFile(wb, "AsManagement.xlsx");

            console.log("Excel 파일 생성 및 다운로드 완료");
        } catch (error) {
            console.error("Error occurred while downloading Excel", error);
        }
    };
    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteData}
            />
            <div className="content ">
                <div className="title_container">
                    <p className="title">A/S관리</p>
                </div>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <InputDate
                                type="text"
                                placeholder="시작날짜"
                                placeholderEnd="종료날짜"
                                name="ipd1"
                                label="등록일"
                                dateValue={sdate}
                                setDateValue={setsdate}
                                dateValueEnd={edate}
                                setDateValueEnd={setedate}
                                multiple={true}
                            />
                        </div>
                        <div className="search_form">
                            <Input
                                className="input_text"
                                type="text"
                                placeholder="연락처"
                                name="searchVal"
                                value={searchVal}
                                setValue={setSearchVal}
                                label="검색"
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        asInfoListApi();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="search_box_wrap">
                        <InputSelect
                            name="status"
                            label="담당자"
                            value={manager}
                            sectionStyle={{ width: "20%" }}
                            setValue={setManager}
                            option={workers}
                        />
                        <InputRadioBox
                            title="상태"
                            Radioes={consts.status3}
                            value={status}
                            setValue={setStatus}
                        />
                    </div>
                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button
                                className="btn btn8"
                                onClick={() => asInfoListApi(true)}
                            >
                                초기화
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() => asInfoListApi()}
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>

                <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                    [검색결과 : {dataList.count}건]
                </p>
                <TableASManagement
                    List={dataList?.rows}
                    setDeletePop={setDeletePop}
                    deletePop={deletePop}
                    setDeleteIdx={setDeleteIdx}
                    setsigongIdx={setsigongIdx}
                />
                <div className="BottomContent">
                    <div className="ButtonContent"></div>
                    <div className="PaginationContent">
                        <Pagination
                            className="my-pagination"
                            // 현제 보고있는 페이지
                            activePage={page}
                            // 한페이지에 출력할 아이템수
                            itemsCountPerPage={20}
                            // 총 아이템수
                            totalItemsCount={dataList.count || 0}
                            // 표시할 페이지수
                            pageRangeDisplayed={10}
                            // 함수
                            onChange={handlePageChange}
                            nextPageText=""
                            prevPageText=""
                            firstPageText=""
                            lastPageText=""
                        ></Pagination>
                    </div>
                    <div
                        className="excelContent"
                        style={{ cursor: "pointer" }}
                        onClick={excelDown}
                    >
                        <img src={images.excel} alt="excel" />
                        <p>엑셀다운로드</p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AsManagement;
