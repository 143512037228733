import { useEffect, useState } from "react";
import {
    BoxArrayContent,
    BoxContent,
    BoxContent2,
    BoxMemoInput,
} from "../../../components/BoxInfo";
import { TableClassfiy5, TableClassfiy6 } from "../../../components/Tables";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../../libs/routes";
import consts from "../../../libs/consts";
import moment from "moment";
import { HistoryBox } from "../../../components/HistoryBox";
import ApiData from "../../../libs/api";
import { usePostData } from "../../../utils/service";
import { toast } from "react-toastify";
import { TabComponent } from "../../../components/TabComponent";
import { PopupComp } from "../../../components/PopUp";
import useLoadingStore from "../../../zustand/Loading";
const array = ["도배", "장판", "타일", "바닥", "천장", "벽", "기타"];
function OrderManagerPreview() {
    const orderDetail = usePostData(ApiData.orderDetail);
    const orderDeleteApi = usePostData(ApiData.orderDelete);
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const listType = searchParams.get("type");

    const [OrderCustomerName, setOrderCustomerName] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [customerSigongDate, setCustomerSigongDate] = useState(new Date());
    const [OrderAddress, setOrderAddress] = useState("");
    const [OrderaddressDetail, setOrderAddressDetail] = useState("");
    const [customerFinalEstimate, setCustomerFinalEstimate] = useState(0);
    const [useRadio, setUseRadio] = useState(0);
    const [agency, setAgency] = useState("");
    const [orderSigongDate, setOrderSigongDate] = useState(new Date());
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [orderAddress2, setOrderAddress2] = useState("");
    const [orderAddressDetail2, setOrderAddressDetail2] = useState("");
    const [orderName, setOrderName] = useState("");
    const [orderPhone, setOrderPhone] = useState("");
    const [orderAmount, setOrderAmount] = useState(0);
    const [Ordermemo, setOrderMemo] = useState("");
    const [OrderListData, setOrderListData] = useState([]);
    const [OrderDetailData, setOrderDetailData] = useState([]);
    const [orderDobae, setOrderDobae] = useState(0);
    const [orderJangpan, setOrderJangpan] = useState(0);
    const [orderMaru, setOrderMaru] = useState(0);
    const [mateList, setMateList] = useState([]);
    const [OrderClkIdx, setOrderClkIdx] = useState(0);
    const [tap, setTap] = useState([]);
    const [deletePop, setDeletePop] = useState(false);
    //시공내용 보기..
    const historyLook = true;
    const [estimateData, setEstimateData] = useState([]);
    const [ContentHistory, setContentHistory] = useState([]);
    const [consultSummary, setConsultSummary] = useState("");
    const [ContentChange, setContentChange] = useState({});
    const [sigongWant, setSigongWant] = useState("");
    const [manager, setManager] = useState(1);
    const [customerName, setCustomerName] = useState("");
    const [phone, setPhone] = useState("");
    const [dobaeOn, setDobaeOn] = useState(0);
    const [jangpanOn, setJangpanOn] = useState(0);
    const [maruOn, setMaruOn] = useState(0);
    const [ShowContent, setShowContent] = useState(false);
    const [ShowContent2, setShowContent2] = useState(false);
    const [address, setAddress] = useState("");
    const [adressDetail, setAdressDetail] = useState("");
    const [area, setArea] = useState(0);
    const [areaPyeong, setAreaPyeong] = useState(0);
    const [directAreaPyeong, setDirectAreaPyeong] = useState(0);
    const [planUrl, setPlanUrl] = useState("");
    const [planImg, setPlanImg] = useState("");
    const [todayStatus, setTodayStatus] = useState(0);
    const [todayMemo, setTodayMemo] = useState("");
    const [veranda, setVeranda] = useState(0);
    const [verandaMemo, setVerandaMemo] = useState("");

    const [dobaeDate, setDobaeDate] = useState(new Date());
    const [dobaeTime, setDobaeTime] = useState("");
    const [dobaeRange, setDobaeRange] = useState(0);
    const [dobaeRangeMemo, setDobaeRangeMemo] = useState("");
    const [dobaeCeiling, setDobaeCeiling] = useState(0);
    const [dobaeCreateCompanyName, setDobaeCreateCompanyName] = useState("");

    const [dobaeDirectCompany, setDobaeDirectCompany] = useState("");
    const [dobaeProduct, setDobaeProduct] = useState(0);
    const [dobaeCnt, setDobaeCnt] = useState(0);
    const [dobaePrice, setDobaePrice] = useState(0);

    const [zangpanDate, setZangpanDate] = useState(new Date());
    const [zangpanTime, setZangpanTime] = useState("");
    const [zangpanRange, setZangpanRange] = useState(0);
    const [zangpanRangeMemo, setZangpanRangeMemo] = useState("");
    const [zangpanCreateCompanyName, setZangpanCreateCompanyName] =
        useState("");
    const [zangpanMM, setZangpanMM] = useState("");
    const [zangpanMolding, setZangpanMolding] = useState(0);
    const [zangpanMoldingMemo, setZangpanMoldingMemo] = useState("");
    const [zangpanMoldingRange, setZangpanMoldingRange] = useState(1);
    const [zangpanMoldingRangeMemo, setZangpanMoldingRangeMemo] = useState("");
    const [zangpanThreshold, setZangpanThreshold] = useState(2);
    const [zangpanPrice, setZangpanPrice] = useState(0);

    const [maruDate, setMaruDate] = useState(new Date());
    const [maruTime, setMaruTime] = useState("");
    const [maruRange, setMaruRange] = useState(0);
    const [maruRangeMemo, setMaruRangeMemo] = useState("");
    const [maruCreateCompanyName, setMaruCreateCompanyName] = useState("");

    const [maruFlooring, setMaruFlooring] = useState(1);
    const [maruFlooringMemo, setMaruFlooringMemo] = useState("");
    const [maruOldProduct, setMaruOldProduct] = useState("");
    const [maruDownMolding, setMaruDownMolding] = useState(2);
    const [maruThreshold, setMaruThreshold] = useState(2);
    const [maruRepair, setMaruRepair] = useState(2);
    const [maruPrice, setMaruPrice] = useState(0);

    const [createDt, setCreateDt] = useState(new Date());
    // const { loadingStart, loadingEnd } = useLoadingStore();

    const navigate = useNavigate();
    const orderDetailApi = () => {
        let sender = { orderIdx: index, type: listType };
        // console.log("sender", sender);
        if (OrderClkIdx !== 0) {
            sender.selectIdx = OrderClkIdx;
        }
        // loadingStart();
        orderDetail
            .mutateAsync(sender)
            .then(({ data }) => {
                // loadingEnd();
                // console.log("data", data);

                setOrderListData(data?.list);
                setOrderDetailData(data?.detail);
                setEstimateData(data?.estimate);
                setTap(data?.tap);
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
            });
    };
    const deleteData = () => {
        let sender = {
            deleteIdx: parseInt(index),
        };
        if (OrderClkIdx !== 0) {
            sender.deleteIdx = OrderClkIdx;
        }
        orderDeleteApi
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                navigate(routes.constr4);
            })
            .catch((e) => {
                toast.error("삭제를 하지 못했습니다.");
            });
    };
    useEffect(() => {
        orderDetailApi();
    }, [OrderClkIdx]);
    useEffect(() => {
        setManager(estimateData?.admin_name);
        setCustomerName(estimateData?.cutomer_name);
        setPhone(estimateData?.mb_phone);
        setDobaeOn(estimateData?.dobae);
        setJangpanOn(estimateData?.zangpan);
        setMaruOn(estimateData?.maru);
        setAddress(estimateData?.address);
        setAdressDetail(estimateData?.address_detail);
        setArea(estimateData?.area);
        setAreaPyeong(estimateData?.area_pyeong);
        setPlanUrl(estimateData?.plan_url);
        setPlanImg(estimateData?.plan_file);
        setTodayStatus(estimateData?.today_status);
        setTodayMemo(estimateData?.today_memo);
        setVeranda(estimateData?.veranda);
        setVerandaMemo(estimateData?.veranda_memo);
        setDobaeDate(estimateData?.dobae_date);
        setDobaeTime(estimateData?.dobae_time);
        setDobaeRange(estimateData?.dobae_range);
        setDobaeRangeMemo(estimateData?.dobae_range_memo);
        setDobaeCeiling(estimateData?.dobae_ceiling);
        setDobaeCreateCompanyName(estimateData?.dobae_company_name);
        setDobaeProduct(estimateData?.dobae_product);
        setDobaeCnt(estimateData?.dobae_cnt);
        setDobaePrice(estimateData?.dobae_price);
        setZangpanDate(estimateData?.zangpan_date);
        setZangpanTime(estimateData?.zangpan_time);
        setZangpanRange(estimateData?.zangpan_range);
        setZangpanRangeMemo(estimateData?.zangpan_range_memo);
        setZangpanCreateCompanyName(estimateData?.zangpan_company_name);
        setZangpanMM(estimateData?.zangpan_mm);
        setZangpanMolding(estimateData?.zangpan_molding);
        setZangpanMoldingMemo(estimateData?.zangpan_molding_memo);
        setZangpanMoldingRange(estimateData?.zangpan_molding_range);
        setZangpanMoldingRangeMemo(estimateData?.zangpan_molding_range_memo);
        setZangpanThreshold(estimateData?.zangpan_threshold);
        setZangpanPrice(estimateData?.zangpan_price);
        setMaruDate(estimateData?.maru_date);
        setMaruTime(estimateData?.maru_time);
        setMaruRange(estimateData?.maru_range);
        setMaruRangeMemo(estimateData?.maru_range_memo);
        setMaruCreateCompanyName(estimateData?.maru_company_name);
        setMaruFlooring(estimateData?.maru_flooring);
        setMaruFlooringMemo(estimateData?.maru_flooring_memo);
        setMaruOldProduct(estimateData?.maru_ori_flooring);
        setMaruDownMolding(estimateData?.maru_molding);
        setMaruThreshold(estimateData?.maru_threshold);
        setMaruRepair(estimateData?.maru_cure);
        setMaruPrice(estimateData?.maru_price);
        setCreateDt(estimateData?.create_dt);
        setSigongWant(`
        ${
            estimateData?.dobae === 1
                ? `도배 : ${moment(
                      estimateData?.dobae_date
                          ? estimateData?.dobae_date
                          : createDt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              estimateData?.dobae_date
                                  ? estimateData?.dobae_date
                                  : estimateData?.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }
        ${
            estimateData?.zangpan === 1
                ? `장판 : ${moment(
                      estimateData?.zangpan_date
                          ? estimateData?.zangpan_date
                          : createDt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              estimateData?.zangpan_date
                                  ? estimateData?.zangpan_date
                                  : estimateData?.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }
        ${
            estimateData?.maru === 1
                ? `마루 : ${moment(
                      estimateData?.estimateData?.maru_date
                          ? maruDate
                          : estimateData?.create_dt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              estimateData?.estimateData?.maru_date
                                  ? maruDate
                                  : estimateData?.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }`);
        let summary = "";

        if (estimateData?.address || estimateData?.address_detail) {
            summary += `${estimateData?.address ? estimateData?.address : ""}${
                estimateData?.address_detail ? estimateData?.address_detail : ""
            }/ `;
        }

        summary += `${estimateData?.area === 1 ? "공급" : "전용"}면적 ${
            estimateData?.area_pyeong || 0
        }평/ `;

        if (estimateData?.veranda == 1) {
            summary += `베란다 확장 있음${
                estimateData?.veranda_memo
                    ? `(${estimateData?.veranda_memo})`
                    : ""
            }/ `;
        } else {
            summary += `베란다 확장 없음${
                estimateData?.veranda_memo
                    ? `(${estimateData?.veranda_memo})`
                    : ""
            }/ `;
        }

        summary += `${
            estimateData?.today_status == 1
                ? "빈집 가구 없음 "
                : estimateData?.today_status == 2
                ? "거주중 가구 있음"
                : "이사 당일"
        }`;

        if (estimateData?.today_memo) {
            summary += `(${estimateData?.today_memo})`;
        }

        setConsultSummary(summary);
    }, [estimateData]);
    useEffect(() => {
        setOrderCustomerName(OrderDetailData?.cutomer_name);
        setCustomerPhone(OrderDetailData?.mb_phone);
        setOrderDobae(OrderDetailData?.dobae);
        setOrderJangpan(OrderDetailData?.zangpan);
        setOrderMaru(OrderDetailData?.maru);
        setCustomerSigongDate(OrderDetailData?.sigong_date);
        setOrderAddress(OrderDetailData?.address);
        setOrderAddressDetail(OrderDetailData?.address_detail);
        setCustomerFinalEstimate(OrderDetailData?.final_price);
        setUseRadio(OrderDetailData?.use_type);
        setAgency(OrderDetailData?.agency_name);
        setDeliveryFee(OrderDetailData?.delivery_price);
        setOrderSigongDate(OrderDetailData?.delivery_date);
        setOrderAddress2(OrderDetailData?.delivery_address);
        setOrderAddressDetail2(OrderDetailData?.delivery_address_detail);
        setOrderName(OrderDetailData?.recipient_name);
        setOrderPhone(OrderDetailData?.recipient_phone);
        setOrderAmount(OrderDetailData?.order_price);
        setOrderMemo(OrderDetailData?.memo);
        setMateList(OrderDetailData?.mateList);
    }, [OrderDetailData]);
    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteData}
            />
            <div className="content ">
                <TabComponent
                    index={tap?.counseling_idx}
                    estimate_idx={tap?.estimate_idx}
                    sigonginfo_idx={tap?.sigonginfo_idx}
                    order_idx={tap?.order_idx}
                    workend_idx={tap?.workend_idx}
                    as_tbl={tap?.as_tbl}
                />
                {/* <HistoryBox
                Info={ContentHistory}
                firstDate={createDt}
                historyLook={historyLook}
                setContentChange={setContentChange}
            /> */}
                <div
                    className="animate__animated animate__fadeIn"
                    style={{ marginTop: "30px" }}
                >
                    <div className="BorderBox">
                        <div
                            className="title HistoryBox"
                            style={{ borderBottom: "1px solid #ccc" }}
                            onClick={() => setShowContent(!ShowContent)}
                        >
                            <div className="left">
                                <h1>견적내용 보기</h1>
                            </div>
                            <div className="right">
                                <svg
                                    style={{
                                        transform: ShowContent
                                            ? "rotate(180deg)"
                                            : "rotate(0deg)",
                                    }}
                                    width="40"
                                    height="40"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                        fill="#333333"
                                    />
                                </svg>
                            </div>
                        </div>
                        {ShowContent && (
                            <>
                                <div className="title">
                                    <h1>상담요약</h1>
                                </div>
                                <div className="contentWrap">
                                    <BoxContent
                                        title="시공희망일"
                                        content={sigongWant}
                                    />
                                    <BoxContent
                                        title="내용"
                                        content={consultSummary}
                                    />
                                </div>
                                <div className="title">
                                    <h1>기본정보</h1>
                                </div>
                                <div className="contentWrap">
                                    <BoxContent
                                        title="담당자"
                                        content={manager || "-"}
                                    />
                                    <BoxContent
                                        title="고객명"
                                        content={customerName || "-"}
                                    />
                                    <BoxContent
                                        title="연락처"
                                        content={phone || "-"}
                                    />
                                    {/* <BoxArrayContent
                                    title="시공"
                                    // array={sigong || "-"}
                                /> */}
                                    <BoxContent
                                        title="주소"
                                        content={`${
                                            address !== null &&
                                            adressDetail !== null
                                                ? address + " " + adressDetail
                                                : address !== null &&
                                                  adressDetail === null
                                                ? address
                                                : address === null &&
                                                  adressDetail !== null
                                                ? adressDetail
                                                : "-"
                                        }`}
                                    />
                                    <BoxContent
                                        title="면적"
                                        content={`${
                                            areaPyeong ? areaPyeong : 0
                                        }평(${area == 1 ? "공급" : "전용"})`}
                                    />
                                    {/*BoxShowImg로 바꿔야 함. */}
                                    <div className="BoxInfo">
                                        <div className="title">도면 URL</div>
                                        <div className="sub">
                                            <a
                                                href={`${planUrl}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                도면보기
                                            </a>
                                        </div>
                                    </div>
                                    <div className="BoxInfo">
                                        <div className="title">도면파일</div>
                                        <div className="sub">
                                            {planImg ? (
                                                <a
                                                    href={`${consts.ImgState}${planImg}`}
                                                    download="도면_파일"
                                                    target="_blank"
                                                >
                                                    도면
                                                </a>
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                    </div>
                                    {/*BoxShowImg로 바꿔야 함. */}
                                    <BoxContent
                                        title="시공당일 주거환경"
                                        content={`${
                                            todayStatus == 1
                                                ? "빈집"
                                                : todayStatus == 2
                                                ? "거주중 가구 있음"
                                                : "이사 당일"
                                        } ${todayMemo ? `(${todayMemo})` : ""}`}
                                    />
                                    <BoxContent
                                        title="베란다 확장"
                                        content={
                                            veranda == 1
                                                ? `베란다 확장 있음 ${
                                                      verandaMemo
                                                          ? `(${verandaMemo})`
                                                          : ""
                                                  }`
                                                : veranda === 3
                                                ? `베란다 확장 미정 ${
                                                      verandaMemo
                                                          ? `(${verandaMemo})`
                                                          : ""
                                                  }`
                                                : `베란다 확장 없음 ${
                                                      verandaMemo
                                                          ? `(${verandaMemo})`
                                                          : ""
                                                  }`
                                        }
                                    />
                                </div>
                                {dobaeOn == 1 && (
                                    <div className="title_color_box">
                                        <div
                                            className="box_title"
                                            style={{
                                                backgroundColor: "#41b06e",
                                                color: "white",
                                            }}
                                        >
                                            <h1>도배</h1>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="contentBorderWrap">
                                                <BoxContent
                                                    title="시공일"
                                                    content={
                                                        dobaeDate
                                                            ? `${moment(
                                                                  dobaeDate
                                                              ).format(
                                                                  "YYYY-MM-DD"
                                                              )}(${
                                                                  consts
                                                                      .dayText[
                                                                      moment(
                                                                          dobaeDate
                                                                      ).day()
                                                                  ]
                                                              })`
                                                            : "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="시공시간"
                                                    content={dobaeTime || "-"}
                                                />
                                                <BoxContent
                                                    title="시공범위"
                                                    content={`${
                                                        dobaeRange == 1
                                                            ? "전체"
                                                            : "일부"
                                                    } ${
                                                        dobaeRangeMemo
                                                            ? `(${dobaeRangeMemo})`
                                                            : ""
                                                    }`}
                                                />
                                                <BoxContent
                                                    title="천장"
                                                    content={
                                                        dobaeCeiling == 1
                                                            ? "천장도배포함"
                                                            : "천장도배없음"
                                                    }
                                                />
                                                <BoxContent
                                                    title="제조사"
                                                    content={
                                                        dobaeCreateCompanyName ||
                                                        "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="벽지 종류"
                                                    content={
                                                        dobaeProduct == 1
                                                            ? "실크"
                                                            : dobaeProduct == 2
                                                            ? "합지"
                                                            : "혼합"
                                                    }
                                                />
                                                <BoxContent
                                                    title="벽지 수"
                                                    content={`${dobaeCnt}개`}
                                                />
                                                <BoxContent
                                                    title="견적금액"
                                                    content={`${dobaePrice.toLocaleString()}원`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {jangpanOn == 1 && (
                                    <div className="title_color_box">
                                        <div
                                            className="box_title"
                                            style={{
                                                backgroundColor: "#fb8500",
                                                color: "white",
                                            }}
                                        >
                                            <h1>장판</h1>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="contentBorderWrap">
                                                <BoxContent
                                                    title="시공일"
                                                    content={
                                                        zangpanDate
                                                            ? `${moment(
                                                                  zangpanDate
                                                              ).format(
                                                                  "YYYY-MM-DD"
                                                              )}(${
                                                                  consts
                                                                      .dayText[
                                                                      moment(
                                                                          zangpanDate
                                                                      ).day()
                                                                  ]
                                                              })`
                                                            : "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="시공시간"
                                                    content={zangpanTime || "-"}
                                                />
                                                <BoxContent
                                                    title="시공범위"
                                                    content={`${
                                                        zangpanRange == 1
                                                            ? "전체"
                                                            : "일부"
                                                    } ${
                                                        zangpanRangeMemo
                                                            ? `(${zangpanRangeMemo})`
                                                            : ""
                                                    }`}
                                                />
                                                <BoxContent
                                                    title="제조사"
                                                    content={
                                                        zangpanCreateCompanyName ||
                                                        "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="장판"
                                                    content={`${zangpanMM}`}
                                                />
                                                <BoxContent2
                                                    title="하단몰딩"
                                                    content1={`종류 : ${
                                                        zangpanMolding == 0
                                                            ? "없음"
                                                            : zangpanMolding ==
                                                              1
                                                            ? zangpanMoldingMemo
                                                            : zangpanMolding ==
                                                              2
                                                            ? "굽도리"
                                                            : "걸레받이"
                                                    }`}
                                                    content2={`범위 : ${
                                                        zangpanMoldingRange == 1
                                                            ? "전체"
                                                            : zangpanMoldingRangeMemo
                                                            ? zangpanMoldingRangeMemo
                                                            : "-"
                                                    }`}
                                                />

                                                <BoxContent
                                                    title="문턱"
                                                    content={
                                                        zangpanThreshold == 1
                                                            ? "시공"
                                                            : "시공없음"
                                                    }
                                                />
                                                <BoxContent
                                                    title="견적금액"
                                                    content={`${zangpanPrice.toLocaleString()}원`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {maruOn == 1 && (
                                    <div className="title_color_box">
                                        <div
                                            className="box_title"
                                            style={{
                                                backgroundColor: "#caf0f8",
                                            }}
                                        >
                                            <h1>마루</h1>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="contentBorderWrap">
                                                <BoxContent
                                                    title="시공일"
                                                    content={
                                                        maruDate
                                                            ? `${moment(
                                                                  maruDate
                                                              ).format(
                                                                  "YYYY-MM-DD"
                                                              )}(${
                                                                  consts
                                                                      .dayText[
                                                                      moment(
                                                                          maruDate
                                                                      ).day()
                                                                  ]
                                                              })`
                                                            : "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="시공시간"
                                                    content={maruTime || "-"}
                                                />
                                                <BoxContent
                                                    title="시공범위"
                                                    content={`${
                                                        maruRange == 1
                                                            ? "전체"
                                                            : "일부"
                                                    } ${
                                                        maruRangeMemo
                                                            ? `(${maruRangeMemo})`
                                                            : "-"
                                                    }`}
                                                />
                                                <BoxContent
                                                    title="제조사"
                                                    content={
                                                        maruCreateCompanyName ||
                                                        "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="바닥제"
                                                    content={
                                                        maruFlooring == 1
                                                            ? maruFlooringMemo
                                                            : maruFlooring == 2
                                                            ? "강마루"
                                                            : maruFlooring == 3
                                                            ? "강화마루"
                                                            : "SB마루"
                                                    }
                                                />
                                                <BoxContent
                                                    title="기존 바닥재"
                                                    content={
                                                        maruOldProduct || "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="하단몰딩"
                                                    content={
                                                        maruDownMolding == 1
                                                            ? "시공"
                                                            : "시공없음"
                                                    }
                                                />
                                                <BoxContent
                                                    title="문턱"
                                                    content={
                                                        maruThreshold == 1
                                                            ? "시공"
                                                            : "시공없음"
                                                    }
                                                />
                                                <BoxContent
                                                    title="보양작업"
                                                    content={
                                                        maruRepair == 1
                                                            ? "시공"
                                                            : "시공없음"
                                                    }
                                                />
                                                <BoxContent
                                                    title="견적금액"
                                                    content={`${maruPrice.toLocaleString()}원`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="BorderBox">
                        <div
                            className="title HistoryBox"
                            style={{ borderBottom: "1px solid #ccc" }}
                            onClick={() => setShowContent2(!ShowContent2)}
                        >
                            <div className="left">
                                <h2>주문내용</h2>
                            </div>
                            <div className="right">
                                <svg
                                    style={{
                                        transform: ShowContent2
                                            ? "rotate(180deg)"
                                            : "rotate(0deg)",
                                    }}
                                    width="40"
                                    height="40"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                        fill="#333333"
                                    />
                                </svg>
                            </div>
                        </div>
                        {ShowContent2 && (
                            <div className="BorderBoxTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>등록일</th>
                                            <th>대리점</th>
                                            <th>배송지</th>
                                            <th>수령자</th>
                                            <th>분류</th>
                                            <th>금액</th>
                                            <th>보기버튼</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {OrderListData?.map((x, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{`${moment(
                                                        x.create_dt
                                                    ).format(
                                                        "YYYY-MM-DD"
                                                    )}`}</td>
                                                    <td>
                                                        {x.agency_name || "-"}
                                                    </td>
                                                    <td>{`${
                                                        x.delivery_address !==
                                                            null &&
                                                        x.delivery_address_detail !==
                                                            null
                                                            ? x.delivery_address +
                                                              " " +
                                                              x.delivery_address_detail
                                                            : x.delivery_address !==
                                                                  null &&
                                                              x.delivery_address_detail ===
                                                                  null
                                                            ? x.delivery_address
                                                            : x.delivery_address ===
                                                                  null &&
                                                              x.delivery_address_detail !==
                                                                  null
                                                            ? x.delivery_address_detail
                                                            : "-"
                                                    }`}</td>

                                                    <td>
                                                        {x.cutomer_name || "-"}
                                                    </td>
                                                    <td>
                                                        {x.use_type === 1
                                                            ? "시공"
                                                            : x.use_type === 2
                                                            ? "퀵배송"
                                                            : x.use_type === 3
                                                            ? "A/S"
                                                            : x.use_type === 4
                                                            ? "내사"
                                                            : "-"}
                                                    </td>
                                                    <td>{`${
                                                        x.all_order_price
                                                            ? x.all_order_price.toLocaleString() +
                                                              "원"
                                                            : "-"
                                                    }`}</td>
                                                    <td
                                                        onClick={() => {
                                                            setOrderClkIdx(
                                                                x.idx
                                                            );
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                            textDecoration:
                                                                "underline",
                                                            color: "blue",
                                                        }}
                                                    >
                                                        내용보기
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <div className="BorderBox">
                        <div className="title">
                            <h1>고객정보</h1>
                        </div>
                        <div className="contentWrap">
                            <BoxContent
                                title="고객명"
                                content={`${OrderCustomerName || "_"} ${
                                    customerPhone || ""
                                }`}
                            />

                            <BoxContent
                                title="시공"
                                content={`${orderDobae === 1 ? "도배 " : ""}${
                                    orderJangpan === 1 ? "장판" : " "
                                }${orderMaru === 1 ? "마루" : " "}`}
                            />
                            <BoxContent
                                title="시공일"
                                content={
                                    customerSigongDate
                                        ? `${moment(customerSigongDate).format(
                                              "YYYY-MM-DD"
                                          )}(${
                                              consts.dayText[
                                                  moment(
                                                      customerSigongDate
                                                  ).day()
                                              ]
                                          })`
                                        : "-"
                                }
                            />
                            <BoxContent
                                title="주소"
                                content={`${
                                    OrderAddress != null &&
                                    OrderaddressDetail != null
                                        ? OrderAddress +
                                          " " +
                                          OrderaddressDetail
                                        : OrderAddress != null &&
                                          OrderaddressDetail == null
                                        ? OrderAddress
                                        : OrderAddress == null &&
                                          OrderaddressDetail != null
                                        ? OrderaddressDetail
                                        : "-"
                                }`}
                            />
                            {/* <BoxContent
                                title="최종견적"
                                content={`${
                                    customerFinalEstimate !== null &&
                                    customerFinalEstimate !== undefined
                                        ? customerFinalEstimate.toLocaleString()
                                        : 0
                                }원`}
                            /> */}

                            <BoxContent
                                title="최종견적"
                                content={`${(
                                    dobaePrice + zangpanPrice + maruPrice || 0
                                ).toLocaleString()}원`}
                            />
                            <BoxContent
                                title="용도"
                                content={
                                    useRadio === 1
                                        ? "시공"
                                        : useRadio === 2
                                        ? "퀵배송"
                                        : useRadio === 3
                                        ? "A/S"
                                        : "내사"
                                }
                            />
                        </div>
                    </div>
                    <div className="BorderBox">
                        <div className="title">
                            <h1>주문정보</h1>
                        </div>
                        <div className="contentWrap">
                            <BoxContent
                                title="대리점"
                                content={agency || "-"}
                            />

                            <BoxContent
                                title="배송비"
                                content={`${
                                    deliveryFee !== null &&
                                    deliveryFee !== undefined
                                        ? deliveryFee.toLocaleString()
                                        : 0
                                }원`}
                            />
                            <BoxContent
                                title="배송일"
                                content={
                                    orderSigongDate
                                        ? `${moment(orderSigongDate).format(
                                              "YYYY-MM-DD"
                                          )}(${
                                              consts.dayText[
                                                  moment(orderSigongDate).day()
                                              ]
                                          })`
                                        : "-"
                                }
                            />
                            <BoxContent
                                title="배송지"
                                content={`${
                                    orderAddress2 != null &&
                                    orderAddressDetail2 != null
                                        ? orderAddress2 +
                                          " " +
                                          orderAddressDetail2
                                        : orderAddress2 != null &&
                                          orderAddressDetail2 == null
                                        ? orderAddress2
                                        : orderAddress2 == null &&
                                          orderAddressDetail2 != null
                                        ? orderAddressDetail2
                                        : "-"
                                }`}
                            />
                            <BoxContent
                                title="수령자"
                                content={`${orderName || "-"} ${
                                    orderPhone || ""
                                }`}
                            />
                            <BoxContent
                                title="주문금액"
                                content={`${
                                    orderAmount !== null &&
                                    orderAmount !== undefined
                                        ? orderAmount.toLocaleString()
                                        : 0
                                }원`}
                            />
                            <BoxContent
                                title="합계금액"
                                content={`${(
                                    (orderAmount || 0) + (deliveryFee || 0)
                                ).toLocaleString()}원`}
                            />
                            <BoxMemoInput
                                title="메모"
                                value={Ordermemo}
                                // setValue={setOrderMemo}
                                readOnly={true}
                            />
                            {/* <BoxContent
                                title="메모"
                                content={Ordermemo || "-"}
                            /> */}
                        </div>
                    </div>
                    <div className="title_color_box">
                        <div
                            className="box_title"
                            style={{
                                backgroundColor: "#41b06e",
                                color: "white",
                            }}
                        >
                            <h1>도배</h1>
                        </div>
                        <div className="contentColorWrap">
                            <div className="subTitleCircle">
                                <div className="GreenCircle"></div>
                                <h3>선택벽지</h3>
                            </div>
                            <TableClassfiy5 list={mateList} />
                            <div className="subTitleCircle">
                                <div className="GreenCircle"></div>
                                <h3>부자재</h3>
                            </div>
                            <TableClassfiy6 list={mateList} />
                        </div>
                    </div>
                </div>
                <div className="twoBtn_wrap">
                    <button
                        className="btn btn8"
                        onClick={() => {
                            navigate(routes.constr4);
                        }}
                    >
                        목록
                    </button>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <button
                            className="btn btn8"
                            onClick={() => {
                                setDeletePop(true);
                            }}
                        >
                            삭제
                        </button>
                        <button
                            className="btn btn9"
                            onClick={() => {
                                navigate(
                                    `${routes.constRegst3}?index=${index}&type=list`
                                );
                            }}
                        >
                            변경
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderManagerPreview;
