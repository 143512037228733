import { useEffect, useState } from "react";
import { da, is, ko } from "date-fns/locale";
import moment from "moment";
import DatePicker from "react-datepicker";

import { PopupComp } from "../../components/PopUp";
import { toast } from "react-toastify";
import ApiData from "../../libs/api";
import { useGetData, usePostData } from "../../utils/service";
import { useQueryClient } from "@tanstack/react-query";
import useLoadingStore from "../../zustand/Loading";
import { set } from "lodash";

//queryClient.invalidateQueries(ApiData.agencyList);
function InventManagement() {
    const queryClient = useQueryClient();
    const { data: materialApi, isLoading } = useGetData(
        ApiData.materialMateList
    );
    const { data: agencyApi } = useGetData(ApiData.agencyList);
    const materialMateInChk = usePostData(ApiData.materialMateInChk);
    const materialMateIn = usePostData(ApiData.materialMateIn);
    const materialMateDelete = usePostData(ApiData.materialMateDelete);
    const materialMateReturn = usePostData(ApiData.materialMateReturn);
    const [agencyList, setAgencyList] = useState([
        { idx: 0, agency_name: "선택" },
    ]);
    const [registerVal, setRegisterVal] = useState("");
    const [registerValNum, setRegisterValNum] = useState(0);
    const [popTitle, setPopTitle] = useState("");
    const [popContent, setPopContent] = useState("");
    const [indexThing, setIndexThing] = useState(0);
    const [popUp, setPopUp] = useState(false);
    // const { loadingStart, loadingEnd } = useLoadingStore();

    //flag값 1일때 벽지번호 없음 2일때 이미 등록 3일때 변경 4일때 반품 5일때 회수
    const [popUpClkFlag, setpopUpClkFlag] = useState(1);
    const [dataList, setDataList] = useState([]);

    const ChangeRegisterVal = (e) => {
        setRegisterVal(e.target.value);
    };
    const ChangeRegisterValNum = (e) => {
        let value = e.target.value;
        const reg = /^[0-9\b]+$/; // 정규 표현식: 숫자와 백스페이스만 허용

        if (value.startsWith("0")) {
            value = value.slice(1);
        }

        if (value === "" || reg.test(value)) {
            setRegisterValNum(value);
        }
    };

    const updateDataList = (i, key, value) => {
        setDataList(
            dataList?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };

    const zoneDelete = () => {
        materialMateDelete
            .mutateAsync({ deleteIdx: indexThing })
            .then(({ data }) => {
                queryClient.invalidateQueries(ApiData.materialMateList);
                toast.success("삭제되었습니다.");
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };

    const Registing = () => {
        setPopUp(false);
        ListIn();
    };
    const AlreadyRegist = () => {
        setPopUp(false);
        ListIn();
    };

    const stockUpdate = () => {
        // console.log("i", i);
        let sender = {
            materialIdx: indexThing,
            materialCount: dataList.find((v, index) => v.idx === indexThing)
                .material_cnt,
        };
        materialMateIn
            .mutateAsync(sender)
            .then(({ data }) => {
                queryClient.invalidateQueries(ApiData.materialMateList);
                setDataList(materialApi?.data.rows);
                toast.success("처리되었습니다.");
            })
            .catch((error) => {
                toast.error(error.response.data);
            });
    };
    const returnGoods = () => {
        let sender = {
            materialIdx: indexThing,
            materialCount: dataList.find((v, index) => v.idx === indexThing)
                .materialCount,
            agencyIdx: dataList.find((v, index) => v.idx === indexThing)
                .agencyName,
            returnDate: dataList.find((v, index) => v.idx === indexThing)
                .returnDate,
            returnPrice: dataList.find((v, index) => v.idx === indexThing)
                .returnPrice,
        };

        materialMateReturn
            .mutateAsync(sender)
            .then(({ data }) => {
                queryClient.invalidateQueries(ApiData.materialMateList);
                // setDataList(materialApi?.data.rows);
                toast.success("처리되었습니다.");
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const RegisterBtn = () => {
        if (registerVal === "") {
            toast.error("벽지번호를 입력해주세요.");
            return;
        }
        materialMateInChk
            .mutateAsync({ materialNum: registerVal })
            .then(({ data }) => {
                if (
                    dataList.some(
                        (v, index) => v.material_number === registerVal
                    )
                ) {
                    setPopTitle("안내");
                    setPopContent(
                        "이미 등록된 제품입니다. 목록을 최상단으로 이동하시겠습니까?"
                    );
                    setPopUp(true);
                    setpopUpClkFlag(2);
                    return;
                } else {
                    ListIn();
                    return;
                }
            })
            .catch((error) => {
                if (
                    error.response.data.includes("일치하는 벽지번호가 없습니다")
                ) {
                    if (
                        dataList.some(
                            (v, index) => v.material_number === registerVal
                        )
                    ) {
                        setPopTitle("안내");
                        setPopContent(
                            "이미 등록된 제품입니다. 목록을 최상단으로 이동하시겠습니까?"
                        );
                        setPopUp(true);
                        setpopUpClkFlag(2);
                        return;
                    } else {
                        setPopTitle("안내");
                        setPopContent(
                            "일치하는 벽지번호가 없습니다. 그래도 등록하시겠습니까?"
                        );
                        setPopUp(true);
                        setpopUpClkFlag(1);
                    }
                } else {
                    toast.error(error.response.data);
                }
            });
        // if (dataList.some((v, index) => v.material_number === registerVal)) {
        //     setPopTitle("안내");
        //     setPopContent(
        //         "이미 등록된 제품입니다. 목록을 최상단으로 이동하시겠습니까?"
        //     );
        //     setPopUp(true);
        //     setpopUpClkFlag(2);
        //     return;
        // } else {
        //     setPopTitle("안내");
        //     setPopContent(
        //         "일치하는 벽지번호가 없습니다. 그래도 등록하시겠습니까?"
        //     );
        //     setPopUp(true);
        //     setpopUpClkFlag(1);
        //     return;
        // }
    };
    const stockChangeBtn = (i) => {
        // console.log("i", i);
        setPopTitle("변경");
        setPopContent("변경하시겠습니까?");
        setPopUp(true);
        setIndexThing(i);
        setpopUpClkFlag(3);
    };
    const ReturnVal = (i) => {
        const item = dataList.find((v, index) => v.idx === i);

        if (
            item.materialCount === undefined ||
            parseInt(item.materialCount) === 0
        ) {
            toast.error("반품수량을 입력해주세요.");
            return;
        }
        if (item.agencyName === undefined || parseInt(item.agencyName) === 0) {
            toast.error("대리점을 선택해주세요.");
            return;
        }
        if (item.returnDate === undefined) {
            toast.error("반품일을 선택해주세요.");
            return;
        }
        if (item.returnPrice === undefined) {
            toast.error("반품단가를 입력해주세요.");
            return;
        }

        setPopTitle("반품");
        setPopContent("반품하시겠습니까?");
        setPopUp(true);
        setIndexThing(i);
        setpopUpClkFlag(4);
    };
    const DeleteClk = (i) => {
        setPopTitle("삭제");
        setPopContent("삭제하시겠습니까?");
        setPopUp(true);
        setIndexThing(i);
        setpopUpClkFlag(5);
    };

    const ListIn = () => {
        let sender = {
            materialNum: registerVal,
            materialCount: registerValNum,
        };
        materialMateIn
            .mutateAsync(sender)
            .then(({ data }) => {
                queryClient.invalidateQueries(ApiData.materialMateList);
                toast.success("등록되었습니다.");
            })
            .catch((error) => {
                toast.error(error.response.data);
            });
    };
    useEffect(() => {
        agencyApi?.data?.map((item, index) => {
            setAgencyList((prev) => [
                ...prev,
                { idx: item.idx, agency_name: item.agency_name },
            ]);
        });
        return () => {
            setAgencyList([{ idx: 0, agency_name: "선택" }]); // 상태를 초기화
        };
    }, [agencyApi]);

    useEffect(() => {
        // isLoading ? loadingStart() : loadingEnd();
        // console.log("materialApi", materialApi);
        setDataList(materialApi?.data.rows);
    }, [materialApi]);

    // useEffect(() => {
    //     console.log("dataList", dataList);
    // }, [dataList]);
    return (
        <>
            <PopupComp
                popTitle={popTitle}
                popContent={popContent}
                OpenPop={popUp}
                setOpenPop={setPopUp}
                SubmitClk={
                    popUpClkFlag == 1
                        ? Registing
                        : popUpClkFlag == 2
                        ? AlreadyRegist
                        : popUpClkFlag == 3
                        ? stockUpdate
                        : popUpClkFlag == 4
                        ? returnGoods
                        : popUpClkFlag == 5
                        ? zoneDelete
                        : null
                }
            />
            <div className="content ">
                <p className="title">자재 재고 관리</p>
                <div className="materialmanage">
                    <p>직접입력 : </p>
                    <input
                        style={{ width: "20%" }}
                        type="text"
                        onChange={ChangeRegisterVal}
                        placeholder="벽지번호"
                        value={registerVal}
                    />
                    <input
                        style={{ width: "10%" }}
                        type="text"
                        value={registerValNum || 0}
                        placeholder="수량"
                        onChange={ChangeRegisterValNum}
                    />
                    <button className="btn btn9" onClick={RegisterBtn}>
                        등록
                    </button>
                </div>
                <div className="materialmanageWrap">
                    <div className="table_scroll">
                        <table>
                            <thead>
                                <tr>
                                    <th>자재</th>
                                    <th>모델번호</th>
                                    <th>재고수량</th>
                                    <th>반품</th>
                                    <th>삭제</th>
                                </tr>
                            </thead>
                            <tbody className="inventManage">
                                {dataList?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.material_name}</td>
                                            <td>{item.material_number}</td>
                                            <td>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                    }}
                                                >
                                                    <div className="tableInputWrap">
                                                        <input
                                                            type="text"
                                                            name="title"
                                                            value={
                                                                item.material_cnt
                                                                    ? item.material_cnt
                                                                          .toString()
                                                                          .replace(
                                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                                              ","
                                                                          )
                                                                    : 0
                                                            }
                                                            index={index}
                                                            onChange={(e) => {
                                                                const value =
                                                                    e.target.value.replace(
                                                                        /,/g,
                                                                        ""
                                                                    );
                                                                const parsedValue =
                                                                    parseInt(
                                                                        value
                                                                    );
                                                                updateDataList(
                                                                    index,
                                                                    "material_cnt",
                                                                    isNaN(
                                                                        parsedValue
                                                                    )
                                                                        ? 0
                                                                        : parsedValue
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    롤
                                                    <button
                                                        className="btn btn9"
                                                        onClick={() => {
                                                            stockChangeBtn(
                                                                item.idx
                                                            );
                                                        }}
                                                    >
                                                        변경
                                                    </button>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="return">
                                                    <div className="returnWrap">
                                                        <div className="tableInputWrap">
                                                            <input
                                                                type="text"
                                                                name="title"
                                                                value={
                                                                    item.materialCount
                                                                        ? item.materialCount
                                                                              .toString()
                                                                              .replace(
                                                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                                                  ","
                                                                              )
                                                                        : 0
                                                                }
                                                                index={index}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const value =
                                                                        e.target.value.replace(
                                                                            /,/g,
                                                                            ""
                                                                        );
                                                                    const parsedValue =
                                                                        parseInt(
                                                                            value
                                                                        );
                                                                    updateDataList(
                                                                        index,
                                                                        "materialCount",
                                                                        isNaN(
                                                                            parsedValue
                                                                        )
                                                                            ? 0
                                                                            : parsedValue
                                                                    );
                                                                }}
                                                            />
                                                        </div>

                                                        <p>롤</p>
                                                        <DatePicker
                                                            locale={ko}
                                                            selected={
                                                                item?.returnDate
                                                            }
                                                            onChange={(
                                                                date
                                                            ) => {
                                                                const formattedDate =
                                                                    moment(
                                                                        date
                                                                    ).format(
                                                                        "YYYY-MM-DD"
                                                                    );
                                                                updateDataList(
                                                                    index,
                                                                    "returnDate",
                                                                    formattedDate
                                                                );
                                                            }}
                                                            className="input_date_label"
                                                            dateFormat="yyyy-MM-dd"
                                                        />
                                                    </div>
                                                    <div className="returnWrap">
                                                        <select
                                                            value={
                                                                item.agencyName ||
                                                                0
                                                            }
                                                            onChange={(e) => {
                                                                updateDataList(
                                                                    index,
                                                                    "agencyName",
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        >
                                                            {agencyList?.map(
                                                                (v) => (
                                                                    <option
                                                                        key={
                                                                            v.idx
                                                                        }
                                                                        value={[
                                                                            v.idx,
                                                                        ]}
                                                                    >
                                                                        {
                                                                            v.agency_name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                        <div className="tableInputWrap">
                                                            <input
                                                                type="text"
                                                                name="title"
                                                                placeholder="단가"
                                                                value={
                                                                    item.returnPrice
                                                                        ? item.returnPrice
                                                                              .toString()
                                                                              .replace(
                                                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                                                  ","
                                                                              )
                                                                        : 0
                                                                }
                                                                index={index}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const value =
                                                                        e.target.value.replace(
                                                                            /,/g,
                                                                            ""
                                                                        );
                                                                    const parsedValue =
                                                                        parseInt(
                                                                            value
                                                                        );
                                                                    updateDataList(
                                                                        index,
                                                                        "returnPrice",
                                                                        isNaN(
                                                                            parsedValue
                                                                        )
                                                                            ? 0
                                                                            : parsedValue
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        원
                                                        <button
                                                            className="btn btn9"
                                                            onClick={() => {
                                                                ReturnVal(
                                                                    item.idx
                                                                );
                                                            }}
                                                        >
                                                            반품
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="trash_btn_red"
                                                    onClick={() =>
                                                        DeleteClk(item.idx)
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
export default InventManagement;
