import { useEffect, useState } from "react";
import { TableChangeIndex } from "../../components/TableEdit";
import consts from "../../libs/consts";
import { set } from "lodash";
import { usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import { toast } from "react-toastify";
import { parse } from "date-fns";
import { fi } from "date-fns/locale";
import { PopupComp } from "../../components/PopUp";
import useLoadingStore from "../../zustand/Loading";

function MaterialClassifManagement() {
    const materialDelete = usePostData(ApiData.materialDelete);
    const materialUpdate = usePostData(ApiData.materialUpdate);
    const materialUpdown = usePostData(ApiData.materialUpdown);
    const materialList = usePostData(ApiData.materialList);
    const [step, setStep] = useState(1);

    const [deletePop, setDeletePop] = useState(false);
    // const [deleteClk, setDeleteClk] = useState(false);

    const [deleteKey, setDeleteKey] = useState("");

    const [companyList, setCompanyList] = useState([]);
    const [companyIdx, setCompanyIdx] = useState(0);
    const [companyClkOPer, setCompanyClkOPer] = useState("");
    const [companyNewContent, setCompanyNewContent] = useState("");
    const [companyOnlyOneIdx, setCompanyOnlyOneIdx] = useState(0);

    const [firstList, setFirstList] = useState([]);
    const [firstIdx, setFirstIdx] = useState(0);
    const [firstClkOPer, setFirstClkOPer] = useState("");
    const [firstNewContent, setFirstNewContent] = useState("");
    const [firstOnlyOneIdx, setFirstOnlyOneIdx] = useState(0);

    const [secondList, setSecondList] = useState([]);
    const [secondIdx, setSecondIdx] = useState(0);
    const [secondClkOPer, setSecondClkOPer] = useState("");
    const [secondNewContent, setSecondNewContent] = useState("");
    const [secondOnlyOneIdx, setSecondOnlyOneIdx] = useState(0);
    const [secondUnitContent, setSecondUnitContent] = useState("");

    const [colorList, setColorList] = useState([]);
    const [colorIdx, setColorIdx] = useState(0);
    const [colorClkOPer, setColorClkOPer] = useState("");
    const [colorNewContent, setColorNewContent] = useState("");
    const [colorOnlyOneIdx, setColorOnlyOneIdx] = useState(0);

    const [patternList, setPatternList] = useState([]);
    const [patternIdx, setPatternIdx] = useState(0);
    const [patternOnlyOneIdx, setPatternOnlyOneIdx] = useState(0);
    const [patternClkOPer, setPatternClkOPer] = useState("");
    const [patternNewContent, setPatternNewContent] = useState("");

    // const { loadingStart, loadingEnd } = useLoadingStore();

    const companyListApi = () => {
        let sender = { apiType: "company", material_type: step };
        // loadingStart();
        materialList
            .mutateAsync(sender)
            .then(({ data }) => {
                // loadingEnd();
                const editData = data?.map((item) => {
                    return { ...item, readOnly: true };
                });
                setCompanyList(editData);
                //초기화 시켜줘야 함.
                setCompanyOnlyOneIdx(0);
                setCompanyIdx(0);
                setCompanyClkOPer("");

                setFirstList([]);
                setFirstOnlyOneIdx(0);
                setFirstIdx(0);
                setFirstClkOPer("");

                setSecondList([]);
                setSecondOnlyOneIdx(0);
                setSecondClkOPer("");
                setSecondOnlyOneIdx(0);

                setColorList([]);
                setColorIdx(0);
                setColorClkOPer("");
                setColorOnlyOneIdx(0);

                setPatternList([]);
                setPatternIdx(0);
                setPatternClkOPer("");
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
            });
    };
    const firstListApi = () => {
        let sender = {
            apiType: "class1",
            material_type: step,
            companyIdx: parseInt(companyOnlyOneIdx),
        };
        materialList
            .mutateAsync(sender)
            .then(({ data }) => {
                const editData = data?.map((item) => {
                    return { ...item, readOnly: true };
                });

                setFirstList(editData);
                setFirstOnlyOneIdx(0);
                setFirstIdx(0);
                setFirstClkOPer("");

                setSecondList([]);
                setSecondOnlyOneIdx(0);
                setSecondClkOPer("");
                setSecondIdx(0);

                setColorList([]);
                setColorIdx(0);
                setColorClkOPer("");
                setColorOnlyOneIdx(0);

                setPatternList([]);
                setPatternIdx(0);
                setPatternClkOPer("");
            })
            .catch((e) => {
                // //console.log(e);
                toast.error(e.response.data);
            });
    };
    const secondListApi = () => {
        let sender = {
            apiType: "class2",
            material_type: step,
            companyIdx: parseInt(companyOnlyOneIdx),
            class1Idx: parseInt(firstOnlyOneIdx),
        };
        materialList
            .mutateAsync(sender)
            .then(({ data }) => {
                const editData = data?.map((item) => {
                    return { ...item, readOnly: true };
                });

                setSecondList(editData);
                setSecondOnlyOneIdx(0);
                setSecondClkOPer("");
                setSecondOnlyOneIdx(0);

                setColorList([]);
                setColorIdx(0);
                setColorClkOPer("");
                setColorOnlyOneIdx(0);

                setPatternList([]);
                setPatternIdx(0);
                setPatternClkOPer("");
            })
            .catch((e) => {
                // //console.log("111", sender, e);
                toast.error(e.response.data);
            });
    };
    const colorListApi = () => {
        let sender = {
            apiType: "color",
            material_type: step,
            companyIdx: parseInt(companyOnlyOneIdx),
            class1Idx: parseInt(firstOnlyOneIdx),
            class2Idx: parseInt(secondOnlyOneIdx),
        };

        materialList
            .mutateAsync(sender)
            .then(({ data }) => {
                const editData = data?.color.map((item) => {
                    return { ...item, readOnly: true };
                });

                setColorList(editData);
                setColorIdx(0);
                setColorClkOPer("");
                setColorOnlyOneIdx(0);

                setPatternList([]);
                setPatternIdx(0);
                setPatternClkOPer("");
            })
            .catch((e) => {
                // console.log("sender", sender);
                console.log(e);
                // toast.error(e.response.data);
            });
    };
    const patternListApi = () => {
        let sender = {
            apiType: "pattern",
            material_type: step,
            companyIdx: parseInt(companyOnlyOneIdx),
            class1Idx: parseInt(firstOnlyOneIdx),
            class2Idx: parseInt(secondOnlyOneIdx),
        };

        materialList
            .mutateAsync(sender)
            .then(({ data }) => {
                // //console.log(data);
                const editData = data?.pattern.map((item) => {
                    return { ...item, readOnly: true };
                });

                setPatternList(editData);
                setPatternIdx(0);
                setPatternClkOPer("");
            })
            .catch((e) => {
                //console.log(e);
                toast.error(e.response.data);
            });
    };
    const goStep = (step) => {
        setStep(step);
    };

    const deleteData = () => {
        //console.log("삭제", deleteKey);
        let sender = {};

        if (deleteKey === "제조사") {
            sender.apiType = "company";
            sender.deleteIdx = parseInt(companyIdx);
        } else if (deleteKey === "1차 분류") {
            sender.apiType = "class1";
            sender.deleteIdx = parseInt(firstIdx);
            //console.log(firstIdx);
        } else if (deleteKey === "2차 분류") {
            sender.apiType = "class2";
            sender.deleteIdx = parseInt(secondIdx);
        } else if (deleteKey === "색상") {
            sender.apiType = "color";
            sender.deleteIdx = parseInt(colorIdx);
        } else if (deleteKey === "패턴") {
            sender.apiType = "pattern";
            sender.deleteIdx = parseInt(patternIdx);
        }

        materialDelete
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("삭제가 완료되었습니다.");
                if (deleteKey === "제조사") {
                    setCompanyClkOPer("");
                    companyListApi();
                } else if (deleteKey === "1차 분류") {
                    firstListApi();
                } else if (deleteKey === "2차 분류") {
                    secondListApi();
                } else if (deleteKey === "색상") {
                    colorListApi();
                } else if (deleteKey === "패턴") {
                    patternListApi();
                }
            })
            .catch((e) => {
                //console.log(e);
                toast.error(e.response.data);
            });
    };
    //company
    useEffect(() => {
        companyListApi();
    }, [step]);
    //company
    useEffect(() => {
        if (companyIdx === 0 && companyClkOPer === "") return;
        if (companyClkOPer === "levelUp") {
            firstListApi();
        } else if (companyClkOPer === "moveUp") {
            let sender = {
                apiType: "company",
                material_type: step,
                companyIdx: parseInt(companyIdx),
                moveType: "up",
            };
            materialUpdown
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("이동완료");
                    companyListApi();
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (companyClkOPer === "moveDown") {
            let sender = {
                apiType: "company",
                material_type: step,
                companyIdx: parseInt(companyIdx),
                moveType: "down",
            };
            materialUpdown
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("이동완료");
                    companyListApi();
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (companyClkOPer === "Append") {
            let sender = {
                apiType: "company",
                material_type: step,
                company_name: companyNewContent,
            };
            materialUpdate
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("등록완료");
                    companyListApi();

                    setCompanyNewContent("");
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (companyClkOPer === "Save") {
            let sender = {
                apiType: "company",
                material_type: step,
                companyIdx: parseInt(companyIdx),
                company_name: companyList.find(
                    (item) => item.idx === companyIdx
                ).company_name,
            };

            materialUpdate
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("수정완료");
                    companyListApi();

                    setSecondNewContent("");
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        }
    }, [companyOnlyOneIdx, companyIdx, companyClkOPer]);
    //1차 분류
    useEffect(() => {
        if (firstOnlyOneIdx === 0 && firstClkOPer === "") return;
        if (firstClkOPer === "levelUp") {
            secondListApi();
        } else if (firstClkOPer === "moveUp") {
            let sender = {
                apiType: "class1",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstIdx),
                moveType: "up",
            };
            materialUpdown
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("이동완료");
                    firstListApi();
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (firstClkOPer === "moveDown") {
            let sender = {
                apiType: "class1",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstIdx),
                moveType: "down",
            };
            materialUpdown
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("이동완료");
                    firstListApi();
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (firstClkOPer === "Append") {
            let sender = {
                apiType: "class1",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1_name: firstNewContent,
            };

            materialUpdate
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("등록완료");
                    firstListApi();

                    setFirstNewContent("");
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (firstClkOPer === "Save") {
            let sender = {
                apiType: "class1",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstIdx),
                class1_name: firstList.find((item) => item.idx === firstIdx)
                    .class1_name,
            };

            materialUpdate
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("등록완료");
                    firstListApi();
                    setFirstNewContent("");
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        }
    }, [firstOnlyOneIdx, firstIdx, firstClkOPer]);

    //2차분류
    useEffect(() => {
        if (secondOnlyOneIdx === 0 && secondClkOPer === "") return;
        if (secondClkOPer === "levelUp") {
            colorListApi();
        } else if (secondClkOPer === "moveUp") {
            let sender = {
                apiType: "class2",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2Idx: parseInt(secondIdx),
                moveType: "up",
            };
            materialUpdown
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("이동완료");
                    secondListApi();
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (secondClkOPer === "moveDown") {
            let sender = {
                apiType: "class2",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2Idx: parseInt(secondIdx),
                moveType: "down",
            };
            materialUpdown
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("이동완료");
                    secondListApi();
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (secondClkOPer === "Append") {
            let sender = {
                apiType: "class2",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2_name: secondNewContent,
                class2_unit: secondUnitContent,
            };
            materialUpdate
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("등록완료");
                    secondListApi();

                    setSecondNewContent("");
                    setSecondUnitContent("");
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (secondClkOPer === "Save") {
            let sender = {
                apiType: "class2",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2Idx: parseInt(secondIdx),
                class2_name: secondList.find((item) => item.idx === secondIdx)
                    .class2_name,
                class2_unit: secondList.find((item) => item.idx === secondIdx)
                    .class2_unit,
            };

            materialUpdate
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("등록완료");
                    secondListApi();

                    setSecondNewContent("");
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        }
    }, [secondOnlyOneIdx, secondIdx, secondClkOPer]);
    useEffect(() => {
        if (secondOnlyOneIdx === 0 && colorOnlyOneIdx === 0 && colorIdx === 0)
            return;
        if (colorClkOPer === "levelUp") {
            patternListApi();
        } else if (colorClkOPer === "moveUp") {
            let sender = {
                apiType: "color",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2Idx: parseInt(secondOnlyOneIdx),
                colorIdx: parseInt(colorIdx),
                moveType: "up",
            };
            materialUpdown
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("이동완료");
                    colorListApi();
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (colorClkOPer === "moveDown") {
            let sender = {
                apiType: "color",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2Idx: parseInt(secondOnlyOneIdx),
                colorIdx: parseInt(colorIdx),
                moveType: "down",
            };
            materialUpdown
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("이동완료");
                    colorListApi();
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (colorClkOPer === "Append") {
            let sender = {
                apiType: "color",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2Idx: parseInt(secondOnlyOneIdx),
                material_color: colorNewContent,
            };

            materialUpdate
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("등록완료");
                    colorListApi();

                    setColorNewContent("");
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (colorClkOPer === "Save") {
            let sender = {
                apiType: "color",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2Idx: parseInt(secondOnlyOneIdx),
                colorIdx: parseInt(colorIdx),
                material_color: colorList.find((item) => item.idx === colorIdx)
                    .material_color,
            };
            //console.log("sender", sender);
            materialUpdate
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("수정완료");
                    colorListApi();

                    setColorNewContent("");
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        }
    }, [colorIdx, colorClkOPer]);

    useEffect(() => {
        if (secondOnlyOneIdx === 0 && colorOnlyOneIdx === 0 && patternIdx === 0)
            return;
        if (patternClkOPer === "levelUp") {
        } else if (patternClkOPer === "moveUp") {
            let sender = {
                apiType: "pattern",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2Idx: parseInt(secondOnlyOneIdx),
                patternIdx: parseInt(patternIdx),
                moveType: "up",
            };
            materialUpdown
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("이동완료");
                    patternListApi();
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (patternClkOPer === "moveDown") {
            let sender = {
                apiType: "pattern",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2Idx: parseInt(secondOnlyOneIdx),
                patternIdx: parseInt(patternIdx),
                moveType: "down",
            };
            materialUpdown
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("이동완료");
                    patternListApi();
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (patternClkOPer === "Append") {
            let sender = {
                apiType: "pattern",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2Idx: parseInt(secondOnlyOneIdx),
                material_pattern: patternNewContent,
            };

            materialUpdate
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("등록완료");
                    patternListApi();

                    setPatternNewContent("");
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else if (patternClkOPer === "Save") {
            let sender = {
                apiType: "pattern",
                material_type: step,
                companyIdx: parseInt(companyOnlyOneIdx),
                class1Idx: parseInt(firstOnlyOneIdx),
                class2Idx: parseInt(secondOnlyOneIdx),
                patternIdx: parseInt(patternIdx),
                material_pattern: patternList.find(
                    (item) => item.idx === patternIdx
                ).material_pattern,
            };
            //console.log("sender", sender);
            materialUpdate
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("수정완료");
                    patternListApi();

                    setColorNewContent("");
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        }
    }, [patternIdx, patternClkOPer]);

    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="분류를 삭제해도 이미 등록된 자재들은 기존 분류명을 유지합니다. 삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteData}
            />
            <div className="content ">
                <p className="title">자재분류관리</p>
                <div className="ClassifyWrap">
                    <div className="ClassifyContentWrap">
                        <div
                            className={
                                step === 1
                                    ? "ClassifyContent active"
                                    : "ClassifyContent"
                            }
                            onClick={() => goStep(1)}
                        >
                            벽지
                        </div>
                        <div
                            className={
                                step === 2
                                    ? "ClassifyContent active"
                                    : "ClassifyContent"
                            }
                            onClick={() => goStep(2)}
                        >
                            장판
                        </div>
                        <div
                            className={
                                step === 3
                                    ? "ClassifyContent active"
                                    : "ClassifyContent"
                            }
                            onClick={() => goStep(3)}
                        >
                            마루
                        </div>
                        <div
                            className={
                                step === 4
                                    ? "ClassifyContent active"
                                    : "ClassifyContent"
                            }
                            onClick={() => goStep(4)}
                        >
                            걸레받이
                        </div>
                        <div
                            className={
                                step === 5
                                    ? "ClassifyContent active"
                                    : "ClassifyContent"
                            }
                            onClick={() => goStep(5)}
                        >
                            부자재
                        </div>
                    </div>
                </div>
                {step === 1 && (
                    <div>
                        <div className="ChangeIndexWrap">
                            <TableChangeIndex
                                setDeleteKey={setDeleteKey}
                                title={"제조사"}
                                list={companyList}
                                // OnlyOneIdx={companyOnlyOneIdx}
                                setOnlyOneIdx={setCompanyOnlyOneIdx}
                                setlist={setCompanyList}
                                setIdx={setCompanyIdx}
                                // clkOperation={companyClkOPer}
                                setClkOperation={setCompanyClkOPer}
                                // deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                content={companyNewContent}
                                setContent={setCompanyNewContent}
                                unit={false}
                            />
                            {companyOnlyOneIdx !== 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"1차 분류"}
                                    list={firstList}
                                    setlist={setFirstList}
                                    setIdx={setFirstIdx}
                                    setOnlyOneIdx={setFirstOnlyOneIdx}
                                    // clkOperation={firstClkOPer}
                                    setClkOperation={setFirstClkOPer}
                                    // deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={firstNewContent}
                                    setContent={setFirstNewContent}
                                    unit={false}
                                />
                            )}
                            {firstOnlyOneIdx != 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"2차 분류"}
                                    list={secondList}
                                    setlist={setSecondList}
                                    setIdx={setSecondIdx}
                                    setOnlyOneIdx={setSecondOnlyOneIdx}
                                    // clkOperation={secondClkOPer}
                                    setClkOperation={setSecondClkOPer}
                                    // deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={secondNewContent}
                                    setContent={setSecondNewContent}
                                    unitContent={secondUnitContent}
                                    setUnitContent={setSecondUnitContent}
                                    unit={true}
                                />
                            )}
                            {secondOnlyOneIdx != 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"색상"}
                                    list={colorList}
                                    setlist={setColorList}
                                    setIdx={setColorIdx}
                                    setOnlyOneIdx={setColorOnlyOneIdx}
                                    clkOperation={colorClkOPer}
                                    setClkOperation={setColorClkOPer}
                                    deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={colorNewContent}
                                    setContent={setColorNewContent}
                                    unit={false}
                                />
                            )}
                            {colorOnlyOneIdx != 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"패턴"}
                                    list={patternList}
                                    setlist={setPatternList}
                                    setIdx={setPatternIdx}
                                    clkOperation={patternClkOPer}
                                    setClkOperation={setPatternClkOPer}
                                    setOnlyOneIdx={setPatternOnlyOneIdx}
                                    deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={patternNewContent}
                                    setContent={setPatternNewContent}
                                    unit={false}
                                />
                            )}
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div>
                        <div className="ChangeIndexWrap">
                            <TableChangeIndex
                                setDeleteKey={setDeleteKey}
                                title={"제조사"}
                                list={companyList}
                                // OnlyOneIdx={companyOnlyOneIdx}
                                setOnlyOneIdx={setCompanyOnlyOneIdx}
                                setlist={setCompanyList}
                                setIdx={setCompanyIdx}
                                // clkOperation={companyClkOPer}
                                setClkOperation={setCompanyClkOPer}
                                // deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                content={companyNewContent}
                                setContent={setCompanyNewContent}
                                unit={false}
                            />
                            {companyOnlyOneIdx !== 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"1차 분류"}
                                    list={firstList}
                                    setlist={setFirstList}
                                    setIdx={setFirstIdx}
                                    setOnlyOneIdx={setFirstOnlyOneIdx}
                                    // clkOperation={firstClkOPer}
                                    setClkOperation={setFirstClkOPer}
                                    // deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={firstNewContent}
                                    setContent={setFirstNewContent}
                                    unit={false}
                                />
                            )}
                            {firstOnlyOneIdx != 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"2차 분류"}
                                    list={secondList}
                                    setlist={setSecondList}
                                    setIdx={setSecondIdx}
                                    setOnlyOneIdx={setSecondOnlyOneIdx}
                                    // clkOperation={secondClkOPer}
                                    setClkOperation={setSecondClkOPer}
                                    // deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={secondNewContent}
                                    setContent={setSecondNewContent}
                                    unitContent={secondUnitContent}
                                    setUnitContent={setSecondUnitContent}
                                    unit={true}
                                />
                            )}
                            {secondOnlyOneIdx != 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"색상"}
                                    list={colorList}
                                    setlist={setColorList}
                                    setIdx={setColorIdx}
                                    setOnlyOneIdx={setColorOnlyOneIdx}
                                    clkOperation={colorClkOPer}
                                    setClkOperation={setColorClkOPer}
                                    deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={colorNewContent}
                                    setContent={setColorNewContent}
                                    unit={false}
                                />
                            )}
                            {colorOnlyOneIdx != 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"패턴"}
                                    list={patternList}
                                    setlist={setPatternList}
                                    setIdx={setPatternIdx}
                                    clkOperation={patternClkOPer}
                                    setClkOperation={setPatternClkOPer}
                                    setOnlyOneIdx={setPatternOnlyOneIdx}
                                    deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={patternNewContent}
                                    setContent={setPatternNewContent}
                                    unit={false}
                                />
                            )}
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div>
                        <div className="ChangeIndexWrap">
                            <TableChangeIndex
                                setDeleteKey={setDeleteKey}
                                title={"제조사"}
                                list={companyList}
                                // OnlyOneIdx={companyOnlyOneIdx}
                                setOnlyOneIdx={setCompanyOnlyOneIdx}
                                setlist={setCompanyList}
                                setIdx={setCompanyIdx}
                                // clkOperation={companyClkOPer}
                                setClkOperation={setCompanyClkOPer}
                                // deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                content={companyNewContent}
                                setContent={setCompanyNewContent}
                                unit={false}
                            />
                            {companyOnlyOneIdx !== 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"1차 분류"}
                                    list={firstList}
                                    setlist={setFirstList}
                                    setIdx={setFirstIdx}
                                    setOnlyOneIdx={setFirstOnlyOneIdx}
                                    // clkOperation={firstClkOPer}
                                    setClkOperation={setFirstClkOPer}
                                    // deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={firstNewContent}
                                    setContent={setFirstNewContent}
                                    unit={false}
                                />
                            )}
                            {firstOnlyOneIdx != 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"2차 분류"}
                                    list={secondList}
                                    setlist={setSecondList}
                                    setIdx={setSecondIdx}
                                    setOnlyOneIdx={setSecondOnlyOneIdx}
                                    // clkOperation={secondClkOPer}
                                    setClkOperation={setSecondClkOPer}
                                    // deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={secondNewContent}
                                    setContent={setSecondNewContent}
                                    unitContent={secondUnitContent}
                                    setUnitContent={setSecondUnitContent}
                                    unit={true}
                                />
                            )}
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div>
                        <div className="ChangeIndexWrap">
                            <TableChangeIndex
                                setDeleteKey={setDeleteKey}
                                title={"제조사"}
                                list={companyList}
                                // OnlyOneIdx={companyOnlyOneIdx}
                                setOnlyOneIdx={setCompanyOnlyOneIdx}
                                setlist={setCompanyList}
                                setIdx={setCompanyIdx}
                                // clkOperation={companyClkOPer}
                                setClkOperation={setCompanyClkOPer}
                                // deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                content={companyNewContent}
                                setContent={setCompanyNewContent}
                                unit={false}
                            />
                            {companyOnlyOneIdx !== 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"1차 분류"}
                                    list={firstList}
                                    setlist={setFirstList}
                                    setIdx={setFirstIdx}
                                    setOnlyOneIdx={setFirstOnlyOneIdx}
                                    // clkOperation={firstClkOPer}
                                    setClkOperation={setFirstClkOPer}
                                    // deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={firstNewContent}
                                    setContent={setFirstNewContent}
                                    unit={false}
                                />
                            )}
                            {firstOnlyOneIdx != 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"2차 분류"}
                                    list={secondList}
                                    setlist={setSecondList}
                                    setIdx={setSecondIdx}
                                    setOnlyOneIdx={setSecondOnlyOneIdx}
                                    // clkOperation={secondClkOPer}
                                    setClkOperation={setSecondClkOPer}
                                    // deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={secondNewContent}
                                    setContent={setSecondNewContent}
                                    unitContent={secondUnitContent}
                                    setUnitContent={setSecondUnitContent}
                                    unit={true}
                                />
                            )}
                        </div>
                    </div>
                )}
                {step === 5 && (
                    <div>
                        <div className="ChangeIndexWrap">
                            <TableChangeIndex
                                setDeleteKey={setDeleteKey}
                                title={"제조사"}
                                list={companyList}
                                // OnlyOneIdx={companyOnlyOneIdx}
                                setOnlyOneIdx={setCompanyOnlyOneIdx}
                                setlist={setCompanyList}
                                setIdx={setCompanyIdx}
                                // clkOperation={companyClkOPer}
                                setClkOperation={setCompanyClkOPer}
                                // deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                content={companyNewContent}
                                setContent={setCompanyNewContent}
                                unit={false}
                            />
                            {companyOnlyOneIdx !== 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"1차 분류"}
                                    list={firstList}
                                    setlist={setFirstList}
                                    setIdx={setFirstIdx}
                                    setOnlyOneIdx={setFirstOnlyOneIdx}
                                    // clkOperation={firstClkOPer}
                                    setClkOperation={setFirstClkOPer}
                                    // deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={firstNewContent}
                                    setContent={setFirstNewContent}
                                    unit={false}
                                />
                            )}
                            {firstOnlyOneIdx != 0 && (
                                <TableChangeIndex
                                    setDeleteKey={setDeleteKey}
                                    title={"2차 분류"}
                                    list={secondList}
                                    setlist={setSecondList}
                                    setIdx={setSecondIdx}
                                    setOnlyOneIdx={setSecondOnlyOneIdx}
                                    // clkOperation={secondClkOPer}
                                    setClkOperation={setSecondClkOPer}
                                    // deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    content={secondNewContent}
                                    setContent={setSecondNewContent}
                                    unitContent={secondUnitContent}
                                    setUnitContent={setSecondUnitContent}
                                    unit={true}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
export default MaterialClassifManagement;
