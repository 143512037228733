import {
    BoxBalancePay,
    BoxButtons,
    BoxContent,
    BoxDefect,
    BoxDepositChk,
    BoxLookImg,
    BoxMemoInput,
    BoxProgress,
} from "../../../components/BoxInfo";
import { HistoryBox, HistoryMessageBox } from "../../../components/HistoryBox";
import {
    TableEndBaseboard,
    TableEndFlooring,
    TableEndOtherCost,
    TableEndSubsidiary,
    TableEndWallPaper,
    TableExpenseDetail,
} from "../../../components/Tables";
import images from "../../../libs/images";
import consts from "../../../libs/consts";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../../libs/routes";
import { usePostData, useSusinPostData } from "../../../utils/service";
import ApiData from "../../../libs/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { TabComponent } from "../../../components/TabComponent";
import useLoadingStore from "../../../zustand/Loading";
import { set } from "lodash";
const img = [
    `https://via.placeholder.com/150`,
    `https://via.placeholder.com/150`,
    `https://via.placeholder.com/150`,
];

function JobFinishedPreview() {
    const navigate = useNavigate();
    const historyLook = false;
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const SusinConnect = useSusinPostData(ApiData.apiConnect);
    const msgReceiveList = usePostData(ApiData.msgReceiveList);
    const workEndMsgList = usePostData(ApiData.workEndMsgList);
    const workEndDetail = usePostData(ApiData.workEndDetail);
    const workEndMsg = usePostData(ApiData.workEndMsg);
    const workEndChange = usePostData(ApiData.workEndChange);
    const [tap, setTap] = useState({});
    const [customerMsg, setCustomerMsg] = useState([]);
    const [workerMsg, setWorkerMsg] = useState([]);
    const [contentChange, setContentChange] = useState([]);
    const [historyContent, setHistoryContent] = useState([]);
    const [dobaePexpenses, setDobaePexpenses] = useState(0);
    const [dobaeMcost, setDobaeMcost] = useState(0);
    const [dobaeSubcost, setDobaeSubcost] = useState(0);
    const [dobaeAddcost, setDobaeAddcost] = useState(0);
    const [zangpanPexpenses, setZangpanPexpenses] = useState(0);
    const [zangpanAddcost, setZangpanAddcost] = useState(0);
    const [admin, setAdmin] = useState("");
    const [duty, setDuty] = useState(3.3);
    const [manager, setManager] = useState("");
    const [progress, setProgress] = useState(1);

    const [allpayment, setAllpayment] = useState(0);
    const [balancePay, setBalancePay] = useState(0);
    const [balanceMsgCount, setBalanceMsgCount] = useState(0);
    const [balanceMsgSuccessCount, setBalanceMsgSuccessCount] = useState(0);
    const [downPay, setDownPay] = useState(0);
    const [depositDate, setDepositDate] = useState("");
    const [depositRadio, setDepositRadio] = useState(0);
    const [memo, setMemo] = useState("");
    const [deletePop, setDeletePop] = useState(false);
    const [deletePopFlag, setDeletePopFlag] = useState(1);
    const [dobaePrice, setDobaePrice] = useState(0);
    const [dobaeAsPrice, setDobaeAsPrice] = useState(0);
    const [dobaedefectChk, setDobaeDefectChk] = useState(false);
    const [dobaeDefectContent, setdobaeDefectContent] = useState("");
    const [dobaeDate, setDobaeDate] = useState(new Date());
    const [dobaeEstimateContent, setDobaeEstimateContent] = useState("");
    const [dobaeEndMsgCount, setDobaeEndMsgCount] = useState(0);
    const [dobaeWallPaperCnt, setDobaeWallPaperCnt] = useState(0);
    const [dobaeBanzang, setDobaeBanzang] = useState({});
    const [dobaeWorker, setDobaeWorker] = useState([]);
    const [dobaeSubmitTime, setDobaeSubmitTime] = useState("");
    const [dobaeChangeTime, setDobaeChangeTime] = useState([]);
    const [dobaeImg, setDobaeImg] = useState([]);
    const [dobaeCompleteImg, setDobaeCompleteImg] = useState([]);
    const [dobaeReciptImg, setDobaeReciptImg] = useState(img);
    const [dobaeWorkerMemo, setDobaeWorkerMemo] = useState("");
    const [dobaeExpenseDetail, setDobaeExpenseDetail] = useState([]);
    const [dobaeDeleteExpenseThing, setDobaeDeleteExpenseThing] = useState(0);
    const [dobaeWallpaperlist, setDobaeWallpaperlist] = useState([]);
    const [dobaeSubList, setDobaeSubList] = useState([]);
    const [dobaeOtherCostList, setDobaeOtherCostList] = useState([]);
    const [dobaeOtherDeleteIdx, setDobaeOtherDeleteIdx] = useState(0);
    const [zangpanPrice, setZangpanPrice] = useState(0);
    const [zangpanAsPrice, setZangpanAsPrice] = useState(0);
    const [zangpanReportMsgCount, setZangpanReportMsgCount] = useState(0);
    const [zangpanDate, setZangpanDate] = useState(new Date());
    const [zangpanBanzang, setZangpanBanzang] = useState({});
    const [zangpanEstimateContent, setZangpanEstimateContent] = useState("");
    const [zangpanDefectChk, setZangpanDefectChk] = useState(false);
    const [zangpanDefectContent, setZangpanDefectContent] = useState("");
    const [zangpanWorker, setZangpanWorker] = useState([]);
    const [zangpanSubmitTime, setZangpanSubmitTime] = useState("");
    const [zangpanChangeTime, setZangpanChangeTime] = useState([]);
    const [zangpanImg, setZangpanImg] = useState([]);
    const [zangpanCompleteImg, setZangpanCompleteImg] = useState([]);
    const [zangpanReciptImg, setZangpanReciptImg] = useState([]);
    const [zangpanExpenseDetail, setZangpanExpenseDetail] = useState([]);
    const [zangpanDeleteExpenseThing, setZangpanDeleteExpenseThing] =
        useState(0);
    const [zangpanWorkerMemo, setZangpanWorkerMemo] = useState("");
    const [zangpanList, setZangpanList] = useState([]);
    const [zangpanBaseboardList, setZangpanBaseboardList] = useState([]);
    const [zangpanOtherCostList, setZangpanOtherCostList] = useState([]);
    const [zangpanOtherDeleteIdx, setZangpanOtherDeleteIdx] = useState(0);
    const [maruPrice, setMaruPrice] = useState(0);
    const [demolitionPrice, setDemolitionPrice] = useState(0);
    const [customerPaymentChk, setCustomerPaymentChk] = useState(0);
    const [dobae, setDobae] = useState(0);
    const [zangpan, setZangpan] = useState(0);
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [customMsgPage, setCustomMsgPage] = useState(1);
    const [customMsgTotal, setCustomMsgTotal] = useState(0);
    const [workerMsgPage, setWorkerMsgPage] = useState(1);
    const [workerMsgTotal, setWorkerMsgTotal] = useState(0);
    const handleCustomMsgPageChange = (pageNumber) => {
        setCustomMsgPage(pageNumber);
    };
    const handleWorkerMsgPageChange = (pageNumber) => {
        setWorkerMsgPage(pageNumber);
    };

    // const { loadingStart, loadingEnd } = useLoadingStore();
    function isValidJSON(json) {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }
    const EndChageApi = () => {
        workEndChange
            .mutateAsync({
                sigongInfoIdx: parseInt(index),
                end_type: progress,
            })
            .then(({ data }) => {
                toast.success("수정되었습니다.");
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const deleteDobaeExpenseData = () => {
        setDobaeExpenseDetail((prev) =>
            prev.filter((item, index) => {
                return index !== dobaeDeleteExpenseThing;
            })
        );
    };
    const deleteOtherDobaeCostData = () => {
        setDobaeOtherCostList((prev) =>
            prev.filter((item, index) => {
                return index !== dobaeOtherDeleteIdx;
            })
        );
    };
    const deleteZangpanExpenseData = () => {
        setZangpanExpenseDetail((prev) =>
            prev.filter((item, index) => {
                return index !== zangpanDeleteExpenseThing;
            })
        );
    };
    const deleteOtherZangpanCostData = () => {
        setZangpanOtherCostList((prev) =>
            prev.filter((item, index) => {
                return index !== zangpanOtherDeleteIdx;
            })
        );
    };
    const ContractMsg = (type) => {
        let sender = {
            sigongInfoIdx: index,
            msgType: type,
        };
        if (type === 1) {
            sender.balance_price =
                parseInt(dobaePrice || 0) +
                parseInt(zangpanPrice || 0) +
                parseInt(demolitionPrice || 0) +
                parseInt(maruPrice || 0) -
                (customerPaymentChk ? downPay : 0);
        }
        // console.log(sender, "sender");
        workEndMsg
            .mutateAsync(sender)
            .then(({ data }) => {
                MsgUpdateApi();
                workEndMsgListApi();
                toast.success("메세지를 전송했습니다.");
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const workEndMsgListApi = () => {
        workEndMsgList
            .mutateAsync({
                sigongInfoIdx: index,
                customPage: customMsgPage - 1,
                workerPage: workerMsgPage - 1,
            })
            .then(({ data }) => {
                setCustomMsgTotal(data?.customerMsg.count);
                setCustomerMsg(data?.customerMsg.rows);
                setWorkerMsgTotal(data?.workerMsg.count);
                setWorkerMsg(data?.workerMsg.rows);
                // console.log("data", data);
            })
            .catch((e) => {
                toast.error(e.response.data);
                // console.log("여기입니다..", e);
            });
    };
    useEffect(() => {
        workEndMsgListApi();
    }, [customMsgPage, workerMsgPage]);
    const MsgUpdateApi = () => {
        workEndDetail
            .mutateAsync({ sigongInfoIdx: index })
            .then((data) => {
                setBalanceMsgCount(data?.data?.balance_msg_count);
                setBalanceMsgSuccessCount(
                    data?.data?.balance_msg_success_count
                );
                setDobaeEndMsgCount(data?.data?.dobae_report_count);
                setZangpanReportMsgCount(data?.data?.zangpan_report_count);
            })
            .catch((err) => {
                toast.error(err.response.data);
            });
    };
    const workEndDetailApi = () => {
        // loadingStart();
        workEndDetail
            .mutateAsync({ sigongInfoIdx: index })
            .then((data) => {
                // loadingEnd();
                setDeliveryPrice(data?.data?.delivery_price);
                setHistoryContent(data?.data?.history);
                setTap(data?.data?.tap);
                setAdmin(data?.data?.admin_name);
                setDuty(data?.data?.duty);
                setProgress(data?.data?.work_end_date === null ? 1 : 2);
                setManager(data?.data?.admin_name);
                setBalanceMsgCount(data?.data?.balance_msg_count);
                setBalanceMsgSuccessCount(
                    data?.data?.balance_msg_success_count
                );
                setAllpayment(data?.data?.balance_price);
                setDownPay(data?.data?.customer_payment);
                setDepositDate(data?.data?.balance_paydate);
                setDepositRadio(data?.data?.balance_paydate === null ? 0 : 1);
                setMemo(data?.data?.workend_memo);
                setDobaePrice(data?.data?.dobaePrice);
                setDobaeAsPrice(data?.data?.dobaeAsPrice);
                setDobaeBanzang(data?.data?.banzangDobaeWorker);
                setDobaeDate(data?.data?.dobae?.dobae_date);
                setDobaeWallPaperCnt(data?.data?.dobae?.dobae_cnt);
                setDobaeEstimateContent(data?.data?.dobae_report_count);
                setDobaeEndMsgCount(data?.data?.dobae_report_count);
                setdobaeDefectContent(data?.data?.dobae?.dobae_as_memo);
                setDobaeDefectChk(data?.data?.dobae?.dobae_as_chk);
                setDobaeWorker(data?.data?.dobaeWorkerList);
                setDobaeExpenseDetail(data?.data?.dobaeWorkerList);
                setDobaeSubmitTime(data?.data?.dobaeReport?.create_dt);
                setDobaeChangeTime(data?.data?.dobaeReport?.reportLogList);
                setDobaeImg(
                    isValidJSON(data?.data?.dobaeReport?.content)
                        ? JSON.parse(data?.data?.dobaeReport?.content)
                        : null
                );
                setDobaeWorkerMemo(data?.data?.dobaeReport?.worker_memo);
                setDobaeWallpaperlist(data?.data?.dobaeMate);
                setDobaeSubList(data?.data?.dobaeSub);
                setDobaeOtherCostList(data?.data?.dobaeAdd);
                setZangpanPrice(data?.data?.zangpanPrice);
                setZangpanAsPrice(data?.data?.zangpanAsPrice);
                setZangpanReportMsgCount(data?.data?.zangpan_report_count);
                setZangpanDate(data?.data?.zangpan?.zangpan_date);
                setZangpanBanzang(data?.data?.banzangZangpanWorker);
                setZangpanWorker(data?.data?.zangpanWorkerList);
                setZangpanExpenseDetail(data?.data?.zangpanWorkerList);
                setZangpanEstimateContent(data?.data?.zangpan?.zangpan_mm);
                setZangpanDefectContent(data?.data?.zangpan?.zangpan_as_memo);
                setZangpanDefectChk(data?.data?.zangpan?.zangpan_as_chk);
                setZangpanSubmitTime(data?.data?.zangpanReport?.create_dt);
                setZangpanChangeTime(data?.data?.zangpanReport?.reportLogList);
                setZangpanImg(
                    isValidJSON(data?.data?.zangpanReport?.content)
                        ? JSON.parse(data?.data?.zangpanReport?.content)
                        : null
                );
                setZangpanList(data?.data?.zangpanMate);
                setZangpanBaseboardList(data?.data?.zangpanSub);
                setZangpanWorkerMemo(data?.data?.zangpanReport?.worker_memo);
                setZangpanOtherCostList(data?.data?.zangpanAdd);
                setMaruPrice(data?.data?.maruPrice);
                setDemolitionPrice(data?.data?.demolitionPrice);
                setCustomerPaymentChk(data?.data?.customerPaymentChk);
                setDobae(data?.data?.dobaeOn);
                setZangpan(data?.data?.zangpanOn);
            })
            .catch((err) => {
                // loadingEnd();
                toast.error(err.response.data);
                navigate(routes.constr5);
            });
    };
    const OpenUrl = ({ SendType, idx, type }) => {
        if (SendType === 4) {
            window.open(
                `https://worker.8amdobae.co.kr/WorkOrder?sigongIdx=${idx}&sigongType=${type}&Looking=true`
            );
        }
    };
    useEffect(() => {
        dobaeImg?.map((c) => {
            if (c.type === 1) {
                setDobaeCompleteImg((prev) => [...prev, c]);
            } else {
                setDobaeReciptImg((prev) => [...prev, c]);
            }
        });
        return () => {
            setDobaeCompleteImg([]);
            setDobaeReciptImg([]);
        };
    }, [dobaeImg]);

    useEffect(() => {
        zangpanImg?.map((c) => {
            if (c.type === 1) {
                setZangpanCompleteImg((prev) => [...prev, c]);
            } else {
                setZangpanReciptImg((prev) => [...prev, c]);
            }
        });
        return () => {
            setZangpanCompleteImg([]);
            setZangpanReciptImg([]);
        };
    }, [zangpanImg]);
    useEffect(() => {
        workEndDetailApi();
        workEndMsgListApi();
    }, [index]);
    useEffect(() => {
        if (contentChange) {
            setDepositDate(contentChange?.balance_paydate);
            setAllpayment(contentChange?.balance_price);
            setDepositRadio(contentChange?.balance_paydate === null ? 0 : 1);
            setMemo(contentChange?.workend_memo);
            setdobaeDefectContent(contentChange?.dobae_as_memo);
            setDobaeDefectChk(contentChange?.dobae_as_chk);
            setZangpanDefectChk(contentChange?.zangpan_as_chk);
            setZangpanDefectContent(contentChange?.zangpan_as_memo);
            if (contentChange?.end_expense_json) {
                setDobaeExpenseDetail(
                    JSON.parse(contentChange?.end_expense_json).filter((c) => {
                        return c.sigong_type === 1;
                    })
                );
                setZangpanExpenseDetail(
                    JSON.parse(contentChange?.end_expense_json).filter((c) => {
                        return c.sigong_type === 2;
                    })
                );
            }
            if (contentChange?.order_mate_json) {
                setDobaeWallpaperlist(
                    JSON.parse(contentChange?.order_mate_json).filter((c) => {
                        return c.material_type === 1;
                    })
                );
                setDobaeSubList(
                    JSON.parse(contentChange?.order_mate_json).filter((c) => {
                        return c.material_type === 5;
                    })
                );
            }
            if (contentChange?.end_other_json) {
                setDobaeOtherCostList(
                    JSON.parse(contentChange?.end_other_json).filter((c) => {
                        return c.sigong_type === 1;
                    })
                );
                setZangpanOtherCostList(
                    JSON.parse(contentChange?.end_other_json).filter((c) => {
                        return c.sigong_type === 2;
                    })
                );
            }
        }
    }, [contentChange]);
    useEffect(() => {
        setDobaePexpenses(
            dobaeExpenseDetail?.reduce((acc, cur) => {
                return (
                    acc + (cur.personnel_expenses || 0) + (cur.meal_price || 0)
                );
            }, 0)
        );
        setDobaeMcost(
            dobaeWallpaperlist?.reduce((acc, cur) => {
                return (
                    acc +
                    ((cur.order_count || 0) +
                        (cur.inventory_count || 0) -
                        (cur.leftover_count || 0)) *
                        (cur.order_price || 0)
                );
            }, 0)
        );
        setDobaeSubcost(
            dobaeSubList?.reduce((acc, cur) => {
                return (
                    acc +
                    ((cur.count || 0) - (cur.leftover_count || 0)) *
                        (cur.order_price || 0)
                );
            }, 0)
        );
        setDobaeAddcost(
            dobaeOtherCostList?.reduce((acc, cur) => {
                return acc + (cur.price || 0);
            }, 0)
        );
        setZangpanPexpenses(
            zangpanExpenseDetail?.reduce((acc, cur) => {
                return (
                    acc + (cur.personnel_expenses || 0) + (cur.meal_price || 0)
                );
            }, 0)
        );
        setZangpanAddcost(
            zangpanOtherCostList?.reduce((acc, cur) => {
                return acc + (cur.price || 0);
            }, 0)
        );
    }, [
        dobaeExpenseDetail,
        zangpanExpenseDetail,
        dobaeOtherCostList,
        zangpanOtherCostList,
        dobaeWallpaperlist,
        dobaeSubList,
    ]);
    return (
        <div className="content ">
            <TabComponent
                index={tap?.counseling_idx}
                estimate_idx={tap?.estimate_idx}
                sigonginfo_idx={tap?.sigonginfo_idx}
                order_idx={tap?.order_idx}
                workend_idx={tap?.sigonginfo_idx}
                as_tbl={tap?.as_tbl}
            />
            <div className="animate__animated animate__fadeIn">
                <BoxProgress
                    Radioes={consts.status2}
                    manager={manager}
                    value={progress}
                    setValue={setProgress}
                    RadioName={"progress"}
                    onClk={EndChageApi}
                />
                <HistoryBox
                    Info={historyContent}
                    historyLook={historyLook}
                    admin={admin}
                    setContentChange={setContentChange}
                />
                <div className="TableBox">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>합계</th>
                                <th>도배</th>
                                <th>장판</th>
                                <th>마루</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>인건비</td>
                                <td>
                                    {(
                                        (dobaePexpenses || 0) +
                                        (zangpanPexpenses || 0)
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>
                                    {(dobaePexpenses || 0).toLocaleString()}원
                                </td>
                                <td>
                                    {(zangpanPexpenses || 0).toLocaleString()}원
                                </td>
                                <td>0원</td>
                            </tr>
                            <tr>
                                <td>자재</td>
                                <td> {(dobaeMcost || 0).toLocaleString()}원</td>
                                <td>{(dobaeMcost || 0).toLocaleString()}원</td>
                                <td>
                                    {" "}
                                    {/* {(
                                            zangpanPrice?.material_cost || 0
                                        ).toLocaleString()} */}
                                    0원
                                </td>
                                <td>0원</td>
                            </tr>
                            <tr>
                                <td>자재 배송비</td>
                                <td>
                                    {" "}
                                    {parseInt(
                                        deliveryPrice || 0
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>
                                    {" "}
                                    {parseInt(
                                        deliveryPrice || 0
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>0원</td>
                                <td>0원</td>
                            </tr>
                            <tr>
                                <td>부자재</td>
                                <td>
                                    {" "}
                                    {parseInt(dobaeSubcost || 0)
                                        // +
                                        // parseInt(
                                        //     zangpanPrice?.sub_material_cost ||
                                        //         0
                                        // )
                                        .toLocaleString()}
                                    원
                                </td>
                                <td>
                                    {" "}
                                    {parseInt(dobaeSubcost || 0)
                                        // +
                                        // parseInt(
                                        //     zangpanPrice?.sub_material_cost ||
                                        //         0
                                        // )
                                        .toLocaleString()}
                                    원
                                </td>
                                <td>
                                    {/* {(
                                            zangpanPrice?.sub_material_cost || 0
                                        ).toLocaleString()} */}
                                    0원
                                </td>
                                <td>0원</td>
                            </tr>

                            <tr>
                                <td>추가비용</td>
                                <td>
                                    {" "}
                                    {(
                                        parseInt(dobaeAddcost || 0) +
                                        parseInt(zangpanAddcost || 0)
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>
                                    {" "}
                                    {parseInt(
                                        dobaeAddcost || 0
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>
                                    {parseInt(
                                        zangpanAddcost || 0
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>0원</td>
                            </tr>
                            <tr>
                                <td>A/S비용</td>
                                <td>
                                    {" "}
                                    {(
                                        parseInt(dobaeAsPrice || 0) +
                                        parseInt(zangpanAsPrice || 0)
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>
                                    {(dobaeAsPrice || 0).toLocaleString()}원
                                </td>
                                <td>
                                    {(zangpanAsPrice || 0).toLocaleString()}원
                                </td>
                                <td>0원</td>
                            </tr>
                            <tr>
                                <td>비용합계</td>
                                <td>
                                    {" "}
                                    {(
                                        (dobaePexpenses || 0) +
                                        (dobaeMcost || 0) +
                                        (dobaeSubcost || 0) +
                                        (dobaeAddcost || 0) +
                                        (dobaeAsPrice || 0) +
                                        (zangpanPexpenses || 0) +
                                        (zangpanAddcost || 0) +
                                        (zangpanAsPrice || 0)
                                    ).toLocaleString()}{" "}
                                    원
                                </td>
                                <td>
                                    {(
                                        (dobaePexpenses || 0) +
                                        (dobaeMcost || 0) +
                                        (dobaeSubcost || 0) +
                                        (dobaeAddcost || 0) +
                                        (dobaeAsPrice || 0)
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>
                                    {" "}
                                    {(
                                        (zangpanPexpenses || 0) +
                                        (zangpanAddcost || 0) +
                                        (zangpanAsPrice || 0)
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>0원</td>
                            </tr>
                            <tr>
                                <td>매출</td>
                                <td>
                                    {(
                                        parseInt(dobaePrice || 0) +
                                        parseInt(zangpanPrice || 0)
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td> {(dobaePrice || 0).toLocaleString()}원</td>
                                <td>
                                    {(zangpanPrice || 0).toLocaleString()}원
                                </td>
                                <td>0원</td>
                            </tr>
                            <tr>
                                <td>이윤</td>
                                <td>
                                    {" "}
                                    {(
                                        parseInt(dobaePrice || 0) -
                                        ((dobaePexpenses || 0) +
                                            (dobaeMcost || 0) +
                                            (dobaeSubcost || 0) +
                                            (dobaeAddcost || 0) +
                                            (dobaeAsPrice || 0)) +
                                        parseInt(zangpanPrice || 0) -
                                        ((zangpanPexpenses || 0) +
                                            (zangpanAddcost || 0)) +
                                        (zangpanAsPrice || 0)
                                    )
                                        // +
                                        // parseInt(zangpanPrice?.sales || 0) -
                                        // parseInt(
                                        //     zangpanPrice?.personnel_expenses ||
                                        //         0
                                        // ) +
                                        // parseInt(
                                        //     zangpanPrice?.material_cost || 0
                                        // ) +
                                        // parseInt(
                                        //     zangpanPrice?.sub_material_cost ||
                                        //         0
                                        // ) +
                                        // parseInt(
                                        //     zangpanPrice?.add_cost || 0
                                        // ) +
                                        // parseInt(zangpanPrice?.as_cost || 0)
                                        .toLocaleString()}
                                    원
                                </td>
                                <td>
                                    {" "}
                                    {(
                                        parseInt(dobaePrice || 0) -
                                        ((dobaePexpenses || 0) +
                                            (dobaeMcost || 0) +
                                            (dobaeSubcost || 0) +
                                            (dobaeAddcost || 0) +
                                            (dobaeAsPrice || 0))
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>
                                    {" "}
                                    {(
                                        parseInt(zangpanPrice || 0) -
                                        ((zangpanPexpenses || 0) +
                                            (zangpanAddcost || 0) +
                                            (zangpanAsPrice || 0))
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>0원</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="contentBox">
                    <BoxContent
                        title="총금액"
                        content={` ${(
                            parseInt(dobaePrice || 0) +
                            parseInt(zangpanPrice || 0) +
                            parseInt(demolitionPrice || 0) +
                            parseInt(maruPrice || 0)
                        ).toLocaleString()}
                            원`}
                    />
                    <BoxBalancePay
                        downPay={downPay}
                        balancePay={
                            parseInt(dobaePrice || 0) +
                            parseInt(zangpanPrice || 0) +
                            parseInt(demolitionPrice || 0) +
                            parseInt(maruPrice || 0) -
                            (customerPaymentChk ? downPay : 0)
                        }
                        readOnly={true}
                    />
                    <BoxButtons
                        title="입금요청"
                        bbtn={true}
                        bbtnNum={balanceMsgCount}
                        bbtnClk={() => ContractMsg(1)}
                    />
                    <BoxButtons
                        title="잔금확인"
                        bbtn={true}
                        bbtnNum={balanceMsgSuccessCount}
                        bbtnClk={() => ContractMsg(2)}
                    />
                    <BoxDepositChk deposit={depositDate} />
                    <BoxMemoInput
                        readOnly={true}
                        value={memo}
                        setValue={setMemo}
                    />
                    {dobae === 1 && (
                        <div className="title_color_box">
                            <div
                                className="box_title"
                                style={{
                                    backgroundColor: "#41b06e",
                                    color: "white",
                                }}
                            >
                                <h1>도배</h1>
                            </div>
                            <div className="contentColorWrap">
                                <div className="contentBorderWrap">
                                    <BoxContent
                                        title="시공일"
                                        content={
                                            dobaeDate
                                                ? `${moment(dobaeDate).format(
                                                      "YYYY-MM-DD"
                                                  )}(${
                                                      consts.dayText[
                                                          moment(
                                                              dobaeDate
                                                          ).day()
                                                      ]
                                                  })`
                                                : "-"
                                        }
                                    />
                                    <BoxContent
                                        title="견적내용"
                                        content={
                                            `${
                                                dobaeEstimateContent === 1
                                                    ? "실크도배"
                                                    : dobaeEstimateContent === 2
                                                    ? "합지도배"
                                                    : dobaeEstimateContent === 3
                                                    ? "혼합도배"
                                                    : "-"
                                            }, 벽지수 : ${
                                                dobaeWallPaperCnt || 0
                                            }` || "-"
                                        }
                                    />
                                    <BoxButtons
                                        title="종료리포트"
                                        gbtn={true}
                                        bbtn={true}
                                        OpenUrl={() =>
                                            OpenUrl({
                                                SendType: 4,
                                                idx: tap.idx,
                                                type: 1,
                                            })
                                        }
                                        bbtnNum={dobaeEndMsgCount || 0}
                                        bbtnClk={() => ContractMsg(3)}
                                    />
                                    <BoxDefect
                                        value={dobaeDefectContent}
                                        setValue={setdobaeDefectContent}
                                        chkbtn={dobaedefectChk}
                                        setChkbtn={setDobaeDefectChk}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>지출내역</h3>
                                </div>
                                <TableExpenseDetail
                                    list={dobaeExpenseDetail}
                                    duty={duty}
                                    readOnly={true}
                                />
                            </div>
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>작업리포트</h3>
                                </div>
                                <div className="contentBorderWrap">
                                    <BoxContent
                                        title="작업자"
                                        content={`${
                                            dobaeWorker?.length
                                        }명 (${dobaeWorker
                                            ?.map((c) => c.worker_name)
                                            .join(", ")})`}
                                    />
                                    <BoxContent
                                        title="제출시간"
                                        content={
                                            dobaeSubmitTime
                                                ? `${moment(
                                                      dobaeSubmitTime
                                                  ).format(
                                                      "YYYY-MM-DD HH:mm"
                                                  )}${
                                                      dobaeChangeTime &&
                                                      dobaeChangeTime.length > 0
                                                          ? `(변경시간 : ${dobaeChangeTime.map(
                                                                (c) => {
                                                                    return `${" "}${moment(
                                                                        c
                                                                    ).format(
                                                                        "YYYY-MM-DD HH:mm"
                                                                    )}`;
                                                                }
                                                            )})`
                                                          : ""
                                                  }`
                                                : "-"
                                        }
                                    />
                                    <BoxLookImg
                                        title="완료 사진"
                                        img={dobaeCompleteImg}
                                    />
                                    <BoxLookImg
                                        title="영수증 사진"
                                        img={dobaeReciptImg}
                                    />
                                    <BoxMemoInput
                                        title="작업자 메모"
                                        value={dobaeWorkerMemo}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>벽지</h3>
                                </div>
                                <TableEndWallPaper
                                    list={dobaeWallpaperlist}
                                    readOnly={true}
                                />
                            </div>
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>부자재</h3>
                                </div>
                                <TableEndSubsidiary
                                    list={dobaeSubList}
                                    readOnly={true}
                                />
                            </div>
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>기타비용</h3>
                                </div>
                                <TableEndOtherCost
                                    list={dobaeOtherCostList}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    )}
                    {zangpan === 1 && (
                        <div className="title_color_box">
                            <div
                                className="box_title"
                                style={{
                                    backgroundColor: "#fb8500",
                                    color: "white",
                                }}
                            >
                                <h1>장판</h1>
                            </div>
                            <div className="contentColorWrap">
                                <div className="contentBorderWrap">
                                    <BoxContent
                                        title="시공일"
                                        content={
                                            zangpanDate
                                                ? `${moment(zangpanDate).format(
                                                      "YYYY-MM-DD"
                                                  )}(${
                                                      consts.dayText[
                                                          moment(
                                                              zangpanDate
                                                          ).day()
                                                      ]
                                                  })`
                                                : "-"
                                        }
                                    />
                                    <BoxContent
                                        title="견적내용"
                                        content={zangpanEstimateContent || "-"}
                                    />
                                    <BoxButtons
                                        title="종료리포트"
                                        gbtn={true}
                                        bbtn={true}
                                        OpenUrl={() =>
                                            OpenUrl({
                                                SendType: 4,
                                                idx: tap.idx,
                                                type: 2,
                                            })
                                        }
                                        bbtnNum={zangpanReportMsgCount || 0}
                                        bbtnClk={() => ContractMsg(4)}
                                    />
                                    <BoxDefect
                                        value={zangpanDefectContent}
                                        setValue={setZangpanDefectContent}
                                        chkbtn={zangpanDefectChk}
                                        setChkbtn={setZangpanDefectChk}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>지출내역</h3>
                                </div>
                                <TableExpenseDetail
                                    list={zangpanExpenseDetail}
                                    readOnly={true}
                                    duty={duty}
                                />
                            </div>
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>작업리포트</h3>
                                </div>
                                <div className="contentBorderWrap">
                                    <BoxContent
                                        title="작업자"
                                        content={`${
                                            zangpanWorker?.length
                                        }명 (${zangpanWorker
                                            ?.map((c) => c.worker_name)
                                            .join(", ")})`}
                                    />
                                    <BoxContent
                                        title="제출시간"
                                        content={
                                            zangpanSubmitTime
                                                ? `${moment(
                                                      zangpanSubmitTime
                                                  ).format(
                                                      "YYYY-MM-DD HH:mm"
                                                  )}${
                                                      zangpanChangeTime &&
                                                      zangpanChangeTime.length >
                                                          0
                                                          ? `(변경시간 : ${zangpanChangeTime.map(
                                                                (c) => {
                                                                    return `${" "}${moment(
                                                                        c
                                                                    ).format(
                                                                        "YYYY-MM-DD HH:mm"
                                                                    )}`;
                                                                }
                                                            )})`
                                                          : ""
                                                  }`
                                                : "-"
                                        }
                                    />
                                    <BoxLookImg
                                        title="완료 사진"
                                        img={zangpanCompleteImg}
                                    />
                                    <BoxLookImg
                                        title="영수증 사진"
                                        img={zangpanReciptImg}
                                    />
                                    <BoxMemoInput
                                        title="작업자 메모"
                                        value={zangpanWorkerMemo}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            {/* <div className="contentColorWrap">
                            <div className="subTitleCircle">
                                <div className="GreenCircle"></div>
                                <h3>장판</h3>
                            </div>
                            <TableEndFlooring />
                        </div>
                        <div className="contentColorWrap">
                            <div className="subTitleCircle">
                                <div className="GreenCircle"></div>
                                <h3>걸래받이</h3>
                            </div>
                            <TableEndBaseboard />
                        </div> */}
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>기타비용</h3>
                                </div>
                                <TableEndOtherCost
                                    list={zangpanOtherCostList}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                        margin: "20px 0",
                    }}
                >
                    <button
                        className="ChangeBtn"
                        onClick={() => {
                            navigate(`${routes.constRegst4}?index=${index}`);
                        }}
                    >
                        변경
                    </button>
                </div>
                <HistoryMessageBox
                    Info={customerMsg}
                    page={customMsgPage}
                    handlePageChange={handleCustomMsgPageChange}
                    totalCount={customMsgTotal}
                />
                <HistoryMessageBox
                    IsWorker={true}
                    Info={workerMsg}
                    page={workerMsgPage}
                    handlePageChange={handleWorkerMsgPageChange}
                    totalCount={workerMsgTotal}
                />
            </div>
        </div>
    );
}

export default JobFinishedPreview;
