import React, { useEffect, useState } from "react";
import moment from "moment";

export default function InputSelect(props) {
    const {
        sectionStyle,
        className,
        placeholder,
        type,
        name,
        name2,
        maxlength,
        value,
        setValue,
        valid,
        label,
        error,
        errorFix,
        errorFix2,
        success,
        readOnly,
        onBlur,
        onChange,
        onChangeMulti,
        onChangeAfter,
        afterTarget = "",
        onChangeFunc,
        index,
        onSearch,
        withButton,
        withButtonPress,
        option,
        optionLabel,
        city,
        optionYear,
        optionMonth,
        optionHour,
        optionMin,
        optionHp,
        optionNotKey,
        setMynum,
        notKey,
        style,
        labelStyle,
        full,
    } = props;

    const [customError, setCustomError] = useState(
        errorFix && value === "2"
            ? "대기 상태로 상태 값 변경 시 고객상담콜을 받을 수 없습니다."
            : ""
    );
    const [customError2, setCustomError2] = useState(
        errorFix2 && value === "1"
            ? "모든 서비스 정상 제공"
            : errorFix2 && value === "2"
            ? "견적의뢰 요청 중지"
            : errorFix2 && value === "3"
            ? "모든서비스 제공 중지"
            : ""
    );

    const handleChange = (e) => {
        if (onChangeMulti) {
            let nm2 = { target: { name: name2 ? name2 : "" } };
            onChangeMulti(index, e, nm2, e.target.value, "");
        } else if (onChange) {
            onChange(index, e, e.target.value);
            onChangeAfter && onChangeAfter(index, afterTarget);
        } else if (onSearch) {
            onSearch(name, e.target.value);
        } else {
            setValue(e.target.value);
            onChangeFunc && onChangeFunc(e.target.value);
        }

        if (errorFix) {
            if (e.target.value === "2") {
                setCustomError(
                    "대기 상태로 상태 값 변경 시 고객상담콜을 받을 수 없습니다."
                );
            } else {
                setCustomError("");
            }
        }

        if (errorFix2) {
            if (e.target.value === "1") {
                setCustomError2("모든 서비스 정상 제공");
            } else if (e.target.value === "2") {
                setCustomError2("견적의뢰 요청 중지");
            } else {
                setCustomError2("모든서비스 제공 중지");
            }
        }
    };

    return (
        <div
            className={
                "input_box_select_custom " +
                (full
                    ? "input_box input_box_select input_box_full"
                    : "input_box input_box_select")
            }
            style={style}
        >
            {label && (
                <label
                    className="input_label"
                    style={labelStyle}
                    htmlFor={name}
                >
                    {label}
                </label>
            )}
            {optionLabel && (
                <span className="input_option_label">{optionLabel}</span>
            )}
            <div className="input_section" style={sectionStyle}>
                <select
                    name={name}
                    onChange={handleChange}
                    value={value}
                    className={readOnly && "disable"}
                    disabled={readOnly}
                >
                    {placeholder && <option value="">{placeholder}</option>}
                    {option &&
                        (Array.isArray(option) ? option : []).map((x, i) => {
                            return (
                                <option key={i} value={x.value}>
                                    {x.text}
                                </option>
                            );
                        })}

                    {optionYear &&
                        [...Array(moment().format("YYYY") * 1 - 2019)].map(
                            (d, i) => {
                                let val = 2020 + i;
                                return (
                                    <option
                                        key={i}
                                        value={val}
                                        selected={value === val}
                                    >
                                        {val + "년"}
                                    </option>
                                );
                            }
                        )}

                    {optionMonth &&
                        [...Array(12)].map((d, i) => {
                            let val = i + 1 < 10 ? "0" + (i + 1) : i + 1;
                            return (
                                <option key={i} value={val}>
                                    {val + "월"}
                                </option>
                            );
                        })}

                    {optionHour &&
                        [...Array(24)].map((d, i) => {
                            let val = i + 1 < 10 ? "0" + (i + 1) : i + 1;
                            return (
                                <option key={i} value={val}>
                                    {val + "시"}
                                </option>
                            );
                        })}
                    {optionMin &&
                        [...Array(60)].map((d, i) => {
                            let val = i + 1 < 10 ? "0" + (i + 1) : i + 1;
                            return (
                                <option key={i} value={val}>
                                    {val + "분"}
                                </option>
                            );
                        })}
                    {optionHp && (
                        <>
                            <option value={"010"}>{"010"}</option>
                            <option value={"011"}>{"011"}</option>
                            <option value={"016"}>{"016"}</option>
                            <option value={"017"}>{"017"}</option>
                        </>
                    )}
                    {optionNotKey &&
                        optionNotKey.map((x, i) => {
                            return (
                                <option key={i} value={x}>
                                    {x}
                                </option>
                            );
                        })}
                </select>
            </div>

            {error && <p className="input_error">{error}</p>}
            {success && <p className="input_success">{success}</p>}
            {customError && <p className="input_error">{customError}</p>}
            {customError2 && <p className="input_error">{customError2}</p>}
        </div>
    );
}
