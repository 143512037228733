import { useEffect, useState } from "react";
import Input from "../../components/Input";
import { InputRadioBox } from "../../components/InputRadioBox";
import { TableWorkerManage } from "../../components/Tables";
import { useNavigate } from "react-router-dom";
import routes from "../../libs/routes";
import consts from "../../libs/consts";
import Pagination from "react-js-pagination";
import ApiData from "../../libs/api";
import { useGetData, usePostData } from "../../utils/service";
import { PopupComp } from "../../components/PopUp";
import { toast } from "react-toastify";
import useLoadingStore from "../../zustand/Loading";

function WorkerManagement() {
    const navigate = useNavigate();
    const workerListData = usePostData(ApiData.workerManageList);
    const workerDeleteData = usePostData(ApiData.workerDelete);
    const [searchVal, setSearchVal] = useState("");
    const [dobaeChk, setDobaeChk] = useState(0);
    const [jangpanChk, setJangpanChk] = useState(0);
    const [maruChk, setMaruChk] = useState(0);
    const [workerType, setWorkerType] = useState(0);
    const [ListData, setListData] = useState([]);
    const [deletePop, setDeletePop] = useState(false);
    const [deleteIdx, setDeleteIdx] = useState(0);
    const [page, setPage] = useState(1);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    useEffect(() => {
        workerListApi();
    }, [page]);
    const resetClk = () => {
        setDobaeChk(0);
        setJangpanChk(0);
        setMaruChk(0);
        setWorkerType(0);
        setSearchVal("");
        workerListApi(true);
    };
    const workerListApi = (reset = false) => {
        let sender = {
            searchVal: searchVal,
            page: page - 1,
        };
        if (parseInt(dobaeChk)) {
            sender.dobae = parseInt(dobaeChk);
        }
        if (parseInt(jangpanChk)) {
            sender.zangpan = parseInt(jangpanChk);
        }
        if (parseInt(maruChk)) {
            sender.maru = parseInt(maruChk);
        }
        if (parseInt(workerType)) {
            sender.type = parseInt(workerType);
        }
        if (reset === true) {
            sender = {
                searchVal: "",
                page: 0,
                dobae: 0,
                zangpan: 0,
                maru: 0,
                type: 0,
            };
        }
        // loadingStart();
        // console.log("sender", sender);
        workerListData
            .mutateAsync(sender)
            .then((data) => {
                // loadingEnd();
                setListData(data);
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
            });
    };

    const deleteData = () => {
        let sender = {
            workerIdx: deleteIdx,
        };
        // console.log("test..", sender);
        workerDeleteData
            .mutateAsync(sender)
            .then(({ data }) => {
                workerListApi();
                setDeletePop(false);
                toast.success("삭제되었습니다.");
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteData}
            />
            <div className="content ">
                <div className="title_container">
                    <p className="title">작업자 관리</p>
                    <button
                        className="btn btn9"
                        onClick={() => {
                            navigate(routes.workerDetail, {});
                        }}
                    >
                        등록
                    </button>
                </div>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <Input
                                className="input_text"
                                type="text"
                                placeholder="이름,연락처"
                                name="stx"
                                value={searchVal}
                                setValue={setSearchVal}
                                label="검색"
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        workerListApi();
                                    }
                                }}
                            />
                        </div>
                        <div className="ChkBoxWrap search_form">
                            <div className="title">작업</div>
                            <div className="ChkBox">
                                <div className="ChkBoxOne">
                                    <input
                                        type="checkbox"
                                        id="dobaeChk"
                                        name="dobaeChk"
                                        checked={dobaeChk}
                                        value={dobaeChk}
                                        onChange={() =>
                                            setDobaeChk(dobaeChk === 0 ? 1 : 0)
                                        }
                                    />
                                    <label htmlFor="dobaeChk">도배</label>
                                </div>
                                <div className="ChkBoxOne">
                                    <input
                                        type="checkbox"
                                        id="jangpanChk"
                                        name="jangpanChk"
                                        checked={jangpanChk}
                                        value={jangpanChk}
                                        onChange={() =>
                                            setJangpanChk(
                                                jangpanChk === 0 ? 1 : 0
                                            )
                                        }
                                    />
                                    <label htmlFor="jangpanChk">장판</label>
                                </div>
                                <div className="ChkBoxOne">
                                    <input
                                        type="checkbox"
                                        id="maruChk"
                                        name="maruChk"
                                        checked={maruChk}
                                        value={maruChk}
                                        onChange={() =>
                                            setMaruChk(maruChk === 0 ? 1 : 0)
                                        }
                                    />
                                    <label htmlFor="maruChk">마루</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search_box_wrap">
                        <InputRadioBox
                            value={workerType}
                            setValue={setWorkerType}
                            title="구분"
                            Radioes={consts.worker}
                        />
                    </div>
                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button
                                className="btn btn8"
                                onClick={() => {
                                    resetClk();
                                }}
                            >
                                초기화
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() => workerListApi()}
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>
                <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                    [검색결과 : {ListData?.data?.length}건]
                </p>
                <TableWorkerManage
                    data={ListData?.data}
                    setDeletePop={setDeletePop}
                    deletePop={deletePop}
                    setDeleteIdx={setDeleteIdx}
                />
                <div className="BottomContent">
                    <div className="ButtonContent"></div>
                    <div className="PaginationContent">
                        <Pagination
                            className="my-pagination"
                            // 현제 보고있는 페이지
                            activePage={page}
                            // 한페이지에 출력할 아이템수
                            itemsCountPerPage={20}
                            // 총 아이템수
                            totalItemsCount={ListData?.data?.length || 0}
                            // 표시할 페이지수
                            pageRangeDisplayed={10}
                            // 함수
                            onChange={handlePageChange}
                            nextPageText=""
                            prevPageText=""
                            firstPageText=""
                            lastPageText=""
                        ></Pagination>
                    </div>
                    <div className="ButtonContent">
                        <button
                            className="btn btn9"
                            onClick={() => {
                                navigate(routes.workerDetail, {});
                            }}
                        >
                            등록
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WorkerManagement;
