import { first } from "lodash";
import moment from "moment";
import { useState } from "react";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";

export function HistoryBox({
    historyLook,
    Info,
    setContentChange,
    firstDate,
    admin = "",
}) {
    const [ShowContent, setShowContent] = useState(historyLook);

    const changeContent = (idx) => {
        let Content = Info.find((info) => info.idx === idx);

        setContentChange(Content);
        toast.success("변경되었습니다.");
    };

    return (
        <div className="BorderBox">
            <div
                className="title HistoryBox"
                style={{ borderBottom: "1px solid #ccc" }}
                onClick={() => setShowContent(!ShowContent)}
            >
                <div className="left">
                    <h2>변경 히스토리</h2>
                    <span>
                        최종변경일 :{" "}
                        {Info && Info.length > 0
                            ? moment(Info[Info.length - 1]?.create_dt).format(
                                  "YYYY-MM-DD HH:mm"
                              )
                            : moment(firstDate).format("YYYY-MM-DD HH:mm")}
                    </span>
                </div>
                <div className="right">
                    <svg
                        style={{
                            transform: ShowContent
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                        }}
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                            fill="#333333"
                        />
                    </svg>
                </div>
            </div>
            {ShowContent && Info && Info.length > 0 && (
                <div className="HistoryBoxcontent">
                    {/* {children} */}
                    <div className="BorderBoxTable">
                        <table>
                            <thead>
                                <tr>
                                    <th>변경일시</th>
                                    <th>관리자명</th>
                                    <th>변경내용</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Info?.map((info, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {info.create_dt
                                                    ? moment(
                                                          info.create_dt
                                                      ).format(
                                                          "YYYY-MM-DD HH:mm"
                                                      )
                                                    : "-"}
                                            </td>
                                            <td>{info.admin_name || admin}</td>
                                            <td>
                                                <p
                                                    onClick={() =>
                                                        changeContent(info.idx)
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                        textDecoration:
                                                            "underline",
                                                        color: "blue",
                                                    }}
                                                >
                                                    내용보기
                                                </p>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
}

export function HistoryMessageBox({
    IsWorker = false,
    Info = [],
    page,
    handlePageChange,
    totalCount,
}) {
    const [ShowContent, setShowContent] = useState(true);

    return (
        <div className="BorderBox">
            <div
                className="title HistoryBox"
                style={{ borderBottom: "1px solid #ccc" }}
                onClick={() => setShowContent(!ShowContent)}
            >
                <div className="left">
                    {IsWorker ? (
                        <h1>작업자 문자 목록</h1>
                    ) : (
                        <h1>고객 발신 목록</h1>
                    )}
                </div>
                <div className="right">
                    <svg
                        style={{
                            transform: ShowContent
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                        }}
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                            fill="#333333"
                        />
                    </svg>
                </div>
            </div>
            {ShowContent && (
                <div className="HistoryBoxcontent">
                    <div className="BorderBoxTable">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ display: "10%" }}>분류</th>
                                    <th>발송 일시</th>
                                    <th>관리자명</th>
                                    <th>발송번호</th>
                                    <th>수신번호</th>
                                    <th>내용</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Info.map((info, index) => (
                                    <tr key={index}>
                                        <td>
                                            {info.sigong_type === 1
                                                ? "상담신청"
                                                : info.sigong_type === 2
                                                ? "견적상담 > 견적서발송"
                                                : info.sigong_type === 3
                                                ? "시공관리 > 예약안내"
                                                : info.sigong_type === 4
                                                ? "시공관리 > 계약금 입금여부"
                                                : info.sigong_type === 5
                                                ? "시공관리 > 최종확인서"
                                                : info.sigong_type === 6
                                                ? "시공관리 > 자재선택"
                                                : info.sigong_type === 7
                                                ? "작업지시서 > 도배"
                                                : info.sigong_type === 8
                                                ? "작업지시서 > 장판"
                                                : info.sigong_type === 9
                                                ? "작업지시서 > 마루"
                                                : info.sigong_type === 10
                                                ? "작업종료 > 입금요청"
                                                : info.sigong_type === 11
                                                ? "작업종료 > 잔금확인"
                                                : info.sigong_type === 12
                                                ? "작업지시서 > 도배 종료리포트"
                                                : info.sigong_type === 13
                                                ? "작업지시서 > 장판 종료리포트"
                                                : info.sigong_type === 14
                                                ? "작업지시서 > 도배 A/S 종료리포트"
                                                : info.sigong_type === 15
                                                ? "작업지시서 > 장판 A/S 종료리포트"
                                                : null}
                                        </td>

                                        <td>
                                            {info.create_dt
                                                ? `${moment(
                                                      info.create_dt
                                                  ).format("YYYY-MM-DD HH:mm")}`
                                                : "-"}
                                        </td>
                                        <td>{info.adm_name || "-"}</td>
                                        <td>{info.sender_phone || "-"}</td>
                                        <td>{info.get_phone || "-"}</td>
                                        <td>{info.msg_content || "-"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        className="my-pagination"
                        // 현제 보고있는 페이지
                        activePage={page}
                        // 한페이지에 출력할 아이템수
                        itemsCountPerPage={20}
                        // 총 아이템수
                        totalItemsCount={totalCount || 0}
                        // 표시할 페이지수
                        pageRangeDisplayed={10}
                        // 함수
                        onChange={handlePageChange}
                        nextPageText=""
                        prevPageText=""
                        firstPageText=""
                        lastPageText=""
                    ></Pagination>
                </div>
            )}
        </div>
    );
}
export function HistorySusinBox({
    Info = [],
    page,
    handlePageChange,
    totalCount,
}) {
    const [ShowContent, setShowContent] = useState(true);

    return (
        <div className="BorderBox">
            <div
                className="title HistoryBox"
                style={{ borderBottom: "1px solid #ccc" }}
                onClick={() => setShowContent(!ShowContent)}
            >
                <div className="left">
                    <h1>고객 수신 목록</h1>
                </div>
                <div className="right">
                    <svg
                        style={{
                            transform: ShowContent
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                        }}
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                            fill="#333333"
                        />
                    </svg>
                </div>
            </div>
            {ShowContent && (
                <div className="HistoryBoxcontent">
                    <div className="BorderBoxTable">
                        <table>
                            <thead>
                                <tr>
                                    <th>수신시간</th>
                                    <th>발신번호</th>
                                    <th>수신번호</th>
                                    <th>문자내용</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Info.map((info, index) => (
                                    <tr key={index}>
                                        <td>
                                            {moment(info.receiveTime).format(
                                                "YYYY-MM-DD HH:mm:ss"
                                            )}
                                        </td>
                                        <td>{info.customerNum}</td>
                                        <td>{info.bizNum}</td>
                                        <td>{info.message}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        className="my-pagination"
                        // 현제 보고있는 페이지
                        activePage={page}
                        // 한페이지에 출력할 아이템수
                        itemsCountPerPage={20}
                        // 총 아이템수
                        totalItemsCount={totalCount || 0}
                        // 표시할 페이지수
                        pageRangeDisplayed={10}
                        // 함수
                        onChange={handlePageChange}
                        nextPageText=""
                        prevPageText=""
                        firstPageText=""
                        lastPageText=""
                    ></Pagination>
                </div>
            )}
        </div>
    );
}
