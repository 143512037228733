import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BoxContent, BoxEditer, BoxTextInput } from "../../components/BoxInfo";
import moment from "moment";
import { useEffect, useState } from "react";
import ApiData from "../../libs/api";
import routes from "../../libs/routes";
import { usePostData } from "../../utils/service";
import { PopupComp, PopupConfirmComp } from "../../components/PopUp";
import useLoadingStore from "../../zustand/Loading";

function FAQManagementDetail() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const [day, setDay] = useState(new Date());
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [deletePop, setDeletePop] = useState(false);
    const [confirmPop, setConfirmPop] = useState(false);
    const [PopContent, setPopContent] = useState("");
    const [error, setError] = useState("");
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const FaQDetail = usePostData(ApiData.FaQDetail);
    const FaqSubmit = usePostData(ApiData.FaqSubmit);
    const FaqDelete = usePostData(ApiData.FaqDelete);

    const DeleteFn = () => {
        FaqDelete.mutateAsync({ faqIdx: index })
            .then(({ data }) => {
                // console.log(data);
                setPopContent("삭제되었습니다.");
                setConfirmPop(!confirmPop);
            })
            .catch((e) => {
                // console.log(e);
            });
    };
    const SubmitClk = () => {
        if (title === "") {
            setError("질문을 입력해주세요.");
            return;
        } else if (
            content === "<p><br></p>" ||
            content === "<p></p>" ||
            content === ""
        ) {
            setError("내용을 입력해주세요.");
            return;
        }
        setError("");
        // setPopContent("저장되었습니다.");
        // setConfirmPop(!confirmPop);
        let sender = {
            title: title,
            content: content,
        };
        if (index) {
            sender.faqIdx = index;
        }
        FaqSubmit.mutateAsync(sender)
            .then(({ data }) => {
                // console.log(data);
                setPopContent("저장되었습니다.");
                setConfirmPop(!confirmPop);
            })
            .catch((e) => {
                // console.log(e);
            });
    };

    useEffect(() => {
        if (index === null) return;
        // loadingStart();
        FaQDetail.mutateAsync({ faqIdx: index })
            .then(({ data }) => {
                // loadingEnd();
                setTitle(data.title);
                setContent(data.content);
                setDay(data.create_dt);
            })
            .catch((e) => {
                // loadingEnd();
                // console.log(e);
            });
    }, [index]);

    return (
        <>
            <PopupComp
                popTitle={"삭제하기"}
                popContent={"삭제하시겠습니까?"}
                isYes={true}
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={DeleteFn}
            />
            <PopupConfirmComp
                popContent={PopContent}
                OpenPop={confirmPop}
                setOpenPop={setConfirmPop}
                SubmitClk={() => {
                    navigate(routes.management2);
                }}
            />
            <div className="content ">
                <p className="title">FAQ 등록(수정)</p>
                <div className="BorderBox">
                    <div className="contentWrap">
                        <BoxContent
                            title="등록일자"
                            content={moment(day).format("YYYY-MM-DD HH:mm")}
                        />
                        <BoxTextInput
                            title="질문"
                            value={title}
                            setValue={setTitle}
                            inputWidth={99}
                        />
                        <BoxEditer value={content} setValue={setContent} />
                    </div>
                    {error && <p className="errorPStyle">{error}</p>}
                    <div className="BtnWrap">
                        {index ? (
                            <button
                                className="btn btn8"
                                onClick={() => {
                                    setDeletePop(!deletePop);
                                }}
                            >
                                삭제
                            </button>
                        ) : (
                            <div></div>
                        )}

                        <button className="btn btn9" onClick={SubmitClk}>
                            저장
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FAQManagementDetail;
