import {
    BoxBalancePay,
    BoxButtons,
    BoxContent,
    BoxDefect,
    BoxDepositDatePick,
    BoxLookImg,
    BoxMemoInput,
    BoxProgress,
} from "../../../components/BoxInfo";
import { HistoryBox, HistoryMessageBox } from "../../../components/HistoryBox";
import { TabComponent } from "../../../components/TabComponent";
import {
    TableEndOtherCostEdit,
    TableExpenseRegister,
} from "../../../components/TableEdit";
import {
    TableEndBaseboard,
    TableEndFlooring,
    TableEndOtherCost,
    TableEndSubsidiary,
    TableEndWallPaper,
} from "../../../components/Tables";
import images from "../../../libs/images";
import consts from "../../../libs/consts";
import routes from "../../../libs/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { PopupComp } from "../../../components/PopUp";
import { usePostData } from "../../../utils/service";
import ApiData from "../../../libs/api";
import { toast } from "react-toastify";
import { set } from "lodash";
import useLoadingStore from "../../../zustand/Loading";

let testing2 = [
    {
        other_cost_history: "기타비용",
        price: 10000,
    },
    {
        other_cost_history: "기타비용2",
        price: 20000,
    },
    {
        other_cost_history: "기타비용3",
        price: 30000,
    },
];

const img = [
    `https://via.placeholder.com/150`,
    `https://via.placeholder.com/150`,
    `https://via.placeholder.com/150`,
];
function JobFinishedRegister() {
    const navigate = useNavigate();
    const historyLook = false;
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const workEndMsgList = usePostData(ApiData.workEndMsgList);
    const workEndDetail = usePostData(ApiData.workEndDetail);
    const workEndMsg = usePostData(ApiData.workEndMsg);
    const workEndChange = usePostData(ApiData.workEndChange);
    const workEndIn = usePostData(ApiData.workEndIn);
    const [customerMsg, setCustomerMsg] = useState([]);
    const [workerMsg, setWorkerMsg] = useState([]);
    const [dobaePexpenses, setDobaePexpenses] = useState(0);
    const [dobaeMcost, setDobaeMcost] = useState(0);
    const [dobaeSubcost, setDobaeSubcost] = useState(0);
    const [dobaeAddcost, setDobaeAddcost] = useState(0);
    const [zangpanPexpenses, setZangpanPexpenses] = useState(0);
    const [zangpanAddcost, setZangpanAddcost] = useState(0);

    const [tap, setTap] = useState({});
    const [historyContent, setHistoryContent] = useState([]);
    const [admin, setAdmin] = useState("");
    const [duty, setDuty] = useState(3.3);
    const [manager, setManager] = useState("");
    const [progress, setProgress] = useState(1);
    const [contentChange, setContentChange] = useState([]);

    const [allpayment, setAllpayment] = useState(0);
    const [balancePay, setBalancePay] = useState(0);
    const [balanceMsgCount, setBalanceMsgCount] = useState(0);
    const [balanceMsgSuccessCount, setBalanceMsgSuccessCount] = useState(0);
    const [downPay, setDownPay] = useState(0);
    const [depositDate, setDepositDate] = useState("");
    const [depositRadio, setDepositRadio] = useState(0);
    const [memo, setMemo] = useState("");
    const [deletePop, setDeletePop] = useState(false);
    const [deletePopFlag, setDeletePopFlag] = useState(1);

    const [dobae, setDobae] = useState(0);
    const [dobaePrice, setDobaePrice] = useState(0);
    const [dobaeAsPrice, setDobaeAsPrice] = useState(0);
    const [dobaedefectChk, setDobaeDefectChk] = useState(false);
    const [dobaeDefectContent, setdobaeDefectContent] = useState("");
    const [dobaeDate, setDobaeDate] = useState(new Date());
    const [dobaeEstimateContent, setDobaeEstimateContent] = useState("");
    const [dobaeEndMsgCount, setDobaeEndMsgCount] = useState(0);
    const [dobaeWallPaperCnt, setDobaeWallPaperCnt] = useState(0);
    const [dobaeBanzang, setDobaeBanzang] = useState({});
    const [dobaeWorker, setDobaeWorker] = useState([]);
    const [dobaeSubmitTime, setDobaeSubmitTime] = useState("");
    const [dobaeChangeTime, setDobaeChangeTime] = useState([]);
    const [dobaeImg, setDobaeImg] = useState([]);
    const [dobaeCompleteImg, setDobaeCompleteImg] = useState([]);
    const [dobaeReciptImg, setDobaeReciptImg] = useState([]);
    const [dobaeWorkerMemo, setDobaeWorkerMemo] = useState("");
    const [dobaeExpenseDetail, setDobaeExpenseDetail] = useState([]);
    const [dobaeDeleteExpenseThing, setDobaeDeleteExpenseThing] = useState(0);
    const [dobaeDeleteExpenseList, setDobaeDeleteExpenseList] = useState([]);
    const [dobaeWallpaperlist, setDobaeWallpaperlist] = useState([]);
    const [dobaeSubList, setDobaeSubList] = useState([]);
    const [dobaeOtherCostList, setDobaeOtherCostList] = useState([]);
    const [dobaeOtherCostDeleteList, setDobaeOtherCostDeleteList] = useState(
        []
    );
    const [dobaeOtherDeleteIdx, setDobaeOtherDeleteIdx] = useState(0);
    const [zangpan, setZangpan] = useState(0);
    const [zangpanReportMsgCount, setZangpanReportMsgCount] = useState(0);
    const [zangpanPrice, setZangpanPrice] = useState(0);
    const [zangpanAsPrice, setZangpanAsPrice] = useState(0);
    const [zangpanDate, setZangpanDate] = useState(new Date());
    const [zangpanBanzang, setZangpanBanzang] = useState({});
    const [zangpanEstimateContent, setZangpanEstimateContent] = useState("");
    const [zangpanDefectChk, setZangpanDefectChk] = useState(false);
    const [zangpanDefectContent, setZangpanDefectContent] = useState("");
    const [zangpanWorker, setZangpanWorker] = useState([]);
    const [zangpanSubmitTime, setZangpanSubmitTime] = useState("");
    const [zangpanChangeTime, setZangpanChangeTime] = useState([]);
    const [zangpanImg, setZangpanImg] = useState([]);
    const [zangpanCompleteImg, setZangpanCompleteImg] = useState([]);
    const [zangpanReciptImg, setZangpanReciptImg] = useState([]);
    const [zangpanWorkerMemo, setZangpanWorkerMemo] = useState("");
    const [zangpanExpenseDetail, setZangpanExpenseDetail] = useState([]);
    const [zangpanDeleteExpenseList, setZangpanDeleteExpenseList] = useState(
        []
    );
    const [zangpanDeleteExpenseThing, setZangpanDeleteExpenseThing] =
        useState(0);
    const [zangpanList, setZangpanList] = useState([]);
    const [zangpanBaseboardList, setZangpanBaseboardList] = useState([]);
    const [zangpanOtherCostList, setZangpanOtherCostList] = useState([]);
    const [zangpanOtherDeleteIdx, setZangpanOtherDeleteIdx] = useState(0);
    const [zangpanOtherCostDeleteList, setZangpanOtherCostDeleteList] =
        useState([]);

    const [maruPrice, setMaruPrice] = useState(0);
    const [demolitionPrice, setDemolitionPrice] = useState(0);
    const [customerPaymentChk, setCustomerPaymentChk] = useState(0);
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [customMsgPage, setCustomMsgPage] = useState(1);
    const [customMsgTotal, setCustomMsgTotal] = useState(0);
    const [workerMsgPage, setWorkerMsgPage] = useState(1);
    const [workerMsgTotal, setWorkerMsgTotal] = useState(0);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const handleCustomMsgPageChange = (pageNumber) => {
        setCustomMsgPage(pageNumber);
    };
    const handleWorkerMsgPageChange = (pageNumber) => {
        setWorkerMsgPage(pageNumber);
    };
    const deleteDobaeExpenseData = () => {
        setDobaeExpenseDetail((prev) =>
            prev.filter((item, index) => {
                return index !== dobaeDeleteExpenseThing;
            })
        );
        setDobaeDeleteExpenseList((prev) => {
            return [...prev, dobaeExpenseDetail[dobaeDeleteExpenseThing].idx];
        });
    };
    const deleteOtherDobaeCostData = () => {
        setDobaeOtherCostList((prev) =>
            prev.filter((item, index) => {
                return index !== dobaeOtherDeleteIdx;
            })
        );
        setDobaeOtherCostDeleteList((prev) => {
            return [...prev, dobaeOtherCostList[dobaeOtherDeleteIdx].idx];
        });
    };
    const deleteZangpanExpenseData = () => {
        setZangpanExpenseDetail((prev) =>
            prev.filter((item, index) => {
                return index !== zangpanDeleteExpenseThing;
            })
        );
        setZangpanDeleteExpenseList((prev) => {
            return [
                ...prev,
                zangpanExpenseDetail[zangpanDeleteExpenseThing].idx,
            ];
        });
    };
    const deleteOtherZangpanCostData = () => {
        setZangpanOtherCostList((prev) =>
            prev.filter((item, index) => {
                return index !== zangpanOtherDeleteIdx;
            })
        );
        setZangpanOtherCostDeleteList((prev) => {
            return [...prev, zangpanOtherCostList[zangpanOtherDeleteIdx].idx];
        });
    };
    const ContractMsg = (type) => {
        let sender = {
            sigongInfoIdx: parseInt(index),
            msgType: type,
        };
        if (type === 1) {
            sender.balance_price =
                parseInt(dobaePrice || 0) +
                parseInt(zangpanPrice || 0) +
                parseInt(demolitionPrice || 0) +
                parseInt(maruPrice || 0) -
                (customerPaymentChk ? downPay : 0);
        }

        workEndMsg
            .mutateAsync(sender)
            .then(({ data }) => {
                MsgUpdateApi();
                workEndMsgListApi();
                toast.success("메세지를 전송했습니다.");
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const SubmitClk = () => {
        if (parseInt(depositRadio) === 1 && depositDate === null) {
            toast.error("입금일을 선택해주세요.");
            return;
        }

        let sender = {
            sigongInfoIdx: parseInt(index),
            balance_price:
                parseInt(dobaePrice || 0) +
                parseInt(zangpanPrice || 0) +
                parseInt(demolitionPrice || 0) +
                parseInt(maruPrice || 0),
            balance_paydate: moment(depositDate).format("YYYY-MM-DD"),
            balance_paydate_chk: depositRadio,
            workend_memo: memo,
            dobae_as_chk: dobaedefectChk,
            dobae_as_memo: dobaeDefectContent,
            dobaeWorkerList: dobaeExpenseDetail,
            dobaeWorkerDelete: dobaeDeleteExpenseList.filter(
                (c) => c !== undefined
            ),
            dobaeMate: dobaeWallpaperlist,
            dobaeSub: dobaeSubList,
            dobaeAdd: dobaeOtherCostList,
            dobaeAddDelete: dobaeOtherCostDeleteList.filter(
                (c) => c !== undefined
            ),
            zangpan_as_chk: zangpanDefectChk,
            zangpan_as_memo: zangpanDefectContent,
            zangpanWorkerList: zangpanExpenseDetail,
            zangpanWorkerDelete: zangpanDeleteExpenseList.filter(
                (c) => c !== undefined
            ),
            zangpanAdd: zangpanOtherCostList,
            zangpanAddDelete: zangpanOtherCostDeleteList.filter(
                (c) => c !== undefined
            ),
        };
        // console.log("sender", sender);
        if (parseInt(dobaeExpenseDetail[0]?.worker_idx) === 0) {
            toast.error("시공 관리에서 작업자를 선택해주세요.");
            return;
        }
        for (let i = 0; i < dobaeExpenseDetail.length; i++) {
            if (
                dobaeExpenseDetail[i].worker_idx === 0 ||
                dobaeExpenseDetail[i].worker_idx === null
            ) {
                toast.error("도배 작업자를 선택해주세요.");
                return;
            }
        }
        if (parseInt(zangpanExpenseDetail[0]?.worker_idx) === 0) {
            toast.error("시공 관리에서 작업자를 선택해주세요.");
            return;
        }
        for (let i = 0; i < zangpanExpenseDetail.length; i++) {
            if (
                zangpanExpenseDetail[i].worker_idx === 0 ||
                zangpanExpenseDetail[i].worker_idx === null
            ) {
                toast.error("장판 작업자를 선택해주세요.");
                return;
            }
        }
        workEndIn
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("수정되었습니다.");
                navigate(`${routes.constPreview5}?index=${index}`);
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const EndChageApi = () => {
        workEndChange
            .mutateAsync({
                sigongInfoIdx: parseInt(index),
                end_type: progress,
            })
            .then(({ data }) => {
                toast.success("수정되었습니다.");
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const MsgUpdateApi = () => {
        workEndDetail
            .mutateAsync({ sigongInfoIdx: parseInt(index) })
            .then((data) => {
                // console.log("data", data);
                setBalanceMsgCount(data?.data?.balance_msg_count);
                setBalanceMsgSuccessCount(
                    data?.data?.balance_msg_success_count
                );
                setDobaeEndMsgCount(data?.data?.dobae_report_count);
                setZangpanReportMsgCount(data?.data?.zangpan_report_count);
            })
            .catch((err) => {
                toast.error(err.response.data);
            });
    };
    function isValidJSON(json) {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }
    const workEndMsgListApi = () => {
        workEndMsgList
            .mutateAsync({
                sigongInfoIdx: index,
                customPage: customMsgPage - 1,
                workerPage: workerMsgPage - 1,
            })
            .then(({ data }) => {
                setCustomMsgTotal(data?.customerMsg.count);
                setCustomerMsg(data?.customerMsg.rows);
                setWorkerMsgTotal(data?.workerMsg.count);
                setWorkerMsg(data?.workerMsg.rows);
                // console.log("data", data);
            })
            .catch((e) => {
                toast.error(e.response.data);
                // console.log("여기입니다..", e);
            });
    };
    useEffect(() => {
        workEndMsgListApi();
    }, [customMsgPage, workerMsgPage]);
    const workEndDetailApi = () => {
        // loadingStart();
        workEndDetail
            .mutateAsync({ sigongInfoIdx: index })
            .then((data) => {
                // loadingEnd();
                // console.log(data);
                setDeliveryPrice(data?.data?.delivery_price);
                setDobae(data?.data?.dobaeOn);
                setZangpan(data?.data?.zangpanOn);
                setHistoryContent(data?.data?.history);
                setAdmin(data?.data?.admin_name);
                setProgress(data?.data?.work_end_date === null ? 1 : 2);
                setTap(data?.data?.tap);
                setDuty(data?.data?.duty);
                setManager(data?.data?.admin_name);
                setBalanceMsgCount(data?.data?.balance_msg_count);
                setBalanceMsgSuccessCount(
                    data?.data?.balance_msg_success_count
                );
                setDepositDate(data?.data?.balance_paydate);
                setDepositRadio(data?.data?.balance_paydate === null ? 0 : 1);
                setMemo(data?.data?.workend_memo);
                setAllpayment(data?.data?.balance_price);
                setDownPay(data?.data?.customer_payment);
                setDobaePrice(data?.data?.dobaePrice);
                setDobaeAsPrice(data?.data?.dobaeAsPrice);
                setDobaeBanzang(data?.data?.banzangDobaeWorker);
                setDobaeDate(data?.data?.dobae?.dobae_date);
                setDobaeEndMsgCount(data?.data?.dobae_report_count);
                setDobaeEstimateContent(data?.data?.dobae.dobae_product);
                setDobaeWallPaperCnt(data?.data?.dobae?.dobae_cnt);
                setdobaeDefectContent(data?.data?.dobae?.dobae_as_memo);
                setDobaeDefectChk(data?.data?.dobae?.dobae_as_chk);
                setDobaeWorker(data?.data?.dobaeWorkerList);
                setDobaeExpenseDetail(data?.data?.dobaeWorkerList);

                setDobaeSubmitTime(data?.data?.dobaeReport?.create_dt);
                setDobaeChangeTime(data?.data?.dobaeReport?.reportLogList);
                setDobaeImg(
                    isValidJSON(data?.data?.dobaeReport?.content)
                        ? JSON.parse(data?.data?.dobaeReport?.content)
                        : null
                );
                setDobaeWorkerMemo(data?.data?.dobaeReport?.worker_memo);
                setDobaeWallpaperlist(data?.data?.dobaeMate);
                setDobaeSubList(data?.data?.dobaeSub);
                setDobaeOtherCostList(data?.data?.dobaeAdd);
                setZangpanReportMsgCount(data?.data?.zangpan_report_count);
                setZangpanPrice(data?.data?.zangpanPrice);
                setZangpanAsPrice(data?.data?.zangpanAsPrice);
                setZangpanDate(data?.data?.zangpan?.zangpan_date);
                setZangpanBanzang(data?.data?.banzangZangpanWorker);
                setZangpanWorker(data?.data?.zangpanWorkerList);
                setZangpanExpenseDetail(data?.data?.zangpanWorkerList);
                setZangpanEstimateContent(data?.data?.zangpan?.zangpan_mm);
                setZangpanDefectContent(data?.data?.zangpan?.zangpan_as_memo);
                setZangpanDefectChk(data?.data?.zangpan?.zangpan_as_chk);
                setZangpanSubmitTime(data?.data?.zangpanReport?.create_dt);
                setZangpanChangeTime(data?.data?.zangpanReport?.reportLogList);
                setZangpanImg(
                    isValidJSON(data?.data?.zangpanReport?.content)
                        ? JSON.parse(data?.data?.zangpanReport?.content)
                        : null
                );
                setZangpanList(data?.data?.zangpanMate);
                setZangpanBaseboardList(data?.data?.zangpanSub);
                setZangpanWorkerMemo(data?.data?.zangpanReport?.worker_memo);
                setZangpanOtherCostList(data?.data?.zangpanAdd);
                setMaruPrice(data?.data?.maruPrice);
                setDemolitionPrice(data?.data?.demolitionPrice);
                setCustomerPaymentChk(data?.data?.customerPaymentChk);
            })
            .catch((err) => {
                // loadingEnd();
                // console.log(err);
                toast.error(err.response.data);
                navigate(routes.constr5);
            });
    };
    useEffect(() => {
        dobaeImg?.map((c) => {
            if (c.type === 1) {
                setDobaeCompleteImg((prev) => [...prev, c]);
            } else {
                setDobaeReciptImg((prev) => [...prev, c]);
            }
        });
        return () => {
            setDobaeCompleteImg([]);
            setDobaeReciptImg([]);
        };
    }, [dobaeImg]);

    useEffect(() => {
        zangpanImg?.map((c) => {
            if (c.type === 1) {
                setZangpanCompleteImg((prev) => [...prev, c]);
            } else {
                setZangpanReciptImg((prev) => [...prev, c]);
            }
        });
        return () => {
            setZangpanCompleteImg([]);
            setZangpanReciptImg([]);
        };
    }, [zangpanImg]);
    useEffect(() => {
        workEndDetailApi();
        workEndMsgListApi();
    }, [index]);
    useEffect(() => {
        if (contentChange) {
            setDepositDate(contentChange?.balance_paydate);
            setAllpayment(contentChange?.balance_price);
            setDepositRadio(contentChange?.balance_paydate === null ? 0 : 1);
            setMemo(contentChange?.workend_memo);
            setdobaeDefectContent(contentChange?.dobae_as_memo);
            setDobaeDefectChk(contentChange?.dobae_as_chk);
            setZangpanDefectChk(contentChange?.zangpan_as_chk);
            setZangpanDefectContent(contentChange?.zangpan_as_memo);
            if (contentChange?.end_expense_json) {
                setDobaeExpenseDetail(
                    JSON.parse(contentChange?.end_expense_json).filter((c) => {
                        return c.sigong_type === 1;
                    })
                );
                setZangpanExpenseDetail(
                    JSON.parse(contentChange?.end_expense_json).filter((c) => {
                        return c.sigong_type === 2;
                    })
                );
            }
            if (contentChange?.order_mate_json) {
                setDobaeWallpaperlist(
                    JSON.parse(contentChange?.order_mate_json).filter((c) => {
                        return c.material_type === 1;
                    })
                );
                setDobaeSubList(
                    JSON.parse(contentChange?.order_mate_json).filter((c) => {
                        return c.material_type === 5;
                    })
                );
            }
            if (contentChange?.end_other_json) {
                setDobaeOtherCostList(
                    JSON.parse(contentChange?.end_other_json).filter((c) => {
                        return c.sigong_type === 1;
                    })
                );
                setZangpanOtherCostList(
                    JSON.parse(contentChange?.end_other_json).filter((c) => {
                        return c.sigong_type === 2;
                    })
                );
            }
        }
    }, [contentChange]);
    const OpenUrl = ({ SendType, idx, type }) => {
        if (SendType === 4) {
            window.open(
                `https://worker.8amdobae.co.kr/WorkOrder?sigongIdx=${idx}&sigongType=${type}&Looking=true`
            );
        }
    };

    useEffect(() => {
        setDobaePexpenses(
            dobaeExpenseDetail?.reduce((acc, cur) => {
                return (
                    acc + (cur.personnel_expenses || 0) + (cur.meal_price || 0)
                );
            }, 0)
        );
        setDobaeMcost(
            dobaeWallpaperlist?.reduce((acc, cur) => {
                return (
                    acc +
                    ((cur.order_count || 0) +
                        (cur.inventory_count || 0) -
                        (cur.leftover_count || 0)) *
                        (cur.order_price || 0)
                );
            }, 0)
        );
        setDobaeSubcost(
            dobaeSubList?.reduce((acc, cur) => {
                return (
                    acc +
                    ((cur.count || 0) - (cur.leftover_count || 0)) *
                        (cur.order_price || 0)
                );
            }, 0)
        );
        setDobaeAddcost(
            dobaeOtherCostList?.reduce((acc, cur) => {
                return acc + (cur.price || 0);
            }, 0)
        );
        setZangpanPexpenses(
            zangpanExpenseDetail?.reduce((acc, cur) => {
                return (
                    acc + (cur.personnel_expenses || 0) + (cur.meal_price || 0)
                );
            }, 0)
        );
        setZangpanAddcost(
            zangpanOtherCostList?.reduce((acc, cur) => {
                return acc + (cur.price || 0);
            }, 0)
        );
        // console.log("dobaeExpenseDetail", dobaeExpenseDetail);
    }, [
        dobaeExpenseDetail,
        zangpanExpenseDetail,
        dobaeOtherCostList,
        zangpanOtherCostList,
        dobaeWallpaperlist,
        dobaeSubList,
    ]);

    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={
                    deletePopFlag === 1
                        ? deleteDobaeExpenseData
                        : deletePopFlag === 2
                        ? deleteOtherDobaeCostData
                        : deletePopFlag === 3
                        ? deleteZangpanExpenseData
                        : deletePopFlag === 4
                        ? deleteOtherZangpanCostData
                        : null
                }
            />
            <div className="content ">
                <TabComponent
                    index={tap?.counseling_idx}
                    estimate_idx={tap?.estimate_idx}
                    sigonginfo_idx={tap?.sigonginfo_idx}
                    order_idx={tap?.order_idx}
                    workend_idx={tap?.sigonginfo_idx}
                    as_tbl={tap?.as_tbl}
                />
                <div className="animate__animated animate__fadeIn">
                    <BoxProgress
                        Radioes={consts.status2}
                        manager={manager}
                        value={progress}
                        setValue={setProgress}
                        RadioName={"progress"}
                        onClk={EndChageApi}
                    />
                    <HistoryBox
                        Info={historyContent}
                        historyLook={historyLook}
                        admin={admin}
                        setContentChange={setContentChange}
                    />
                    <div className="TableBox">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>합계</th>
                                    <th>도배</th>
                                    <th>장판</th>
                                    <th>마루</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>인건비</td>
                                    <td>
                                        {(
                                            (dobaePexpenses || 0) +
                                            (zangpanPexpenses || 0)
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {(dobaePexpenses || 0).toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {(
                                            zangpanPexpenses || 0
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>0원</td>
                                </tr>
                                <tr>
                                    <td>자재</td>
                                    <td>
                                        {" "}
                                        {(dobaeMcost || 0).toLocaleString()}원
                                    </td>
                                    <td>
                                        {(dobaeMcost || 0).toLocaleString()}원
                                    </td>
                                    <td>
                                        {" "}
                                        {/* {(
                                            zangpanPrice?.material_cost || 0
                                        ).toLocaleString()} */}
                                        0원
                                    </td>
                                    <td>0원</td>
                                </tr>
                                <tr>
                                    <td>자재 배송비</td>
                                    <td>
                                        {" "}
                                        {parseInt(
                                            deliveryPrice || 0
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {" "}
                                        {parseInt(
                                            deliveryPrice || 0
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>0원</td>
                                    <td>0원</td>
                                </tr>
                                <tr>
                                    <td>부자재</td>
                                    <td>
                                        {" "}
                                        {parseInt(dobaeSubcost || 0)
                                            // +
                                            // parseInt(
                                            //     zangpanPrice?.sub_material_cost ||
                                            //         0
                                            // )
                                            .toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {" "}
                                        {parseInt(dobaeSubcost || 0)
                                            // +
                                            // parseInt(
                                            //     zangpanPrice?.sub_material_cost ||
                                            //         0
                                            // )
                                            .toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {/* {(
                                            zangpanPrice?.sub_material_cost || 0
                                        ).toLocaleString()} */}
                                        0원
                                    </td>
                                    <td>0원</td>
                                </tr>

                                <tr>
                                    <td>추가비용</td>
                                    <td>
                                        {" "}
                                        {(
                                            parseInt(dobaeAddcost || 0) +
                                            parseInt(zangpanAddcost || 0)
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {" "}
                                        {parseInt(
                                            dobaeAddcost || 0
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {parseInt(
                                            zangpanAddcost || 0
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>0원</td>
                                </tr>
                                <tr>
                                    <td>A/S비용</td>
                                    <td>
                                        {" "}
                                        {(
                                            parseInt(dobaeAsPrice || 0) +
                                            parseInt(zangpanAsPrice || 0)
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {(dobaeAsPrice || 0).toLocaleString()}원
                                    </td>
                                    <td>
                                        {(zangpanAsPrice || 0).toLocaleString()}
                                        원
                                    </td>
                                    <td>0원</td>
                                </tr>
                                <tr>
                                    <td>비용합계</td>
                                    <td>
                                        {" "}
                                        {(
                                            (dobaePexpenses || 0) +
                                            (dobaeMcost || 0) +
                                            (dobaeSubcost || 0) +
                                            (dobaeAddcost || 0) +
                                            (dobaeAsPrice || 0) +
                                            (zangpanPexpenses || 0) +
                                            (zangpanAddcost || 0) +
                                            (zangpanAsPrice || 0)
                                        ).toLocaleString()}{" "}
                                        원
                                    </td>
                                    <td>
                                        {(
                                            (dobaePexpenses || 0) +
                                            (dobaeMcost || 0) +
                                            (dobaeSubcost || 0) +
                                            (dobaeAddcost || 0) +
                                            (dobaeAsPrice || 0)
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {" "}
                                        {(
                                            (zangpanPexpenses || 0) +
                                            (zangpanAddcost || 0) +
                                            (zangpanAsPrice || 0)
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>0원</td>
                                </tr>
                                <tr>
                                    <td>매출</td>
                                    <td>
                                        {(
                                            parseInt(dobaePrice || 0) +
                                            parseInt(zangpanPrice || 0)
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {" "}
                                        {(dobaePrice || 0).toLocaleString()}원
                                    </td>
                                    <td>
                                        {(zangpanPrice || 0).toLocaleString()}원
                                    </td>
                                    <td>0원</td>
                                </tr>
                                <tr>
                                    <td>이윤</td>
                                    <td>
                                        {" "}
                                        {(
                                            parseInt(dobaePrice || 0) -
                                            ((dobaePexpenses || 0) +
                                                (dobaeMcost || 0) +
                                                (dobaeSubcost || 0) +
                                                (dobaeAddcost || 0) +
                                                (dobaeAsPrice || 0)) +
                                            parseInt(zangpanPrice || 0) -
                                            ((zangpanPexpenses || 0) +
                                                (zangpanAddcost || 0)) +
                                            parseInt(zangpanAsPrice || 0)
                                        )
                                            // +
                                            // parseInt(zangpanPrice?.sales || 0) -
                                            // parseInt(
                                            //     zangpanPrice?.personnel_expenses ||
                                            //         0
                                            // ) +
                                            // parseInt(
                                            //     zangpanPrice?.material_cost || 0
                                            // ) +
                                            // parseInt(
                                            //     zangpanPrice?.sub_material_cost ||
                                            //         0
                                            // ) +
                                            // parseInt(
                                            //     zangpanPrice?.add_cost || 0
                                            // ) +
                                            // parseInt(zangpanPrice?.as_cost || 0)
                                            .toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {" "}
                                        {(
                                            parseInt(dobaePrice || 0) -
                                            ((dobaePexpenses || 0) +
                                                (dobaeMcost || 0) +
                                                (dobaeSubcost || 0) +
                                                (dobaeAddcost || 0) +
                                                (dobaeAsPrice || 0))
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {" "}
                                        {(
                                            parseInt(zangpanPrice || 0) -
                                            ((zangpanPexpenses || 0) +
                                                (zangpanAddcost || 0) +
                                                (zangpanAsPrice || 0))
                                        ).toLocaleString()}
                                        원
                                    </td>
                                    <td>0원</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="contentBox">
                        <BoxContent
                            title="총금액"
                            content={` ${(
                                parseInt(dobaePrice || 0) +
                                parseInt(zangpanPrice || 0) +
                                parseInt(demolitionPrice || 0) +
                                parseInt(maruPrice || 0)
                            ).toLocaleString()}
                            원`}
                        />
                        <BoxBalancePay
                            downPay={downPay}
                            balancePay={
                                parseInt(dobaePrice || 0) +
                                parseInt(zangpanPrice || 0) +
                                parseInt(demolitionPrice || 0) +
                                parseInt(maruPrice || 0) -
                                (customerPaymentChk ? downPay : 0)
                            }
                            readOnly={true}
                        />
                        <BoxButtons
                            title="입금요청"
                            bbtn={true}
                            bbtnNum={balanceMsgCount}
                            bbtnClk={() => ContractMsg(1)}
                        />
                        <BoxButtons
                            title="잔금확인"
                            bbtn={true}
                            bbtnNum={balanceMsgSuccessCount}
                            bbtnClk={() => ContractMsg(2)}
                        />
                        <BoxDepositDatePick
                            title={"입금일"}
                            day={depositDate}
                            setDay={setDepositDate}
                            radio={depositRadio}
                            setRadio={setDepositRadio}
                        />
                        <BoxMemoInput value={memo} setValue={setMemo} />

                        {/* 도배 */}
                        {dobae === 1 && (
                            <div className="title_color_box">
                                <div
                                    className="box_title"
                                    style={{
                                        backgroundColor: "#41b06e",
                                        color: "white",
                                    }}
                                >
                                    <h1>도배</h1>
                                </div>
                                <div className="contentColorWrap">
                                    <div className="contentBorderWrap">
                                        <BoxContent
                                            title="시공일"
                                            content={
                                                dobaeDate
                                                    ? `${moment(
                                                          dobaeDate
                                                      ).format("YYYY-MM-DD")}(${
                                                          consts.dayText[
                                                              moment(
                                                                  dobaeDate
                                                              ).day()
                                                          ]
                                                      })`
                                                    : "-"
                                            }
                                        />
                                        <BoxContent
                                            title="견적내용"
                                            content={
                                                `${
                                                    dobaeEstimateContent === 1
                                                        ? "실크도배"
                                                        : dobaeEstimateContent ===
                                                          2
                                                        ? "합지도배"
                                                        : dobaeEstimateContent ===
                                                          3
                                                        ? "혼합도배"
                                                        : "-"
                                                }, 벽지수 : ${
                                                    dobaeWallPaperCnt || 0
                                                }` || "-"
                                            }
                                        />
                                        <BoxButtons
                                            title="종료리포트"
                                            gbtn={true}
                                            bbtn={true}
                                            OpenUrl={() =>
                                                OpenUrl({
                                                    SendType: 4,
                                                    idx: tap.idx,
                                                    type: 1,
                                                })
                                            }
                                            bbtnNum={dobaeEndMsgCount || 0}
                                            bbtnClk={() => ContractMsg(3)}
                                        />
                                        <BoxDefect
                                            value={dobaeDefectContent}
                                            setValue={setdobaeDefectContent}
                                            chkbtn={dobaedefectChk}
                                            setChkbtn={setDobaeDefectChk}
                                            id={"dobaedefectChk"}
                                        />
                                    </div>
                                </div>
                                <div className="contentColorWrap">
                                    <div className="subTitleCircle">
                                        <div className="GreenCircle"></div>
                                        <h3>지출내역</h3>
                                    </div>
                                    <TableExpenseRegister
                                        list={dobaeExpenseDetail}
                                        setList={setDobaeExpenseDetail}
                                        setDeleteidx={
                                            setDobaeDeleteExpenseThing
                                        }
                                        setDeletePop={setDeletePop}
                                        setDeletePopFlag={setDeletePopFlag}
                                        banzang={dobaeBanzang}
                                        flagNum={1}
                                        duty={duty}
                                    />
                                </div>
                                <div className="contentColorWrap">
                                    <div className="subTitleCircle">
                                        <div className="GreenCircle"></div>
                                        <h3>작업리포트</h3>
                                    </div>
                                    <div className="contentBorderWrap">
                                        <BoxContent
                                            title="작업자"
                                            content={`${
                                                dobaeWorker?.length
                                            }명 (${dobaeWorker
                                                ?.map((c) => c.worker_name)
                                                .join(", ")})`}
                                        />
                                        <BoxContent
                                            title="제출시간"
                                            content={
                                                dobaeSubmitTime
                                                    ? `${moment(
                                                          dobaeSubmitTime
                                                      ).format(
                                                          "YYYY-MM-DD HH:mm"
                                                      )}${
                                                          dobaeChangeTime &&
                                                          dobaeChangeTime.length >
                                                              0
                                                              ? `(변경시간 : ${dobaeChangeTime.map(
                                                                    (c) => {
                                                                        return `${" "}${moment(
                                                                            c
                                                                        ).format(
                                                                            "YYYY-MM-DD HH:mm"
                                                                        )}`;
                                                                    }
                                                                )})`
                                                              : ""
                                                      }`
                                                    : "-"
                                            }
                                        />
                                        <BoxLookImg
                                            title="완료 사진"
                                            img={dobaeCompleteImg}
                                        />
                                        <BoxLookImg
                                            title="영수증 사진"
                                            img={dobaeReciptImg}
                                        />
                                        <BoxMemoInput
                                            title="작업자 메모"
                                            value={dobaeWorkerMemo}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="subTitleCircle">
                                        <div className="GreenCircle"></div>
                                        <h3>벽지</h3>
                                    </div>
                                    <TableEndWallPaper
                                        list={dobaeWallpaperlist}
                                        setList={setDobaeWallpaperlist}
                                        isASmanage={false}
                                    />
                                    <div className="subTitleCircle">
                                        <div className="GreenCircle"></div>
                                        <h3>부자재</h3>
                                    </div>
                                    <TableEndSubsidiary
                                        list={dobaeSubList}
                                        setList={setDobaeSubList}
                                    />
                                    <div className="subTitleCircle">
                                        <div className="GreenCircle"></div>
                                        <h3>기타비용</h3>
                                    </div>
                                    <TableEndOtherCostEdit
                                        list={dobaeOtherCostList}
                                        setList={setDobaeOtherCostList}
                                        setDeleteidx={setDobaeOtherDeleteIdx}
                                        setDeletePop={setDeletePop}
                                        setDeletePopFlag={setDeletePopFlag}
                                        flagNum={2}
                                    />
                                </div>
                            </div>
                        )}
                        {zangpan === 1 && (
                            <div className="title_color_box">
                                <div
                                    className="box_title"
                                    style={{
                                        backgroundColor: "#fb8500",
                                        color: "white",
                                    }}
                                >
                                    <h1>장판</h1>
                                </div>
                                <div className="contentColorWrap">
                                    <div className="contentBorderWrap">
                                        <BoxContent
                                            title="시공일"
                                            content={
                                                zangpanDate
                                                    ? `${moment(
                                                          zangpanDate
                                                      ).format("YYYY-MM-DD")}(${
                                                          consts.dayText[
                                                              moment(
                                                                  zangpanDate
                                                              ).day()
                                                          ]
                                                      })`
                                                    : "-"
                                            }
                                        />
                                        <BoxContent
                                            title="견적내용"
                                            content={
                                                zangpanEstimateContent || "-"
                                            }
                                        />
                                        <BoxButtons
                                            title="종료리포트"
                                            gbtn={true}
                                            bbtn={true}
                                            OpenUrl={() =>
                                                OpenUrl({
                                                    SendType: 4,
                                                    idx: tap.idx,
                                                    type: 2,
                                                })
                                            }
                                            bbtnNum={zangpanReportMsgCount || 0}
                                            bbtnClk={() => ContractMsg(4)}
                                        />
                                        <BoxDefect
                                            value={zangpanDefectContent}
                                            setValue={setZangpanDefectContent}
                                            chkbtn={zangpanDefectChk}
                                            setChkbtn={setZangpanDefectChk}
                                            id={"zangpanDefectChk"}
                                        />
                                    </div>
                                </div>
                                <div className="contentColorWrap">
                                    <div className="subTitleCircle">
                                        <div className="GreenCircle"></div>
                                        <h3>작업리포트</h3>
                                    </div>
                                    <div className="contentBorderWrap">
                                        <BoxContent
                                            title="작업자"
                                            content={`${
                                                zangpanWorker?.length
                                            }명 (${zangpanWorker
                                                ?.map((c) => c.worker_name)
                                                .join(", ")})`}
                                        />
                                        <BoxContent
                                            title="제출시간"
                                            content={
                                                zangpanSubmitTime
                                                    ? `${moment(
                                                          zangpanSubmitTime
                                                      ).format(
                                                          "YYYY-MM-DD HH:mm"
                                                      )}${
                                                          zangpanChangeTime &&
                                                          zangpanChangeTime.length >
                                                              0
                                                              ? `(변경시간 : ${zangpanChangeTime.map(
                                                                    (c) => {
                                                                        return `${" "}${moment(
                                                                            c
                                                                        ).format(
                                                                            "YYYY-MM-DD HH:mm"
                                                                        )}`;
                                                                    }
                                                                )})`
                                                              : ""
                                                      }`
                                                    : "-"
                                            }
                                        />
                                        <BoxLookImg
                                            title="완료 사진"
                                            img={zangpanCompleteImg}
                                        />
                                        <BoxLookImg
                                            title="영수증 사진"
                                            img={zangpanReciptImg}
                                        />
                                        <BoxMemoInput
                                            title="작업자 메모"
                                            value={zangpanWorkerMemo}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="contentColorWrap">
                                    <span
                                        style={{
                                            width: 10,
                                            height: 10,
                                            backgroundColor: "red",
                                        }}
                                    ></span>
                                    <div className="subTitleCircle">
                                        <div className="GreenCircle"></div>
                                        <h3>지출내역</h3>
                                    </div>
                                    <TableExpenseRegister
                                        list={zangpanExpenseDetail}
                                        setList={setZangpanExpenseDetail}
                                        setDeleteidx={
                                            setZangpanDeleteExpenseThing
                                        }
                                        setDeletePop={setDeletePop}
                                        setDeletePopFlag={setDeletePopFlag}
                                        flagNum={3}
                                        duty={duty}
                                        banzang={zangpanBanzang}
                                    />
                                </div>
                                {/* <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>작업리포트</h3>
                                </div>
                                <div className="contentBorderWrap">
                                    <BoxContent
                                        title="작업자"
                                        content={`${
                                            zangpanWorker?.length
                                        }명 (${zangpanWorker
                                            ?.map((c) => c.worker_name)
                                            .join(", ")})`}
                                    />
                                    <BoxContent
                                        title="제출시간"
                                        content={
                                            zangpanSubmitTime
                                                ? `${moment(
                                                      zangpanSubmitTime
                                                  ).format(
                                                      "YYYY-MM-DD HH:mm"
                                                  )}(변경시간 : ${zangpanChangeTime.map(
                                                      (c) => {
                                                          return `${" "}${moment(
                                                              c
                                                          ).format(
                                                              "YYYY-MM-DD HH:mm"
                                                          )}`;
                                                      }
                                                  )})`
                                                : "-"
                                        }
                                    />
                                    <BoxLookImg
                                        title="완료 사진"
                                        img={zangpanCompleteImg}
                                    />
                                    <BoxLookImg
                                        title="영수증 사진"
                                        img={zangpanReciptImg}
                                    />
                                    <BoxContent
                                        title="작업자 메모"
                                        content={zangpanWorkerMemo || "-"}
                                    />
                                </div>
                            </div> */}
                                {/* <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>장판</h3>
                                </div>
                                <TableEndFlooring
                                    list={zangpanList}
                                    setList={setZangpanList}
                                />
                            </div>
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>걸래받이</h3>
                                </div>
                                <TableEndBaseboard
                                    list={zangpanBaseboardList}
                                    setList={setZangpanBaseboardList}
                                />
                            </div> */}
                                <div className="contentColorWrap">
                                    <div className="subTitleCircle">
                                        <div className="GreenCircle"></div>
                                        <h3>기타비용</h3>
                                    </div>
                                    <TableEndOtherCostEdit
                                        list={zangpanOtherCostList}
                                        setList={setZangpanOtherCostList}
                                        setDeleteidx={setZangpanOtherDeleteIdx}
                                        setDeletePop={setDeletePop}
                                        setDeletePopFlag={setDeletePopFlag}
                                        flagNum={4}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="BtnEnd" onClick={SubmitClk}>
                        <button className="btn btn9">저장</button>
                    </div>
                </div>
                <HistoryMessageBox
                    Info={customerMsg}
                    page={customMsgPage}
                    handlePageChange={handleCustomMsgPageChange}
                    totalCount={customMsgTotal}
                />
                <HistoryMessageBox
                    IsWorker={true}
                    Info={workerMsg}
                    page={workerMsgPage}
                    handlePageChange={handleWorkerMsgPageChange}
                    totalCount={workerMsgTotal}
                />
            </div>
        </>
    );
}

export default JobFinishedRegister;
