import React, { useEffect, useState } from "react";

export default function Button({
    type = 1,
    title,
    onClick = () => {},
    disable,
    round,
    image,
    style,
    imgStyle,
}) {
    return (
        <button
            style={style}
            type="button"
            className={`btn btn${type} ${round ? "btn_round" : ""} ${
                disable ?? ""
            }`}
            onClick={onClick}
        >
            {image ? <img src={image} style={imgStyle} /> : <></>}
            {title}
        </button>
    );
}
