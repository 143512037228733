import { useEffect, useState } from "react";
import InputSelect from "../../components/InputSelect";
import moment from "moment";
import consts from "../../libs/consts";
import { useGetData, usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import useLoadingStore from "../../zustand/Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import routes from "../../libs/routes";
const testing = [
    { title: "입금" },
    { title: "배정" },
    { title: "자재" },
    { title: "작업" },
    { title: "최종" },
    { title: "배송" },
];
function ConstrCalendar() {
    const { data: workerListApi } = useGetData(ApiData.workerList);
    const sigongCalendar = usePostData(ApiData.sigongCalendar);
    const navigate = useNavigate();
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const [worker, setWorker] = useState(0);
    const [workerList, setWorkerList] = useState([{ value: 0, text: "전체" }]);
    const [checkboxVal, setCheckboxVal] = useState([1, 2, 3, 4]);
    const [currentDate, setCurrentDate] = useState(moment());
    const [weekDates, setWeekDates] = useState(getWeekDates(currentDate));
    const [claendarList, setCalendarList] = useState([]);

    const [ClkIdx, setClkIdx] = useState(0);
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");

    const formattedStartOfWeek = `${startOfWeek.format(
        "M/D"
    )}(${convertDayToKorean(startOfWeek.day())})`;
    const formattedEndOfWeek = `${endOfWeek.format("M/D")}(${convertDayToKorean(
        endOfWeek.day()
    )})`;

    const sigongCalendarApi = () => {
        let sender = {
            start_date: startOfWeek.format("YYYY-MM-DD"),
            worker_idx: worker === 0 ? "" : worker,
        };
        if (checkboxVal.length !== 0) {
            sender.sigong_type = checkboxVal?.map((c) => parseInt(c));
        }

        // console.log("sender", sender);
        // loadingStart();
        sigongCalendar
            .mutateAsync(sender)
            .then(({ data }) => {
                // loadingEnd();
                // console.log("data", data);
                setCalendarList(data);
            })
            .catch((e) => {
                toast.error(e.response.data);
                // loadingEnd();
            });
    };
    const handlePrev = () => {
        const newDate = currentDate.clone().subtract(1, "week");
        setCurrentDate(newDate);
        setWeekDates(getWeekDates(newDate));
    };

    const handleNext = () => {
        const newDate = currentDate.clone().add(1, "week");
        setCurrentDate(newDate);
        setWeekDates(getWeekDates(newDate));
    };
    function getWeekDates(date) {
        const weekStart = date.clone().startOf("week");
        const dates = Array(7)
            .fill(0)
            .map((_, i) => {
                const day = weekStart.clone().add(i, "days");
                return `${day.format("M/D")}(${convertDayToKorean(day.day())})`;
            });
        return dates;
    }
    const handleChange = (e) => {
        const value = parseInt(e.target.value);
        if (e.target.checked) {
            setCheckboxVal([...checkboxVal, value]);
        } else {
            setCheckboxVal(checkboxVal.filter((v) => v !== value));
        }
    };

    function convertDayToKorean(day) {
        const daysInKorean = ["일", "월", "화", "수", "목", "금", "토"];
        return daysInKorean[day];
    }
    const CalendarItem = ({ item, borderColor }) => (
        <div
            className="calendarDayWrap"
            style={{ cursor: "pointer" }}
            onClick={() => {
                navigate(`${routes.constPreview3}?index=${item.sigongIdx}`);
            }}
        >
            <div
                style={{
                    border: `2px solid ${borderColor}`,
                }}
                className="line"
            />
            <div className="work">
                {parseInt(item.payment_chk) === 0 && (
                    <div
                        style={{
                            background: "#d9534f",
                            borderRadius: "2px",
                        }}
                    >
                        입금
                    </div>
                )}
                {parseInt(item.worker_chk) === 0 && (
                    <div
                        style={{
                            background: "#3078B6",
                            borderRadius: "2px",
                        }}
                    >
                        배정
                    </div>
                )}
                {parseInt(item.work_order) === 0 && (
                    <div
                        style={{
                            background: "#41B06E",
                            borderRadius: "2px",
                        }}
                    >
                        작업
                    </div>
                )}
                {parseInt(item.material_chk) === 0 && (
                    <div
                        style={{
                            background: "#F0B053",
                            borderRadius: "2px",
                        }}
                    >
                        자재
                    </div>
                )}
                {parseInt(item.delivery) === 0 && (
                    <div
                        style={{
                            background: "#ccc",
                            borderRadius: "2px",
                        }}
                    >
                        배송
                    </div>
                )}
                {parseInt(item.final_chk) === 0 && (
                    <div
                        style={{
                            background: "#5BC0DE",
                            borderRadius: "2px",
                        }}
                    >
                        최종
                    </div>
                )}
                {parseInt(item.payment_chk) === 1 &&
                    parseInt(item.worker_chk) === 1 &&
                    parseInt(item.work_order) === 1 &&
                    parseInt(item.material_chk) === 1 &&
                    parseInt(item.final_chk) === 1 && (
                        <div
                            style={{
                                minHeight: "20px",
                            }}
                        ></div>
                    )}
            </div>
            <div className="info">
                <p>{item.banzang_name}</p>
                <p>{item.mb_phone.slice(7)}</p>
            </div>
        </div>
    );

    useEffect(() => {
        workerListApi?.data?.map((c) => {
            setWorkerList((prev) => [...prev, { value: c.idx, text: c.name }]);
        });
        return () => {
            setWorkerList([{ value: 0, text: "전체" }]);
        };
    }, [workerListApi]);

    useEffect(() => {
        sigongCalendarApi();
    }, [worker, checkboxVal, weekDates]);
    return (
        <div className="content ">
            <p className="title">시공캘린더</p>
            <div className="CalenderBox">
                <div className="CalenderBoxWrap">
                    <InputSelect
                        name="status"
                        // label="작업지시서"
                        value={worker}
                        setValue={setWorker}
                        option={workerList}
                    />
                </div>
                <div style={{ width: "40%" }} className="CalenderBoxWrap">
                    <div className="ChkBox">
                        {consts.constructionBox6.map((x, i) => (
                            <div key={i} className="ChkBoxOne">
                                <input
                                    checked={checkboxVal
                                        .map((c) => parseInt(c))
                                        .includes(x.value)}
                                    type="checkbox"
                                    id={x.label}
                                    name={x.label}
                                    value={x.value}
                                    onChange={handleChange}
                                />
                                <label htmlFor={x.label}>{x.label}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="WeekDate">
                <p>{`${formattedStartOfWeek} - ${formattedEndOfWeek}`}</p>
                <button onClick={handlePrev}>이전</button>
                <button onClick={handleNext}>다음</button>
            </div>
            <div>
                <p style={{ fontSize: "18px", marginBottom: "10px" }}>합계</p>
                <div className="table_scroll">
                    <table>
                        <thead>
                            <tr>
                                {weekDates.map((item, index) => {
                                    return <th key={index}>{item}</th>;
                                })}
                            </tr>
                            <tr>
                                {claendarList?.map((item, index) => {
                                    return (
                                        <th key={index}>
                                            {item.dobae?.length +
                                                item.zangpan?.length +
                                                item.maru?.length +
                                                item.as?.length}
                                            건
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody className="calendar">
                            <tr>
                                {claendarList?.map((item, index) => (
                                    <td key={index}>
                                        {item.dobae?.map((x, i) => (
                                            <CalendarItem
                                                item={x}
                                                key={i}
                                                borderColor="#41b06e"
                                            />
                                        ))}
                                        {item.zangpan?.map((x, i) => (
                                            <CalendarItem
                                                item={x}
                                                key={i}
                                                borderColor="#F0B053"
                                            />
                                        ))}
                                        {item.maru?.map((x, i) => (
                                            <CalendarItem
                                                item={x}
                                                key={i}
                                                borderColor="#5BC0DE"
                                            />
                                        ))}
                                        {item.as?.map((x, i) => (
                                            <CalendarItem
                                                item={x}
                                                key={i}
                                                borderColor="#d9534f"
                                            />
                                        ))}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
export default ConstrCalendar;
