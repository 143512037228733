import { useEffect, useState } from "react";
import {
    BoxArrayContent,
    BoxButtons,
    BoxContent,
    BoxDeposComplete,
    BoxOneBtn,
    BoxContent2,
    BoxTotalPrice,
    BoxMemoInput,
} from "../../../components/BoxInfo";
import {
    HistoryBox,
    HistoryMessageBox,
    HistorySusinBox,
} from "../../../components/HistoryBox";
import { MemoBox } from "../../../components/MemoBox";
import {
    TableClassfiy,
    TableClassfiy2,
    TableClassfiy3,
    TableClassfiy4,
    TableMemo,
} from "../../../components/Tables";
import routes from "../../../libs/routes";
import images from "../../../libs/images";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "../../../zustand/Login";
import moment from "moment";
import consts from "../../../libs/consts";
import ApiData from "../../../libs/api";
import {
    useGetData,
    usePostData,
    useSusinPostData,
} from "../../../utils/service";
import { toast } from "react-toastify";
import { TabComponent } from "../../../components/TabComponent";
import { PopupComp } from "../../../components/PopUp";
import { set } from "lodash";
import { is } from "date-fns/locale";
import useLoadingStore from "../../../zustand/Loading";

function ConstrManagPreview() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const SusinConnect = useSusinPostData(ApiData.apiConnect);
    const msgReceiveList = usePostData(ApiData.msgReceiveList);
    const memoList = usePostData(ApiData.memoEstimateList);
    const sigongInfoOrderMsg = usePostData(ApiData.sigongInfoOrderMsg);
    const sigongInfoDetail = usePostData(ApiData.sigongInfoDetail);
    const sigongInfoContractMsg = usePostData(ApiData.sigongInfoContractMsg);
    const sigonginfoMsgList = usePostData(ApiData.sigonginfoMsgList);
    const memoRegister = usePostData(ApiData.sigongInfoMemoIn);
    const sigongInfoMemoDelete = usePostData(ApiData.memoEstimateDelete);
    const sigongOrderIn = usePostData(ApiData.sigongOrderIn);
    const historyLook = true;
    const [ContentHistory, setContentHistory] = useState([]);
    const [consultSummary, setConsultSummary] = useState("");
    const [ContentChange, setContentChange] = useState({});
    const [sigongWant, setSigongWant] = useState("");
    const [manager, setManager] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [phone, setPhone] = useState("");
    const [dobaeOn, setDobaeOn] = useState(0);
    const [jangpanOn, setJangpanOn] = useState(0);
    const [maruOn, setMaruOn] = useState(0);
    const [ShowContent, setShowContent] = useState(true);
    const [address, setAddress] = useState("");
    const [adressDetail, setAdressDetail] = useState("");
    const [area, setArea] = useState(0);
    const [areaPyeong, setAreaPyeong] = useState(0);
    const [directAreaPyeong, setDirectAreaPyeong] = useState(0);
    const [planUrl, setPlanUrl] = useState("");
    const [planImg, setPlanImg] = useState("");
    const [todayStatus, setTodayStatus] = useState(0);
    const [todayMemo, setTodayMemo] = useState("");
    const [veranda, setVeranda] = useState(0);
    const [verandaMemo, setVerandaMemo] = useState("");
    const [priceAll, setPriceAll] = useState([]);

    const [dobaeDate, setDobaeDate] = useState(new Date());
    const [dobaeTime, setDobaeTime] = useState("");
    const [dobaeRange, setDobaeRange] = useState(0);
    const [dobaeRangeMemo, setDobaeRangeMemo] = useState("");
    const [dobaeCeiling, setDobaeCeiling] = useState(0);
    const [dobaeCreateCompanyName, setDobaeCreateCompanyName] = useState("");

    const [dobaeDirectCompany, setDobaeDirectCompany] = useState("");
    const [dobaeProduct, setDobaeProduct] = useState(0);
    const [dobaeCnt, setDobaeCnt] = useState(0);
    const [dobaePrice, setDobaePrice] = useState(0);

    const [zangpanDate, setZangpanDate] = useState(new Date());
    const [zangpanTime, setZangpanTime] = useState("");
    const [zangpanRange, setZangpanRange] = useState(0);
    const [zangpanRangeMemo, setZangpanRangeMemo] = useState("");
    const [zangpanCreateCompanyName, setZangpanCreateCompanyName] =
        useState("");
    const [zangpanMM, setZangpanMM] = useState("");
    const [zangpanMolding, setZangpanMolding] = useState(0);
    const [zangpanMoldingMemo, setZangpanMoldingMemo] = useState("");
    const [zangpanMoldingRange, setZangpanMoldingRange] = useState(1);
    const [zangpanMoldingRangeMemo, setZangpanMoldingRangeMemo] = useState("");
    const [zangpanThreshold, setZangpanThreshold] = useState(2);
    const [zangpanPrice, setZangpanPrice] = useState(0);

    const [maruDate, setMaruDate] = useState(new Date());
    const [maruTime, setMaruTime] = useState("");
    const [maruRange, setMaruRange] = useState(0);
    const [maruRangeMemo, setMaruRangeMemo] = useState("");
    const [maruCreateCompanyName, setMaruCreateCompanyName] = useState("");

    const [maruFlooring, setMaruFlooring] = useState(1);
    const [maruFlooringMemo, setMaruFlooringMemo] = useState("");
    const [maruOldProduct, setMaruOldProduct] = useState("");
    const [maruDownMolding, setMaruDownMolding] = useState(2);
    const [maruThreshold, setMaruThreshold] = useState(2);
    const [maruRepair, setMaruRepair] = useState(2);
    const [maruPrice, setMaruPrice] = useState(0);

    const [createDt, setCreateDt] = useState(new Date());
    const [isUpdateData, setIsUpdateData] = useState(false);
    const [customerDirectMemo, setCustomerDirectMemo] = useState("");
    // memo
    const [memoWrite, setMemoWrite] = useState("");
    const [memoType, setMemoType] = useState(0);
    const [memoListData, setMemoListData] = useState([]);

    const [memoListCommon, setMemoListCommon] = useState([]);
    const [memoListDobae, setMemoListDobae] = useState([]);
    const [memoListZangpan, setMemoListZangpan] = useState([]);
    const [memoListMaru, setMemoListMaru] = useState([]);
    const [memoListSignificant, setMemoListSignificant] = useState([]);
    const [deletePop, setDeletePop] = useState(false);

    const [deleteIdx, setDeleteIdx] = useState(0);
    const [isDelete, setIsDelete] = useState(true);

    //시공관리 only
    const [materialDate, setMaterialDate] = useState("");
    const [enterPW, setEnterPW] = useState("");
    const [doorPW, setDoorPW] = useState("");
    const [customerMemo, setCustomerMemo] = useState("");
    const [customerContractChk, setCustomerContractChk] = useState(0);
    const [deposit, setDeposit] = useState(0);
    const [depositAmount, setDepositAmount] = useState(0);
    const [depositName, setDepositName] = useState("");
    const [customerFinalChk, setCustomerFinalChk] = useState(0);
    const [verandaExpansion, setVerandaExpansion] = useState(0);

    const [contractSendCount, setContractSendCount] = useState(0);
    const [depositSendCount, setDepositSendCount] = useState(0);
    const [finalSendCount, setFinalSendCount] = useState(0);
    const [productSelSendCount, setProductSelSendCount] = useState(0);
    const [tap, setTap] = useState({});
    const { mbData } = useUser();

    //시공관리 only 도배
    const [dobaeWorkerInfo, setDobaeWorkerInfo] = useState([]);
    const [dobaeOrderChk, setDobaeOrderChk] = useState(0);
    const [dobaeMsgSendCount, setDobaeMsgSendCount] = useState(0);
    const [dobaePersonnel, setDobaePersonnel] = useState(1);
    const [dobaeMakeManager, setDobaeMakeManager] = useState("");
    const [dobaeWorkerMemo, setDobaeWorkerMemo] = useState("");
    const [dobaeMaterials, setDobaeMaterials] = useState([]);
    const [subMaterials, setSubMaterials] = useState([]); //도배 부자재

    //시공관리 only 장판
    const [zangpanWorkerInfo, setZangpanWorkerInfo] = useState([]);
    const [zangpanOrderChk, setZangpanOrderChk] = useState(0);
    const [zangpanSendCount, setZangpanSendCount] = useState(0);
    const [zangpanPersonnel, setZangpanPersonnel] = useState(1);
    const [zangpanMakeManager, setZangpanMakeManager] = useState("");
    const [zangpanWorkerMemo, setZangpanWorkerMemo] = useState("");
    const [zangpanMaterials, setZangpanMaterials] = useState([]);
    const [zangpanMop, setZangpanMop] = useState([]);

    //시공관리 only 마루
    const [maruWorkerInfo, setMaruWorkerInfo] = useState([]);
    const [maruOrderChk, setMaruOrderChk] = useState(0);
    const [maruSendCount, setMaruSendCount] = useState(0);
    const [maruPersonnel, setMaruPersonnel] = useState(1);
    const [maruMakeManager, setMaruMakeManager] = useState("");
    const [maruWorkerMemo, setMaruWorkerMemo] = useState("");
    const [maruMaterials, setMaruMaterials] = useState([]);
    const [maruMop, setMaruMop] = useState([]);
    const [demolitionDate, setDemolitionDate] = useState(new Date());
    const [demolitionTime, setDemolitionTime] = useState("");
    const [demolitionRange, setDemolitionRange] = useState("");
    const [demolitionPrice, setDemolitionPrice] = useState(0);

    //시공 안내문 밑 내용
    const [apartUnit, setApartUnit] = useState("");
    const [constrUnit, setConstrUnit] = useState("");
    const [constrDetail, setConstrDetail] = useState("");
    const [constrStartDate, setConstrStartDate] = useState(new Date());
    const [constrEndDate, setConstrEndDate] = useState(new Date());
    const [constrNoise, setConstrNoise] = useState(new Date());
    const [constrFieldManager, setConstrFieldManager] = useState("");

    //문자관리
    const [customerMsg, setCustomerMsg] = useState([]);
    const [workerMsg, setWorkerMsg] = useState([]);
    const [page, setPage] = useState(1);
    const [totalSusinCount, setTotalSusinCount] = useState(0);
    const [susinData, setSusinData] = useState([]);
    const [customMsgPage, setCustomMsgPage] = useState(1);
    const [customMsgTotal, setCustomMsgTotal] = useState(0);
    const [workerMsgPage, setWorkerMsgPage] = useState(1);
    const [workerMsgTotal, setWorkerMsgTotal] = useState(0);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    const handleCustomMsgPageChange = (pageNumber) => {
        setCustomMsgPage(pageNumber);
    };
    const handleWorkerMsgPageChange = (pageNumber) => {
        setWorkerMsgPage(pageNumber);
    };
    const SusinListApi = () => {
        let sender = {
            bizNum: "0515815258",
            messageType: "SMS",
            startDate: "",
            endDate: "",
            offset: page - 1,
            searchVal: phone || "",
            token: localStorage.getItem("tokensAccessMsg"),
        };
        // console.log("sender", sender);
        msgReceiveList
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log("data!!!!!!", data);
                setTotalSusinCount(data.totalCount);
                setSusinData(data.rows);
                // console.log(data);
            })
            .catch((error) => {});
    };

    const SusinConnectApi = () => {
        SusinConnect.mutateAsync({
            apiKey: "wlUq55z9Az",
            secret: "sH2RsJMzcwJyqaaV",
        })
            .then(({ data }) => {
                // console.log("data???", data);
                localStorage.setItem(
                    "tokensAccessMsg",
                    data.rows[0].accessToken
                );
                localStorage.setItem(
                    "tokensRefreshMsg",
                    data.rows[0].refreshToken
                );
                SusinListApi();
            })
            .catch((error) => {
                // RetryConnectApi();
            });
    };
    // const { loadingStart, loadingEnd } = useLoadingStore();

    useEffect(() => {
        setDobaePersonnel(dobaeWorkerInfo[0]?.people_cnt);
        setDobaeMakeManager(dobaeWorkerInfo[0]?.banzang_name);
        setDobaeWorkerMemo(dobaeWorkerInfo[0]?.memo);
        setDobaeOrderChk(dobaeWorkerInfo[0]?.order_chk);

        setZangpanPersonnel(zangpanWorkerInfo[0]?.people_cnt);
        setZangpanMakeManager(zangpanWorkerInfo[0]?.banzang_name);
        setZangpanWorkerMemo(zangpanWorkerInfo[0]?.memo);
        setZangpanOrderChk(zangpanWorkerInfo[0]?.order_chk);

        setMaruPersonnel(maruWorkerInfo[0]?.people_cnt);
        setMaruMakeManager(maruWorkerInfo[0]?.banzang_name);
        setMaruWorkerMemo(maruWorkerInfo[0]?.memo);
        setMaruOrderChk(maruWorkerInfo[0]?.order_chk);
    }, [dobaeWorkerInfo, zangpanWorkerInfo, maruWorkerInfo]);

    const dataSetting = (data) => {
        setDobaeMaterials(data?.dobae);
        setSubMaterials(data?.sub);
        setZangpanMaterials(data?.zangpan);
        setZangpanMop(data?.zangpanMop);
        setMaruMaterials(data?.maru);
        setMaruMop(data?.maruMop);
        setContractSendCount(data?.contractCount);
        setDepositSendCount(data?.depositCount);
        setFinalSendCount(data?.finalCount);
        setProductSelSendCount(data?.productSelCount);
        setDobaeMsgSendCount(data?.dobaeMsgCount);
        setZangpanSendCount(data?.zangpanMsgCount);
        setMaruSendCount(data?.maruCount);
        setMemoListData(data.memoList);
        setDobaeWorkerInfo(data?.dobaeWorker);
        setZangpanWorkerInfo(data?.zangpanWorker);
        setMaruWorkerInfo(data?.maruWorker);
        if (data.detail !== null) {
            // console.log("data.detail", data.detail);
            setManager(data.detail?.admin_name);
            setIsUpdateData(true);
            setCreateDt(data.detail?.create_dt);
            setCustomerName(data.detail?.cutomer_name);
            setPhone(data.detail?.mb_phone);
            setAddress(data.detail?.address);
            setAdressDetail(data.detail?.address_detail);
            setArea(data.detail?.area);
            if (
                consts.pyeong?.some((item) => {
                    return item.value === parseInt(data.detail?.area_pyeong);
                })
            ) {
                setAreaPyeong(parseInt(data.detail?.area_pyeong));
            } else {
                setAreaPyeong(0);
                setDirectAreaPyeong(data.detail?.area_pyeong);
            }
            setAreaPyeong(parseInt(data.detail?.area_pyeong));
            setPlanUrl(data.detail?.plan_url);
            setPlanImg(data.detail?.plan_file);
            setTodayStatus(parseInt(data.detail?.today_status));
            setTodayMemo(data.detail?.today_memo);
            setVeranda(parseInt(data.detail?.veranda));
            setVerandaMemo(data.detail?.veranda_memo);
            setDobaeOn(parseInt(data.detail?.dobae));
            setJangpanOn(parseInt(data.detail?.zangpan));
            setMaruOn(parseInt(data.detail?.maru));
            setDobaeDate(
                data.detail?.dobae_date == null
                    ? new Date()
                    : data.detail?.dobae_date
            );
            setDobaeTime(data.detail?.dobae_time);
            setDobaeRange(parseInt(data.detail?.dobae_range));
            setDobaeRangeMemo(data.detail?.dobae_range_memo);
            setDobaeCeiling(data.detail?.dobae_ceiling);
            setDobaeCreateCompanyName(data.detail?.dobae_company_name);

            setDobaeProduct(parseInt(data.detail?.dobae_product));
            setDobaeCnt(parseInt(data.detail?.dobae_cnt));
            setDobaePrice(parseInt(data.detail?.dobae_price));
            setZangpanDate(
                data.detail?.zangpan_date == null
                    ? new Date()
                    : data.detail?.zangpan_date
            );
            setZangpanTime(data.detail?.zangpan_time);
            setZangpanRange(parseInt(data.detail?.zangpan_range));
            setZangpanRangeMemo(data.detail?.zangpan_range_memo);
            setZangpanCreateCompanyName(data.detail?.zangpan_company_name);

            setZangpanMM(data.detail?.zangpan_mm);
            setZangpanMolding(parseInt(data.detail?.zangpan_molding));
            setZangpanMoldingMemo(data.detail?.zangpan_molding_memo);
            setZangpanMoldingRange(
                parseInt(data.detail?.zangpan_molding_range)
            );
            setZangpanMoldingRangeMemo(data.detail?.zangpan_molding_range_memo);
            setZangpanThreshold(parseInt(data.detail?.zangpan_threshold));
            setZangpanPrice(parseInt(data.detail?.zangpan_price));
            setMaruDate(
                data.detail?.maru_date == null
                    ? new Date()
                    : data.detail?.maru_date
            );
            setMaruTime(data.detail?.maru_time);
            setMaruRange(parseInt(data.detail?.maru_range));
            setMaruRangeMemo(data.detail?.maru_range_memo);
            setMaruFlooring(parseInt(data.detail?.maru_flooring));
            setMaruFlooringMemo(data.detail?.maru_flooring_memo);
            setMaruDownMolding(parseInt(data.detail?.maru_molding));
            setMaruThreshold(parseInt(data.detail?.maru_threshold));
            setMaruRepair(parseInt(data.detail?.maru_cure));
            setMaruPrice(parseInt(data.detail?.maru_price));
            setMaruOldProduct(data.detail?.maru_ori_flooring);
            setDemolitionDate(data.detail?.demolition_date);
            setDemolitionTime(data.detail?.demolition_time);
            setDemolitionRange(data.detail?.demolition_range);
            setDemolitionPrice(data.detail?.demolition_price);

            setCustomerDirectMemo(data.detail?.customer_direct_memo);
            //시공관리 only
            setEnterPW(data.detail?.door_pass);
            setDoorPW(data.detail?.entrance_pass);
            setCustomerMemo(data.detail?.customer_memo);
            setCustomerContractChk(data.detail?.customer_contract_chk);
            setDeposit(data.detail?.customer_payment_chk); //계약금 입금여부 확인
            setDepositAmount(data.detail?.customer_payment);
            setDepositName(data.detail?.customer_payment_name);
            setCustomerFinalChk(data.detail?.customer_final_chk);
            setVerandaExpansion(data.detail?.material_chk);

            //시공 안내문 밑 내용
            setApartUnit(data.detail?.house_count);
            setConstrUnit(data.detail?.house_const_count);
            setConstrDetail(data.detail?.construction_memo);
            setConstrStartDate(data.detail?.const_start_date);
            setConstrEndDate(data.detail?.const_end_date);
            setConstrNoise(data.detail?.const_noise_date);
            setConstrFieldManager(data.detail?.field_manager);
            setMaruCreateCompanyName(data.detail?.maru_company_name);
            setMaterialDate(data?.detail?.material_date);
            setSigongWant(`
            ${
                data?.detail?.dobae === 1
                    ? `도배 : ${moment(
                          data?.detail?.dobae_date
                              ? data?.detail?.dobae_date
                              : data?.detail?.create_dt
                      ).format("YYYY-MM-DD")}(${
                          consts.dayText[
                              moment(
                                  data?.detail?.dobae_date
                                      ? data?.detail?.dobae_date
                                      : data?.detail?.create_dt
                              ).day()
                          ]
                      }), `
                    : ""
            }
            ${
                data?.detail?.zangpan === 1
                    ? `장판 : ${moment(
                          data?.detail?.zangpan_date
                              ? data?.detail?.zangpan_date
                              : data?.detail?.create_dt
                      ).format("YYYY-MM-DD")}(${
                          consts.dayText[
                              moment(
                                  data?.detail?.zangpan_date
                                      ? data?.detail?.zangpan_date
                                      : data?.detail?.create_dt
                              ).day()
                          ]
                      }), `
                    : ""
            }
            ${
                data?.detail?.maru === 1
                    ? `마루 : ${moment(
                          data?.detail?.maru_date
                              ? data?.detail?.maru_date
                              : data?.detail?.create_dt
                      ).format("YYYY-MM-DD")}(${
                          consts.dayText[
                              moment(
                                  data?.detail?.maru
                                      ? data?.detail?.maru
                                      : data?.detail?.create_dt
                              ).day()
                          ]
                      }), `
                    : ""
            }`);
            let summary = "";

            if (data?.detail?.address || data?.detail?.address_detail) {
                summary += `${
                    data?.detail?.address ? data?.detail?.address : ""
                }${
                    data?.detail?.address_detail
                        ? data?.detail?.address_detail
                        : ""
                }/ `;
            }

            summary += `${data?.detail?.area === 1 ? "공급" : "전용"}면적 ${
                data?.detail?.area_pyeong || 0
            }평/ `;

            if (data?.detail?.veranda == 1) {
                summary += `베란다 확장 있음${
                    data?.detail?.veranda_memo
                        ? `(${data?.detail?.veranda_memo})`
                        : ""
                }/ `;
            } else {
                summary += `베란다 확장 없음${
                    data?.detail?.veranda_memo
                        ? `(${data?.detail?.veranda_memo})`
                        : ""
                }/ `;
            }

            summary += `${
                data?.detail?.today_status == 1
                    ? "빈집 가구 없음 "
                    : data?.detail?.today_status == 2
                    ? "거주중 가구 있음"
                    : "이사 당일"
            }`;

            if (data?.detail?.today_memo) {
                summary += `(${data?.detail?.today_memo})`;
            }

            setConsultSummary(summary);
        } else {
        }
    };
    useEffect(() => {
        if (index) {
            // loadingStart();
            sigongInfoDetail
                .mutateAsync({ sigongInfoIdx: index })
                .then(({ data }) => {
                    // console.log("data", data);
                    // loadingEnd();
                    setContentHistory(data.history);
                    setTap(data.tap);
                    dataSetting(data);
                })
                .catch((e) => {
                    // loadingEnd();
                    toast.error(e.response.data);
                });
        }
        SigongMsgListApi();
    }, [index]);
    useEffect(() => {
        if (Object.keys(ContentChange).length === 0) return;
        // console.log("ContentChange", ContentChange);
        setDobaeMaterials(ContentChange?.dobaeList);
        setSubMaterials(ContentChange?.subList);
        setZangpanMaterials(ContentChange?.zangpanList);
        setZangpanMop(ContentChange?.zangpanMopList);
        setMaruMaterials(ContentChange?.maruList);
        setMaruMop(ContentChange?.maruMopList);
        setContractSendCount(ContentChange?.contractCount);
        setDepositSendCount(ContentChange?.depositCount);
        setFinalSendCount(ContentChange?.finalCount);
        setProductSelSendCount(ContentChange?.productSelCount);
        setDobaeMsgSendCount(ContentChange?.dobaeMsgCount);
        setZangpanSendCount(ContentChange?.zangpanMsgCount);
        setMaruSendCount(ContentChange?.maruCount);
        setMemoListData(ContentChange.memoList);
        setDobaeWorkerInfo(ContentChange?.dobaeWorker);
        setZangpanWorkerInfo(ContentChange?.zangpanWorker);
        setMaruWorkerInfo(ContentChange?.maruWorker);
        setCreateDt(ContentChange.create_dt);
        setCustomerName(ContentChange.cutomer_name);
        setPhone(ContentChange.mb_phone);
        setAddress(ContentChange.address);
        setAdressDetail(ContentChange.address_detail);
        setArea(ContentChange.area);
        if (
            consts.pyeong?.some((item) => {
                return item.value === parseInt(ContentChange.area_pyeong);
            })
        ) {
            setAreaPyeong(parseInt(ContentChange.area_pyeong));
        } else {
            setAreaPyeong(0);
            setDirectAreaPyeong(ContentChange.area_pyeong);
        }
        setAreaPyeong(parseInt(ContentChange.area_pyeong));
        setPlanUrl(ContentChange.plan_url);
        setPlanImg(ContentChange.plan_file);
        setTodayStatus(parseInt(ContentChange.today_status));
        setTodayMemo(ContentChange.today_memo);
        setVeranda(parseInt(ContentChange.veranda));
        setVerandaMemo(ContentChange.veranda_memo);
        setDobaeOn(parseInt(ContentChange.dobae));
        setJangpanOn(parseInt(ContentChange.zangpan));
        setMaruOn(parseInt(ContentChange.maru));
        setDobaeDate(
            ContentChange.dobae_date == null
                ? new Date()
                : ContentChange.dobae_date
        );
        setDobaeTime(ContentChange.dobae_time);
        setDobaeRange(parseInt(ContentChange.dobae_range));
        setDobaeRangeMemo(ContentChange.dobae_range_memo);
        setDobaeCeiling(ContentChange.dobae_ceiling);
        setDobaeCreateCompanyName(ContentChange.dobae_company_name);

        setDobaeProduct(parseInt(ContentChange.dobae_product));
        setDobaeCnt(parseInt(ContentChange.dobae_cnt));
        setDobaePrice(parseInt(ContentChange.dobae_price));
        setZangpanDate(
            ContentChange.zangpan_date == null
                ? new Date()
                : ContentChange.zangpan_date
        );
        setZangpanTime(ContentChange.zangpan_time);
        setZangpanRange(parseInt(ContentChange.zangpan_range));
        setZangpanRangeMemo(ContentChange.zangpan_range_memo);
        setZangpanCreateCompanyName(ContentChange.zangpan_company_name);

        setZangpanMM(ContentChange.zangpan_mm);
        setZangpanMolding(parseInt(ContentChange.zangpan_molding));
        setZangpanMoldingMemo(ContentChange.zangpan_molding_memo);
        setZangpanMoldingRange(parseInt(ContentChange.zangpan_molding_range));
        setZangpanMoldingRangeMemo(ContentChange.zangpan_molding_range_memo);
        setZangpanThreshold(parseInt(ContentChange.zangpan_threshold));
        setZangpanPrice(parseInt(ContentChange.zangpan_price));
        setMaruDate(
            ContentChange.maru_date == null
                ? new Date()
                : ContentChange.maru_date
        );
        setMaruTime(ContentChange.maru_time);
        setMaruRange(parseInt(ContentChange.maru_range));
        setMaruRangeMemo(ContentChange.maru_range_memo);
        setMaruFlooring(parseInt(ContentChange.maru_flooring));
        setMaruFlooringMemo(ContentChange.maru_flooring_memo);
        setMaruDownMolding(parseInt(ContentChange.maru_molding));
        setMaruThreshold(parseInt(ContentChange.maru_threshold));
        setMaruRepair(parseInt(ContentChange.maru_cure));
        setMaruPrice(parseInt(ContentChange.maru_price));
        setMaruOldProduct(ContentChange.maru_ori_flooring);
        setDemolitionDate(ContentChange?.demolition_date);
        setDemolitionTime(ContentChange?.demolition_time);
        setDemolitionRange(ContentChange?.demolition_range);
        setDemolitionPrice(ContentChange?.demolition_price);
        setCustomerDirectMemo(ContentChange?.customer_direct_memo);

        //시공관리 only
        setEnterPW(ContentChange.door_pass);
        setDoorPW(ContentChange.entrance_pass);
        setCustomerMemo(ContentChange.customer_memo);
        setCustomerContractChk(ContentChange.customer_contract_chk);
        setDeposit(ContentChange.customer_payment_chk); //계약금 입금여부 확인
        setDepositAmount(ContentChange.customer_payment);
        setDepositName(ContentChange.customer_payment_name);
        setCustomerFinalChk(ContentChange.customer_final_chk);
        setVerandaExpansion(ContentChange.material_chk);

        //시공 안내문 밑 내용
        setApartUnit(ContentChange.house_count);
        setConstrUnit(ContentChange.house_const_count);
        setConstrDetail(ContentChange.construction_memo);
        setConstrStartDate(ContentChange.const_start_date);
        setConstrEndDate(ContentChange.const_end_date);
        setConstrNoise(ContentChange.const_noise_date);
        setConstrFieldManager(ContentChange.field_manager);
        setMaruCreateCompanyName(ContentChange.maru_company_name);
        setMaterialDate(ContentChange.material_date);
        setSigongWant(`
        ${
            ContentChange.dobae === 1
                ? `도배 : ${moment(
                      ContentChange.dobae_date
                          ? ContentChange.dobae_date
                          : ContentChange.create_dt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              ContentChange.dobae_date
                                  ? ContentChange.dobae_date
                                  : ContentChange.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }
        ${
            ContentChange.zangpan === 1
                ? `장판 : ${moment(
                      ContentChange.zangpan_date
                          ? ContentChange.zangpan_date
                          : ContentChange.create_dt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              ContentChange.zangpan_date
                                  ? ContentChange.zangpan_date
                                  : ContentChange.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }
        ${
            ContentChange.maru === 1
                ? `마루 : ${moment(
                      ContentChange.maru_date
                          ? ContentChange.maru_date
                          : ContentChange.create_dt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              ContentChange.maru
                                  ? ContentChange.maru
                                  : ContentChange.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }`);
        let summary = "";

        if (ContentChange.address || ContentChange.address_detail) {
            summary += `${ContentChange.address ? ContentChange.address : ""}${
                ContentChange.address_detail ? ContentChange.address_detail : ""
            }/ `;
        }

        summary += `${ContentChange.area === 1 ? "공급" : "전용"}면적 ${
            ContentChange.area_pyeong || 0
        }평/ `;

        if (ContentChange.veranda == 1) {
            summary += `베란다 확장 있음${
                ContentChange.veranda_memo
                    ? `(${ContentChange.veranda_memo})`
                    : ""
            }/ `;
        } else {
            summary += `베란다 확장 없음${
                ContentChange.veranda_memo
                    ? `(${ContentChange.veranda_memo})`
                    : ""
            }/ `;
        }

        summary += `${
            ContentChange.today_status == 1
                ? "빈집 가구 없음 "
                : ContentChange.today_status == 2
                ? "거주중 가구 있음"
                : "이사 당일"
        }`;

        if (ContentChange.today_memo) {
            summary += `(${ContentChange.today_memo})`;
        }

        setConsultSummary(summary);
    }, [ContentChange]);
    const SigongMsgListApi = () => {
        sigonginfoMsgList
            .mutateAsync({
                sigonginfoIdx: index,
                customPage: customMsgPage - 1,
                workerPage: workerMsgPage - 1,
            })
            .then(({ data }) => {
                setCustomMsgTotal(data?.customerMsg.count);
                setCustomerMsg(data?.customerMsg.rows);
                setWorkerMsgTotal(data?.workerMsg.count);
                setWorkerMsg(data?.workerMsg.rows);
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    useEffect(() => {
        SigongMsgListApi();
    }, [customMsgPage, workerMsgPage]);
    const ContractSigongMsg = (type) => {
        if (type === 1 && dobaeMakeManager === null) {
            toast.error("작업자가 배정되지 않았습니다.");
            return;
        }
        if (type === 2 && zangpanMakeManager === null) {
            toast.error("작업자가 배정되지 않았습니다.");
            return;
        }
        if (type === 3 && maruMakeManager === null) {
            toast.error("작업자가 배정되지 않았습니다.");
            return;
        }
        // console.log("type", type);
        sigongInfoOrderMsg
            .mutateAsync({ sigongInfoIdx: index, workerAbility: type })
            .then(({ data }) => {
                toast.success("메세지를 전송했습니다.");
                sigongSendUpdate();
            })
            .catch((e) => {
                // console.log(e);
                toast.error(e.response.data);
            });
    };
    const sigongSendUpdate = () => {
        sigongInfoDetail
            .mutateAsync({
                sigongInfoIdx: index,
            })
            .then(({ data }) => {
                setContractSendCount(data.contractCount);
                setDepositSendCount(data.depositCount);
                setFinalSendCount(data.finalCount);
                setProductSelSendCount(data.productSelCount);
                setDobaeMsgSendCount(data?.dobaeMsgCount);
                setZangpanSendCount(data?.zangpanMsgCount);
                setMaruSendCount(data?.maruCount);
                setCustomerFinalChk(data.detail?.customer_final_chk);
                setDobaeOrderChk(data?.dobaeWorker[0]?.order_chk);
                setZangpanOrderChk(data?.zangpanWorker[0]?.order_chk);
                setMaruOrderChk(data?.maruWorker[0]?.order_chk);

                SigongMsgListApi();
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const ContractMsg = (type) => {
        sigongInfoContractMsg
            .mutateAsync({ sigongInfoIdx: index, msgType: type })
            .then(({ data }) => {
                toast.success("메세지를 전송했습니다.");
                sigongSendUpdate();
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const saveMemo = () => {
        let sender = {
            sigonginfoIdx: index,
            memo_type: memoType,
            memo_content: memoWrite,
        };
        // console.log("sender", sender);
        if (memoType === 0) {
            toast.error("메모 타입을 선택해주세요");
            return;
        } else if (memoWrite === "") {
            toast.error("메모를 입력해주세요");
            return;
        }

        memoRegister
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("저장되었습니다.");
                // MemoListApi();
                setMemoWrite("");
                setMemoType(0);
                memoUpdate();
            })
            .catch((e) => {
                // console.log(e);
                toast.error("저장에 실패했습니다.");
            });
    };

    const MemoListApi = () => {
        memoList
            .mutateAsync({ estimateIdx: tap?.estimate_idx })
            .then(({ data }) => {
                // console.log("data!!!!!!", data);
                setMemoListData(data);
            })
            .catch((e) => {
                toast.error("정보를 가져오지 못했습니다.");
            });
    };
    const OpenUrl = ({ SendType, idx, type }) => {
        if (SendType === 1) {
            window.open(
                `https://order.8amdobae.co.kr/UserLogin?sigongIdx=${idx}&Looking=true`
            );
        } else if (SendType === 2) {
            window.open(
                `https://order.8amdobae.co.kr/UserNewFinalConfirm?sigongIdx=${idx}&Looking=true`
            );
        } else if (SendType === 3) {
            window.open(
                `https://order.8amdobae.co.kr/UserNewMaterialSelect?sigongIdx=${idx} `
            );
        } else if (SendType === 4) {
            window.open(
                `https://worker.8amdobae.co.kr/WorkOrder?sigongIdx=${idx}&sigongType=${type}&Looking=true`
            );
        }
    };
    useEffect(() => {
        const common = [];
        const dobae = [];
        const zangpan = [];
        const maru = [];
        const significant = [];

        memoListData?.forEach((item) => {
            switch (item.memo_type) {
                case 1:
                    common.push(item);
                    break;
                case 2:
                    dobae.push(item);
                    break;
                case 3:
                    zangpan.push(item);
                    break;
                case 4:
                    maru.push(item);
                    break;
                case 5:
                    significant.push(item);
                    break;
                default:
                    break;
            }
        });

        setMemoListCommon(common);
        setMemoListDobae(dobae);
        setMemoListZangpan(zangpan);
        setMemoListMaru(maru);
        setMemoListSignificant(significant);
    }, [memoListData]);
    // useEffect(() => {
    //     memoListData?.map((item, index) => {
    //         if (item.memo_type === 1) {
    //             console.log("item", item);
    //             setMemoListCommon((prev) => [...prev, item]);
    //         } else if (item.memo_type === 2) {
    //             setMemoListDobae((prev) => [...prev, item]);
    //         } else if (item.memo_type === 3) {
    //             setMemoListZangpan((prev) => [...prev, item]);
    //         } else if (item.memo_type === 4) {
    //             setMemoListMaru((prev) => [...prev, item]);
    //         } else if (item.memo_type === 5) {
    //             setMemoListSignificant((prev) => [...prev, item]);
    //         }
    //     });
    //     return () => {
    //         setMemoListCommon([]);
    //         setMemoListDobae([]);
    //         setMemoListZangpan([]);
    //         setMemoListMaru([]);
    //         setMemoListSignificant([]);
    //     };
    // }, [memoListData]);
    const memoUpdate = () => {
        sigongInfoDetail
            .mutateAsync({ sigongInfoIdx: parseInt(index) })
            .then(({ data }) => {
                setMemoListData(data?.memoList);
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const DeleteMemo = () => {
        sigongInfoMemoDelete
            .mutateAsync({ memoIdx: deleteIdx })
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                MemoListApi();
                // memoUpdate();
            })
            .catch((e) => {
                toast.error("삭제에 실패했습니다.");
            });
    };
    const GoNextBtn = () => {
        setDeletePop(true);
        setIsDelete(false);
    };
    const GoNext = () => {
        if (dobaeMakeManager === null || dobaeMakeManager === "선택") {
            toast.error("작업자가 배정되지 않았습니다.");
            return;
        }
        sigongOrderIn
            .mutateAsync({ sigonginfoIdx: index })
            .then(({ data }) => {
                navigate(`${routes.constRegst3}?index=${data}&type=list`);
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    useEffect(() => {
        let newPriceAll = [...priceAll];

        const updateOrAdd = (title, price) => {
            const index = newPriceAll.findIndex((item) => item.title === title);
            if (index !== -1) {
                newPriceAll[index] = { title, price };
            } else {
                newPriceAll.push({ title, price });
            }
        };

        if (dobaePrice != 0) {
            updateOrAdd("도배", dobaePrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "도배");
        }

        if (zangpanPrice != 0) {
            updateOrAdd("장판", zangpanPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "장판");
        }

        if (maruPrice != 0) {
            updateOrAdd("마루", maruPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "마루");
        }

        if (demolitionPrice != 0 && demolitionPrice != null) {
            updateOrAdd("철거", demolitionPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "철거");
        }

        setPriceAll(newPriceAll);
    }, [dobaePrice, zangpanPrice, maruPrice, demolitionPrice]);

    useEffect(() => {
        if (tap?.estimate_idx) {
            MemoListApi();
        }
    }, [tap]);
    useEffect(() => {
        if (phone) {
            SusinConnectApi();
        }
    }, [page, phone]);
    return (
        <>
            <PopupComp
                popTitle={`${isDelete ? "삭제" : "주문"}`}
                popContent={`${
                    isDelete
                        ? "정말로 삭제하시겠습니까?"
                        : "해당 자재/부자재를 주문관리 목록에 추가하시겠습니까?"
                }`}
                isYes={true}
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={isDelete ? DeleteMemo : GoNext}
            />
            <div className="content ">
                <TabComponent
                    index={tap?.counseling_idx}
                    estimate_idx={tap?.estimate_idx}
                    sigonginfo_idx={tap?.sigonginfo_idx}
                    order_idx={tap?.order_idx}
                    workend_idx={tap?.workend_idx}
                    as_tbl={tap?.as_tbl}
                />
                <HistoryBox
                    Info={ContentHistory}
                    firstDate={createDt}
                    adminName={mbData?.mb_name}
                    historyLook={historyLook}
                    setContentChange={setContentChange}
                />
                <div className="animate__animated animate__fadeIn">
                    {/* <BoxProgress Radioes={DummyRadio} manager="hello" /> */}
                    <div className="BorderBox">
                        <div
                            className="title HistoryBox"
                            style={{ borderBottom: "1px solid #ccc" }}
                            onClick={() => setShowContent(!ShowContent)}
                        >
                            <div className="left">
                                <h1>견적내용 보기</h1>
                            </div>
                            <div className="right">
                                <svg
                                    style={{
                                        transform: ShowContent
                                            ? "rotate(180deg)"
                                            : "rotate(0deg)",
                                    }}
                                    width="40"
                                    height="40"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                        fill="#333333"
                                    />
                                </svg>
                            </div>
                        </div>
                        {ShowContent && (
                            <>
                                <div className="title">
                                    <h1>상담요약</h1>
                                </div>
                                <div className="contentWrap">
                                    <BoxContent
                                        title="시공희망일"
                                        content={sigongWant}
                                    />
                                    <BoxContent
                                        title="내용"
                                        content={consultSummary}
                                    />
                                </div>
                                <div className="title">
                                    <h1>기본정보</h1>
                                </div>
                                <div className="contentWrap">
                                    <BoxContent
                                        title="담당자"
                                        content={manager || "-"}
                                    />
                                    <BoxContent
                                        title="고객명"
                                        content={customerName || "-"}
                                    />
                                    <BoxContent
                                        title="연락처"
                                        content={phone || "-"}
                                    />
                                    {/* <BoxArrayContent
                                    title="시공"
                                    // array={sigong || "-"}
                                /> */}
                                    <BoxContent
                                        title="주소"
                                        content={`${
                                            address !== null &&
                                            adressDetail !== null
                                                ? address + " " + adressDetail
                                                : address !== null &&
                                                  adressDetail === null
                                                ? address
                                                : address === null &&
                                                  adressDetail !== null
                                                ? adressDetail
                                                : "-"
                                        }`}
                                    />
                                    <BoxContent
                                        title="면적"
                                        content={`${
                                            areaPyeong ? areaPyeong : 0
                                        }평(${area == 1 ? "공급" : "전용"})`}
                                    />
                                    {/*BoxShowImg로 바꿔야 함. */}
                                    <div className="BoxInfo">
                                        <div className="title">도면 URL</div>
                                        <div className="sub">
                                            {planUrl ? (
                                                <a
                                                    href={`${planUrl}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    도면보기
                                                </a>
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                    </div>
                                    <div className="BoxInfo">
                                        <div className="title">도면파일</div>
                                        <div className="sub">
                                            {planImg ? (
                                                <a
                                                    href={`${consts.ImgState}${planImg}`}
                                                    download="도면_파일"
                                                    target="_blank"
                                                >
                                                    도면
                                                </a>
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                    </div>
                                    {/*BoxShowImg로 바꿔야 함. */}
                                    <BoxContent
                                        title="시공당일 주거환경"
                                        content={`${
                                            todayStatus == 1
                                                ? "빈집"
                                                : todayStatus == 2
                                                ? "거주중 가구 있음"
                                                : "이사 당일"
                                        } ${todayMemo ? `(${todayMemo})` : ""}`}
                                    />
                                    <BoxContent
                                        title="베란다 확장"
                                        content={
                                            veranda == 1
                                                ? `베란다 확장 있음 ${
                                                      verandaMemo
                                                          ? `(${verandaMemo})`
                                                          : ""
                                                  }`
                                                : veranda === 3
                                                ? `베란다 확장 미정 ${
                                                      verandaMemo
                                                          ? `(${verandaMemo})`
                                                          : ""
                                                  }`
                                                : `베란다 확장 없음 ${
                                                      verandaMemo
                                                          ? `(${verandaMemo})`
                                                          : ""
                                                  }`
                                        }
                                    />
                                    <BoxTotalPrice Info={priceAll} />
                                </div>
                                {dobaeOn == 1 && (
                                    <div className="title_color_box">
                                        <div
                                            className="box_title"
                                            style={{
                                                backgroundColor: "#41b06e",
                                                color: "white",
                                            }}
                                        >
                                            <h1>도배</h1>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="contentBorderWrap">
                                                <BoxContent
                                                    title="시공일"
                                                    content={
                                                        dobaeDate
                                                            ? `${moment(
                                                                  dobaeDate
                                                              ).format(
                                                                  "YYYY-MM-DD"
                                                              )}(${
                                                                  consts
                                                                      .dayText[
                                                                      moment(
                                                                          dobaeDate
                                                                      ).day()
                                                                  ]
                                                              })`
                                                            : "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="시공시간"
                                                    content={dobaeTime || "-"}
                                                />
                                                <BoxContent
                                                    title="시공범위"
                                                    content={`${
                                                        dobaeRange == 1
                                                            ? "전체"
                                                            : "일부"
                                                    } ${
                                                        dobaeRangeMemo
                                                            ? `(${dobaeRangeMemo})`
                                                            : ""
                                                    }`}
                                                />
                                                <BoxContent
                                                    title="천장"
                                                    content={
                                                        dobaeCeiling == 1
                                                            ? "천장도배포함"
                                                            : "천장도배없음"
                                                    }
                                                />
                                                <BoxContent
                                                    title="제조사"
                                                    content={
                                                        dobaeCreateCompanyName ||
                                                        "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="벽지 종류"
                                                    content={
                                                        dobaeProduct == 1
                                                            ? "실크"
                                                            : dobaeProduct == 2
                                                            ? "합지"
                                                            : "혼합"
                                                    }
                                                />
                                                <BoxContent
                                                    title="벽지 수"
                                                    content={`${dobaeCnt}개`}
                                                />
                                                <BoxContent
                                                    title="견적금액"
                                                    content={`${(
                                                        dobaePrice || 0
                                                    ).toLocaleString()}원`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {jangpanOn == 1 && (
                                    <div className="title_color_box">
                                        <div
                                            className="box_title"
                                            style={{
                                                backgroundColor: "#fb8500",
                                                color: "white",
                                            }}
                                        >
                                            <h1>장판</h1>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="contentBorderWrap">
                                                <BoxContent
                                                    title="시공일"
                                                    content={
                                                        zangpanDate
                                                            ? `${moment(
                                                                  zangpanDate
                                                              ).format(
                                                                  "YYYY-MM-DD"
                                                              )}(${
                                                                  consts
                                                                      .dayText[
                                                                      moment(
                                                                          zangpanDate
                                                                      ).day()
                                                                  ]
                                                              })`
                                                            : "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="시공시간"
                                                    content={zangpanTime || "-"}
                                                />
                                                <BoxContent
                                                    title="시공범위"
                                                    content={`${
                                                        zangpanRange == 1
                                                            ? "전체"
                                                            : "일부"
                                                    } ${
                                                        zangpanRangeMemo
                                                            ? `(${zangpanRangeMemo})`
                                                            : ""
                                                    }`}
                                                />
                                                <BoxContent
                                                    title="제조사"
                                                    content={
                                                        zangpanCreateCompanyName ||
                                                        "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="장판"
                                                    content={`${zangpanMM}`}
                                                />
                                                <BoxContent2
                                                    title="하단몰딩"
                                                    content1={`종류 : ${
                                                        zangpanMolding == 0
                                                            ? "없음"
                                                            : zangpanMolding ==
                                                              1
                                                            ? zangpanMoldingMemo
                                                            : zangpanMolding ==
                                                              2
                                                            ? "굽도리"
                                                            : "걸레받이"
                                                    }`}
                                                    content2={`범위 : ${
                                                        parseInt(
                                                            zangpanMoldingRange
                                                        ) == 2
                                                            ? "전체"
                                                            : zangpanMoldingRangeMemo
                                                            ? zangpanMoldingRangeMemo
                                                            : "-"
                                                    }`}
                                                />

                                                <BoxContent
                                                    title="문턱"
                                                    content={
                                                        zangpanThreshold == 1
                                                            ? "시공"
                                                            : "시공없음"
                                                    }
                                                />
                                                <BoxContent
                                                    title="견적금액"
                                                    content={`${(
                                                        zangpanPrice || 0
                                                    ).toLocaleString()}원`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {maruOn == 1 && (
                                    <div className="title_color_box">
                                        <div
                                            className="box_title"
                                            style={{
                                                backgroundColor: "#caf0f8",
                                            }}
                                        >
                                            <h1>마루</h1>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="contentBorderWrap">
                                                <BoxContent
                                                    title="시공일"
                                                    content={
                                                        maruDate
                                                            ? `${moment(
                                                                  maruDate
                                                              ).format(
                                                                  "YYYY-MM-DD"
                                                              )}(${
                                                                  consts
                                                                      .dayText[
                                                                      moment(
                                                                          maruDate
                                                                      ).day()
                                                                  ]
                                                              })`
                                                            : "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="시공시간"
                                                    content={maruTime || "-"}
                                                />
                                                <BoxContent
                                                    title="시공범위"
                                                    content={`${
                                                        maruRange == 1
                                                            ? "전체"
                                                            : "일부"
                                                    } ${
                                                        maruRangeMemo
                                                            ? `(${maruRangeMemo})`
                                                            : "-"
                                                    }`}
                                                />
                                                <BoxContent
                                                    title="제조사"
                                                    content={
                                                        maruCreateCompanyName ||
                                                        "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="바닥제"
                                                    content={
                                                        maruFlooring == 1
                                                            ? maruFlooringMemo
                                                            : maruFlooring == 2
                                                            ? "강마루"
                                                            : maruFlooring == 3
                                                            ? "강화마루"
                                                            : "SB마루"
                                                    }
                                                />
                                                <BoxContent
                                                    title="기존 바닥재"
                                                    content={
                                                        maruOldProduct || "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="하단몰딩"
                                                    content={
                                                        maruDownMolding == 1
                                                            ? "시공"
                                                            : "시공없음"
                                                    }
                                                />
                                                <BoxContent
                                                    title="문턱"
                                                    content={
                                                        maruThreshold == 1
                                                            ? "시공"
                                                            : "시공없음"
                                                    }
                                                />
                                                <BoxContent
                                                    title="보양작업"
                                                    content={
                                                        maruRepair == 1
                                                            ? "시공"
                                                            : "시공없음"
                                                    }
                                                />
                                                <BoxContent
                                                    title="견적금액"
                                                    content={`${(
                                                        maruPrice || 0
                                                    ).toLocaleString()}원`}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="title_color_box"
                                            style={{ border: "0px" }}
                                        >
                                            <div
                                                className="box_title"
                                                style={{
                                                    backgroundColor: "#ced4da",
                                                }}
                                            >
                                                <h1>철거</h1>
                                            </div>
                                            <div className="contentColorWrap">
                                                <div className="contentBorderWrap">
                                                    <BoxContent
                                                        title="시공일"
                                                        content={
                                                            demolitionDate
                                                                ? `${moment(
                                                                      demolitionDate
                                                                  ).format(
                                                                      "YYYY-MM-DD"
                                                                  )}(${
                                                                      consts
                                                                          .dayText[
                                                                          moment(
                                                                              demolitionDate
                                                                          ).day()
                                                                      ]
                                                                  })`
                                                                : "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="시공시간"
                                                        content={
                                                            demolitionTime ||
                                                            "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="범위"
                                                        content={
                                                            demolitionRange ||
                                                            "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="금액"
                                                        content={`${(
                                                            demolitionPrice || 0
                                                        ).toLocaleString()}원`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <div className="BorderBox">
                        <div className="title">
                            <h1>정보</h1>
                        </div>
                        <div className="contentWrap">
                            <BoxContent
                                title="현관 비밀번호"
                                content={enterPW || "-"}
                            />
                            <BoxContent
                                title="입구 비밀번호"
                                content={doorPW || "-"}
                            />
                            <BoxMemoInput
                                title="고객 메모"
                                value={customerMemo}
                                readOnly={true}
                            />
                            {/* <BoxContent
                                title="고객 메모"
                                content={customerMemo || "-"}
                            /> */}
                        </div>
                    </div>
                    <div className="BorderBox">
                        <div className="title">
                            <h1>계약</h1>
                        </div>
                        <div className="contentWrap">
                            <BoxButtons
                                title="예약안내"
                                IsSend={
                                    customerContractChk === 0
                                        ? "미확인"
                                        : "확인"
                                }
                                OpenUrl={() =>
                                    OpenUrl({ SendType: 1, idx: tap.idx })
                                }
                                gbtn={true}
                                bbtn={true}
                                bbtnNum={contractSendCount || 0}
                                bbtnClk={() => ContractMsg(3)}
                            />
                            <BoxDeposComplete
                                deposit={deposit}
                                price={depositAmount}
                                name={depositName}
                                sendMail={depositSendCount || 0}
                                sendMailClk={() => ContractMsg(4)}
                            />
                            <BoxButtons
                                title="최종확인서"
                                IsSend={
                                    customerFinalChk === 1 ? "발송" : "미발송"
                                }
                                OpenUrl={() =>
                                    OpenUrl({ SendType: 2, idx: tap.idx })
                                }
                                gbtn={true}
                                bbtn={true}
                                bbtnNum={finalSendCount || 0}
                                bbtnClk={() => ContractMsg(5)}
                            />
                        </div>
                    </div>
                    <div className="BorderBox">
                        <div className="title">
                            <h1>자재선택</h1>
                        </div>
                        <div className="contentWrap">
                            <BoxOneBtn title="상태" submit={verandaExpansion} />

                            <BoxButtons
                                title="발송"
                                gbtn={true}
                                bbtn={true}
                                OpenUrl={() =>
                                    OpenUrl({ SendType: 3, idx: tap.idx })
                                }
                                bbtnNum={productSelSendCount || 0}
                                bbtnClk={() => ContractMsg(6)}
                            />
                            <BoxContent
                                title="제출 마감일"
                                content={
                                    materialDate
                                        ? `${moment(materialDate).format(
                                              "YYYY-MM-DD HH:mm"
                                          )}`
                                        : "-"
                                }
                            />
                        </div>
                    </div>
                    {dobaeOn == 1 && (
                        <div className="title_color_box">
                            <div
                                className="box_title"
                                style={{
                                    backgroundColor: "#41b06e",
                                    color: "white",
                                }}
                            >
                                <h1>도배</h1>
                            </div>
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>인력배정</h3>
                                </div>
                                <div className="contentBorderWrap">
                                    <BoxContent
                                        title="총인원"
                                        content={`${dobaePersonnel || 0}명`}
                                    />
                                    <BoxContent
                                        title="반장"
                                        content={dobaeMakeManager || "-"}
                                    />
                                    <BoxButtons
                                        title="작업지시서"
                                        IsSend={
                                            dobaeOrderChk === 0
                                                ? "미발송"
                                                : "발송"
                                        }
                                        OpenUrl={() =>
                                            OpenUrl({
                                                SendType: 4,
                                                idx: tap.idx,
                                                type: 1,
                                            })
                                        }
                                        gbtn={true}
                                        bbtn={true}
                                        bbtnNum={dobaeMsgSendCount || 0}
                                        bbtnClk={() => ContractSigongMsg(1)}
                                    />
                                    <BoxMemoInput
                                        title="작업자 전달사항"
                                        value={dobaeWorkerMemo}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="SpaceBetween">
                                    <div className="subTitleCircle">
                                        <div className="GreenCircle"></div>
                                        <h3>선택벽지</h3>
                                    </div>
                                    <button
                                        onClick={GoNextBtn}
                                        style={{
                                            width: "130px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "10px",
                                            height: "40px",
                                            color: "white",
                                            backgroundColor: "#66CDAA",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        자재/부자재 주문
                                    </button>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        backgroundColor: "#41b06e",
                                        color: "white",
                                        height: "35px",
                                        alignItems: "center",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <h2 style={{ fontSize: "20px" }}>
                                        벽지 종류 : [
                                        {dobaeCreateCompanyName === ""
                                            ? dobaeDirectCompany
                                            : dobaeCreateCompanyName}
                                        ]{" "}
                                        {dobaeProduct === 1
                                            ? "실크"
                                            : dobaeProduct === 2
                                            ? "합지"
                                            : dobaeProduct === 3
                                            ? "혼합"
                                            : "-"}{" "}
                                        , 벽지 수 : {dobaeCnt || 0}개 ,
                                        {dobaeCeiling === 1
                                            ? "천장 도배 포함"
                                            : "천장 도배 안함"}
                                        ,{" "}
                                        {veranda === 1
                                            ? "베란다 확장 없음"
                                            : veranda === 2
                                            ? "베란다 확장 있음"
                                            : "베란다 확장 미정"}
                                    </h2>
                                </div>
                                <TableClassfiy list={dobaeMaterials} />
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>부자재</h3>
                                </div>
                                <TableClassfiy2 list={subMaterials} />
                            </div>
                        </div>
                    )}
                    {jangpanOn == 1 && (
                        <div className="title_color_box">
                            <div
                                className="box_title"
                                style={{
                                    backgroundColor: "#fb8500",
                                    color: "white",
                                }}
                            >
                                <h1>장판</h1>
                            </div>
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>인력배정</h3>
                                </div>
                                <div className="contentBorderWrap">
                                    <BoxContent
                                        title="총인원"
                                        content={`${zangpanPersonnel || 0}명`}
                                    />
                                    <BoxContent
                                        title="반장"
                                        content={zangpanMakeManager || "-"}
                                    />
                                    <BoxButtons
                                        title="작업지시서"
                                        IsSend={
                                            zangpanOrderChk === 0
                                                ? "미발송"
                                                : "발송"
                                        }
                                        OpenUrl={() =>
                                            OpenUrl({
                                                SendType: 4,
                                                idx: tap.idx,
                                                type: 2,
                                            })
                                        }
                                        gbtn={true}
                                        bbtn={true}
                                        bbtnNum={zangpanSendCount || 0}
                                        bbtnClk={() => ContractSigongMsg(2)}
                                    />
                                    <BoxMemoInput
                                        title="작업자 전달사항"
                                        value={zangpanWorkerMemo}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>선택제품</h3>
                                </div>
                                <TableClassfiy3 list={zangpanMaterials} />
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>걸레받이</h3>
                                </div>
                                <TableClassfiy4 list={zangpanMop} />
                            </div>
                        </div>
                    )}
                    {maruOn == 1 && (
                        <div className="title_color_box">
                            <div
                                className="box_title"
                                style={{ backgroundColor: "#caf0f8" }}
                            >
                                <h1>마루</h1>
                            </div>
                            <div className="contentColorWrap">
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>인력배정</h3>
                                </div>
                                <div className="contentBorderWrap">
                                    <BoxContent
                                        title="총인원"
                                        content={`${maruPersonnel || 0}명`}
                                    />
                                    <BoxContent
                                        title="반장"
                                        content={maruMakeManager || "-"}
                                    />
                                    {/* <BoxButtons
                                        title="작업지시서"
                                        IsSend={
                                            maruOrderChk === 0
                                                ? "미발송"
                                                : "발송"
                                        }
                                        gbtn={true}
                                        bbtn={true}
                                        bbtnNum={maruSendCount || 0}
                                        bbtnClk={() => ContractSigongMsg(3)}
                                    /> */}
                                    <BoxMemoInput
                                        title="작업자 전달사항"
                                        value={maruWorkerMemo}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>바닥재</h3>
                                </div>
                                <TableClassfiy3 list={maruMaterials} />
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>걸래받이</h3>
                                </div>
                                <TableClassfiy4 list={maruMop} />
                                <div
                                    className="MarginTop20"
                                    style={{
                                        display: "flex",
                                        gap: "15px",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",

                                            alignItems: "center",
                                            fontSize: "18px",
                                        }}
                                    >
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>시공안내문</h3>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{ borderTop: "1px solid #ddd" }}
                                    className="contentWrap"
                                >
                                    <BoxContent
                                        title="세대"
                                        content={apartUnit || "-"}
                                    />
                                    <BoxContent
                                        title="공사세대"
                                        content={constrUnit || "-"}
                                    />
                                    <BoxContent
                                        title="공사내역"
                                        content={constrDetail || "-"}
                                    />
                                    <BoxContent
                                        title="공사기간"
                                        content={
                                            constrStartDate && constrEndDate
                                                ? `${moment(
                                                      constrStartDate
                                                  ).format(
                                                      "YYYY-MM-DD"
                                                  )} ~ ${moment(
                                                      constrEndDate
                                                  ).format("YYYY-MM-DD")}`
                                                : "-"
                                        }
                                    />
                                    <BoxContent
                                        title="소음발생 예상일자"
                                        content={constrNoise || "-"}
                                    />
                                    <BoxContent
                                        title="현장담당자"
                                        content={constrFieldManager || "-"}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="MemoBoxWrap" style={{ marginTop: "40px" }}>
                        <div className="title">
                            <img src={images.pencil} />
                            <p>고객메모/요청사항</p>
                        </div>

                        <div className="MemoWrap">
                            <textarea
                                readOnly={true}
                                className="MemoBox"
                                value={customerDirectMemo}
                                onChange={(e) =>
                                    setCustomerDirectMemo(e.target.value)
                                }
                            ></textarea>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            width: "100%",
                            margin: "20px 0",
                        }}
                    >
                        <button
                            className="ChangeBtn"
                            onClick={() => {
                                navigate(
                                    `${routes.constRegst2}?index=${index}`
                                );
                            }}
                        >
                            변경
                        </button>
                    </div>
                    <MemoBox
                        memoWrite={memoWrite}
                        setMemoWrite={setMemoWrite}
                        memoType={memoType}
                        setMemoType={setMemoType}
                        submitClk={saveMemo}
                    />
                    {memoListCommon.length > 0 && (
                        <TableMemo
                            memotype={"일반"}
                            Info={memoListCommon}
                            deletePop={deletePop}
                            setDeletePop={setDeletePop}
                            setDeleteIdx={setDeleteIdx}
                            isDelete={isDelete}
                            setIsDelete={setIsDelete}
                        />
                    )}
                    {memoListDobae.length > 0 && (
                        <TableMemo
                            memotype={"도배"}
                            Info={memoListDobae}
                            deletePop={deletePop}
                            setDeletePop={setDeletePop}
                            setDeleteIdx={setDeleteIdx}
                            isDelete={isDelete}
                            setIsDelete={setIsDelete}
                        />
                    )}

                    {memoListZangpan.length > 0 && (
                        <TableMemo
                            memotype={"장판"}
                            Info={memoListZangpan}
                            deletePop={deletePop}
                            setDeletePop={setDeletePop}
                            setDeleteIdx={setDeleteIdx}
                            isDelete={isDelete}
                            setIsDelete={setIsDelete}
                        />
                    )}
                    {memoListMaru.length > 0 && (
                        <TableMemo
                            memotype={"마루"}
                            Info={memoListMaru}
                            deletePop={deletePop}
                            setDeletePop={setDeletePop}
                            setDeleteIdx={setDeleteIdx}
                            isDelete={isDelete}
                            setIsDelete={setIsDelete}
                        />
                    )}
                    {memoListSignificant.length > 0 && (
                        <TableMemo
                            memotype={"특이사항"}
                            Info={memoListSignificant}
                            deletePop={deletePop}
                            setDeletePop={setDeletePop}
                            isDelete={isDelete}
                            setIsDelete={setIsDelete}
                        />
                    )}

                    <HistoryMessageBox
                        Info={customerMsg}
                        page={customMsgPage}
                        handlePageChange={handleCustomMsgPageChange}
                        totalCount={customMsgTotal}
                    />
                    <HistorySusinBox
                        page={page}
                        Info={susinData}
                        handlePageChange={handlePageChange}
                        totalCount={totalSusinCount}
                    />
                    <HistoryMessageBox
                        IsWorker={true}
                        Info={workerMsg}
                        page={workerMsgPage}
                        handlePageChange={handleWorkerMsgPageChange}
                        totalCount={workerMsgTotal}
                    />
                </div>
            </div>
        </>
    );
}

export default ConstrManagPreview;
