import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
    BoxContent,
    BoxDropdownInput,
    BoxImageInput,
    BoxMemoInput,
    BoxRadioboxInput,
    BoxSendContent,
    BoxTextInput,
} from "../../../components/BoxInfo";
import { HistoryBox } from "../../../components/HistoryBox";
import { SendHistory } from "../../../components/Tables";

import routes from "../../../libs/routes";
import { useGetData, usePostData } from "../../../utils/service";
import ApiData from "../../../libs/api";
import { useEffect, useState } from "react";
import moment from "moment";
import consts from "../../../libs/consts";
import InputFile from "../../../components/InputFile";
import { toast } from "react-toastify";

import { useUser } from "../../../zustand/Login";
import { TabComponent } from "../../../components/TabComponent";
import useLoadingStore from "../../../zustand/Loading";
import { set } from "lodash";

function ReqForConsultDetail() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");

    const historyLook = true;

    const goMsg = usePostData(ApiData.goingMsg);
    const counselingDetail = usePostData(ApiData.counselingDetail);
    const counselingSave = usePostData(ApiData.counnselingDetailSave);
    const {
        data: templateApi,
        isLoading: templateIsLoading,
        error: templateError,
    } = useGetData(ApiData.mailTempList);
    const {
        data: CallerListApi,
        isLoading: CallerListIsLoading,
        error: CallerListError,
    } = useGetData(ApiData.CallerList);
    const { mbData } = useUser();
    const [templateData, setTemplateData] = useState([]);
    const [templateDropdown, setTemplateDropdown] = useState([
        { value: 0, text: "템플릿 선택" },
    ]);
    const [templateValue, setTemplateValue] = useState(0);
    const [CallerList, setCallerList] = useState([]);
    const [CallerDropdown, setCallerDropdown] = useState([]);
    const [CallerValue, setCallerValue] = useState(0);
    const [CallerPhone, setCallerPhone] = useState("");
    const [textareaContent, setTextareaContent] = useState("");
    const [recevingPhone, setRecevingPhone] = useState("");
    const [templateImg, setTemplateImg] = useState("");
    const [imgFileType, setImgFileType] = useState("");
    const [msgHistory, setMsgHistory] = useState([]);
    const [RadioValue, setRadioValue] = useState(0);
    const [memoContent, setMemoContent] = useState("");
    const [dataSetting, setDataSetting] = useState([]);
    const [product, setProduct] = useState("");
    const [price, setPrice] = useState("");
    const [contentChange, setContentChange] = useState();
    const [page, setPage] = useState(1);
    const [msgCount, setMsgCount] = useState(0);
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    // const { loadingStart, loadingEnd } = useLoadingStore();

    const SaveData = (estimateIn = "") => {
        let sender = {
            counselingIdx: index,
            counselingType: RadioValue,
            counselingMemo: memoContent,
        };

        if (estimateIn) {
            sender.estimateIn = parseInt(1);
        }
        // loadingStart();
        counselingSave
            .mutateAsync(sender)
            .then(({ data }) => {
                // loadingEnd();
                toast.success("저장에 성공했습니다.");
                CounselingDetailApi();
                // navigate(`${routes.constr1}`);
                if (estimateIn) {
                    navigate(
                        `${routes.constRegst1}?index=${data.estimate_idx}`
                    );
                }
            })
            .catch((e) => {
                // loadingEnd();
                toast.error("저장에 실패했습니다.");
            });
    };
    const SendClk = () => {
        let sender = {
            admIdx: mbData.idx,
            senderPhone: CallerPhone,
            getPhone: recevingPhone,
            msgContent: textareaContent,
            temImg: templateImg,
        };

        if (templateImg) {
            if (templateImg.includes("template")) {
                sender.imgType = "";
            } else {
                sender.imgType = 1;
                sender.fileType = imgFileType.split("/")[1];
            }
        } else {
        }

        goMsg
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("발송되었습니다.");
            })
            .catch((e) => {
                // console.log(e);
            });
    };

    useEffect(() => {
        setCallerList(CallerListApi?.data);
        setCallerPhone(CallerListApi?.data[0]?.phone);
    }, [CallerListApi]);
    useEffect(() => {
        CallerList?.map((item, index) => {
            setCallerDropdown((prev) => [
                ...prev,
                { value: item.idx, text: item.phone },
            ]);
        });
        return () => {
            setCallerDropdown([]);
        };
    }, [CallerList]);
    useEffect(() => {
        let CallerInfo = CallerList?.filter((item) => item.idx === CallerValue);
        if (CallerInfo?.length > 0) {
            setCallerPhone(CallerInfo[0]?.phone);
        }
    }, [CallerValue]);

    // useEffect(() => {
    //     console.log("contentChange", contentChange);
    // }, [contentChange]);
    useEffect(() => {
        // console.log("templateApi", templateApi);
        setTemplateData(templateApi?.data);
        // setTemplateValue(templateApi?.data[0]?.idx);
    }, [templateApi]);

    useEffect(() => {
        templateData?.map((item, index) => {
            // console.log("item", item);
            setTemplateDropdown((prev) => [
                ...prev,
                { value: item.idx, text: item.title },
            ]);
        });
        return () => {
            setTemplateDropdown([{ value: 0, text: "템플릿 선택" }]);
        };
    }, [templateData]);

    useEffect(() => {
        if (parseInt(templateValue) === 0) {
            setTextareaContent("");
            setTemplateImg("");
            return;
        }
        let tempSetting = templateData?.filter(
            (item) => item.idx === parseInt(templateValue)
        );
        // console.log("templateValue", templateValue);
        if (tempSetting?.length > 0) {
            // console.log("tempSetting", tempSetting);
            setTextareaContent(tempSetting[0]?.content);
            setTemplateImg(tempSetting[0]?.img_url);
        }
    }, [templateValue]);
    useEffect(() => {
        CounselingDetailApi();
    }, [index, page]);

    const CounselingDetailApi = () => {
        // loadingStart();
        counselingDetail
            .mutateAsync({ counselingIdx: index, page: page - 1 })
            .then(({ data }) => {
                console.log("data", data);
                // loadingEnd();
                setMsgCount(data?.msg_history.count);
                setDataSetting(data);
                setRecevingPhone(data?.detail?.mb_phone);
                setMsgHistory(data?.msg_history.rows);
                setRadioValue(parseInt(data?.detail?.counseling_type));
                setMemoContent(data?.detail?.counseling_memo);
                setPrice(
                    data?.detail?.sigong_type === 1
                        ? `실크 : ${data?.detail?.first_value}만원, 합지 : ${data?.detail?.second_value}만원, 혼합 : ${data?.detail.third_value}만원`
                        : `1.8mm : ${data?.detail.first_value}만원, 2.2mm : ${data?.detail.second_value}만원, 2.7mm : ${data?.detail.third_value}만원, 3.0mm이상 : 0만원`
                );

                // setContentChange(data?.history);
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
            });
    };
    useEffect(() => {
        if (contentChange) {
            setRadioValue(contentChange?.ori_counseling_type);
            setMemoContent(contentChange?.ori_memo);
        }
    }, [contentChange]);
    useEffect(() => {
        if (dataSetting?.detail?.sigong_type == 1) {
            switch (dataSetting?.detail?.dobae_product) {
                case 1:
                    setProduct("실크");

                    break;
                case 2:
                    setProduct("합지");

                    break;
                default:
                    setProduct("혼합");

                    break;
            }
        } else {
            switch (dataSetting?.detail?.zangpan_product) {
                case 1:
                    setProduct("장판 1.8mm ");

                    break;
                case 2:
                    setProduct("장판 2.2mm ");

                    break;
                case 3:
                    setProduct("장판 2.7mm ");

                    break;
                default:
                    setProduct("장판 3.0mm 이상");
                    break;
            }
        }
    }, [dataSetting]);

    return (
        <div className="content ">
            <TabComponent
                index={dataSetting?.tap?.counseling_idx}
                estimate_idx={dataSetting?.tap?.estimate_idx}
                sigonginfo_idx={dataSetting?.tap?.sigonginfo_idx}
                order_idx={dataSetting?.tap?.order_idx}
                workend_idx={dataSetting?.tap?.workend_idx}
                as_tbl={dataSetting?.tap?.as_tbl}
            />

            <div
                className="animate__animated animate__fadeIn"
                style={{ marginTop: "20px" }}
            >
                <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "right" }}>
                        <div
                            className="FlexCenter"
                            style={{
                                width: "160px",
                                backgroundColor: "#2a9d8f",
                                color: "white",
                                fontSize: "16px",
                                height: "40px",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                SaveData(true);
                            }}
                        >
                            견적상담으로 이동 {"  >"}
                        </div>
                    </div>
                    <HistoryBox
                        historyLook={historyLook}
                        Info={dataSetting?.history}
                        adminName={mbData?.mb_name}
                        setContentChange={setContentChange}
                    />
                    <div className="BorderBox">
                        <div className="title">
                            <h1>요청내역</h1>
                        </div>
                        <div className="contentWrap">
                            <BoxContent
                                title="등록일시"
                                content={
                                    moment(
                                        dataSetting?.detail?.create_dt
                                    ).format("YYYY.MM.DD HH:mm") || "-"
                                }
                            />
                            <BoxRadioboxInput
                                title="상태"
                                Radioes={consts.Confirm}
                                radioValue={RadioValue}
                                setRadioValue={setRadioValue}
                            />
                            <BoxContent
                                title="주거형태"
                                content={
                                    dataSetting?.detail?.residence === 1
                                        ? "아파트"
                                        : "빌라/오피스텔"
                                }
                            />
                            <BoxContent
                                title="면적"
                                content={
                                    dataSetting?.detail?.area_pyeong
                                        ? `${
                                              dataSetting?.detail?.area_pyeong
                                          }평(
                                    ${
                                        dataSetting?.detail?.area === 1
                                            ? "공급"
                                            : "전용"
                                    })` || "-"
                                        : "-"
                                }
                            />
                            <BoxContent
                                title="방개수"
                                content={`${
                                    dataSetting?.detail?.area_room_cnt
                                        ? dataSetting?.detail?.area_room_cnt +
                                          "개"
                                        : "-"
                                }`}
                            />
                            <BoxContent
                                title="베란다 확장"
                                content={`${
                                    dataSetting?.detail?.veranda == 1
                                        ? "베란다 확장 있음 "
                                        : "베란다 확장 없음"
                                }`}
                            />
                            <BoxContent
                                title="천장 도배"
                                content={`${
                                    dataSetting?.detail?.ceiling == 1
                                        ? "천장 도배 포함 "
                                        : "천장 도배 제외"
                                }`}
                            />
                            <BoxContent
                                title="시공당일 주거환경"
                                content={`${
                                    dataSetting?.detail?.today_status == 1
                                        ? "빈집 가구 없음 "
                                        : dataSetting?.detail?.today_status == 2
                                        ? "거주중 가구 있음"
                                        : "이사 당일"
                                }`}
                            />
                            <BoxContent title="자재" content={product} />
                            <BoxContent
                                title="벽지 색상"
                                content={
                                    dataSetting?.detail?.wall_color_cnt || "-"
                                }
                            />
                            <BoxContent
                                title="시공희망일"
                                content={
                                    dataSetting?.detail?.sigong_date || "-"
                                }
                            />

                            <BoxContent
                                title="연락처"
                                content={
                                    dataSetting?.detail?.mb_phone
                                        ? dataSetting?.detail?.mb_phone.replace(
                                              /(\d{3})(\d{4})(\d{4})/,
                                              "$1-$2-$3"
                                          )
                                        : "-"
                                }
                            />
                            <BoxContent
                                title="시공 가격"
                                content={`[${
                                    dataSetting?.detail?.sigong_type == 1
                                        ? "도배"
                                        : "장판"
                                }] ${price}`}
                            />
                            <BoxMemoInput
                                value={memoContent}
                                setValue={(value) => {
                                    setMemoContent(value);
                                }}
                            />
                        </div>
                        <div className="BtnWrap">
                            <button
                                className="btn btn8"
                                onClick={() => {
                                    navigate(`${routes.constr1}`);
                                }}
                            >
                                목록
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() => {
                                    SaveData();
                                }}
                            >
                                {" "}
                                저장
                            </button>
                        </div>
                    </div>
                </div>
                <div className="BorderBox">
                    <div className="title">
                        <h1>문자 발송</h1>
                    </div>
                    <div className="contentSplitWrap">
                        <div
                            style={{ borderTop: "1px solid #ddd" }}
                            className="left"
                        >
                            <BoxDropdownInput
                                title="발송 번호"
                                width={50}
                                Dropdowns={CallerDropdown}
                                value={CallerValue}
                                setValue={setCallerValue}
                                defaultValue={"없음"}
                            />
                            <BoxTextInput
                                title="받는 번호"
                                inputWidth={60}
                                value={recevingPhone}
                                setValue={setRecevingPhone}
                                placeholder="번호 입력"
                                readOnly={true}
                            />
                            <BoxDropdownInput
                                title="템플릿"
                                width={60}
                                value={templateValue}
                                setValue={setTemplateValue}
                                Dropdowns={templateDropdown}
                                defaultValue={"없음"}
                            />
                            <BoxSendContent
                                value={textareaContent}
                                setValue={setTextareaContent}
                            />
                            <div
                                style={{
                                    border: "none",
                                    borderBottom: "1px solid #ddd",
                                }}
                                className="ImgInputWrap"
                            >
                                <div className="Imgtitle">이미지</div>
                                <div className="sub">
                                    <InputFile
                                        type="file"
                                        name={"img_url"}
                                        value={
                                            templateImg &&
                                            (templateImg.includes("template")
                                                ? `${consts.ServerImgFolder}${templateImg}`
                                                : `${templateImg}`)
                                        }
                                        alt={"Template"}
                                        onChange={(idx, name, result) => {
                                            setTemplateImg(result);
                                        }}
                                        valid="image"
                                        // style={{ flex: 1.68 }}
                                        imgStyle={{
                                            width: "40%",
                                        }}
                                        setFileType={(type) => {
                                            setImgFileType(type);
                                        }}
                                    />
                                    <button
                                        type="button"
                                        style={{
                                            fontSize: "12px",
                                            position: "absolute",
                                            bottom: "10px",
                                            right: "10px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setTemplateImg("")}
                                    >
                                        삭제하기
                                    </button>
                                </div>
                            </div>
                            <button
                                className="btn btn9"
                                style={{
                                    marginTop: "20px",
                                }}
                                onClick={() => {
                                    SendClk();
                                }}
                            >
                                발송하기
                            </button>
                        </div>
                        <div className="right">
                            <h1
                                style={{
                                    fontSize: "16px",
                                    marginBottom: "20px",
                                }}
                            >
                                발송 히스토리
                            </h1>
                            <SendHistory
                                list={msgHistory}
                                page={page}
                                handlePageChange={handlePageChange}
                                totalCount={msgCount}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReqForConsultDetail;
