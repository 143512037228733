import { create } from "zustand";

const useEnterStore = create((set) => ({
    dataList: (reset = false) => {
        // dataList 함수의 내용
    },
    setDataList: (dataList) => set({ dataList }),
}));

export default useEnterStore;
