import images from "../libs/images";

import consts from "../libs/consts";

export function MemoBox({
    memoWrite,
    setMemoWrite,
    memoType,
    setMemoType,
    submitClk,
}) {
    const handleMemoType = (e) => {
        setMemoType(parseInt(e.target.value));
    };

    return (
        <div className="MemoBoxWrap">
            <div className="title">
                <img src={images.pencil} />
                <p>메모</p>
            </div>

            <div className="MemoWrap">
                <div className="radioWrap">
                    {consts.memoRadio.map((c, i) => (
                        <div key={i}>
                            <input
                                className="radio"
                                type="radio"
                                id={"memo" + i}
                                name="memo"
                                checked={memoType === c.value}
                                value={c.value}
                                onChange={handleMemoType}
                            />
                            <label htmlFor={"memo" + i}>{c.label}</label>
                        </div>
                    ))}
                </div>
                <textarea
                    className="MemoBox"
                    value={memoWrite}
                    onChange={(e) => setMemoWrite(e.target.value)}
                ></textarea>
                <div className="btnWrap">
                    <button className="btn btn8" onClick={submitClk}>
                        저장
                    </button>
                </div>
            </div>
        </div>
    );
}
