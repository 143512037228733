import { useEffect, useState } from "react";
import Input from "../../components/Input";
import { useGetData, usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import { toast } from "react-toastify";
import consts from "../../libs/consts";
import { InputRadioBox } from "../../components/InputRadioBox";
import InputSelect from "../../components/InputSelect";
import { fi } from "date-fns/locale";
import {
    PriceDobaeTable,
    PriceTable,
    PriceZangpanTable,
} from "../../components/Tables";
import Pagination from "react-js-pagination";
function PriceSetting() {
    const PriceUpdate = usePostData(ApiData.PriceUpdate);
    const PriceDobaeList = usePostData(ApiData.PriceDobaeList);
    const PriceZangpanList = usePostData(ApiData.PriceZangpanList);
    const PriceWallPaperUpdate = usePostData(ApiData.PriceWallPaperUpdate);
    const { data: PriceWallPaper } = useGetData(ApiData.PriceWallPaper);
    const [type, setType] = useState(1);
    const [living, setLiving] = useState(1);
    const [veranda, setVeranda] = useState(2);
    const [event, setEvent] = useState(0);

    const [innerType, setInnerType] = useState(1);
    const [innerLiving, setInnerLiving] = useState(1);
    const [innerVeranda, setInnerVeranda] = useState(2);
    const [innerEvent, setInnerEvent] = useState(0);
    const [innerCeiling, setinnerCeiling] = useState(2);
    const [innerRoom, setInnerRoom] = useState(1);
    const [firstValue, setFirstValue] = useState(0);
    const [secondValue, setSecondValue] = useState(0);
    const [thirdValue, setThirdValue] = useState(0);
    const [WallpaperPrice, setWallpaperPrice] = useState(0);
    const [pyeong, setPyeong] = useState(15);

    const [DataDobaeList, setDataDobaeList] = useState([]);

    const [DataZangpanList, setDataZangpanList] = useState([]);

    const [page, setPage] = useState(1);
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    const PriceWallPaperUpdateApi = () => {
        if (WallpaperPrice === 0) {
            toast.error("벽지 가격을 입력해주세요.");
            return;
        }
        let sender = {
            WallpaperPrice: WallpaperPrice,
        };
        PriceWallPaperUpdate.mutateAsync(sender)
            .then((data) => {
                toast.success("저장되었습니다.");
            })
            .catch((error) => {
                toast.error("저장에 실패했습니다.");
            });
    };

    const PriceListApi = (reset = false) => {
        let sender = {
            living: living,
            veranda: veranda,
            event: event,
            page: page - 1,
        };
        if (reset === true) {
            setType(1);
            setLiving(1);
            setVeranda(2);
            setEvent(0);
            sender = {
                living: 1,
                veranda: 0,
                event: 0,
                page: 0,
            };
        }
        PriceDobaeList.mutateAsync(sender)
            .then(({ data }) => {
                setDataDobaeList(data?.rows);
            })
            .catch((error) => {
                toast.error("데이터를 불러오는데 실패했습니다.");
            });
        PriceZangpanList.mutateAsync(sender)
            .then(({ data }) => {
                // console.log("!!!@#", data);
                setDataZangpanList(data?.rows);
            })
            .catch((error) => {
                toast.error("데이터를 불러오는데 실패했습니다.");
            });
    };
    const PriceUpdateApi = () => {
        let sender = {
            type: innerType,
            Living: innerLiving,
            Veranda: innerVeranda,
            Event: innerEvent,
            pyeong: pyeong,
            firstValue: firstValue,
            secondValue: secondValue,
            thirdValue: thirdValue,
        };
        if (innerType === 1) {
            sender.Ceiling = innerCeiling;
        }
        if (innerType === 2) {
            sender.Room = innerRoom;
        }
        if (parseInt(pyeong) <= 14) {
            toast.error("공급평수는 15평 이상이어야 합니다.");
            return;
        }
        console.log("sender", sender);
        PriceUpdate.mutateAsync(sender)
            .then((data) => {
                setInnerType(1);
                setInnerLiving(1);
                setInnerVeranda(2);
                setInnerEvent(0);
                setinnerCeiling(2);
                setInnerRoom(1);
                setFirstValue(0);
                setSecondValue(0);
                setThirdValue(0);
                setPyeong(15);
                PriceListApi();
                toast.success("저장되었습니다.");
            })
            .catch((error) => {
                toast.error("저장에 실패했습니다.");
            });
    };
    useEffect(() => {
        PriceListApi();
    }, [page]);
    useEffect(() => {
        setWallpaperPrice(PriceWallPaper?.data?.wall_cnt_price || 0);
    }, [PriceWallPaper]);

    return (
        <>
            <div className="content ">
                <p className="title">가격설정 검색</p>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <InputRadioBox
                            title="시공타입"
                            Radioes={consts.sigongType}
                            setValue={setType}
                            value={type}
                            radioName="type"
                        />
                        <InputRadioBox
                            title="유형"
                            Radioes={consts.living}
                            setValue={setLiving}
                            value={living}
                            radioName="living"
                        />
                    </div>
                    <div className="search_box_wrap">
                        <InputRadioBox
                            title="베란다 확장"
                            Radioes={consts.BarandaExpansion2}
                            setValue={setVeranda}
                            value={veranda}
                            radioName="veranda"
                        />
                        <InputRadioBox
                            title="이벤트"
                            Radioes={consts.event}
                            setValue={setEvent}
                            value={event}
                            radioName="event"
                        />
                    </div>
                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button
                                className="btn btn8"
                                onClick={() => {
                                    PriceListApi(true);
                                }}
                            >
                                초기화
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() => {
                                    PriceListApi();
                                }}
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>
                <p className="title">가격저장</p>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <InputRadioBox
                            title="시공타입"
                            Radioes={consts.sigongType}
                            setValue={setInnerType}
                            value={innerType}
                            radioName="innertype"
                        />
                        <InputRadioBox
                            title="유형"
                            Radioes={consts.living}
                            setValue={setInnerLiving}
                            value={innerLiving}
                            radioName="innerLiving"
                        />
                    </div>
                    <div className="search_box_wrap">
                        <InputRadioBox
                            title="베란다 확장"
                            Radioes={consts.BarandaExpansion2}
                            setValue={setInnerVeranda}
                            value={innerVeranda}
                            radioName="innerVeranda"
                        />
                        {innerType === 1 ? (
                            <InputRadioBox
                                title="천장"
                                Radioes={consts.Ceiling2}
                                setValue={setinnerCeiling}
                                value={innerCeiling}
                                radioName="innerCeiling"
                            />
                        ) : (
                            <InputRadioBox
                                title="방"
                                Radioes={consts.room}
                                setValue={setInnerRoom}
                                value={innerRoom}
                                radioName="innerRoom"
                            />
                        )}

                        <InputRadioBox
                            title="이벤트"
                            Radioes={consts.event}
                            setValue={setInnerEvent}
                            value={innerEvent}
                            radioName="innerEvent"
                        />
                    </div>
                    <div className="search_form" style={{ padding: "0 20px" }}>
                        <Input
                            className="input_text"
                            type="number"
                            placeholder="공급 평수"
                            name="stx"
                            value={pyeong}
                            setValue={setPyeong}
                            label="공급 평수"
                            style={{ width: "50%" }}
                        />
                    </div>
                    <div
                        className="search_form"
                        style={{
                            padding: "10px 20px",
                            display: "flex",
                            gap: "40px",
                        }}
                    >
                        <Input
                            className="input_text"
                            type="number"
                            placeholder={innerType === 1 ? "합지" : "1.8mm"}
                            name="stx"
                            value={firstValue}
                            setValue={setFirstValue}
                            label={innerType === 1 ? "합지" : "1.8mm"}
                            style={{ width: "30%" }}
                        />
                        <Input
                            className="input_text"
                            type="number"
                            placeholder={innerType === 1 ? "실크" : "2.2mm"}
                            name="stx"
                            value={secondValue}
                            setValue={setSecondValue}
                            label={innerType === 1 ? "실크" : "2.2mm"}
                            style={{ width: "30%" }}
                        />
                        <Input
                            className="input_text"
                            type="number"
                            placeholder={innerType === 1 ? "혼합" : "2.7mm"}
                            name="stx"
                            value={thirdValue}
                            setValue={setThirdValue}
                            label={innerType === 1 ? "혼합" : "2.7mm"}
                            style={{ width: "30%" }}
                        />
                    </div>
                    <div className="search_box_wrap_center">
                        <button
                            className="btn btn9"
                            onClick={() => {
                                PriceUpdateApi();
                            }}
                        >
                            저장
                        </button>
                    </div>
                </div>
                <p className="title">벽지가격 저장</p>
                <div className="search_box">
                    <div
                        className="search_form"
                        style={{
                            padding: "0 20px",
                            justifyContent: "space-between",
                        }}
                    >
                        <Input
                            className="input_text"
                            type="number"
                            placeholder="벽지 색상 가격"
                            name="stx"
                            value={WallpaperPrice}
                            setValue={setWallpaperPrice}
                            label="벽지 가격"
                        />
                        <button
                            className="btn btn9"
                            onClick={() => {
                                PriceWallPaperUpdateApi();
                            }}
                        >
                            저장
                        </button>
                    </div>
                </div>

                <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                    [{type === 1 ? "도배 가격표" : "장판 가격표"}]
                </p>
                {type === 1 ? (
                    <PriceDobaeTable type={1} list={DataDobaeList} />
                ) : (
                    <PriceZangpanTable type={2} list={DataZangpanList} />
                )}
            </div>
            <div className="PaginationContent">
                <Pagination
                    className="my-pagination"
                    // 현제 보고있는 페이지
                    activePage={page}
                    // 한페이지에 출력할 아이템수
                    itemsCountPerPage={20}
                    // 총 아이템수
                    totalItemsCount={
                        type === 1
                            ? Object.keys(DataDobaeList || {}).length || 0
                            : Object.keys(DataZangpanList || {}).length || 0
                    }
                    // 표시할 페이지수
                    pageRangeDisplayed={10}
                    // 함수
                    onChange={handlePageChange}
                    nextPageText=""
                    prevPageText=""
                    firstPageText=""
                    lastPageText=""
                ></Pagination>
            </div>
        </>
    );
}
export default PriceSetting;
