import { useEffect, useState } from "react";
import Input from "../../components/Input";
import Pagination from "react-js-pagination";
import {
    usePostData,
    useSusinPostData,
    useSusinSendPostData,
} from "../../utils/service";
import ApiData from "../../libs/api";
import moment from "moment";
import { PopupContentNImg } from "../../components/PopUp";

function IncomingList() {
    const SusinConnect = useSusinPostData(ApiData.apiConnect);
    // const RetryConnect = useSusinPostData(ApiData.apiRetryConnect);
    const msgReceiveList = usePostData(ApiData.msgReceiveList);
    // const SusinList = useSusinSendPostData(ApiData.apiSusinList);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const [searchVal, setSearchVal] = useState("");
    const [page, setPage] = useState(1);
    const [popimgUrl, setPopImgUrl] = useState("");
    const [openPop, setOpenPop] = useState(false);
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    const ContentImgClk = (img) => {
        setPopImgUrl(img);
        setOpenPop(!openPop);
    };
    const SusinListApi = () => {
        let sender = {
            bizNum: "0515815258",
            messageType: "SMS",
            startDate: "",
            endDate: "",
            offset: page - 1,
            searchVal: searchVal,
            token: localStorage.getItem("tokensAccessMsg"),
        };
        // console.log("sender", sender);
        msgReceiveList
            .mutateAsync(sender)
            .then(({ data }) => {
                setTotalCount(data.totalCount);
                setData(data.rows);
                // console.log(data);
            })
            .catch((error) => {});
    };
    // const RetryConnectApi = () => {
    //     RetryConnect.mutateAsync({
    //         accessToken: localStorage.getItem("tokensAccessMsg"),
    //         refreshToken: localStorage.getItem("tokensRefreshMsg"),
    //     })
    //         .then(({ data }) => {
    //             localStorage.setItem(
    //                 "tokensAccessMsg",
    //                 data.rows[0].accessToken
    //             );
    //             localStorage.setItem(
    //                 "tokensRefreshMsg",
    //                 data.rows[0].refreshToken
    //             );
    //             SusinListApi();
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };

    const SusinConnectApi = () => {
        SusinConnect.mutateAsync({
            apiKey: "wlUq55z9Az",
            secret: "sH2RsJMzcwJyqaaV",
        })
            .then(({ data }) => {
                // console.log("data???", data);
                localStorage.setItem(
                    "tokensAccessMsg",
                    data.rows[0].accessToken
                );
                localStorage.setItem(
                    "tokensRefreshMsg",
                    data.rows[0].refreshToken
                );
                SusinListApi();
            })
            .catch((error) => {
                // RetryConnectApi();
            });
    };
    useEffect(() => {
        const fetchData = async () => {
            // if (localStorage.getItem("tokensAccessMsg") !== null) {
            //     await RetryConnectApi();
            // } else {
            //     await SusinConnectApi();
            // }
            await SusinConnectApi();
        };

        fetchData();
    }, [page]);
    return (
        <>
            <PopupContentNImg
                popTitle={"이미지"}
                popContent={""}
                popImg={popimgUrl}
                OpenPop={openPop}
                setOpenPop={setOpenPop}
            />
            <div className="content ">
                <p className="title">수신목록</p>
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        alignContent: "center",
                        width: "40%",
                        margin: "20px 0",
                    }}
                >
                    <Input
                        className="input_text"
                        type="text"
                        placeholder="발신자, 발신번호, 받는번호"
                        name="stx"
                        value={searchVal}
                        setValue={setSearchVal}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                SusinListApi();
                            }
                        }}
                        // label="검색"
                    />
                    <button
                        className="btn btn9"
                        onClick={() => {
                            SusinListApi();
                        }}
                    >
                        검색
                    </button>
                </div>
                <div>
                    <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                        [총 {totalCount}건]
                    </p>
                </div>
                <div className="table_scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>수신시간</th>
                                {/* <th>발신자</th> */}
                                <th>발신번호</th>
                                <th>수신번호</th>
                                <th>문자내용</th>
                                <th>이미지</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            {moment(x.receiveTime).format(
                                                "YYYY-MM-DD HH:mm:ss"
                                            )}
                                        </td>
                                        {/* <td>{x.sender}</td> */}
                                        <td>{x.customerNum}</td>
                                        <td>{x.bizNum}</td>
                                        <td>{x.message}</td>
                                        <td
                                            style={{
                                                cursor: "pointer",
                                                color: "#007bff",
                                            }}
                                            onClick={() => {
                                                if (x.images !== null) {
                                                    ContentImgClk(x.images[0]);
                                                }
                                            }}
                                        >
                                            {x.images == null ? "-" : "이미지"}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
                className="my-pagination"
                // 현제 보고있는 페이지
                activePage={page}
                // 한페이지에 출력할 아이템수
                itemsCountPerPage={20}
                // 총 아이템수
                totalItemsCount={totalCount || 0}
                // 표시할 페이지수
                pageRangeDisplayed={10}
                // 함수
                onChange={handlePageChange}
                nextPageText=""
                prevPageText=""
                firstPageText=""
                lastPageText=""
            ></Pagination>
        </>
    );
}

export default IncomingList;
