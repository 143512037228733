import { useEffect, useState } from "react";
import {
    BoxAdressInput,
    BoxArrayContent2,
    BoxButtons,
    BoxCheckboxInput,
    BoxContent,
    BoxDateInput,
    BoxDepositInput,
    BoxDropdownInput,
    BoxDropdownMemoInput,
    BoxDropdownTime,
    BoxDropdownTwoInput,
    BoxImageInput,
    BoxJustDropDown,
    BoxMemoInput,
    BoxRadioboxInput,
    BoxTextInput,
    BoxTotalPrice,
} from "../../../components/BoxInfo";
import {
    HistoryBox,
    HistoryMessageBox,
    HistorySusinBox,
} from "../../../components/HistoryBox";
import { MemoBox } from "../../../components/MemoBox";
import moment from "moment";
import {
    ModelNumTableEdit,
    ModelNumTableEdit2,
    ModelNumTableEdit3,
    TableEditClasfy,
} from "../../../components/TableEdit";
import { TableMemo } from "../../../components/Tables";
import consts from "../../../libs/consts";
import routes from "../../../libs/routes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TabComponent } from "../../../components/TabComponent";
import { useUser } from "../../../zustand/Login";
import InputFile from "../../../components/InputFile";
import ApiData from "../../../libs/api";
import images from "../../../libs/images";
import {
    useGetData,
    usePostData,
    useSusinPostData,
} from "../../../utils/service";
import { toast } from "react-toastify";
import { PopupComp } from "../../../components/PopUp";
import { set } from "lodash";
import useLoadingStore from "../../../zustand/Loading";
import Input from "../../../components/Input";
import Pagination from "react-js-pagination";

function ConstrManagementRegister() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const {
        data: ManagerData,
        isLoading,
        isError,
    } = useGetData(ApiData.adminIdList);
    const SusinConnect = useSusinPostData(ApiData.apiConnect);
    const msgReceiveList = usePostData(ApiData.msgReceiveList);
    const { data: WorkerListData } = useGetData(ApiData.sigongWorkerList);
    const companyListData = usePostData(ApiData.EstimateCompanyList);
    const sigongInfoDetail = usePostData(ApiData.sigongInfoDetail);
    const sigongPopupFilter = usePostData(ApiData.sigongPopupFilter);
    const sigongInfoMateIn = usePostData(ApiData.sigongInfoMateIn);
    const sigongInfoContractMsg = usePostData(ApiData.sigongInfoContractMsg);
    const sigongInfoOrderMsg = usePostData(ApiData.sigongInfoOrderMsg);
    const sigongInfoIn = usePostData(ApiData.sigongInfoIn);
    const memoRegister = usePostData(ApiData.sigongInfoMemoIn);
    const sigonginfoMsgList = usePostData(ApiData.sigonginfoMsgList);
    const sigongInfoMemoDelete = usePostData(ApiData.memoEstimateDelete);
    const zangpanMMType = usePostData(ApiData.zangpanMMType);
    const memoList = usePostData(ApiData.memoEstimateList);
    const historyLook = true;
    const [ContentHistory, setContentHistory] = useState([]);
    const [consultSummary, setConsultSummary] = useState("");
    const [ContentChange, setContentChange] = useState({});
    const [sigongWant, setSigongWant] = useState("");
    const [manager, setManager] = useState(1);
    const [managerSetting, setManagerSetting] = useState([]);
    const [customerName, setCustomerName] = useState("");
    const [phone, setPhone] = useState("");
    const [dobaeOn, setDobaeOn] = useState(0);
    const [jangpanOn, setJangpanOn] = useState(0);
    const [maruOn, setMaruOn] = useState(0);
    const [ShowContent, setShowContent] = useState(true);
    const [address, setAddress] = useState("");
    const [adressDetail, setAdressDetail] = useState("");
    const [area, setArea] = useState(0);
    const [areaPyeong, setAreaPyeong] = useState(0);
    const [directAreaPyeong, setDirectAreaPyeong] = useState(0);
    const [planUrl, setPlanUrl] = useState("");
    const [planImg, setPlanImg] = useState("");
    const [planImgfileType, setPlanImgFileType] = useState("");
    const [todayStatus, setTodayStatus] = useState(0);
    const [todayMemo, setTodayMemo] = useState("");
    const [veranda, setVeranda] = useState(0);
    const [verandaMemo, setVerandaMemo] = useState("");

    const [dobaeDate, setDobaeDate] = useState(new Date());
    const [dobaeTime, setDobaeTime] = useState("");
    const [dobaeTimeDinput, setDobaeTimeDinput] = useState("");
    const [dobaeRange, setDobaeRange] = useState(0);
    const [dobaeRangeMemo, setDobaeRangeMemo] = useState("");
    const [dobaeCeiling, setDobaeCeiling] = useState(0);
    const [dobaeCreateCompanyName, setDobaeCreateCompanyName] = useState("");
    const [dobaeCompany, setDobaeCompany] = useState([
        { value: "", text: "직접입력" },
    ]);
    const [dobaeDirectCompany, setDobaeDirectCompany] = useState("");
    const [dobaeProduct, setDobaeProduct] = useState(0);
    const [dobaeCnt, setDobaeCnt] = useState(0);
    const [dobaeDirectCnt, setDobaeDirectCnt] = useState(0);
    const [dobaePrice, setDobaePrice] = useState(0);

    const [zangpanMMList, setZangpanMMList] = useState([]);
    const [zangpanDate, setZangpanDate] = useState("");
    const [zangpanTime, setZangpanTime] = useState("");
    const [zangpanTimeDinput, setZangpanTimeDinput] = useState("");
    const [zangpanRange, setZangpanRange] = useState(0);
    const [zangpanRangeMemo, setZangpanRangeMemo] = useState("");
    const [zangpanCreateCompanyName, setZangpanCreateCompanyName] =
        useState("");
    const [zangpanCompany, setZangpanCompany] = useState([
        { value: "", text: "직접입력" },
    ]);
    const [zangpanDirectCompany, setZangpanDirectCompany] = useState("");
    const [zangpanMM, setZangpanMM] = useState("");
    const [zangpanMolding, setZangpanMolding] = useState(0);
    const [zangpanMoldingMemo, setZangpanMoldingMemo] = useState("");
    const [zangpanMoldingRange, setZangpanMoldingRange] = useState(1);
    const [zangpanMoldingRangeMemo, setZangpanMoldingRangeMemo] = useState("");
    const [zangpanThreshold, setZangpanThreshold] = useState(2);
    const [zangpanPrice, setZangpanPrice] = useState(0);

    const [maruDate, setMaruDate] = useState("");
    const [maruTime, setMaruTime] = useState("");
    const [maruTimeDinput, setMaruTimeDinput] = useState("");
    const [maruRange, setMaruRange] = useState(0);
    const [maruRangeMemo, setMaruRangeMemo] = useState("");
    const [maruCreateCompanyName, setMaruCreateCompanyName] = useState("");
    const [maruCompany, setMaruCompany] = useState([
        { value: "", text: "직접입력" },
    ]);
    const [maruDirectCompany, setMaruDirectCompany] = useState("");
    const [maruFlooring, setMaruFlooring] = useState(1);
    const [maruFlooringMemo, setMaruFlooringMemo] = useState("");
    const [maruOldProduct, setMaruOldProduct] = useState("");
    const [maruDownMolding, setMaruDownMolding] = useState(2);
    const [maruThreshold, setMaruThreshold] = useState(2);
    const [maruRepair, setMaruRepair] = useState(2);
    const [maruPrice, setMaruPrice] = useState(0);
    const [demolitionDate, setDemolitionDate] = useState("");
    const [demolitionTime, setDemolitionTime] = useState("");
    const [deolitionTimeDinput, setDemolitionTimeDinput] = useState("");
    const [demolitionRange, setDemolitionRange] = useState("");
    const [demolitionPrice, setDemolitionPrice] = useState(0);

    const [createDt, setCreateDt] = useState(new Date());
    const [priceAll, setPriceAll] = useState([]);

    const [customerDirectMemo, setCustomerDirectMemo] = useState("");

    // memo

    const [memoWrite, setMemoWrite] = useState("");
    const [memoType, setMemoType] = useState(0);
    const [memoListData, setMemoListData] = useState([]);

    const [memoListCommon, setMemoListCommon] = useState([]);
    const [memoListDobae, setMemoListDobae] = useState([]);
    const [memoListZangpan, setMemoListZangpan] = useState([]);
    const [memoListMaru, setMemoListMaru] = useState([]);
    const [memoListSignificant, setMemoListSignificant] = useState([]);
    const [deletePop, setDeletePop] = useState(false);

    const [deleteIdx, setDeleteIdx] = useState(0);
    const [isDelete, setIsDelete] = useState(true);

    const [popupFlag, setPopupFlag] = useState(1);

    //시공관리 only
    const [enterPW, setEnterPW] = useState("");
    const [doorPW, setDoorPW] = useState("");
    const [customerMemo, setCustomerMemo] = useState("");
    const [customerContractChk, setCustomerContractChk] = useState(0);
    const [deposit, setDeposit] = useState(0);
    const [depositAmount, setDepositAmount] = useState(0);
    const [depositName, setDepositName] = useState("");
    const [customerFinalChk, setCustomerFinalChk] = useState(0);
    const [verandaExpansion, setVerandaExpansion] = useState(0);
    const [materialDate, setMaterialDate] = useState("");

    const [contractSendCount, setContractSendCount] = useState(0);
    const [depositSendCount, setDepositSendCount] = useState(0);
    const [finalSendCount, setFinalSendCount] = useState(0);
    const [productSelSendCount, setProductSelSendCount] = useState(0);
    const [materialDeleteList, setMaterialDeleteList] = useState([]);
    const [materialDeleteIdx, setMaterialDeleteIdx] = useState(0);
    const [tap, setTap] = useState({});
    const { mbData } = useUser();

    //시공관리 only 도배

    const [dobaeWorkers, setDobaeWorkers] = useState([
        { value: 0, text: "선택" },
    ]);
    const [dobaePersonnel, setDobaePersonnel] = useState(1);
    const [dobaeMakeManager, setDobaeMakeManager] = useState(0);
    const [dobaeMakeManagerName, setDobaeMakeManagerName] = useState("");
    const [dobaeWorkerMemo, setDobaeWorkerMemo] = useState("");
    const [dobaeOrderChk, setDobaeOrderChk] = useState(0);
    const [dobaeMaterials, setDobaeMaterials] = useState([]);
    const [dobaeMsgSendCount, setDobaeMsgSendCount] = useState(0);
    const [subMaterials, setSubMaterials] = useState([]); //도배 부자재

    const [dobaeWorkerInfo, setDobaeWorkerInfo] = useState([]);

    //시공관리 only 장판
    const [zangpanWorkers, setZangpanWorkers] = useState([
        { value: 0, text: "선택" },
    ]);
    const [zangpanPersonnel, setZangpanPersonnel] = useState(1);
    const [zangpanMakeManager, setZangpanMakeManager] = useState(1);
    const [zangpanMakeManagerName, setZangpanMakeManagerName] = useState("");
    const [zangpanWorkerMemo, setZangpanWorkerMemo] = useState("");
    const [zangpanSendCount, setZangpanSendCount] = useState(0);
    const [zangpanOrderChk, setZangpanOrderChk] = useState(0);
    const [zangpanWorkerInfo, setZangpanWorkerInfo] = useState([]);

    const [zangpanMaterials, setZangpanMaterials] = useState([]);
    const [zangpanMop, setZangpanMop] = useState([]);

    //시공관리 only 마루
    const [maruWorkers, setMaruWorkers] = useState([
        { value: 0, text: "선택" },
    ]);
    const [maruPersonnel, setMaruPersonnel] = useState(1);
    const [maruMakeManager, setMaruMakeManager] = useState(1);
    const [maruMakeManagerName, setMaruMakeManagerName] = useState("");
    const [maruSendCount, setMaruSendCount] = useState(0);
    const [maruWorkerMemo, setMaruWorkerMemo] = useState("");
    const [maruOrderChk, setMaruOrderChk] = useState(0);
    const [maruMaterials, setMaruMaterials] = useState([]);
    const [maruMop, setMaruMop] = useState([]);
    const [maruWorkerInfo, setMaruWorkerInfo] = useState([]);

    //시공 안내문 밑 내용
    const [apartUnit, setApartUnit] = useState("");
    const [constrUnit, setConstrUnit] = useState("");
    const [constrDetail, setConstrDetail] = useState("");
    const [constrStartDate, setConstrStartDate] = useState(new Date());
    const [constrEndDate, setConstrEndDate] = useState(new Date());
    const [constrNoise, setConstrNoise] = useState(new Date());
    const [constrFieldManager, setConstrFieldManager] = useState("");

    //팝업 관련
    const [OpenPop, setOpenPop] = useState(false);

    const [innerType, setInnerType] = useState(1);
    const [innerSelectCompany, setInnerSelectCompany] = useState("제조사");
    const [innerSelectFirstCategory, setInnerSelectFirstCategory] =
        useState("1차분류");
    const [innerSelectSecondCategory, setInnerSelectSecondCategory] =
        useState("2차분류");
    const [filterPopupList, setFilterPopupList] = useState([]);
    const [companyList, setCompanyList] = useState(["제조사"]);
    const [firstCategory, setFirstCategory] = useState(["1차분류"]);
    const [secondCategory, setSecondCategory] = useState(["2차분류"]);
    const [agencyValue, setAgencyValue] = useState([]);
    const [agencyMateListData, setAgencyMateListData] = useState([]);
    const [openPopRegFlag, setOpenPopRegFlag] = useState(0);
    const [materialSearchVal, setMaterialSearchVal] = useState("");

    //문자관리
    const [customerMsg, setCustomerMsg] = useState([]);
    const [workerMsg, setWorkerMsg] = useState([]);
    const [innerPage, setInnerPage] = useState(1);
    const [agencyListCount, setAgencyListCount] = useState(0);
    const handleMsgPageChange = (pageNumber) => {
        setMsgPage(pageNumber);
    };
    const [totalSusinCount, setTotalSusinCount] = useState(0);
    const [susinData, setSusinData] = useState([]);
    const [msgPage, setMsgPage] = useState(1);
    const [customMsgPage, setCustomMsgPage] = useState(1);
    const [customMsgTotal, setCustomMsgTotal] = useState(0);
    const [workerMsgPage, setWorkerMsgPage] = useState(1);
    const [workerMsgTotal, setWorkerMsgTotal] = useState(0);

    const zangpanMMTypeApi = () => {
        zangpanMMType
            .mutateAsync({ company: zangpanCreateCompanyName })
            .then(({ data }) => {
                setZangpanMMList(
                    data?.map((v) => {
                        return { value: v.class1_name, text: v.class1_name };
                    })
                );
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };

    const companyListDataApi = () => {
        companyListData
            .mutateAsync()
            .then(({ data }) => {
                setDobaeCompany(
                    [{ value: "", text: "선택해주세요" }].concat(
                        data?.dobae?.map((v) => {
                            return { value: v, text: v };
                        })
                    )
                );
                setZangpanCompany(
                    [{ value: "", text: "선택해주세요" }].concat(
                        data?.zangpan?.map((v) => {
                            return { value: v, text: v };
                        })
                    )
                );
                setMaruCompany(
                    [{ value: "", text: "선택해주세요" }].concat(
                        data?.maru?.map((v) => {
                            return { value: v, text: v };
                        })
                    )
                );
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };

    const handlePageChange = (pageNumber) => {
        setInnerPage(pageNumber);
    };
    const handleCustomMsgPageChange = (pageNumber) => {
        setCustomMsgPage(pageNumber);
    };
    const handleWorkerMsgPageChange = (pageNumber) => {
        setWorkerMsgPage(pageNumber);
    };

    const SusinListApi = () => {
        let sender = {
            bizNum: "0515815258",
            messageType: "SMS",
            startDate: "",
            endDate: "",
            offset: msgPage - 1,
            searchVal: phone || "",
            token: localStorage.getItem("tokensAccessMsg"),
        };
        // console.log("sender", sender);
        msgReceiveList
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log("data!!!!!!", data);
                setTotalSusinCount(data.totalCount);
                setSusinData(data.rows);
                // console.log(data);
            })
            .catch((error) => {});
    };

    const SusinConnectApi = () => {
        SusinConnect.mutateAsync({
            apiKey: "wlUq55z9Az",
            secret: "sH2RsJMzcwJyqaaV",
        })
            .then(({ data }) => {
                // console.log("data???", data);
                localStorage.setItem(
                    "tokensAccessMsg",
                    data.rows[0].accessToken
                );
                localStorage.setItem(
                    "tokensRefreshMsg",
                    data.rows[0].refreshToken
                );
                SusinListApi();
            })
            .catch((error) => {
                // RetryConnectApi();
            });
    };
    useEffect(() => {
        if (phone) {
            SusinConnectApi();
        }
    }, [msgPage, phone]);

    // const { loadingStart, loadingEnd } = useLoadingStore();
    const AddMaterial = () => {
        let sender = {
            material_company:
                innerSelectCompany === "제조사" ? "" : innerSelectCompany,
            material_class01:
                innerSelectFirstCategory === "1차분류"
                    ? ""
                    : innerSelectFirstCategory,
            material_class02:
                innerSelectSecondCategory === "2차분류"
                    ? ""
                    : innerSelectSecondCategory,
            searchVal: materialSearchVal,
            material_type: innerType,
            page: innerPage - 1,
        };
        if (innerSelectCompany === "제조사") {
            sender.material_class01 = "";
            setInnerSelectFirstCategory("1차분류");
        }
        if (innerSelectFirstCategory === "1차분류") {
            sender.material_class01 = "";
            setInnerSelectSecondCategory("2차분류");
        }
        sigongPopupFilter
            .mutateAsync(sender)
            .then(({ data }) => {
                setAgencyListCount(data?.list.count);
                setAgencyMateListData(data?.list.rows);
                setFilterPopupList(data?.filter);
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };
    const SigongMsgListApi = () => {
        sigonginfoMsgList
            .mutateAsync({
                sigonginfoIdx: index,
                customPage: customMsgPage - 1,
                workerPage: workerMsgPage - 1,
            })
            .then(({ data }) => {
                // console.log("data", data);
                setCustomMsgTotal(data?.customerMsg.count);
                setCustomerMsg(data?.customerMsg.rows);
                setWorkerMsgTotal(data?.workerMsg.count);
                setWorkerMsg(data?.workerMsg.rows);
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    useEffect(() => {
        SigongMsgListApi();
    }, [customMsgPage, workerMsgPage]);

    useEffect(() => {
        filterPopupList?.map((item, index) => {
            setCompanyList((prev) =>
                prev.includes(item.material_company)
                    ? prev
                    : [...prev, item.material_company]
            );
        });

        filterPopupList?.map((item, index) => {
            setFirstCategory((prev) =>
                prev.includes(item.material_class01)
                    ? prev
                    : [...prev, item.material_class01]
            );
        });
        filterPopupList?.map((item, index) => {
            setSecondCategory((prev) =>
                prev.includes(item.material_class02)
                    ? prev
                    : [...prev, item.material_class02]
            );
        });

        return () => {
            setCompanyList(["제조사"]);
            setFirstCategory(["1차분류"]);
            setSecondCategory(["2차분류"]);
        };
    }, [filterPopupList]);
    useEffect(() => {
        AddMaterial();
    }, [
        innerType,
        innerSelectCompany,
        innerSelectFirstCategory,
        innerSelectSecondCategory,
        OpenPop,
        materialSearchVal,
        innerPage,
    ]);
    const handleChange = (e) => {
        if (e.target.checked) {
            setAgencyValue([...agencyValue, parseInt(e.target.value)]);
        } else {
            setAgencyValue(
                agencyValue.filter(
                    (value) => value !== parseInt(e.target.value)
                )
            );
        }
    };
    useEffect(() => {
        ManagerData?.data?.map((item, index) => {
            setManagerSetting((prev) => [
                ...prev,
                { value: item.idx, text: item.mb_name },
            ]);
        });

        // setManagerSetting();
        return () => {
            setManagerSetting([]); // 상태를 초기화
        };
    }, [ManagerData]);

    useEffect(() => {
        if (Object.keys(ContentChange).length === 0) return;
        // console.log("ContentChange", ContentChange);
        setDobaeMaterials(ContentChange?.dobaeList);
        setSubMaterials(ContentChange?.subList);
        setZangpanMaterials(ContentChange?.zangpanList);
        setZangpanMop(ContentChange?.zangpanMopList);
        setMaruMaterials(ContentChange?.maruList);
        setMaruMop(ContentChange?.maruMopList);
        setContractSendCount(ContentChange?.contractCount);
        setDepositSendCount(ContentChange?.depositCount);
        setFinalSendCount(ContentChange?.finalCount);
        setProductSelSendCount(ContentChange?.productSelCount);
        setDobaeMsgSendCount(ContentChange?.dobaeMsgCount);
        setZangpanSendCount(ContentChange?.zangpanMsgCount);
        setMaruSendCount(ContentChange?.maruCount);

        setDobaeWorkerInfo(ContentChange?.dobaeWorker);
        setZangpanWorkerInfo(ContentChange?.zangpanWorker);
        setMaruWorkerInfo(ContentChange?.maruWorker);
        setCreateDt(ContentChange.create_dt);
        setCustomerName(ContentChange.cutomer_name);
        setPhone(ContentChange.mb_phone);
        setAddress(ContentChange.address);
        setAdressDetail(ContentChange.address_detail);
        setArea(ContentChange.area);
        if (
            consts.pyeong?.some((item) => {
                return item.value === parseInt(ContentChange.area_pyeong);
            })
        ) {
            setAreaPyeong(parseInt(ContentChange.area_pyeong));
        } else {
            setAreaPyeong(0);
            setDirectAreaPyeong(ContentChange.area_pyeong);
        }
        setAreaPyeong(parseInt(ContentChange.area_pyeong));
        setPlanUrl(ContentChange.plan_url);
        setPlanImg(ContentChange.plan_file);
        setTodayStatus(parseInt(ContentChange.today_status));
        setTodayMemo(ContentChange.today_memo);
        setVeranda(parseInt(ContentChange.veranda));
        setVerandaMemo(ContentChange.veranda_memo);
        setDobaeOn(parseInt(ContentChange.dobae));
        setJangpanOn(parseInt(ContentChange.zangpan));
        setMaruOn(parseInt(ContentChange.maru));
        setDobaeDate(
            ContentChange.dobae_date == null
                ? new Date()
                : ContentChange.dobae_date
        );
        setDobaeTime(ContentChange.dobae_time);
        setDobaeRange(parseInt(ContentChange.dobae_range));
        setDobaeRangeMemo(ContentChange.dobae_range_memo);
        setDobaeCeiling(ContentChange.dobae_ceiling);
        setDobaeCreateCompanyName(ContentChange.dobae_company_name);

        setDobaeProduct(parseInt(ContentChange.dobae_product));
        setDobaeCnt(parseInt(ContentChange.dobae_cnt));
        setDobaePrice(parseInt(ContentChange.dobae_price));
        setZangpanDate(
            ContentChange.zangpan_date == null
                ? new Date()
                : ContentChange.zangpan_date
        );
        setZangpanTime(ContentChange.zangpan_time);
        setZangpanRange(parseInt(ContentChange.zangpan_range));
        setZangpanRangeMemo(ContentChange.zangpan_range_memo);
        setZangpanCreateCompanyName(ContentChange.zangpan_company_name);

        setZangpanMM(ContentChange.zangpan_mm);
        setZangpanMolding(parseInt(ContentChange.zangpan_molding || 0));
        setZangpanMoldingMemo(ContentChange.zangpan_molding_memo);
        setZangpanMoldingRange(
            parseInt(ContentChange.zangpan_molding_range || 1)
        );
        setZangpanMoldingRangeMemo(ContentChange.zangpan_molding_range_memo);
        setZangpanThreshold(parseInt(ContentChange.zangpan_threshold));
        setZangpanPrice(parseInt(ContentChange.zangpan_price));
        setMaruDate(
            ContentChange.maru_date == null
                ? new Date()
                : ContentChange.maru_date
        );
        setMaruTime(ContentChange.maru_time);
        setMaruRange(parseInt(ContentChange.maru_range));
        setMaruRangeMemo(ContentChange.maru_range_memo);
        setMaruFlooring(parseInt(ContentChange.maru_flooring));
        setMaruFlooringMemo(ContentChange.maru_flooring_memo);
        setMaruDownMolding(parseInt(ContentChange.maru_molding));
        setMaruThreshold(parseInt(ContentChange.maru_threshold));
        setMaruRepair(parseInt(ContentChange.maru_cure));
        setMaruPrice(parseInt(ContentChange.maru_price));
        setMaruOldProduct(ContentChange.maru_ori_flooring);

        setDemolitionDate(
            ContentChange?.demolition_date == null
                ? new Date()
                : ContentChange?.demolition_date
        );
        setDemolitionTime(ContentChange?.demolition_time);
        setDemolitionRange(parseInt(ContentChange?.demolition_range || 0));
        setDemolitionPrice(parseInt(ContentChange?.demolition_price || 0));
        //시공관리 only
        setEnterPW(ContentChange.door_pass);
        setDoorPW(ContentChange.entrance_pass);
        setCustomerMemo(ContentChange.customer_memo);
        setCustomerContractChk(ContentChange.customer_contract_chk);
        setDeposit(ContentChange.customer_payment_chk); //계약금 입금여부 확인
        setDepositAmount(ContentChange.customer_payment);
        setDepositName(ContentChange.customer_payment_name);
        setCustomerFinalChk(ContentChange.customer_final_chk);
        setVerandaExpansion(ContentChange.material_chk);
        //시공 안내문 밑 내용
        setApartUnit(ContentChange.house_count);
        setConstrUnit(ContentChange.house_const_count);
        setConstrDetail(ContentChange.construction_memo);
        setConstrStartDate(ContentChange.const_start_date);
        setConstrEndDate(ContentChange.const_end_date);
        setConstrNoise(ContentChange.const_noise_date);
        setConstrFieldManager(ContentChange.field_manager);
        setMaruCreateCompanyName(ContentChange.maru_company_name);
        setDobaeMakeManager(
            parseInt(ContentChange.dobaeWorker[0]?.banzang_idx)
        );
        setCustomerDirectMemo(ContentChange.customer_direct_memo);
        setMaterialDate(ContentChange.material_date);
        setSigongWant(`
        ${
            ContentChange.dobae === 1
                ? `도배 : ${moment(
                      ContentChange.dobae_date
                          ? ContentChange.dobae_date
                          : ContentChange.create_dt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              ContentChange.dobae_date
                                  ? ContentChange.dobae_date
                                  : ContentChange.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }
        ${
            ContentChange.zangpan === 1
                ? `장판 : ${moment(
                      ContentChange.zangpan_date
                          ? ContentChange.zangpan_date
                          : ContentChange.create_dt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              ContentChange.zangpan_date
                                  ? ContentChange.zangpan_date
                                  : ContentChange.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }
        ${
            ContentChange.maru === 1
                ? `마루 : ${moment(
                      ContentChange.maru_date
                          ? ContentChange.maru_date
                          : ContentChange.create_dt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              ContentChange.maru
                                  ? ContentChange.maru
                                  : ContentChange.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }`);
        let summary = "";

        if (ContentChange.address || ContentChange.address_detail) {
            summary += `${ContentChange.address ? ContentChange.address : ""}${
                ContentChange.address_detail ? ContentChange.address_detail : ""
            }/ `;
        }

        summary += `${ContentChange.area === 1 ? "공급" : "전용"}면적 ${
            ContentChange.area_pyeong || 0
        }평/ `;

        if (ContentChange.veranda == 1) {
            summary += `베란다 확장 있음${
                ContentChange.veranda_memo
                    ? `(${ContentChange.veranda_memo})`
                    : ""
            }/ `;
        } else {
            summary += `베란다 확장 없음${
                ContentChange.veranda_memo
                    ? `(${ContentChange.veranda_memo})`
                    : ""
            }/ `;
        }

        summary += `${
            ContentChange.today_status == 1
                ? "빈집 가구 없음 "
                : ContentChange.today_status == 2
                ? "거주중 가구 있음"
                : "이사 당일"
        }`;

        if (ContentChange.today_memo) {
            summary += `(${ContentChange.today_memo})`;
        }

        setConsultSummary(summary);
    }, [ContentChange]);
    const sigongSendUpdate = () => {
        sigongInfoDetail
            .mutateAsync({
                sigongInfoIdx: index,
            })
            .then(({ data }) => {
                setContractSendCount(data?.contractCount);
                setDepositSendCount(data?.depositCount);
                setFinalSendCount(data?.finalCount);
                setProductSelSendCount(data?.productSelCount);
                setDobaeMsgSendCount(data?.dobaeMsgCount);
                setZangpanSendCount(data?.zangpanMsgCount);
                setMaruSendCount(data?.maruMsgCount);
                setCustomerFinalChk(data.detail?.customer_final_chk);
                SigongMsgListApi();
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const dataChange = (data) => {
        setDobaeMaterials(data?.dobae);
        setSubMaterials(data?.sub);
        setZangpanMaterials(data?.zangpan);
        setZangpanMop(data?.zangpanMop);
        setMaruMaterials(data?.maru);
        setMaruMop(data?.maruMop);
        setContractSendCount(data?.contractCount);
        setDepositSendCount(data?.depositCount);
        setFinalSendCount(data?.finalCount);
        setProductSelSendCount(data?.productSelCount);
        setDobaeMsgSendCount(data?.dobaeMsgCount);
        setZangpanSendCount(data?.zangpanMsgCount);
        setMaruSendCount(data?.maruMsgCount);
        // setMemoListData(data?.memoList);
        setDobaeWorkerInfo(data?.dobaeWorker);
        setZangpanWorkerInfo(data?.zangpanWorker);
        setMaruWorkerInfo(data?.maruWorker);
        if (data?.detail !== null) {
            // console.log("!!!!!!Data", data);
            setManager(data?.detail?.admin_idx);

            setCreateDt(data?.detail?.create_dt);
            setCustomerName(data?.detail?.cutomer_name);
            setPhone(data?.detail?.mb_phone);
            setAddress(data?.detail?.address);
            setAdressDetail(data?.detail?.address_detail);
            setArea(data?.detail?.area);
            if (
                consts.pyeong?.some((item) => {
                    return item.value === parseInt(data?.detail?.area_pyeong);
                })
            ) {
                setAreaPyeong(parseInt(data?.detail?.area_pyeong));
            } else {
                setAreaPyeong(0);
                setDirectAreaPyeong(data?.detail?.area_pyeong);
            }
            setAreaPyeong(parseInt(data?.detail?.area_pyeong));
            setPlanUrl(data?.detail?.plan_url);
            setPlanImg(data?.detail?.plan_file);
            setTodayStatus(parseInt(data?.detail?.today_status));
            setTodayMemo(data?.detail?.today_memo);
            setVeranda(parseInt(data?.detail?.veranda));
            setVerandaMemo(data?.detail?.veranda_memo);
            setDobaeOn(parseInt(data?.detail?.dobae));
            setJangpanOn(parseInt(data?.detail?.zangpan));
            setMaruOn(parseInt(data?.detail?.maru));
            setDobaeDate(
                data?.detail?.dobae_date == null
                    ? new Date()
                    : data?.detail?.dobae_date
            );
            setDobaeTime(data?.detail?.dobae_time);
            setDobaeRange(parseInt(data?.detail?.dobae_range || 0));
            setDobaeRangeMemo(data?.detail?.dobae_range_memo);
            setDobaeCeiling(data?.detail?.dobae_ceiling);
            setDobaeCreateCompanyName(data?.detail?.dobae_company_name);
            // if (
            //     dobaeCompany?.some((item) => {
            //         // console.log("item", item);
            //         return item.value === data?.detail?.dobae_company_name;
            //     })
            // ) {
            //     setDobaeCreateCompanyName(data?.detail?.dobae_company_name);
            // } else {
            //     // setDobaeCreateCompanyName("");
            //     setDobaeDirectCompany(data?.detail?.dobae_company_name);
            // }
            setDobaeProduct(parseInt(data?.detail?.dobae_product || 0));
            setDobaeCnt(parseInt(data?.detail?.dobae_cnt || 0));
            setDobaePrice(parseInt(data?.detail?.dobae_price || 0));
            setZangpanDate(
                data?.detail?.zangpan_date == null
                    ? new Date()
                    : data?.detail?.zangpan_date
            );
            setZangpanTime(data?.detail?.zangpan_time);
            setZangpanRange(parseInt(data?.detail?.zangpan_range));
            setZangpanRangeMemo(data?.detail?.zangpan_range_memo);
            setZangpanCreateCompanyName(data?.detail?.zangpan_company_name);
            // if (
            //     zangpanCompany?.some((item) => {
            //         return item.value === data?.detail?.zangpan_company_name;
            //     })
            // ) {
            //     setZangpanCreateCompanyName(data?.detail?.zangpan_company_name);
            // } else {
            //     // setZangpanCreateCompanyName("");
            //     setZangpanDirectCompany(data?.detail?.zangpan_company_name);
            // }
            setZangpanMM(data?.detail?.zangpan_mm);
            setZangpanMolding(parseInt(data?.detail?.zangpan_molding));
            setZangpanMoldingMemo(data?.detail?.zangpan_molding_memo);
            setZangpanMoldingRange(
                parseInt(data?.detail?.zangpan_molding_range || 1)
            );
            setZangpanMoldingRangeMemo(
                data?.detail?.zangpan_molding_range_memo
            );
            setZangpanThreshold(parseInt(data?.detail?.zangpan_threshold));
            setZangpanPrice(parseInt(data?.detail?.zangpan_price));
            setMaruDate(
                data?.detail?.maru_date == null
                    ? new Date()
                    : data?.detail?.maru_date
            );

            setMaruCreateCompanyName(data?.detail?.maru_company_name);
            // if (
            //     maruCompany?.some((item) => {
            //         return item.value === data?.detail?.maru_company_name;
            //     })
            // ) {
            //     setMaruCreateCompanyName(data?.detail?.maru_company_name);
            // } else {
            //     // setMaruCreateCompanyName("");
            //     setMaruDirectCompany(data?.detail?.maru_company_name);
            // }
            setMaruTime(data?.detail?.maru_time);
            setMaruRange(parseInt(data?.detail?.maru_range || 0));
            setMaruRangeMemo(data?.detail?.maru_range_memo);
            setMaruFlooring(parseInt(data?.detail?.maru_flooring || 1));
            setMaruFlooringMemo(data?.detail?.maru_flooring_memo);
            setMaruDownMolding(parseInt(data?.detail?.maru_molding));
            setMaruThreshold(parseInt(data?.detail?.maru_threshold));
            setMaruRepair(parseInt(data?.detail?.maru_cure));
            setMaruPrice(parseInt(data?.detail?.maru_price));
            setMaruOldProduct(data?.detail?.maru_ori_flooring);

            setDemolitionDate(
                data?.detail?.demolition_date == null
                    ? new Date()
                    : data?.detail?.demolition_date
            );
            setDemolitionTime(data?.detail?.demolition_time);
            setDemolitionRange(data?.detail?.demolition_range);
            setDemolitionPrice(parseInt(data?.detail?.demolition_price || 0));

            //시공관리 only
            setDobaeMakeManager(parseInt(data.dobaeWorker[0]?.banzang_idx));
            setEnterPW(data?.detail?.door_pass);
            setDoorPW(data?.detail?.entrance_pass);
            setCustomerMemo(data?.detail?.customer_memo);
            setCustomerContractChk(data?.detail?.customer_contract_chk);
            setDeposit(data?.detail?.customer_payment_chk); //BoxDeposComplete 확인
            setDepositAmount(data?.detail?.customer_payment);
            setDepositName(data?.detail?.customer_payment_name);
            setCustomerFinalChk(data?.detail?.customer_final_chk);
            setVerandaExpansion(data?.detail?.material_chk);

            //시공 안내문 밑 내용
            setApartUnit(data?.detail?.house_count);
            setConstrUnit(data?.detail?.house_const_count);
            setConstrDetail(data?.detail?.construction_memo);
            setConstrStartDate(data?.detail?.const_start_date);
            setConstrEndDate(data?.detail?.const_end_date);
            setConstrNoise(data?.detail?.const_noise_date);
            setConstrFieldManager(data?.detail?.field_manager);
            setCustomerDirectMemo(data?.detail?.customer_direct_memo);
            setMaterialDate(data?.detail?.material_date);
            setSigongWant(`
            ${
                data?.detail?.dobae === 1
                    ? `도배 : ${moment(
                          data?.detail?.dobae_date
                              ? data?.detail?.dobae_date
                              : data?.detail?.create_dt
                      ).format("YYYY-MM-DD")}(${
                          consts.dayText[
                              moment(
                                  data?.detail?.dobae_date
                                      ? data?.detail?.dobae_date
                                      : data?.detail?.create_dt
                              ).day()
                          ]
                      }), `
                    : ""
            }
            ${
                data?.detail?.zangpan === 1
                    ? `장판 : ${moment(
                          data?.detail?.zangpan_date
                              ? data?.detail?.zangpan_date
                              : data?.detail?.create_dt
                      ).format("YYYY-MM-DD")}(${
                          consts.dayText[
                              moment(
                                  data?.detail?.zangpan_date
                                      ? data?.detail?.zangpan_date
                                      : data?.detail?.create_dt
                              ).day()
                          ]
                      }), `
                    : ""
            }
            ${
                data?.detail?.maru === 1
                    ? `마루 : ${moment(
                          data?.detail?.maru_date
                              ? data?.detail?.maru_date
                              : data?.detail?.create_dt
                      ).format("YYYY-MM-DD")}(${
                          consts.dayText[
                              moment(
                                  data?.detail?.maru
                                      ? data?.detail?.maru
                                      : data?.detail?.create_dt
                              ).day()
                          ]
                      }), `
                    : ""
            }`);
            let summary = "";

            if (data?.detail?.address || data?.detail?.address_detail) {
                summary += `${
                    data?.detail?.address ? data?.detail?.address : ""
                }${
                    data?.detail?.address_detail
                        ? data?.detail?.address_detail
                        : ""
                }/ `;
            }

            summary += `${data?.detail?.area === 1 ? "공급" : "전용"}면적 ${
                data?.detail?.area_pyeong || 0
            }평/ `;

            if (data?.detail?.veranda == 1) {
                summary += `베란다 확장 있음${
                    data?.detail?.veranda_memo
                        ? `(${data?.detail?.veranda_memo})`
                        : ""
                }/ `;
            } else {
                summary += `베란다 확장 없음${
                    data?.detail?.veranda_memo
                        ? `(${data?.detail?.veranda_memo})`
                        : ""
                }/ `;
            }

            summary += `${
                data?.detail?.today_status == 1
                    ? "빈집 가구 없음 "
                    : data?.detail?.today_status == 2
                    ? "거주중 가구 있음"
                    : "이사 당일"
            }`;

            if (data?.detail?.today_memo) {
                summary += `(${data?.detail?.today_memo})`;
            }

            setConsultSummary(summary);
        }
    };

    // useEffect(() => {
    //     console.log("materialDeleteList", materialDeleteList);
    // }, [materialDeleteList]);

    const loadDetailInfo = () => {
        if (index) {
            // loadingStart();
            sigongInfoDetail
                .mutateAsync({ sigongInfoIdx: index })
                .then(({ data }) => {
                    // loadingEnd();
                    setContentHistory(data?.history);
                    setTap(data?.tap);
                    dataChange(data);
                })
                .catch((e) => {
                    // loadingEnd();
                    // console.log(e);
                    // toast.error(e.response.data);
                });
        }
        SigongMsgListApi();
    };

    useEffect(() => {
        WorkerListData?.data?.dobaeWorker.map((item, index) => {
            setDobaeWorkers((prev) => [
                ...prev,
                { value: item.idx, text: item.name },
            ]);
        });
        WorkerListData?.data?.zangpanWorker.map((item, index) => {
            setZangpanWorkers((prev) => [
                ...prev,
                { value: item.idx, text: item.name },
            ]);
        });
        WorkerListData?.data?.maruWorker.map((item, index) => {
            setMaruWorkers((prev) => [
                ...prev,
                { value: item.idx, text: item.name },
            ]);
        });
        return () => {
            setDobaeWorkers([{ value: 0, text: "선택" }]);
            setZangpanWorkers([{ value: 0, text: "선택" }]);
            setMaruWorkers([{ value: 0, text: "선택" }]);
        };
    }, [WorkerListData]);

    useEffect(() => {
        const fetchData = async () => {
            if (index) {
                await loadDetailInfo();
                companyListDataApi();
            }
        };

        fetchData();
    }, [index]);

    useEffect(() => {
        zangpanMMTypeApi();
    }, [zangpanCreateCompanyName]);
    useEffect(() => {
        setDobaeMakeManagerName(
            dobaeWorkers.find(
                (item) => item.value === parseInt(dobaeMakeManager)
            )?.text
        );
        setZangpanMakeManagerName(
            zangpanWorkers.find(
                (item) => item.value === parseInt(zangpanMakeManager)
            )?.text
        );
        setMaruMakeManagerName(
            maruWorkers.find((item) => item.value == parseInt(maruMakeManager))
                ?.text
        );
    }, [dobaeMakeManager, zangpanMakeManager, maruMakeManager]);
    const submitClk = () => {
        let sender = {
            sigongInfoIdx: index,
            adminIdx: manager,
            cutomerName: customerName,
            mb_phone: phone,
            address: address,
            address_detail: adressDetail,
            area: area,
            dobae: parseInt(dobaeOn),
            zangpan: parseInt(jangpanOn),
            maru: parseInt(maruOn),
            area_pyeong:
                areaPyeong !== 0
                    ? parseInt(areaPyeong)
                    : parseInt(directAreaPyeong),
            plan_url: planUrl,
            plan_file: planImg,
            today_status: parseInt(todayStatus),
            today_memo: todayMemo,
            veranda: parseInt(veranda),
            veranda_memo: verandaMemo,
            dobae_date: dobaeDate,
            dobae_time: dobaeTime,
            dobae_range: parseInt(dobaeRange),
            dobae_range_memo: dobaeRangeMemo,
            dobae_ceiling: parseInt(dobaeCeiling),
            dobae_company_name: dobaeCreateCompanyName,
            // dobae_company_name:
            //     dobaeCreateCompanyName === ""
            //         ? dobaeDirectCompany
            //         : dobaeCreateCompanyName,
            dobae_product: parseInt(dobaeProduct),
            dobae_cnt: parseInt(dobaeCnt),
            dobae_price: parseInt(dobaePrice),
            zangpan_date: zangpanDate,
            zangpan_time: zangpanTime,
            zangpan_range: parseInt(zangpanRange),
            zangpan_range_memo: zangpanRangeMemo,
            zangpan_company_name: zangpanCreateCompanyName,
            // zangpan_company_name:
            //     zangpanCreateCompanyName === ""
            //         ? zangpanDirectCompany
            //         : zangpanCreateCompanyName,
            zangpan_mm: zangpanMM,
            zangpan_price: parseInt(zangpanPrice),
            zangpan_molding: parseInt(zangpanMolding),
            zangpan_molding_memo: zangpanMoldingMemo,
            zangpan_molding_range: parseInt(zangpanMoldingRange),
            zangpan_molding_range_memo: zangpanMoldingRangeMemo,
            zangpan_threshold: parseInt(zangpanThreshold),
            maru_date: maruDate,
            maru_time: maruTime,
            maru_range: parseInt(maruRange),
            maru_range_memo: maruRangeMemo,
            maru_company_name: maruCreateCompanyName,
            // maru_company_name:
            //     maruCreateCompanyName === ""
            //         ? maruDirectCompany
            //         : maruCreateCompanyName,

            maru_flooring: parseInt(maruFlooring),
            maru_flooring_memo: maruFlooringMemo,
            maru_ori_flooring: maruOldProduct,
            maru_molding: parseInt(maruDownMolding),
            maru_threshold: parseInt(maruThreshold),
            maru_cure: parseInt(maruRepair),
            maru_price: parseInt(maruPrice),
            door_pass: enterPW,
            entrance_pass: doorPW,
            customer_memo: customerMemo,
            customer_contract_chk: customerContractChk,
            customer_payment_chk: deposit,
            customer_payment: depositAmount,
            customer_payment_name: depositName,
            customer_final_chk: customerFinalChk,
            material_chk: verandaExpansion,

            dobaeWorkerIdx: dobaeWorkerInfo[0]?.idx || 0,
            dobaeWorker: dobaeOn,
            dobae_banzang_name: dobaeMakeManagerName,
            dobae_people_cnt: dobaePersonnel,
            dobae_banzang_idx: parseInt(dobaeMakeManager || 0),
            dobae_workderMemo: dobaeWorkerMemo,
            dobaeArr: dobaeMaterials,
            subArr: subMaterials,

            zangpanWorkerIdx: zangpanWorkerInfo[0]?.idx || 0,
            zangpanWorker: jangpanOn,
            zangpan_people_cnt: zangpanPersonnel,
            zangpan_banzang_name: zangpanMakeManagerName,
            zangpan_banzang_idx: parseInt(zangpanMakeManager || 0),
            zangpan_workderMemo: zangpanWorkerMemo,
            zangpanArr: zangpanMaterials,
            zangpanMopArr: zangpanMop,

            maruWorkerIdx: maruWorkerInfo[0]?.idx || 0,
            maruWorker: maruOn,
            maru_people_cnt: maruPersonnel,
            maru_banzang_name: maruMakeManagerName,
            maru_banzang_idx: parseInt(maruMakeManager || 0),
            maru_workderMemo: maruWorkerMemo,
            maruArr: maruMaterials,
            maruMopArr: maruMop,
            demolition_date: demolitionDate,
            demolition_time: demolitionTime,
            demolition_range: demolitionRange,
            demolition_price: parseInt(demolitionPrice),

            house_count: apartUnit,
            house_const_count: constrUnit,
            construction_memo: constrDetail,
            const_start_date: constrStartDate,
            const_end_date: constrEndDate,
            const_noise_date: constrNoise,
            field_manager: constrFieldManager,

            deleteProductIdx: materialDeleteList,

            customer_direct_memo: customerDirectMemo,
        };

        let earliestDate = null;
        let earliestTime = "12:00";

        if (dobaeOn) {
            earliestDate = dobaeDate;
        }

        if (jangpanOn) {
            if (earliestDate === null || zangpanDate < earliestDate) {
                earliestDate = zangpanDate;
            }
        }

        if (maruOn) {
            if (earliestDate === null || maruDate < earliestDate) {
                earliestDate = maruDate;
            }
        }

        if (earliestDate !== null) {
            const dayOfWeek = moment(earliestDate).day(); // 0: 일요일, 1: 월요일, 2: 화요일, ..., 6: 토요일
            const daysToSubtract = dayOfWeek === 1 || dayOfWeek === 2 ? 4 : 3;
            sender.material_date = `${moment(earliestDate)
                .subtract(daysToSubtract, "days")
                .format("YYYY-MM-DD")} ${earliestTime}`;
        } else {
            sender.material_date = ""; // 모든 날짜가 없는 경우 빈 문자열로 설정
        }
        console.log("sender", sender.material_date);
        // if (
        //     customerName === "" ||
        //     customerName === undefined ||
        //     customerName === null
        // ) {
        //     toast.error("고객명을 입력해주세요.");
        //     return;
        // }
        if (
            (areaPyeong === 0 ||
                areaPyeong === NaN ||
                areaPyeong === undefined) &&
            directAreaPyeong === 0
        ) {
            toast.error("기본정보 면적을 입력해주세요.");
            return;
        } else if (todayStatus === 0 || todayStatus === undefined) {
            toast.error("기본정보 시공당일 주거환경을 선택해주세요.");
            return;
        } else if (veranda === 0 || veranda === undefined) {
            toast.error("기본정보 베란다 확장여부를 선택해주세요.");
            return;
        }
        if (planImg !== "" && planImg !== null && planImg !== undefined) {
            if (!planImg.includes("plan_img")) {
                sender.plan_file_type = 1;
            }
        }
        if (phone === "" || phone === undefined) {
            toast.error("연락처를 입력해주세요.");
            return;
        } else if (phone.includes("-")) {
            sender.mb_phone = phone.replace(/-/g, "");
        } else if (phone.includes(" ")) {
            sender.mb_phone = phone.replace(/ /g, "");
        } else if (!consts.numPattern.test(phone)) {
            toast.error("번호를 제대로 입력해주세요.");
            return;
        }

        //도배가 켜졌을때  아직 제조사 안들어감
        if (
            dobaeOn === 1 &&
            (dobaeTime === "" || dobaeTime === "--:--" || dobaeTime === null) &&
            (dobaeTimeDinput === "" || dobaeTimeDinput === null)
        ) {
            toast.error("도배시공시간을 선택해주세요.");
            return;
        } else if (
            dobaeOn === 1 &&
            (dobaeTime === "" || dobaeTime === "--:--") &&
            dobaeTimeDinput !== ""
        ) {
            sender.dobae_time = dobaeTimeDinput;
        }
        if (dobaeOn === 1 && (dobaeRange === 0 || dobaeRange === null)) {
            toast.error("도배시공범위를 선택해주세요.");
            return;
        } else if (
            dobaeOn === 1 &&
            (dobaeCeiling === 0 || dobaeCeiling === null)
        ) {
            toast.error("도배천장을 선택해주세요.");
            return;
        } else if (
            dobaeOn === 1 &&
            (dobaeCreateCompanyName === "" || dobaeCreateCompanyName === null)
            //     &&
            // (dobaeDirectCompany === "" || dobaeDirectCompany === null)
        ) {
            toast.error("도배 제조사를 선택해주세요.");
            return;
        } else if (
            dobaeOn === 1 &&
            (dobaeProduct === 0 || dobaeProduct === null)
        ) {
            toast.error("도배벽지종류를 선택해주세요.");
            return;
        } else if (
            dobaeOn === 1 &&
            (dobaeCnt === 0 || dobaeCnt === null || dobaeCnt === NaN) &&
            dobaeDirectCnt === 0
        ) {
            toast.error("도배벽지수를 선택해주세요.");
            return;
        } else if (dobaeOn === 1 && (dobaePrice === 0 || dobaePrice === null)) {
            toast.error("도배견적금액을 입력해주세요.");
            return;
        }
        // if (dobaeOn === 1 && parseInt(dobaeMakeManager) === 0) {
        //     toast.error("도배담당자를 선택해주세요.");
        //     return;
        // }

        if (
            dobaeOn === 1 &&
            (dobaeCnt === 0 || dobaeCnt === null) &&
            dobaeDirectCnt !== 0
        ) {
            sender.dobae_cnt = dobaeDirectCnt;
        }

        //장판이 켜졌을때 아직 제조사 안들어감
        if (
            jangpanOn === 1 &&
            (zangpanTime === "" ||
                zangpanTime == "--:--" ||
                zangpanTime === null) &&
            (zangpanTimeDinput === "" || zangpanTimeDinput === null)
        ) {
            toast.error("장판시공시간을 입력해주세요.");
            return;
        } else if (
            jangpanOn === 1 &&
            (zangpanRange === 0 || zangpanRange === null)
        ) {
            toast.error("장판시공범위를 선택해주세요.");
            return;
        } else if (
            jangpanOn === 1 &&
            (zangpanCreateCompanyName === "" ||
                zangpanCreateCompanyName === null)
            //     &&
            // (zangpanDirectCompany === "" || zangpanDirectCompany === null)
        ) {
            toast.error("장판 제조사를 선택해주세요.");
            return;
        } else if (jangpanOn === 1 && zangpanMM === "") {
            toast.error("장판길이 입력해주세요.");
            return;
        } else if (
            jangpanOn === 1 &&
            zangpanMolding === 1 &&
            zangpanMoldingMemo === ""
        ) {
            toast.error("하단몰딩 종류를 입력해주세요.");
            return;
        }
        // else if (
        //     jangpanOn === 1 &&
        //     zangpanMoldingRange === 1 &&
        //     (zangpanMoldingRangeMemo === "" || zangpanMoldingRangeMemo === null)
        // ) {
        //     toast.error("장판하단몰딩 범위를 입력해주세요.");
        //     return;
        // }
        else if (jangpanOn === 1 && zangpanThreshold === 2) {
            toast.error("장판문턱을 선택해주세요.");
            return;
        } else if (jangpanOn === 1 && zangpanPrice === 0) {
            toast.error("장판견적금액을 입력해주세요.");
            return;
        }
        // if (jangpanOn === 1 && parseInt(zangpanMakeManager) === 0) {
        //     toast.error("장판담당자를 선택해주세요.");
        //     return;
        // }
        if (
            jangpanOn === 1 &&
            (zangpanTime === "" || zangpanTime == "--:--") &&
            zangpanTimeDinput !== ""
        ) {
            sender.zangpan_time = zangpanTimeDinput;
        }
        //마루가 열렸을때 제조사 아직 없음
        if (
            maruOn === 1 &&
            (maruTime === "" || maruTime == "--:--" || maruTime === null) &&
            (maruTimeDinput === "" || maruTimeDinput === null)
        ) {
            toast.error("마루시간을 입력해주세요.");
            return;
        } else if (maruOn === 1 && maruRange === 0) {
            toast.error("마루시공범위를 선택해주세요.");
            return;
        } else if (
            maruOn === 1 &&
            (maruCreateCompanyName === "" || maruCreateCompanyName === null)
            //  &&
            // (maruDirectCompany === "" || maruDirectCompany === null)
        ) {
            toast.error("마루 제조사를 선택해주세요.");
            return;
        } else if (
            maruOn === 1 &&
            maruFlooring === 1 &&
            (maruFlooringMemo === "" || maruFlooringMemo === null)
        ) {
            toast.error("마루바닥재를 선택해주세요.");
            return;
        } else if (
            maruOn === 1 &&
            (maruOldProduct === "" || maruOldProduct === null)
        ) {
            toast.error("마루기존 바닥재를 입력해주세요.");
            return;
        } else if (maruOn === 1 && maruDownMolding === 2) {
            toast.error("마루하단몰딩을 선택해주세요.");
            return;
        } else if (maruOn === 1 && maruThreshold == 2) {
            toast.error("마루문턱을 선택해주세요.");
            return;
        } else if (maruOn === 1 && maruRepair == 2) {
            toast.error("마루보양작업을 선택해주세요.");
            return;
        } else if (maruOn === 1 && maruPrice === 0) {
            toast.error("마루견적금액을 입력해주세요.");
            return;
        }
        // if (maruOn === 1 && parseInt(maruMakeManager) === 0) {
        //     toast.error("마루담당자를 선택해주세요.");
        //     return;
        // }

        if (
            maruOn === 1 &&
            (maruTime === "" || maruTime == "--:--") &&
            maruTimeDinput !== ""
        ) {
            sender.maru_time = maruTimeDinput;
        }
        if (
            (demolitionTime === "" || demolitionTime == "--:--") &&
            deolitionTimeDinput !== ""
        ) {
            sender.demolition_time = deolitionTimeDinput;
        }

        //시공관리 only

        if (maruOn === 1 && (apartUnit === "" || apartUnit === null)) {
            toast.error("세대를 입력해주세요.");
            return;
        } else if (maruOn === 1 && (constrUnit === "" || constrUnit === null)) {
            toast.error("공사세대를 입력해주세요.");
            return;
        } else if (
            maruOn === 1 &&
            (constrDetail === "" || constrDetail === null)
        ) {
            toast.error("공사내역을 입력해주세요.");
            return;
        } else if (
            maruOn === 1 &&
            (constrStartDate === "" || constrStartDate === null)
        ) {
            toast.error("공사시작일을 입력해주세요.");
            return;
        } else if (
            maruOn === 1 &&
            (constrEndDate === "" || constrEndDate === null)
        ) {
            toast.error("공사종료일을 입력해주세요.");
            return;
        } else if (
            maruOn === 1 &&
            (constrNoise === "" || constrNoise === null)
        ) {
            toast.error("소음시간을 입력해주세요.");
            return;
        } else if (
            maruOn === 1 &&
            (constrFieldManager === "" || constrFieldManager === null)
        ) {
            toast.error("현장관리자를 입력해주세요.");
            return;
        }

        // console.log("test..=?>?>>>>>>>>나가는값", sender);

        sigongInfoIn
            .mutateAsync(sender)
            .then(({ data }) => {
                navigate(`${routes.constPreview3}?index=${index}`);
                toast.success("등록에 성공했습니다.");
            })
            .catch((e) => {
                toast.error(e.response.data);
            });

        // sigongInfoMateIn
        //     .mutateAsync(sender)
        //     .then(({ data }) => {
        //         setOpenPop(!OpenPop);
        //         TableUpdate();
        //         setInnerSelectCompany("제조사");
        //         setInnerSelectFirstCategory("1차분류");
        //         setAgencyValue([]);
        //         setAgencyMateListData([]);
        //         setOpenPopRegFlag(0);
        //         toast.success("저장되었습니다.");
        //     })
        //     .catch((e) => {
        //         toast.error(e.response.data);
        //     });
    };
    // const TableUpdate = () => {
    //     sigongInfoDetail
    //         .mutateAsync({ sigongInfoIdx: parseInt(index) })
    //         .then(({ data }) => {
    //             setDobaeMaterials(data?.dobae);
    //             setSubMaterials(data?.sub);
    //             setZangpanMaterials(data?.zangpan);
    //             setZangpanMop(data?.zangpanMop);
    //             setMaruMaterials(data?.maru);
    //             setMaruMop(data?.maruMop);
    //         })
    //         .catch((e) => {
    //             toast.error(e.response.data);
    //         });
    // };
    const MemoListApi = () => {
        memoList
            .mutateAsync({ estimateIdx: tap?.estimate_idx })
            .then(({ data }) => {
                // console.log("data!!!!!!", data);
                setMemoListData(data);
            })
            .catch((e) => {
                toast.error("정보를 가져오지 못했습니다.");
            });
    };
    // const memoUpdate = () => {
    //     sigongInfoDetail
    //         .mutateAsync({ sigongInfoIdx: parseInt(index) })
    //         .then(({ data }) => {
    //             setMemoListData(data?.memoList);
    //         })
    //         .catch((e) => {
    //             toast.error(e.response.data);
    //         });
    // };
    const saveMemo = () => {
        let sender = {
            sigonginfoIdx: index,
            memo_type: memoType,
            memo_content: memoWrite,
        };
        // console.log("sender", sender);
        if (memoType === 0) {
            toast.error("메모 타입을 선택해주세요");
            return;
        } else if (memoWrite === "") {
            toast.error("메모를 입력해주세요");
            return;
        }

        memoRegister
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("저장되었습니다.");
                // MemoListApi();
                setMemoWrite("");
                setMemoType(0);
                MemoListApi();
            })
            .catch((e) => {
                // console.log(e);
                toast.error("저장에 실패했습니다.");
            });
    };
    const GoBack = () => {
        navigate(routes.constr3);
    };
    const DeleteMemo = () => {
        // console.log(deleteIdx);
        sigongInfoMemoDelete
            .mutateAsync({ memoIdx: deleteIdx })
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                MemoListApi();
                setPopupFlag(1);
            })
            .catch((e) => {
                toast.error("삭제에 실패했습니다.");
            });

        // console.log("deleteIdx", deleteIdx);
    };
    const ListGoback = () => {
        // console.log("test", isDelete);
        setIsDelete(false);
        setDeletePop(true);
        setPopupFlag(1);
    };
    const ClosePop = () => {
        setOpenPop(!OpenPop);
        setMaterialSearchVal("");
        setInnerSelectCompany("제조사");
        setInnerSelectFirstCategory("1차분류");
        setInnerSelectSecondCategory("2차분류");
        setAgencyValue([]);
    };
    const materialListSave = () => {
        if (agencyValue.length === 0) {
            setOpenPop(!OpenPop);
            return;
        }
        let sender = {
            sigongInfoIdx: parseInt(index),
        };
        if (openPopRegFlag === 1) {
            sender.dobaeArr = agencyValue.flatMap((num) =>
                agencyMateListData?.filter((c, i) => {
                    return parseInt(c.idx) === parseInt(num);
                })
            );
        }
        const flagToPropertyMap = {
            1: "dobaeArr",
            2: "subArr",
            3: "zangpanArr",
            4: "zangpanMopArr",
            5: "maruArr",
            6: "maruMopArr",
        };

        const flagToFunctionMap = {
            1: setDobaeMaterials,
            2: setSubMaterials,
            3: setZangpanMaterials,
            4: setZangpanMop,
            5: setMaruMaterials,
            6: setMaruMop,
        };
        const updateMaterials = (updateFunction) => {
            updateFunction((prev) =>
                prev.concat(
                    agencyValue.flatMap((num) =>
                        agencyMateListData
                            ?.map((c) => {
                                const { idx, ...rest } = c;
                                return parseInt(c.idx) === parseInt(num)
                                    ? rest
                                    : null;
                            })
                            .filter(Boolean)
                    )
                )
            );
        };

        if (flagToFunctionMap.hasOwnProperty(openPopRegFlag)) {
            updateMaterials(flagToFunctionMap[openPopRegFlag]);
            setOpenPop(!OpenPop);
            setAgencyValue([]);
        }

        // sigongInfoMateIn
        //     .mutateAsync(sender)
        //     .then(({ data }) => {
        //         setOpenPop(!OpenPop);
        //         TableUpdate();
        //         setInnerSelectCompany("제조사");
        //         setInnerSelectFirstCategory("1차분류");
        //         setAgencyValue([]);
        //         setAgencyMateListData([]);
        //         setOpenPopRegFlag(0);
        //         toast.success("저장되었습니다.");
        //     })
        //     .catch((e) => {
        //         toast.error(e.response.data);
        //     });
    };
    useEffect(() => {
        if (OpenPop) {
            document.getElementsByTagName("HTML")[0]["style"]["overflow-y"] =
                "hidden";
        }

        return () => {
            document.getElementsByTagName("HTML")[0]["style"]["overflow-y"] =
                "scroll";
        };
    }, [OpenPop]);
    const btnDobaePop = () => {
        setInnerType(1);
        setOpenPop(!OpenPop);
        setOpenPopRegFlag(1);
    };
    const btnDobaeSubPop = () => {
        setInnerType(5);
        setOpenPop(!OpenPop);
        setOpenPopRegFlag(2);
    };
    const btnZangpanPop = () => {
        setInnerType(2);
        setOpenPop(!OpenPop);
        setOpenPopRegFlag(3);
    };
    const btnZangpanBaseBoardPop = () => {
        setInnerType(4);
        setOpenPop(!OpenPop);
        setOpenPopRegFlag(4);
    };
    const btnMaruPop = () => {
        setInnerType(3);
        setOpenPop(!OpenPop);
        setOpenPopRegFlag(5);
    };
    const btnMaruBaseBoardPop = () => {
        setInnerType(6);
        setOpenPop(!OpenPop);
        setOpenPopRegFlag(6);
    };
    const ContractMsg = (type) => {
        sigongInfoContractMsg
            .mutateAsync({ sigongInfoIdx: index, msgType: type })
            .then(({ data }) => {
                toast.success("메세지를 전송했습니다.");
                sigongSendUpdate();
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const ContractSigongMsg = (type) => {
        if (type === 1 && dobaeMakeManager === 0) {
            toast.error("작업자가 배정되지 않았습니다.");
            return;
        }
        if (type === 2 && zangpanMakeManager === 0) {
            toast.error("작업자가 배정되지 않았습니다.");
            return;
        }
        if (type === 3 && maruMakeManager === 0) {
            toast.error("작업자가 배정되지 않았습니다.");
            return;
        }
        // console.log("type", type);
        sigongInfoOrderMsg
            .mutateAsync({ sigongInfoIdx: index, workerAbility: type })
            .then(({ data }) => {
                toast.success("메세지를 전송했습니다.");
                sigongSendUpdate();
            })
            .catch((e) => {
                // console.log(e);
                toast.error(e.response.data);
            });
    };
    //테이블 내 요소 삭제
    const deleteDobaeMaterial = () => {
        setDobaeMaterials((prev) =>
            prev.filter((item, index) => {
                return index !== materialDeleteIdx;
            })
        );
        setMaterialDeleteList((prev) => [
            ...prev,
            dobaeMaterials[materialDeleteIdx].idx,
        ]);
    };
    const deleteSubMaterial = () => {
        // console.log("materialDeleteIdx", materialDeleteIdx);
        setSubMaterials((prev) =>
            prev.filter((item, index) => {
                return index !== materialDeleteIdx;
            })
        );
        setMaterialDeleteList((prev) => [
            ...prev,
            subMaterials[materialDeleteIdx].idx,
        ]);
    };
    const deleteZangpanMaterial = () => {
        setZangpanMaterials((prev) =>
            prev.filter((item, index) => {
                return index !== materialDeleteIdx;
            })
        );
        setMaterialDeleteList((prev) => [
            ...prev,
            zangpanMaterials[materialDeleteIdx].idx,
        ]);
    };
    const deleteZangpanMop = () => {
        setZangpanMop((prev) =>
            prev.filter((item, index) => {
                return index !== materialDeleteIdx;
            })
        );
        setMaterialDeleteList((prev) => [
            ...prev,
            zangpanMop[materialDeleteIdx].idx,
        ]);
    };
    const deleteMaruMaterial = () => {
        setMaruMaterials((prev) =>
            prev.filter((item, index) => {
                return index !== materialDeleteIdx;
            })
        );
        setMaterialDeleteList((prev) => [...prev, materialDeleteIdx]);
    };
    const deleteMaruMop = () => {
        setMaruMop((prev) =>
            prev.filter((item, index) => {
                return index !== materialDeleteIdx;
            })
        );
        setMaterialDeleteList((prev) => [...prev, materialDeleteIdx]);
    };
    const OpenUrl = ({ SendType, idx, type }) => {
        if (SendType === 1) {
            window.open(
                `https://order.8amdobae.co.kr/UserLogin?sigongIdx=${idx}&Looking=true`
            );
        } else if (SendType === 2) {
            window.open(
                `https://order.8amdobae.co.kr/UserNewFinalConfirm?sigongIdx=${idx}&Looking=true`
            );
        } else if (SendType === 3) {
            window.open(
                `https://order.8amdobae.co.kr/UserNewMaterialSelect?sigongIdx=${idx}&Looking=true `
            );
        } else if (SendType === 4) {
            window.open(
                `https://worker.8amdobae.co.kr/WorkOrder?sigongIdx=${idx}&sigongType=${type}&Looking=true`
            );
        }
    };
    useEffect(() => {
        memoListData?.map((item, index) => {
            if (item.memo_type === 1) {
                setMemoListCommon((prev) => [...prev, item]);
            } else if (item.memo_type === 2) {
                setMemoListDobae((prev) => [...prev, item]);
            } else if (item.memo_type === 3) {
                setMemoListZangpan((prev) => [...prev, item]);
            } else if (item.memo_type === 4) {
                setMemoListMaru((prev) => [...prev, item]);
            } else if (item.memo_type === 5) {
                setMemoListSignificant((prev) => [...prev, item]);
            }
        });
        return () => {
            setMemoListCommon([]);
            setMemoListDobae([]);
            setMemoListZangpan([]);
            setMemoListMaru([]);
            setMemoListSignificant([]);
        };
    }, [memoListData]);
    useEffect(() => {
        setDobaePersonnel(dobaeWorkerInfo[0]?.people_cnt || 1);
        setDobaeMakeManager(dobaeWorkerInfo[0]?.banzang_idx);
        setDobaeWorkerMemo(dobaeWorkerInfo[0]?.memo);
        setDobaeOrderChk(dobaeWorkerInfo[0]?.order_chk);

        setZangpanPersonnel(zangpanWorkerInfo[0]?.people_cnt || 1);
        setZangpanMakeManager(zangpanWorkerInfo[0]?.banzang_idx);
        setZangpanWorkerMemo(zangpanWorkerInfo[0]?.memo);
        setZangpanOrderChk(zangpanWorkerInfo[0]?.order_chk);

        setMaruPersonnel(maruWorkerInfo[0]?.people_cnt || 1);
        setMaruMakeManager(maruWorkerInfo[0]?.banzang_idx);
        setMaruWorkerMemo(maruWorkerInfo[0]?.memo);
        setMaruOrderChk(maruWorkerInfo[0]?.order_chk);
    }, [dobaeWorkerInfo, zangpanWorkerInfo, maruWorkerInfo]);

    useEffect(() => {
        let newPriceAll = [...priceAll];

        const updateOrAdd = (title, price) => {
            const index = newPriceAll.findIndex((item) => item.title === title);
            if (index !== -1) {
                newPriceAll[index] = { title, price };
            } else {
                newPriceAll.push({ title, price });
            }
        };

        if (dobaePrice != 0) {
            updateOrAdd("도배", dobaePrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "도배");
        }

        if (zangpanPrice != 0) {
            updateOrAdd("장판", zangpanPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "장판");
        }

        if (maruPrice != 0) {
            updateOrAdd("마루", maruPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "마루");
        }

        if (demolitionPrice != 0) {
            updateOrAdd("철거", demolitionPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "철거");
        }

        setPriceAll(newPriceAll);
    }, [dobaePrice, zangpanPrice, maruPrice, demolitionPrice]);
    useEffect(() => {
        if (tap?.estimate_idx) {
            MemoListApi();
        }
    }, [tap]);

    return (
        <>
            <PopupComp
                popTitle={`${isDelete ? "삭제" : "안내"}`}
                popContent={`${
                    isDelete
                        ? "정말로 삭제하시겠습니까?"
                        : "저장 없이 페이지 이동하시겠습니까?"
                }`}
                isYes={true}
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={
                    popupFlag === 1
                        ? GoBack
                        : popupFlag === 2
                        ? DeleteMemo
                        : popupFlag === 3
                        ? deleteDobaeMaterial
                        : popupFlag === 4
                        ? deleteSubMaterial
                        : popupFlag === 5
                        ? deleteZangpanMaterial
                        : popupFlag === 6
                        ? deleteZangpanMop
                        : popupFlag === 7
                        ? deleteMaruMaterial
                        : popupFlag === 8
                        ? deleteMaruMop
                        : null
                }
            />
            {OpenPop && (
                <div style={{ overflow: "auto" }} className={`popup show`}>
                    <div
                        className="popup_container"
                        style={{
                            width: "1000px",
                            minHeight: "600px",
                            overflow: "hidden",

                            justifyContent: "flex-start",
                        }}
                    >
                        <div
                            className="popup_title"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0 20px",
                            }}
                        >
                            <p>자재 목록</p>
                            <div className="exit_btn" onClick={ClosePop}></div>
                        </div>
                        <div style={{ padding: "10px" }} className="popup_msg">
                            <div className="SelectBoxWrap">
                                <select
                                    // style={{ width: `${width}%` }}
                                    value={innerSelectCompany}
                                    onChange={(e) => {
                                        setInnerSelectCompany(e.target.value);
                                    }}
                                >
                                    {companyList?.map((x, i) => (
                                        <option key={i} value={x}>
                                            {x}
                                        </option>
                                    ))}
                                </select>
                                {innerSelectCompany !== "제조사" ? (
                                    <select
                                        // style={{ width: `${width}%` }}
                                        value={innerSelectFirstCategory}
                                        onChange={(e) => {
                                            setInnerSelectFirstCategory(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {firstCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}
                                {innerSelectFirstCategory !== "1차분류" ? (
                                    <select
                                        // style={{ width: `${width}%` }}
                                        value={innerSelectSecondCategory}
                                        onChange={(e) => {
                                            setInnerSelectSecondCategory(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {secondCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}

                                <Input
                                    className="input_text"
                                    type="text"
                                    placeholder="모델번호, 제품명"
                                    name="stx"
                                    value={materialSearchVal}
                                    setValue={setMaterialSearchVal}
                                    // style={{ width: "20%" }}
                                />
                                <button
                                    className="btn btn9"
                                    onClick={materialListSave}
                                >
                                    저장
                                </button>
                            </div>
                            <div
                                style={{ marginTop: "20px" }}
                                className="table_scroll"
                            >
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="FlexCenter">
                                                    <div
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            border: "1px solid #ddd",
                                                        }}
                                                    ></div>
                                                </div>
                                            </th>
                                            <th>종류</th>
                                            <th>제조사</th>
                                            <th>1차분류</th>
                                            <th>2차분류</th>
                                            <th>모델번호</th>
                                            <th>제품명</th>
                                            <th>제품이미지</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agencyMateListData?.map((x, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <input
                                                            checked={agencyValue
                                                                .map((c) =>
                                                                    parseInt(c)
                                                                )
                                                                .includes(
                                                                    x.idx
                                                                )}
                                                            value={x.idx}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            id={x.idx}
                                                            className="OneChkBox"
                                                            type="checkbox"
                                                        />
                                                        <label
                                                            htmlFor={x.idx}
                                                        />
                                                    </td>
                                                    <td>
                                                        {x.material_type === 1
                                                            ? "벽지"
                                                            : x.material_type ===
                                                              2
                                                            ? "장판"
                                                            : x.material_type ===
                                                              3
                                                            ? "마루"
                                                            : x.material_type ===
                                                              4
                                                            ? "걸레받이"
                                                            : x.material_type ===
                                                              5
                                                            ? "부자재"
                                                            : null}
                                                    </td>
                                                    <td>
                                                        {x.material_company}
                                                    </td>
                                                    <td>
                                                        {x.material_class01}
                                                    </td>
                                                    <td>
                                                        {x.material_class02}
                                                    </td>
                                                    <td>{x.material_number}</td>
                                                    <td>{x.material_name}</td>
                                                    <td>
                                                        {x.material_img ? (
                                                            <img
                                                                style={{
                                                                    height: "50px",
                                                                }}
                                                                src={`${consts.ImgState}${x.material_img}`}
                                                            />
                                                        ) : (
                                                            <p>이미지 없음</p>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={innerPage}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={agencyListCount || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                    </div>
                </div>
            )}
            <div className="content ">
                <TabComponent
                    index={tap?.counseling_idx}
                    estimate_idx={tap?.estimate_idx}
                    sigonginfo_idx={tap?.sigonginfo_idx}
                    order_idx={tap?.order_idx}
                    workend_idx={tap?.workend_idx}
                    as_tbl={tap?.as_tbl}
                />
                <HistoryBox
                    Info={ContentHistory}
                    firstDate={createDt}
                    adminName={mbData?.mb_name}
                    historyLook={historyLook}
                    setContentChange={setContentChange}
                />
                {/* <div className="listBox">
                        {Array(10)
                            .fill(0)
                            .map((v, i) => (
                                <div
                                    className="list-item"
                                    onClick={() => {
                                        console.log(i);
                                    }}
                                >
                                    list1
                                </div>
                            ))}
                    </div> */}

                <div className="BorderBox">
                    <div
                        className="title HistoryBox"
                        style={{ borderBottom: "1px solid #ccc" }}
                        onClick={() => setShowContent(!ShowContent)}
                    >
                        <div className="left">
                            <h1>견적내용 보기</h1>
                        </div>
                        <div className="right">
                            <svg
                                style={{
                                    transform: ShowContent
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                }}
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                    fill="#333333"
                                />
                            </svg>
                        </div>
                    </div>
                    {ShowContent && (
                        <div className="HistoryBoxcontent">
                            <div className="title">
                                <h1>상담요약</h1>
                            </div>
                            <div className="contentWrap">
                                {/* <BoxArrayContent2 title="지역" array={testDummy} /> */}
                                <BoxContent
                                    title="시공희망일"
                                    content={sigongWant}
                                />
                                <BoxContent
                                    title="내용"
                                    content={consultSummary}
                                />

                                {/* <BoxContent
                                title="시공당일 주거환경"
                                content="아파트"
                            />
                            <BoxArrayContent2 title="지역" array={testDummy} /> */}
                            </div>
                            <div className="title">
                                <h1>기본정보</h1>
                            </div>
                            <div className="contentWrap">
                                <BoxJustDropDown
                                    title="담당자"
                                    value={manager}
                                    setValue={setManager}
                                    Dropdowns={managerSetting}
                                />
                                <BoxTextInput
                                    value={customerName}
                                    setValue={setCustomerName}
                                    title="고객명"
                                />
                                <BoxTextInput
                                    value={phone}
                                    setValue={setPhone}
                                    title="연락처"
                                />
                                <div className="BoxInfo">
                                    <div className="title">시공</div>
                                    <div className="sub">
                                        <div className="ChkBoxOne">
                                            <input
                                                type="checkbox"
                                                id={"dobaeOn"}
                                                name={"dobaeOn"}
                                                value={
                                                    isNaN(dobaeOn) ? 0 : dobaeOn
                                                }
                                                onChange={(e) => {
                                                    setDobaeOn(
                                                        e.target.checked ? 1 : 0
                                                    );
                                                }}
                                                checked={
                                                    isNaN(dobaeOn)
                                                        ? false
                                                        : dobaeOn
                                                }
                                            />
                                            <label htmlFor={"dobaeOn"}>
                                                도배
                                            </label>
                                        </div>
                                        <div className="ChkBoxOne">
                                            <input
                                                type="checkbox"
                                                id={"jangpanOn"}
                                                name={"jangpanOn"}
                                                value={
                                                    isNaN(jangpanOn)
                                                        ? 0
                                                        : jangpanOn
                                                }
                                                onChange={(e) => {
                                                    setJangpanOn(
                                                        e.target.checked ? 1 : 0
                                                    );
                                                }}
                                                checked={
                                                    isNaN(jangpanOn)
                                                        ? false
                                                        : jangpanOn
                                                }
                                            />
                                            <label htmlFor={"jangpanOn"}>
                                                장판
                                            </label>
                                        </div>
                                        <div className="ChkBoxOne">
                                            <input
                                                type="checkbox"
                                                id={"maruOn"}
                                                name={"maruOn"}
                                                value={
                                                    isNaN(maruOn) ? 0 : maruOn
                                                }
                                                onChange={(e) => {
                                                    setMaruOn(
                                                        e.target.checked ? 1 : 0
                                                    );
                                                }}
                                                checked={
                                                    isNaN(maruOn)
                                                        ? false
                                                        : maruOn
                                                }
                                            />
                                            <label htmlFor={"maruOn"}>
                                                마루
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <BoxAdressInput
                                    value={address}
                                    setValue={setAddress}
                                    detailValue={adressDetail}
                                    setDetailValue={setAdressDetail}
                                />
                                <BoxRadioboxInput
                                    title="면적"
                                    radioValue={area}
                                    setRadioValue={setArea}
                                    selectedValue={areaPyeong}
                                    setSelectedValue={setAreaPyeong}
                                    directInput={directAreaPyeong}
                                    setDirectInput={setDirectAreaPyeong}
                                    directType="number"
                                    Radioes={consts.areaBox}
                                    Dropdowns={consts.pyeong}
                                    TextInputUnit="평"
                                    radioNames="area"
                                />
                                <BoxTextInput
                                    title="도면 URL"
                                    value={planUrl}
                                    setValue={setPlanUrl}
                                    placeholder="네이버 부동산 내 도면 URL등"
                                    inputWidth={70}
                                />
                                <div
                                    style={{
                                        border: "none",
                                        borderBottom: "1px solid #ddd",
                                    }}
                                    className="ImgInputWrap"
                                >
                                    <div className="Imgtitle">도면파일</div>
                                    <div className="sub">
                                        <InputFile
                                            type="file"
                                            name={"plan_img"}
                                            value={
                                                planImg &&
                                                (planImg.includes("plan_img")
                                                    ? `${consts.ImgState}${planImg}`
                                                    : `${planImg}`)
                                            }
                                            alt={"plan_img"}
                                            onChange={(idx, name, result) => {
                                                setPlanImg(result);
                                            }}
                                            valid="image"
                                            // style={{ flex: 1.68 }}
                                            imgStyle={{
                                                width: "40%",
                                            }}
                                            imgInnerStyle={{
                                                height: "500px",
                                                maxWidth: "500px",
                                                objectFit: "contain",
                                            }}
                                            setFileType={(type) => {
                                                setPlanImgFileType(type);
                                            }}
                                        />
                                        <button
                                            type="button"
                                            style={{
                                                fontSize: "12px",
                                                position: "absolute",
                                                bottom: "10px",
                                                right: "10px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => setPlanImg("")}
                                        >
                                            삭제하기
                                        </button>
                                    </div>
                                </div>
                                <BoxRadioboxInput
                                    title="시공당일 주거환경"
                                    Radioes={consts.LivingEnvironmentBox}
                                    IsTextInput={true}
                                    radioValue={todayStatus}
                                    setRadioValue={setTodayStatus}
                                    directInput={todayMemo}
                                    setDirectInput={setTodayMemo}
                                    placeholder="메모입력"
                                    TextInputWidth={20}
                                    radioNames="todayStatus"
                                />
                                <BoxRadioboxInput
                                    title="베란다 확장"
                                    Radioes={consts.BarandaExpansion}
                                    radioValue={veranda}
                                    setRadioValue={setVeranda}
                                    directInput={verandaMemo}
                                    setDirectInput={setVerandaMemo}
                                    IsTextInput={true}
                                    placeholder="메모입력"
                                    TextInputWidth={20}
                                    radioNames="veranda"
                                />
                                <BoxTotalPrice Info={priceAll} />
                            </div>
                            {dobaeOn === 1 && (
                                <div className="title_color_box">
                                    <div
                                        className="box_title"
                                        style={{
                                            backgroundColor: "#41b06e",
                                            color: "white",
                                        }}
                                    >
                                        <h1>도배</h1>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="contentBorderWrap">
                                            <BoxDateInput
                                                startvalue={dobaeDate}
                                                setStartValue={setDobaeDate}
                                                title="시공일"
                                            />
                                            <BoxDropdownTime
                                                title="시공시간"
                                                value={dobaeTime}
                                                setValue={setDobaeTime}
                                                directInput={dobaeTimeDinput}
                                                setDirectInput={
                                                    setDobaeTimeDinput
                                                }
                                            />
                                            <BoxRadioboxInput
                                                title="시공범위"
                                                IsTextInput={true}
                                                TextInputWidth={20}
                                                placeholder="내용입력"
                                                directInput={dobaeRangeMemo}
                                                setDirectInput={
                                                    setDobaeRangeMemo
                                                }
                                                radioValue={dobaeRange}
                                                setRadioValue={setDobaeRange}
                                                Radioes={
                                                    consts.ConstructionScope
                                                }
                                                radioNames="dobaeRange"
                                            />
                                            <BoxRadioboxInput
                                                title="천장"
                                                Radioes={consts.Ceiling}
                                                radioValue={dobaeCeiling}
                                                setRadioValue={setDobaeCeiling}
                                                radioNames="dobaeCeiling"
                                            />

                                            <BoxDropdownInput
                                                title="제조사"
                                                value={dobaeCreateCompanyName}
                                                setValue={
                                                    setDobaeCreateCompanyName
                                                }
                                                Dropdowns={dobaeCompany}
                                                defaultValue={"없음"}
                                                // directInput={dobaeDirectCompany}
                                                // setDirectInput={
                                                //     setDobaeDirectCompany
                                                // }
                                            />
                                            <BoxRadioboxInput
                                                title="벽지 종류"
                                                Radioes={consts.wallpaperType}
                                                radioValue={dobaeProduct}
                                                setRadioValue={setDobaeProduct}
                                                extraInputType="number"
                                                radioNames="wallpaperType"
                                            />
                                            <BoxDropdownInput
                                                title="벽지 수"
                                                value={dobaeCnt}
                                                setValue={setDobaeCnt}
                                                Dropdowns={consts.TestEx3}
                                                directInput={dobaeDirectCnt}
                                                setDirectInput={
                                                    setDobaeDirectCnt
                                                }
                                            />
                                            <BoxTextInput
                                                title="견적금액"
                                                value={dobaePrice}
                                                setValue={setDobaePrice}
                                                unit="원"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {jangpanOn === 1 && (
                                <div className="title_color_box">
                                    <div
                                        className="box_title"
                                        style={{
                                            backgroundColor: "#fb8500",
                                            color: "white",
                                        }}
                                    >
                                        <h1>장판</h1>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="contentBorderWrap">
                                            <BoxDateInput
                                                startvalue={zangpanDate}
                                                setStartValue={setZangpanDate}
                                                title="시공일"
                                            />
                                            <BoxDropdownTime
                                                title="시공시간"
                                                value={zangpanTime}
                                                setValue={setZangpanTime}
                                                directInput={zangpanTimeDinput}
                                                setDirectInput={
                                                    setZangpanTimeDinput
                                                }
                                            />
                                            <BoxRadioboxInput
                                                title="시공범위"
                                                IsTextInput={true}
                                                TextInputWidth={20}
                                                placeholder="내용입력"
                                                directInput={zangpanRangeMemo}
                                                setDirectInput={
                                                    setZangpanRangeMemo
                                                }
                                                radioValue={zangpanRange}
                                                setRadioValue={setZangpanRange}
                                                Radioes={
                                                    consts.ConstructionScope
                                                }
                                                radioNames="zangpanRange"
                                            />
                                            <BoxDropdownInput
                                                title="제조사"
                                                Dropdowns={zangpanCompany}
                                                value={zangpanCreateCompanyName}
                                                setValue={
                                                    setZangpanCreateCompanyName
                                                }
                                                defaultValue={"없음"}
                                                // directInput={
                                                //     zangpanDirectCompany
                                                // }
                                                // setDirectInput={
                                                //     setZangpanDirectCompany
                                                // }
                                            />
                                            <BoxDropdownInput
                                                title="장판"
                                                Dropdowns={zangpanMMList}
                                                value={zangpanMM}
                                                setValue={setZangpanMM}
                                                defaultValue={"없음"}
                                                // directInput={zangpanDirectCompany}
                                                // setDirectInput={setZangpanDirectCompany}
                                            />
                                            <BoxDropdownTwoInput
                                                title="하단몰딩"
                                                value1={zangpanMolding}
                                                setValue1={setZangpanMolding}
                                                value1memo={zangpanMoldingMemo}
                                                setValue1Memo={
                                                    setZangpanMoldingMemo
                                                }
                                                value2={zangpanMoldingRange}
                                                setValue2={
                                                    setZangpanMoldingRange
                                                }
                                                value2memo={
                                                    zangpanMoldingRangeMemo
                                                }
                                                setValue2Memo={
                                                    setZangpanMoldingRangeMemo
                                                }
                                                Dropdowns={consts.molding1}
                                                Dropdowns2={consts.molding2}
                                            />
                                            <BoxRadioboxInput
                                                title="문턱"
                                                Radioes={consts.threshold}
                                                radioValue={zangpanThreshold}
                                                setRadioValue={
                                                    setZangpanThreshold
                                                }
                                                radioNames="zangpanThreshold"
                                            />

                                            <BoxTextInput
                                                value={zangpanPrice}
                                                setValue={setZangpanPrice}
                                                title="견적금액"
                                                inputWidth={20}
                                                unit="원"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {maruOn === 1 && (
                                <div className="title_color_box">
                                    <div
                                        className="box_title"
                                        style={{ backgroundColor: "#caf0f8" }}
                                    >
                                        <h1>마루</h1>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="contentBorderWrap">
                                            <BoxDateInput
                                                title="시공일"
                                                startvalue={maruDate}
                                                setStartValue={setMaruDate}
                                            />
                                            <BoxDropdownTime
                                                title="시공시간"
                                                value={maruTime}
                                                setValue={setMaruTime}
                                                directInput={maruTimeDinput}
                                                setDirectInput={
                                                    setMaruTimeDinput
                                                }
                                            />
                                            <BoxRadioboxInput
                                                title="시공범위"
                                                IsTextInput={true}
                                                TextInputWidth={20}
                                                placeholder="내용입력"
                                                directInput={maruRangeMemo}
                                                setDirectInput={
                                                    setMaruRangeMemo
                                                }
                                                radioValue={maruRange}
                                                setRadioValue={setMaruRange}
                                                Radioes={
                                                    consts.ConstructionScope
                                                }
                                                radioNames="maruRange"
                                            />
                                            <BoxDropdownInput
                                                title="제조사"
                                                Dropdowns={maruCompany}
                                                value={maruCreateCompanyName}
                                                setValue={
                                                    setMaruCreateCompanyName
                                                }
                                                defaultValue={"없음"}
                                                // directInput={maruDirectCompany}
                                                // setDirectInput={
                                                //     setMaruDirectCompany
                                                // }
                                            />
                                            <BoxDropdownMemoInput
                                                title="바닥재"
                                                defaultValue={1}
                                                value={maruFlooring}
                                                setValue={setMaruFlooring}
                                                InputValue={maruFlooringMemo}
                                                setInputValue={
                                                    setMaruFlooringMemo
                                                }
                                                Dropdowns={consts.TestEx}
                                            />
                                            <BoxTextInput
                                                title="기존 바닥재"
                                                value={maruOldProduct}
                                                setValue={setMaruOldProduct}
                                            />

                                            <BoxRadioboxInput
                                                title="하단몰딩"
                                                radioValue={maruDownMolding}
                                                setRadioValue={
                                                    setMaruDownMolding
                                                }
                                                Radioes={consts.threshold}
                                                radioNames="maruDownMolding"
                                            />
                                            <BoxRadioboxInput
                                                title="문턱"
                                                radioValue={maruThreshold}
                                                setRadioValue={setMaruThreshold}
                                                Radioes={consts.threshold}
                                                radioNames="maruThreshold"
                                            />
                                            <BoxRadioboxInput
                                                title="보양작업"
                                                radioValue={maruRepair}
                                                setRadioValue={setMaruRepair}
                                                Radioes={consts.threshold}
                                                radioNames="maruRepair"
                                            />

                                            <BoxTextInput
                                                title="견적금액"
                                                inputWidth={20}
                                                value={maruPrice}
                                                setValue={setMaruPrice}
                                                unit="원"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="title_color_box"
                                        style={{ border: "0px" }}
                                    >
                                        <div
                                            className="box_title"
                                            style={{
                                                backgroundColor: "#ced4da",
                                            }}
                                        >
                                            <h1>철거</h1>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="contentBorderWrap">
                                                <BoxDateInput
                                                    title="시공일"
                                                    startvalue={demolitionDate}
                                                    setStartValue={
                                                        setDemolitionDate
                                                    }
                                                />
                                                <BoxDropdownTime
                                                    title="시공시간"
                                                    value={demolitionTime}
                                                    setValue={setDemolitionTime}
                                                    directInput={
                                                        deolitionTimeDinput
                                                    }
                                                    setDirectInput={
                                                        setDemolitionTimeDinput
                                                    }
                                                />

                                                <BoxTextInput
                                                    value={demolitionRange}
                                                    setValue={
                                                        setDemolitionRange
                                                    }
                                                    title="범위"
                                                    type="text"
                                                    inputWidth={30}
                                                />
                                                <BoxTextInput
                                                    title="견적금액"
                                                    inputWidth={20}
                                                    value={demolitionPrice}
                                                    setValue={
                                                        setDemolitionPrice
                                                    }
                                                    unit="원"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="BorderBox">
                    <div className="title">
                        <h1>정보</h1>
                    </div>
                    <div className="contentWrap">
                        <BoxTextInput
                            value={enterPW}
                            setValue={setEnterPW}
                            title="현관 비밀번호"
                            inputWidth={10}
                        />
                        <BoxTextInput
                            value={doorPW}
                            setValue={setDoorPW}
                            title="입구 비밀번호"
                            inputWidth={10}
                        />
                        <BoxMemoInput
                            title="고객 메모"
                            value={customerMemo}
                            setValue={setCustomerMemo}
                        />
                    </div>
                </div>
                <div className="BorderBox">
                    <div className="title">
                        <h1>계약</h1>
                    </div>
                    <div className="contentWrap">
                        <BoxButtons
                            title="예약안내"
                            IsSend={
                                customerContractChk === 0 ? "미확인" : "확인"
                            }
                            OpenUrl={() =>
                                OpenUrl({ SendType: 1, idx: tap.idx })
                            }
                            gbtn={true}
                            bbtn={true}
                            bbtnNum={contractSendCount || 0}
                            bbtnClk={() => ContractMsg(3)}
                        />
                        <BoxDepositInput
                            sendMail={depositSendCount || 0}
                            sendMailClk={() => ContractMsg(4)}
                            deposit={deposit}
                            setDeposit={setDeposit}
                            price={depositAmount}
                            setPrice={setDepositAmount}
                            name={depositName}
                            setName={setDepositName}
                        />
                        <BoxButtons
                            title="최종확인서"
                            IsSend={customerFinalChk === 1 ? "발송" : "미발송"}
                            OpenUrl={() =>
                                OpenUrl({ SendType: 2, idx: tap.idx })
                            }
                            gbtn={true}
                            bbtn={true}
                            bbtnNum={finalSendCount || 0}
                            bbtnClk={() => ContractMsg(5)}
                        />
                    </div>
                </div>
                <div className="BorderBox">
                    <div className="title">
                        <h1>자재 선택</h1>
                    </div>
                    <div className="contentWrap">
                        <BoxRadioboxInput
                            title="상태"
                            Radioes={consts.submitBox}
                            radioValue={verandaExpansion}
                            setRadioValue={setVerandaExpansion}
                        />
                        <BoxButtons
                            title="발송"
                            gbtn={true}
                            OpenUrl={() =>
                                OpenUrl({ SendType: 3, idx: tap.idx })
                            }
                            bbtn={true}
                            bbtnNum={productSelSendCount || 0}
                            bbtnClk={() => ContractMsg(6)}
                        />
                        <BoxContent
                            title="제출 마감일"
                            content={
                                materialDate
                                    ? `${moment(materialDate).format(
                                          "YYYY-MM-DD HH:mm"
                                      )}`
                                    : "-"
                            }
                        />
                    </div>
                </div>
                {dobaeOn === 1 && (
                    <div className="title_color_box">
                        <div
                            className="box_title"
                            style={{
                                backgroundColor: "#41b06e",
                                color: "white",
                            }}
                        >
                            <h1>도배</h1>
                        </div>
                        <div className="contentColorWrap">
                            <div className="subTitleCircle">
                                <div className="GreenCircle"></div>
                                <h3>인력배정</h3>
                            </div>

                            <div className="contentBorderWrap">
                                <BoxDropdownInput
                                    title="총인원"
                                    Dropdowns={consts.parsonnel}
                                    value={dobaePersonnel}
                                    setValue={setDobaePersonnel}
                                />
                                <BoxDropdownInput
                                    title="반장"
                                    Dropdowns={dobaeWorkers}
                                    value={dobaeMakeManager}
                                    setValue={setDobaeMakeManager}
                                    defaultValue="없음"
                                />
                                <BoxButtons
                                    title="작업지시서"
                                    IsSend={
                                        dobaeOrderChk === 0 ? "미발송" : "발송"
                                    }
                                    OpenUrl={() =>
                                        OpenUrl({
                                            SendType: 4,
                                            idx: tap.idx,
                                            type: 1,
                                        })
                                    }
                                    gbtn={true}
                                    bbtn={true}
                                    bbtnNum={dobaeMsgSendCount || 0}
                                    bbtnClk={() => ContractSigongMsg(1)}
                                />

                                <BoxMemoInput
                                    title="작업자 전달사항"
                                    value={dobaeWorkerMemo}
                                    setValue={setDobaeWorkerMemo}
                                />
                            </div>
                        </div>
                        <div className="contentColorWrap">
                            <div className="subTitleCircle">
                                <div className="GreenCircle"></div>
                                <h3>선택벽지</h3>
                            </div>
                            <div className="contentBorderWrap">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        backgroundColor: "#41b06e",
                                        height: "35px",
                                        alignItems: "center",
                                        color: "white",
                                        marginBottom: "20px",
                                    }}
                                >
                                    <h2 style={{ fontSize: "20px" }}>
                                        벽지 종류 : [
                                        {dobaeCreateCompanyName === ""
                                            ? dobaeDirectCompany
                                            : dobaeCreateCompanyName}
                                        ]{" "}
                                        {dobaeProduct === 1
                                            ? "실크"
                                            : dobaeProduct === 2
                                            ? "합지"
                                            : dobaeProduct === 3
                                            ? "혼합"
                                            : "-"}{" "}
                                        , 벽지 수 : {dobaeCnt || 0}개 ,
                                        {dobaeCeiling === 1
                                            ? "천장 도배 포함"
                                            : "천장 도배 안함"}
                                        ,{" "}
                                        {veranda === 1
                                            ? "베란다 확장 없음"
                                            : veranda === 2
                                            ? "베란다 확장 있음"
                                            : "베란다 확장 미정"}
                                    </h2>
                                </div>
                                <ModelNumTableEdit
                                    list={dobaeMaterials}
                                    setList={setDobaeMaterials}
                                    ClkBtn={btnDobaePop}
                                    setFlag={setPopupFlag}
                                    FlagValue={3}
                                    setDeleteIdx={setMaterialDeleteIdx}
                                    setDeletePop={setDeletePop}
                                    deletePop={deletePop}
                                />
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>부자재</h3>
                                </div>
                                <TableEditClasfy
                                    list={subMaterials}
                                    setList={setSubMaterials}
                                    ClkBtn={btnDobaeSubPop}
                                    setFlag={setPopupFlag}
                                    FlagValue={4}
                                    setDeleteIdx={setMaterialDeleteIdx}
                                    setDeletePop={setDeletePop}
                                    deletePop={deletePop}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {jangpanOn === 1 && (
                    <div className="title_color_box">
                        <div
                            className="box_title"
                            style={{
                                backgroundColor: "#fb8500",
                                color: "white",
                            }}
                        >
                            <h1>장판</h1>
                        </div>

                        <div className="contentColorWrap">
                            <div className="subTitleCircle">
                                <div className="GreenCircle"></div>
                                <h3>인력배정</h3>
                            </div>
                            <div className="contentBorderWrap">
                                <BoxDropdownInput
                                    title="총인원"
                                    Dropdowns={consts.parsonnel}
                                    value={zangpanPersonnel}
                                    setValue={setZangpanPersonnel}
                                />
                                <BoxDropdownInput
                                    title="반장"
                                    Dropdowns={zangpanWorkers}
                                    value={zangpanMakeManager}
                                    setValue={setZangpanMakeManager}
                                    defaultValue="없음"
                                />
                                <BoxButtons
                                    title="작업지시서"
                                    IsSend={
                                        zangpanOrderChk === 0
                                            ? "미발송"
                                            : "발송"
                                    }
                                    OpenUrl={() =>
                                        OpenUrl({
                                            SendType: 4,
                                            idx: tap.idx,
                                            type: 2,
                                        })
                                    }
                                    gbtn={true}
                                    bbtn={true}
                                    bbtnNum={zangpanSendCount || 0}
                                    bbtnClk={() => ContractSigongMsg(2)}
                                />
                                <BoxMemoInput
                                    title="작업자 전달사항"
                                    value={zangpanWorkerMemo}
                                    setValue={setZangpanWorkerMemo}
                                />

                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>선택 제품</h3>
                                </div>
                                <ModelNumTableEdit2
                                    list={zangpanMaterials}
                                    setList={setZangpanMaterials}
                                    ClkBtn={btnZangpanPop}
                                    setFlag={setPopupFlag}
                                    FlagValue={5}
                                    setDeleteIdx={setMaterialDeleteIdx}
                                    setDeletePop={setDeletePop}
                                    deletePop={deletePop}
                                />
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>걸레받이</h3>
                                </div>
                                <ModelNumTableEdit3
                                    list={zangpanMop}
                                    setList={setZangpanMop}
                                    ClkBtn={btnZangpanBaseBoardPop}
                                    setFlag={setPopupFlag}
                                    FlagValue={6}
                                    setDeleteIdx={setMaterialDeleteIdx}
                                    setDeletePop={setDeletePop}
                                    deletePop={deletePop}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {maruOn === 1 && (
                    <div className="title_color_box">
                        <div
                            className="box_title"
                            style={{ backgroundColor: "#caf0f8" }}
                        >
                            <h1>마루</h1>
                        </div>
                        <div className="contentColorWrap">
                            <div className="subTitleCircle">
                                <div className="GreenCircle"></div>
                                <h3>인력배정</h3>
                            </div>
                            <div className="contentBorderWrap">
                                <BoxDropdownInput
                                    title="총인원"
                                    Dropdowns={consts.parsonnel}
                                    value={maruPersonnel}
                                    setValue={setMaruPersonnel}
                                />
                                <BoxDropdownInput
                                    title="반장"
                                    Dropdowns={maruWorkers}
                                    value={maruMakeManager}
                                    setValue={setMaruMakeManager}
                                    defaultValue="없음"
                                />
                                {/* <BoxButtons
                                    title="작업지시서"
                                    IsSend={
                                        maruOrderChk === 0 ? "미발송" : "발송"
                                    }
                                    gbtn={true}
                                    bbtn={true}
                                    bbtnNum={maruSendCount || 0}
                                    bbtnClk={() => ContractSigongMsg(3)}
                                /> */}
                                <BoxMemoInput
                                    title="작업자 전달사항"
                                    value={maruWorkerMemo}
                                    setValue={setMaruWorkerMemo}
                                />

                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>바닥재</h3>
                                </div>
                                <ModelNumTableEdit2
                                    list={maruMaterials}
                                    setList={setMaruMaterials}
                                    ClkBtn={btnMaruPop}
                                    setFlag={setPopupFlag}
                                    FlagValue={7}
                                    setDeleteIdx={setMaterialDeleteIdx}
                                    setDeletePop={setDeletePop}
                                    deletePop={deletePop}
                                />
                                <div className="subTitleCircle">
                                    <div className="GreenCircle"></div>
                                    <h3>걸레받이</h3>
                                </div>
                                <ModelNumTableEdit3
                                    list={maruMop}
                                    setList={setMaruMop}
                                    ClkBtn={btnMaruBaseBoardPop}
                                    setFlag={setPopupFlag}
                                    FlagValue={8}
                                    setDeleteIdx={setMaterialDeleteIdx}
                                    setDeletePop={setDeletePop}
                                    deletePop={deletePop}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "15px",
                                        alignItems: "center",
                                    }}
                                >
                                    <div className="subTitleCircle">
                                        <div className="GreenCircle"></div>
                                        <h3>시공안내문</h3>
                                    </div>
                                </div>
                                <div
                                    style={{ borderTop: "1px solid #ddd" }}
                                    className="contentWrap"
                                >
                                    <BoxTextInput
                                        value={apartUnit}
                                        setValue={setApartUnit}
                                        title="세대"
                                        type="text"
                                        inputWidth={10}
                                    />
                                    <BoxTextInput
                                        value={constrUnit}
                                        setValue={setConstrUnit}
                                        title="공사세대"
                                        type="text"
                                        inputWidth={10}
                                    />
                                    <BoxTextInput
                                        value={constrDetail}
                                        setValue={setConstrDetail}
                                        title="공사내역"
                                        inputWidth={50}
                                    />
                                    <BoxDateInput
                                        title="공사기간"
                                        startvalue={constrStartDate}
                                        setStartValue={setConstrStartDate}
                                        endValue={constrEndDate}
                                        setEndValue={setConstrEndDate}
                                        isDouble={true}
                                    />
                                    <BoxDateInput
                                        startvalue={constrNoise}
                                        setStartValue={setConstrNoise}
                                        title="소음발생 예상일자"
                                        inputWidth={50}
                                    />
                                    <BoxTextInput
                                        value={constrFieldManager}
                                        setValue={setConstrFieldManager}
                                        title="현장담당자"
                                        inputWidth={50}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="MemoBoxWrap" style={{ marginTop: "40px" }}>
                    <div className="title">
                        <img src={images.pencil} />
                        <p>고객메모/요청사항</p>
                    </div>

                    <div className="MemoWrap">
                        <textarea
                            className="MemoBox"
                            value={customerDirectMemo}
                            onChange={(e) =>
                                setCustomerDirectMemo(e.target.value)
                            }
                        ></textarea>
                    </div>
                </div>

                <div className="SaveBtn">
                    <button className="btn btn9" onClick={submitClk}>
                        저장
                    </button>
                </div>
                {index && (
                    <>
                        <MemoBox
                            memoWrite={memoWrite}
                            setMemoWrite={setMemoWrite}
                            memoType={memoType}
                            setMemoType={setMemoType}
                            submitClk={saveMemo}
                        />
                        {memoListCommon.length > 0 && (
                            <TableMemo
                                memotype={"일반"}
                                Info={memoListCommon}
                                deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                setDeleteIdx={setDeleteIdx}
                                isDelete={isDelete}
                                setIsDelete={setIsDelete}
                                setFlag={setPopupFlag}
                                FlagValue={2}
                            />
                        )}
                        {memoListDobae.length > 0 && (
                            <TableMemo
                                memotype={"도배"}
                                Info={memoListDobae}
                                deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                setDeleteIdx={setDeleteIdx}
                                isDelete={isDelete}
                                setIsDelete={setIsDelete}
                                setFlag={setPopupFlag}
                                FlagValue={2}
                            />
                        )}

                        {memoListZangpan.length > 0 && (
                            <TableMemo
                                memotype={"장판"}
                                Info={memoListZangpan}
                                deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                setDeleteIdx={setDeleteIdx}
                                isDelete={isDelete}
                                setIsDelete={setIsDelete}
                                setFlag={setPopupFlag}
                                FlagValue={2}
                            />
                        )}
                        {memoListMaru.length > 0 && (
                            <TableMemo
                                memotype={"마루"}
                                Info={memoListMaru}
                                deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                setDeleteIdx={setDeleteIdx}
                                isDelete={isDelete}
                                setIsDelete={setIsDelete}
                                setFlag={setPopupFlag}
                                FlagValue={2}
                            />
                        )}
                        {memoListSignificant.length > 0 && (
                            <TableMemo
                                memotype={"특이사항"}
                                Info={memoListSignificant}
                                deletePop={deletePop}
                                setDeletePop={setDeletePop}
                                setDeleteIdx={setDeleteIdx}
                                isDelete={isDelete}
                                setIsDelete={setIsDelete}
                                setFlag={setPopupFlag}
                                FlagValue={2}
                            />
                        )}
                        <div className="twoBtn_wrap">
                            <button
                                className="btn btn8"
                                onClick={() => ListGoback()}
                            >
                                목록
                            </button>
                            <button className="btn btn9" onClick={submitClk}>
                                저장
                            </button>
                        </div>
                    </>
                )}
                <HistoryMessageBox
                    Info={customerMsg}
                    page={customMsgPage}
                    handlePageChange={handleCustomMsgPageChange}
                    totalCount={customMsgTotal}
                />
                <HistorySusinBox
                    page={msgPage}
                    Info={susinData}
                    handlePageChange={handleMsgPageChange}
                    totalCount={totalSusinCount}
                />
                <HistoryMessageBox
                    IsWorker={true}
                    Info={workerMsg}
                    page={workerMsgPage}
                    handlePageChange={handleWorkerMsgPageChange}
                    totalCount={workerMsgTotal}
                />
            </div>
        </>
    );
}

export default ConstrManagementRegister;
