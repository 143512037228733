import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import routes from "./libs/routes";

import Login from "./pages/auth/Login";

import Header from "./components/Header";
//ConstStatus
import ReqforConsult from "./pages/constStatus/ReqForConsult";
import EstimateReq from "./pages/constStatus/EstimateReq";
import ConstrManagement from "./pages/constStatus/ConstrMangement";
import OrderManagement from "./pages/constStatus/OrderManagement";
import JobFinished from "./pages/constStatus/JobFinished";
import ASManagement from "./pages/constStatus/ASManagement";
import ConstrCalendar from "./pages/constStatus/ConstrCalendar";
import VisitCalendar from "./pages/constStatus/VisitCalendar";
import DeleteList from "./pages/constStatus/DeleteList";

//ConstStatusPreview
import ReqForConsultPreview from "./pages/constStatus/Preview/ReqForConsultPreview";
import EstimateReqPreview from "./pages/constStatus/Preview/EstimateReqPreview";
import ConstrManagPreview from "./pages/constStatus/Preview/ConstrManagPreview";
import OrderManagePreview from "./pages/constStatus/Preview/OrderManagePreview";
import JobFinishedPreview from "./pages/constStatus/Preview/JobFinishedPreview";
import ASManagePreview from "./pages/constStatus/Preview/ASManagePreview";

//Material
import InventManagement from "./pages/material/InventManagement";
import WInventManagement from "./pages/material/WInventManagement";
import ReturnManagement from "./pages/material/ReturnManagement";
import AgencyMaterialManagement from "./pages/material/AgencyMaterialManagement";
import MaterialListManagement from "./pages/material/MaterialListManagement";
import MaterialClassifManagement from "./pages/material/MaterialClassifManagement";
import AgencyManagement from "./pages/material/AgencyManagement";

//WorkerInventory
import LookInventory from "./pages/material/WorkerInvent/LookInventory";
import LogSearch from "./pages/material/WorkerInvent/LogSearch";

//AgencyMaterialDetail
import AgencyMaterialDetail from "./pages/material/AgencyMaterialDetail/AgencyMaterialDetail";

//MaterialListManageDetail
import MaterialListManageDetail from "./pages/material/MaterialListManageDetail/MaterialListManageDetail";

//AgencyManageDetail
import AgencyManageDetail from "./pages/material/AgencyManageDetail/AgencyManageDetail";
//Worker
import WorkerManagement from "./pages/workers/WorkerManagement";
import WorkerManageDetail from "./pages/workers/WorkerManageDetail";
//Message
import OutgoingList from "./pages/message/OutgoingList";
import IncomingList from "./pages/message/IncomingList";
import CharacterTemplate from "./pages/message/CharacterTemplate";
import SystemMessManagement from "./pages/message/SystemMessManagement";
//Management
import ExhibitionBanner from "./pages/management/ExhibitionBanner";
import FAQManagement from "./pages/management/FAQManagement";
import ManegerAccManagement from "./pages/management/ManegerAccManagement";
//ManagementDetail
import FAQManagementDetail from "./pages/management/FAQManagementDetail";
import ManagerAccDetail from "./pages/management/ManagerAccDetail";

import EstimateReqRegister from "./pages/constStatus/Register/EstimateReqRegister";
import ConstrManagementRegister from "./pages/constStatus/Register/ConstrManagementRegister";
import OrderManageRegister from "./pages/constStatus/Register/OrderManageRegister";
import JobFinishedRegister from "./pages/constStatus/Register/JobFinishedRegister";
import ASManageRegister from "./pages/constStatus/Register/ASManageRegister";
import { useGetData, usePostData } from "./utils/service";
import { useEffect, useState } from "react";
import { postData } from "./utils/service";
import { useUser } from "./zustand/Login";
import ApiData from "./libs/api";
import PriceSetting from "./pages/management/PriceSetting";

const PageTracker = () => {
    const location = useLocation();
    const ChkingData = usePostData(ApiData.mbChk);
    const { mbData, ResetMbData } = useUser();
    useEffect(() => {
        // 페이지 이동이 감지되었을 때 실행할 코드
        routes?.login != "/login" &&
            mbData?.token &&
            ChkingData.mutateAsync()
                .then((data) => {
                    // console.log("성공..");
                })
                .catch((e) => {
                    ResetMbData();
                });
    }, [location?.pathname]);

    return null; // 이 컴포넌트는 렌더링할 UI가 없으므로 null을 반환합니다.
};

function Router() {
    const { mbData, ResetMbData } = useUser();
    const [mbLevel, setMbLevel] = useState([]);

    useEffect(() => {
        setMbLevel(mbData?.mb_level.map(Number));
    }, [mbData]);
    // window.addEventListener("storage", (event) => {
    //     if (event.key === "Login-Storage") {
    //         ResetMbData();
    //     }
    // });

    return (
        <>
            <BrowserRouter>
                {mbData?.mb_id ? (
                    <>
                        <Header />
                        <main>
                            <PageTracker />

                            <Routes>
                                <Route
                                    exact
                                    path={"/"}
                                    element={
                                        mbLevel.includes(1) ? (
                                            <ReqforConsult />
                                        ) : mbLevel.includes(2) ? (
                                            <InventManagement />
                                        ) : mbLevel.includes(3) ? (
                                            <WorkerManagement />
                                        ) : mbLevel.includes(4) ? (
                                            <OutgoingList />
                                        ) : mbLevel.includes(5) ? (
                                            <ExhibitionBanner />
                                        ) : (
                                            <InventManagement />
                                        )
                                    }
                                />
                                <Route
                                    exact
                                    path={routes.constr1}
                                    element={<ReqforConsult />}
                                />
                                <Route
                                    exact
                                    path={routes.constr2}
                                    element={<EstimateReq />}
                                />
                                <Route
                                    exact
                                    path={routes.constr3}
                                    element={<ConstrManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.constr4}
                                    element={<OrderManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.constr5}
                                    element={<JobFinished />}
                                />
                                <Route
                                    exact
                                    path={routes.constr6}
                                    element={<ASManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.constr7}
                                    element={<ConstrCalendar />}
                                />
                                <Route
                                    exact
                                    path={routes.constr8}
                                    element={<VisitCalendar />}
                                />
                                <Route
                                    exact
                                    path={routes.constr9}
                                    element={<DeleteList />}
                                />
                                <Route
                                    exact
                                    path={routes.constPreview1}
                                    element={<ReqForConsultPreview />}
                                />
                                <Route
                                    exact
                                    path={routes.constPreview2}
                                    element={<EstimateReqPreview />}
                                />
                                <Route
                                    exact
                                    path={routes.constPreview3}
                                    element={<ConstrManagPreview />}
                                />
                                <Route
                                    exact
                                    path={routes.constPreview4}
                                    element={<OrderManagePreview />}
                                />
                                <Route
                                    exact
                                    path={routes.constPreview5}
                                    element={<JobFinishedPreview />}
                                />
                                <Route
                                    exact
                                    path={routes.constPreview6}
                                    element={<ASManagePreview />}
                                />
                                <Route
                                    exact
                                    path={routes.constRegst1}
                                    element={<EstimateReqRegister />}
                                />
                                <Route
                                    exact
                                    path={routes.constRegst2}
                                    element={<ConstrManagementRegister />}
                                />
                                <Route
                                    exact
                                    path={routes.constRegst3}
                                    element={<OrderManageRegister />}
                                />
                                <Route
                                    exact
                                    path={routes.constRegst4}
                                    element={<JobFinishedRegister />}
                                />
                                <Route
                                    exact
                                    path={routes.constRegst5}
                                    element={<ASManageRegister />}
                                />
                                <Route
                                    exact
                                    path={routes.material1}
                                    element={<InventManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.material2}
                                    element={<WInventManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.material3}
                                    element={<ReturnManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.material4}
                                    element={<AgencyMaterialManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.material5}
                                    element={<MaterialListManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.material6}
                                    element={<MaterialClassifManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.material7}
                                    element={<AgencyManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.worker}
                                    element={<WorkerManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.message1}
                                    element={<OutgoingList />}
                                />
                                <Route
                                    exact
                                    path={routes.message2}
                                    element={<IncomingList />}
                                />
                                <Route
                                    exact
                                    path={routes.message3}
                                    element={<CharacterTemplate />}
                                />
                                <Route
                                    exact
                                    path={routes.message4}
                                    element={<SystemMessManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.management1}
                                    element={<ExhibitionBanner />}
                                />
                                <Route
                                    exact
                                    path={routes.management2}
                                    element={<FAQManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.management3}
                                    element={<ManegerAccManagement />}
                                />
                                <Route
                                    exact
                                    path={routes.management4}
                                    element={<PriceSetting />}
                                />
                                <Route
                                    exact
                                    path={routes.material2_1}
                                    element={<LookInventory />}
                                />{" "}
                                <Route
                                    exact
                                    path={routes.material2_2}
                                    element={<LogSearch />}
                                />
                                <Route
                                    exact
                                    path={routes.material4_1}
                                    element={<AgencyMaterialDetail />}
                                />
                                <Route
                                    exact
                                    path={routes.material5_1}
                                    element={<MaterialListManageDetail />}
                                />
                                <Route
                                    exact
                                    path={routes.material7_1}
                                    element={<AgencyManageDetail />}
                                />
                                <Route
                                    exact
                                    path={routes.managementDetail1}
                                    element={<FAQManagementDetail />}
                                />
                                <Route
                                    exact
                                    path={routes.managementDetail2}
                                    element={<ManagerAccDetail />}
                                />
                                <Route
                                    exact
                                    path={routes.workerDetail}
                                    element={<WorkerManageDetail />}
                                />
                            </Routes>
                        </main>
                    </>
                ) : (
                    <Routes>
                        <Route exact path={"*"} element={<Login />} />
                        <Route exact path={routes.login} element={<Login />} />
                        {/* <Route
                            exact
                            path={routes.master}
                            element={<Master />}
                        /> */}
                    </Routes>
                )}
            </BrowserRouter>
        </>
    );
}
export default Router;
