import { useEffect, useState } from "react";
import InputDate from "../../../components/InputDate";

import { InputCheckBox } from "../../../components/InputCheckBox";

import consts from "../../../libs/consts";

import Pagination from "react-js-pagination";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { usePostData } from "../../../utils/service";
import ApiData from "../../../libs/api";
import moment from "moment";
import { toast } from "react-toastify";
import useLoadingStore from "../../../zustand/Loading";

function LogSearch() {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const workerMateLogList = usePostData(ApiData.workerMateLogList);
    const workerMateLogMemo = usePostData(ApiData.workerMateLogMemo);
    const [dataList, setDataList] = useState([]);
    const [allDataList, setAllDataList] = useState([]);
    const [sdate, setsdate] = useState("");
    const [edate, setedate] = useState("");
    const [searchType, setSearchType] = useState([]);
    // const { loadingStart, loadingEnd } = useLoadingStore();

    const [page, setPage] = useState(1);
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handleReset = () => {
        //console.log(1111);
        setsdate("");
        setedate("");
        setSearchType([]);
        LogListApi(true);
    };
    const handleSave = (i) => {
        let sender = {
            logIdx: i,
            memo: dataList.find((item) => item.idx === i).memo,
        };
        workerMateLogMemo
            .mutateAsync(sender)
            .then(({ data }) => {
                LogListApi();
                toast.success("저장되었습니다.");
            })
            .catch((error) => {
                toast.error("저장에 실패하였습니다.");
            });
    };
    const updateDataList = (i, key, value) => {
        setDataList(
            dataList?.map((item, index) =>
                item.idx === i ? { ...item, [key]: value } : item
            )
        );
    };
    const LogListApi = (reset = false) => {
        let sender = {
            start_date: sdate,
            end_date: edate,
            reason_type: searchType,
            page: page - 1,
            workerIdx: index,
        };
        if (reset) {
            sender = {
                start_date: "",
                end_date: "",
                reason_type: [],
                page: 0,
                workerIdx: index,
            };
        }
        // loadingStart();
        //console.log("sender", sender);
        workerMateLogList
            .mutateAsync(sender)
            .then(({ data }) => {
                //console.log("data!#!@#!#", data);
                // loadingEnd();
                setDataList(data.list);
                setAllDataList(data);
            })
            .catch((error) => {
                // loadingEnd();
                toast.error(error.response.data);
            });
    };
    useEffect(() => {
        //console.log("searchType", searchType);
    }, [searchType]);
    useEffect(() => {
        LogListApi();
    }, [page]);

    return (
        <>
            <div className="content ">
                <p className="title">
                    [{location?.state?.data || "OO"}] 작업자 재고 로그 조회
                </p>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <InputDate
                                type="text"
                                placeholder="시작날짜"
                                placeholderEnd="종료날짜"
                                name="ipd1"
                                label="등록일"
                                dateValue={sdate}
                                setDateValue={setsdate}
                                dateValueEnd={edate}
                                setDateValueEnd={setedate}
                                multiple={true}
                            />
                        </div>
                        <InputCheckBox
                            title="사유"
                            Chkboxes={consts.reason}
                            setValue={setSearchType}
                            values={searchType}
                        />
                    </div>

                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button className="btn btn8" onClick={handleReset}>
                                초기화
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() => LogListApi()}
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>
                <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                    [등록: {allDataList?.inCnt}롤, 차감 : {allDataList?.outCnt}
                    롤]
                </p>
                <div className="logSearchResult">
                    <p>
                        조회기간 :{" "}
                        {sdate
                            ? moment(sdate).format("YYYY-MM-DD")
                            : "0000-00-00"}{" "}
                        ~{" "}
                        {edate
                            ? moment(edate).format("YYYY-MM-DD")
                            : "0000-00-00"}
                    </p>
                    <p>수량 : {allDataList?.sumCnt}롤</p>
                </div>
                <div className="table_scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>날짜</th>
                                <th>모델번호</th>
                                <th>사유</th>
                                <th>등록</th>
                                <th>차감</th>
                                <th>관리자</th>
                                <th>비고</th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {moment(item.create_dt).format(
                                                "YYYY-MM-DD"
                                            )}
                                        </td>
                                        <td>{item.material_number}</td>
                                        <td>
                                            {item.reason_type === 0
                                                ? "삭제"
                                                : item.reason_type === 1
                                                ? "등록"
                                                : item.reason_type === 2
                                                ? "회수"
                                                : item.reason_type === 3
                                                ? "반품"
                                                : item.reason_type === 4
                                                ? "수정"
                                                : "-"}
                                        </td>
                                        <td>
                                            {item.reason_type === 1
                                                ? item.count + "롤"
                                                : item.reason_type === 4
                                                ? item.count - item.ori_count >
                                                  0
                                                    ? item.count -
                                                      item.ori_count +
                                                      "롤"
                                                    : null
                                                : null}
                                        </td>
                                        <td>
                                            {item.reason_type !== 1
                                                ? item.count - item.ori_count >
                                                  0
                                                    ? null
                                                    : item.count + "롤"
                                                : null}
                                        </td>
                                        <td>{item.admin_name}</td>
                                        <td>
                                            <div className="tableInputWrap">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    value={item.memo || ""}
                                                    index={index}
                                                    onChange={(e) => {
                                                        updateDataList(
                                                            item.idx,
                                                            "memo",
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn9"
                                                onClick={() =>
                                                    handleSave(item.idx)
                                                }
                                            >
                                                저장
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="BottomContent">
                    <div
                        className="PaginationContent"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <Pagination
                            className="my-pagination"
                            // 현제 보고있는 페이지
                            activePage={page}
                            // 한페이지에 출력할 아이템수
                            itemsCountPerPage={20}
                            // 총 아이템수
                            totalItemsCount={allDataList?.count || 0}
                            // 표시할 페이지수
                            pageRangeDisplayed={10}
                            // 함수
                            onChange={handlePageChange}
                            nextPageText=""
                            prevPageText=""
                            firstPageText=""
                            lastPageText=""
                        ></Pagination>
                    </div>
                </div>
            </div>
        </>
    );
}
export default LogSearch;
