import { useNavigate } from "react-router-dom";
import images from "../libs/images";
import routes from "../libs/routes";

import Input from "./Input";
import moment from "moment";
import consts from "../libs/consts";
import ApiData from "../libs/api";
import { useGetData, usePostData } from "../utils/service";
import { memo, useEffect, useState } from "react";
import { set } from "lodash";
import Pagination from "react-js-pagination";

const Dropdowns = [
    {
        value: 0,
        text: "이종훈",
    },
    {
        value: 1,
        text: "김정수",
    },
    {
        value: 2,
        text: "사진종",
    },
];
const test = [
    {
        title: "도배",
        id: 1,
        choose: "1",
        state: 0,
        construction: "홍길동3",
        contact: "010-1234-5678",
        hopeDate: "2021-10-01",
        area: "100",
        material: "타일",
        regDate: "2021-09-01",
        manage: "",
        test: ["예약안내", "계약금", "작업지시서"],
        test2: ["●", "✕", "✕"],
        having: true,
        structDate: ["01-11(목)", "01-12(금)", "01-13(토)"],
        defect: ["도배하자", "장판하자", "마루하자"],
        reportChk: [true, true, false],
        price: ["100,000", "200,000", "300,000"],
        product: [
            {
                title: "도배",
                having: true,
            },
            {
                title: "장판",
                having: true,
            },
            {
                title: "마루",
                having: false,
            },
            {
                title: "철거",
                having: true,
            },
        ],
        product2: [
            {
                title: "도배",
                having: true,
            },
            {
                title: "장판",
                having: true,
            },
            {
                title: "마루",
                having: true,
            },
        ],
    },
    {
        title: "장판",
        id: 2,
        choose: "1",
        state: 1,
        construction: "홍길동4",
        contact: "010-1234-5678",
        hopeDate: "2021-10-01",
        area: "100",
        material: "타일",
        regDate: "2021-09-01",
        manage: "",
        test: ["예약안내", "계약금", "작업지시서"],
        test2: ["●", "✕", "✕"],
        defect: ["도배하자", "장판하자"],
        reportChk: [false, true],
        structDate: ["01-11(목)", "01-12(금)"],
        price: ["100,000", "200,000"],
        product2: [
            {
                title: "도배",
                having: true,
            },
            {
                title: "장판",
                having: true,
            },
        ],
        product: [
            {
                title: "도배",
                having: true,
            },
            {
                title: "장판",
                having: true,
            },
            {
                title: "마루",
                having: true,
            },
            {
                title: "철거",
                having: true,
            },
        ],
    },
    {
        title: "마루",
        id: 3,
        choose: "1",
        state: 2,
        construction: "홍길동2",
        contact: "010-1234-5678",
        hopeDate: "2021-10-01",
        area: "100",
        material: "타일",
        regDate: "2021-09-01",
        manage: "",
        test: ["예약안내", "계약금", "작업지시서"],
        defect: ["도배하자"],
        reportChk: [true],
        test2: ["●", "✕", "✕"],
        structDate: ["01-11(목)"],
        price: ["100,000"],
        product2: [
            {
                title: "도배",
                having: true,
            },

            {
                title: "마루",
                having: false,
            },
        ],
        product: [
            {
                title: "도배",
                having: false,
            },
            {
                title: "장판",
                having: true,
            },
            {
                title: "마루",
                having: false,
            },
            {
                title: "철거",
                having: true,
            },
        ],
    },
    {
        id: 4,
        choose: "1",
        state: 0,
        construction: "홍길동1",
        contact: "010-1234-5678",
        hopeDate: "2021-10-01",
        area: "100",
        material: "타일",
        regDate: "2021-09-01",
        manage: "",
        test: ["예약안내", "계약금", "작업지시서"],
        defect: ["도배하자", "장판하자"],
        reportChk: [true, true],
        test2: ["●", "✕", "✕"],
        price: ["100,000", "200,000"],
        structDate: ["01-11(목)", "01-12(금)"],
        product2: [
            {
                title: "도배",
                having: true,
            },
            {
                title: "장판",
                having: true,
            },
            {
                title: "마루",
                having: false,
            },
        ],
        product: [
            {
                title: "도배",
                having: false,
            },
            {
                title: "장판",
                having: false,
            },
            {
                title: "마루",
                having: false,
            },
            {
                title: "철거",
                having: true,
            },
        ],
    },
];

export function ReqConsultTable({
    values,
    setValues,
    Data = [],
    isDelete = false,
    setDeletePop,
    deletePop,
    setDeleteIdx,
    setPopupType,
}) {
    const navigate = useNavigate();

    // const ClkRecoverOne = (idx) => {
    //     setPopupType(1);
    //     setDeletePop(!deletePop);
    //     setValues([idx]);
    // };
    // const ClkDeleteOne = (idx) => {
    //     setPopupType(2);
    //     setDeletePop(!deletePop);
    //     setValues([idx]);
    // };

    const DeleteClk = (idx, sidx) => {
        setDeletePop(!deletePop);

        setDeleteIdx(idx);
    };
    const handleChange = (e) => {
        if (e.target.checked) {
            setValues([...values, e.target.value]);
        } else {
            setValues(values.filter((value) => value !== e.target.value));
        }
    };
    const getStatusText = (state) => {
        switch (state) {
            case 1:
                return (
                    <div
                        className="TableState"
                        style={{
                            backgroundColor: "#CCC",
                        }}
                    >
                        확인
                    </div>
                );
            case 2:
                return (
                    <div
                        className="TableState"
                        style={{
                            backgroundColor: "#f00",
                        }}
                    >
                        미확인
                    </div>
                );
            case 3:
                return (
                    <div
                        className="TableState"
                        style={{
                            backgroundColor: "#5C96ED",
                        }}
                    >
                        부재중
                    </div>
                );

            default:
                return "";
        }
    };
    return (
        <>
            <div className="table_scroll">
                <table>
                    <thead>
                        <tr>
                            <th>선택</th>
                            <th>상태</th>
                            <th>시공</th>
                            <th>연락처</th>
                            <th>희망일</th>
                            <th>면적</th>
                            <th>자재</th>
                            <th>등록일시</th>
                            {!isDelete && <th>관리</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {Data?.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        checked={values
                                            .map((c) => parseInt(c))
                                            .includes(item.idx)}
                                        value={item.idx}
                                        onChange={handleChange}
                                        id={item.idx}
                                        className="OneChkBox"
                                        type="checkbox"
                                    />
                                    <label htmlFor={item.idx} />
                                </td>
                                <td className="FlexCenter">
                                    {getStatusText(item.counseling_type)}
                                </td>
                                <td>
                                    {item.sigong_type === 1 ? "도배" : "장판"}
                                </td>
                                <td>
                                    {item.mb_phone.replace(
                                        /(\d{3})(\d{4})(\d{4})/,
                                        "$1-$2-$3"
                                    ) || "-"}
                                </td>
                                <td>
                                    {item.sigong_date || "-"}(
                                    {consts.dayText[
                                        moment(item.sigong_date).day()
                                    ] || "-"}
                                    )
                                </td>
                                <td>
                                    {item.area_pyeong}평(
                                    {item.area === 1 ? "공급" : "전용"})
                                </td>
                                <td>
                                    {item.dobae_product === 1
                                        ? "실크"
                                        : item.dobae_product === 2
                                        ? "합지"
                                        : "혼합"}
                                </td>
                                <td>
                                    {moment(item.create_dt).format(
                                        "YYYY.MM.DD HH:mm"
                                    ) || "-"}
                                </td>
                                {!isDelete && (
                                    <td className="FlexCenter TableBtns">
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constPreview1}?index=${item.idx}`
                                                );
                                            }}
                                        >
                                            보기
                                        </button>
                                        <button
                                            onClick={() => DeleteClk(item.idx)}
                                        >
                                            삭제
                                        </button>
                                    </td>
                                )}
                                {/* {isDelete ? (
                                    <td className="FlexCenter TableBtns">
                                        <button
                                            onClick={() =>
                                                ClkRecoverOne(item.idx)
                                            }
                                        >
                                            복원
                                        </button>
                                        <button
                                            onClick={() =>
                                                ClkDeleteOne(item.idx)
                                            }
                                        >
                                            삭제
                                        </button>
                                    </td>
                                ) : (
                                    <td className="FlexCenter TableBtns">
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constPreview1}?index=${item.idx}`
                                                );
                                            }}
                                        >
                                            보기
                                        </button>
                                        <button
                                            onClick={() => DeleteClk(item.idx)}
                                        >
                                            삭제
                                        </button>
                                    </td>
                                )} */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export function SendHistory({ list, page, handlePageChange, totalCount }) {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>발송 일시</th>
                        <th>관리자명</th>
                        <th>발송 내용</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, index) => (
                        <tr key={index}>
                            <td>
                                {moment(item?.create_dt).format("YYYY-MM-DD")}
                            </td>
                            <td>{item.adm_name}</td>
                            <td>{item.msg_content}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                className="my-pagination"
                // 현제 보고있는 페이지
                activePage={page}
                // 한페이지에 출력할 아이템수
                itemsCountPerPage={20}
                // 총 아이템수
                totalItemsCount={totalCount || 0}
                // 표시할 페이지수
                pageRangeDisplayed={10}
                // 함수
                onChange={handlePageChange}
                nextPageText=""
                prevPageText=""
                firstPageText=""
                lastPageText=""
            ></Pagination>
        </div>
    );
}

export function EstimateTable({
    isDelete = false,
    Info = [],
    setDeletePop,
    deletePop,
    setDeleteIdx,
    values,
    setValues,
}) {
    const navigate = useNavigate();
    const [managerSetting, setManagerSetting] = useState([]);

    const {
        data: ManagerData,
        isLoading,
        isError,
    } = useGetData(ApiData.adminIdList);
    const DeleteClk = (idx) => {
        setDeletePop(!deletePop);

        setDeleteIdx(idx);
    };
    useEffect(() => {
        ManagerData?.data?.map((item, index) => {
            setManagerSetting((prev) => [
                ...prev,
                { value: item.idx, text: item.mb_name },
            ]);
        });
        return () => {
            setManagerSetting([]); // 상태를 초기화
        };
        // setManagerSetting();
    }, [ManagerData]);

    const handleChange = (e) => {
        if (e.target.checked) {
            setValues([...values, e.target.value]);
        } else {
            setValues(values.filter((value) => value !== e.target.value));
        }
    };
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        {isDelete && <th>선택</th>}
                        <th>등록일시</th>
                        <th>담당자</th>
                        <th>고객명</th>
                        <th>연락처</th>
                        <th>주소</th>
                        <th>면적</th>
                        <th>총액</th>
                        <th>시공일</th>
                        <th>제품</th>
                        {!isDelete && <th>관리</th>}
                    </tr>
                </thead>
                <tbody>
                    {Info.map((item, index) => (
                        <tr key={index}>
                            {isDelete && (
                                <td>
                                    <input
                                        checked={values
                                            .map((c) => parseInt(c))
                                            .includes(item.idx)}
                                        value={item.idx}
                                        onChange={handleChange}
                                        id={item.idx}
                                        className="OneChkBox"
                                        type="checkbox"
                                    />
                                    <label htmlFor={item.idx} />
                                </td>
                            )}

                            <td>
                                {moment(item.create_dt).format(
                                    "YYYY-MM-DD HH:mm"
                                ) || "-"}
                            </td>
                            <td>{item.mb_name || "-"}</td>
                            <td>{item.cutomer_name || "-"}</td>
                            <td>{item.mb_phone || "-"}</td>
                            <td>{`${
                                item.address !== null &&
                                item.address_detail !== null
                                    ? item.address + " " + item.address_detail
                                    : item.address !== null &&
                                      item.address_detail === null
                                    ? item.address
                                    : item.address === null &&
                                      item.address_detail !== null
                                    ? item.address_detail
                                    : "-"
                            }`}</td>
                            <td>{`${
                                item.area_pyeong ? item.area_pyeong : 0
                            }평(${item.area == 1 ? "공급" : "전용"})`}</td>
                            <td>
                                {`${(
                                    item.maru_price +
                                    item.zangpan_price +
                                    item.dobae_price +
                                    item.demolition_price
                                ).toLocaleString()}원`}
                            </td>

                            <td>
                                {
                                    <>
                                        {item?.dobae_date ||
                                        item?.zangpan_date ||
                                        item?.maru_date ? (
                                            // ||
                                            // item?.demolition_date
                                            <>
                                                {item?.dobae_date && (
                                                    <p>{`${moment(
                                                        item.dobae_date
                                                    ).format("YYYY-MM-DD")}(${
                                                        consts.dayText[
                                                            moment(
                                                                item.dobae_date
                                                            ).day()
                                                        ]
                                                    })`}</p>
                                                )}
                                                {item?.zangpan_date && (
                                                    <p>{`${moment(
                                                        item.zangpan_date
                                                    ).format("YYYY-MM-DD")}(${
                                                        consts.dayText[
                                                            moment(
                                                                item.zangpan_date
                                                            ).day()
                                                        ]
                                                    })`}</p>
                                                )}
                                                {item?.maru_date && (
                                                    <p>{`${moment(
                                                        item.maru_date
                                                    ).format("YYYY-MM-DD")}(${
                                                        consts.dayText[
                                                            moment(
                                                                item.maru_date
                                                            ).day()
                                                        ]
                                                    })`}</p>
                                                )}
                                                {/* {item?.demolition_date && (
                                                    <p>{`${moment(
                                                        item.demolition_date
                                                    ).format("YYYY-MM-DD")}(${
                                                        consts.dayText[
                                                            moment(
                                                                item.demolition_date
                                                            ).day()
                                                        ]
                                                    })`}
                                                    </p>
                                                )} */}
                                            </>
                                        ) : (
                                            <p>-</p>
                                        )}
                                    </>
                                }
                            </td>
                            <td>
                                {item.dobae === 1 && (
                                    <div
                                        style={{
                                            backgroundColor: "#41b06e",
                                            color: "white",

                                            margin: "5px 0",
                                        }}
                                    >
                                        도배
                                    </div>
                                )}
                                {item.zangpan === 1 && (
                                    <div
                                        style={{
                                            backgroundColor: "#fb8500",
                                            color: "white",

                                            margin: "5px 0",
                                        }}
                                    >
                                        장판
                                    </div>
                                )}
                                {item.maru === 1 && (
                                    <div
                                        style={{
                                            backgroundColor: "#caf0f8",
                                            color: "black",

                                            margin: "5px 0",
                                        }}
                                    >
                                        마루
                                    </div>
                                )}
                                {/* {item.demolition_price !== 0 && (
                                    <div
                                        style={{
                                            backgroundColor: "#ced4da",
                                            color: "black",

                                            margin: "5px 0",
                                        }}
                                    >
                                        철거
                                    </div>
                                )} */}
                            </td>
                            {!isDelete && (
                                <td>
                                    <div
                                        className="TableBtns "
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constRegst1}?index=${item.idx}`
                                                );
                                            }}
                                        >
                                            변경
                                        </button>
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constPreview2}?index=${item.idx}`
                                                );
                                            }}
                                        >
                                            보기
                                        </button>
                                        <button
                                            onClick={() => DeleteClk(item.idx)}
                                        >
                                            삭제
                                        </button>
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function ConstManageTable({
    isDelete = false,
    dataList,
    setDeletePop,
    deletePop,
    setDeleteIdx,
    values,
    setValues,
}) {
    const navigate = useNavigate();
    const DeleteClk = (idx) => {
        setDeletePop(!deletePop);
        setDeleteIdx(idx);
    };

    const handleChange = (e) => {
        if (e.target.checked) {
            setValues([...values, e.target.value]);
        } else {
            setValues(values.filter((value) => value !== e.target.value));
        }
    };

    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        {isDelete && <th>선택</th>}
                        <th>담당자</th>
                        <th>고객명</th>
                        <th>연락처</th>
                        <th>주소</th>
                        <th>총액</th>
                        <th>시공일</th>
                        <th>제품</th>
                        <th>반장</th>
                        <th>인원</th>
                        <th colSpan={2}>현황</th>
                        <th>진행 단계</th>
                        {!isDelete && <th>관리</th>}
                    </tr>
                </thead>
                <tbody>
                    {dataList?.map((item, index) => (
                        <tr key={index}>
                            {isDelete && (
                                <td>
                                    <input
                                        checked={values
                                            .map((c) => parseInt(c))
                                            .includes(item.idx)}
                                        value={item.idx}
                                        onChange={handleChange}
                                        id={item.idx}
                                        className="OneChkBox"
                                        type="checkbox"
                                    />
                                    <label htmlFor={item.idx} />
                                </td>
                            )}
                            <td>{item.admin_name || "-"}</td>
                            <td>{item.cutomer_name || "-"}</td>
                            <td>{item.mb_phone}</td>
                            <td>{`${
                                item.address !== null &&
                                item.address_detail !== null
                                    ? item.address + " " + item.address_detail
                                    : item.address !== null &&
                                      item.address_detail === null
                                    ? item.address
                                    : item.address === null &&
                                      item.address_detail !== null
                                    ? item.address_detail
                                    : "-"
                            }`}</td>
                            <td>{`${(
                                (item.maru_price || 0) +
                                (item.zangpan_price || 0) +
                                (item.dobae_price || 0) +
                                (item.demolition_price || 0)
                            ).toLocaleString()}원`}</td>

                            <td>
                                {
                                    <>
                                        {item?.dobae_date ||
                                        item?.zangpan_date ||
                                        item?.maru_date ? (
                                            // ||
                                            // item?.demolition_date
                                            <>
                                                {item?.dobae_date &&
                                                    item?.dobae === 1 && (
                                                        <p>{`${moment(
                                                            item.dobae_date
                                                        ).format(
                                                            "YYYY-MM-DD"
                                                        )}(${
                                                            consts.dayText[
                                                                moment(
                                                                    item.dobae_date
                                                                ).day()
                                                            ]
                                                        })`}</p>
                                                    )}
                                                {item?.zangpan_date &&
                                                    item?.zangpan === 1 && (
                                                        <p>{`${moment(
                                                            item.zangpan_date
                                                        ).format(
                                                            "YYYY-MM-DD"
                                                        )}(${
                                                            consts.dayText[
                                                                moment(
                                                                    item.zangpan_date
                                                                ).day()
                                                            ]
                                                        })`}</p>
                                                    )}
                                                {item?.maru_date &&
                                                    item?.maru === 1 && (
                                                        <p>{`${moment(
                                                            item.maru_date
                                                        ).format(
                                                            "YYYY-MM-DD"
                                                        )}(${
                                                            consts.dayText[
                                                                moment(
                                                                    item.maru_date
                                                                ).day()
                                                            ]
                                                        })`}</p>
                                                    )}
                                                {/* {item?.demolition_date &&
                                                    item.demolition_price !==
                                                        0 && (
                                                        <p>{`${moment(
                                                            item.demolition_date
                                                        ).format(
                                                            "YYYY-MM-DD"
                                                        )}(${
                                                            consts.dayText[
                                                                moment(
                                                                    item.demolition_date
                                                                ).day()
                                                            ]
                                                        })`}</p>
                                                    )} */}
                                            </>
                                        ) : (
                                            <p>-</p>
                                        )}
                                    </>
                                }
                            </td>

                            <td>
                                {item.dobae === 1 && (
                                    <div
                                        style={{
                                            backgroundColor: "#41b06e",
                                            color: "white",

                                            margin: "5px 0",
                                        }}
                                    >
                                        도배
                                    </div>
                                )}
                                {item.zangpan === 1 && (
                                    <div
                                        style={{
                                            backgroundColor: "#fb8500",
                                            color: "white",

                                            margin: "5px 0",
                                        }}
                                    >
                                        장판
                                    </div>
                                )}
                                {item.maru === 1 && (
                                    <div
                                        style={{
                                            backgroundColor: "#caf0f8",
                                            color: "black",

                                            margin: "5px 0",
                                        }}
                                    >
                                        마루
                                    </div>
                                )}
                                {/* {item.demolition_price !== 0 && (
                                    <div
                                        style={{
                                            backgroundColor: "#ced4da",
                                            color: "black",

                                            margin: "5px 0",
                                        }}
                                    >
                                        철거
                                    </div>
                                )} */}
                            </td>
                            <td>
                                {item.worker_list?.map((c, i) => {
                                    return (
                                        <p key={i}>{c.banzang_name || "-"}</p>
                                    );
                                })}
                            </td>
                            <td>
                                {" "}
                                {item.worker_list?.map((c, i) => {
                                    return <p key={i}>{c.people_cnt || "-"}</p>;
                                })}
                            </td>
                            <td>
                                <div style={{ textAlign: "right" }}>
                                    <p>예약안내:</p>
                                    <p>계약금:</p>
                                    <p>작업지시서:</p>
                                </div>
                            </td>
                            <td>
                                <div style={{ textAlign: "left" }}>
                                    <p>
                                        {item.customer_contract_chk === 0
                                            ? "X"
                                            : "O"}
                                    </p>
                                    <p>
                                        {item.customer_payment_chk === 0
                                            ? "X"
                                            : "O"}
                                    </p>
                                    <p>{item.workerOrder === 0 ? "X" : "O"}</p>
                                </div>
                            </td>
                            <td>{`${
                                item.work_end_date !== null ? "종료" : "진행"
                            }`}</td>
                            {!isDelete && (
                                <td>
                                    <div
                                        className="TableBtns "
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constRegst2}?index=${item.idx}`
                                                );
                                            }}
                                        >
                                            변경
                                        </button>
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constPreview3}?index=${item.idx}`
                                                );
                                            }}
                                        >
                                            보기
                                        </button>
                                        <button
                                            onClick={() => DeleteClk(item.idx)}
                                        >
                                            삭제
                                        </button>
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TableMemo({
    memotype,
    Info,
    deletePop,
    setDeletePop,
    setDeleteIdx,
    isDelete,
    setIsDelete,
    setFlag = () => {},
    FlagValue = 0,
}) {
    let backgroundColor;
    let color;

    // console.log("Info", Info);
    const setDelete = (idx) => {
        setDeletePop(!deletePop);
        setDeleteIdx(idx);
        setIsDelete(true);
        setFlag(FlagValue);
    };

    switch (memotype) {
        case "도배":
            backgroundColor = "#41b06e";
            color = "white";
            break;
        case "장판":
            backgroundColor = "#fb8500";
            color = "white";
            break;
        case "마루":
            backgroundColor = "#caf0f8";
            color = "black";
            break;
        case "특이사항":
            backgroundColor = "#ced4da";
            color = "black";
            break;
        default:
            backgroundColor = "#ededed";
            color = "black";
            break;
    }
    return (
        <>
            <h1 style={{ margin: "20px 0px", fontSize: "16px" }}>
                {memotype} 메모
            </h1>
            <div className="table_memo">
                <table>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    backgroundColor: backgroundColor,
                                    color: color,
                                    width: "15%",
                                }}
                            >
                                작성일시
                            </th>
                            <th
                                style={{
                                    backgroundColor: backgroundColor,
                                    color: color,
                                    width: "15%",
                                }}
                            >
                                작성자
                            </th>
                            <th
                                style={{
                                    backgroundColor: backgroundColor,
                                    color: color,
                                    width: "60%",
                                }}
                            >
                                내용
                            </th>
                            <th
                                style={{
                                    backgroundColor: backgroundColor,
                                    color: color,
                                    width: "10%",
                                }}
                            >
                                삭제
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Info?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {moment(item.create_dt).format(
                                            "YYYY-MM-DD"
                                        )}
                                    </td>
                                    <td>{item.memo_mbname}</td>
                                    <td
                                        style={{
                                            display: "flex",
                                            justifyContent: "start",
                                            paddingLeft: "15px",
                                        }}
                                    >
                                        <textarea
                                            style={{
                                                backgroundColor: "#f5f5f5",
                                                width: "100%",
                                                border: "none",
                                                resize: "none",
                                            }}
                                            readOnly
                                            value={item.memo_content}
                                        />
                                    </td>
                                    <td>
                                        <img
                                            style={{
                                                width: "15px",
                                                cursor: "pointer",
                                            }}
                                            src={images.trash_red}
                                            onClick={() => setDelete(item.idx)}
                                        ></img>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export function TableClassfiy({ list }) {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>모델번호</th>
                        <th>1차분류</th>
                        <th>2차분류</th>
                        <th>수량</th>
                        <th>메모</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((x, index) => (
                        <tr key={index}>
                            <td>{x.material_number}</td>
                            <td>{x.material_class01}</td>
                            <td>{x.material_class02}</td>
                            <td>{x.count}롤</td>
                            <td>{x.memo || "-"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
export function TableClassfiy2({ list }) {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>1차분류</th>
                        <th>2차분류</th>
                        <th>수량</th>
                        <th>메모</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((x, index) => (
                        <tr key={index}>
                            <td>{x.material_class01}</td>
                            <td>{x.material_class02}</td>
                            <td>{x.count || 0}개</td>
                            <td>{x.memo || "-"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TableClassfiy3({ list }) {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>모델번호</th>
                        <th>1차분류</th>
                        <th>2차분류</th>
                        <th>길이</th>
                        <th>메모</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((x, index) => (
                        <tr key={index}>
                            <td>{x.material_number}</td>
                            <td>{x.material_class01}</td>
                            <td>{x.material_class02}</td>
                            <td>{x.length}</td>
                            <td>{x.memo || "-"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TableClassfiy4({ list }) {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>모델번호</th>
                        <th>1차분류</th>
                        <th>2차분류</th>
                        <th>메모</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((x, index) => (
                        <tr key={index}>
                            <td>{x.material_number}</td>
                            <td>{x.material_class01}</td>
                            <td>{x.material_class02}</td>
                            <td>{x.memo || "-"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TableClassfiy5({ list }) {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>모델번호</th>
                        <th>1차분류</th>
                        <th>2차분류</th>
                        <th>필요수량</th>
                        <th>재고</th>
                        <th>주문수량</th>
                        <th>단가</th>
                        <th>합계</th>
                        <th>메모</th>
                    </tr>
                </thead>
                <tbody>
                    {list
                        ?.filter((x, i) => x.material_type === 1)
                        .map((x, i) => (
                            <tr key={i}>
                                <td>{x.material_number || "-"}</td>
                                <td>{x.material_class01 || "-"} </td>
                                <td>{x.material_class02 || "-"}</td>
                                <td>{x.count || 0}롤</td>
                                <td>{x.inventory_count || 0}롤</td>
                                <td>{x.order_count || 0}롤</td>
                                <td>
                                    {Number(x.order_price).toLocaleString() ||
                                        "0"}
                                </td>
                                <td>
                                    {" "}
                                    {Number(
                                        x.order_count * x.order_price
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>{x.memo || "-"}</td>
                            </tr>
                        ))}
                </tbody>
                <tbody>
                    {list?.filter((x, i) => x.material_type === 1).length !==
                        0 && (
                        <tr>
                            <td style={{ background: "#ccc" }} colSpan={2}></td>
                            <td style={{ background: "#ccc" }}>총합</td>
                            <td>
                                {list
                                    ?.filter((x, i) => x.material_type === 1)
                                    .reduce((acc, c) => {
                                        return acc + parseInt(c.count);
                                    }, 0)}
                                롤
                            </td>
                            <td style={{ background: "#ccc" }} colSpan={3}></td>
                            <td>
                                {list
                                    ?.filter((x, i) => x.material_type === 1)
                                    .reduce((acc, c) => {
                                        return (
                                            acc +
                                            parseInt(
                                                c.order_count * c.order_price
                                            )
                                        );
                                    }, 0)
                                    .toLocaleString()}
                                원
                            </td>
                            <td style={{ background: "#ccc" }} colSpan={2}></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export function TableClassfiy6({ list }) {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>1차분류</th>
                        <th>2차분류</th>
                        <th>수량</th>
                        <th>단가</th>
                        <th>합계</th>
                        <th>메모</th>
                    </tr>
                </thead>
                <tbody>
                    {list
                        ?.filter((x, i) => x.material_type === 5)
                        .map((x, i) => (
                            <tr key={i}>
                                <td>{x.material_class01 || "-"}</td>
                                <td>{x.material_class02 || "-"}</td>
                                <td>{x.count || 0}개</td>
                                <td>
                                    {Number(x.order_price).toLocaleString() ||
                                        "0"}
                                    원
                                </td>
                                <td>
                                    {" "}
                                    {Number(
                                        x.count * x.order_price
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>{x.memo || ""}</td>
                            </tr>
                        ))}
                </tbody>
                <tbody>
                    {list?.filter((x, i) => x.material_type === 5).length !==
                        0 && (
                        <tr>
                            <td style={{ background: "#ccc" }}></td>
                            <td style={{ background: "#ccc" }}>총합</td>
                            <td>
                                {list
                                    ?.filter((x, i) => x.material_type === 5)
                                    ?.reduce((acc, c) => {
                                        return acc + parseInt(c.count);
                                    }, 0)}
                                개
                            </td>
                            <td style={{ background: "#ccc" }}></td>
                            <td>
                                {list
                                    ?.filter((x, i) => x.material_type === 5)
                                    ?.reduce((acc, c) => {
                                        return (
                                            acc +
                                            parseInt(c.count * c.order_price)
                                        );
                                    }, 0)
                                    .toLocaleString()}
                                원
                            </td>
                            <td style={{ background: "#ccc" }} colSpan={2}></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export function TableOrderManagement({
    list,
    agencyList,
    isDelete = false,
    setDeletePop,
    deletePop,
    setDeleteIdx,
    values,
    setValues,
}) {
    const navigate = useNavigate();
    const DeleteClk = (idx) => {
        setDeletePop(!deletePop);

        setDeleteIdx(idx);
    };
    const handleChange = (e) => {
        if (e.target.checked) {
            setValues([...values, e.target.value]);
        } else {
            setValues(values.filter((value) => value !== e.target.value));
        }
    };

    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        {isDelete && <th>선택</th>}
                        <th>배송일</th>
                        <th>대리점</th>
                        <th>배송지</th>
                        <th>수령자</th>
                        <th>분류</th>
                        <th>금액</th>
                        <th>시공</th>
                        <th>퀵배송</th>
                        <th>A/S</th>
                        <th>내사</th>
                        {!isDelete && <th>관리</th>}
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, index) => (
                        <tr key={index}>
                            {isDelete && (
                                <td>
                                    <input
                                        checked={values
                                            .map((c) => parseInt(c))
                                            .includes(item.idx)}
                                        value={item.idx}
                                        onChange={handleChange}
                                        id={item.idx}
                                        className="OneChkBox"
                                        type="checkbox"
                                    />
                                    <label htmlFor={item.idx} />
                                </td>
                            )}
                            <td>
                                {item.delivery_date
                                    ? `${moment(item.delivery_date).format(
                                          "YYYY-MM-DD"
                                      )}(${
                                          consts.dayText[
                                              moment(item.delivery_date).day()
                                          ]
                                      })`
                                    : "-"}
                            </td>
                            <td>
                                {agencyList.find(
                                    (c) =>
                                        c.value === item.agency_idx &&
                                        c.value !== 0
                                )?.text || "-"}
                            </td>

                            <td>{`${
                                item.delivery_address !== null &&
                                item.delivery_address_detail !== null
                                    ? item.delivery_address +
                                      " " +
                                      item.delivery_address_detail
                                    : item.delivery_address !== null &&
                                      item.delivery_address_detail === null
                                    ? item.delivery_address
                                    : item.delivery_address === null &&
                                      item.delivery_address_detail !== null
                                    ? item.delivery_address_detail
                                    : "-"
                            }`}</td>

                            <td>{item.recipient_name || "-"}</td>
                            <td>
                                {item.dobae === 0 &&
                                item.zangpan === 0 &&
                                item.maru === 0 ? (
                                    "-"
                                ) : (
                                    <div
                                        className="FlexCenter"
                                        style={{ flexDirection: "column" }}
                                    >
                                        {item.dobae === 1 ? <p>도배</p> : null}
                                        {item.zangpan === 1 ? (
                                            <p>장판</p>
                                        ) : null}
                                        {item.maru === 1 ? <p>마루</p> : null}
                                    </div>
                                )}
                            </td>
                            <td>{`${
                                item.all_order_price
                                    ? item.all_order_price.toLocaleString()
                                    : 0
                            }원`}</td>

                            <td>
                                {item.use_type === 1 ? (
                                    <img
                                        style={{ width: "30px" }}
                                        src={images.checkmark}
                                    />
                                ) : null}
                            </td>
                            <td>
                                {item.use_type === 2 ? (
                                    <img
                                        style={{ width: "30px" }}
                                        src={images.checkmark}
                                    />
                                ) : null}
                            </td>
                            <td>
                                {item.use_type === 3 ? (
                                    <img
                                        style={{ width: "30px" }}
                                        src={images.checkmark}
                                    />
                                ) : null}
                            </td>
                            <td>
                                {item.use_type === 4 ? (
                                    <img
                                        style={{ width: "30px" }}
                                        src={images.checkmark}
                                    />
                                ) : null}
                            </td>
                            {!isDelete && (
                                <td>
                                    <div
                                        className="TableBtns "
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constRegst3}?index=${item.idx}&type=list`
                                                );
                                            }}
                                        >
                                            변경
                                        </button>
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constPreview4}?index=${item.idx}&type=list`
                                                );
                                            }}
                                        >
                                            보기
                                        </button>
                                        <button
                                            onClick={() => DeleteClk(item.idx)}
                                        >
                                            삭제
                                        </button>
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TableJobFinished({ isDelete = false, list }) {
    const navigate = useNavigate();
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th rowSpan="2">상태</th>
                        <th rowSpan="2">지역</th>
                        <th rowSpan="2">고객</th>
                        <th rowSpan="2">시공</th>
                        <th rowSpan="2">시공일</th>
                        <th rowSpan="2">하자</th>
                        <th rowSpan="2">반장</th>
                        <th colSpan="2">리포트</th>
                        <th colSpan="2">잔금</th>
                        <th rowSpan="2">금액</th>
                        <th rowSpan="2">관리</th>
                    </tr>
                    <tr>
                        <th
                            style={{
                                borderTop: "none",
                                borderLeft: "1px solid #ddd",
                            }}
                        >
                            문자
                        </th>
                        <th style={{ borderTop: "none" }}>수신</th>
                        <th style={{ borderTop: "none" }}>문자</th>
                        <th style={{ borderTop: "none" }}>입금</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, index) => (
                        <tr key={index}>
                            <td>{`${
                                item.work_end_date !== null ? "완료" : "진행"
                            }`}</td>
                            <td>{`${
                                item.address !== null &&
                                item.address_detail !== null
                                    ? item.address + " " + item.address_detail
                                    : item.address !== null &&
                                      item.address_detail === null
                                    ? item.address
                                    : item.address === null &&
                                      item.address_detail !== null
                                    ? item.address_detail
                                    : "-"
                            }`}</td>
                            <td>{item.cutomer_name || "-"}</td>
                            <td>
                                {item.dobae === 1 && (
                                    <div
                                        style={{
                                            backgroundColor: "#41b06e",
                                            color: "white",

                                            margin: "5px 0",
                                        }}
                                    >
                                        도배
                                    </div>
                                )}
                                {item.zangpan === 1 && (
                                    <div
                                        style={{
                                            backgroundColor: "#fb8500",
                                            color: "white",

                                            margin: "5px 0",
                                        }}
                                    >
                                        장판
                                    </div>
                                )}
                                {/* {item.maru === 1 && (
                                    <div
                                        style={{
                                            backgroundColor: "#caf0f8",
                                            color: "black",

                                            margin: "5px 0",
                                        }}
                                    >
                                        마루
                                    </div>
                                )} */}
                            </td>
                            <td>
                                {
                                    <>
                                        {item?.dobae_date ||
                                        item?.zangpan_date ||
                                        item?.maru_date ? (
                                            <>
                                                {item?.dobae_date &&
                                                    item?.dobae === 1 && (
                                                        <p>{`${moment(
                                                            item.dobae_date
                                                        ).format(
                                                            "YYYY-MM-DD"
                                                        )}(${
                                                            consts.dayText[
                                                                moment(
                                                                    item.dobae_date
                                                                ).day()
                                                            ]
                                                        })`}</p>
                                                    )}
                                                {item?.zangpan_date &&
                                                    item?.zangpan === 1 && (
                                                        <p>{`${moment(
                                                            item.zangpan_date
                                                        ).format(
                                                            "YYYY-MM-DD"
                                                        )}(${
                                                            consts.dayText[
                                                                moment(
                                                                    item.zangpan_date
                                                                ).day()
                                                            ]
                                                        })`}</p>
                                                    )}
                                                {/* {item?.maru_date &&
                                                    item?.maru === 1 && (
                                                        <p>{`${moment(
                                                            item.maru_date
                                                        ).format(
                                                            "YYYY-MM-DD"
                                                        )}(${
                                                            consts.dayText[
                                                                moment(
                                                                    item.maru_date
                                                                ).day()
                                                            ]
                                                        })`}</p>
                                                    )} */}
                                            </>
                                        ) : (
                                            <p>-</p>
                                        )}
                                    </>
                                }
                            </td>
                            <td>
                                {item.dobae_as_chk === 1 ||
                                item.zangpan_as_chk === 1 ? (
                                    <>
                                        {item.dobae_as_chk === 1 ? (
                                            <p>도배 하자</p>
                                        ) : (
                                            "-"
                                        )}
                                        {item.zangpan_as_chk === 1 ? (
                                            <p>장판 하자</p>
                                        ) : (
                                            "-"
                                        )}
                                    </>
                                ) : (
                                    "-"
                                )}
                            </td>
                            <td>
                                {item.dobae_banzang || item.zangpan_banzang ? (
                                    <>
                                        {item.dobae_banzang ? (
                                            <p>{item.dobae_banzang}</p>
                                        ) : null}
                                        {item.zangpan_banzang ? (
                                            <p>{item.zangpan_banzang}</p>
                                        ) : null}
                                    </>
                                ) : (
                                    "-"
                                )}
                            </td>
                            <td>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {(item.dobae_report_balsin_chk &&
                                        item.dobae === 1) ||
                                    (item.zangpan_report_balsin_chk &&
                                        item.zangpan === 1) ? (
                                        <>
                                            {item.dobae === 1 ? (
                                                item.dobae_report_balsin_chk ? (
                                                    <img
                                                        style={{
                                                            width: "30px",
                                                        }}
                                                        src={images.checkmark}
                                                    />
                                                ) : (
                                                    "-"
                                                )
                                            ) : null}
                                            {item.zangpan === 1 ? (
                                                item.zangpan_report_balsin_chk ? (
                                                    <img
                                                        style={{
                                                            width: "30px",
                                                        }}
                                                        src={images.checkmark}
                                                    />
                                                ) : (
                                                    "-"
                                                )
                                            ) : null}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            </td>
                            <td>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {item.dobae_report_susin_chk ||
                                    item.zangpan_report_susin_chk ? (
                                        <>
                                            {item.dobae === 1 ? (
                                                item.dobae_report_susin_chk ? (
                                                    <img
                                                        style={{
                                                            width: "30px",
                                                        }}
                                                        src={images.checkmark}
                                                    />
                                                ) : (
                                                    "-"
                                                )
                                            ) : null}
                                            {item.zangpan === 1 ? (
                                                item.zangpan_report_susin_chk ? (
                                                    <img
                                                        style={{
                                                            width: "30px",
                                                        }}
                                                        src={images.checkmark}
                                                    />
                                                ) : (
                                                    "-"
                                                )
                                            ) : null}
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            </td>
                            <td>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {item.balance_msg_status === 1 ? (
                                        <img
                                            style={{ width: "30px" }}
                                            src={images.checkmark}
                                        />
                                    ) : (
                                        "-"
                                    )}
                                    {/* {item.reportChk.map((i) => {
                                        return i ? (
                                            <img
                                                style={{ width: "20px" }}
                                                src={images.checkmark}
                                            />
                                        ) : (
                                            <div
                                                style={{ width: "20px" }}
                                            ></div>
                                        );
                                    })} */}
                                </div>
                            </td>
                            <td>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {item.balance_paydate !== null ? (
                                        <img
                                            style={{ width: "30px" }}
                                            src={images.checkmark}
                                        />
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            </td>
                            <td>
                                {/* {item.price.map((i) => {
                                    return <p key={i + i}>{i}원</p>;
                                })} */}
                            </td>
                            {isDelete ? (
                                <td>
                                    <div
                                        className="TableBtns FlexCenter "
                                        style={{ display: "flex" }}
                                    >
                                        <button>복원</button>

                                        <button>삭제</button>
                                    </div>
                                </td>
                            ) : (
                                <td>
                                    <div
                                        className="TableBtns "
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constRegst4}?index=${item.idx}`
                                                );
                                            }}
                                        >
                                            변경
                                        </button>
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constPreview5}?index=${item.idx}`
                                                );
                                            }}
                                        >
                                            보기
                                        </button>
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TableExpenseDetail({
    Expense = true,
    list,
    duty,
    readOnly = false,
}) {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>작업자</th>
                        <th>인건비</th>
                        <th>식대</th>
                        <th>합계</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.worker_name}</td>
                            <td>
                                <div
                                    className="tableInputWrap FlexCenter"
                                    style={{ gap: "10px" }}
                                >
                                    <input
                                        type="text"
                                        name="title"
                                        readOnly={readOnly}
                                        value={
                                            Number(
                                                item.personnel_expenses || 0
                                            ).toLocaleString() || "0"
                                        }
                                        index={index}
                                        // onChange={(e) => {
                                        //     let val = e.target.value;
                                        //     val =
                                        //         parseInt(
                                        //             e.target.value.replace(
                                        //                 /[^0-9]/g,
                                        //                 ""
                                        //             )
                                        //         ) || 0;
                                        //     updateDataList(
                                        //         index,
                                        //         "personnel_expenses",
                                        //         val
                                        //     );
                                        // }}
                                    />
                                    <p>원</p>
                                </div>
                            </td>
                            <td>
                                <div
                                    className="tableInputWrap FlexCenter"
                                    style={{ gap: "10px" }}
                                >
                                    <input
                                        type="text"
                                        name="title"
                                        readOnly={readOnly}
                                        value={
                                            Number(
                                                item.meal_price || 0
                                            ).toLocaleString() || "0"
                                        }
                                        index={index}
                                        // onChange={(e) => {
                                        //     let val = e.target.value;
                                        //     val =
                                        //         parseInt(
                                        //             e.target.value.replace(
                                        //                 /[^0-9]/g,
                                        //                 ""
                                        //             )
                                        //         ) || 0;
                                        //     updateDataList(
                                        //         index,
                                        //         "meal_price",
                                        //         val
                                        //     );
                                        // }}
                                    />
                                    <p>원</p>
                                </div>
                            </td>
                            <td>
                                {Number(
                                    item.personnel_expenses + item.meal_price
                                ).toLocaleString() || "0"}
                                원
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>소계</td>
                        <td>
                            {" "}
                            {list
                                ?.reduce((acc, cur) => {
                                    return acc + cur.personnel_expenses;
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                        <td>
                            {" "}
                            {list
                                ?.reduce((acc, cur) => {
                                    return acc + cur.meal_price;
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                        <td>
                            {" "}
                            {list
                                ?.reduce((acc, cur) => {
                                    return (
                                        acc +
                                        cur.personnel_expenses +
                                        cur.meal_price
                                    );
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2"></td>
                        <td>세금</td>
                        <td>
                            {" "}
                            {list
                                ?.reduce((acc, cur) => {
                                    return parseInt(
                                        acc +
                                            ((cur.personnel_expenses +
                                                cur.meal_price) *
                                                duty) /
                                                100
                                    );
                                }, 0)
                                .toLocaleString()}
                            원{" "}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2"></td>
                        <td>총합계</td>
                        <td>
                            {" "}
                            {list
                                ?.reduce((acc, cur) => {
                                    return parseInt(
                                        acc +
                                            (cur.personnel_expenses +
                                                cur.meal_price) +
                                            ((cur.personnel_expenses +
                                                cur.meal_price) *
                                                duty) /
                                                100
                                    );
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export function TableExpenseDetail2() {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>작업자</th>
                        <th>인건비</th>
                        <th>식대</th>
                        <th>합계</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>김철수</td>
                        <td>
                            <div className="flexWrap">
                                <Input
                                    className="input_text"
                                    type="text"
                                    name="title"
                                    // value={x.title}
                                    // onChange={zoneSetValue}
                                    readOnly
                                    labelStyle={{ display: "none" }}
                                />
                                <p>원</p>
                            </div>
                        </td>
                        <td>
                            <div className="flexWrap">
                                <Input
                                    className="input_text"
                                    type="text"
                                    name="title"
                                    // value={x.title}
                                    // onChange={zoneSetValue}
                                    readOnly
                                    labelStyle={{ display: "none" }}
                                />
                                <p>원</p>
                            </div>
                        </td>
                        <td>
                            <div className="flexWrap">
                                <Input
                                    className="input_text"
                                    type="text"
                                    name="title"
                                    // value={x.title}
                                    // onChange={zoneSetValue}
                                    readOnly
                                    labelStyle={{ display: "none" }}
                                />
                                <p>원</p>
                            </div>
                        </td>
                    </tr>
                    {test.map((item, index) => (
                        <tr key={index}>
                            <td>
                                {Dropdowns.length > 1 ? (
                                    <select
                                        style={{
                                            width: "60%",
                                            height: "40px",
                                            padding: "10px",
                                        }}
                                    >
                                        {Dropdowns.map((x, i) => (
                                            <option key={i} value={x.value}>
                                                {x.text}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    Dropdowns[0]
                                )}
                            </td>
                            <td>
                                <div className="flexWrap">
                                    <Input
                                        className="input_text"
                                        type="text"
                                        name="title"
                                        // value={x.title}
                                        // onChange={zoneSetValue}
                                        index={index + 1}
                                        readOnly
                                        labelStyle={{ display: "none" }}
                                    />
                                    <p>원</p>
                                </div>
                            </td>
                            <td>
                                <div className="flexWrap">
                                    <Input
                                        className="input_text"
                                        type="text"
                                        name="title"
                                        // value={x.title}
                                        // onChange={zoneSetValue}
                                        index={index + 1}
                                        readOnly
                                        labelStyle={{ display: "none" }}
                                    />
                                    <p>원</p>
                                </div>
                            </td>
                            <td>
                                <div className="flexWrap">
                                    <Input
                                        className="input_text"
                                        type="text"
                                        name="title"
                                        // value={x.title}
                                        // onChange={zoneSetValue}
                                        index={index + 1}
                                        readOnly
                                        labelStyle={{ display: "none" }}
                                    />
                                    <p>원</p>
                                </div>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>소계</td>
                        <td>100,000원</td>
                        <td>100,000원</td>
                        <td>100,000원</td>
                    </tr>
                    <tr>
                        <td colSpan="2"></td>
                        <td>세금</td>
                        <td>0원</td>
                    </tr>
                    <tr>
                        <td colSpan="2"></td>
                        <td>합계</td>
                        <td>0원</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
export function TableEndWallPaper({
    isASmanage = false,
    list,
    setList,
    readOnly = false,
}) {
    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th rowSpan="2">모델번호</th>
                        <th rowSpan="2">1차분류</th>
                        <th rowSpan="2">2차분류</th>
                        <th rowSpan="2">수량</th>
                        <th rowSpan="2">단가</th>
                        <th colSpan="3">
                            {isASmanage === true ? "리포트" : "투입"}
                        </th>
                        <th rowSpan="2">사용</th>
                        <th colSpan="2">잔여</th>
                    </tr>
                    <tr>
                        <th
                            style={{
                                borderTop: "none",
                                borderLeft: "1px solid #ddd",
                            }}
                        >
                            주문
                        </th>
                        <th style={{ borderTop: "none" }}>재고</th>
                        <th style={{ borderTop: "none" }}>합계</th>
                        <th style={{ borderTop: "none" }}>수량</th>
                        <th style={{ borderTop: "none" }}>금액</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.material_number || "-"}</td>
                            <td>{item.material_class01 || "-"}</td>
                            <td>{item.material_class02 || "-"}</td>
                            <td>{item.count || 0}개</td>
                            <td>
                                {(item.order_price || 0).toLocaleString() || 0}
                                원
                            </td>
                            <td>
                                <div
                                    className="tableInputWrap FlexCenter"
                                    style={{ gap: "10px" }}
                                >
                                    <input
                                        type="text"
                                        name="title"
                                        value={
                                            Number(
                                                item.order_count || 0
                                            ).toLocaleString() || "0"
                                        }
                                        index={index}
                                        readOnly={readOnly}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            val =
                                                parseInt(
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                    )
                                                ) || 0;
                                            updateDataList(
                                                index,
                                                "order_count",
                                                val
                                            );
                                        }}
                                    />
                                    <p>개</p>
                                </div>
                            </td>
                            <td>
                                <div
                                    className="tableInputWrap FlexCenter"
                                    style={{ gap: "10px" }}
                                >
                                    <input
                                        type="text"
                                        name="title"
                                        value={
                                            Number(
                                                item.inventory_count || 0
                                            ).toLocaleString() || "0"
                                        }
                                        readOnly={readOnly}
                                        index={index}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            val =
                                                parseInt(
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                    )
                                                ) || 0;
                                            updateDataList(
                                                index,
                                                "inventory_count",
                                                val
                                            );
                                        }}
                                    />
                                    <p>개</p>
                                </div>
                            </td>
                            <td>
                                {(item.order_count || 0) +
                                    (item.inventory_count || 0)}
                                개
                            </td>

                            <td>
                                {" "}
                                {(item.order_count || 0) +
                                    (item.inventory_count || 0) -
                                    (item.leftover_count || 0)}
                                개
                            </td>
                            <td>
                                <div
                                    className="tableInputWrap FlexCenter"
                                    style={{ gap: "10px" }}
                                >
                                    <input
                                        type="text"
                                        name="title"
                                        value={
                                            Number(
                                                item.leftover_count || 0
                                            ).toLocaleString() || "0"
                                        }
                                        readOnly={readOnly}
                                        index={index}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            val =
                                                parseInt(
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                    )
                                                ) || 0;
                                            updateDataList(
                                                index,
                                                "leftover_count",
                                                val
                                            );
                                        }}
                                    />
                                    <p>개</p>
                                </div>
                            </td>
                            <td>
                                {(
                                    ((item.order_count || 0) +
                                        (item.inventory_count || 0) -
                                        (item.leftover_count || 0)) *
                                    (item.order_price || 0)
                                ).toLocaleString()}
                                원
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan="5"></td>
                        <td>
                            {list?.reduce((acc, cur) => {
                                return acc + (cur.order_count || 0);
                            }, 0)}
                            개
                        </td>
                        <td>
                            {list?.reduce((acc, cur) => {
                                return acc + (cur.inventory_count || 0);
                            }, 0)}
                            개{" "}
                        </td>
                        <td>
                            {list?.reduce((acc, cur) => {
                                return (
                                    acc +
                                    ((cur.order_count || 0) +
                                        (cur.inventory_count || 0))
                                );
                            }, 0)}
                            개
                        </td>
                        <td>
                            {list?.reduce((acc, cur) => {
                                return (
                                    acc +
                                    ((cur.order_count || 0) -
                                        (cur.leftover_count || 0))
                                );
                            }, 0)}
                            개
                        </td>
                        <td>
                            {list?.reduce((acc, cur) => {
                                return acc + (cur.leftover_count || 0);
                            }, 0)}
                            개
                        </td>
                        <td>
                            {list
                                ?.reduce((acc, cur) => {
                                    return (
                                        acc +
                                        ((cur.order_count || 0) +
                                            (cur.inventory_count || 0) -
                                            (cur.leftover_count || 0)) *
                                            (cur.order_price || 0)
                                    );
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export function TableEndSubsidiary({ list, setList, readOnly = false }) {
    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th rowSpan="2">1차분류</th>
                        <th rowSpan="2">2차분류</th>
                        <th rowSpan="2">단가</th>
                        <th rowSpan="2">주문수량</th>
                        <th colSpan="2">시공</th>
                    </tr>
                    <tr>
                        <th
                            style={{
                                borderTop: "none",
                                borderLeft: "1px solid #ddd",
                            }}
                        >
                            사용
                        </th>
                        <th style={{ borderTop: "none" }}>잔여</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.material_class01 || "-"}</td>
                            <td>{item.material_class02 || "-"}</td>
                            <td>
                                {(item.order_price || 0).toLocaleString() || 0}
                                원
                            </td>
                            <td>{item.count || 0}</td>
                            <td>
                                {(item.count || 0) - (item.leftover_count || 0)}
                            </td>
                            <td>
                                <div
                                    className="tableInputWrap FlexCenter"
                                    style={{ gap: "10px" }}
                                >
                                    <input
                                        type="text"
                                        name="title"
                                        value={
                                            Number(
                                                item.leftover_count || 0
                                            ).toLocaleString() || "0"
                                        }
                                        index={index}
                                        readOnly={readOnly}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            val =
                                                parseInt(
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                    )
                                                ) || 0;
                                            updateDataList(
                                                index,
                                                "leftover_count",
                                                val
                                            );
                                        }}
                                    />
                                    <p>개</p>
                                </div>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan="3"></td>
                        <td>합계</td>
                        <td>
                            {list
                                ?.reduce((acc, cur) => {
                                    return (
                                        acc +
                                        ((cur.count || 0) -
                                            (cur.leftover_count || 0)) *
                                            (cur.order_price || 0)
                                    );
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export function TableEndOtherCost({ list, readOnly = false }) {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>내역</th>
                        <th>금액</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div className="tableInputWrap">
                                        <input
                                            type="text"
                                            name="title"
                                            value={item.content || ""}
                                            index={index}
                                            readOnly={readOnly}
                                            // onChange={(e) => {
                                            //     updateDataList(
                                            //         index,
                                            //         "content",
                                            //         e.target.value
                                            //     );
                                            // }}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div
                                        className="tableInputWrap FlexCenter"
                                        style={{ gap: "10px" }}
                                    >
                                        <input
                                            type="text"
                                            name="title"
                                            readOnly={readOnly}
                                            value={
                                                Number(
                                                    item.price || 0
                                                ).toLocaleString() || "0"
                                            }
                                            index={index}
                                            // onChange={(e) => {
                                            //     let val = e.target.value;
                                            //     val =
                                            //         parseInt(
                                            //             e.target.value.replace(
                                            //                 /[^0-9]/g,
                                            //                 ""
                                            //             )
                                            //         ) || 0;
                                            //     updateDataList(index, "price", val);
                                            // }}
                                        />
                                        <p>원</p>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td style={{ textAlign: "right", padding: "10px" }}>
                            합계
                        </td>
                        <td style={{ textAlign: "right", padding: "10px" }}>
                            {list
                                ?.reduce((acc, cur) => {
                                    return acc + (cur.price || 0);
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export function TableEndFlooring({ list, setList }) {
    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>모델번호</th>
                        <th>1차분류</th>
                        <th>2차분류</th>
                        <th>길이</th>
                        <th>사용</th>
                        <th>금액</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.material_number || "-"}</td>
                            <td>{item.material_class01 || "-"}</td>
                            <td>{item.material_class02 || "-"}</td>
                            <td>{item.length || 0}m</td>
                            <td>
                                <div
                                    className="tableInputWrap FlexCenter"
                                    style={{ gap: "10px" }}
                                >
                                    <input
                                        type="text"
                                        name="title"
                                        value={
                                            Number(
                                                item.use_length || 0
                                            ).toLocaleString() || "0"
                                        }
                                        index={index}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            val =
                                                parseInt(
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                    )
                                                ) || 0;
                                            updateDataList(
                                                index,
                                                "use_length",
                                                val
                                            );
                                        }}
                                    />
                                    <p>m</p>
                                </div>
                            </td>
                            <td>
                                <div
                                    className="tableInputWrap FlexCenter"
                                    style={{ gap: "10px" }}
                                >
                                    <input
                                        type="text"
                                        name="title"
                                        value={
                                            Number(
                                                item.order_price || 0
                                            ).toLocaleString() || "0"
                                        }
                                        index={index}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            val =
                                                parseInt(
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                    )
                                                ) || 0;
                                            updateDataList(
                                                index,
                                                "order_price",
                                                val
                                            );
                                        }}
                                    />
                                    <p>원</p>
                                </div>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td
                            colSpan="5"
                            style={{ textAlign: "right", padding: "10px" }}
                        >
                            합계
                        </td>
                        <td style={{ textAlign: "right", padding: "10px" }}>
                            {list
                                ?.reduce((acc, cur) => {
                                    return (
                                        acc +
                                        (cur.price || 0) * (cur.use_length || 0)
                                    );
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export function TableEndBaseboard({ list, setList }) {
    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>모델번호</th>
                        <th>1차분류</th>
                        <th>2차분류</th>
                        <th>금액</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.material_number || "-"}</td>
                            <td>{item.material_class01 || "-"}</td>
                            <td>{item.material_class02 || "-"}</td>
                            <td>
                                <div
                                    className="tableInputWrap FlexCenter"
                                    style={{ gap: "10px" }}
                                >
                                    <input
                                        type="text"
                                        name="title"
                                        value={
                                            Number(
                                                item.order_price || 0
                                            ).toLocaleString() || "0"
                                        }
                                        index={index}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            val =
                                                parseInt(
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                    )
                                                ) || 0;
                                            updateDataList(
                                                index,
                                                "order_price",
                                                val
                                            );
                                        }}
                                    />
                                    <p>원</p>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TableASManagement({
    isDelete = false,
    List,
    setDeletePop,
    deletePop,
    setDeleteIdx,
    setsigongIdx,
    values,
    setValues,
}) {
    const navigate = useNavigate();

    const DeleteClk = (idx, sidx) => {
        setDeletePop(!deletePop);
        setDeleteIdx(idx);
        setsigongIdx(sidx);
    };
    const handleChange = (e) => {
        if (e.target.checked) {
            setValues([...values, e.target.value]);
        } else {
            setValues(values.filter((value) => value !== e.target.value));
        }
    };
    const renderProductItem = (productItem) => {
        switch (productItem) {
            case 1:
                return (
                    <div
                        style={{
                            background: "#41b06e",
                            color: "white",
                            width: "50px",
                            padding: "5px 0px",
                        }}
                    >
                        도배
                    </div>
                );
            case 2:
                return (
                    <div
                        style={{
                            background: "#fb8500",
                            color: "white",
                            width: "50px",
                            padding: "5px 0px",
                        }}
                    >
                        장판
                    </div>
                );
            case 3:
                return (
                    <div
                        style={{
                            background: "#caf0f8",
                            color: "black",
                            width: "50px",
                            padding: "5px 0px",
                        }}
                    >
                        마루
                    </div>
                );
            default:
                return null;
            // <div
            //     style={{
            //         background: "white",
            //         color: "black",
            //         width: "50px",
            //         padding: "5px 0px",
            //     }}
            // >
            //     없음
            // </div>
        }
    };
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        {isDelete && <th>선택</th>}
                        <th>시공</th>
                        <th>상태</th>
                        <th>지역</th>
                        <th>고객</th>

                        <th>전화번호</th>
                        <th>시공일</th>
                        <th>작업자</th>
                        <th>사진</th>

                        <th>A/S일정</th>
                        <th>비용</th>
                        {!isDelete && <th>관리</th>}
                    </tr>
                </thead>
                <tbody>
                    {List?.map((item, index) => (
                        <tr key={index}>
                            {isDelete && (
                                <td>
                                    <input
                                        checked={values
                                            .map((c) => parseInt(c))
                                            .includes(item.idx)}
                                        value={item.idx}
                                        onChange={handleChange}
                                        id={item.idx}
                                        className="OneChkBox"
                                        type="checkbox"
                                    />
                                    <label htmlFor={item.idx} />
                                </td>
                            )}
                            <td>
                                <div
                                    className="FlexCenter"
                                    style={{
                                        flexDirection: "column",
                                        gap: "5px",
                                    }}
                                >
                                    {renderProductItem(
                                        Number(item.dobae_as_chk === 1 && 1)
                                    )}
                                    {renderProductItem(
                                        Number(item.zangpan_as_chk === 1 && 2)
                                    )}
                                </div>
                            </td>
                            <td>
                                {item.status === 1
                                    ? "진행중"
                                    : item.status === 2
                                    ? "완료"
                                    : item.status === 3
                                    ? "종료"
                                    : "-"}
                            </td>
                            <td>{`${
                                item.address != null &&
                                item.address_detail !== null
                                    ? item.address + " " + item.address_detail
                                    : item.address !== null &&
                                      item.address_detail === null
                                    ? item.address
                                    : item.address === null &&
                                      item.address_detail !== null
                                    ? item.address_detail
                                    : "-"
                            }`}</td>

                            <td>{item.cutomer_name}</td>

                            <td>{item.mb_phone}</td>
                            <td>
                                {item?.dobae_date || item?.zangpan_date ? (
                                    // ||
                                    // item?.demolition_date
                                    <>
                                        {item?.dobae_date && (
                                            <p>{`${moment(
                                                item.dobae_date
                                            ).format("YYYY-MM-DD")}(${
                                                consts.dayText[
                                                    moment(
                                                        item.dobae_date
                                                    ).day()
                                                ]
                                            })`}</p>
                                        )}
                                        {item?.zangpan_date && (
                                            <p>{`${moment(
                                                item.zangpan_date
                                            ).format("YYYY-MM-DD")}(${
                                                consts.dayText[
                                                    moment(
                                                        item.zangpan_date
                                                    ).day()
                                                ]
                                            })`}</p>
                                        )}
                                    </>
                                ) : (
                                    <p>-</p>
                                )}
                            </td>
                            <td>
                                {item.dobae_as_chk === 1 && (
                                    <p>{item.dobae_banzang}</p>
                                )}
                                {item.zangpan_as_chk === 1 && (
                                    <p>{item.zangpan_banzang}</p>
                                )}
                            </td>
                            <td>
                                {item.dobae_as_chk === 1 && (
                                    <p>{item.imgCount_dobae}</p>
                                )}
                                {item.zangpan_as_chk === 1 && (
                                    <p>{item.imgCount_zangpan}</p>
                                )}
                            </td>

                            <td>
                                {item.dobae_as_date || item.zangpan_as_date ? (
                                    // ||
                                    // item?.demolition_date
                                    <>
                                        {item.dobae_as_date && (
                                            <p>{`${moment(
                                                item.dobae_date
                                            ).format("YYYY-MM-DD")}(${
                                                consts.dayText[
                                                    moment(
                                                        item.dobae_as_date
                                                    ).day()
                                                ]
                                            })`}</p>
                                        )}
                                        {item.zangpan_as_date && (
                                            <p>{`${moment(
                                                item.zangpan_as_date
                                            ).format("YYYY-MM-DD")}(${
                                                consts.dayText[
                                                    moment(
                                                        item.zangpan_as_date
                                                    ).day()
                                                ]
                                            })`}</p>
                                        )}
                                    </>
                                ) : (
                                    <p>-</p>
                                )}
                            </td>
                            <td>
                                {item.dobae_as_chk === 1 && (
                                    <p>
                                        {item.dobae_as_price.toLocaleString() +
                                            "원"}
                                    </p>
                                )}
                                {item.zangpan_as_chk === 1 && (
                                    <p>
                                        {item.zangpan_as_price.toLocaleString() +
                                            "원"}
                                    </p>
                                )}
                            </td>
                            {!isDelete && (
                                <td>
                                    <div
                                        className="TableBtns "
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constRegst5}?index=${item.idx}`
                                                );
                                            }}
                                        >
                                            변경
                                        </button>
                                        <button
                                            onClick={() => {
                                                navigate(
                                                    `${routes.constPreview6}?index=${item.idx}`
                                                );
                                            }}
                                        >
                                            보기
                                        </button>
                                        <button
                                            onClick={() =>
                                                DeleteClk(
                                                    item.idx,
                                                    item.sigonginfo_idx
                                                )
                                            }
                                        >
                                            삭제
                                        </button>
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TableASOrder() {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>모델번호</th>
                        <th>1차분류</th>
                        <th>2차분류</th>
                        <th>주문</th>
                        <th>잔여</th>
                        <th>사용</th>
                    </tr>
                </thead>
                <tbody>
                    {test.map((item, index) => (
                        <tr key={index}>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TableASUsed() {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>모델번호</th>
                        <th>1차분류</th>
                        <th>2차분류</th>
                        <th>단가</th>
                        <th>수량</th>
                        <th>메모</th>
                    </tr>
                </thead>
                <tbody>
                    {test.map((item, index) => (
                        <tr key={index}>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TableWorkerManage({
    data,
    setDeletePop,
    deletePop,
    setDeleteIdx,
}) {
    const navigate = useNavigate();
    const workerDelete = (idx) => {
        setDeleteIdx(idx);
        setDeletePop(!deletePop);
    };
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>구분</th>
                        <th>이름</th>
                        <th>연락처</th>
                        <th>소속</th>
                        <th>계좌</th>
                        <th>등록일</th>
                        <th>관리</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.type == 1 ? "일반" : "반장"}</td>
                            <td>{item.name}</td>
                            <td>{item.phone}</td>
                            <td>{item.belong}</td>
                            <td>
                                {item.bank_name},{item.bank_num},
                                {item.bank_mbname}
                            </td>
                            <td>
                                {moment(item.create_dt).format("YYYY-MM-DD")}
                            </td>
                            <td>
                                <div
                                    className="TableBtns "
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <button
                                        onClick={() => {
                                            navigate(
                                                `${routes.workerDetail}?index=${item.idx}`,
                                                { state: { data: "change" } }
                                            );
                                        }}
                                    >
                                        변경
                                    </button>
                                    <button
                                        onClick={() => {
                                            navigate(
                                                `${routes.workerDetail}?index=${item.idx}`,
                                                { state: { data: "view" } }
                                            );
                                        }}
                                    >
                                        보기
                                    </button>
                                    <button
                                        onClick={() => workerDelete(item.idx)}
                                    >
                                        삭제
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
export function PriceDobaeTable({ list }) {
    // console.log("list", list);
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th colSpan={2}>면적</th>
                        <th colSpan="3">{"천장포함"}</th>
                        <th colSpan="3">{"천장미포함"}</th>
                    </tr>
                    <tr>
                        <th>공급</th>
                        <th>전용</th>
                        <th>{"합지"}</th>
                        <th>{"실크"}</th>
                        <th>{"혼합"}</th>
                        <th>{"합지"}</th>
                        <th>{"실크"}</th>
                        <th>{"혼합"}</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(list).map((key, index) => {
                        let dataList = list[key];

                        const CeilingItem = () => {
                            return dataList?.map((item, indexItem) => {
                                // console.log("item", item);
                                return (
                                    <>
                                        <td key={indexItem + "a"}>
                                            {" "}
                                            {item.first_value}
                                        </td>
                                        <td key={indexItem + "b"}>
                                            {" "}
                                            {item.second_value}
                                        </td>
                                        <td key={indexItem + "c"}>
                                            {item.third_value}
                                        </td>
                                    </>
                                );
                            });
                        };

                        return (
                            <>
                                <tr key={index}>
                                    <td>{key}</td>
                                    <td>{key - 3}</td>
                                    {dataList.length === 2 ? (
                                        <CeilingItem />
                                    ) : parseInt(dataList[0].ceiling) === 2 ? (
                                        <>
                                            <td key="g">0</td>
                                            <td key="h">0</td>
                                            <td key="i">0</td>
                                            <CeilingItem />
                                        </>
                                    ) : (
                                        <>
                                            <CeilingItem />
                                            <td key="d">0</td>
                                            <td key="e">0</td>
                                            <td key="f">0</td>
                                        </>
                                    )}
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
export function PriceZangpanTable({ list }) {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th colSpan={2}>면적</th>
                        <th colSpan="3">{"전체"}</th>
                        <th colSpan="3">{"방만"}</th>
                    </tr>
                    <tr>
                        <th>공급</th>
                        <th>전용</th>
                        <th>{"1.8mm"}</th>
                        <th>{"2.2mm"}</th>
                        <th>{"2.7mm"}</th>
                        <th>{"1.8mm"}</th>
                        <th>{"2.2mm"}</th>
                        <th>{"2.7mm"}</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(list).map((key, index) => {
                        let dataList = list[key];

                        const CeilingItem = () => {
                            return dataList.map((item, indexItem) => {
                                return (
                                    <>
                                        <td key={indexItem + "a"}>
                                            {" "}
                                            {item.first_value}
                                        </td>
                                        <td key={indexItem + "b"}>
                                            {" "}
                                            {item.second_value}
                                        </td>
                                        <td key={indexItem + "c"}>
                                            {item.third_value}
                                        </td>
                                    </>
                                );
                            });
                        };

                        return (
                            <>
                                <tr key={index}>
                                    <td>{key}</td>
                                    <td>{key - 3}</td>
                                    {dataList.length === 2 ? (
                                        <CeilingItem />
                                    ) : dataList[0].room === 1 ? (
                                        <>
                                            {CeilingItem()}
                                            <td key="d">0</td>
                                            <td key="e">0</td>
                                            <td key="f">0</td>
                                        </>
                                    ) : (
                                        <>
                                            <td key="g">0</td>
                                            <td key="h">0</td>
                                            <td key="i">0</td>
                                            {CeilingItem()}
                                        </>
                                    )}
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
