import { useEffect, useState } from "react";
import ApiData from "../../libs/api";
import { useGetData, usePostData } from "../../utils/service";

import { toast } from "react-toastify";
import useLoadingStore from "../../zustand/Loading";

function SystemMessManagement() {
    // const systemMsgList = usePostData(ApiData.systemMsgList);
    const { data, isLoading, isError } = useGetData(ApiData.systemMsgList);
    const msgSystemReg = usePostData(ApiData.systemMsgReg);
    const [counselingData, setCounselingData] = useState("");
    const [reserveData, setReserveData] = useState("");
    const [CustomerData, setCustomerData] = useState("");
    const [reservPData, setreservPData] = useState("");
    const [finalData, setFinalData] = useState("");
    const [balanceSuccess, setBalanceSuccess] = useState("");
    const [balancePriceData, setBalancePriceData] = useState("");
    const [btnJson, setBtnJson] = useState({});
    const [materialData, setMaterialData] = useState("");
    const [sigongDobaeWork, setSigongDobaeWork] = useState("");
    const [sigongZangpanWork, setSigongZangpanWork] = useState("");
    const [jobFinishedDobaeWork, setJobFinishedDobaeWork] = useState("");
    const [jobFinishedZangpanWork, setJobFinishedZangpanWork] = useState("");
    const [asDobaeWork, setAsDobaeWork] = useState("");
    const [asZangpanWork, setAsZangpanWork] = useState("");
    // const { loadingStart, loadingEnd } = useLoadingStore();

    // const systemMsgList
    const SubmitClk = () => {
        let sender = {
            counselingTemplate: counselingData,
            reserTemplate: reserveData,
            finalTemplate: finalData,
            customerTemplate: CustomerData,
            reserPriceTemplate: reservPData,
            balancePriceTemplate: balancePriceData,
            balanceSuccessTemplate: balanceSuccess,
            productSelTemplate: materialData,
            sigongDobaeWork: sigongDobaeWork,
            sigongZangpanWork: sigongZangpanWork,
            jobFinishedDobaeWork: jobFinishedDobaeWork,
            jobFinishedZangpanWork: jobFinishedZangpanWork,
            asDobaeWork: asDobaeWork,
            asZangpanWork: asZangpanWork,
        };
        // console.log("sender", sender);
        msgSystemReg
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("저장되었습니다.");
            })
            .catch((e) => {
                // console.log(e);
                toast.error(e.response.data);
            });
    };

    useEffect(() => {
        // console.log("data", data);
        // isLoading ? loadingStart() : loadingEnd();
        setCounselingData(data?.data.counseling_template);
        setReserveData(data?.data.reser_template);
        setFinalData(data?.data.final_template);
        setCustomerData(data?.data.customer_template);
        setreservPData(data?.data.reser_price_template);
        setBalancePriceData(data?.data.balance_price_template);
        setBtnJson(data?.data.btn_json);
        setMaterialData(data?.data.product_sel_template);
        setBalanceSuccess(data?.data.balance_price_success_template);
        setSigongDobaeWork(data?.data.sigong_dobae_work);
        setSigongZangpanWork(data?.data.sigong_zangpan_work);
        setJobFinishedDobaeWork(data?.data.job_finished_dobae_work);
        setJobFinishedZangpanWork(data?.data.job_finished_zangpan_work);
        setAsDobaeWork(data?.data.as_dobae_work);
        setAsZangpanWork(data?.data.as_zangpan_work);
    }, [isLoading]);
    return (
        <>
            <div className="content ">
                <div className="title_container">
                    <p className="title">시스템문자관리</p>
                    <button className="btn btn9" onClick={SubmitClk}>
                        저장하기
                    </button>
                </div>
                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        견적서
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setCounselingData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.link}`
                                    )
                                }
                            >
                                링크
                            </button>
                        </div>

                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={counselingData}
                            onChange={(e) => {
                                setCounselingData(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        예약안내
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setReserveData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.link}`
                                    )
                                }
                            >
                                링크
                            </button>
                        </div>
                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={reserveData}
                            onChange={(e) => {
                                setReserveData(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        최종확인서
                    </div>

                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setFinalData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.link}`
                                    )
                                }
                            >
                                링크
                            </button>
                        </div>
                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={finalData}
                            onChange={(e) => {
                                setFinalData(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        자재 선택
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setMaterialData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.link}`
                                    )
                                }
                            >
                                링크
                            </button>
                        </div>
                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={materialData}
                            onChange={(e) => {
                                setMaterialData(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        고객 확인 후 입금계좌 안내
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setCustomerData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.reser_price}`
                                    )
                                }
                            >
                                예약금
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setCustomerData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.link}`
                                    )
                                }
                            >
                                링크
                            </button>
                        </div>

                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={CustomerData}
                            onChange={(e) => {
                                setCustomerData(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        예약금 확인
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setreservPData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.reser_price}`
                                    )
                                }
                            >
                                예약금
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setreservPData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_date}`
                                    )
                                }
                            >
                                시공일
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setreservPData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.link}`
                                    )
                                }
                            >
                                링크
                            </button>
                        </div>

                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={reservPData}
                            onChange={(e) => {
                                setreservPData(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        입금요청
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setBalancePriceData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.contract_price}`
                                    )
                                }
                            >
                                계약금액
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setBalancePriceData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.reser_price}`
                                    )
                                }
                            >
                                예약금
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setBalancePriceData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.balance_price}`
                                    )
                                }
                            >
                                잔금
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setBalancePriceData(
                                        (prevState) =>
                                            `${prevState} ${btnJson.link}`
                                    )
                                }
                            >
                                링크
                            </button>
                        </div>

                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={balancePriceData}
                            onChange={(e) => {
                                setBalancePriceData(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        입금완료
                    </div>
                    <div className="SystemMessage">
                        <textarea
                            style={{
                                width: "99%",
                                height: "120px",
                                resize: "none",
                            }}
                            value={balanceSuccess}
                            onChange={(e) => {
                                setBalanceSuccess(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        시공관리 도배 작업지시서
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setSigongDobaeWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_Date}`
                                    )
                                }
                            >
                                시공일
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setSigongDobaeWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_address}`
                                    )
                                }
                            >
                                주소
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setSigongDobaeWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.worker_Link}`
                                    )
                                }
                            >
                                작업자 링크
                            </button>
                        </div>

                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={sigongDobaeWork}
                            onChange={(e) => {
                                setSigongDobaeWork(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        시공관리 장판 작업지시서
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setSigongZangpanWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_Date}`
                                    )
                                }
                            >
                                시공일
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setSigongZangpanWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_address}`
                                    )
                                }
                            >
                                주소
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setSigongZangpanWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.worker_Link}`
                                    )
                                }
                            >
                                작업자 링크
                            </button>
                        </div>

                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={sigongZangpanWork}
                            onChange={(e) => {
                                setSigongZangpanWork(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        작업종료 도배 작업지시서
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setJobFinishedDobaeWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_Date}`
                                    )
                                }
                            >
                                시공일
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setJobFinishedDobaeWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_address}`
                                    )
                                }
                            >
                                주소
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setJobFinishedDobaeWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.worker_Link}`
                                    )
                                }
                            >
                                작업자 링크
                            </button>
                        </div>

                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={jobFinishedDobaeWork}
                            onChange={(e) => {
                                setJobFinishedDobaeWork(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        작업종료 장판 작업지시서
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setJobFinishedZangpanWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_Date}`
                                    )
                                }
                            >
                                시공일
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setJobFinishedZangpanWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_address}`
                                    )
                                }
                            >
                                주소
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setJobFinishedZangpanWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.worker_Link}`
                                    )
                                }
                            >
                                작업자 링크
                            </button>
                        </div>

                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={jobFinishedZangpanWork}
                            onChange={(e) => {
                                setJobFinishedZangpanWork(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        A/S관리 도배 작업지시서
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setAsDobaeWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_Date}`
                                    )
                                }
                            >
                                시공일
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setAsDobaeWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_address}`
                                    )
                                }
                            >
                                주소
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setAsDobaeWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.worker_Link}`
                                    )
                                }
                            >
                                작업자 링크
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setAsDobaeWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.worker_Date}`
                                    )
                                }
                            >
                                A/S시공일
                            </button>
                        </div>

                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={asDobaeWork}
                            onChange={(e) => {
                                setAsDobaeWork(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="BoxInfo" style={{ height: "140px" }}>
                    <div style={{ justifyContent: "center" }} className="title">
                        A/S관리 장판 작업지시서
                    </div>
                    <div className="SystemMessage">
                        <div className="buttonWrap">
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setAsZangpanWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_Date}`
                                    )
                                }
                            >
                                시공일
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setAsZangpanWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.sigong_address}`
                                    )
                                }
                            >
                                주소
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setAsZangpanWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.worker_Link}`
                                    )
                                }
                            >
                                작업자 링크
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() =>
                                    setAsZangpanWork(
                                        (prevState) =>
                                            `${prevState} ${btnJson.worker_Date}`
                                    )
                                }
                            >
                                A/S시공일
                            </button>
                        </div>

                        <textarea
                            style={{
                                width: "99%",
                                height: "65px",
                                resize: "none",
                            }}
                            value={asZangpanWork}
                            onChange={(e) => {
                                setAsZangpanWork(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className="twoBtn_wrap">
                    <div></div>
                    <button className="btn btn9" onClick={SubmitClk}>
                        저장하기
                    </button>
                </div>
            </div>
        </>
    );
}

export default SystemMessManagement;
