import { useEffect, useState } from "react";
import Input from "../../components/Input";
import InputDate from "../../components/InputDate";
import { InputCheckBox } from "../../components/InputCheckBox";
import InputSelect from "../../components/InputSelect";
import { ConstManageTable } from "../../components/Tables";
import consts from "../../libs/consts";
import Pagination from "react-js-pagination";
import images from "../../libs/images";
import XLSX from "xlsx-js-style";
import { useGetData, usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import { PopupComp } from "../../components/PopUp";
import { toast } from "react-toastify";
import moment from "moment";
import useLoadingStore from "../../zustand/Loading";

function ConstrManagement() {
    const sigongInfoList = usePostData(ApiData.sigongInfoList);
    const sigongInfoDelete = usePostData(ApiData.sigongInfoDelete);
    const {
        data: ManagerData,
        isLoading,
        isError,
    } = useGetData(ApiData.adminIdList);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const [sdate, setsdate] = useState("");
    const [edate, setedate] = useState("");
    const [selectDate, setSelectDate] = useState("");
    const [searchType, setSearchType] = useState([]);
    const [searchVal, setSearchVal] = useState("");
    const [manager, setManager] = useState("");
    const [managerSetting, setManagerSetting] = useState([
        { value: "", text: "전체" },
    ]);
    const [reservInfo, setReservInfo] = useState("");
    const [downPayment, setDownPayment] = useState("");
    const [workOrder, setWorkOrder] = useState("");
    const [progressStage, setProgressStage] = useState("");
    const [allData, setAllData] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [deletePop, setDeletePop] = useState(false);
    const [deleteIdx, setDeleteIdx] = useState(0);

    const [page, setPage] = useState(1);
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const sigongInfoListApi = (reset = false) => {
        let sender = {
            searchVal: searchVal,
            product_type: searchType.map((c) => parseInt(c)),
            page: page - 1,
        };
        if (manager !== "") {
            sender.admin_idx = parseInt(manager);
        }
        if (downPayment !== "") {
            sender.customer_payment_chk = parseInt(downPayment);
        }
        if (reservInfo !== "") {
            sender.customer_contract_chk = parseInt(reservInfo);
        }
        if (workOrder !== "") {
            sender.order_chk = parseInt(workOrder);
        }
        if (progressStage !== "") {
            sender.sigong_chk = parseInt(progressStage);
        }
        if (reset == true) {
            sender = {
                searchVal: "",
                product_type: [],
                admin_idx: "",
                customer_contract_chk: "",
                customer_payment_chk: "",
                order_chk: "",
                sigong_chk: "",
                page: 0,
            };
        }
        // console.log("sender", sender);
        // loadingStart();
        sigongInfoList
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log(data);
                // loadingEnd();
                setAllData(data);
                setDataList(data.rows);
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
            });
    };
    useEffect(() => {
        ManagerData?.data?.map((item, index) => {
            setManagerSetting((prev) => [
                ...prev,
                { value: item.idx, text: item.mb_name },
            ]);
        });
        return () => {
            setManagerSetting([{ value: "", text: "전체" }]); // 상태를 초기화
        };
        // setManagerSetting();
    }, [ManagerData]);
    useEffect(() => {
        sigongInfoListApi();
    }, [page]);

    const excelDown = async () => {
        try {
            // 서버에서 직원 리스트 가져오기
            let sender = {
                searchVal: "",
                product_type: [],
                admin_idx: "",
                customer_contract_chk: "",
                customer_payment_chk: "",
                order_chk: "",
                sigong_chk: "",
                page: 0,
                excelDown: 1,
            };

            let excel = await sigongInfoList
                .mutateAsync(sender)
                .then(({ data }) => {
                    return data;
                })
                .catch((e) => {
                    // console.log(e.response.data);
                });
            const excelData = excel;
            // console.log(excelData);

            // Excel 파일 생성 및 다운로드
            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: {
                    bold: true,
                    color: { rgb: "000000" },
                    name: "함초롱바탕",
                    sz: 13,
                },
                fill: { fgColor: { rgb: "f1faee" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };
            const dataStyle = {
                font: { color: { rgb: "000000" }, name: "함초롱바탕", sz: 11 },
                fill: { fgColor: { rgb: "ffffff" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };

            // 열의 폭을 정의
            const colWidths = [
                80, 80, 110, 200, 120, 120, 120, 80, 80, 120, 80,
            ];

            // cols 속성을 사용하여 각 열의 폭을 조절
            const cols = colWidths.map((width) => ({ wpx: width }));

            const headerRow = [
                { v: "담당자", t: "s", s: headerStyle },
                { v: "고객명", t: "s", s: headerStyle },
                { v: "연락처", t: "s", s: headerStyle },
                { v: "주소", t: "s", s: headerStyle },
                { v: "총액", t: "s", s: headerStyle },
                { v: "시공일", t: "s", s: headerStyle },
                { v: "제품", t: "s", s: headerStyle },
                { v: "반장", t: "s", s: headerStyle },
                { v: "인원", t: "s", s: headerStyle },
                { v: "예약안내", t: "s", s: headerStyle },
                { v: "예약금", t: "s", s: headerStyle },
                { v: "작업지시서", t: "s", s: headerStyle },
                { v: "진행단계", t: "s", s: headerStyle },
            ];
            const dataRows = excelData.rows.flatMap((emp) => {
                const commonData = [
                    {
                        v: `${emp.admin_name || "-"}`,
                        t: "s",
                        s: dataStyle,
                    }, //담당자
                    {
                        v: `${emp.cutomer_name || "-"}`,
                        t: "s",
                        s: dataStyle,
                    }, // 고객명
                    {
                        v: `${emp.mb_phone || "-"}`,
                        t: "s",
                        s: dataStyle,
                    }, // 연락처
                    {
                        v: `${
                            emp.address !== null && emp.address_detail !== null
                                ? emp.address + " " + emp.address_detail
                                : emp.address !== null &&
                                  emp.address_detail === null
                                ? emp.address
                                : emp.address === null &&
                                  emp.address_detail !== null
                                ? emp.address_detail
                                : "-"
                        }`,
                        t: "s",
                        s: dataStyle,
                    }, // 주소
                    {
                        v: `${
                            emp.dobae_price +
                                emp.zangpan_price +
                                emp.maru_price +
                                emp.demolition_price || ""
                        }`,
                        t: "s",
                        s: dataStyle,
                    }, // 총액
                ];
                const productData = [
                    {
                        product: "도배",
                        date: emp.dobae_date,
                        isProduct: emp.dobae === 1,
                        worker: emp.worker_list[0]?.banzang_name || "-",
                        peopleCnt: emp.worker_list[0]?.people_cnt || "-",
                        customerContractChk: emp.customer_contract_chk,
                        customerPaymentChk: emp.customer_payment_chk,
                    },
                    {
                        product: "장판",
                        date: emp.zangpan_date,
                        isProduct: emp.zangpan === 1,
                        worker: emp.worker_list[1]?.banzang_name || "-",
                        peopleCnt: emp.worker_list[1]?.people_cnt || "-",
                        customerContractChk: emp.customer_contract_chk,
                        customerPaymentChk: emp.customer_payment_chk,
                    },
                    {
                        product: "마루",
                        date: emp.maru_date,
                        isProduct: emp.maru === 1,
                        worker: emp.worker_list[2]?.banzang_name || "-",
                        peopleCnt: emp.worker_list[2]?.people_cnt || "-",
                        customerContractChk: emp.customer_contract_chk,
                        customerPaymentChk: emp.customer_payment_chk,
                    },
                    // {
                    //     product: "철거",
                    //     date: emp.demolition_date,
                    //     isProduct: emp.demolition_price !== 0,
                    //     worker: emp.worker_list[3]?.banzang_name || "-",
                    //     peopleCnt: emp.worker_list[3]?.people_cnt || "-",
                    //     customerContractChk: emp.customer_contract_chk,
                    //     customerPaymentChk: emp.customer_payment_chk,
                    // },
                ];

                return productData
                    .filter((pd) => pd.isProduct)
                    .map((pd) => [
                        ...commonData,
                        {
                            v: pd.date
                                ? moment(pd.date).format("YYYY.MM.DD")
                                : "",
                            t: "s",
                            s: dataStyle,
                        }, //시공일
                        {
                            v: pd.product,
                            t: "s",
                            s: dataStyle,
                        }, //제품
                        {
                            v: pd.worker,
                            t: "s",
                            s: dataStyle,
                        }, //반장
                        {
                            v: pd.peopleCnt,
                            t: "s",
                            s: dataStyle,
                        }, //인원
                        {
                            v: emp.customer_contract_chk === 0 ? "X" : "O",
                            t: "s",
                            s: dataStyle,
                        }, //예약안내
                        {
                            v: emp.customer_payment_chk === 0 ? "X" : "O",
                            t: "s",
                            s: dataStyle,
                        }, //예약금
                        {
                            v: emp.workerOrder === 0 ? "X" : "O",
                            t: "s",
                            s: dataStyle,
                        }, //작업지시서
                        {
                            v: emp.endChk === 0 ? "종료" : "진행",
                            t: "s",
                            s: dataStyle,
                        }, //진행단계
                    ]);
            });

            const rows = [headerRow, ...dataRows];

            // 새로운 Sheet 객체 생성
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws["!cols"] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, "상담신청");

            // 파일 다운로드
            XLSX.writeFile(wb, "ConstrManagement.xlsx");

            console.log("Excel 파일 생성 및 다운로드 완료");
        } catch (error) {
            console.error("Error occurred while downloading Excel", error);
        }
    };
    const ResetClk = () => {
        setsdate("");
        setedate("");
        setSelectDate("");
        setSearchType([]);
        setSearchVal("");
        setManager("");
        setReservInfo("");
        setDownPayment("");
        setWorkOrder("");
        setProgressStage("");
        sigongInfoListApi(true);
    };
    const deleteData = () => {
        let sender = {
            sigongInfoIdx: deleteIdx,
        };
        // console.log("sender", sender);
        sigongInfoDelete
            .mutateAsync(sender)
            .then(({ data }) => {
                sigongInfoListApi();
                toast.success("삭제를 완료했습니다.");
            })
            .catch((e) => {
                toast.error("삭제를 하지 못했습니다.");
            });
    };
    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteData}
            />
            <div className="content ">
                <p className="title">시공관리</p>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <InputDate
                                labelSelect={true}
                                type="text"
                                placeholder="시작날짜"
                                placeholderEnd="종료날짜"
                                name="ipd1"
                                dateValue={sdate}
                                setDateValue={setsdate}
                                dateValueEnd={edate}
                                setDateValueEnd={setedate}
                                multiple={true}
                                selectDate={selectDate}
                                setSelectDate={setSelectDate}
                            />
                        </div>
                        <div className="search_form">
                            <Input
                                className="input_text"
                                type="text"
                                placeholder="연락처, 주소, 고객명"
                                name="stx"
                                value={searchVal}
                                setValue={setSearchVal}
                                label="검색"
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        sigongInfoListApi();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="search_box_wrap">
                        <InputCheckBox
                            title="상태"
                            Chkboxes={consts.material}
                            setValue={setSearchType}
                            values={searchType}
                        />
                        <InputSelect
                            name="status"
                            label="담당자"
                            value={manager}
                            setValue={setManager}
                            sectionStyle={{ width: "26%" }}
                            option={managerSetting}
                        />
                    </div>
                    <div className="search_box_wrap">
                        <div className="search_box_wrapper">
                            <InputSelect
                                name="status"
                                label="예약안내"
                                value={reservInfo}
                                setValue={setReservInfo}
                                option={[
                                    {
                                        value: "",
                                        text: "전체",
                                    },
                                    {
                                        value: parseInt(0),
                                        text: "미발송",
                                    },
                                    {
                                        value: parseInt(1),
                                        text: "발송",
                                    },
                                ]}
                            />
                            <InputSelect
                                name="status"
                                label="계약금"
                                value={downPayment}
                                setValue={setDownPayment}
                                option={[
                                    {
                                        value: "",
                                        text: "전체",
                                    },
                                    {
                                        value: parseInt(0),
                                        text: "미입금",
                                    },
                                    {
                                        value: parseInt(1),
                                        text: "입금",
                                    },
                                ]}
                            />
                        </div>
                        <div className="search_box_wrapper">
                            <InputSelect
                                name="status"
                                label="작업지시서"
                                value={workOrder}
                                setValue={setWorkOrder}
                                option={[
                                    {
                                        value: "",
                                        text: "전체",
                                    },
                                    {
                                        value: parseInt(0),
                                        text: "미발송",
                                    },
                                    {
                                        value: parseInt(1),
                                        text: "발송",
                                    },
                                ]}
                            />
                            <InputSelect
                                name="status"
                                label="진행단계"
                                value={progressStage}
                                setValue={setProgressStage}
                                option={[
                                    {
                                        value: "",
                                        text: "전체",
                                    },
                                    {
                                        value: parseInt(0),
                                        text: "종료",
                                    },
                                    {
                                        value: parseInt(1),
                                        text: "진행",
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button className="btn btn8" onClick={ResetClk}>
                                초기화
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() => sigongInfoListApi()}
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                        [오늘 : {allData?.today}건, 검색결과 : {allData?.count}
                        건]
                    </p>
                    <ConstManageTable
                        dataList={dataList}
                        setDeletePop={setDeletePop}
                        deletePop={deletePop}
                        setDeleteIdx={setDeleteIdx}
                    />
                    <div className="BottomContent">
                        <div className="ButtonContent"></div>
                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={page}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={allData?.count || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <div
                                className="excelContent"
                                style={{ cursor: "pointer" }}
                                onClick={excelDown}
                            >
                                <img src={images.excel} alt="excel" />
                                <p>엑셀다운로드</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ConstrManagement;
