import { useNavigate } from "react-router-dom";
import routes from "../../libs/routes";
import { usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import { useEffect, useState } from "react";
import { PopupComp } from "../../components/PopUp";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import useLoadingStore from "../../zustand/Loading";
import Pagination from "react-js-pagination";
import Input from "../../components/Input";

function AgencyManagement() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const agencyListApi = usePostData(ApiData.agencyListThings);
    const agencyDelete = usePostData(ApiData.agencyDelete);
    const [page, setPage] = useState(1);
    const [agencyCount, setAgencyCount] = useState(0);
    const [searchVal, setSearchVal] = useState("");
    const [agencyList, setAgencyList] = useState([]);
    const [deletePop, setDeletePop] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    const deleteClk = (idx) => {
        setDeletePop(true);
        setDeleteId(idx);
        // console.log("deleteClk", idx);
    };

    const deleteData = () => {
        agencyDelete
            .mutateAsync({ deleteIdx: deleteId })
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                agencyListData();
            })
            .catch((error) => {
                toast.error("삭제실패");
            });
    };
    const agencyListData = (reset = false) => {
        let sender = {
            page: page - 1,
            searchVal: searchVal,
        };
        if (reset === true) {
            setSearchVal("");
            sender = {
                page: 0,
                searchVal: "",
            };
        }
        // console.log("sender", sender);
        agencyListApi
            .mutateAsync(sender)
            .then(({ data }) => {
                setAgencyCount(data.count);
                setAgencyList(data.rows);
            })
            .catch((error) => {
                // console.log("error", error);
            });
    };
    useEffect(() => {
        agencyListData();
    }, [page]);
    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteData}
            />
            <div className="content ">
                <p className="title">대리점관리</p>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <Input
                                className="input_text"
                                type="text"
                                placeholder="대리점명, 위치, 전화번호, 담당자, 연락처"
                                name="stx"
                                value={searchVal}
                                setValue={setSearchVal}
                                label="검색"
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        agencyListData();
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button
                                className="btn btn8"
                                onClick={() => agencyListData(true)}
                            >
                                초기화
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() => agencyListData()}
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "20px" }} className="table_scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>대리점명</th>
                                <th>위치</th>
                                <th>전화번호</th>
                                <th>담당자</th>
                                <th>연락처</th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {agencyList?.map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{x.agency_name}</td>
                                        <td>{`${x.address || ""}${
                                            x.address_detail || ""
                                        }`}</td>
                                        <td>{x.agency_phone}</td>
                                        <td>{x.manager_name}</td>
                                        <td>{x.manager_phone}</td>
                                        <td>
                                            <div
                                                className="TableBtns FlexCenter "
                                                style={{ display: "flex" }}
                                            >
                                                <button
                                                    onClick={() => {
                                                        navigate(
                                                            `${routes.material7_1}?index=${x.idx}`
                                                        );
                                                    }}
                                                >
                                                    변경
                                                </button>

                                                <button
                                                    onClick={() =>
                                                        deleteClk(x.idx)
                                                    }
                                                >
                                                    삭제
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="PaginationContent">
                    <Pagination
                        className="my-pagination"
                        // 현제 보고있는 페이지
                        activePage={page}
                        // 한페이지에 출력할 아이템수
                        itemsCountPerPage={20}
                        // 총 아이템수
                        totalItemsCount={agencyCount || 0}
                        // 표시할 페이지수
                        pageRangeDisplayed={10}
                        // 함수
                        onChange={handlePageChange}
                        nextPageText=""
                        prevPageText=""
                        firstPageText=""
                        lastPageText=""
                    ></Pagination>
                </div>
                <div className="BtnEnd">
                    <button
                        className="btn btn9"
                        onClick={() => {
                            navigate(`${routes.material7_1}`);
                        }}
                    >
                        등록
                    </button>
                </div>
            </div>
        </>
    );
}
export default AgencyManagement;
