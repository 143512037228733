import { useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import DaumPostcode from "react-daum-postcode";
import { ko } from "date-fns/locale";
import moment from "moment";

import "react-quill/dist/quill.snow.css";
import { v4 as uuidv4 } from "uuid";
import InputFile from "./InputFile";
import consts from "../libs/consts";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize";

import { usePostData } from "../utils/service";
import ApiData from "../libs/api";
import { resizeFile } from "../utils/utils";
Quill.register("modules/ImageResize", ImageResize);

// import styles from "../contents/QuillEditor.module.css";
export function BoxContent({ title, content }) {
    return (
        <div className="ImgInputWrap border-bot">
            <div className="Imgtitle">{title}</div>
            <div className="sub flex-al-center">{content}</div>
        </div>
    );
}
export function BoxContent2({ title, content1, content2 }) {
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div style={{ display: "block" }} className="sub">
                <p>{content1}</p>
                <p>{content2}</p>
            </div>
        </div>
    );
}
export function BoxShowImg({ title, goUrl = false, file = false }) {
    const imageUrl = "이미지 URL"; // 이미지 URL을 설정해주세요.
    const downloadUrl = "다운로드 URL"; // 다운로드 URL을 설정해주세요.
    const fileName = "파일 이름"; // 파일 이름을 설정해주세요.

    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                {goUrl ? (
                    <a
                        href={imageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={imageUrl} alt={title} />
                    </a>
                ) : null}
                {file ? (
                    <a href={downloadUrl} download={fileName}>
                        {fileName}
                    </a>
                ) : null}
            </div>
        </div>
    );
}
export function BoxLookImg({ title, img = [] }) {
    return (
        <div
            className="ImgInputWrap border-bot"
            // style={{ border: "none", borderBottom: "1px solid #ddd" }}
        >
            <div className="Imgtitle">{title}</div>
            <div
                className="sub flex-al-center"
                // style={{ display: "flex", alignItems: "center" }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3,1fr)",
                        gap: "10px",
                    }}
                >
                    {img?.map((x, i) => (
                        <img
                            style={{
                                marginRight: "10px",
                                maxWidth: "350px",
                            }}
                            key={i}
                            src={`${consts.ImgState}${x.sucImg}`}
                            alt={title}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
export function BoxDelImg({ title, img }) {
    return (
        <div className="BoxInfo" style={{ height: "170px" }}>
            <div className="title">{title}</div>
            <div className="sub">
                <img
                    style={{ height: "150px", marginRight: "10px" }}
                    // key={i}
                    src={img}
                    alt={title}
                />
                <button
                    style={{
                        width: "80px",
                        padding: "5px 0",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        backgroundColor: "#dcdcdc",
                        fontSize: "16px",
                        marginRight: "10px",
                    }}
                    // onClick={toggleHandler}
                >
                    삭제
                </button>
            </div>
        </div>
    );
}
export function BoxTextInput({
    title,
    placeholder = "",
    value = "",
    setValue,
    inputWidth = 30,
    unit = "",
    type = "text",
    placeholder2 = "",
    value2 = "",
    setValue2 = () => {},
    readOnly = false,
}) {
    const onChangeValue2 = (e) => {
        setValue2(e.target.value);
    };
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                <input
                    style={{ width: `${inputWidth}%` }}
                    type={type}
                    placeholder={placeholder}
                    value={
                        unit === "원"
                            ? value
                                ? Number(value).toLocaleString()
                                : 0
                            : value || ""
                    }
                    onChange={(e) => {
                        let val = e.target.value;
                        if (unit === "원") {
                            val =
                                parseInt(
                                    e.target.value.replace(/[^0-9]/g, "")
                                ) || 0;
                        }
                        setValue(val);
                    }}
                    readOnly={readOnly}
                />
                <span>{unit}</span>
                {placeholder2 && (
                    <input
                        style={{ width: `${inputWidth}%` }}
                        type="text"
                        placeholder={placeholder2}
                        value={value2 || ""} // 수정된 부분
                        onChange={onChangeValue2} // 수정된 부분
                    />
                )}
            </div>
        </div>
    );
}
export function BoxTextRadioInput({
    title,
    placeholder = "",
    inputWidth = 30,
    unit = "",
    Radioes = [],
}) {
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                <input
                    style={{ width: `${inputWidth}%` }}
                    type="text"
                    placeholder={placeholder}
                />
                <span>{unit}</span>
                {Radioes.map((x, i) => (
                    <div key={i} className="RadioBoxOne">
                        <input
                            className="radio"
                            type="radio"
                            id={x.label}
                            name="radioGroup"
                            value={x.value}
                        />
                        <label htmlFor={x.label}>{x.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}
export function BoxCheckboxInput({ title, Chkboxes = [] }) {
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                {Chkboxes.map((x, i) => (
                    <div key={i} className="ChkBoxOne">
                        <input
                            type="checkbox"
                            id={x.label}
                            name={x.label}
                            value={x.value}
                        />
                        <label htmlFor={x.label}>{x.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export function BoxRadioboxInput({
    radioNames = "radioGroup",
    title,
    Radioes,
    Dropdowns = [],
    DropdownWidth = 15,
    IsTextInput = false,
    placeholder = "",
    TextInputWidth = 10,
    TextInputUnit = "",
    radioValue,
    setRadioValue,
    selectedValue,
    setSelectedValue,
    directType = "text",
    directInput,
    setDirectInput,
    disabled = false,
}) {
    const OnChangeRadio = (e) => {
        setRadioValue(parseInt(e.target.value));
    };
    const handleInputChange = (e) => {
        setDirectInput(e.target.value);
    };

    const handleDropdownChange = (e) => {
        if (directType === "number") {
            setSelectedValue(parseInt(e.target.value));
        } else {
            setSelectedValue(e.target.value);
        }
    };

    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                {Radioes.map((x, i) => {
                    const id = uuidv4();
                    return (
                        <div key={i} className="RadioBoxOne">
                            <input
                                checked={
                                    isNaN(radioValue)
                                        ? false
                                        : radioValue === x.value
                                }
                                className="radio"
                                type="radio"
                                id={id}
                                name={radioNames}
                                value={x.value}
                                onChange={OnChangeRadio}
                                disabled={disabled}
                            />
                            <label htmlFor={id}>{x.label}</label>
                        </div>
                    );
                })}
                {Dropdowns.length > 0 && (
                    <select
                        style={{
                            width: `${DropdownWidth}%`,
                        }}
                        value={
                            selectedValue
                                ? selectedValue
                                : typeof selectedValue === "number"
                                ? 0
                                : ""
                        }
                        onChange={handleDropdownChange}
                    >
                        {Dropdowns.map((x, i) => (
                            <option key={i} value={parseInt(x.value)}>
                                {x.text}
                            </option>
                        ))}
                    </select>
                )}
                {selectedValue === 0 && (
                    <>
                        <input
                            style={{ width: `${TextInputWidth}%` }}
                            type={directType}
                            value={directInput || ""}
                            placeholder={placeholder}
                            onChange={handleInputChange}
                        />
                        <span>{TextInputUnit}</span>
                    </>
                )}
                {IsTextInput && (
                    <>
                        <input
                            style={{ width: `${TextInputWidth}%` }}
                            type={directType}
                            value={directInput || ""}
                            placeholder={placeholder}
                            onChange={handleInputChange}
                        />
                        <span>{TextInputUnit}</span>
                    </>
                )}
            </div>
        </div>
    );
}
export function BoxJustDropDown({
    title,
    Dropdowns = [],
    width = "20",
    value = "",
    setValue,
}) {
    const handleChange = (event) => {
        setValue(event.target.value); // 선택한 값 업데이트
    };

    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                <select
                    style={{ width: `${width}%` }}
                    value={value || ""}
                    onChange={handleChange}
                >
                    {Dropdowns?.map((x, i) => (
                        <option key={i} value={x.value}>
                            {x.text}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export function BoxDropdownInput({
    title,
    Dropdowns = [],
    width = "20",
    value = "",
    setValue,
    defaultValue = 0,
    directInput = "",
    setDirectInput = () => {},
    extraInputType = "text",
}) {
    directInput = directInput || "";
    const handleChange = (event) => {
        setValue(event.target.value); // 선택한 값 업데이트
    };

    const handleInputChange = (event) => {
        setDirectInput(
            extraInputType == "number"
                ? parseInt(event.target.value)
                : event.target.value
        );
    };
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                <select
                    style={{ width: `${width}%` }}
                    value={value || ""}
                    onChange={handleChange}
                >
                    {Dropdowns?.map((x, i) => (
                        <option key={i} value={x.value}>
                            {x.text}
                        </option>
                    ))}
                </select>
                {value === defaultValue && (
                    <>
                        <input
                            style={{ width: `20%` }}
                            type={extraInputType}
                            value={directInput}
                            placeholder={"직접입력"}
                            onChange={handleInputChange}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
export function BoxDropdownMemoInput({
    title,
    Dropdowns = [],
    width = "20",
    value,
    setValue,
    InputValue = "",
    setInputValue,
    defaultValue = 0,
    extraInputType = "text",
}) {
    const handleChange = (event) => {
        setValue(event.target.value); // 선택한 값 업데이트
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                <select
                    style={{ width: `${width}%` }}
                    value={value}
                    onChange={handleChange}
                >
                    {Dropdowns?.map((x, i) => (
                        <option key={i} value={x.value}>
                            {x.text}
                        </option>
                    ))}
                </select>
                {value == defaultValue && (
                    <>
                        <input
                            style={{ width: `20%` }}
                            type={extraInputType}
                            value={InputValue}
                            placeholder={"직접입력"}
                            onChange={handleInputChange}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
export function BoxDropdownTwoInput({
    title,
    value1,
    setValue1,
    value1memo = "",
    setValue1Memo,
    value2,
    setValue2,
    value2memo = "",
    setValue2Memo,
    Dropdowns = [],
    width = "20",
    Dropdowns2 = [],
}) {
    const handleChange = (event) => {
        setValue1(event.target.value); // 선택한 값 업데이트
    };
    const handleChange2 = (event) => {
        setValue2(event.target.value); // 선택한 값 업데이트
    };
    const handleMemo1Change = (event) => {
        setValue1Memo(event.target.value);
    };
    const handleMemo2Change = (event) => {
        setValue2Memo(event.target.value);
    };
    return (
        <div className="BoxInfo" style={{ height: "90px" }}>
            <div className="title">{title}</div>
            <div className="sub2">
                <div style={{ width: `${50}%`, display: "flex", gap: "5px" }}>
                    <select
                        style={{ width: `${30}%` }}
                        value={value1}
                        onChange={handleChange}
                    >
                        {Dropdowns.map((x, i) => (
                            <option key={i} value={x.value}>
                                {x.text}
                            </option>
                        ))}
                    </select>
                    {value1 == 1 && (
                        <>
                            <input
                                style={{
                                    width: `30%`,
                                    height: "100%",
                                    border: "1px solid #ddd",
                                    padding: "0 12px",
                                    background: "#fff",
                                    borderRadius: "4px",
                                    fontSize: "14px",
                                }}
                                type="text"
                                value={value1memo || ""}
                                placeholder={"직접입력"}
                                onChange={handleMemo1Change}
                            />
                        </>
                    )}
                </div>
                <div style={{ width: `${50}%`, display: "flex", gap: "5px" }}>
                    <select
                        style={{ width: `${30}%` }}
                        value={value2}
                        onChange={handleChange2}
                    >
                        {Dropdowns2.map((x, i) => (
                            <option key={i} value={x.value}>
                                {x.text}
                            </option>
                        ))}
                    </select>
                    {value2 == 1 && (
                        <>
                            <input
                                style={{
                                    width: `30%`,
                                    height: "100%",
                                    border: "1px solid #ddd",
                                    padding: "0 12px",
                                    background: "#fff",
                                    borderRadius: "4px",
                                    fontSize: "14px",
                                }}
                                type="text"
                                value={value2memo || ""}
                                placeholder={"직접입력"}
                                onChange={handleMemo2Change}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

const postCodeStyle = {
    width: "100%",
    borderTop: "1px solid #000",
};

export function BoxAdressInput({
    value = "",
    setValue,
    detailValue = "",
    setDetailValue,
}) {
    const [zonecode, setZonecode] = useState("");

    const [isOpen, setIsOpen] = useState(false); // 초기값을 불리언으로 변경

    const completeHandler = (data) => {
        const { address, zonecode } = data;
        setZonecode(zonecode);
        setValue(address);
    };

    const closeHandler = (state) => {
        if (state === "FORCE_CLOSE") {
            setIsOpen(false);
        } else if (state === "COMPLETE_CLOSE") {
            setIsOpen(false);
        }
    };

    const toggleHandler = () => {
        setIsOpen((prevOpenState) => !prevOpenState);
    };

    const inputChangeHandler = (event) => {
        setDetailValue(event.target.value);
    };

    return (
        <div className="BoxInfo">
            <div className="title">주소</div>
            <div className="sub">
                <button
                    style={{
                        width: "100px",
                        padding: "5px 0",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        backgroundColor: "#dcdcdc",
                        fontSize: "16px",
                        marginRight: "10px",
                    }}
                    onClick={toggleHandler}
                >
                    주소찾기
                </button>
                {isOpen && (
                    <div className="postCode ">
                        <div
                            style={{
                                background: "white",
                                display: "flex",
                                justifyContent: "end",
                            }}
                        >
                            <div
                                className="exit_btn"
                                onClick={() => setIsOpen(false)}
                            />
                        </div>

                        <DaumPostcode
                            onComplete={completeHandler}
                            onClose={closeHandler}
                        />
                    </div>
                )}
                <div
                    style={{
                        background: "#dcdcdc",
                        height: "80%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        fontSize: "15px",
                        padding: "10px",
                        width: "400px",
                        marginRight: "10px",
                    }}
                >
                    {value}
                </div>
                <input
                    style={{ width: "300px" }}
                    placeholder={"상세주소 입력"}
                    value={detailValue || ""}
                    onChange={inputChangeHandler}
                />
            </div>
        </div>
    );
}

export function BoxDateInput({
    title,
    startvalue = "",
    setStartValue,
    isDouble = false,
    endValue = "",
    setEndValue,
}) {
    //날짜 정보를 가져 오거나 선택할 수 있는 컴포넌트

    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                {isDouble ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                            gap: "10px",
                        }}
                    >
                        <DatePicker
                            locale={ko}
                            selected={startvalue}
                            onChange={(date) =>
                                setStartValue(moment(date).format("YYYY-MM-DD"))
                            }
                            className="input_date_label"
                            dateFormat="yyyy-MM-dd"
                        />
                        <span style={{ display: "flex" }}>~</span>
                        <DatePicker
                            locale={ko}
                            selected={endValue}
                            onChange={(date) =>
                                setEndValue(moment(date).format("YYYY-MM-DD"))
                            }
                            className="input_date_label"
                            dateFormat="yyyy-MM-dd"
                        />
                    </div>
                ) : (
                    <DatePicker
                        locale={ko}
                        selected={startvalue}
                        onChange={(date) =>
                            setStartValue(moment(date).format("YYYY-MM-DD"))
                        }
                        className="input_date_label"
                        dateFormat="yyyy-MM-dd"
                    />
                )}
            </div>
        </div>
    );
}
export function BoxDateTimeSelect({ title }) {
    const [startDate, setStartDate] = useState(new Date());
    const [hour, setHour] = useState(0);
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub datePickRadio">
                <DatePicker
                    locale={ko}
                    selected={startDate}
                    onChange={(date) =>
                        setStartDate(moment(date).format("YYYY-MM-DD"))
                    }
                    className="input_date_label"
                    dateFormat="yyyy-MM-dd"
                />
                <select
                    style={{ width: `10%` }}
                    value={hour}
                    onChange={setHour}
                >
                    {[...Array(24)].map((d, i) => {
                        let val = i + 1 < 10 ? "0" + (i + 1) : i + 1;
                        return (
                            <option key={i} value={val}>
                                {val + "시"}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
}
export function BoxDepositDatePick({ title, day, setDay, radio, setRadio }) {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub datePickRadio">
                <DatePicker
                    locale={ko}
                    selected={day}
                    onChange={(date) =>
                        setDay(moment(date).format("YYYY-MM-DD"))
                    }
                    className="input_date_label"
                    dateFormat="yyyy-MM-dd"
                />
                <div className="RadioBoxOne">
                    <input
                        checked={radio === 0}
                        className="radio"
                        type="radio"
                        id="Deposit1"
                        name="radioGroup"
                        value={radio}
                        onChange={(e) => setRadio(0)}
                    />
                    <label htmlFor="Deposit1">미입금</label>
                </div>
                <div className="RadioBoxOne">
                    <input
                        checked={radio === 1}
                        className="radio"
                        type="radio"
                        id="Deposit2"
                        name="radioGroup"
                        value={radio}
                        onChange={(e) => setRadio(1)}
                    />
                    <label htmlFor="Deposit2">입금완료</label>
                </div>
            </div>
        </div>
    );
}
export function BoxMemoInput({
    readOnly = false,
    title = "메모",
    value = "",
    setValue,
}) {
    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <div className="BoxInfo" style={{ height: "120px" }}>
            <div className="title">{title}</div>
            <div className="sub">
                <textarea
                    style={{
                        width: "99%",
                        height: "100px",
                        resize: "none",
                        border: "1px solid #ddd",
                    }}
                    value={value || ""}
                    onChange={handleChange}
                    readOnly={readOnly}
                />
            </div>
        </div>
    );
}

export function BoxImageInput({ title = "이미지" }) {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileDelete = () => {
        setFile(null);
    };

    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                <input
                    style={{ height: "auto", paddingLeft: "0px" }}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                />
                {file && (
                    <div>
                        <span>{file.name}</span>
                        <button onClick={handleFileDelete}>삭제</button>
                    </div>
                )}
            </div>
        </div>
    );
}

export function BoxSendContent({ value, setValue }) {
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <div className="BoxInfo" style={{ height: "220px" }}>
            <div
                className="title"
                style={{
                    alignItems: "flex-start",
                    paddingTop: "20px",
                    flexDirection: "column",
                }}
            >
                발송내용
                <span>{value?.length != 0 ? value?.length : 0}/2000</span>
            </div>
            <div className="sub">
                <textarea
                    value={value}
                    onChange={handleChange}
                    style={{ width: "99%", height: "200px", resize: "none" }}
                />
            </div>
        </div>
    );
}

export function BoxVisitResv({
    startDate,
    setStartDate,
    time = new Date(),
    setTime,

    visitValue,
    setVisitValue,
    registerPopup,
    setRegisterPopup,
}) {
    const [hour, setHour] = useState(1);
    const [minute, setMinute] = useState(0);
    useEffect(() => {
        setHour(time?.split(":")[0] || 1);
        setMinute(time?.split(":")[1] || 0);
    }, [time]);

    const handleHourChange = (e) => {
        setHour(e.target.value);
        setTime(`${e.target.value}:${minute}`);
    };
    const handleMinuteChange = (e) => {
        setMinute(e.target.value);
        setTime(`${hour}:${e.target.value}`);
    };
    return (
        <div className="BoxInfo">
            <div className="title">방문예약</div>
            <div className="sub ResvDate">
                <DatePicker
                    locale={ko}
                    selected={startDate}
                    onChange={(date) =>
                        setStartDate(moment(date).format("YYYY-MM-DD"))
                    }
                    className="input_date_label"
                    dateFormat="yyyy-MM-dd"
                />
                <select
                    style={{ width: `10%` }}
                    value={parseInt(hour)}
                    onChange={(e) => {
                        handleHourChange(e);
                    }}
                >
                    {[...Array(24)].map((d, i) => {
                        let val = i + 1 < 10 ? "0" + (i + 1) : i + 1;
                        return (
                            <option key={i} value={i + 1}>
                                {val + "시"}
                            </option>
                        );
                    })}
                </select>
                <select
                    style={{ width: `10%` }}
                    value={parseInt(minute)}
                    onChange={(e) => {
                        handleMinuteChange(e);
                    }}
                >
                    {[...Array(6)].map((d, i) => {
                        let value = i * 10;
                        return (
                            <option key={i} value={value}>
                                {value}분
                            </option>
                        );
                    })}
                </select>
                <div className="RadioBoxOne">
                    <input
                        className="radio"
                        type="radio"
                        id="visit3"
                        name="visitRadio"
                        value={visitValue}
                        checked={visitValue === 0}
                        onChange={(e) => setVisitValue(0)}
                    />
                    <label htmlFor="visit3">방문없음</label>
                </div>
                <div className="RadioBoxOne">
                    <input
                        className="radio"
                        type="radio"
                        id="visit1"
                        name="visitRadio"
                        value={visitValue}
                        checked={visitValue === 1}
                        onChange={(e) => setVisitValue(1)}
                    />
                    <label htmlFor="visit1">미방문</label>
                </div>
                <div className="RadioBoxOne">
                    <input
                        className="radio"
                        type="radio"
                        id="visit2"
                        name="visitRadio"
                        value={visitValue}
                        checked={visitValue === 2}
                        onChange={(e) => setVisitValue(2)}
                    />
                    <label htmlFor="visit2">방문완료</label>
                </div>

                <div>
                    <button
                        className="ResvBtn"
                        onClick={() => {
                            setRegisterPopup(!registerPopup);
                        }}
                    >
                        예약현황
                    </button>
                </div>
            </div>
        </div>
    );
}
export function BoxResvResult({ content, registerPopup, setRegisterPopup }) {
    return (
        <div className="BoxInfo">
            <div className="title">방문예약</div>
            <div className="sub ResvDate">
                {content}
                <div>
                    <button
                        className="ResvBtn"
                        onClick={() => {
                            setRegisterPopup(!registerPopup);
                        }}
                    >
                        예약현황
                    </button>
                </div>
            </div>
        </div>
    );
}

export function BoxTotalPrice({ Info }) {
    const totalPrice = Info.reduce(
        (sum, item) => sum + parseInt(item.price),
        0
    );
    return (
        <div className="BoxInfo">
            <div className="title">견적금액</div>
            <div className="sub">
                <h1>
                    {(totalPrice ? parseInt(totalPrice) : 0).toLocaleString()}원
                </h1>
                <div
                    style={{ display: "flex", gap: "10px", marginLeft: "10px" }}
                >
                    {Info.map((x, i) => (
                        <div style={{ display: "flex" }} key={i}>
                            [<span>{x.title}</span> &nbsp; : &nbsp;
                            <span>
                                {(x.price
                                    ? parseInt(x.price)
                                    : 0
                                ).toLocaleString()}
                            </span>
                            원 ]
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export function BoxArrayContent({ title, array }) {
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                {array.map((x, i) => (
                    <div
                        style={{
                            padding: "0px 3px",
                        }}
                        key={i}
                    >
                        <span>[{x}]</span>
                        {/* <span>{x.title}</span> &nbsp; : &nbsp;
                        <span>{x.date}</span>
                        <span>({x.day})</span>
                        {i !== array.length - 1 ? <span>,</span> : null} */}
                    </div>
                ))}
            </div>
        </div>
    );
}
export function BoxArrayContent2({ title, array }) {
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                {array.map((x, i) => (
                    <div
                        style={{
                            padding: "0px 3px",
                        }}
                        key={i}
                    >
                        <span>
                            {x.title} : {x.price}
                        </span>
                        {/* <span>{x.title}</span> &nbsp; : &nbsp;
                        <span>{x.date}</span>
                        <span>({x.day})</span>
                        {i !== array.length - 1 ? <span>,</span> : null} */}
                    </div>
                ))}
            </div>
        </div>
    );
}

export function BoxOneBtn({ title, submit }) {
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                {submit ? (
                    <>
                        <div className="depositBox FlexCenter">제출</div>
                        <div
                            style={{
                                display: "flex",
                                marginLeft: "20px",
                                gap: "10px",
                            }}
                        ></div>
                    </>
                ) : (
                    <div className="depositBox  FlexCenter">미제출</div>
                )}
            </div>
        </div>
    );
}

export function BoxButtons({
    title,
    IsSend = false,
    confirm = false,
    gbtn = false,
    bbtn = false,
    bbtnNum = 0,
    // sbtn = false,
    bbtnClk = () => {},
    OpenUrl = () => {},
}) {
    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub" style={{ gap: "20px" }}>
                {IsSend && (
                    <div
                        className="FlexCenter"
                        style={{
                            background: "#FDFD96",
                            width: "90px",
                            height: "30px",
                        }}
                    >
                        {IsSend}
                    </div>
                )}
                {gbtn && (
                    <button
                        onClick={OpenUrl}
                        style={{
                            width: "90px",
                            height: "30px",
                            color: "white",
                            backgroundColor: "#A8E6CF",
                        }}
                    >
                        {confirm ? "확인서보기" : "보기"}
                    </button>
                )}
                {bbtn && (
                    <button
                        style={{
                            width: "110px",
                            height: "30px",
                            color: "white",
                            backgroundColor: "#A2D5F2",
                        }}
                        onClick={bbtnClk}
                    >
                        문자 발송 &nbsp;{bbtnNum}
                    </button>
                )}
                {/* {sbtn && (
                    <button
                        style={{
                            width: "110px",
                            height: "30px",
                            color: "white",
                            backgroundColor: "#6699CC",
                        }}
                    >
                        스케쥴표
                    </button>
                )} */}
            </div>
        </div>
    );
}

export function BoxDeposComplete({
    sendMailClk,
    sendMail,
    deposit = false,
    price = "",
    name = "",
}) {
    return (
        <div className="BoxInfo">
            <div className="title">계약금 입금여부</div>
            <div className="sub" style={{ gap: "10px" }}>
                <div
                    style={{
                        display: "flex",
                        marginLeft: "10px",
                        gap: "10px",
                    }}
                >
                    <span>{price.toLocaleString()}</span>원<span>{name}</span>
                </div>
                <div className="depositBox FlexCenter">
                    {deposit ? "입금완료" : "미입금"}
                </div>
                <button
                    style={{
                        width: "110px",
                        height: "30px",
                        color: "white",
                        backgroundColor: "#A2D5F2",
                    }}
                    onClick={sendMailClk}
                >
                    문자 발송 &nbsp;{sendMail}
                </button>
            </div>
        </div>
    );
}
export function BoxDepositInput({
    sendMail,
    sendMailClk = () => {},
    deposit,
    setDeposit,
    price,
    setPrice,
    name,
    setName,
}) {
    const OnChangeRadio = (e) => {
        setDeposit(parseInt(e.target.value));
    };
    return (
        <div className="BoxInfo">
            <div className="title">계약금 입금여부</div>
            <div className="sub" style={{ gap: "20px" }}>
                <button
                    style={{
                        width: "110px",
                        height: "30px",
                        color: "white",
                        backgroundColor: "#A2D5F2",
                    }}
                    onClick={sendMailClk}
                >
                    문자 발송 &nbsp;{sendMail}
                </button>

                <div className="RadioBoxOne">
                    <input
                        checked={deposit === 0}
                        onChange={OnChangeRadio}
                        className="radio"
                        type="radio"
                        id="deposit1"
                        name="deposit"
                        value={0}
                    />
                    <label htmlFor="deposit1">미입금</label>
                </div>
                <div className="RadioBoxOne">
                    <input
                        checked={deposit === 1}
                        onChange={OnChangeRadio}
                        className="radio"
                        type="radio"
                        id="deposit2"
                        name="deposit"
                        value={1}
                    />
                    <label htmlFor="deposit2">입금완료</label>
                </div>
                <div>
                    <input
                        value={Number(price || 0).toLocaleString()}
                        onChange={(e) => {
                            let val = e.target.value;
                            val = e.target.value.replace(/[^0-9]/g, "");
                            setPrice(val);
                        }}
                        style={{ height: "40px" }}
                        type="text"
                        placeholder="입금액"
                    />
                    <span>원</span>
                </div>
                <div>
                    <input
                        value={name || ""}
                        onChange={(e) => setName(e.target.value)}
                        style={{ height: "40px" }}
                        type="text"
                        placeholder="입금자명"
                    />
                </div>
            </div>
        </div>
    );
}

export function BoxProgress({
    Radioes,
    manager = "",
    isAs = false,
    RadioName = "radioGroup",
    value,
    setValue,
    onClk,
}) {
    const OnChangeRadio = (e) => {
        setValue(parseInt(e.target.value));
    };
    return (
        <div className="BoxProgressInfo">
            <div className="title">{isAs ? "A/S상태" : "진행 상태"}</div>
            <div className="progress">
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        marginLeft: "10px",
                    }}
                >
                    {Radioes.map((x, i) => (
                        <div key={i} className="RadioBoxOne">
                            <input
                                checked={value === x.value}
                                className="radio"
                                type="radio"
                                id={x.label}
                                name={RadioName}
                                value={x.value}
                                onChange={OnChangeRadio}
                            />
                            <label htmlFor={x.label}>{x.label}</label>
                        </div>
                    ))}
                    <button onClick={onClk}>저장</button>
                </div>

                {manager && <div> 담당자 : {manager}</div>}
            </div>
        </div>
    );
}
export function BoxSelectProgress({ Dropdowns = [], manager = "" }) {
    return (
        <div className="BoxProgressInfo">
            <div className="title">진행 상태</div>
            <div className="progress">
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        marginLeft: "10px",
                    }}
                >
                    <select
                        style={{
                            width: "15%",
                        }}
                        // value={selectedValue}
                        // onChange={handleDropdownChange}
                    >
                        {Dropdowns.map((x, i) => (
                            <option key={i} value={x.value}>
                                {x.text}
                            </option>
                        ))}
                    </select>
                    <select
                        style={{
                            width: "15%",
                        }}
                        // value={selectedValue}
                        // onChange={handleDropdownChange}
                    >
                        {Dropdowns.map((x, i) => (
                            <option key={i} value={x.value}>
                                {x.text}
                            </option>
                        ))}
                    </select>

                    <button>저장</button>
                </div>

                {manager && <div> 담당자 : {manager}</div>}
            </div>
        </div>
    );
}

export function BoxBalancePay({
    value = "",
    inputWidth = 10,
    downPay,
    balancePay,
    readOnly = false,
}) {
    return (
        <div className="BoxInfo">
            <div className="title">잔금</div>
            <div className="sub">
                <input
                    value={Number(balancePay || 0).toLocaleString() || "0"}
                    style={{
                        width: `${inputWidth}%`,
                        textAlign: "right",
                        padding: "10px",
                    }}
                    type="text"
                    // placeholder={placeholder}
                    readOnly={readOnly}
                />
                <p>원</p>
                <p style={{ marginLeft: "20px" }}>
                    (계약금 : {(downPay || 0).toLocaleString()}원)
                </p>
            </div>
        </div>
    );
}

export function BoxDepositChk({ deposit }) {
    return (
        <div className="BoxInfo">
            <div className="title">입금일</div>
            <div className="sub">
                {deposit !== null ? (
                    <>
                        <div className="depositBox FlexCenter">입금완료</div>
                        <span style={{ marginLeft: "15px" }}>
                            {deposit
                                ? `${moment(deposit).format("YYYY-MM-DD")}(${
                                      consts.dayText[moment(deposit).day()]
                                  })`
                                : "-"}
                        </span>
                    </>
                ) : (
                    <div
                        style={{ background: "orange" }}
                        className="depositBox FlexCenter"
                    >
                        미입금
                    </div>
                )}
            </div>
        </div>
    );
}

export function BoxDefect({
    value,
    setValue,
    inputWidth = 80,
    readOnly = false,
    chkbtn,
    setChkbtn,
    id,
}) {
    return (
        <div className="BoxInfo" style={{ height: "120px" }}>
            <div className="title">하자</div>
            <div className="sub">
                <div className="ChkBoxOne">
                    <input
                        type="checkbox"
                        id={id}
                        name="defeactChk"
                        value={chkbtn}
                        checked={chkbtn}
                        onChange={(e) => setChkbtn(e.target.checked)}
                    />
                    <label htmlFor={id}>하자</label>
                </div>
                <textarea
                    style={{
                        width: "95%",
                        height: "100px",
                        resize: "none",
                        border: "1px solid #ddd",
                        padding: "10px",
                    }}
                    value={value || ""}
                    onChange={(e) => setValue(e.target.value)}
                    readOnly={readOnly}
                />
            </div>
        </div>
    );
}

export function BoxASRadio() {
    return (
        <div style={{ height: "120px" }} className="BoxInfo">
            <div className="title">A/S상태</div>
            <div className="ASRadioSub">
                <div className="RadioWrap">
                    <div className="RadioBoxOne">
                        <input
                            className="radio"
                            type="radio"
                            id="defeact1"
                            name="defeact"
                            value="진행"
                        />
                        <label htmlFor="defeact1">진행</label>
                    </div>
                    <div className="RadioBoxOne">
                        <input
                            className="radio"
                            type="radio"
                            id="defeact2"
                            name="defeact"
                            value="완료"
                        />
                        <label htmlFor="defeact2">완료</label>
                    </div>
                    <div className="RadioBoxOne">
                        <input
                            className="radio"
                            type="radio"
                            id="defeact3"
                            name="defeact"
                            value="완료"
                        />
                        <label htmlFor="defeact3">종료</label>
                    </div>
                </div>
                <div className="radioExplanation">
                    <ul>
                        <li>하자 : 하자처리 진행중</li>
                        <li>완료 : 하자처리 완료</li>
                        <li>종료 : 하자문의 왔으나 하자가 아님</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "align",
    "color",
    "background",
    "size",
    "h1",
];
export function BoxImageEditer({ value, setValue }) {
    const quillRef = useRef(null);
    // const [file, setFile] = useState(null);
    // const imageApi = usePostData(ApiData.EditorImageUpload);
    // const deleteImageApi = usePostData(ApiData.EditorImageDelete); // 이미지 삭제 API
    // const imageSendApi = () => {
    //     let sender = {
    //         material_img: file,
    //     };
    //     console.log("sender", sender);
    //     imageApi
    //         .mutateAsync(sender)
    //         .then(({ data }) => {
    //             console.log("data", consts.ImgState + data);
    //             const imgUrl = consts.ImgState + data;
    //             const editor = quillRef.current.getEditor();
    //             const range = editor.getSelection();
    //             editor.insertEmbed(range.index, "image", imgUrl);
    //             editor.setSelection(range.index + 1);
    //         })
    //         .catch((err) => {});
    // };
    // const handleChange = async (value) => {
    //     var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
    //     let imgVal = "";

    //     if (value.name.toLowerCase().match(reg)) {
    //         imgVal = await resizeFile(value, 500, 500);
    //         // console.log('base64 ==> ', imgVal);
    //         return imgVal;
    //     } else {
    //         // console.log("이미지 파일만 업로드 가능합니다,");
    //     }
    // };
    // const imageHandler = () => {
    //     const input = document.createElement("input");
    //     input.setAttribute("type", "file");
    //     input.setAttribute("accept", "image/*");
    //     input.onchange = (event) => {
    //         const file = event.target.files[0];
    //         const reader = new FileReader();
    //         reader.onloadend = async () => {
    //             const resizedImageUrl = await handleChange(file);
    //             setFile(resizedImageUrl);
    //         };
    //         reader.readAsDataURL(file);
    //     };
    //     input.click();
    // };

    // useEffect(() => {
    //     if (file) {
    //         imageSendApi();
    //     }
    // }, [file]);

    const modules = useMemo(
        () => ({
            toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike"],
                [{ align: [] }],

                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }],

                ["image"],
                [{ color: [] }, { background: [] }],

                ["clean"],
            ],
            ImageResize: {
                parchment: Quill.import("parchment"),
            },
            clipboard: {
                matchVisual: false,
            },
        }),
        []
    );

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "color",
        "background",
        "align",
        "link",
        "image",
        "float",
        "height",
        "width",
    ];

    // useEffect(() => {
    //     const editor = quillRef.current.getEditor();
    //     editor.on("text-change", handleTextChange);

    //     return () => {
    //         editor.off("text-change", handleTextChange);
    //     };
    // }, []);

    return (
        <div className="BoxInfo" style={{ height: "350px" }}>
            <div
                className="title"
                style={{ alignItems: "baseline", padding: "10px" }}
            >
                자재 설명
            </div>
            <div
                className="sub"
                style={{ alignItems: "baseline", paddingTop: "10px" }}
            >
                <ReactQuill
                    ref={quillRef}
                    onChange={setValue}
                    style={{ height: "290px", width: "99%" }}
                    // className={styles.quill}
                    modules={modules}
                    formats={formats}
                    value={value}
                    placeholder={
                        "후원받고자 하는 동물의 자세한 정보를 입력해주세요!"
                    }
                    theme="snow"
                />
            </div>
        </div>
    );
}

export function BoxEditer({ value, setValue }) {
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ size: ["small", false, "large", "huge"] }],
                    [{ align: [] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [
                        {
                            color: [],
                        },
                        { background: [] },
                    ],
                ],
            },
        };
    }, []);
    const editor = useRef();

    return (
        <div className="BoxInfo" style={{ height: "350px" }}>
            <div
                className="title"
                style={{ alignItems: "baseline", padding: "10px" }}
            >
                답변
            </div>
            <div
                className="sub"
                style={{ alignItems: "baseline", paddingTop: "10px" }}
            >
                <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    onChange={setValue}
                    value={value}
                    style={{ height: "290px", width: "99%" }}
                />
                {/* <Editor
                    ref={editor}
                    initialValue={desc}
                    placeholder="내용을 입력해주세요."
                    previewStyle="vertical" // 미리보기 스타일 지정
                    height="700px" // 에디터 창 높이
                    initialEditType="wysiwyg" // 초기 입력모드 설정(디폴트 markdown)
                    toolbarItems={[
                        // 툴바 옵션 설정
                        ["heading", "bold", "italic", "strike"],
                        ["hr", "quote"],
                        ["ul", "ol", "task", "indent", "outdent"],
                        ["table", "image", "link"],
                    ]}
                /> */}
            </div>
        </div>
    );
}

export function BoxInputFile() {
    return (
        <div style={{ height: "100%" }} className="BoxInfo">
            <div className="title">이미지</div>
            <div style={{ padding: "10px" }} className="sub"></div>
        </div>
    );
}

export function BoxImgUpLoad({
    title,
    ImgFile,
    setImgFile,
    altImg,
    ImgFileType,
    setImgFileType,
    deletePop,
    setDeletePop,
    index,
    idx,
    setDeleteClk,
}) {
    const DeleteThing = (index, idx) => {
        setDeletePop(!deletePop);
        setDeleteClk({ index: index, DelIdx: idx });
    };

    return (
        <div
            className="ImgInputWrap"
            style={{ border: "none", borderBottom: "1px solid #ddd" }}
        >
            <div className="Imgtitle">{title}</div>
            <div
                className="sub"
                style={{ display: "flex", alignItems: "center" }}
            >
                <InputFile
                    type="file"
                    name={"img_url"}
                    value={
                        ImgFile &&
                        (ImgFile.includes(altImg)
                            ? `${consts.ImgState}${ImgFile}`
                            : `${ImgFile}`)
                    }
                    alt={altImg}
                    onChange={(idx, name, result) => {
                        setImgFile(result);
                    }}
                    valid="image"
                    // style={{ flex: 1.68 }}
                    imgStyle={{
                        width: "40%",
                    }}
                    setFileType={(type) => {
                        setImgFileType(type);
                    }}
                />
                <button
                    type="button"
                    className="trash_btn_red"
                    onClick={() => DeleteThing(index, idx)}
                ></button>
            </div>
        </div>
    );
}
export function BoxDropdownTime({
    title,
    width = "20",
    value,
    setValue,
    directInput,
    setDirectInput,
}) {
    let times = [{ text: "직접입력", value: "--:--" }];
    for (let i = 8; i <= 23; i++) {
        for (let j = 0; j < 60; j += 30) {
            let hour = i < 10 ? `0${i}` : `${i}`;
            let minute = j === 0 ? "00" : `${j}`;
            times.push({
                text: `${hour}:${minute}`,
                value: `${hour}:${minute}`,
            });
        }
    }

    const handleInputChange = (e) => {
        setDirectInput(e.target.value);
    };
    const handleChange = (event) => {
        setValue(event.target.value); // 선택한 값 업데이트
    };

    useEffect(() => {
        if (times.map((v) => v.value).includes(value) === false) {
            setValue("--:--"); //직접입력 셀렉트
            setDirectInput(value); // 인풋박스 값
        }
    }, [value]);

    return (
        <div className="BoxInfo">
            <div className="title">{title}</div>
            <div className="sub">
                <select
                    style={{ width: `${width}%` }}
                    value={value || "--:--"}
                    onChange={handleChange}
                >
                    {times.map((x, i) => (
                        <option key={i} value={x.value}>
                            {x.text}
                        </option>
                    ))}
                </select>

                {times.map((v) => v.value).includes(value) === false ||
                value == "--:--" ? (
                    <input
                        style={{ width: `20%` }}
                        type="time"
                        value={directInput || ""}
                        placeholder={"00:00 형식으로 입력"}
                        onChange={handleInputChange}
                    />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
