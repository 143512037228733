import { useEffect, useState } from "react";
import {
    BoxAdressInput,
    BoxMemoInput,
    BoxTextInput,
} from "../../../components/BoxInfo";
import InputSelect from "../../../components/InputSelect";
import Input from "../../../components/Input";
import routes from "../../../libs/routes";
import consts from "../../../libs/consts";
import ApiData from "../../../libs/api";
import { toast } from "react-toastify";
import { set } from "lodash";
import { useGetData, usePostData } from "../../../utils/service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ro } from "date-fns/locale";
import { PopupComp } from "../../../components/PopUp";
import useLoadingStore from "../../../zustand/Loading";
import Pagination from "react-js-pagination";

function AgencyMaterialDetail() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const agencyMateDetailIn = usePostData(ApiData.agencyMateDetailIn);
    const agencyDetail = usePostData(ApiData.agencyDetail);
    // const agencyMateDetail = usePostData(ApiData.agencyMateDetail);
    const agencySelectThings = usePostData(
        ApiData.agencyMaterialDetailListOption
    );
    const agencyMateDelete = usePostData(ApiData.agencyMateDelete);
    const agencyMateIn = usePostData(ApiData.agencyMateIn);
    const agencyMatePrice = usePostData(ApiData.agencyMatePrice);
    const sigongPopupFilter = usePostData(ApiData.sigongPopupFilter);
    // const agencyMateList = usePostData(ApiData.agencyMateList);
    const [agencyValue, setAgencyValue] = useState([]);
    const [agencyMateListData, setAgencyMateListData] = useState([]);
    const [companyList, setCompanyList] = useState(["제조사"]);
    const [firstCategory, setFirstCategory] = useState(["1차분류"]);
    const [secondCategory, setSecondCategory] = useState(["2차분류"]);
    const [priceCompanyList, setPriceCompanyList] = useState([]);
    const [priceSelectCompany, setPriceSelectCompany] = useState("제조사");
    const [selectCompany, setSelectCompany] = useState("");
    const [priceFirstCategory, setPriceFirstCategory] = useState([]);
    const [priceSelectFirstCategory, setPriceSelectFirstCategory] =
        useState("1차분류");
    const [priceSelectSecondCategory, setPriceSelectSecondCategory] =
        useState("2차분류");
    const [outCompanyList, setOutCompanyList] = useState(["제조사"]);
    const [outFirstCategory, setOutFirstCategory] = useState(["1차분류"]);
    const [outSecondCategory, setOutSecondCategory] = useState(["2차분류"]);
    const [outSearchVal, setOutSearchVal] = useState("");
    const [outMateList, setOutMateList] = useState([]);
    const [selectFirstCategory, setSelectFirstCategory] = useState("");
    const [priceAgencyList, setPriceAgencyList] = useState([]);
    const [innerType, setInnerType] = useState(1);
    const [innerSelectCompany, setInnerSelectCompany] = useState("");
    const [innerSelectFirstCategory, setInnerSelectFirstCategory] =
        useState("");
    const [innerSelectSecondCategory, setInnerSelectSecondCategory] =
        useState("2차분류");
    const [step, setStep] = useState(1);

    const [deletePop, setDeletePop] = useState(false);
    const [deleteThing, setDeleteThing] = useState(0);
    const [agencyName, setAgencyName] = useState("");
    const [agencyAddress, setAgencyAddress] = useState("");
    const [agencyAddressDetail, setAgencyAddressDetail] = useState("");
    const [agencyManager, setAgencyManager] = useState("");
    const [agencyPhone, setAgencyPhone] = useState("");
    const [agencyContact, setAgencyContact] = useState("");
    const [agencyBank, setAgencyBank] = useState("");
    const [agencyBankName, setAgencyBankName] = useState("");
    const [agencyDepositor, setAgencyDepositor] = useState("");
    const [agencyMemo, setAgencyMemo] = useState("");

    const [idx, setIdx] = useState(0);

    const [OpenPop, setOpenPop] = useState(false);
    const [priceChange, setPriceChange] = useState([]);
    const [filterPopupList, setFilterPopupList] = useState([]);
    const [materialSearchVal, setMaterialSearchVal] = useState("");

    const [innerPage, setInnerPage] = useState(1);
    const [agencyListCount, setAgencyListCount] = useState(0);
    const handlePageChange = (pageNumber) => {
        setInnerPage(pageNumber);
    };
    const [outterPage, setOutterPAge] = useState(1);
    const [agencyOutListCount, setAgencyOutListCount] = useState(0);
    const handleOutPageChange = (pageNumber) => {
        setInnerPage(pageNumber);
    };
    // const { loadingStart, loadingEnd } = useLoadingStore();

    const handleChange = (e) => {
        if (e.target.checked) {
            setAgencyValue([...agencyValue, parseInt(e.target.value)]);
        } else {
            setAgencyValue(
                agencyValue.filter((value) => value !== e.target.value)
            );
        }
    };
    const InfoSave = () => {
        if (agencyName === "") {
            toast.error("대리점명을 입력해주세요.");
            return;
        } else if (agencyAddress === "") {
            toast.error("주소를 입력해주세요.");
            return;
        } else if (agencyAddressDetail === "") {
            toast.error("상세주소를 입력해주세요.");
            return;
        } else if (agencyManager === "") {
            toast.error("담당자를 입력해주세요.");
            return;
        }

        if (agencyPhone === "") {
            toast.error("전화번호를 입력해주세요.");
            return;
        } else if (agencyPhone.includes("-")) {
            setAgencyPhone(agencyPhone.replace(/-/g, ""));
        } else if (agencyPhone.includes(" ")) {
            setAgencyPhone(agencyPhone.replace(/ /g, ""));
        }
        if (agencyContact === "") {
            toast.error("연락처를 입력해주세요.");
            return;
        } else if (agencyContact.includes("-")) {
            setAgencyContact(agencyContact.replace(/-/g, ""));
        } else if (agencyContact.includes(" ")) {
            setAgencyContact(agencyContact.replace(/ /g, ""));
        }
        if (agencyBankName === "") {
            toast.error("은행명을 입력해주세요.");
            return;
        }
        if (agencyBank === "") {
            toast.error("계좌번호를 입력해주세요.");
            return;
        } else if (agencyBank.includes("-")) {
            setAgencyPhone(agencyBank.replace(/-/g, ""));
        } else if (agencyBank.includes(" ")) {
            setAgencyPhone(agencyBank.replace(/ /g, ""));
        } else if (!consts.bank.test(agencyBank)) {
            toast.error("계좌번호를 제대로 입력해주세요.");
            return;
        }
        if (agencyDepositor === "") {
            toast.error("예금주를 입력해주세요.");
            return;
        }
        let sender = {
            agency_name: agencyName,
            address: agencyAddress,
            address_detail: agencyAddressDetail,
            manager_name: agencyManager,
            agency_phone: agencyPhone,
            manager_phone: agencyContact,
            agency_bankname: agencyBankName,
            agency_banknum: agencyBank,
            agency_depositor: agencyDepositor,
            agency_memo: agencyMemo,
        };

        if (index) {
            sender.agencyIdx = index;
        }

        agencyMateDetailIn
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("저장되었습니다.");
                if (!index) {
                    navigate(`${routes.material7_1}?index=${data.idx}`);
                }
            })
            .catch((e) => {
                //console.log(e);
                toast.error(e.response.data);
            });
    };
    const materialListSave = () => {
        let sender = {
            agencyIdx: index,
            agencyArr: agencyValue,
        };
        //console.log("sender", sender);
        agencyMateIn
            .mutateAsync(sender)
            .then(({ data }) => {
                setOpenPop(!OpenPop);
                setInnerType(1);
                agencySelectThingsApi();
                setInnerSelectCompany("제조사");
                setSelectFirstCategory("1차분류");
                setAgencyValue([]);

                toast.success("저장되었습니다.");
            })
            .catch((e) => {
                //console.log(e);
                toast.error(e.response.data);
            });
    };
    const ClosePop = () => {
        setMaterialSearchVal("");
        setOpenPop(!OpenPop);
    };
    const deleteData = () => {
        let sender = { agencyIdx: index, deleteIdx: deleteThing };
        agencyMateDelete
            .mutateAsync(sender)
            .then(({ data }) => {
                agencySelectThingsApi();
                toast.success("삭제되었습니다.");
            })
            .catch((e) => {
                //console.log(e);
                toast.error(e.response.data);
            });
    };
    const deleteClk = (i) => {
        setDeleteThing(i);
        setDeletePop(!deletePop);
    };
    const priceUpdate = () => {
        if (!index) {
            toast.error("대리점을 먼저 저장해주세요.");
            return;
        }
        //console.log("priceChange", priceChange);
        if (priceChange.length > 0) {
            let sender = {
                agencyIdx: index,
                agencyArr: priceChange.map((c) => {
                    return {
                        idx: c.idx,
                        price: priceAgencyList.find((x) => x.idx === c.idx)
                            .order_price,
                    };
                }),
            };
            agencyMatePrice
                .mutateAsync(sender)
                .then(({ data }) => {
                    toast.success("값이 저장되었습니다.");
                })
                .catch((e) => {
                    //console.log(e);
                    toast.error(e.response.data);
                });
        } else {
            toast.error("변경된 값이 없습니다.");
            return;
        }
    };

    const AddMaterial = () => {
        let sender = {
            material_company:
                innerSelectCompany === "제조사" ? "" : innerSelectCompany,
            material_class01:
                innerSelectFirstCategory === "1차분류"
                    ? ""
                    : innerSelectFirstCategory,
            material_class02:
                innerSelectSecondCategory === "2차분류"
                    ? ""
                    : innerSelectSecondCategory,
            material_type: step,
            searchVal: materialSearchVal,
            page: innerPage - 1,
        };
        if (innerSelectCompany === "제조사") {
            sender.material_class01 = "";
            setInnerSelectFirstCategory("1차분류");
        }
        if (innerSelectFirstCategory === "1차분류") {
            sender.material_class01 = "";
            setInnerSelectSecondCategory("2차분류");
        }
        sigongPopupFilter
            .mutateAsync(sender)
            .then(({ data }) => {
                setAgencyListCount(data?.list?.count);
                setAgencyMateListData(data?.list?.rows);
                setFilterPopupList(data?.filter);
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };
    // const agencyMateDetailApi = () => {
    //     let sender = {
    //         material_type: step,
    //         agencyIdx: index,
    //         company: priceSelectCompany === "제조사" ? "" : priceSelectCompany,
    //         class1:
    //             priceSelectFirstCategory === "1차분류"
    //                 ? ""
    //                 : priceSelectFirstCategory,
    //     };

    //     agencyMateDetail
    //         .mutateAsync(sender)
    //         .then(({ data }) => {
    //             //console.log(data);
    //             setPriceCompanyList(["제조사", ...data.company]);
    //             setPriceFirstCategory(["1차분류", ...data.class01]);
    //             setPriceAgencyList(data.mateList);
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //             toast.error(e.response.data);
    //         });
    // };
    const updateDataList = (i, key, value) => {
        setPriceChange((prevState) => {
            if (!prevState.some((item) => item.idx === i)) {
                return [...prevState, { idx: parseInt(i) }];
            }
            return prevState;
        });
        setPriceAgencyList(
            priceAgencyList?.map((item, index) =>
                item.idx === i ? { ...item, [key]: value } : item
            )
        );
    };

    const agencySelectThingsApi = () => {
        let sender = {
            agency_idx: index,
            material_type: step,
            material_company:
                priceSelectCompany === "제조사" ? "" : priceSelectCompany,
            material_class01:
                priceSelectFirstCategory === "1차분류"
                    ? ""
                    : priceSelectFirstCategory,
            material_class02:
                priceSelectSecondCategory === "2차분류"
                    ? ""
                    : priceSelectSecondCategory,
            searchVal: outSearchVal,
            page: outterPage - 1,
        };
        // console.log("sender!@#!#!#!@#!#", sender);
        agencySelectThings
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log("DDDDaAATATA", data);
                setOutMateList(data.filter);
                setPriceAgencyList(data.list.rows);
                setAgencyOutListCount(data.list.count);
            })
            .catch((e) => {
                // console.log(e);
                toast.error(e.response.data);
            });
    };

    useEffect(() => {
        AddMaterial();
    }, [
        innerType,
        innerSelectCompany,
        innerSelectFirstCategory,
        innerSelectSecondCategory,
        materialSearchVal,
        innerPage,
        OpenPop,
    ]);
    useEffect(() => {
        setInnerType(1);
        setInnerSelectCompany("제조사");
        setInnerSelectFirstCategory("1차분류");
    }, [OpenPop]);
    useEffect(() => {
        filterPopupList?.map((item, index) => {
            setCompanyList((prev) =>
                prev.includes(item.material_company)
                    ? prev
                    : [...prev, item.material_company]
            );
        });

        filterPopupList?.map((item, index) => {
            setFirstCategory((prev) =>
                prev.includes(item.material_class01)
                    ? prev
                    : [...prev, item.material_class01]
            );
        });
        filterPopupList?.map((item, index) => {
            setSecondCategory((prev) =>
                prev.includes(item.material_class02)
                    ? prev
                    : [...prev, item.material_class02]
            );
        });

        return () => {
            setCompanyList(["제조사"]);
            setFirstCategory(["1차분류"]);
            setSecondCategory(["2차분류"]);
        };
    }, [filterPopupList]);
    useEffect(() => {
        setIdx(index);
    }, [index]);

    useEffect(() => {
        if (index) {
            agencyDetail
                .mutateAsync({ agencyIdx: index })
                .then(({ data }) => {
                    setAgencyName(data.agency_name);
                    setAgencyAddress(data.address);
                    setAgencyAddressDetail(data.address_detail);
                    setAgencyManager(data.manager_name);
                    setAgencyPhone(data.agency_phone);
                    setAgencyContact(data.manager_phone);
                    setAgencyBankName(data.agency_bankname);
                    setAgencyBank(data.agency_banknum);
                    setAgencyDepositor(data.agency_depositor);
                    setAgencyMemo(data.agency_memo);
                })
                .catch((e) => {
                    console.log(e);
                    toast.error(e.response.data);
                });
        }
    }, [index]);
    useEffect(() => {
        if (OpenPop) {
            document.getElementsByTagName("HTML")[0]["style"]["overflow-y"] =
                "hidden";
        }

        return () => {
            document.getElementsByTagName("HTML")[0]["style"]["overflow-y"] =
                "scroll";
        };
    }, [OpenPop]);
    useEffect(() => {
        agencySelectThingsApi();
    }, [
        step,
        priceSelectCompany,
        priceSelectFirstCategory,
        priceSelectSecondCategory,
        outSearchVal,
        outterPage,
    ]);
    useEffect(() => {
        outMateList?.map((item, index) => {
            setOutCompanyList((prev) =>
                prev.includes(item.material_company)
                    ? prev
                    : [...prev, item.material_company]
            );
        });
        outMateList?.map((item, index) => {
            setOutFirstCategory((prev) =>
                prev.includes(item.material_class01)
                    ? prev
                    : [...prev, item.material_class01]
            );
        });
        outMateList?.map((item, index) => {
            setOutSecondCategory((prev) =>
                prev.includes(item.material_class02)
                    ? prev
                    : [...prev, item.material_class02]
            );
        });
        return () => {
            setOutCompanyList(["제조사"]);
            setOutFirstCategory(["1차분류"]);
            setOutSecondCategory(["2차분류"]);
        };
    }, [outMateList]);
    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteData}
            />
            {OpenPop && (
                <div style={{ overflow: "auto" }} className={`popup show`}>
                    <div
                        className="popup_container"
                        style={{
                            width: "1000px",
                            minHeight: "600px",
                            overflow: "hidden",

                            justifyContent: "flex-start",
                        }}
                    >
                        <div
                            className="popup_title"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0 20px",
                            }}
                        >
                            <p>자재 목록</p>
                            <div className="exit_btn" onClick={ClosePop}></div>
                        </div>
                        <div style={{ padding: "10px" }} className="popup_msg">
                            <div className="SelectBoxWrap">
                                <select
                                    // style={{ width: `${width}%` }}
                                    value={innerSelectCompany}
                                    onChange={(e) => {
                                        setInnerSelectCompany(e.target.value);
                                    }}
                                >
                                    {companyList?.map((x, i) => (
                                        <option key={i} value={x}>
                                            {x}
                                        </option>
                                    ))}
                                </select>
                                {innerSelectCompany !== "제조사" ? (
                                    <select
                                        // style={{ width: `${width}%` }}
                                        value={innerSelectFirstCategory}
                                        onChange={(e) => {
                                            setInnerSelectFirstCategory(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {firstCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}
                                {innerSelectFirstCategory !== "1차분류" ? (
                                    <select
                                        // style={{ width: `${width}%` }}
                                        value={innerSelectSecondCategory}
                                        onChange={(e) => {
                                            setInnerSelectSecondCategory(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {secondCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}
                                <Input
                                    className="input_text"
                                    type="text"
                                    placeholder="모델번호, 제품명"
                                    name="stx"
                                    value={materialSearchVal}
                                    setValue={setMaterialSearchVal}
                                    // style={{ width: "20%" }}
                                />
                                <button
                                    className="btn btn9"
                                    onClick={materialListSave}
                                >
                                    저장
                                </button>
                            </div>
                            <div
                                style={{ marginTop: "20px" }}
                                className="table_scroll"
                            >
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="FlexCenter">
                                                    <div
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            border: "1px solid #ddd",
                                                        }}
                                                    ></div>
                                                </div>
                                            </th>
                                            <th>종류</th>
                                            <th>제조사</th>
                                            <th>1차분류</th>
                                            <th>2차분류</th>
                                            <th>모델번호</th>
                                            <th>제품명</th>
                                            <th>제품이미지</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agencyMateListData?.map((x, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <input
                                                            checked={agencyValue
                                                                .map((c) =>
                                                                    parseInt(c)
                                                                )
                                                                .includes(
                                                                    x.idx
                                                                )}
                                                            value={x.idx}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            id={x.idx}
                                                            className="OneChkBox"
                                                            type="checkbox"
                                                        />
                                                        <label
                                                            htmlFor={x.idx}
                                                        />
                                                    </td>
                                                    <td>
                                                        {x.material_type === 1
                                                            ? "벽지"
                                                            : x.material_type ===
                                                              2
                                                            ? "장판"
                                                            : x.material_type ===
                                                              3
                                                            ? "마루"
                                                            : x.material_type ===
                                                              4
                                                            ? "걸레받이"
                                                            : x.material_type ===
                                                              5
                                                            ? "부자재"
                                                            : null}
                                                    </td>
                                                    <td>
                                                        {x.material_company}
                                                    </td>
                                                    <td>
                                                        {x.material_class01}
                                                    </td>
                                                    <td>
                                                        {x.material_class02}
                                                    </td>
                                                    <td>{x.material_number}</td>
                                                    <td>{x.material_name}</td>
                                                    <td>
                                                        {x.material_img ? (
                                                            <img
                                                                style={{
                                                                    height: "50px",
                                                                }}
                                                                src={`${consts.ImgState}${x.material_img}`}
                                                            />
                                                        ) : (
                                                            <p>이미지 없음</p>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={innerPage}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={agencyListCount || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                    </div>
                </div>
            )}
            <div className="content ">
                <p className="title">대리점 상세보기</p>
                <div className="BorderBox">
                    <div className="title">
                        <h1>기본정보</h1>
                    </div>
                    <div className="contentWrap">
                        <BoxTextInput
                            title="대리점명"
                            inputWidth={20}
                            value={agencyName}
                            setValue={setAgencyName}
                        />
                        <BoxAdressInput
                            value={agencyAddress}
                            setValue={setAgencyAddress}
                            detailValue={agencyAddressDetail}
                            setDetailValue={setAgencyAddressDetail}
                        />
                        <BoxTextInput
                            title="담당자"
                            inputWidth={20}
                            value={agencyManager}
                            setValue={setAgencyManager}
                        />
                        <BoxTextInput
                            title="전화번호"
                            inputWidth={30}
                            value={agencyPhone}
                            setValue={setAgencyPhone}
                        />
                        <BoxTextInput
                            title="연락처"
                            inputWidth={30}
                            value={agencyContact}
                            setValue={setAgencyContact}
                        />
                        <div className="BoxInfo">
                            <div className="title">은행</div>
                            <div className="sub">
                                <input
                                    style={{ width: `${20}%` }}
                                    type={"text"}
                                    placeholder={"은행명"}
                                    value={agencyBankName}
                                    onChange={(e) => {
                                        setAgencyBankName(e.target.value);
                                    }}
                                />
                                <input
                                    style={{ width: `${20}%` }}
                                    type="text"
                                    placeholder={"계좌번호"}
                                    value={agencyBank} // 수정된 부분
                                    onChange={(e) => {
                                        setAgencyBank(e.target.value);
                                    }} // 수정된 부분
                                />
                                <input
                                    style={{ width: `${20}%` }}
                                    type="text"
                                    placeholder={"예금주"}
                                    value={agencyDepositor} // 수정된 부분
                                    onChange={(e) => {
                                        setAgencyDepositor(e.target.value);
                                    }} // 수정된 부분
                                />
                            </div>
                        </div>

                        <BoxMemoInput
                            value={agencyMemo}
                            setValue={setAgencyMemo}
                        />
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                        }}
                    >
                        <button className="btn btn9" onClick={InfoSave}>
                            저장
                        </button>
                    </div>
                    <div style={{ gap: "20px" }} className="title">
                        <h1>자재 기본가격 설정</h1>
                        <button
                            className="btn btn9"
                            onClick={() => {
                                index
                                    ? setOpenPop(!OpenPop)
                                    : toast.error(
                                          "대리점을 먼저 저장해주세요."
                                      );
                            }}
                        >
                            자재 추가
                        </button>
                    </div>
                    <div className="pop_progress">
                        <p
                            className={step === 1 ? "active" : ""}
                            onClick={() => {
                                setStep(1);
                                setPriceChange([]);
                            }}
                        >
                            벽지
                        </p>
                        <p
                            className={step === 5 ? "active" : ""}
                            onClick={() => {
                                setStep(5);
                                setPriceChange([]);
                            }}
                        >
                            부자재
                        </p>
                    </div>
                    {step === 1 && (
                        <div>
                            <div className="SelectBoxWrap">
                                <select
                                    // style={{ width: `${width}%` }}
                                    value={priceSelectCompany}
                                    onChange={(e) => {
                                        setPriceSelectCompany(e.target.value);
                                    }}
                                >
                                    {outCompanyList?.map((x, i) => (
                                        <option key={i} value={x}>
                                            {x}
                                        </option>
                                    ))}
                                </select>
                                {priceSelectCompany !== "제조사" ? (
                                    <select
                                        // style={{ width: `${width}%` }}
                                        value={priceSelectFirstCategory}
                                        onChange={(e) => {
                                            setPriceSelectFirstCategory(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {outFirstCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}
                                {priceSelectFirstCategory !== "1차분류" ? (
                                    <select
                                        // style={{ width: `${width}%` }}
                                        value={priceSelectSecondCategory}
                                        onChange={(e) => {
                                            setPriceSelectSecondCategory(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {outSecondCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}
                                <Input
                                    className="input_text"
                                    type="text"
                                    placeholder="모델번호, 제품명"
                                    name="stx"
                                    value={outSearchVal}
                                    setValue={setOutSearchVal}
                                    // style={{ width: "20%" }}
                                />
                                <button
                                    className="btn btn9"
                                    onClick={priceUpdate}
                                >
                                    저장
                                </button>
                            </div>
                            <div
                                style={{ marginTop: "20px" }}
                                className="table_scroll"
                            >
                                <table>
                                    <thead>
                                        <tr>
                                            <th>제조사</th>
                                            <th>1차분류</th>
                                            <th>2차분류</th>
                                            <th>가격</th>
                                            <th>관리</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {priceAgencyList?.map((x, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        {x.material_company}
                                                    </td>
                                                    <td>
                                                        {x.material_class01}
                                                    </td>
                                                    <td>
                                                        {x.material_class02}
                                                    </td>
                                                    <td
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        <div className="tableInputWrap">
                                                            <input
                                                                type="text"
                                                                name="title"
                                                                value={
                                                                    x.order_price
                                                                        ? x.order_price
                                                                              .toString()
                                                                              .replace(
                                                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                                                  ","
                                                                              )
                                                                        : 0
                                                                }
                                                                // index={i}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const value =
                                                                        e.target.value.replace(
                                                                            /,/g,
                                                                            ""
                                                                        );
                                                                    const parsedValue =
                                                                        parseInt(
                                                                            value
                                                                        );
                                                                    updateDataList(
                                                                        x.idx,
                                                                        "order_price",
                                                                        isNaN(
                                                                            parsedValue
                                                                        )
                                                                            ? 0
                                                                            : parsedValue
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        원
                                                    </td>
                                                    <td>
                                                        <div
                                                            className="TableBtns FlexCenter "
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <button
                                                                onClick={() =>
                                                                    deleteClk(
                                                                        x.idx
                                                                    )
                                                                }
                                                            >
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="PaginationContent">
                                <Pagination
                                    className="my-pagination"
                                    // 현제 보고있는 페이지
                                    activePage={outterPage}
                                    // 한페이지에 출력할 아이템수
                                    itemsCountPerPage={20}
                                    // 총 아이템수
                                    totalItemsCount={agencyOutListCount || 0}
                                    // 표시할 페이지수
                                    pageRangeDisplayed={10}
                                    // 함수
                                    onChange={handleOutPageChange}
                                    nextPageText=""
                                    prevPageText=""
                                    firstPageText=""
                                    lastPageText=""
                                ></Pagination>
                            </div>
                        </div>
                    )}
                    {step === 5 && (
                        <div>
                            <div>
                                <div className="SelectBoxWrap">
                                    <select
                                        // style={{ width: `${width}%` }}
                                        value={priceSelectCompany}
                                        onChange={(e) => {
                                            setPriceSelectCompany(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {outCompanyList?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                    {priceSelectCompany !== "제조사" ? (
                                        <select
                                            // style={{ width: `${width}%` }}
                                            value={priceSelectFirstCategory}
                                            onChange={(e) => {
                                                setPriceSelectFirstCategory(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            {outFirstCategory?.map((x, i) => (
                                                <option key={i} value={x}>
                                                    {x}
                                                </option>
                                            ))}
                                        </select>
                                    ) : null}
                                    {priceSelectFirstCategory !== "1차분류" ? (
                                        <select
                                            // style={{ width: `${width}%` }}
                                            value={priceSelectSecondCategory}
                                            onChange={(e) => {
                                                setPriceSelectSecondCategory(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            {outSecondCategory?.map((x, i) => (
                                                <option key={i} value={x}>
                                                    {x}
                                                </option>
                                            ))}
                                        </select>
                                    ) : null}
                                    <Input
                                        className="input_text"
                                        type="text"
                                        placeholder="모델번호, 제품명"
                                        name="stx"
                                        value={outSearchVal}
                                        setValue={setOutSearchVal}
                                        // style={{ width: "20%" }}
                                    />
                                    <button
                                        className="btn btn9"
                                        onClick={priceUpdate}
                                    >
                                        저장
                                    </button>
                                </div>
                                <div
                                    style={{ marginTop: "20px" }}
                                    className="table_scroll"
                                >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>제조사</th>
                                                <th>1차분류</th>
                                                <th>2차분류</th>
                                                <th>가격</th>
                                                <th>관리</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {priceAgencyList?.map((x, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            {x.material_company}
                                                        </td>
                                                        <td>
                                                            {x.material_class01}
                                                        </td>
                                                        <td>
                                                            {x.material_class02}
                                                        </td>
                                                        <td
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                gap: "10px",
                                                            }}
                                                        >
                                                            <div className="tableInputWrap">
                                                                <input
                                                                    type="text"
                                                                    name="title"
                                                                    value={
                                                                        x.order_price
                                                                            ? x.order_price
                                                                                  .toString()
                                                                                  .replace(
                                                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                                                      ","
                                                                                  )
                                                                            : 0
                                                                    }
                                                                    // index={i}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        const value =
                                                                            e.target.value.replace(
                                                                                /,/g,
                                                                                ""
                                                                            );
                                                                        const parsedValue =
                                                                            parseInt(
                                                                                value
                                                                            );
                                                                        updateDataList(
                                                                            x.idx,
                                                                            "order_price",
                                                                            isNaN(
                                                                                parsedValue
                                                                            )
                                                                                ? 0
                                                                                : parsedValue
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                            원
                                                        </td>
                                                        <td>
                                                            <div
                                                                className="TableBtns FlexCenter "
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                }}
                                                            >
                                                                <button
                                                                    onClick={() =>
                                                                        deleteClk(
                                                                            x.idx
                                                                        )
                                                                    }
                                                                >
                                                                    삭제
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="PaginationContent">
                                <Pagination
                                    className="my-pagination"
                                    // 현제 보고있는 페이지
                                    activePage={outterPage}
                                    // 한페이지에 출력할 아이템수
                                    itemsCountPerPage={20}
                                    // 총 아이템수
                                    totalItemsCount={agencyOutListCount || 0}
                                    // 표시할 페이지수
                                    pageRangeDisplayed={10}
                                    // 함수
                                    onChange={handleOutPageChange}
                                    nextPageText=""
                                    prevPageText=""
                                    firstPageText=""
                                    lastPageText=""
                                ></Pagination>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
export default AgencyMaterialDetail;
