import {
    BoxAdressInput,
    BoxContent,
    BoxContent2,
    BoxDateInput,
    BoxDropdownInput,
    BoxMemoInput,
    BoxRadioboxInput,
    BoxTextInput,
    BoxTextRadioInput,
} from "../../../components/BoxInfo";
import moment from "moment";
import {
    TableOrderEditClasfy,
    TableOrderEditClasfy2,
} from "../../../components/TableEdit";
import routes from "../../../libs/routes";
import consts from "../../../libs/consts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApiData from "../../../libs/api";
import { useGetData, usePostData } from "../../../utils/service";
import { PopupComp } from "../../../components/PopUp";
import { set } from "lodash";
import { TabComponent } from "../../../components/TabComponent";
import { useQueryClient } from "@tanstack/react-query";
import useLoadingStore from "../../../zustand/Loading";
import { sub } from "date-fns";
import Input from "../../../components/Input";
import Pagination from "react-js-pagination";

function OrderManageRegister() {
    const navigate = useNavigate();
    const orderDetail = usePostData(ApiData.orderDetail);
    const sigongInfoMateIn = usePostData(ApiData.sigongInfoMateIn);
    const sigongPopupFilter = usePostData(ApiData.sigongPopupFilter);
    const orderIn = usePostData(ApiData.orderIn);
    const queryClient = useQueryClient();
    const {
        data: agencyListApi,
        isloading,
        error,
    } = useGetData(ApiData.agencyList);

    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const listType = searchParams.get("type");

    const [materialSearchVal, setMaterialSearchVal] = useState("");
    const [OpenPop, setOpenPop] = useState(false);
    const [innerSelectCompany, setInnerSelectCompany] = useState("제조사");
    const [innerSelectFirstCategory, setInnerSelectFirstCategory] =
        useState("1차분류");
    const [innerSelectSecondCategory, setInnerSelectSecondCategory] =
        useState("2차분류");
    const [filterPopupList, setFilterPopupList] = useState([]);
    const [companyList, setCompanyList] = useState(["제조사"]);
    const [firstCategory, setFirstCategory] = useState(["1차분류"]);
    const [secondCategory, setSecondCategory] = useState(["2차분류"]);
    const [agencyValue, setAgencyValue] = useState([]);
    const [openPopRegFlag, setOpenPopRegFlag] = useState(0);
    const [agencyMateListData, setAgencyMateListData] = useState([]);
    const [innerType, setInnerType] = useState(1);
    const [materialDeleteList, setMaterialDeleteList] = useState([]);
    const [materialDeleteIdx, setMaterialDeleteIdx] = useState(0);
    const [materialDeleteIdx2, setMaterialDeleteIdx2] = useState(0);
    const [popupFlag, setPopupFlag] = useState(0);
    const [isDelete, setIsDelete] = useState(false);
    const [deletePop, setDeletePop] = useState(false);
    const [ShowContent, setShowContent] = useState(false);
    const [OrderCustomerName, setOrderCustomerName] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [orderDobae, setOrderDobae] = useState(0);
    const [orderJangpan, setOrderJangpan] = useState(0);
    const [orderMaru, setOrderMaru] = useState(0);
    const [customerSigongDate, setCustomerSigongDate] = useState(new Date());
    const [OrderAddress, setOrderAddress] = useState("");
    const [OrderaddressDetail, setOrderAddressDetail] = useState("");
    const [customerFinalEstimate, setCustomerFinalEstimate] = useState(0);
    const [useRadio, setUseRadio] = useState(0);
    const [agency, setAgency] = useState(1);
    const [orderSigongDate, setOrderSigongDate] = useState(new Date());
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [orderAddress2, setOrderAddress2] = useState("");
    const [orderAddressDetail2, setOrderAddressDetail2] = useState("");
    const [orderName, setOrderName] = useState("");
    const [orderPhone, setOrderPhone] = useState("");
    const [orderAmount, setOrderAmount] = useState(0);
    const [Ordermemo, setOrderMemo] = useState("");
    const [agencyList, setAgencyList] = useState([{ value: 0, text: "선택" }]);
    const [showContent2, setShowContent2] = useState(false);
    const [OrderClkIdx, setOrderClkIdx] = useState(0);
    const [OrderListData, setOrderListData] = useState([]);
    const [OrderDetailData, setOrderDetailData] = useState([]);
    const [mateList, setMateList] = useState([]);
    const [dobaeList, setDobaeList] = useState([]);
    const [subList, setSubList] = useState([]);
    const [tap, setTap] = useState([]);

    //시공 내용
    const [estimateData, setEstimateData] = useState([]);
    const historyLook = true;
    const [ContentHistory, setContentHistory] = useState([]);
    const [consultSummary, setConsultSummary] = useState("");
    const [ContentChange, setContentChange] = useState({});
    const [sigongWant, setSigongWant] = useState("");
    const [manager, setManager] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [phone, setPhone] = useState("");
    const [dobaeOn, setDobaeOn] = useState(0);
    const [jangpanOn, setJangpanOn] = useState(0);
    const [maruOn, setMaruOn] = useState(0);
    const [address, setAddress] = useState("");
    const [adressDetail, setAdressDetail] = useState("");
    const [area, setArea] = useState(0);
    const [areaPyeong, setAreaPyeong] = useState(0);
    const [directAreaPyeong, setDirectAreaPyeong] = useState(0);
    const [planUrl, setPlanUrl] = useState("");
    const [planImg, setPlanImg] = useState("");
    const [todayStatus, setTodayStatus] = useState(0);
    const [todayMemo, setTodayMemo] = useState("");
    const [veranda, setVeranda] = useState(0);
    const [verandaMemo, setVerandaMemo] = useState("");

    const [dobaeDate, setDobaeDate] = useState(new Date());
    const [dobaeTime, setDobaeTime] = useState("");
    const [dobaeRange, setDobaeRange] = useState(0);
    const [dobaeRangeMemo, setDobaeRangeMemo] = useState("");
    const [dobaeCeiling, setDobaeCeiling] = useState(0);
    const [dobaeCreateCompanyName, setDobaeCreateCompanyName] = useState("");

    const [dobaeDirectCompany, setDobaeDirectCompany] = useState("");
    const [dobaeProduct, setDobaeProduct] = useState(0);
    const [dobaeCnt, setDobaeCnt] = useState(0);
    const [dobaePrice, setDobaePrice] = useState(0);

    const [zangpanDate, setZangpanDate] = useState(new Date());
    const [zangpanTime, setZangpanTime] = useState("");
    const [zangpanRange, setZangpanRange] = useState(0);
    const [zangpanRangeMemo, setZangpanRangeMemo] = useState("");
    const [zangpanCreateCompanyName, setZangpanCreateCompanyName] =
        useState("");
    const [zangpanMM, setZangpanMM] = useState("");
    const [zangpanMolding, setZangpanMolding] = useState(0);
    const [zangpanMoldingMemo, setZangpanMoldingMemo] = useState("");
    const [zangpanMoldingRange, setZangpanMoldingRange] = useState(1);
    const [zangpanMoldingRangeMemo, setZangpanMoldingRangeMemo] = useState("");
    const [zangpanThreshold, setZangpanThreshold] = useState(2);
    const [zangpanPrice, setZangpanPrice] = useState(0);

    const [maruDate, setMaruDate] = useState(new Date());
    const [maruTime, setMaruTime] = useState("");
    const [maruRange, setMaruRange] = useState(0);
    const [maruRangeMemo, setMaruRangeMemo] = useState("");
    const [maruCreateCompanyName, setMaruCreateCompanyName] = useState("");

    const [maruFlooring, setMaruFlooring] = useState(1);
    const [maruFlooringMemo, setMaruFlooringMemo] = useState("");
    const [maruOldProduct, setMaruOldProduct] = useState("");
    const [maruDownMolding, setMaruDownMolding] = useState(2);
    const [maruThreshold, setMaruThreshold] = useState(2);
    const [maruRepair, setMaruRepair] = useState(2);
    const [maruPrice, setMaruPrice] = useState(0);

    const [createDt, setCreateDt] = useState(new Date());
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const [innerPage, setInnerPage] = useState(1);
    const [agencyListCount, setAgencyListCount] = useState(0);
    const handlePageChange = (pageNumber) => {
        setInnerPage(pageNumber);
    };
    const orderDetailApi = () => {
        let sender = { orderIdx: index, type: listType };

        if (OrderClkIdx !== 0) {
            sender.selectIdx = OrderClkIdx;
        }
        // loadingStart();
        // console.log("sender", sender);
        orderDetail
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log("data", data);
                // loadingEnd();
                setOrderListData(data?.list);
                setOrderDetailData(data?.detail);
                setEstimateData(data?.estimate);
                setTap(data?.tap);
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
            });
    };
    const submit = () => {
        let sender = {
            orderListIdx: parseInt(OrderClkIdx),
            cutomer_name: OrderCustomerName,
            mb_phone: customerPhone,
            sigong_date: customerSigongDate,
            address: OrderAddress,
            address_detail: OrderaddressDetail,
            final_price: parseInt(customerFinalEstimate),
            use_type: parseInt(useRadio),
            agency_idx: parseInt(agency),
            delivery_price: parseInt(deliveryFee),
            delivery_date: orderSigongDate,
            delivery_address: orderAddress2,
            delivery_address_detail: orderAddressDetail2,
            recipient_name: orderName,
            recipient_phone: orderPhone,
            order_price: parseInt(orderAmount),
            all_order_price: parseInt(orderAmount + deliveryFee),
            memo: Ordermemo,
            dobaeArr: dobaeList,
            subArr: subList,
            deleteIdxArr: materialDeleteList.filter(
                (item) => item !== undefined
            ),
        };
        // if (OrderCustomerName === "" || OrderCustomerName === null) {
        //     toast.error("고객명을 입력해주세요.");
        //     return;
        // }
        if (customerPhone === "" || customerPhone === null) {
            toast.error("고객 연락처를 입력해주세요.");
            return;
        } else if (customerPhone?.includes("-")) {
            // sender.phone = customerPhone.replace(/-/g, "");
        } else if (customerPhone?.includes(" ")) {
            // sender.phone = customerPhone.replace(/ /g, "");
        } else if (!consts.numPattern.test(customerPhone)) {
            toast.error("고객 번호를 제대로 입력해주세요.");
            return;
        }
        if (customerSigongDate === null) {
            toast.error("시공일을 입력해주세요.");
            return;
        }
        // if (OrderAddress === "" || OrderAddress === null) {
        //     toast.error("주소를 입력해주세요.");
        //     return;
        // } else if (OrderaddressDetail === "" || OrderaddressDetail === null) {
        //     toast.error("상세주소를 입력해주세요.");
        //     return;
        // }
        // if (customerFinalEstimate === 0 || customerFinalEstimate === null) {
        //     toast.error("최종견적을 입력해주세요.");
        //     return;
        // }
        if (parseInt(agency) === 0 || agency === null) {
            toast.error("대리점을 선택해주세요.");
            return;
        }
        // if (deliveryFee === 0 || deliveryFee === null) {
        //     toast.error("배송비를 입력해주세요.");
        //     return;
        // }
        if (orderSigongDate === null) {
            toast.error("배송일을 입력해주세요.");
            return;
        }
        // if (orderAddress2 === "" || orderAddress2 === null) {
        //     toast.error("배송지를 입력해주세요.");
        //     return;
        // } else if (orderAddressDetail2 === "" || orderAddressDetail2 === null) {
        //     toast.error("배송지 상세주소를 입력해주세요.");
        //     return;
        // }

        if (orderName === "" || orderName === null) {
            toast.error("수령자명을 입력해주세요.");
            return;
        } else if (orderPhone === "" || orderPhone === null) {
            toast.error("수령자 연락처를 입력해주세요.");
            return;
        } else if (orderPhone?.includes("-")) {
            // sender.phone = customerPhone.replace(/-/g, "");
        } else if (orderPhone?.includes(" ")) {
            // sender.phone = customerPhone.replace(/ /g, "");
        } else if (!consts.numPattern.test(orderPhone)) {
            toast.error("수령자 번호를 제대로 입력해주세요.");
            return;
        }
        // if (orderAmount === 0 || orderAmount === null) {
        //     toast.error("주문금액을 입력해주세요.");
        //     return;
        // }

        if (index !== null) {
            sender.orderListIdx = parseInt(index);
        }
        if (OrderClkIdx !== 0) {
            sender.orderListIdx = parseInt(OrderClkIdx);
        }
        if (parseInt(agency) !== 0 && agency !== null) {
            // console.log("agency", parseInt(agency));
            sender.agency_name = agencyList.find(
                (x) => x.value === parseInt(agency)
            ).text;
        }
        // console.log("sender", sender, customerName);
        orderIn
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("저장되었습니다.");
                if (index) {
                    navigate(
                        `${routes.constPreview4}?index=${index}&type=list`
                    );
                } else {
                    navigate(`${routes.constPreview4}?index=${data}&type=list`);
                }

                // if (OrderClkIdx !== 0) {
                //     navigate(`${routes.constRegst3}?index=${OrderClkIdx}`);
                //     orderDetailApi();
                //     return;
                // }
                // if (index !== null) {
                //     orderDetailApi();
                //     return;
                // }

                // if (OrderClkIdx === 0 || index === null) {
                //     navigate(`${routes.constRegst3}?index=${data}&type=list`);
                //     orderDetailApi();
                //     return;
                // }
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };
    const AddMaterial = () => {
        let sender = {
            material_company:
                innerSelectCompany === "제조사" ? "" : innerSelectCompany,
            material_class01:
                innerSelectFirstCategory === "1차분류"
                    ? ""
                    : innerSelectFirstCategory,
            material_class02:
                innerSelectSecondCategory === "2차분류"
                    ? ""
                    : innerSelectSecondCategory,

            material_type: innerType,
            searchVal: materialSearchVal,
            page: innerPage - 1,
        };
        if (innerSelectCompany === "제조사") {
            sender.material_class01 = "";
            setInnerSelectFirstCategory("1차분류");
        }
        if (innerSelectFirstCategory === "1차분류") {
            sender.material_class01 = "";
            setInnerSelectSecondCategory("2차분류");
        }
        sigongPopupFilter
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log("data?!?!?!?!?!?!?!?!?!", data);
                setAgencyMateListData(data?.list.rows);
                setAgencyListCount(data?.list.count);
                setFilterPopupList(data?.filter);
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };
    const ClosePop = () => {
        setOpenPop(!OpenPop);
        setMaterialSearchVal("");
        setInnerSelectCompany("제조사");
        setInnerSelectFirstCategory("1차분류");
        setInnerSelectSecondCategory("2차분류");
        setAgencyValue([]);
    };
    const handleChange = (e) => {
        if (e.target.checked) {
            setAgencyValue([...agencyValue, parseInt(e.target.value)]);
        } else {
            setAgencyValue(
                agencyValue.filter(
                    (value) => value !== parseInt(e.target.value)
                )
            );
        }
    };
    const materialListSave = () => {
        const flagToPropertyMap = {
            1: "dobaeArr",
            2: "subArr",
            3: "zangpanArr",
            4: "zangpanMopArr",
            5: "maruArr",
            6: "maruMopArr",
        };

        if (flagToPropertyMap.hasOwnProperty(openPopRegFlag)) {
            if (openPopRegFlag === 1) {
                setDobaeList((prev) =>
                    prev.concat(
                        agencyValue.flatMap((num) =>
                            agencyMateListData
                                ?.map((c) => {
                                    const { idx, ...rest } = c;
                                    return parseInt(c.idx) === parseInt(num)
                                        ? rest
                                        : null;
                                })
                                .filter(Boolean)
                        )
                    )
                );
            } else if (openPopRegFlag === 2) {
                setSubList((prev) =>
                    prev.concat(
                        agencyValue.flatMap((num) =>
                            agencyMateListData
                                ?.map((c) => {
                                    const { idx, ...rest } = c;
                                    return parseInt(c.idx) === parseInt(num)
                                        ? rest
                                        : null;
                                })
                                .filter(Boolean)
                        )
                    )
                );
            }
        }
        setOpenPop(!OpenPop);
        setAgencyValue([]);
    };

    const btnDobaePop = () => {
        setInnerType(1);
        setOpenPop(!OpenPop);
        setOpenPopRegFlag(1);
    };
    const btnDobaeSubPop = () => {
        setInnerType(5);
        setOpenPop(!OpenPop);
        setOpenPopRegFlag(2);
    };
    const deleteDobaeMaterial = () => {
        setDobaeList((prev) =>
            prev.filter((item, index) => {
                return index !== materialDeleteIdx;
            })
        );
        setMaterialDeleteList((prev) => [
            ...prev,
            dobaeList[materialDeleteIdx].idx,
        ]);
    };
    const deleteSubMaterial = () => {
        setSubList((prev) =>
            prev.filter((item, index) => {
                return index !== materialDeleteIdx2;
            })
        );
        setMaterialDeleteList((prev) => [
            ...prev,
            subList[materialDeleteIdx2].idx,
        ]);
    };
    const GoBack = () => {
        navigate(routes.constr4);
    };
    const ListGoback = () => {
        setIsDelete(false);
        setDeletePop(true);
        setPopupFlag(3);
    };
    useEffect(() => {
        // console.log("materialDeleteList", materialDeleteList);
    }, [materialDeleteList]);
    useEffect(() => {
        filterPopupList?.map((item, index) => {
            setCompanyList((prev) =>
                prev.includes(item.material_company)
                    ? prev
                    : [...prev, item.material_company]
            );
        });

        filterPopupList?.map((item, index) => {
            setFirstCategory((prev) =>
                prev.includes(item.material_class01)
                    ? prev
                    : [...prev, item.material_class01]
            );
        });
        filterPopupList?.map((item, index) => {
            setSecondCategory((prev) =>
                prev.includes(item.material_class02)
                    ? prev
                    : [...prev, item.material_class02]
            );
        });

        return () => {
            setCompanyList(["제조사"]);
            setFirstCategory(["1차분류"]);
            setSecondCategory(["2차분류"]);
        };
    }, [filterPopupList]);
    useEffect(() => {
        AddMaterial();
    }, [
        innerType,
        innerSelectCompany,
        innerSelectFirstCategory,
        innerSelectSecondCategory,
        OpenPop,
        materialSearchVal,
        innerPage,
    ]);

    useEffect(() => {
        if (index !== null) {
            orderDetailApi();
        }
    }, [OrderClkIdx]);
    useEffect(() => {
        agencyListApi?.data?.map((item, index) => {
            setAgencyList((prev) => [
                ...prev,
                { value: item.idx, text: item.agency_name },
            ]);
        });
        return () => {
            setAgencyList([{ value: 0, text: "선택" }]);
        };
    }, [agencyListApi]);
    useEffect(() => {
        //매니저 이름 들어가야 함
        setManager(estimateData?.admin_name);
        setCustomerName(estimateData?.cutomer_name);
        setPhone(estimateData?.mb_phone);
        setDobaeOn(estimateData?.dobae);
        setJangpanOn(estimateData?.zangpan);
        setMaruOn(estimateData?.maru);
        setAddress(estimateData?.address);
        setAdressDetail(estimateData?.address_detail);
        setArea(estimateData?.area);
        setAreaPyeong(estimateData?.area_pyeong);
        setPlanUrl(estimateData?.plan_url);
        setPlanImg(estimateData?.plan_file);
        setTodayStatus(estimateData?.today_status);
        setTodayMemo(estimateData?.today_memo);
        setVeranda(estimateData?.veranda);
        setVerandaMemo(estimateData?.veranda_memo);
        setDobaeDate(estimateData?.dobae_date);
        setDobaeTime(estimateData?.dobae_time);
        setDobaeRange(estimateData?.dobae_range);
        setDobaeRangeMemo(estimateData?.dobae_range_memo);
        setDobaeCeiling(estimateData?.dobae_ceiling);
        setDobaeCreateCompanyName(estimateData?.dobae_company_name);
        setDobaeProduct(estimateData?.dobae_product);
        setDobaeCnt(estimateData?.dobae_cnt);
        setDobaePrice(estimateData?.dobae_price);
        setZangpanDate(estimateData?.zangpan_date);
        setZangpanTime(estimateData?.zangpan_time);
        setZangpanRange(estimateData?.zangpan_range);
        setZangpanRangeMemo(estimateData?.zangpan_range_memo);
        setZangpanCreateCompanyName(estimateData?.zangpan_company_name);
        setZangpanMM(estimateData?.zangpan_mm);
        setZangpanMolding(estimateData?.zangpan_molding);
        setZangpanMoldingMemo(estimateData?.zangpan_molding_memo);
        setZangpanMoldingRange(estimateData?.zangpan_molding_range);
        setZangpanMoldingRangeMemo(estimateData?.zangpan_molding_range_memo);
        setZangpanThreshold(estimateData?.zangpan_threshold);
        setZangpanPrice(estimateData?.zangpan_price);
        setMaruDate(estimateData?.maru_date);
        setMaruTime(estimateData?.maru_time);
        setMaruRange(estimateData?.maru_range);
        setMaruRangeMemo(estimateData?.maru_range_memo);
        setMaruCreateCompanyName(estimateData?.maru_company_name);
        setMaruFlooring(estimateData?.maru_flooring);
        setMaruFlooringMemo(estimateData?.maru_flooring_memo);
        setMaruOldProduct(estimateData?.maru_ori_flooring);
        setMaruDownMolding(estimateData?.maru_molding);
        setMaruThreshold(estimateData?.maru_threshold);
        setMaruRepair(estimateData?.maru_cure);
        setMaruPrice(estimateData?.maru_price);
        setCreateDt(estimateData?.create_dt);
        setSigongWant(`
        ${
            estimateData?.dobae === 1
                ? `도배 : ${moment(
                      estimateData?.dobae_date
                          ? estimateData?.dobae_date
                          : createDt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              estimateData?.dobae_date
                                  ? estimateData?.dobae_date
                                  : estimateData?.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }
        ${
            estimateData?.zangpan === 1
                ? `장판 : ${moment(
                      estimateData?.zangpan_date
                          ? estimateData?.zangpan_date
                          : createDt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              estimateData?.zangpan_date
                                  ? estimateData?.zangpan_date
                                  : estimateData?.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }
        ${
            estimateData?.maru === 1
                ? `마루 : ${moment(
                      estimateData?.estimateData?.maru_date
                          ? maruDate
                          : estimateData?.create_dt
                  ).format("YYYY-MM-DD")}(${
                      consts.dayText[
                          moment(
                              estimateData?.estimateData?.maru_date
                                  ? maruDate
                                  : estimateData?.create_dt
                          ).day()
                      ]
                  }), `
                : ""
        }`);
        let summary = "";

        if (estimateData?.address || estimateData?.address_detail) {
            summary += `${estimateData?.address ? estimateData?.address : ""}${
                estimateData?.address_detail ? estimateData?.address_detail : ""
            }/ `;
        }

        summary += `${estimateData?.area === 1 ? "공급" : "전용"}면적 ${
            estimateData?.area_pyeong || 0
        }평/ `;

        if (estimateData?.veranda == 1) {
            summary += `베란다 확장 있음${
                estimateData?.veranda_memo
                    ? `(${estimateData?.veranda_memo})`
                    : ""
            }/ `;
        } else {
            summary += `베란다 확장 없음${
                estimateData?.veranda_memo
                    ? `(${estimateData?.veranda_memo})`
                    : ""
            }/ `;
        }

        summary += `${
            estimateData?.today_status == 1
                ? "빈집 가구 없음 "
                : estimateData?.today_status == 2
                ? "거주중 가구 있음"
                : "이사 당일"
        }`;

        if (estimateData?.today_memo) {
            summary += `(${estimateData?.today_memo})`;
        }

        setConsultSummary(summary);
    }, [estimateData]);
    useEffect(() => {
        // setOrderClkIdx(OrderDetailData.idx);
        setOrderCustomerName(OrderDetailData?.cutomer_name);
        setCustomerPhone(OrderDetailData?.mb_phone);
        setOrderDobae(OrderDetailData?.dobae);
        setOrderJangpan(OrderDetailData?.zangpan);
        setOrderMaru(OrderDetailData?.maru);
        setCustomerSigongDate(OrderDetailData?.sigong_date);
        setOrderAddress(OrderDetailData?.address);
        setOrderAddressDetail(OrderDetailData?.address_detail);

        setUseRadio(OrderDetailData?.use_type);
        setAgency(OrderDetailData?.agency_idx);
        setDeliveryFee(OrderDetailData?.delivery_price);
        setOrderSigongDate(OrderDetailData?.delivery_date || new Date());
        setOrderAddress2(OrderDetailData?.delivery_address);
        setOrderAddressDetail2(OrderDetailData?.delivery_address_detail);
        setOrderName(OrderDetailData?.recipient_name);
        setOrderPhone(OrderDetailData?.recipient_phone);
        setOrderAmount(OrderDetailData?.order_price);
        setOrderMemo(OrderDetailData?.memo);
        setMateList(OrderDetailData?.mateList);
        setCustomerFinalEstimate(OrderDetailData?.final_price);
    }, [OrderDetailData]);
    useEffect(() => {
        mateList?.map((item, index) => {
            if (item.material_type === 1) {
                setDobaeList((prev) => [...prev, item]);
            } else {
                setSubList((prev) => [...prev, item]);
            }
        });
        return () => {
            setDobaeList([]);
            setSubList([]);
        };
    }, [mateList]);

    useEffect(() => {
        setOrderAmount(
            dobaeList
                ?.filter((x, i) => x.material_type === 1)
                .reduce((acc, c) => {
                    return (
                        acc +
                        parseInt((c.order_count || 0) * (c.order_price || 0))
                    );
                }, 0) +
                subList
                    ?.filter((x, i) => x.material_type === 5)
                    .reduce((acc, c) => {
                        return (
                            acc +
                            parseInt((c.count || 0) * (c.order_price || 0))
                        );
                    }, 0)
        );
    }, [dobaeList, subList]);
    return (
        <>
            <PopupComp
                popTitle={`${isDelete ? "삭제" : "안내"}`}
                popContent={`${
                    isDelete
                        ? "정말로 삭제하시겠습니까?"
                        : "저장 없이 페이지 이동하시겠습니까?"
                }`}
                isYes={true}
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={
                    popupFlag === 1
                        ? deleteDobaeMaterial
                        : popupFlag === 2
                        ? deleteSubMaterial
                        : popupFlag === 3
                        ? GoBack
                        : null
                }
            />
            {OpenPop && (
                <div style={{ overflow: "auto" }} className={`popup show`}>
                    <div
                        className="popup_container"
                        style={{
                            width: "1000px",
                            minHeight: "600px",
                            overflow: "hidden",

                            justifyContent: "flex-start",
                        }}
                    >
                        <div
                            className="popup_title"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0 20px",
                            }}
                        >
                            <p>자재 목록</p>
                            <div className="exit_btn" onClick={ClosePop}></div>
                        </div>
                        <div style={{ padding: "10px" }} className="popup_msg">
                            <div className="SelectBoxWrap">
                                <select
                                    // style={{ width: `${width}%` }}
                                    value={innerSelectCompany}
                                    onChange={(e) => {
                                        setInnerSelectCompany(e.target.value);
                                    }}
                                >
                                    {companyList?.map((x, i) => (
                                        <option key={i} value={x}>
                                            {x}
                                        </option>
                                    ))}
                                </select>
                                {innerSelectCompany !== "제조사" ? (
                                    <select
                                        // style={{ width: `${width}%` }}
                                        value={innerSelectFirstCategory}
                                        onChange={(e) => {
                                            setInnerSelectFirstCategory(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {firstCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}
                                {innerSelectFirstCategory !== "1차분류" ? (
                                    <select
                                        // style={{ width: `${width}%` }}
                                        value={innerSelectSecondCategory}
                                        onChange={(e) => {
                                            setInnerSelectSecondCategory(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {secondCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}

                                <Input
                                    className="input_text"
                                    type="text"
                                    placeholder="모델번호, 제품명"
                                    name="stx"
                                    value={materialSearchVal}
                                    setValue={setMaterialSearchVal}
                                    // style={{ width: "20%" }}
                                />
                                <button
                                    className="btn btn9"
                                    onClick={materialListSave}
                                >
                                    저장
                                </button>
                            </div>
                            <div
                                style={{ marginTop: "20px" }}
                                className="table_scroll"
                            >
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="FlexCenter">
                                                    <div
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            border: "1px solid #ddd",
                                                        }}
                                                    ></div>
                                                </div>
                                            </th>
                                            <th>종류</th>
                                            <th>제조사</th>
                                            <th>1차분류</th>
                                            <th>2차분류</th>
                                            <th>모델번호</th>
                                            <th>제품명</th>
                                            <th>제품이미지</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agencyMateListData?.map((x, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <input
                                                            checked={agencyValue
                                                                .map((c) =>
                                                                    parseInt(c)
                                                                )
                                                                .includes(
                                                                    x.idx
                                                                )}
                                                            value={x.idx}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            id={x.idx}
                                                            className="OneChkBox"
                                                            type="checkbox"
                                                        />
                                                        <label
                                                            htmlFor={x.idx}
                                                        />
                                                    </td>
                                                    <td>
                                                        {x.material_type === 1
                                                            ? "벽지"
                                                            : x.material_type ===
                                                              2
                                                            ? "장판"
                                                            : x.material_type ===
                                                              3
                                                            ? "마루"
                                                            : x.material_type ===
                                                              4
                                                            ? "걸레받이"
                                                            : x.material_type ===
                                                              5
                                                            ? "부자재"
                                                            : null}
                                                    </td>
                                                    <td>
                                                        {x.material_company}
                                                    </td>
                                                    <td>
                                                        {x.material_class01}
                                                    </td>
                                                    <td>
                                                        {x.material_class02}
                                                    </td>
                                                    <td>{x.material_number}</td>
                                                    <td>{x.material_name}</td>
                                                    <td>
                                                        {x.material_img ? (
                                                            <img
                                                                style={{
                                                                    height: "50px",
                                                                }}
                                                                src={`${consts.ImgState}${x.material_img}`}
                                                            />
                                                        ) : (
                                                            <p>이미지 없음</p>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={innerPage}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={agencyListCount || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                    </div>
                </div>
            )}
            <div className="content ">
                {index && (
                    <>
                        <TabComponent
                            index={tap?.counseling_idx}
                            estimate_idx={tap?.estimate_idx}
                            sigonginfo_idx={tap?.sigonginfo_idx}
                            order_idx={tap?.order_idx}
                            workend_idx={tap?.workend_idx}
                            as_tbl={tap?.as_tbl}
                        />
                        <div className="BorderBox">
                            <div
                                className="title HistoryBox"
                                style={{ borderBottom: "1px solid #ccc" }}
                                onClick={() => setShowContent(!ShowContent)}
                            >
                                <div className="left">
                                    <h2>주문내용</h2>
                                </div>
                                <div className="right">
                                    <svg
                                        style={{
                                            transform: ShowContent
                                                ? "rotate(180deg)"
                                                : "rotate(0deg)",
                                        }}
                                        width="40"
                                        height="40"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                            fill="#333333"
                                        />
                                    </svg>
                                </div>
                            </div>
                            {ShowContent && (
                                <div className="BorderBoxTable">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>등록일</th>
                                                <th>대리점</th>
                                                <th>배송지</th>
                                                <th>수령자</th>
                                                <th>분류</th>
                                                <th>금액</th>
                                                <th>보기버튼</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {OrderListData?.map((x, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{`${moment(
                                                            x.create_dt
                                                        ).format(
                                                            "YYYY-MM-DD"
                                                        )}`}</td>
                                                        <td>
                                                            {agencyList?.find(
                                                                (c) =>
                                                                    c.value ===
                                                                    x.agency_idx
                                                            )?.text || "-"}
                                                        </td>
                                                        <td>{`${
                                                            x.delivery_address !==
                                                                null &&
                                                            x.delivery_address_detail !==
                                                                null
                                                                ? x.delivery_address +
                                                                  " " +
                                                                  x.delivery_address_detail
                                                                : x.delivery_address !==
                                                                      null &&
                                                                  x.delivery_address_detail ===
                                                                      null
                                                                ? x.delivery_address
                                                                : x.delivery_address ===
                                                                      null &&
                                                                  x.delivery_address_detail !==
                                                                      null
                                                                ? x.delivery_address_detail
                                                                : "-"
                                                        }`}</td>
                                                        <td>
                                                            {x.cutomer_name ||
                                                                "-"}
                                                        </td>
                                                        <td>
                                                            {x.use_type === 1
                                                                ? "시공"
                                                                : x.use_type ===
                                                                  2
                                                                ? "퀵배송"
                                                                : x.use_type ===
                                                                  3
                                                                ? "A/S"
                                                                : x.use_type ===
                                                                  4
                                                                ? "내사"
                                                                : "-"}
                                                        </td>
                                                        <td>{`${
                                                            x.all_order_price
                                                                ? x.all_order_price.toLocaleString() +
                                                                  "원"
                                                                : "-"
                                                        }`}</td>
                                                        <td
                                                            onClick={() => {
                                                                setOrderClkIdx(
                                                                    x.idx
                                                                );
                                                            }}
                                                            style={{
                                                                cursor: "pointer",
                                                                textDecoration:
                                                                    "underline",
                                                                color: "blue",
                                                            }}
                                                        >
                                                            내용보기
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        <div className="BorderBox">
                            <div
                                className="title HistoryBox"
                                style={{ borderBottom: "1px solid #ccc" }}
                                onClick={() => setShowContent2(!showContent2)}
                            >
                                <div className="left">
                                    <h1>견적내용 보기</h1>
                                </div>
                                <div className="right">
                                    <svg
                                        style={{
                                            transform: showContent2
                                                ? "rotate(180deg)"
                                                : "rotate(0deg)",
                                        }}
                                        width="40"
                                        height="40"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                            fill="#333333"
                                        />
                                    </svg>
                                </div>
                            </div>
                            {showContent2 && (
                                <>
                                    <div className="title">
                                        <h1>상담요약</h1>
                                    </div>
                                    <div className="contentWrap">
                                        <BoxContent
                                            title="시공희망일"
                                            content={sigongWant}
                                        />
                                        <BoxContent
                                            title="내용"
                                            content={consultSummary}
                                        />
                                    </div>
                                    <div className="title">
                                        <h1>기본정보</h1>
                                    </div>
                                    <div className="contentWrap">
                                        <BoxContent
                                            title="담당자"
                                            content={manager || "-"}
                                        />
                                        <BoxContent
                                            title="고객명"
                                            content={customerName || "-"}
                                        />
                                        <BoxContent
                                            title="연락처"
                                            content={phone || "-"}
                                        />
                                        {/* <BoxArrayContent
                                    title="시공"
                                    // array={sigong || "-"}
                                /> */}
                                        <BoxContent
                                            title="주소"
                                            content={`${
                                                address !== null &&
                                                adressDetail !== null
                                                    ? address +
                                                      " " +
                                                      adressDetail
                                                    : address !== null &&
                                                      adressDetail === null
                                                    ? address
                                                    : address === null &&
                                                      adressDetail !== null
                                                    ? adressDetail
                                                    : "-"
                                            }`}
                                        />
                                        <BoxContent
                                            title="면적"
                                            content={`${
                                                areaPyeong ? areaPyeong : 0
                                            }평(${
                                                area == 1 ? "공급" : "전용"
                                            })`}
                                        />
                                        {/*BoxShowImg로 바꿔야 함. */}
                                        <div className="BoxInfo">
                                            <div className="title">
                                                도면 URL
                                            </div>
                                            <div className="sub">
                                                {planUrl ? (
                                                    <a
                                                        href={`${planUrl}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        도면보기
                                                    </a>
                                                ) : (
                                                    "-"
                                                )}
                                            </div>
                                        </div>
                                        <div className="BoxInfo">
                                            <div className="title">
                                                도면파일
                                            </div>
                                            <div className="sub">
                                                {planImg ? (
                                                    <a
                                                        href={`${consts.ImgState}${planImg}`}
                                                        download
                                                    >
                                                        도면
                                                    </a>
                                                ) : (
                                                    "-"
                                                )}
                                            </div>
                                        </div>
                                        {/*BoxShowImg로 바꿔야 함. */}
                                        <BoxContent
                                            title="시공당일 주거환경"
                                            content={`${
                                                todayStatus == 1
                                                    ? "빈집"
                                                    : todayStatus == 2
                                                    ? "거주중 가구 있음"
                                                    : "이사 당일"
                                            } ${
                                                todayMemo
                                                    ? `(${todayMemo})`
                                                    : ""
                                            }`}
                                        />
                                        <BoxContent
                                            title="베란다 확장"
                                            content={
                                                veranda == 1
                                                    ? "베란다 확장 있음 "
                                                    : "베란다 확장 없음"
                                            }
                                        />
                                    </div>
                                    {dobaeOn == 1 && (
                                        <div className="title_color_box">
                                            <div
                                                className="box_title"
                                                style={{
                                                    backgroundColor: "#41b06e",
                                                    color: "white",
                                                }}
                                            >
                                                <h1>도배</h1>
                                            </div>
                                            <div className="contentColorWrap">
                                                <div className="contentBorderWrap">
                                                    <BoxContent
                                                        title="시공일"
                                                        content={
                                                            dobaeDate
                                                                ? `${moment(
                                                                      dobaeDate
                                                                  ).format(
                                                                      "YYYY-MM-DD"
                                                                  )}(${
                                                                      consts
                                                                          .dayText[
                                                                          moment(
                                                                              dobaeDate
                                                                          ).day()
                                                                      ]
                                                                  })`
                                                                : "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="시공시간"
                                                        content={
                                                            dobaeTime || "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="시공범위"
                                                        content={`${
                                                            dobaeRange == 1
                                                                ? "전체"
                                                                : "일부"
                                                        } ${
                                                            dobaeRangeMemo
                                                                ? `(${dobaeRangeMemo})`
                                                                : ""
                                                        }`}
                                                    />
                                                    <BoxContent
                                                        title="천장"
                                                        content={
                                                            dobaeCeiling == 1
                                                                ? "천장도배포함"
                                                                : "천장도배없음"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="제조사"
                                                        content={
                                                            dobaeCreateCompanyName ||
                                                            "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="벽지 종류"
                                                        content={
                                                            dobaeProduct == 1
                                                                ? "실크"
                                                                : dobaeProduct ==
                                                                  2
                                                                ? "합지"
                                                                : "혼합"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="벽지 수"
                                                        content={`${dobaeCnt}개`}
                                                    />
                                                    <BoxContent
                                                        title="견적금액"
                                                        content={`${dobaePrice.toLocaleString()}원`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {jangpanOn == 1 && (
                                        <div className="title_color_box">
                                            <div
                                                className="box_title"
                                                style={{
                                                    backgroundColor: "#fb8500",
                                                    color: "white",
                                                }}
                                            >
                                                <h1>장판</h1>
                                            </div>
                                            <div className="contentColorWrap">
                                                <div className="contentBorderWrap">
                                                    <BoxContent
                                                        title="시공일"
                                                        content={
                                                            zangpanDate
                                                                ? `${moment(
                                                                      zangpanDate
                                                                  ).format(
                                                                      "YYYY-MM-DD"
                                                                  )}(${
                                                                      consts
                                                                          .dayText[
                                                                          moment(
                                                                              zangpanDate
                                                                          ).day()
                                                                      ]
                                                                  })`
                                                                : "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="시공시간"
                                                        content={
                                                            zangpanTime || "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="시공범위"
                                                        content={`${
                                                            zangpanRange == 1
                                                                ? "전체"
                                                                : "일부"
                                                        } ${
                                                            zangpanRangeMemo
                                                                ? `(${zangpanRangeMemo})`
                                                                : ""
                                                        }`}
                                                    />
                                                    <BoxContent
                                                        title="제조사"
                                                        content={
                                                            zangpanCreateCompanyName ||
                                                            "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="장판"
                                                        content={`${zangpanMM}`}
                                                    />
                                                    <BoxContent2
                                                        title="하단몰딩"
                                                        content1={`종류 : ${
                                                            zangpanMolding == 0
                                                                ? "없음"
                                                                : zangpanMolding ==
                                                                  1
                                                                ? zangpanMoldingMemo
                                                                : zangpanMolding ==
                                                                  2
                                                                ? "굽도리"
                                                                : "걸레받이"
                                                        }`}
                                                        content2={`범위 : ${
                                                            zangpanMoldingRange ==
                                                            1
                                                                ? "전체"
                                                                : zangpanMoldingRangeMemo
                                                                ? zangpanMoldingRangeMemo
                                                                : "-"
                                                        }`}
                                                    />

                                                    <BoxContent
                                                        title="문턱"
                                                        content={
                                                            zangpanThreshold ==
                                                            1
                                                                ? "시공"
                                                                : "시공없음"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="견적금액"
                                                        content={`${zangpanPrice.toLocaleString()}원`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {maruOn == 1 && (
                                        <div className="title_color_box">
                                            <div
                                                className="box_title"
                                                style={{
                                                    backgroundColor: "#caf0f8",
                                                }}
                                            >
                                                <h1>마루</h1>
                                            </div>
                                            <div className="contentColorWrap">
                                                <div className="contentBorderWrap">
                                                    <BoxContent
                                                        title="시공일"
                                                        content={
                                                            maruDate
                                                                ? `${moment(
                                                                      maruDate
                                                                  ).format(
                                                                      "YYYY-MM-DD"
                                                                  )}(${
                                                                      consts
                                                                          .dayText[
                                                                          moment(
                                                                              maruDate
                                                                          ).day()
                                                                      ]
                                                                  })`
                                                                : "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="시공시간"
                                                        content={
                                                            maruTime || "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="시공범위"
                                                        content={`${
                                                            maruRange == 1
                                                                ? "전체"
                                                                : "일부"
                                                        } ${
                                                            maruRangeMemo
                                                                ? `(${maruRangeMemo})`
                                                                : "-"
                                                        }`}
                                                    />
                                                    <BoxContent
                                                        title="제조사"
                                                        content={
                                                            maruCreateCompanyName ||
                                                            "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="바닥제"
                                                        content={
                                                            maruFlooring == 1
                                                                ? maruFlooringMemo
                                                                : maruFlooring ==
                                                                  2
                                                                ? "강마루"
                                                                : maruFlooring ==
                                                                  3
                                                                ? "강화마루"
                                                                : "SB마루"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="기존 바닥재"
                                                        content={
                                                            maruOldProduct ||
                                                            "-"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="하단몰딩"
                                                        content={
                                                            maruDownMolding == 1
                                                                ? "시공"
                                                                : "시공없음"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="문턱"
                                                        content={
                                                            maruThreshold == 1
                                                                ? "시공"
                                                                : "시공없음"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="보양작업"
                                                        content={
                                                            maruRepair == 1
                                                                ? "시공"
                                                                : "시공없음"
                                                        }
                                                    />
                                                    <BoxContent
                                                        title="견적금액"
                                                        content={`${maruPrice.toLocaleString()}원`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )}

                <div className="BorderBox">
                    <div className="title">
                        <h1>고객정보</h1>
                    </div>
                    <div className="contentWrap">
                        <BoxTextInput
                            title="고객명"
                            placeholder="성함"
                            value={OrderCustomerName}
                            setValue={setOrderCustomerName}
                            value2={customerPhone}
                            setValue2={setCustomerPhone}
                            inputWidth={20}
                            placeholder2="연락처"
                        />
                        <BoxContent
                            title="시공"
                            content={`${orderDobae === 1 ? "도배 " : ""}${
                                orderJangpan === 1 ? "장판" : " "
                            }${orderMaru === 1 ? "마루" : " "}`}
                        />
                        <BoxDateInput
                            title="시공일"
                            startvalue={customerSigongDate}
                            setStartValue={setCustomerSigongDate}
                        />
                        <BoxAdressInput
                            value={OrderAddress}
                            setValue={setOrderAddress}
                            detailValue={OrderaddressDetail}
                            setDetailValue={setOrderAddressDetail}
                        />
                        {/* <BoxContent
                            title="최종견적"
                            content={`${(
                                dobaePrice + zangpanPrice + maruPrice || 0
                            ).toLocaleString()}원`}
                        /> */}
                        <BoxTextInput
                            value={customerFinalEstimate}
                            setValue={setCustomerFinalEstimate}
                            title="최종견적"
                            unit="원"
                            inputWidth={20}
                            readOnly={true}
                        />
                        <BoxRadioboxInput
                            radioValue={useRadio}
                            setRadioValue={setUseRadio}
                            title="용도"
                            Radioes={consts.useBox}
                        />
                    </div>
                </div>
                <div className="BorderBox">
                    <div className="title">
                        <h1>주문정보</h1>
                    </div>
                    <div className="contentWrap">
                        <BoxDropdownInput
                            value={agency}
                            setValue={setAgency}
                            title="대리점"
                            Dropdowns={agencyList}
                            defaultValue={"없음"}
                        />
                        <BoxTextInput
                            title="배송비"
                            value={deliveryFee}
                            setValue={setDeliveryFee}
                            inputWidth={20}
                            unit="원"
                        />
                        <BoxDateInput
                            title="배송일"
                            startvalue={orderSigongDate}
                            setStartValue={setOrderSigongDate}
                        />

                        <BoxAdressInput
                            value={orderAddress2}
                            setValue={setOrderAddress2}
                            detailValue={orderAddressDetail2}
                            setDetailValue={setOrderAddressDetail2}
                        />
                        <BoxTextInput
                            title="수령자"
                            placeholder="성함"
                            value={orderName}
                            setValue={setOrderName}
                            value2={orderPhone}
                            setValue2={setOrderPhone}
                            inputWidth={20}
                            placeholder2="연락처"
                        />

                        <BoxTextInput
                            value={orderAmount}
                            setValue={setOrderAmount}
                            title="주문금액"
                            unit="원"
                            inputWidth={20}
                            readOnly={true}
                        />
                        <BoxTextInput
                            title="합계금액"
                            unit="원"
                            inputWidth={20}
                            value={orderAmount + deliveryFee}
                            // setValue={setTotalAmount}
                            readOnly={true}
                        />
                        <BoxMemoInput
                            title="메모"
                            value={Ordermemo}
                            setValue={setOrderMemo}
                        />
                    </div>
                </div>
                <div className="title_color_box">
                    <div
                        className="box_title"
                        style={{ backgroundColor: "#41b06e", color: "white" }}
                    >
                        <h1>도배</h1>
                    </div>
                    <div className="contentColorWrap">
                        <div className="subTitleCircle">
                            <div className="GreenCircle"></div>
                            <h3>선택벽지</h3>
                        </div>
                        <TableOrderEditClasfy
                            list={dobaeList}
                            setList={setDobaeList}
                            ClkBtn={btnDobaePop}
                            setDeleteIdx={setMaterialDeleteIdx}
                            setDeletePop={setDeletePop}
                            deletePop={deletePop}
                            setIsDelete={setIsDelete}
                            setFlag={setPopupFlag}
                            FlagValue={1}
                        />
                        <div className="subTitleCircle">
                            <div className="GreenCircle"></div>
                            <h3>부자재</h3>
                        </div>
                        <TableOrderEditClasfy2
                            list={subList}
                            setList={setSubList}
                            ClkBtn={btnDobaeSubPop}
                            setDeleteIdx={setMaterialDeleteIdx2}
                            setDeletePop={setDeletePop}
                            deletePop={deletePop}
                            setIsDelete={setIsDelete}
                            setFlag={setPopupFlag}
                            FlagValue={2}
                        />
                    </div>
                </div>
                <div className="twoBtn_wrap">
                    <button className="btn btn8" onClick={() => ListGoback()}>
                        목록
                    </button>
                    <button className="btn btn9" onClick={() => submit()}>
                        저장
                    </button>
                </div>
            </div>
        </>
    );
}
export default OrderManageRegister;
