import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
    BoxContent,
    BoxEditer,
    BoxMemoInput,
    BoxTextInput,
} from "../../components/BoxInfo";
import { useEffect, useState } from "react";
import { set } from "lodash";
import { v4 as uuidv4 } from "uuid";
import consts from "../../libs/consts";
import ApiData from "../../libs/api";
import routes from "../../libs/routes";
import { usePostData } from "../../utils/service";
import { PopupComp, PopupConfirmComp } from "../../components/PopUp";
import { useUser } from "../../zustand/Login";

function ManagerAccDetail() {
    const navigate = useNavigate();
    const { mbData } = useUser();

    const RegisterApi = usePostData(ApiData.adminRegister);
    const UpdateApi = usePostData(ApiData.adminUpdate);
    const DetailApi = usePostData(ApiData.adminDetail);
    const DeletelApi = usePostData(ApiData.adminDelete);
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const [updateIdx, setUpdateIdx] = useState(0);
    const [roleTitle, setRoleTitle] = useState("");
    const [adminName, setAdminName] = useState("");
    const [adminId, setAdminId] = useState("");
    const [adminMemo, setAdminMemo] = useState("");
    const [deletePop, setDeletePop] = useState(false);
    const [confirmPop, setConfirmPop] = useState(false);
    const [isSucess, setIsSucess] = useState(false);
    const [PopContent, setPopContent] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [password, setpassword] = useState("");
    const [passwordCheck, setpasswordCheck] = useState("");

    const [adminLevel, setAdminLevel] = useState([]);

    function isValidJSON(json) {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }
    useEffect(() => {
        if (index === null) return;
        DetailApi.mutateAsync({ mbIdx: mbData.idx, admIdx: index })
            .then(({ data }) => {
                setUpdateIdx(data.idx);
                setRoleTitle(data.role_title);
                setAdminName(data.mb_name);
                setAdminId(data.mb_id);
                setAdminMemo(data.memo);

                setAdminLevel(
                    isValidJSON(data.mb_level)
                        ? JSON.parse(data.mb_level)
                        : null
                );
            })
            .catch((e) => {
                setPopContent(e?.response?.data);
                setConfirmPop(!confirmPop);
            });
    }, [index]);
    const validateInputs = () => {
        if (roleTitle === "") {
            return "관리역활을 입력해주세요.";
        } else if (adminName === "") {
            return "담당자명을 입력해주세요.";
        } else if (adminId === "") {
            return "아이디를 입력해주세요.";
        } else if (adminId.length < 4) {
            return "아이디는 4자리 이상 입력해주세요.";
        } else if (!consts.idPattern.test(adminId)) {
            return "아이디는  영문, 숫자를 포함해야 합니다.";
        } else if (password === "") {
            return "비밀번호를 입력해주세요.";
        } else if (!consts.passwordPattern.test(password)) {
            return "비밀번호는 8~20자리의 영문, 숫자, 특수문자를 포함해야 합니다.";
        } else if (password !== passwordCheck) {
            return "비밀번호가 일치하지 않습니다.";
        } else if (adminMemo === "") {
            return "비고를 입력해주세요.";
        } else if (adminLevel.length === 0) {
            return "접근권한을 선택해주세요.";
        }
        return "";
    };
    const validateInputs2 = () => {
        if (roleTitle === "") {
            return "관리역활을 입력해주세요.";
        } else if (adminName === "") {
            return "담당자명을 입력해주세요.";
        } else if (adminId === "") {
            return "아이디를 입력해주세요.";
        } else if (adminId.length < 4) {
            return "아이디는 4자리 이상 입력해주세요.";
        } else if (!consts.idPattern.test(adminId)) {
            return "아이디는  영문, 숫자를 포함해야 합니다.";
        } else if (oldPassword > 0) {
            if (password === "") {
                return "비밀번호를 입력해주세요.";
            } else if (!consts.passwordPattern.test(password)) {
                return "비밀번호는 8~20자리의 영문, 숫자, 특수문자를 포함해야 합니다.";
            } else if (password !== passwordCheck) {
                return "비밀번호가 일치하지 않습니다.";
            }
        } else if (adminMemo === "") {
            return "비고를 입력해주세요.";
        } else if (adminLevel.length === 0) {
            return "접근권한을 선택해주세요.";
        }
        return "";
    };

    const SubmitClk = () => {
        const error = validateInputs();
        let sender = {
            role_title: roleTitle,
            mb_name: adminName,
            mb_id: adminId,
            mb_pass: password,
            memo: adminMemo,
            mb_level: adminLevel,
        };

        setPopContent(error);
        setConfirmPop(!confirmPop);
        // console.log("sender", sender);
        if (error === "") {
            RegisterApi.mutateAsync(sender)
                .then(({ data }) => {
                    setPopContent("저장되었습니다.");
                    setIsSucess(true);
                    setConfirmPop(!confirmPop);
                })
                .catch((e) => {
                    setPopContent(e.response.data);
                    setConfirmPop(!confirmPop);
                });
        }
    };

    const SubmitmodifyClk = () => {
        const error = validateInputs2();
        let sender = {
            mbIdx: mbData.idx,
            admIdx: updateIdx,
            role_title: roleTitle,
            mb_name: adminName,
            mb_id: adminId,
            mb_pass: oldPassword,
            new_pass: password,
            memo: adminMemo,
            mb_level: adminLevel,
        };
        setPopContent(error);
        setConfirmPop(!confirmPop);
        // console.log(error);

        if (error === "") {
            UpdateApi.mutateAsync(sender)
                .then(({ data }) => {
                    // console.log(1111);
                    setPopContent("저장되었습니다.");
                    setIsSucess(true);
                    setConfirmPop(!confirmPop);
                })
                .catch((e) => {
                    // console.log(2222);
                    setPopContent(e.response.data);
                    setConfirmPop(!confirmPop);
                });
        }
    };

    const deleteClk = () => {
        // console.log("delete");

        DeletelApi.mutateAsync({ mbIdx: mbData.idx, admIdx: updateIdx })
            .then(({ data }) => {
                setPopContent("삭제되었습니다.");
                setConfirmPop(!confirmPop);
                setIsSucess(true);
            })
            .catch((e) => {
                setPopContent(e.response.data);
                setConfirmPop(!confirmPop);
            });
    };

    const handleChange = (e) => {
        if (e.target.checked) {
            setAdminLevel([...adminLevel, e.target.value]);
        } else {
            setAdminLevel(
                adminLevel.filter((value) => value !== e.target.value)
            );
        }
    };

    return (
        <>
            <PopupComp
                popTitle={"삭제하기"}
                popContent={"삭제하시겠습니까?"}
                isYes={true}
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteClk}
            />
            <PopupConfirmComp
                popContent={PopContent}
                OpenPop={confirmPop}
                setOpenPop={setConfirmPop}
                SubmitClk={
                    isSucess ? () => navigate(routes.management3) : () => {}
                }
            />
            <div className="content ">
                <div className="title_container">
                    <p className="title">담당자계정관리</p>
                    <div
                        style={{
                            width: "16%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                        className="innerBtnWrap"
                    >
                        {index ? (
                            <button
                                className="btn btn8"
                                onClick={() => {
                                    setDeletePop(!deletePop);
                                }}
                            >
                                삭제
                            </button>
                        ) : (
                            <div></div>
                        )}
                        <button
                            className="btn btn9"
                            onClick={index ? SubmitmodifyClk : SubmitClk}
                        >
                            {index ? "수정" : "등록"}
                        </button>
                    </div>
                </div>
                <div className="BorderBox">
                    <div className="title">
                        <h1>기본정보</h1>
                    </div>
                    <div className="contentWrap">
                        <BoxTextInput
                            title="관리역활"
                            value={roleTitle}
                            setValue={setRoleTitle}
                            inputWidth={20}
                        />
                        <BoxTextInput
                            title="담당자명"
                            value={adminName}
                            setValue={setAdminName}
                            inputWidth={20}
                        />
                        <BoxTextInput
                            title="아이디"
                            value={adminId}
                            setValue={setAdminId}
                            placeholder="4자리 이상 영문, 숫자"
                            inputWidth={20}
                        />
                        {index && (
                            <BoxTextInput
                                title="기존 비밀번호"
                                placeholder="8~20자리 이상 영문, 숫자, 특수문자"
                                value={oldPassword}
                                setValue={setOldPassword}
                                inputWidth={25}
                                type="password"
                            />
                        )}
                        <BoxTextInput
                            title={index ? "새로운 비밀번호" : "비밀번호"}
                            placeholder="8~20자리 이상 영문, 숫자, 특수문자"
                            value={password}
                            setValue={setpassword}
                            inputWidth={25}
                            type="password"
                        />
                        <BoxTextInput
                            title="비밀번호 확인"
                            placeholder="8~20자리 이상 영문, 숫자, 특수문자"
                            value={passwordCheck}
                            setValue={setpasswordCheck}
                            inputWidth={25}
                            type="password"
                        />
                        <BoxMemoInput
                            value={adminMemo}
                            setValue={setAdminMemo}
                            title="비고"
                        />
                    </div>
                </div>
                <div className="BorderBox">
                    <div className="title">
                        <h1>접근권한</h1>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            padding: " 10px 0",
                        }}
                    >
                        {consts.level.map((x, i) => {
                            const id = uuidv4();
                            return (
                                <div key={i} className="ChkBoxOne">
                                    <input
                                        type="checkbox"
                                        checked={adminLevel
                                            .map((c) => parseInt(c))
                                            .includes(x.value)}
                                        id={id}
                                        name={x.label}
                                        value={x.value}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor={id}>{x.label}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManagerAccDetail;
