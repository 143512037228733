import { useEffect, useState } from "react";
import InputDate from "../../components/InputDate";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import { InputCheckBox } from "../../components/InputCheckBox";
import { InputRadioBox } from "../../components/InputRadioBox";
import { TableJobFinished } from "../../components/Tables";
import consts from "../../libs/consts";
import images from "../../libs/images";
import Pagination from "react-js-pagination";
import { usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import moment from "moment";
import useLoadingStore from "../../zustand/Loading";
import { set } from "lodash";

function JobFinished() {
    const workEndList = usePostData(ApiData.workEndList);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const [sdate, setsdate] = useState("");
    const [edate, setedate] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [manager, setManager] = useState("");
    const [workerList, setWorkerList] = useState([]);
    const [banzangList, setBanzangList] = useState([
        { value: "", text: "전체" },
    ]);
    const [balance, setBalance] = useState(0);
    const [status, setStatus] = useState(0);
    const [nonDeposit, setNonDeposit] = useState(0);
    const [endReport, setEndReport] = useState(0);
    const [page, setPage] = useState(1);
    const [Alldata, setAlldata] = useState([]);
    const [dataRows, setDataRows] = useState([]);
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    const resetBtn = () => {
        setsdate("");
        setedate("");
        setSearchVal("");
        setManager(0);
        setBalance(0);
        setNonDeposit(0);
        setEndReport(0);
        workEndListApi(true);
    };
    const workEndListApi = (reset = false) => {
        let sender = {
            start_date: sdate,
            end_date: edate,
            searchVal: searchVal,
            admin_idx: manager === 0 ? "" : manager,
            work_end_status: status === 0 ? "" : status,
            balance_paydate: balance === 0 ? "" : balance,
            balance_msg_status: nonDeposit === 0 ? "" : nonDeposit,
            report_type: endReport === 0 ? "" : endReport,
            page: page - 1,
        };
        if (reset == true) {
            sender = {
                start_date: "",
                end_date: "",
                searchVal: "",
                admin_idx: "",
                work_end_status: "",
                balance_paydate: "",
                balance_msg_status: "",
                report_type: "",
                page: 0,
            };
        }
        // loadingStart();
        workEndList
            .mutateAsync(sender)
            .then((data) => {
                // loadingEnd();
                // console.log("data", data);
                setAlldata(data.data);
                setDataRows(data.data.rows);
                setWorkerList(data.data.workerList);
            })
            .catch((err) => {
                // loadingEnd();
                toast.error(err.response.data);
            });
    };
    const excelDown = async () => {
        try {
            let sender = {
                start_date: "",
                end_date: "",
                searchVal: "",
                admin_idx: "",
                work_end_status: "",
                balance_paydate: "",
                balance_msg_status: "",
                report_type: "",
                excelDown: 1,
                // page: 0,
            };
            // 서버에서 주문관리 리스트 가져오기
            let excel = await workEndList
                .mutateAsync(sender)
                .then(({ data }) => {
                    // console.log("data", data);
                    return data;
                })
                .catch((e) => {
                    // console.log(e.response.data);
                });
            const excelData = excel;
            // console.log(excelData);

            // Excel 파일 생성 및 다운로드
            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: {
                    bold: true,
                    color: { rgb: "000000" },
                    name: "함초롱바탕",
                    sz: 13,
                },
                fill: { fgColor: { rgb: "f1faee" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };
            const dataStyle = {
                font: { color: { rgb: "000000" }, name: "함초롱바탕", sz: 11 },
                fill: { fgColor: { rgb: "ffffff" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };

            // 열의 폭을 정의
            const colWidths = [110, 200, 80, 80, 150, 130, 80, 80, 80, 80];

            // cols 속성을 사용하여 각 열의 폭을 조절
            const cols = colWidths.map((width) => ({ wpx: width }));

            const headerRow = [
                { v: "상태", t: "s", s: headerStyle },
                { v: "지역", t: "s", s: headerStyle },
                { v: "고객", t: "s", s: headerStyle },
                { v: "시공", t: "s", s: headerStyle },
                { v: "시공일", t: "s", s: headerStyle },
                { v: "하자", t: "s", s: headerStyle },
                { v: "반장", t: "s", s: headerStyle },
                { v: "리포트문자", t: "s", s: headerStyle },
                { v: "리포트수신", t: "s", s: headerStyle },
                { v: "잔금문자", t: "s", s: headerStyle },
                { v: "잔금입금", t: "s", s: headerStyle },
                { v: "금액", t: "s", s: headerStyle },
            ];
            const dataRows = excelData?.map((emp) => {
                const commonData = [
                    {
                        v: `${emp?.work_end_date !== null ? "완료" : "진행중"}`,
                        t: "s",
                        s: dataStyle,
                    }, //상태

                    {
                        v: `${
                            emp.address !== null && emp.address_detail !== null
                                ? emp.address + " " + emp.address_detail
                                : emp.address !== null &&
                                  emp.address_detail === null
                                ? emp.address
                                : emp.address === null &&
                                  emp.address_detail !== null
                                ? emp.address_detail
                                : "-"
                        }`,
                        t: "s",
                        s: dataStyle,
                    }, // 지역
                    {
                        v: `${emp.cutomer_name || "-"}`,
                        t: "s",
                        s: dataStyle,
                    }, // 고객
                ];
                const productData = [
                    {
                        product: "도배",
                        date: emp.dobae_date,
                        isProduct: emp.dobae === 1,
                        asChk: emp.dobae_as_chk === 1 ? "도배하자" : "-",
                        banzang: emp.dobae_banzang ? emp.dobae_banzang : "-",
                        balsinChk:
                            emp.dobae_report_balsin_chk === 1 ? "O" : "X",
                        report: emp.dobae_report_susin_chk === 1 ? "O" : "X",
                    },
                    {
                        product: "장판",
                        date: emp.zangpan_date,
                        isProduct: emp.zangpan === 1,
                        asChk: emp.zangpan_as_chk === 1 ? "장판하자" : "-",
                        banzang: emp.zangpan_banzang
                            ? emp.zangpan_banzang
                            : "-",
                        balsinChk:
                            emp.zangpan_report_balsin_chk === 1 ? "O" : "X",
                        report: emp.zangpan_report_susin_chk === 1 ? "O" : "X",
                    },
                ];

                return productData
                    .filter((pd) => pd.isProduct)
                    .map((pd) => [
                        ...commonData,
                        {
                            v: pd.product,
                            t: "s",
                            s: dataStyle,
                        }, //제품
                        {
                            v: pd.date
                                ? moment(pd.date).format("YYYY.MM.DD")
                                : "",
                            t: "s",
                            s: dataStyle,
                        }, //시공일

                        {
                            v: pd.asChk,
                            t: "s",
                            s: dataStyle,
                        }, //하자
                        {
                            v: pd.banzang,
                            t: "s",
                            s: dataStyle,
                        }, //반장
                        {
                            v: pd.balsinChk,
                            t: "s",
                            s: dataStyle,
                        }, //발신
                        {
                            v: pd.report,
                            t: "s",
                            s: dataStyle,
                        }, //리포트
                        {
                            v: emp.balance_msg_status === 0 ? "X" : "O",
                            t: "s",
                            s: dataStyle,
                        }, //잔금문자
                        {
                            v: emp.balance_paydate !== null ? "O" : "X",
                            t: "s",
                            s: dataStyle,
                        }, //잔금 입금
                        {
                            v: emp.price,
                            t: "s",
                            s: dataStyle,
                        }, //금액
                    ]);
            });

            const rows = [headerRow, ...dataRows.flat()];

            // 새로운 Sheet 객체 생성
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws["!cols"] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, "상담신청");

            // 파일 다운로드
            XLSX.writeFile(wb, "JobFinished.xlsx");

            console.log("Excel 파일 생성 및 다운로드 완료");
        } catch (error) {
            console.error("Excel 파일 생성 및 다운로드 실패", error);
        }
    };
    useEffect(() => {
        workerList.map((item) => {
            if (item.banzang_idx !== 0) {
                setBanzangList((prev) => [
                    ...prev,
                    {
                        value: item.banzang_name,
                        text: item.banzang_name,
                    },
                ]);
            }
        });
        return () => {
            setBanzangList([{ value: "", text: "전체" }]);
        };
    }, [workerList]);
    useEffect(() => {
        workEndListApi();
    }, [page]);
    return (
        <>
            <div className="content ">
                <p className="title">작업종료</p>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <InputDate
                                label="시공일"
                                type="text"
                                placeholder="시작날짜"
                                placeholderEnd="종료날짜"
                                name="ipd1"
                                dateValue={sdate}
                                setDateValue={setsdate}
                                dateValueEnd={edate}
                                setDateValueEnd={setedate}
                                multiple={true}
                            />
                        </div>
                        <div className="search_form">
                            <Input
                                className="input_text"
                                type="text"
                                placeholder="연락처, 고객명"
                                name="stx"
                                value={searchVal}
                                setValue={setSearchVal}
                                label="검색"
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        workEndListApi();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="search_box_wrap">
                        <div
                            className="search_box_wrapper"
                            style={{ marginBottom: "0px" }}
                        >
                            <InputSelect
                                name="status"
                                label="담당자"
                                value={manager}
                                setValue={setManager}
                                option={banzangList}
                            />
                            <InputRadioBox
                                title="상태"
                                Radioes={consts.status}
                                setValue={setStatus}
                                value={status}
                                radioName={"status"}
                            />
                        </div>
                        <InputRadioBox
                            title="잔금"
                            Radioes={consts.balancebox}
                            setValue={setBalance}
                            value={balance}
                            radioName={"balance"}
                        />
                    </div>
                    <div className="search_box_wrap">
                        <InputRadioBox
                            title="미입금문자"
                            Radioes={consts.noMoneymail}
                            setValue={setNonDeposit}
                            value={nonDeposit}
                            radioName="nonDeposit"
                        />
                        <InputRadioBox
                            title="종료리포트"
                            Radioes={consts.endReport}
                            setValue={setEndReport}
                            value={endReport}
                            radioName="endReport"
                        />
                    </div>
                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button className="btn btn8" onClick={resetBtn}>
                                초기화
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() => {
                                    workEndListApi();
                                }}
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>
                <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                    [오늘 : {Alldata?.today || 0}건, 검색결과 :{" "}
                    {Alldata?.count || 0}
                    건]
                </p>
                <TableJobFinished list={dataRows} />
                <div className="BottomContent">
                    <div className="ButtonContent"></div>
                    <div className="PaginationContent">
                        <Pagination
                            className="my-pagination"
                            // 현제 보고있는 페이지
                            activePage={page}
                            // 한페이지에 출력할 아이템수
                            itemsCountPerPage={20}
                            // 총 아이템수
                            totalItemsCount={Alldata?.count || 0}
                            // 표시할 페이지수
                            pageRangeDisplayed={10}
                            // 함수
                            onChange={handlePageChange}
                            nextPageText=""
                            prevPageText=""
                            firstPageText=""
                            lastPageText=""
                        ></Pagination>
                    </div>
                    <div
                        className="excelContent"
                        style={{ cursor: "pointer" }}
                        onClick={excelDown}
                    >
                        <img src={images.excel} alt="excel" />
                        <p>엑셀다운로드</p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default JobFinished;
