import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["scd"] = "APLYNXADMINSCD";

const getData = (url) => {
    let dm = "https://api.8amdobae.co.kr/"; // PNSOFT TEST SERVER
    axios.defaults.headers.common["authorization"] =
        localStorage.getItem("tokens");
    return axios.post(dm + `${url}`);
};

const postData = (url, sender = {}) => {
    let dm = "https://api.8amdobae.co.kr/"; // PNSOFT TEST SERVER
    axios.defaults.headers.common["authorization"] =
        localStorage.getItem("tokens");

    return axios.post(dm + `${url}`, sender);
};

const SusinPostData = (url, sender = {}) => {
    let dm = "https://api.teltok.kr/teltok-auth/openapi/v2/"; // PNSOFT TEST SERVER

    return axios.post(dm + `${url}`, sender);
};
// const SusinSendPostData = (url, sender = {}) => {
//     let dm = "https://api.teltok.kr/teltok-openapi/openapi/v2/"; // PNSOFT TEST SERVER
//     axios.defaults.headers.common["Authorization"] =
//         "Bearer " + localStorage.getItem("tokensAccessMsg");

//     return axios.post(dm + `${url}`, sender);
// };

export function useGetData(url) {
    return useQuery({ queryKey: [url], queryFn: () => getData(url) });
}

export function usePostData(url, sender) {
    return useMutation({
        mutationKey: [url],
        mutationFn: (data) => postData(url, data || sender),
    });
}
export function useSusinPostData(url, sender) {
    return useMutation({
        mutationKey: [url],
        mutationFn: (data) => SusinPostData(url, data || sender),
    });
}
// export function useSusinSendPostData(url, sender) {
//     return useMutation({
//         mutationKey: [url],
//         mutationFn: (data) => SusinSendPostData(url, data || sender),
//     });
// }
