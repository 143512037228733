import React, { useEffect, useState } from "react";

// import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import moment from "moment";

// import AnimateHeight from "react-animate-height";

// import images from "../libs/images";
import consts from "../libs/consts";
// import routes from "../libs/routes";
// import { addrList } from "../libs/dummy";

// import { numFormat, useWindowSize, dataFormat } from "../utils/utils";

// import InputSelect from "../components/InputSelect";
// import InputSelectMulti from "../components/InputSelectMulti";
// import InputRange from "../components/InputRange";

// import Button from "../components/Button";

// import { searchSetData, reset } from "../redux/searchSlice";

const lnbShow = consts.lnbShow;
const lnvHide = consts.lnvHide;

export default function LeftNav({ menu }) {
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // const { width, height } = useWindowSize();

    const [show, setShow] = useState(false);

    // const [showM, setShowM] = useState(false);

    // const { csCount, reportCount } = useSelector((s) => s.dataReducer); // 전체데이터
    // const { cadastral } = useSelector((s) => s.popupReducer); // 지적도 팝업
    // const summary = useSelector((s) => s.resultReducer.summary);

    useEffect(() => {
        if (
            !lnbShow?.includes(location.pathname) &&
            !lnvHide?.includes(location.pathname)
        ) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [location.pathname]);

    const linkFunc = (link) => {
        // dispatch(reset());
        if (link) {
            // dispatch( setPopupStorage({ key: 'cadastral', value: false }) );
            // dispatch( setPopupStorage({ key: 'drawing', value: false }) );
            navigate(link);
        }
    };

    return (
        <>
            <div
                style={{ display: show ? "block" : "none" }}
                className={`left_nav animate__animated animate__faster ${
                    "animate__fadeIn"
                    // lnbShow?.includes(location.pathname)
                    //     ? "animate__fadeOut"
                    //     : "animate__fadeIn"
                }`}
            >
                {menu?.leftNav?.map((x, i) => {
                    return (
                        <button
                            className={`left_nav_one ${
                                x.valueMulti.includes(location.pathname)
                                    ? "active"
                                    : ""
                            } `}
                            key={i}
                            onClick={() => linkFunc(x.route)}
                        >
                            {x.title}
                            {/* {x.route === routes.service1 && csCount > 0 && (
                                    <span className="left_nav_count">
                                        {csCount}
                                    </span>
                                )}
                                {x.route === routes.service2 && reportCount > 0 && (
                                    <span className="left_nav_count">
                                        {reportCount}
                                    </span>
                                )} */}
                        </button>
                    );
                })}
            </div>
        </>
    );
}
