import { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";

import consts from "../libs/consts";

// import * as APIS from "./service";

export const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; // 휴대폰번호
export const regEmail =
    /^[^\sㄱ-ㅎㅏ-ㅣ가-힣]*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/; // 이메일
export const patternNum = /[0-9]/; // 숫자
export const patternEng = /[a-zA-Z]/; // 영문
export const patternSpc =
    /[\"\'\[\].,;-=~!@#$%^&*()_+|<>?:{}/~`|•√π÷×¶∆£¢€¥^°=\s]/; // 특수문자
export const patternUrl = /^http[s]?:\/\/([\S]{3,})/i; // URL

export const patternSpcInstar = /[^0-9a-zA-Z._]+/g; // 인스타용 허용글자
export const patternNick = /[^0-9a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]+/g; // 닉네임용 허용글자
export const patternBrand =
    /[^0-9a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣.,;-=~!@#$%^&*()_+|<>?:{}/~`\s]+/g; // 브랜드이름, 상호명용 허용글자
export const patternId = /[^0-9a-zA-Z]+/g; // 아이디용 허용글자

export const patternFloat = /^\d*[.]\d*$/; //소수점 한개만
export const patternCoord = /^-?(([-+]?)([\d]{1,3})((\.{1})(\d+))?)/; // 좌표

export const regInstargram =
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/; // 이메일

export const patternKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크

export const regFileDoc =
    /(.*?)\.(jpg|jpeg|png|gif|bmp|svg|pdf|ai|psd|xls|xlsx|ppt|pptx|pem|zip|hwp|txt|doc|docx|mp4)$/; // 문서파일 가능한 확장자
export const regFileImage = /(.*?)\.(jpg|jpeg|png|gif|bmp|svg)$/; // 이미지파일 가능한 확장자
export const regFilePdf = /(.*?)\.(pdf)$/; // PDF파일 가능한 확장자
export const regFileExcel = /(.*?)\.(xlsx|csv)$/; // 엑셀 파일 가능한 확장자

export const hpHypen = (str) => {
    return str.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
        "$1-$2-$3"
    );
};

export const numFormat = (num, cut = 0) => {
    if (num) {
        if (cut) num = Math.floor(num / cut) * cut; // 1000 = 백원단위 절삭
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return 0;
    }
};

export const patternNickFunc = (nick) => {
    /**
        1. 한글 최소 2글자 이상 최대 8글자 제한
        2. 영문 최소 2글자 이상 최대 15글자 제한
        3. 한글 + 영문 + 숫자 혼용 최대 15글자 제한
        4. 숫자만 사용 최대 8글자 제한
        5. 자음,모음,특수기호 허용 x
    */

    let regx = /[^0-9a-zA-Z가-힣]+/g; // 모음과 자음은 제외

    if (regx.test(nick) || nick.length < 2 || nick.length > 15) return true;

    let regxKor = /[가-힣]/; // 한글
    let regxEng = /[a-zA-Z]/; // 영어
    let regxNum = /[0-9]/; // 숫자

    let korCheck = regxKor.test(nick);
    let engCheck = regxEng.test(nick);
    let numCheck = regxNum.test(nick);

    // 영어만 작성한경우
    if (!korCheck && engCheck && !numCheck) {
        return nick.length > 15 ? true : false;
    }

    // 나머지
    return nick.length > 8 ? true : false;
};

export const regPassword = (pw) => {
    return (
        pw.length > 7 &&
        pw.length < 13 &&
        patternNum.test(pw) &&
        patternSpc.test(pw) &&
        patternEng.test(pw)
    );
};

export const speak = (text, opt_prop, onEndFunc) => {
    const synth = window.speechSynthesis;

    if (
        typeof SpeechSynthesisUtterance === "undefined" ||
        typeof window.speechSynthesis === "undefined"
    ) {
        alert("이 브라우저는 음성 합성을 지원하지 않습니다.");
        return;
    }

    const voices = synth.getVoices();

    synth.cancel(); // 현재 읽고있다면 초기화

    const prop = opt_prop || {};

    const speechMsg = new SpeechSynthesisUtterance();
    speechMsg.rate = prop.rate || 1; // 속도: 0.1 ~ 10
    speechMsg.pitch = prop.pitch || 1; // 음높이: 0 ~ 2
    speechMsg.lang = prop.lang || "ko-KR";
    speechMsg.text = text;

    // SpeechSynthesisUtterance에 저장된 내용을 바탕으로 음성합성 실행
    synth.speak(speechMsg);

    speechMsg.onend = function () {
        onEndFunc();
    };
};

export const speakEnd = (text, opt_prop) => {
    const synth = window.speechSynthesis;
    synth.cancel();
};

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        const tick = () => {
            const { current } = savedCallback;

            if (current !== undefined && current !== null) {
                current();
            }
        };
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
        return () => {};
    }, [delay]);
};

export const findJson = (option, idx) => {
    if (!option) return "";

    return option?.find((item) => item?.idx === idx)?.title;
};

export const findJsonCl = (object, val) => {
    let title = "";

    object.forEach((one, index) => {
        if (one.idx * 1 === val * 1) {
            title = one.cl;
        }
    });
    return title;
};

export const findJsonNum = (object, val) => {
    let title = 0;

    object.forEach((one, index) => {
        if (one.idx * 1 === val * 1) {
            title = one.num;
        }
    });
    return title;
};

export const findJsonKey = (object, val, key) => {
    let title = 0;

    object.forEach((one, index) => {
        if (one.idx * 1 === val * 1) {
            title = one[key];
        }
    });
    return title;
};

export const findArrInclues = (object, val, keyText) => {
    let chk = false;

    object.forEach((one, index) => {
        //console.log(one);
        //console.log(val);
        if (!keyText) {
            if (one * 1 === val * 1) {
                chk = true;
                return;
            }
        } else {
            if (one[keyText] * 1 === val * 1) {
                chk = true;
                return;
            }
        }
    });
    return chk;
};

export const getFileName = (url) => {
    let urls = url.split("/");
    return urls[urls.length - 1];
};

export const goLink = (navigate, route, state) => {
    //console.log(state);
    navigate(route, { state: state });
};

export const clickImgS3 = (imgsrc) => {
    window.open(consts.s3url + imgsrc, "", "width=600px, height=600px");
};
export const clickDownS3 = (imgsrc) => {
    window.open(consts.s3url + imgsrc);
};

export const clickImg = (imgsrc) => {
    var imageWin = new Image();
    imageWin = window.open("", "", "");
    imageWin.document.write("<html><body style='margin:0'>");
    imageWin.document.write(
        "<img src='" +
            imgsrc +
            "' border=0 style='width: 100%; height: 100%; object-fit: contain;'>"
    );
    imageWin.document.write("</body><html>");
};

export const clickDown = (url, mime = "", name = "") => {
    if (mime === "base") {
        var link = document.createElement("a");
        link.download = name;
        link.href = url;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    } else {
        window.open(url, "", "");
    }
};

export const allSetValue = (i, e, val, list) => {
    //console.log(i);
    //console.log(val);
    //console.log(e.target.name);

    // let newFormValues = [...list];
    // newFormValues[i][e.target.name] = val;

    const newFormValues = list.map((item, index) => {
        if (index !== i) {
            return item;
        }
        return {
            ...item,
            [e.target.name]: val,
        };
    });

    return newFormValues;
};

export const allSetValueObject = (e, val, list) => {
    //console.log(e);
    //console.log(val);
    //console.log(list);

    return {
        ...list,
        [e.target.name]: val,
    };
};

export const allSetValueAppend = (i, e, val, list) => {
    //console.log(i);
    //console.log(val);
    //console.log(e.target.name);

    // let newFormValues = [...list];
    // newFormValues[i][e.target.name] = [...newFormValues[i][e.target.name], ...val];

    const newFormValues = list.map((item, index) => {
        if (index !== i) {
            return item;
        }
        return {
            ...item,
            [e.target.name]: [...item[e.target.name], ...val],
        };
    });

    return newFormValues;
};

export const allSetValueMultiAppend = (i, e, ee, val, val2, list) => {
    //console.log(i);
    //console.log(val);
    //console.log(e.target.name);

    // let newFormValues = [...list];
    // newFormValues[i][e.target.name] = [...newFormValues[i][e.target.name], ...val];

    const newFormValues = list.map((item, index) => {
        if (index !== i) {
            return item;
        }
        return {
            ...item,
            [e.target.name]: [...item[e.target.name], ...val],
            [ee.target.name]: [...item[ee.target.name], ...val2],
        };
    });

    return newFormValues;
};

export const allSetValueMulti = (i, e, ee, val, val2, list) => {
    //console.log(i);
    //console.log(val);
    //console.log(e);

    const newFormValues = list.map((item, index) => {
        if (index !== i) {
            return item;
        }
        return {
            ...item,
            [e.target.name]: val,
            [ee.target.name]: val2,
        };
    });

    // let newFormValues = [...caseAll];
    // newFormValues[i][e.target.name] = val;
    return newFormValues;
};

export const allSetDeletePhoto = (i, e, val, list) => {
    //console.log(i);
    let newFormValues = [...list];
    newFormValues[i][e.target.name] = val;

    return newFormValues;
};

export const changeArrayOrder = (list, targetIdx, moveValue) => {
    // 배열값이 없는 경우 나가기

    //console.log(targetIdx, moveValue);
    if (list.length < 0) return list;

    // 이동할 index 값을 변수에 선언
    const newPosition = targetIdx + moveValue;

    // 이동할 값이 0보다 작거나 최대값을 벗어나는 경우 종료
    if (newPosition < 0 || newPosition >= list.length) return list;

    // 임의의 변수를 하나 만들고 배열 값 저장
    const tempList = JSON.parse(JSON.stringify(list));

    // 옮길 대상을 target 변수에 저장하기
    const target = tempList.splice(targetIdx, 1)[0];

    // 새로운 위치에 옮길 대상을 추가하기
    tempList.splice(newPosition, 0, target);
    return tempList;
};

export const dataProcessFunc = (data) => {
    let list = data;

    if (list?.length > 0) {
        return list?.map((x, i) => {
            // //console.log('list i', i);

            let sdate = x.list?.[0]?.sdate || "0000-00-00";
            let edate = x.list?.[0]?.edate || "0000-00-00";
            let total_per = 0;

            let list_2 = x.list.map((xx, ii) => {
                if (!moment(sdate).isBefore(xx.sdate)) {
                    sdate = xx.sdate;
                }
                if (moment(edate).isBefore(xx.edate)) {
                    edate = xx.edate;
                }

                let total_day = moment
                    .duration(moment(xx.edate).diff(moment(xx.sdate)))
                    .asDays();
                let now_day = Math.floor(
                    moment.duration(moment().diff(moment(xx.sdate))).asDays()
                );

                let data_per = Math.round((now_day / total_day) * 100);

                let plan_per =
                    data_per < 0 ? 0 : data_per <= 100 ? data_per : 100;

                total_per += xx.per * 1;

                return {
                    ...xx,
                    total_day: total_day,
                    now_day: now_day,
                    plan_per: plan_per,
                };
            });

            let total_day = moment
                .duration(moment(edate).diff(moment(sdate)))
                .asDays();
            let now_day = Math.floor(
                moment.duration(moment().diff(moment(sdate))).asDays()
            );

            let all_data_per = Math.round((now_day / total_day) * 100);

            let plan_per =
                all_data_per < 0 ? 0 : all_data_per <= 100 ? all_data_per : 100;

            return {
                ...x,
                list: list_2,
                sdate: sdate,
                edate: edate,
                total_day: total_day,
                now_day: now_day,
                plan_per: plan_per,
                total_per: Math.round(total_per / x.list?.length),
            };
        });
    }

    return list;
};

export const adminAuth = (mbData) => {
    if (mbData?.level === 10) return true;

    return false;
};

export const workAuth = (mbData) => {
    if (mbData?.auth_work === 2 || mbData?.level === 10) return true;

    return false;
};

export const noteAuth = (mbData) => {
    if (mbData?.auth_note === 2 || mbData?.level === 10) return true;

    return false;
};

export const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
};

export const useAutosizeTextArea = (textAreaRef, value) => {
    useEffect(() => {
        if (textAreaRef) {
            // We need to reset the height momentarily to get the correct scrollHeight for the textarea
            textAreaRef.style.height = "0px";
            const scrollHeight = textAreaRef.scrollHeight;

            // We then set the height directly, outside of the render loop
            // Trying to set this with state or a ref will product an incorrect value.
            textAreaRef.style.height = scrollHeight + "px";
        }
    }, [textAreaRef, value]);
};

export const autolink = (text) => {
    if (text) {
        var regURL = new RegExp(
            "(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)",
            "gi"
        );
        text = text.replace(
            regURL,
            "<a className='link' href='$1://$2' target='_blank'>$1://$2</a>"
        );
    }

    return text;
};

export const getMakerClass = (item) => {
    let makerClass = "marker6";

    if (item?.item_H && item?.item_H !== "0" && item?.item_H !== 0) {
        makerClass = "marker1";
        return makerClass;
    }
    if (item?.item_BN) {
        makerClass = "marker2";
        return makerClass;
    }

    if (item?.item_K * 1 <= 5000) {
        makerClass = "marker3";
        return makerClass;
    }
    if (item?.item_K * 1 <= 10000) {
        makerClass = "marker4";
        return makerClass;
    }
    if (item?.item_K * 1 <= 20000) {
        makerClass = "marker5";
        return makerClass;
    }

    return makerClass;
};

export const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
};

export const dataFormat = (colum, dataOri) => {
    let data = dataOri;
    if (!data) return "";

    /* format 변환 */
    if (colum?.format === "int") {
        data = numFormat(Math.floor(data * 1));
    }
    if (colum?.formatDate) {
        if (data * 1 !== 0 && moment(data).isValid()) {
            data = moment(data).format(colum?.formatDate);
        }
    }
    /* format 변환 끝*/

    /* unit 검사 */
    if (colum?.unit) {
        data = data + "" + colum?.unit;
    }

    return data;
};

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timer);
        }; //value 변경 시점에 clearTimeout을 해줘야함.
    }, [value]);

    return debouncedValue;
};

// export const pdfViewer = async (urls, fileName) => {
//     let sender = {
//         urls: urls,
//         fileName: fileName,
//     };

//     let signUrl = await APIS.postData("/getS3Url", sender)
//         .then(({ data }) => {
//             console.log("download", data);
//             return data;
//         })
//         .catch((e) => {
//             toast.error(e.response.data, consts.toastErrorOption);
//             return false;
//         });

//     return signUrl;
// };

// export const clickDownS3FileName = (urls, fileName) => {
//     let sender = {
//         urls: urls,
//         fileName: fileName,
//     };

//     APIS.postData("/getS3Url", sender)
//         .then(({ data }) => {
//             console.log("download", data);

//             const a = document.createElement("a");
//             a.href = data;
//             a.download = fileName;
//             document.body.appendChild(a);
//             a.click();
//             a.remove();
//         })
//         .catch((e) => {
//             toast.error(e.response.data, consts.toastErrorOption);
//         });
// };

export const isJson = (str) => {
    try {
        var json = JSON.parse(str);
        return typeof json === "object";
    } catch (e) {
        return false;
    }
};

// 동일 매물 filter 함수
export const sameFunc = (ori, item) => {
    if (!ori || !item) return false;

    return (
        ori?.idx !== item?.idx &&
        ori?.dong === item?.dong &&
        ori?.jibun === item?.jibun &&
        ori?.building_floor === item?.building_floor &&
        ori?.building_unit === item?.building_unit &&
        ori?.sell_type === item?.sell_type &&
        (ori?.sell_type === "매매"
            ? ori?.price_sell === item?.price_sell
            : ori?.sell_type === "전세"
            ? ori?.price_all === item?.price_all
            : ori?.price_all === item?.price_all &&
              ori?.price_month === item?.price_month)
    );
};
// 동일 매물끼리 묶기
export const sameListFunc = async (arr) => {
    const result = arr.reduce((carry, current) => {
        const { dong, jibun, building_floor, building_unit, sell_type } =
            current;
        let el = carry.find(
            (item) =>
                item.dong === current?.dong &&
                item.jibun === current?.jibun &&
                item.building_floor === current?.building_floor &&
                item.building_unit === current?.building_unit &&
                item.sell_type === current?.sell_type
        );
        if (!el) {
            el = {
                dong,
                jibun,
                building_floor,
                building_unit,
                sell_type,
                list: [],
            };
            carry.push(el);
        }
        el.list.push(current);
        return carry;
    }, []);

    let return_arr = [];

    await Promise.all(
        result?.map((x, i) => {
            return_arr.push(...x?.list);
        })
    );

    return return_arr;
};

// 동일 고객 filter 함수
export const sameCustomerFunc = (ori, item) => {
    if (!ori || !item) return false;

    return ori?.idx !== item?.idx && ori?.hp === item?.hp;
};
// 동일 고객끼리 묶기
export const sameCustomerListFunc = async (arr) => {
    const result = arr.reduce((carry, current) => {
        const { hp } = current;
        let el = carry.find((item) => item.hp === current?.hp);
        if (!el) {
            el = { hp, list: [] };
            carry.push(el);
        }
        el.list.push(current);
        return carry;
    }, []);

    let return_arr = [];

    await Promise.all(
        result?.map((x, i) => {
            return_arr.push(...x?.list);
        })
    );

    return return_arr;
};

export const chunkFunc = (data = [], size = 1) => {
    const arr = [];

    for (let i = 0; i < data.length; i += size) {
        arr.push(data.slice(i, i + size));
    }

    return arr;
};
export const resizeFile = (file, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");

            var MAX_WIDTH = maxWidth;
            var MAX_HEIGHT = maxHeight;
            var width = img.width;
            var height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas.toDataURL());
        };
    });
};
