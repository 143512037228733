import { useEffect, useState } from "react";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import images from "../../libs/images";
import Pagination from "react-js-pagination";
import XLSX from "xlsx-js-style";
import { useGetData, usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import { toast } from "react-toastify";
import moment from "moment";
import useLoadingStore from "../../zustand/Loading";

const dummyData = [
    {
        date: "2021-09-01",
        model: "model1",
        reason: "도배",
        add: 25,
        sub: 0,
        manager: "홍길동",
        etc: "비고",
    },
];
function ReturnManagement() {
    const mateReturnList = usePostData(ApiData.mateReturnList);
    const [sdate, setsdate] = useState("");
    const [edate, setedate] = useState("");
    const [agency, setAgency] = useState([{ value: "", text: "대리점" }]);
    const [agencyValue, setAgencyValue] = useState("");
    const [nameList, setNamelist] = useState([{ value: "", text: "이름" }]);
    const [nameValue, setNameValue] = useState("");
    const [datalist, setDatalist] = useState([]);

    const [returner, setReturner] = useState("");
    const [page, setPage] = useState(1);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    const excelDown = async () => {
        try {
            // 서버에서 직원 리스트 가져오기
            let sender = {
                start_date: "",
                end_date: "",
                agency_name: "",
                return_name: "",
                page: 0,
                excelDown: 1,
            };
            let excel = await mateReturnList
                .mutateAsync(sender)
                .then(({ data }) => {
                    return data;
                })
                .catch((e) => {
                    toast.error(e.response.data);
                });
            const excelData = excel;
            //console.log(excelData);

            // Excel 파일 생성 및 다운로드
            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: {
                    bold: true,
                    color: { rgb: "000000" },
                    name: "함초롱바탕",
                    sz: 13,
                },
                fill: { fgColor: { rgb: "f1faee" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };
            const dataStyle = {
                font: { color: { rgb: "000000" }, name: "함초롱바탕", sz: 11 },
                fill: { fgColor: { rgb: "ffffff" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };

            // 열의 폭을 정의
            const colWidths = [110, 110, 110, 110, 110, 110, 110, 110];

            // cols 속성을 사용하여 각 열의 폭을 조절
            const cols = colWidths.map((width) => ({ wpx: width }));

            const headerRow = [
                { v: "반품일", t: "s", s: headerStyle },
                { v: "제조사", t: "s", s: headerStyle },
                { v: "모델번호", t: "s", s: headerStyle },
                { v: "대리점", t: "s", s: headerStyle },
                { v: "반품자", t: "s", s: headerStyle },
                { v: "단가", t: "s", s: headerStyle },
                { v: "수량", t: "s", s: headerStyle },
                { v: "금액", t: "s", s: headerStyle },
            ];

            const dataRows = excelData?.map((emp) => [
                {
                    v: `${moment(emp.return_date).format("YYYY.MM.DD HH:mm")}`,
                    t: "s",
                    s: dataStyle,
                }, //반품일

                {
                    v: `${emp.material_company}`,
                    t: "s",
                    s: dataStyle,
                }, // 제조사
                {
                    v: `${emp.material_number}`,
                    t: "s",
                    s: dataStyle,
                }, // 모델번호
                {
                    v: `${emp.agency_name}`,
                    t: "s",
                    s: dataStyle,
                }, // 대리점
                {
                    v: `${emp.return_mb_name}`,
                    t: "s",
                    s: dataStyle,
                }, // 반품자
                {
                    v: `${emp.return_price.toLocaleString()}원`,
                    t: "s",
                    s: dataStyle,
                }, //단가
                {
                    v: `${emp.return_cnt}`,
                    t: "s",
                    s: dataStyle,
                }, // 수량
                {
                    v: `${(
                        emp.return_price * emp.return_cnt
                    ).toLocaleString()}원`,
                    t: "s",
                    s: dataStyle,
                }, //금액
            ]);

            const rows = [headerRow, ...dataRows];

            // 새로운 Sheet 객체 생성
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws["!cols"] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, "상담신청");

            // 파일 다운로드
            XLSX.writeFile(wb, "ReturnManagement.xlsx");

            console.log("Excel 파일 생성 및 다운로드 완료");
        } catch (error) {
            console.error("Error occurred while downloading Excel", error);
        }
    };
    const resetBtn = () => {
        setsdate("");
        setedate("");
        setAgencyValue("");
        setNameValue("");
        setPage(1);
        mateReturnListApi(true);
    };

    const mateReturnListApi = (reset = false) => {
        let sender = {
            start_date: sdate,
            end_date: edate,
            agency_name: agencyValue,
            return_name: nameValue,
            page: page - 1,
        };
        if (reset === true) {
            sender = {
                start_date: "",
                end_date: "",
                agency_name: "",
                return_name: "",
                page: 0,
            };
        }
        // loadingStart();
        mateReturnList
            .mutateAsync(sender)
            .then(({ data }) => {
                // loadingEnd();
                setDatalist(data);
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
            });
    };
    useEffect(() => {
        datalist?.returnAgencyList?.map((item) => {
            setAgency((prev) => [...prev, { value: item, text: item }]);
        });
        datalist?.returnNameList?.map((item) => {
            setNamelist((prev) => [...prev, { value: item, text: item }]);
        });
        return () => {
            setAgency([{ value: "", text: "대리점" }]);
            setNamelist([{ value: "", text: "이름" }]);
        };
    }, [datalist]);

    useEffect(() => {
        mateReturnListApi();
    }, [page]);

    return (
        <>
            <div className="content ">
                <p className="title">반품관리</p>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <InputDate
                                type="text"
                                placeholder="시작날짜"
                                placeholderEnd="종료날짜"
                                name="ipd1"
                                label="등록일"
                                dateValue={sdate}
                                setDateValue={setsdate}
                                dateValueEnd={edate}
                                setDateValueEnd={setedate}
                                multiple={true}
                            />
                        </div>
                        <div className="search_box_wrapper">
                            <InputSelect
                                name="status"
                                label="대리점"
                                value={agencyValue}
                                setValue={setAgencyValue}
                                option={agency}
                            />
                            <InputSelect
                                name="status"
                                label="반품자"
                                value={nameValue}
                                setValue={setNameValue}
                                option={nameList}
                            />
                        </div>
                    </div>

                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button className="btn btn8" onClick={resetBtn}>
                                초기화
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() => mateReturnListApi()}
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>

                <div className="logSearchResult">
                    <p>
                        조회기간 :{" "}
                        {sdate
                            ? moment(sdate).format("YYYY-MM-DD")
                            : "0000-00-00"}{" "}
                        ~{" "}
                        {edate
                            ? moment(edate).format("YYYY-MM-DD")
                            : "0000-00-00"}
                    </p>
                    <p>수량 : {datalist?.sumCnt}롤</p>
                    <p>
                        합계 :
                        {datalist?.allPrice
                            ? datalist.allPrice.toLocaleString()
                            : 0}
                        원
                    </p>
                </div>
                <div className="table_scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>반품일</th>
                                <th>제조사</th>
                                <th>모델번호</th>
                                <th>대리점</th>
                                <th>반품자</th>
                                <th>단가</th>
                                <th>수량</th>
                                <th>금액</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datalist?.rows?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {moment(item.return_date).format(
                                                "YYYY-MM-DD"
                                            )}
                                        </td>
                                        <td>{item.material_company}</td>
                                        <td>{item.material_number}</td>
                                        <td>{item.agency_name}</td>
                                        <td>{item.return_mb_name}</td>
                                        <td>{item.return_price}</td>
                                        <td>{item.return_cnt}</td>
                                        <td>
                                            {item.return_price *
                                                item.return_cnt}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="BottomContent">
                    <div className="ButtonContent"></div>
                    <div className="PaginationContent">
                        <Pagination
                            className="my-pagination"
                            // 현제 보고있는 페이지
                            activePage={page}
                            // 한페이지에 출력할 아이템수
                            itemsCountPerPage={20}
                            // 총 아이템수
                            totalItemsCount={datalist?.count || 0}
                            // 표시할 페이지수
                            pageRangeDisplayed={10}
                            // 함수
                            onChange={handlePageChange}
                            nextPageText=""
                            prevPageText=""
                            firstPageText=""
                            lastPageText=""
                        ></Pagination>
                    </div>
                    <div
                        className="excelContent"
                        style={{ cursor: "pointer" }}
                        onClick={() => excelDown()}
                    >
                        <img src={images.excel} alt="excel" />
                        <p>엑셀다운로드</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReturnManagement;
