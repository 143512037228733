import { useEffect, useState } from "react";
import ApiData from "../libs/api";
import consts from "../libs/consts";
import { usePostData } from "../utils/service";
import moment from "moment";
import { toast } from "react-toastify";
export const PopupComp = ({
    popTitle,
    popContent = "",
    imgUrl = "",
    isYes = false,
    OpenPop,
    setOpenPop,
    SubmitClk,
}) => {
    const ClosePop = () => {
        setOpenPop(!OpenPop);
    };
    const SubmitClkBtn = () => {
        setOpenPop(!OpenPop);
        SubmitClk();
    };
    return (
        <>
            <div className={`popup ${OpenPop ? "show" : ""}`}>
                <div className="popup_container">
                    <div
                        className="popup_title"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 20px",
                        }}
                    >
                        <p>{popTitle}</p>
                        <div className="exit_btn" onClick={ClosePop}></div>
                    </div>
                    <div className="popup_msg">
                        <p>{popContent}</p>
                    </div>

                    <div className="popupBtnWrap">
                        <button className="btn btn8" onClick={ClosePop}>
                            취소
                        </button>
                        <button className="btn btn9" onClick={SubmitClkBtn}>
                            {isYes ? "네" : "확인"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export const PopupConfirmComp = ({
    popContent,

    OpenPop,
    setOpenPop,
    SubmitClk,
}) => {
    const ClosePop = () => {
        setOpenPop(!OpenPop);
        SubmitClk();
    };
    return (
        <>
            <div className={`popup ${OpenPop ? "show" : ""}`}>
                <div className="popup_container">
                    <div style={{ marginTop: "50px" }} className="popup_msg">
                        <p>{popContent}</p>
                    </div>
                    <div className="popupBtnWrap">
                        <button className="btn btn8" onClick={ClosePop}>
                            닫기
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export const PopupContentNImg = ({
    popContent = "",
    popTitle,
    popImg = "",
    OpenPop,
    setOpenPop,
    // SubmitClk,
}) => {
    const ClosePop = () => {
        setOpenPop(!OpenPop);
        // SubmitClk();
    };

    return (
        <>
            <div className={`popup ${OpenPop ? "show" : ""}`}>
                <div className="popup_container">
                    <div
                        className="popup_title"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 20px",
                        }}
                    >
                        <p>{popTitle}</p>
                        <div className="exit_btn" onClick={ClosePop}></div>
                    </div>
                    {popContent !== "" && (
                        <div className="popup_msg">
                            <p>{popContent}</p>
                        </div>
                    )}

                    {popImg !== "" && (
                        <div className="popup_img">
                            <img
                                src={`${consts.ServerImgFolder}${popImg}`}
                                alt="popupImg"
                            />
                        </div>
                    )}
                    <div className="popupBtnWrap">
                        <button className="btn btn8" onClick={ClosePop}>
                            닫기
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export const PopupCalendar = ({
    OpenPop,
    setOpenPop,
    // SubmitClk,
}) => {
    const visitCalendar = usePostData(ApiData.visitCalendar);
    const [currentDate, setCurrentDate] = useState(moment());
    const [weekDates, setWeekDates] = useState(getWeekDates(currentDate));
    const [calendarList, setCalendarList] = useState([]);
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    const formattedStartOfWeek = `${startOfWeek.format(
        "M/D"
    )}(${convertDayToKorean(startOfWeek.day())})`;
    const formattedEndOfWeek = `${endOfWeek.format("M/D")}(${convertDayToKorean(
        endOfWeek.day()
    )})`;

    function getWeekDates(date) {
        const weekStart = date.clone().startOf("week");
        const dates = Array(7)
            .fill(0)
            .map((_, i) => {
                const day = weekStart.clone().add(i, "days");
                return `${day.format("M/D")}(${convertDayToKorean(day.day())})`;
            });
        return dates;
    }
    function convertDayToKorean(day) {
        const daysInKorean = ["일", "월", "화", "수", "목", "금", "토"];
        return daysInKorean[day];
    }
    const ClosePop = () => {
        setOpenPop(!OpenPop);
        // SubmitClk();
    };
    const handlePrev = () => {
        const newDate = currentDate.clone().subtract(1, "week");
        setCurrentDate(newDate);
        setWeekDates(getWeekDates(newDate));
    };

    const handleNext = () => {
        const newDate = currentDate.clone().add(1, "week");
        setCurrentDate(newDate);
        setWeekDates(getWeekDates(newDate));
    };
    const visitCalendarApi = () => {
        let sender = {
            start_date: startOfWeek.format("YYYY-MM-DD"),
        };

        visitCalendar
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log("data", data);
                setCalendarList(data);
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    useEffect(() => {
        visitCalendarApi();
    }, [OpenPop, weekDates]);
    return (
        <>
            <div className={`popup ${OpenPop ? "show" : ""}`}>
                <div className=" popup_container popup_container_component xl">
                    <div
                        className="popup_title"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 20px",
                        }}
                    >
                        <p>예약현황</p>
                        <div className="exit_btn" onClick={ClosePop}></div>
                    </div>

                    <div>
                        <div className="WeekDate">
                            <p>{`${formattedStartOfWeek} - ${formattedEndOfWeek}`}</p>
                            <button onClick={handlePrev}>이전</button>
                            <button onClick={handleNext}>다음</button>
                        </div>
                        <div>
                            <div className="table_scroll">
                                <table>
                                    <thead>
                                        <tr>
                                            {weekDates?.map((v, i) => {
                                                return <th key={i}>{v}</th>;
                                            })}
                                        </tr>

                                        <tr>
                                            {calendarList?.map(
                                                (item, index) => {
                                                    return (
                                                        <th key={index}>
                                                            {item.length}건
                                                        </th>
                                                    );
                                                }
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className="calendar">
                                        <tr>
                                            {calendarList?.map(
                                                (item, index) => {
                                                    return (
                                                        <td key={index}>
                                                            {item?.map(
                                                                (
                                                                    subItem,
                                                                    subIndex
                                                                ) => {
                                                                    return (
                                                                        <div
                                                                            style={{
                                                                                border: "1px solid #ddd",
                                                                                gap: "5px",
                                                                                display:
                                                                                    "flex",
                                                                                flexDirection:
                                                                                    "column",
                                                                                margin: "10px 0",
                                                                            }}
                                                                            className="visitCalendarWrap"
                                                                            key={
                                                                                subIndex
                                                                            }
                                                                        >
                                                                            {subItem.before_date_type ===
                                                                                1 && (
                                                                                <div className="NoClkWrap" />
                                                                            )}
                                                                            <div className="time">
                                                                                {moment(
                                                                                    subItem.before_date
                                                                                ).format(
                                                                                    "HH:mm"
                                                                                )}
                                                                            </div>
                                                                            <div className="job">
                                                                                {subItem.dobae ===
                                                                                    1 && (
                                                                                    <div
                                                                                        style={{
                                                                                            background:
                                                                                                "#41B06E",
                                                                                            color: "white",
                                                                                            width: "100%",
                                                                                            fontSize:
                                                                                                "12px",
                                                                                        }}
                                                                                    >
                                                                                        도배
                                                                                    </div>
                                                                                )}
                                                                                {subItem.zangpan ===
                                                                                    1 && (
                                                                                    <div
                                                                                        style={{
                                                                                            background:
                                                                                                "#F0B053",
                                                                                            color: "white",
                                                                                            width: "100%",
                                                                                            fontSize:
                                                                                                "12px",
                                                                                        }}
                                                                                    >
                                                                                        장판
                                                                                    </div>
                                                                                )}
                                                                                {subItem.maru ===
                                                                                    1 && (
                                                                                    <div
                                                                                        style={{
                                                                                            background:
                                                                                                "#5BC0DE",
                                                                                            color: "white",
                                                                                            width: "100%",
                                                                                            fontSize:
                                                                                                "12px",
                                                                                        }}
                                                                                    >
                                                                                        마루
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div className="number">
                                                                                [
                                                                                {subItem.mb_phone.slice(
                                                                                    7
                                                                                )}

                                                                                ]
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </td>
                                                    );
                                                }
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="popupBtnWrap">
                        <button className="btn btn8" onClick={ClosePop}>
                            닫기
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
