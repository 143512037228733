import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialState = {
    mbData: {
        create_dt: "",
        delete_dt: "",
        idx: "",
        mb_id: "",
        mb_name: "",
        memo: "",
        role_title: "",
        token: "",
        update_dt: "",
        mb_level: [],
    },
};
const getInitialState = () => {
    const savedState = localStorage.getItem("Login-Storage");
    if (savedState) {
        //console.log(JSON.parse(savedState));
        return JSON.parse(savedState).state.mbData;
    } else {
        return initialState.mbData;
    }
};
export const useUser = create(
    persist(
        (set) => ({
            setMbData: (data) => {
                set({
                    mbData: {
                        // ...getInitialState().mbData,
                        create_dt: data?.create_dt,
                        delete_dt: data?.delete_dt,
                        idx: data?.idx,
                        mb_id: data?.mb_id,
                        mb_name: data?.mb_name,
                        memo: data?.memo,
                        role_title: data?.role_title,
                        token: data?.token,
                        update_dt: data?.update_dt,
                        mb_level: JSON.parse(data?.mb_level),
                    },
                });
            },
            ResetMbData: () => set({ mbData: initialState.mbData }),
        }),
        {
            name: "Login-Storage", // 로컬 스토리지에 저장될 이름
            // 로컬 스토리지에 저장될 상태의 키를 선택합니다.
            // 이 경우에는 'tabs' 상태만 저장됩니다.
            getStorage: () => localStorage, // 사용할 스토리지를 선택합니다. 이 경우에는 웹의 로컬 스토리지를 사용합니다.
        }
    )
);
