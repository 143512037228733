import React, { useEffect, useState, useRef, useId } from "react";

// import { useDispatch, useSelector } from "react-redux";
// import { open, close } from '../redux/popupSlice';

import Button from "./Button";

import images from "../libs/images";
import consts from "../libs/consts";

import {
    clickDownS3,
    clickImg,
    getFileName,
    regFileDoc,
    regFileImage,
    regFilePdf,
    regFileExcel,
} from "../utils/utils";

import { toast } from "react-toastify";
import { set } from "lodash";

export default function InputFile(props) {
    // const dispatch = useDispatch();

    const {
        alt = "이미지",
        className,
        placeholder,
        type,
        name,
        maxlength,
        value,
        idx,
        setValue,
        setExt,
        setFileName,
        defaultFileName,
        onChange,
        fileNameView = true,
        valid,
        label,
        labelLeft,
        labelType = "image",
        error,
        setError,
        success,
        readOnly,
        onBlur,
        withButton,
        withButtonPress,
        style,
        down = false,
        fileDeleteButton = false,
        onDelete,
        imgStyle,
        buttonStyle = {},
        setImgFileType,
        setFileType = "",
        imgInnerStyle = {},
    } = props;

    const fileInput = useRef();
    const [cker, setCker] = useState(false);
    const [file_name, setfile_name] = useState("");
    // const [fileType, setFileType] = useState("");

    const id = useId();

    useEffect(() => {
        setfile_name(defaultFileName);
    }, [defaultFileName]);

    const handleChange = ({ file }) => {
        if (setError) setError("");

        var reg = "";
        var msg = "";

        if (valid === "image") {
            reg = regFileImage;
            msg = "이미지";
        } else if (valid === "doc") {
            reg = regFileDoc;
            msg = "문서";
        } else if (valid === "pdf") {
            reg = regFilePdf;
            msg = "문서";
        } else if (valid === "excel") {
            reg = regFileExcel;
            msg = "문서";
        }

        if (file.name.toLowerCase().match(reg)) {
            setfile_name(file.name);

            if (setFileName) setFileName(file.name);

            let reader = new FileReader();
            reader.onload = function () {
                let fn = file.name.split(".");
                // let imgType = fn[fn.length - 1];
                if (onChange) {
                    // console.log(reader.result);
                    // console.log("fileType: ", fileType);
                    onChange(idx, name, reader.result);
                } else {
                    setValue(reader.result);
                    setCker(true);

                    if (setExt) {
                        setExt(fn[fn.length - 1]);
                    }
                }
                fileInput.current.value = null;
            };
            reader.readAsDataURL(file);
        } else {
            fileInput.current.value = "";
            setCker(false);

            if (!onChange) {
                setValue("");
                setfile_name("");

                if (setFileName) setFileName("");
                if (setExt) setExt("");
            }

            toast.error(
                msg === "문서"
                    ? "지원하지 않는 파일형식 입니다."
                    : "이미지 파일만 업로드 가능합니다.",
                consts.toastErrorOption
            );

            // dispatch(
            //     open({
            //         message: msg + " 파일만 업로드 가능합니다.",
            //     })
            // );
        }
    };

    // set file reader function
    const fileDelete = (e) => {
        e.stopPropagation();

        if (!readOnly) {
            fileInput.current.value = "";
            setCker(false);

            if (onChange) {
                onChange(idx, name, "");
            } else {
                setValue("");
            }
        }
    };

    return (
        <div className="input_box" style={style}>
            {label && <p className="input_label">{label}</p>}

            <div className="input_file_section">
                {labelType === "image" ? (
                    <label
                        className={"input_file_label"}
                        style={imgStyle}
                        htmlFor={!readOnly ? id : ""}
                    >
                        {value ? (
                            <img
                                style={imgInnerStyle}
                                className={"input_preview_img"}
                                src={value}
                                alt={alt}
                            />
                        ) : value ? (
                            <img
                                className={"input_preview_img"}
                                src={consts.s3url + value}
                                alt={alt}
                            />
                        ) : labelType === "text" ? (
                            <p>파일선택</p>
                        ) : (
                            <img
                                src={images.camera}
                                alt={alt}
                                className="camera_icon"
                            />
                        )}
                        {/*                         
                        {value && (
                            <button className={'input_preview_button '} onClick={fileDelete} >
                                <img src={detailImageUrl ? images.trash_white : images.camera}/>
                            </button>
                        )} */}
                    </label>
                ) : (
                    <div className="input_file_label_doc">
                        {!readOnly && (
                            <label className={"file_label_btn"} htmlFor={id}>
                                파일찾기
                            </label>
                        )}

                        {/* 
                        {(file_name && fileNameView) && (
                            <div className='input_file_label_doc_names'>
                                <p onClick={() => clickImg()}>{file_name}</p>
                                {(value && fileDeleteButton) && (
                                    <button className="trash_gray_btn" style={{marginLeft: 8}} onClick={() => fileDelete()}/>
                                )}
                            </div>
                        )} */}

                        <div
                            className="input_file_label_doc_names"
                            style={buttonStyle}
                        >
                            {value ? (
                                <p
                                    onClick={() =>
                                        valid === "image" ? clickImg(value) : {}
                                    }
                                >
                                    {file_name}
                                </p>
                            ) : value ? (
                                <p onClick={() => clickDownS3(value)}>
                                    {fileNameView ? value : "파일 다운로드"}
                                </p>
                            ) : (
                                <p>파일없음</p>
                            )}
                        </div>
                    </div>
                )}

                <input
                    ref={fileInput}
                    type={type}
                    name={name}
                    id={id}
                    onChange={({ target: { files } }) => {
                        if (!setFileType == "") {
                            if (files[0]?.type) setFileType(files[0]?.type);
                        }

                        if (files.length) {
                            handleChange({
                                file: files[0],
                            });
                        }
                    }}
                />
            </div>

            {error && (
                <p className="input_error animate__animated animate__headShake">
                    {error}
                </p>
            )}
            {success && <p className="input_success">{success}</p>}
        </div>
    );
}
