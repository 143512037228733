import exit from "../assets/images/exit.svg";
import exit_white from "../assets/images/exit_white.svg";
import checkbox_off from "../assets/images/checkbox_off.svg";
import checkbox_on from "../assets/images/checkbox_on.svg";
import calendar from "../assets/images/calendar.svg";
import calendar_black from "../assets/images/calendar_black.svg";
import calendar_green from "../assets/images/calendar_green.svg";
import camera from "../assets/images/camera.svg";
import down from "../assets/images/down.svg";
import up from "../assets/images/up.svg";
import trash from "../assets/images/trash.svg";
import trash_red from "../assets/images/trash_red.svg";
import trash_white from "../assets/images/trash_white.svg";
import pagination_left from "../assets/images/pagination_left.svg";
import pagination_right from "../assets/images/pagination_right.svg";
import excel from "../assets/images/excel.svg";
import warning from "../assets/images/warning.svg";
import checkmark from "../assets/images/checkmark.svg";
import radio_on from "../assets/images/radio_on.svg";
import radio_off from "../assets/images/radio_off.svg";

import logo from "../assets/images/logo.svg";
import logo_text from "../assets/images/logo_text.svg";
import header_logo from "../assets/images/header_logo.svg";

import id from "../assets/images/id.svg";
import pw from "../assets/images/pw.svg";
import login from "../assets/images/login.svg";
import join from "../assets/images/join.svg";
import find_id from "../assets/images/find_id.svg";
import find_pw from "../assets/images/find_pw.svg";
import issue from "../assets/images/issue.svg";

import success from "../assets/images/success.svg";
import date from "../assets/images/date.svg";
import mail from "../assets/images/mail.svg";
import print from "../assets/images/print.svg";
import sound_on from "../assets/images/sound_on.svg";
import sound_off from "../assets/images/sound_off.svg";
import done from "../assets/images/done.svg";
import up_gray from "../assets/images/up_gray.svg";
import down_gray from "../assets/images/down_gray.svg";
import down_white from "../assets/images/down_white.svg";
import chat from "../assets/images/chat.svg";

import google from "../assets/images/google.svg";
import apple from "../assets/images/apple.svg";
import naver from "../assets/images/naver.svg";
import kakao from "../assets/images/kakao.svg";
import facebook from "../assets/images/facebook.svg";
import line from "../assets/images/line.svg";
import vimeo from "../assets/images/vimeo.svg";

import send from "../assets/images/send.svg";
import send_blue from "../assets/images/send_blue.svg";

import save from "../assets/images/save.svg";
import add from "../assets/images/add.svg";

import icon_chart from "../assets/images/icon_chart.svg";
import icon_folder from "../assets/images/icon_folder.svg";
import icon_safety from "../assets/images/icon_safety.svg";
import guide from "../assets/images/guide.svg";
import regist from "../assets/images/regist.svg";
import upload from "../assets/images/upload.svg";
import bookmark from "../assets/images/bookmark.svg";
import close from "../assets/images/close.svg";
import filter from "../assets/images/filter.svg";
import filter_black from "../assets/images/filter_black.svg";

import issue_icon from "../assets/images/issue_icon.svg";

import main_icon1 from "../assets/images/main_icon1.svg";
import main_icon2 from "../assets/images/main_icon2.svg";
import main_icon3 from "../assets/images/main_icon3.svg";

import map_on from "../assets/images/map_on.svg";
import map_off from "../assets/images/map_off.svg";
import grid_on from "../assets/images/grid_on.svg";
import grid_off from "../assets/images/grid_off.svg";

import cadastral_map from "../assets/images/cadastral_map.png";
import circle_line from "../assets/images/circle_line.png";

import left_menu1 from "../assets/images/left_menu1.svg";
import left_menu2 from "../assets/images/left_menu2.svg";
import left_menu3 from "../assets/images/left_menu3.svg";
import left_menu4 from "../assets/images/left_menu4.svg";
import left_menu5 from "../assets/images/left_menu5.svg";
import left_menu6 from "../assets/images/left_menu6.svg";
import reset from "../assets/images/reset.svg";
import setting from "../assets/images/setting.svg";
import location from "../assets/images/location.svg";
import table_down from "../assets/images/table_down.svg";
import search_small from "../assets/images/search_small.svg";
import right_arrow from "../assets/images/right_arrow.svg";

import marker1 from "../assets/images/marker1.svg";
import logout from "../assets/images/logout.svg";

import profile from "../assets/images/profile.png";
import rocket from "../assets/images/rocket.png";
import graph from "../assets/images/graph.png";
import pencil from "../assets/images/pencil.svg";
import ImgDel from "../assets/images/ImgDel.svg";

const exportData = {
    ImgDel,
    exit,
    excel,
    exit_white,
    checkbox_off,
    checkbox_on,
    calendar,
    camera,
    down,
    up,
    trash,
    trash_red,
    trash_white,
    profile,
    warning,
    checkmark,
    radio_on,
    radio_off,
    logo,
    logo_text,
    header_logo,
    id,
    pw,
    login,
    join,
    find_id,
    find_pw,
    success,
    issue,
    date,
    mail,
    print,
    sound_on,
    sound_off,
    rocket,
    graph,
    done,
    up_gray,
    down_gray,
    down_white,
    chat,
    google,
    apple,
    naver,
    kakao,
    line,
    vimeo,
    facebook,
    send,
    send_blue,
    save,
    add,
    icon_chart,
    icon_folder,
    icon_safety,
    guide,
    regist,
    upload,
    bookmark,
    close,
    calendar_black,
    calendar_green,
    filter,
    filter_black,
    issue_icon,
    main_icon1,
    main_icon2,
    main_icon3,
    map_on,
    map_off,
    grid_on,
    grid_off,
    cadastral_map,
    circle_line,
    left_menu1,
    left_menu2,
    left_menu3,
    left_menu4,
    left_menu5,
    left_menu6,
    reset,
    setting,
    location,
    table_down,
    search_small,
    right_arrow,
    marker1,
    logout,
    pencil,
};

export default exportData;
