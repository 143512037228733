import { useEffect, useState } from "react";
import Input from "../../components/Input";
import { InputRadioBox } from "../../components/InputRadioBox";

import routes from "../../libs/routes";
import { useNavigate } from "react-router-dom";
import consts from "../../libs/consts";
import Pagination from "react-js-pagination";
import images from "../../libs/images";
import { useGetData, usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import { toast } from "react-toastify";
import { PopupComp } from "../../components/PopUp";
import useLoadingStore from "../../zustand/Loading";
import { se } from "date-fns/locale";

function MaterialListManagement() {
    // const {
    //     data: selectThings,
    //     isLoading,
    //     isError,
    // } = useGetData(ApiData.materialListOption);
    const materialDetailList = usePostData(ApiData.materialDetailList);
    const materialDetailDelete = usePostData(ApiData.materialDetailDelete);
    const materialDropDownList = usePostData(ApiData.materialDropDownList);
    const [radioValue, setRadioValue] = useState(0);
    const [searchVal, setSearchVal] = useState("");
    const [companyList, setCompanyList] = useState([]);
    const [selectCompany, setSelectCompany] = useState("");
    const [firstCategory, setFirstCategory] = useState([]);
    const [selectFirstCategory, setSelectFirstCategory] = useState("");
    const [secondCategory, setSecondCategory] = useState([]);
    const [selectSecondCategory, setSelectSecondCategory] = useState("");
    const [deleteDateContent, setDeleteDateContent] = useState(0);
    const [deletePop, setDeletePop] = useState(false);

    const [materialState, setMaterialState] = useState(0);
    const [materialNew, setMaterialNew] = useState(0);
    const [materialBest, setMaterialBest] = useState(0);
    const [materialPremium, setMaterialPremium] = useState(0);
    const [materialHighEnd, setMaterialHighEnd] = useState(0);

    const [ceilingAvailable, setCeilingAvailable] = useState(0);
    const [ceilingFirst, setCeilingFirst] = useState(0);

    const [materialListData, setMaterialListData] = useState([]);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    const handleCeilingAvailable = (e) => {
        setCeilingAvailable(e.target.checked ? 1 : 0);
    };
    const handleCeilingFirst = (e) => {
        setCeilingFirst(e.target.checked ? 1 : 0);
    };

    const handleMaterialState = (e) => {
        setMaterialState(e.target.checked ? 1 : 0);
    };
    const handleMaterialNew = (e) => {
        setMaterialNew(e.target.checked ? 1 : 0);
    };
    const handleMaterialBest = (e) => {
        setMaterialBest(e.target.checked ? 1 : 0);
    };
    const handleMaterialPremium = (e) => {
        setMaterialPremium(e.target.checked ? 1 : 0);
    };
    const handleMaterialHighEnd = (e) => {
        setMaterialHighEnd(e.target.checked ? 1 : 0);
    };

    const handleCompanyChange = (e) => {
        console.log("e.target.value", e.target.value);
        setSelectCompany(e.target.value);
    };
    const handleFirstCategoryChange = (e) => {
        setSelectFirstCategory(e.target.value);
    };
    const handleSecondCategoryChange = (e) => {
        setSelectSecondCategory(e.target.value);
    };

    const materialDropDownListApi = (reset = false) => {
        let sender = {
            company: selectCompany === "제조사" ? null : selectCompany,
            class1:
                selectFirstCategory === "1차분류" ? null : selectFirstCategory,
            class2:
                selectSecondCategory === "2차분류"
                    ? null
                    : selectSecondCategory,
        };
        if (reset === true) {
            sender = {
                company: null,
                class1: null,
                class2: null,
            };
        }

        materialDropDownList
            .mutateAsync(sender)
            .then(({ data }) => {
                console.log("?data!!!!!!!!!");

                const uniqueCompanies = new Set([]);
                const uniqueFirstCategory = new Set([]);
                const uniqueSecondCategory = new Set([]);

                data?.forEach((item) => {
                    uniqueCompanies.add(item.material_company);
                    uniqueFirstCategory.add(item.material_class01);
                    uniqueSecondCategory.add(item.material_class02);
                });

                setCompanyList(Array.from(uniqueCompanies));
                setFirstCategory(Array.from(uniqueFirstCategory));
                setSecondCategory(Array.from(uniqueSecondCategory));
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    const materialListApi = (reset = false) => {
        let sender = {
            material_type: radioValue,
            searchVal: searchVal,
            company: selectCompany === "제조사" ? null : selectCompany,
            class1:
                selectFirstCategory === "1차분류" ? null : selectFirstCategory,
            class2:
                selectSecondCategory === "2차분류"
                    ? null
                    : selectSecondCategory,
            material_status: parseInt(materialState),
            material_new: parseInt(materialNew),
            material_best: parseInt(materialBest),
            material_primeum: parseInt(materialPremium),
            material_highend: parseInt(materialHighEnd),
            ceiling_available: parseInt(ceilingAvailable),
            ceiling_first: parseInt(ceilingFirst),
            page: page - 1,
        };

        if (reset == true) {
            sender = {
                material_type: 0,
                searchVal: "",
                company: null,
                class1: null,
                class2: null,
                material_status: 0,
                material_new: 0,
                material_best: 0,
                material_primeum: 0,
                material_highend: 0,
                page: 0,
            };
        }
        //console.log("sender", sender);
        // loadingStart();
        materialDetailList
            .mutateAsync(sender)
            .then(({ data }) => {
                // loadingEnd();
                // console.log("data", data);
                setMaterialListData(data);
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
            });
    };
    const deleteData = () => {
        let sender = {
            deleteType: "detail",
            deleteIdx: deleteDateContent,
        };

        materialDetailDelete
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                materialListApi();
                setDeletePop(false);
            })
            .catch((e) => {
                toast.error("삭제에 실패하였습니다.");
            });
    };
    const DeleteClk = (idx) => {
        setDeletePop(true);
        setDeleteDateContent(idx);
        //console.log("idx", idx);
    };
    const ResetBtn = () => {
        // console.log("???");
        setRadioValue(0);
        setSearchVal("");
        setSelectCompany("제조사");
        setSelectFirstCategory("1차분류");
        setSelectSecondCategory("2차분류");
        setMaterialState(0);
        setMaterialNew(0);
        setMaterialBest(0);
        setMaterialPremium(0);
        setMaterialHighEnd(0);
        setCeilingAvailable(0);
        setCeilingFirst(0);
        materialListApi(true);
        materialDropDownListApi(true);
    };

    useEffect(() => {
        materialListApi();
    }, [page]);

    useEffect(() => {
        materialDropDownListApi();
    }, [selectCompany, selectFirstCategory, selectSecondCategory]);
    // useEffect(() => {
    //     // console.log("selectThings", selectThings);
    //     if (
    //         selectThings?.data.companyList &&
    //         selectThings.data.companyList.length > 0
    //     ) {
    //         selectThings.data.companyList[0] = "제조사";
    //     }
    //     if (
    //         selectThings?.data.class01 &&
    //         selectThings.data.class01.length > 0
    //     ) {
    //         selectThings.data.class01[0] = "1차분류";
    //     }
    //     if (
    //         selectThings?.data.class02 &&
    //         selectThings.data.class02.length > 0
    //     ) {
    //         selectThings.data.class02[0] = "2차분류";
    //     }
    //     setCompanyList(selectThings?.data.companyList);
    //     setFirstCategory(selectThings?.data.class01);
    //     setSecondCategory(selectThings?.data.class02);
    // }, [selectThings]);

    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteData}
            />
            <div className="content ">
                <div className="title_container">
                    <p className="title">자재목록관리</p>
                    <button
                        className="btn btn9"
                        onClick={() => {
                            navigate(`${routes.material5_1}`);
                        }}
                    >
                        등록
                    </button>
                </div>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <InputRadioBox
                            title="상태"
                            Radioes={consts.constructionBox3}
                            value={radioValue}
                            setValue={setRadioValue}
                        />
                        <div className="search_form">
                            <Input
                                className="input_text"
                                type="text"
                                placeholder="분류, 모델번호, 제품명"
                                name="searchVal"
                                value={searchVal}
                                setValue={setSearchVal}
                                label="검색"
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        materialListApi();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="search_box_wrap">
                        <div
                            style={{ marginBottom: "0px" }}
                            className="search_box_wrapper"
                        >
                            <div className="SelectedWrap">
                                <div className="SelectedWrapTitle">
                                    <p>분류</p>
                                </div>
                                <div className="SelectedWrapContent">
                                    <select
                                        onChange={handleCompanyChange}
                                        value={selectCompany}
                                    >
                                        <option value="제조사">제조사</option>
                                        {companyList?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        onChange={handleFirstCategoryChange}
                                        value={selectFirstCategory}
                                    >
                                        <option value="1차분류">1차분류</option>
                                        {firstCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        onChange={handleSecondCategoryChange}
                                        value={selectSecondCategory}
                                    >
                                        <option value="2차분류">2차분류</option>
                                        {secondCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="ChkBoxWrap search_form">
                            <div className="title">필터</div>
                            <div className="ChkBox">
                                <div className="ChkBoxOne">
                                    <input
                                        checked={materialState}
                                        type="checkbox"
                                        id={"materialState"}
                                        name={"materialState"}
                                        value={materialState}
                                        onChange={handleMaterialState}
                                    />
                                    <label htmlFor={"materialState"}>
                                        비전시
                                    </label>
                                </div>
                                <div className="ChkBoxOne">
                                    <input
                                        checked={materialNew}
                                        type="checkbox"
                                        id={"materialNew"}
                                        name={"materialNew"}
                                        value={materialNew}
                                        onChange={handleMaterialNew}
                                    />
                                    <label htmlFor={"materialNew"}>NEW</label>
                                </div>
                                <div className="ChkBoxOne">
                                    <input
                                        checked={materialBest}
                                        type="checkbox"
                                        id={"materialBest"}
                                        name={"materialBest"}
                                        value={materialBest}
                                        onChange={handleMaterialBest}
                                    />
                                    <label htmlFor={"materialBest"}>BEST</label>
                                </div>
                                <div className="ChkBoxOne">
                                    <input
                                        checked={materialPremium}
                                        type="checkbox"
                                        id={"materialPremium"}
                                        name={"materialPremium"}
                                        value={materialPremium}
                                        onChange={handleMaterialPremium}
                                    />
                                    <label htmlFor={"materialPremium"}>
                                        PREMIUM
                                    </label>
                                </div>
                                <div className="ChkBoxOne">
                                    <input
                                        checked={materialHighEnd}
                                        type="checkbox"
                                        id={"materialHighEnd"}
                                        name={"materialHighEnd"}
                                        value={materialHighEnd}
                                        onChange={handleMaterialHighEnd}
                                    />
                                    <label htmlFor={"materialHighEnd"}>
                                        HIGH-END
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* <InputCheckBox
                            title="필터"
                            Chkboxes={consts.filter}
                            setValue={setSearchType}
                            values={searchType}
                        /> */}
                    </div>
                    <div className="search_box_wrap">
                        <div className="ChkBoxWrap search_form">
                            <div className="title">천장 가능</div>
                            <div className="ChkBox">
                                <div className="ChkBoxOne">
                                    <input
                                        checked={ceilingAvailable}
                                        type="checkbox"
                                        id={"ceilingAvailable"}
                                        name={"ceilingAvailable"}
                                        value={ceilingAvailable}
                                        onChange={handleCeilingAvailable}
                                    />
                                    <label htmlFor={"ceilingAvailable"}>
                                        천장 가능
                                    </label>
                                </div>
                                <div className="ChkBoxOne">
                                    <input
                                        checked={ceilingFirst}
                                        type="checkbox"
                                        id={"ceilingFirst"}
                                        name={"ceilingFirst"}
                                        value={ceilingFirst}
                                        onChange={handleCeilingFirst}
                                    />
                                    <label htmlFor={"ceilingFirst"}>
                                        천장 우선 적용
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button className="btn btn8" onClick={ResetBtn}>
                                초기화
                            </button>
                            <button
                                className="btn btn9"
                                onClick={materialListApi}
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>
                <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                    [{materialListData.count}건]
                </p>
                <div className="table_scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>사진</th>
                                <th>제조사</th>
                                <th>1차분류</th>
                                <th>2차분류</th>
                                <th>모델번호</th>
                                <th>제품명</th>
                                <th>색상</th>
                                <th>패턴</th>
                                <th>전시</th>
                                <th>필터</th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {materialListData?.rows?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1 + (page - 1) * 20}</td>
                                        <td>
                                            <div className="FlexCenter">
                                                {item.material_img ? (
                                                    <img
                                                        style={{
                                                            width: "150px",
                                                        }}
                                                        src={`${consts.ImgState}${item.material_img}`}
                                                        alt="material"
                                                    />
                                                ) : (
                                                    "-"
                                                )}
                                            </div>
                                        </td>
                                        <td>{item.material_company || "-"}</td>
                                        <td>{item.material_class01 || "-"}</td>
                                        <td>{item.material_class02 || "-"}</td>
                                        <td>{item.material_number || "-"}</td>
                                        <td>{item.material_name || "-"}</td>
                                        <td>{item.material_color || "-"}</td>
                                        <td>{item.material_pattern || "-"}</td>
                                        <td>
                                            {item.material_status ? "O" : "X"}
                                        </td>
                                        <td>
                                            <div>
                                                {item.material_badge === 1 ? (
                                                    <p style={{ color: "red" }}>
                                                        NEW
                                                    </p>
                                                ) : item.material_badge ===
                                                  2 ? (
                                                    <p
                                                        style={{
                                                            color: "blue",
                                                        }}
                                                    >
                                                        BEST
                                                    </p>
                                                ) : item.material_badge ===
                                                  3 ? (
                                                    <p
                                                        style={{
                                                            color: "green",
                                                        }}
                                                    >
                                                        PREMIUM
                                                    </p>
                                                ) : item.material_badge ===
                                                  4 ? (
                                                    <p
                                                        style={{
                                                            color: "purple",
                                                        }}
                                                    >
                                                        HIGH-END
                                                    </p>
                                                ) : null}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="FlexCenter TableBtns">
                                                <button
                                                    onClick={() => {
                                                        navigate(
                                                            `${routes.material5_1}?index=${item.idx}`
                                                        );
                                                    }}
                                                >
                                                    상세
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        DeleteClk(item.idx)
                                                    }
                                                >
                                                    삭제
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="BottomContent">
                    <div className="ButtonContent"></div>
                    <div className="PaginationContent">
                        <Pagination
                            className="my-pagination"
                            // 현제 보고있는 페이지
                            activePage={page}
                            // 한페이지에 출력할 아이템수
                            itemsCountPerPage={20}
                            // 총 아이템수
                            totalItemsCount={materialListData?.count || 0}
                            // 표시할 페이지수
                            pageRangeDisplayed={10}
                            // 함수
                            onChange={handlePageChange}
                            nextPageText=""
                            prevPageText=""
                            firstPageText=""
                            lastPageText=""
                        ></Pagination>
                    </div>
                    <div className="ButtonContent">
                        <button
                            className="btn btn9"
                            onClick={() => {
                                navigate(`${routes.material5_1}`);
                            }}
                        >
                            등록
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MaterialListManagement;
