import { useEffect, useState } from "react";
import {
    BoxCheckboxInput,
    BoxDelImg,
    BoxImageEditer,
    BoxLookImg,
    BoxRadioboxInput,
    BoxTextInput,
} from "../../../components/BoxInfo";
import moment from "moment";
import consts from "../../../libs/consts";
import routes from "../../../libs/routes";
import InputFile from "../../../components/InputFile";
import { usePostData } from "../../../utils/service";
import ApiData from "../../../libs/api";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { set } from "lodash";
import useLoadingStore from "../../../zustand/Loading";

function MaterialListManageDetail() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const materialOption = usePostData(ApiData.materialOption);
    const materialDetailIn = usePostData(ApiData.materialDetailIn);
    const materialDetail = usePostData(ApiData.materialDetail);
    const [materialType, setMaterialType] = useState(0);
    const [companyList, setCompanyList] = useState([]);
    const [companyIdx, setCompanyIdx] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [class1List, setClass1List] = useState([]);
    const [class1Idx, setClass1Idx] = useState(0);
    const [class1Name, setClass1Name] = useState("");
    const [class2List, setClass2List] = useState([]);
    const [class2Idx, setClass2Idx] = useState(0);
    const [class2Name, setClass2Name] = useState("");
    const [colorList, setColorList] = useState([]);
    const [colorIdx, setColorIdx] = useState(0);
    const [colorName, setColorName] = useState("");
    const [patternList, setPatternList] = useState([]);
    const [patternIdx, setPatternIdx] = useState(0);
    const [patternName, setPatternName] = useState("");
    const [modelNumber, setModelNumber] = useState("");
    const [productName, setProductName] = useState("");
    const [materialImg, setMaterialImg] = useState("");
    const [materialState, setMaterialState] = useState(0);
    const [materialBadge, setMaterialBadge] = useState(0);
    const [sorting, setSorting] = useState("");
    const [materialContent, setMaterialContent] = useState("");
    const [ceilingAvailable, setCeilingAvailable] = useState(0);
    const [ceilingFirst, setCeilingFirst] = useState(0);
    const [ceilingFirstDate, setCeilingFirstDate] = useState("");
    const [materialImgFileType, setMaterialImgFileType] = useState("");
    // const { loadingStart, loadingEnd } = useLoadingStore();

    const OnChangeCompany = (e) => {
        //console.log("company");
        setClass1List([]);
        setClass1Idx(parseInt(0));
        setClass2List([]);
        setClass2Idx(parseInt(0));
        setColorList([]);
        setColorIdx(parseInt(0));
        setPatternList([]);
        setPatternIdx(parseInt(0));
        setCompanyIdx(parseInt(e.target.value));
    };
    const OnChangeClass1 = (e) => {
        //console.log("class1");
        setClass2List([]);
        setClass2Idx(parseInt(0));
        setColorList([]);
        setColorIdx(parseInt(0));
        setPatternList([]);
        setPatternIdx(parseInt(0));
        setClass1Idx(parseInt(e.target.value));
    };
    const OnChangeClass2 = (e) => {
        //console.log("class2");
        setColorList([]);
        setColorIdx(parseInt(0));
        setPatternList([]);
        setPatternIdx(parseInt(0));
        setClass2Idx(parseInt(e.target.value));
    };
    const OnChangeColor = (e) => {
        setColorIdx(parseInt(e.target.value));
    };

    const OnChangePattern = (e) => {
        setPatternIdx(parseInt(e.target.value));
    };
    const handleMaterialState = (e) => {
        setMaterialState(e.target.checked);
    };
    const handleMaterialFirst = (e) => {
        setCeilingFirst(e.target.checked);
    };
    const handleCeilingAvailable = (e) => {
        setCeilingAvailable(e.target.checked);
    };
    const handleMaterialBadge = (e) => {
        setMaterialBadge(parseInt(e.target.value));
    };

    const Submit = () => {
        if (index === null) {
            if (materialType === 0) {
                toast.error("종류를 선택해주세요.");
                return;
            }
            if (materialType !== 0 && companyIdx === 0) {
                toast.error("제조사를 선택해주세요.");
                return;
            }
            if (
                materialType !== 0 &&
                companyIdx !== 0 &&
                class1Idx === 0 &&
                class1List.length != 0
            ) {
                toast.error("1차 분류를 선택해주세요.");
                return;
            }
            if (
                materialType !== 0 &&
                companyIdx !== 0 &&
                class1Idx !== 0 &&
                class2Idx === 0 &&
                class2List.length != 0
            ) {
                toast.error("2차 분류를 선택해주세요.");
                return;
            }
            if (modelNumber === "") {
                toast.error("모델번호를 입력해주세요.");
                return;
            } else if (productName === "") {
                toast.error("제품명을 입력해주세요.");
                return;
            }
            if (
                materialType !== 0 &&
                companyIdx !== 0 &&
                class1Idx !== 0 &&
                class2Idx !== 0 &&
                colorIdx === 0 &&
                colorList != undefined
            ) {
                //console.log(colorList);
                toast.error("색상을 선택해주세요.");
                return;
            }
            if (
                materialType !== 0 &&
                companyIdx !== 0 &&
                class1Idx !== 0 &&
                class2Idx !== 0 &&
                patternIdx === 0 &&
                patternList != undefined
            ) {
                toast.error("패턴을 선택해주세요.");
                return;
            }
        } else {
            if (modelNumber === "") {
                toast.error("모델번호를 입력해주세요.");
                return;
            } else if (productName === "") {
                toast.error("제품명을 입력해주세요.");
                return;
            }
        }

        let sender = {
            material_type: materialType,
            material_status: materialState,
            material_badge: materialBadge,
            material_name: productName,
            material_number: modelNumber,
            material_editor: materialContent,
            material_ceiling: ceilingAvailable,
            material_ceiling_first: ceilingFirst,
        };
        //console.log("materialImg", materialImg);
        if (
            materialImg !== "" &&
            materialImg !== null &&
            !materialImg.includes("material")
        ) {
            sender.imgType = 1;
            sender.material_img = materialImg;
        }
        //제조사 값 세팅
        if (companyIdx != 0) {
            sender.material_company = companyList.find(
                (x) => x.idx === companyIdx
            ).company_name;
        } else {
            sender.material_company = companyName;
        }
        //1차 분류 값 세팅
        if (class1Idx != 0) {
            sender.material_class01 = class1List.find(
                (x) => x.idx === class1Idx
            ).class1_name;
        } else {
            sender.material_class01 = class1Name;
        }
        //2차 분류 값 세팅
        if (class2Idx != 0) {
            sender.material_class02 = class2List.find(
                (x) => x.idx === class2Idx
            ).class2_name;
        } else {
            sender.material_class02 = class2Name;
        }
        //색상 값 세팅
        if (colorIdx != 0) {
            sender.material_color = colorList.find(
                (x) => x.idx === colorIdx
            ).material_color;
        } else {
            sender.material_color = colorName;
        }
        //패턴 값 세팅
        if (patternIdx != 0) {
            sender.material_pattern = patternList.find(
                (x) => x.idx === patternIdx
            ).material_pattern;
        } else {
            sender.material_pattern = patternName;
        }
        if (index !== null) {
            sender.materialIdx = index;
        }
        //console.log("sender", sender);
        materialDetailIn
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("저장되었습니다.");
                navigate(routes.material5);

                // //console.log(data);
            })
            .catch((e) => {
                //console.log(e);
                // toast.error(e.response.data);
            });
    };
    useEffect(() => {
        setCompanyIdx(parseInt(0));
        setClass1List([]);
        setClass1Idx(parseInt(0));
        setClass2List([]);
        setClass2Idx(parseInt(0));
        setColorList([]);
        setColorIdx(parseInt(0));
        setPatternList([]);
        setPatternIdx(parseInt(0));
    }, [materialType]);

    useEffect(() => {
        let sender = {
            material_type: materialType,
            companyIdx: companyIdx,
            class1Idx: class1Idx,
            class2Idx: class2Idx,
        };
        materialOption
            .mutateAsync(sender)
            .then(({ data }) => {
                setCompanyList(data?.company);
                setClass1List(data?.class1);
                setClass2List(data?.class2);
                setColorList(data?.color);
                setPatternList(data?.pattern);

                // //console.log(data);
            })
            .catch((e) => {
                //console.log(e);
                // toast.error(e.response.data);
            });
    }, [materialType, companyIdx, class1Idx, class2Idx]);
    useEffect(() => {
        if (index) {
            let sender = {
                materialIdx: index,
            };
            // loadingStart();
            materialDetail
                .mutateAsync(sender)
                .then(({ data }) => {
                    // loadingEnd();

                    setMaterialType(data.material_type);
                    setCompanyName(data.material_company);
                    setClass1Name(data.material_class01);
                    setClass2Name(data.material_class02);
                    setColorName(data.material_color);
                    setPatternName(data.material_pattern);
                    setModelNumber(data.material_number);
                    setProductName(data.material_name);
                    setMaterialImg(data.material_img);
                    setMaterialState(data.material_status);
                    setMaterialBadge(data.material_badge);
                    setMaterialContent(data.material_editor);
                    setCeilingAvailable(data.material_ceiling);
                    setSorting(data.material_order);
                    setCeilingFirst(data.material_ceiling_first ? 1 : 0);
                    setCeilingFirstDate(data.material_ceiling_first);
                })
                .catch((e) => {
                    // loadingEnd();
                    toast.error(e.response.data);
                });
        }
    }, [index]);

    useEffect(() => {
        if (!ceilingAvailable) {
            setCeilingFirst(0);
        }
    }, [ceilingAvailable]);

    return (
        <>
            <div className="content ">
                <p className="title">자재 상세</p>
                <div className="BorderBox">
                    <BoxRadioboxInput
                        title="종류"
                        radioValue={materialType}
                        setRadioValue={setMaterialType}
                        Radioes={consts.constructionBox3}
                    />
                    {materialType !== 0 || companyName !== "" ? (
                        <div
                            style={{
                                height: "auto",
                                background: "#f1faee",
                                minHeight: "45px",
                            }}
                            className="BoxInfo"
                        >
                            <div className="title">제조사</div>
                            <div
                                className="sub"
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(5,1fr)",
                                    gap: "5px",
                                    minHeight: "45px",
                                }}
                            >
                                {companyName && (
                                    <div style={{ marginRight: "10px" }}>
                                        {companyName}
                                    </div>
                                )}
                                {companyList?.map((x, i) => {
                                    const id = uuidv4();
                                    return (
                                        <div key={i} className="RadioBoxOne">
                                            <input
                                                checked={companyIdx === x.idx}
                                                className="radio"
                                                type="radio"
                                                id={id}
                                                name={"company"}
                                                value={x.idx}
                                                onChange={OnChangeCompany}
                                            />
                                            <label htmlFor={id}>
                                                {x.company_name}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}
                    {companyIdx !== 0 || class1Name !== "" ? (
                        <div
                            className="BoxInfo"
                            style={{
                                height: "auto",
                                background: "#f1faee",
                                minHeight: "45px",
                            }}
                        >
                            <div className="title">1차 분류</div>
                            <div
                                className="sub"
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(5,1fr)",
                                    gap: "5px",
                                    minHeight: "45px",
                                }}
                            >
                                {class1Name && (
                                    <div style={{ marginRight: "10px" }}>
                                        {class1Name}
                                    </div>
                                )}
                                {class1List?.map((x, i) => {
                                    const id = uuidv4();
                                    return (
                                        <div key={i} className="RadioBoxOne">
                                            <input
                                                checked={class1Idx === x.idx}
                                                className="radio"
                                                type="radio"
                                                id={id}
                                                name={"class1"}
                                                value={x.idx}
                                                onChange={OnChangeClass1}
                                            />
                                            <label htmlFor={id}>
                                                {x.class1_name}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}
                    {class1Idx !== 0 || class2Name !== "" ? (
                        <div
                            className="BoxInfo"
                            style={{
                                height: "auto",
                                background: "#f1faee",
                                minHeight: "45px",
                            }}
                        >
                            <div className="title">2차 분류</div>
                            <div
                                className="sub"
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(5,1fr)",
                                    gap: "5px",
                                    minHeight: "45px",
                                }}
                            >
                                {class2Name && (
                                    <div style={{ marginRight: "10px" }}>
                                        {class2Name}
                                    </div>
                                )}
                                {class2List?.map((x, i) => {
                                    const id = uuidv4();
                                    return (
                                        <div key={i} className="RadioBoxOne">
                                            <input
                                                checked={class2Idx === x.idx}
                                                className="radio"
                                                type="radio"
                                                id={id}
                                                name={"class2"}
                                                value={x.idx}
                                                onChange={OnChangeClass2}
                                            />
                                            <label htmlFor={id}>
                                                {x.class2_name}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : null}

                    <BoxTextInput
                        title="모델번호"
                        value={modelNumber}
                        setValue={setModelNumber}
                    />
                    <BoxTextInput
                        title="제품명"
                        value={productName}
                        setValue={setProductName}
                    />
                    {materialType === 1 && (
                        <div
                            className="BoxInfo"
                            style={{
                                height: "auto",
                                background: "#f1faee",
                                minHeight: "45px",
                            }}
                        >
                            <div className="title">색상</div>
                            <div
                                className="sub"
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(5,1fr)",
                                    gap: "5px",
                                    minHeight: "45px",
                                }}
                            >
                                {colorName && (
                                    <div style={{ marginRight: "10px" }}>
                                        {colorName}
                                    </div>
                                )}
                                {colorList?.map((x, i) => {
                                    const id = uuidv4();
                                    return (
                                        <div key={i} className="RadioBoxOne">
                                            <input
                                                checked={colorIdx === x.idx}
                                                className="radio"
                                                type="radio"
                                                id={id}
                                                name={"color"}
                                                value={x.idx}
                                                onChange={OnChangeColor}
                                            />
                                            <label htmlFor={id}>
                                                {x.material_color}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    <div
                        style={{
                            border: "none",
                            borderBottom: "1px solid #ddd",
                        }}
                        className="ImgInputWrap"
                    >
                        <div className="Imgtitle">이미지</div>
                        <div className="sub">
                            <InputFile
                                type="file"
                                name={"plan_img"}
                                value={
                                    materialImg &&
                                    (materialImg.includes("material")
                                        ? `${consts.ImgState}${materialImg}`
                                        : `${materialImg}`)
                                }
                                alt={"materialImg"}
                                onChange={(idx, name, result) => {
                                    setMaterialImg(result);
                                }}
                                valid="image"
                                // style={{ flex: 1.68 }}
                                imgStyle={{
                                    width: "40%",
                                }}
                                setFileType={(type) => {
                                    setMaterialImgFileType(type);
                                }}
                            />
                            <button
                                type="button"
                                style={{
                                    fontSize: "12px",
                                    position: "absolute",
                                    bottom: "10px",
                                    right: "10px",
                                    cursor: "pointer",
                                }}
                                onClick={() => setMaterialImg("")}
                            >
                                삭제하기
                            </button>
                        </div>
                    </div>
                    {materialType === 1 && (
                        <div
                            className="BoxInfo"
                            style={{
                                height: "auto",
                                background: "#f1faee",
                                minHeight: "45px",
                            }}
                        >
                            <div className="title">패턴</div>
                            <div
                                className="sub"
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(5,1fr)",
                                    gap: "5px",
                                    minHeight: "45px",
                                }}
                            >
                                {patternName && (
                                    <div style={{ marginRight: "10px" }}>
                                        {patternName}
                                    </div>
                                )}
                                {patternList?.map((x, i) => {
                                    const id = uuidv4();
                                    return (
                                        <div key={i} className="RadioBoxOne">
                                            <input
                                                checked={patternIdx === x.idx}
                                                className="radio"
                                                type="radio"
                                                id={id}
                                                name={"pattern"}
                                                value={x.idx}
                                                onChange={OnChangePattern}
                                            />
                                            <label htmlFor={id}>
                                                {x.material_pattern}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className="BoxInfo">
                        <div className="title">전시</div>
                        <div className="sub">
                            <div className="ChkBoxOne">
                                <input
                                    checked={materialState}
                                    type="checkbox"
                                    id={"materialState"}
                                    name={"materialState"}
                                    value={materialState}
                                    onChange={handleMaterialState}
                                />
                                <label htmlFor={"materialState"}>전시</label>
                            </div>
                        </div>
                    </div>
                    <div className="BoxInfo">
                        <div className="title">천장 가능 여부</div>
                        <div className="sub">
                            <div className="ChkBoxOne">
                                <input
                                    checked={ceilingAvailable}
                                    type="checkbox"
                                    id={"ceilingAvailable"}
                                    name={"ceilingAvailable"}
                                    value={ceilingAvailable}
                                    onChange={handleCeilingAvailable}
                                />
                                <label htmlFor={"ceilingAvailable"}>
                                    천장 가능
                                </label>
                            </div>
                            {ceilingAvailable == 1 && (
                                <div className="ChkBoxOne">
                                    <input
                                        checked={ceilingFirst}
                                        type="checkbox"
                                        id={"ceilingFirst"}
                                        name={"ceilingFirst"}
                                        value={ceilingFirst}
                                        onChange={handleMaterialFirst}
                                    />
                                    <label htmlFor={"ceilingFirst"}>
                                        천장 우선 적용{" "}
                                        {`${
                                            ceilingFirstDate === null ||
                                            ceilingFirstDate === undefined ||
                                            ceilingFirstDate === ""
                                                ? ""
                                                : `(${moment(
                                                      ceilingFirstDate
                                                  ).format(
                                                      "YYYY-MM-DD HH:mm"
                                                  )})`
                                        }`}
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="BoxInfo">
                        <div className="title">필터</div>
                        <div className="sub">
                            {consts.material_badge.map((x, i) => (
                                <div className="RadioBoxOne" key={i}>
                                    <input
                                        checked={materialBadge === x.value}
                                        className="radio"
                                        type="radio"
                                        id={x.id}
                                        name="materialBadge"
                                        value={x.value}
                                        onChange={handleMaterialBadge}
                                    />
                                    <label htmlFor={x.id}>{x.label}</label>
                                </div>
                            ))}
                        </div>
                    </div>

                    <BoxTextInput
                        title="정렬"
                        value={sorting}
                        // setValue={setSorting}
                        readOnly={true}
                    />
                    <BoxImageEditer
                        value={materialContent}
                        setValue={setMaterialContent}
                    />
                </div>
                <div className="BtnEnd">
                    <button className="btn btn9" onClick={Submit}>
                        저장
                    </button>
                </div>
            </div>
        </>
    );
}

export default MaterialListManageDetail;
