import { useEffect, useState } from "react";
import InputDate from "../../../components/InputDate";
import { InputRadioBox } from "../../../components/InputRadioBox";
import consts from "../../../libs/consts";
import routes from "../../../libs/routes";
import Pagination from "react-js-pagination";
import images from "../../../libs/images";
import moment from "moment";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { usePostData } from "../../../utils/service";
import ApiData from "../../../libs/api";
import XLSX from "xlsx-js-style";
import useLoadingStore from "../../../zustand/Loading";
import { toast } from "react-toastify";

function AgencyMaterialDetail() {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const agencyMaterialDetailList = usePostData(
        ApiData.agencyMaterialDetailList
    );
    const date = new Date();
    const [sdate, setsdate] = useState(
        moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
            "YYYY-MM-DD"
        )
    );
    const [edate, setedate] = useState(
        moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
            "YYYY-MM-DD"
        )
    );
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [allData, setAlldata] = useState([]);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const agencyMaterialDetailListApi = () => {
        let sender = {
            agencyIdx: index,
            start_date: sdate,
            end_date: edate,
            page: page - 1,
        };
        // loadingStart();
        agencyMaterialDetailList
            .mutateAsync(sender)
            .then((data) => {
                // loadingEnd();
                //console.log("!!!Data", data);
                setData(data.data.rows);
                setAlldata(data.data);
            })
            .catch((error) => {
                // loadingEnd();
                toast.error(error.response.data);
            });
    };
    const excelDown = async () => {
        try {
            // 서버에서 직원 리스트 가져오기

            let sender = {
                agencyIdx: index,
                start_date: sdate,
                end_date: edate,
                excelDown: 1,
            };

            let excel = await agencyMaterialDetailList
                .mutateAsync(sender)
                .then(({ data }) => {
                    return data;
                })
                .catch((e) => {
                    //console.log(e.response.data);
                });
            const excelData = excel;
            //console.log(excelData);

            // Excel 파일 생성 및 다운로드
            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: {
                    bold: true,
                    color: { rgb: "000000" },
                    name: "함초롱바탕",
                    sz: 13,
                },
                fill: { fgColor: { rgb: "f1faee" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };
            const dataStyle = {
                font: { color: { rgb: "000000" }, name: "함초롱바탕", sz: 11 },
                fill: { fgColor: { rgb: "ffffff" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };

            // 열의 폭을 정의
            const colWidths = [
                150, 120, 120, 120, 120, 120, 120, 120, 200, 120,
            ];

            // cols 속성을 사용하여 각 열의 폭을 조절
            const cols = colWidths.map((width) => ({ wpx: width }));

            const headerRow = [
                { v: "날짜", t: "s", s: headerStyle },
                { v: "구분", t: "s", s: headerStyle },
                { v: "1차분류", t: "s", s: headerStyle },
                { v: "2차분류", t: "s", s: headerStyle },
                { v: "모델번호", t: "s", s: headerStyle },
                { v: "수량", t: "s", s: headerStyle },
                { v: "단가", t: "s", s: headerStyle },
                { v: "합계", t: "s", s: headerStyle },
                { v: "주소", t: "s", s: headerStyle },
                { v: "고객 연락처", t: "s", s: headerStyle },
            ];

            const dataRows = excelData?.map((data) => [
                {
                    v: moment(data.create_dt).format("YYYY-MM-DD"),
                    t: "s",
                    s: dataStyle,
                }, // 날짜
                {
                    v: data.reason || "-",
                    t: "s",
                    s: dataStyle,
                }, // 구분
                {
                    v: data.material_class01 || "-",
                    t: "s",
                    s: dataStyle,
                }, // 1차 분류
                {
                    v: data.material_class02 || "-",
                    t: "s",
                    s: dataStyle,
                }, // 2차 분류
                {
                    v: data.material_number || "-",
                    t: "s",
                    s: dataStyle,
                }, // 모델번호
                {
                    v: data.order_count || "-",
                    t: "s",
                    s: dataStyle,
                }, // 수량
                {
                    v: `${data.order_price.toLocaleString()}원`,
                    t: "s",
                    s: dataStyle,
                }, // 단가
                {
                    v: `${(
                        data.order_count * data.order_price
                    ).toLocaleString()}원`,
                    t: "s",
                    s: dataStyle,
                }, // 합계
                {
                    v: data.delivery_address || "-",
                    t: "s",
                    s: dataStyle,
                }, // 주소
                {
                    v: data.mb_phone || "-",
                    t: "s",
                    s: dataStyle,
                }, // 고객 연락처
            ]);

            const rows = [headerRow, ...dataRows];

            // 새로운 Sheet 객체 생성
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws["!cols"] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, "상담신청");

            // 파일 다운로드
            XLSX.writeFile(wb, "AgencyMaterialManagement.xlsx");

            //console.log("Excel 파일 생성 및 다운로드 완료");
        } catch (error) {
            //console.error("Error occurred while downloading Excel", error);
        }
    };
    useEffect(() => {
        agencyMaterialDetailListApi();
    }, [page]);
    return (
        <>
            <div className="content ">
                <p className="title">
                    [{location?.state?.agency_name}] 자재 관리
                </p>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <InputDate
                                type="text"
                                placeholder="시작날짜"
                                placeholderEnd="종료날짜"
                                name="ipd1"
                                label="등록일"
                                dateValue={sdate}
                                setDateValue={setsdate}
                                dateValueEnd={edate}
                                setDateValueEnd={setedate}
                                multiple={true}
                            />
                        </div>
                        {/* <InputRadioBox
                            title="목록보기"
                            Radioes={consts.lookList}
                        /> */}
                    </div>

                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button className="btn btn8">초기화</button>
                            <button className="btn btn9">검색</button>
                        </div>
                    </div>
                </div>
                <div className="logSearchResult">
                    <p>
                        조회기간 : {sdate} ~ {edate}
                    </p>
                    <p>수량 : {allData?.orderCount || 0}롤</p>
                    {/* <p>배송비 : 50,000원</p> */}
                    <p>
                        주문합계 :{" "}
                        {(
                            parseInt(allData?.orderCount || 0) *
                            parseInt(allData?.orderPrice)
                        ).toLocaleString()}
                        원
                    </p>
                </div>
                <div className="table_scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>날짜</th>
                                <th>구분</th>
                                <th>1차분류</th>
                                <th>2차분류</th>
                                <th>모델번호</th>
                                {/* <th>배송비</th> */}
                                <th>수량</th>
                                <th>단가</th>
                                <th>합계</th>
                                <th>주소, 고객 연락처</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {moment(data.create_dt).format(
                                                "YYYY-MM-DD"
                                            )}
                                        </td>
                                        <td>매입</td>
                                        <td>{data.material_class01 || "-"}</td>
                                        <td>{data.material_class02 || "-"}</td>
                                        <td>{data.material_number || "-"}</td>
                                        {/* <td>{data.price}</td> */}
                                        <td>
                                            {data.material_type === 1
                                                ? data.order_count || 0
                                                : data.count || 0}
                                        </td>
                                        <td>
                                            {data.order_price.toLocaleString() ||
                                                "-"}
                                            원
                                        </td>
                                        <td>
                                            {(
                                                (data.material_type === 1
                                                    ? data.order_count
                                                    : data.count) *
                                                data.order_price
                                            ).toLocaleString() || "-"}
                                            원
                                        </td>
                                        <td>
                                            <div>
                                                <p>
                                                    {data.delivery_address ||
                                                        ""}
                                                </p>
                                                <p>{data.mb_phone || "-"}</p>
                                            </div>
                                        </td>
                                        <td>
                                            {data?.sigonginfo_idx && (
                                                <button
                                                    onClick={() => {
                                                        data.sigonginfo_idx &&
                                                            navigate(
                                                                `${routes.constPreview3}?index=${data.sigonginfo_idx}`
                                                            );
                                                    }}
                                                    style={{
                                                        padding: "5px 10px",
                                                        backgroundColor:
                                                            "#f2f2f2",
                                                        border: "1px solid #ccc",
                                                        borderRadius: "3px",
                                                        color: "#333",
                                                        fontSize: "12px",
                                                        cursor: "pointer",
                                                        width: "70px",
                                                    }}
                                                >
                                                    보기
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="BottomContent">
                    <div className="ButtonContent"></div>
                    <div className="PaginationContent">
                        <Pagination
                            className="my-pagination"
                            // 현제 보고있는 페이지
                            activePage={page}
                            // 한페이지에 출력할 아이템수
                            itemsCountPerPage={20}
                            // 총 아이템수
                            totalItemsCount={allData?.count || 0}
                            // 표시할 페이지수
                            pageRangeDisplayed={10}
                            // 함수
                            onChange={handlePageChange}
                            nextPageText=""
                            prevPageText=""
                            firstPageText=""
                            lastPageText=""
                        ></Pagination>
                    </div>
                    <div
                        className="excelContent"
                        style={{ cursor: "pointer" }}
                        onClick={excelDown}
                    >
                        <img src={images.excel} alt="excel" />
                        <p>엑셀다운로드</p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AgencyMaterialDetail;
