import routes from "../libs/routes";
const exportData = {
    ServerImgFolder: "https://img.8amdobae.co.kr/",
    ImgState: "https://8amdobaebucket.s3.ap-northeast-2.amazonaws.com/",
    passwordPattern:
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,20}$/,
    idPattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/,
    phonePattern: /^(01[016789]{1})?[0-9]{3,4}?[0-9]{4}$/,
    phonePattern2: /^(0[2-6]{1}|01[016789]{1}|[0-9]{3})-?[0-9]{3,4}-?[0-9]{4}$/,
    numPattern: /^[0-9-]+$/,
    bank: /^[0-9]{10,16}$/,

    material: ["제조사", "1차 분류", "2차 분류", "색상", "패턴"],
    material2: ["제조사", "1차 분류", "2차 분류"],
    dayText: ["일", "월", "화", "수", "목", "금", "토"],
    constr: [
        { title: "상담신청", path: routes.constPreview1 },
        { title: "견적상담", path: routes.constPreview2 },
        { title: "시공정보", path: routes.constPreview3 },
        { title: "주문관리", path: routes.constPreview4 },
        { title: "작업종료", path: routes.constPreview5 },
        { title: "A/S관리", path: routes.constPreview6 },
    ],
    parsonnel: [
        { text: "1명", value: 1 },
        { text: "2명", value: 2 },
        { text: "3명", value: 3 },
        { text: "4명", value: 4 },
        { text: "5명", value: 5 },
        { text: "6명", value: 6 },
        { text: "7명", value: 7 },
        { text: "8명", value: 8 },
        { text: "9명", value: 9 },
        { text: "10명", value: 10 },
    ],
    molding1: [
        { text: "없음", value: 0 },
        { text: "직접입력", value: 1 },
        { text: "굽도리", value: 2 },
        { text: "걸레받이", value: 3 },
    ],
    molding2: [
        { text: "직접입력", value: 1 },
        { text: "전체", value: 2 },
    ],
    pyeong: [
        { text: "직접입력", value: 0 },
        { text: "12평", value: 12 },
        { text: "13평", value: 13 },
        { text: "14평", value: 14 },
        { text: "15평", value: 15 },
        { text: "16평", value: 16 },
        { text: "17평", value: 17 },
        { text: "18평", value: 18 },
        { text: "19평", value: 19 },
        { text: "20평", value: 20 },
        { text: "21평", value: 21 },
        { text: "22평", value: 22 },
        { text: "23평", value: 23 },
        { text: "24평", value: 24 },
        { text: "25평", value: 25 },
        { text: "26평", value: 26 },
        { text: "27평", value: 27 },
        { text: "28평", value: 28 },
        { text: "29평", value: 29 },
        { text: "30평", value: 30 },
        { text: "31평", value: 31 },
        { text: "32평", value: 32 },
        { text: "33평", value: 33 },
        { text: "34평", value: 34 },
        { text: "35평", value: 35 },
        { text: "36평", value: 36 },
        { text: "37평", value: 37 },
        { text: "38평", value: 38 },
        { text: "39평", value: 39 },
        { text: "40평", value: 40 },
        { text: "41평", value: 41 },
        { text: "42평", value: 42 },
        { text: "43평", value: 43 },
        { text: "44평", value: 44 },
        { text: "45평", value: 45 },
        { text: "46평", value: 46 },
        { text: "47평", value: 47 },
        { text: "48평", value: 48 },
        { text: "49평", value: 49 },
        { text: "50평", value: 50 },
        { text: "51평", value: 51 },
        { text: "52평", value: 52 },
        { text: "53평", value: 53 },
        { text: "54평", value: 54 },
        { text: "55평", value: 55 },
        { text: "56평", value: 56 },
        { text: "57평", value: 57 },
        { text: "58평", value: 58 },
        { text: "59평", value: 59 },
        { text: "60평", value: 60 },
    ],
    sendLevel: [
        { label: "전체", value: "" },
        { label: "발송성공", value: 1 },
        { label: "발송실패", value: 2 },
    ],
    reason: [
        {
            label: "등록",
            value: 1,
        },
        {
            label: "회수",
            value: 2,
        },
        {
            label: "반품",
            value: 3,
        },
        {
            label: "수정",
            value: 4,
        },
        {
            label: "삭제",
            value: 0,
        },
    ],
    level: [
        {
            label: "시공현황",
            value: 1,
        },
        {
            label: "자재",
            value: 2,
        },
        {
            label: "작업자",
            value: 3,
        },
        {
            label: "문자",
            value: 4,
        },
        {
            label: "관리",
            value: 5,
        },
    ],
    imgAlt: "test",
    Confirm: [
        {
            label: "확인",
            value: 1,
        },
        {
            label: "미확인",
            value: 2,
        },
        {
            label: "부재중",
            value: 3,
        },
    ],

    constructionBox: [
        {
            label: "도배",
            value: 1,
        },
        {
            label: "장판",
            value: 2,
        },
        {
            label: "마루",
            value: 3,
        },
    ],
    constructionBox2: [
        {
            label: "도배",
            value: 1,
        },
        {
            label: "장판",
            value: 2,
        },
        {
            label: "마루",
            value: 3,
        },
        {
            label: "철거",
            value: 4,
        },
        {
            label: "AS",
            value: 5,
        },
    ],
    constructionBox3: [
        {
            label: "벽지",
            value: 1,
        },
        {
            label: "장판",
            value: 2,
        },
        {
            label: "마루",
            value: 3,
        },
        {
            label: "걸레받이",
            value: 4,
        },
        {
            label: "부자재",
            value: 5,
        },
    ],
    constructionBox4: [
        {
            label: "실크",
            value: 1,
        },
        {
            label: "합지",
            value: 2,
        },
        {
            label: "혼합",
            value: 3,
        },
        {
            label: "장판",
            value: 4,
        },
        {
            label: "마루",
            value: 5,
        },
    ],
    constructionBox6: [
        {
            label: "도배",
            value: 1,
        },
        {
            label: "장판",
            value: 2,
        },
        {
            label: "마루",
            value: 3,
        },

        {
            label: "AS",
            value: 4,
        },
    ],
    areaBox: [
        {
            label: "공급면적",
            value: 1,
        },
        {
            label: "전용면적",
            value: 2,
        },
    ],
    status: [
        { label: "전체", value: 0 },
        {
            label: "진행",
            value: 2,
        },
        {
            label: "완료",
            value: 1,
        },
    ],
    status2: [
        {
            label: "진행",
            value: 1,
        },
        {
            label: "완료",
            value: 2,
        },
    ],
    status3: [
        {
            label: "진행(하자)",
            value: 1,
        },
        {
            label: "완료(하자처리완료)",
            value: 2,
        },
        {
            label: "종료(하자아님)",
            value: 3,
        },
    ],
    noMoneymail: [
        { label: "전체", value: 0 },
        {
            label: "독촉문자 발송",
            value: 1,
        },
        {
            label: "독촉문자 미발송",
            value: 2,
        },
    ],
    balancebox: [
        { label: "전체", value: 0 },
        {
            label: "미수금",
            value: 1,
        },
        {
            label: "수금",
            value: 2,
        },
    ],
    endReport: [
        { label: "전체", value: 0 },
        {
            label: "도배(제출)",
            value: 1,
        },
        {
            label: "도배(미제출)",
            value: 2,
        },
        {
            label: "장판(제출)",
            value: 3,
        },
        {
            label: "장판(미제출)",
            value: 4,
        },
    ],

    LivingEnvironmentBox: [
        {
            label: "빈집",
            value: 1,
        },
        {
            label: "거주중",
            value: 2,
        },
        {
            label: "이사당일",
            value: 3,
        },
    ],
    BarandaExpansion: [
        {
            label: "베란다 확장 없음",
            value: 1,
        },
        {
            label: "베란다 확장 있음",
            value: 2,
        },
        {
            label: "모름",
            value: 3,
        },
    ],
    time: [
        {
            text: "오전 8:00",
            value: 1,
        },
        {
            text: "오전 9:00",
            value: 2,
        },

        {
            text: "오전 10:00",
            value: 3,
        },
        {
            text: "오전 11:00",
            value: 4,
        },
        {
            text: "오후 12:00",
            value: 5,
        },
        {
            text: "오후 1:00",
            value: 6,
        },
        {
            text: "오후 2:00",
            value: 7,
        },
        {
            text: "오후 3:00",
            value: 8,
        },
        {
            text: "오후 4:00",
            value: 9,
        },
        {
            text: "오후 5:00",
            value: 10,
        },
        {
            text: "오후 6:00",
            value: 11,
        },
        {
            text: "오후 7:00",
            value: 12,
        },
        {
            text: "오후 8:00",
            value: 13,
        },
        {
            text: "오후 9:00",
            value: 14,
        },
        {
            text: "오후 10:00",
            value: 15,
        },
        {
            text: "오후 11:00",
            value: 16,
        },
    ],
    ConstructionScope: [
        {
            label: "전체",
            value: 1,
        },
        {
            label: "일부",
            value: 2,
        },
    ],
    Ceiling: [
        {
            label: "천장도배포함",
            value: 1,
        },
        {
            label: "천장도배안함",
            value: 2,
        },
    ],
    wallpaperType: [
        {
            label: "실크",
            value: 1,
        },
        {
            label: "합지",
            value: 2,
        },
        {
            label: "혼합",
            value: 3,
        },
    ],
    material: [
        {
            label: "실크",
            value: 1,
        },
        {
            label: "합지",
            value: 2,
        },
        {
            label: "혼합",
            value: 3,
        },
        {
            label: "장판",
            value: 4,
        },
        {
            label: "마루",
            value: 5,
        },
    ],
    TestEx: [
        {
            text: "직접입력",
            value: 1,
        },
        {
            text: "강마루",
            value: 2,
        },
        {
            text: "강화마루",
            value: 3,
        },
        {
            text: "SB마루",
            value: 4,
        },
    ],
    TestEx2: [
        {
            text: "종류선택",
            value: 1,
        },
        {
            label: "선택",
            value: 2,
        },
    ],

    TestEx3: [
        { text: "직접입력", value: 0 },
        {
            text: "1",
            value: 1,
        },
        {
            text: "2",
            value: 2,
        },
        {
            text: "3",
            value: 3,
        },
        {
            text: "4",
            value: 4,
        },
        {
            text: "5",
            value: 5,
        },
        {
            text: "6",
            value: 6,
        },
        {
            text: "7",
            value: 7,
        },
        {
            text: "8",
            value: 8,
        },
    ],
    TestEx4: [
        {
            text: "제조사",
            value: 1,
        },
        {
            text: "선택",
            value: 2,
        },
    ],
    TestEx5: [
        {
            text: "대분류",
            value: 1,
        },
        {
            text: "선택",
            value: 2,
        },
    ],
    TestEx6: [
        {
            text: "중분류",
            value: 1,
        },
        {
            text: "선택",
            value: 2,
        },
    ],
    TestEx7: [
        {
            text: "소분류",
            value: 1,
        },
        {
            text: "선택",
            value: 2,
        },
    ],

    threshold: [
        {
            label: "시공 없음",
            value: 0,
        },
        {
            label: "시공",
            value: 1,
        },
    ],
    //용도 영어로
    useBox: [
        {
            label: "시공",
            value: 1,
        },
        {
            label: "퀵배송",
            value: 2,
        },
        {
            label: "A/S",
            value: 3,
        },
        {
            label: "내사",
            value: 4,
        },
    ],
    submitBox: [
        {
            label: "미제출",
            value: 0,
        },
        {
            label: "제출",
            value: 1,
        },
    ],

    man: [
        {
            text: "1명",
            value: 1,
        },
        {
            text: "2명",
            value: 2,
        },
        {
            text: "3명",
            value: 3,
        },
        {
            text: "4명",
            value: 4,
        },
        {
            text: "5명",
            value: 5,
        },
    ],
    lookList: [
        {
            label: "전체",
            value: 1,
        },
        {
            label: "배송비 있는 목록만",
            value: 2,
        },
        {
            label: "배송비 없는 목록만",
            value: 3,
        },
    ],
    filter: [
        {
            label: "NEW",
            value: 1,
        },
        {
            label: "BEST",
            value: 2,
        },
    ],
    maker: [
        {
            label: "LG",
            value: 1,
        },
        {
            label: "개나리",
            value: 2,
        },
    ],
    classify1: [
        {
            label: "실크",
            value: 1,
        },
        {
            label: "합지",
            value: 2,
        },
    ],
    classify2: [
        {
            label: "Lohas",
            value: 1,
        },
        {
            label: "avenue",
            value: 2,
        },
    ],
    classify3: [
        {
            title: "1차분류",
            value: 1,
        },
        {
            title: "avenue",
            value: 2,
        },
    ],
    color: [
        {
            label: "화이트",
            value: 1,
        },
        {
            label: "베이지",
            value: 2,
        },
    ],
    pattern: [
        {
            label: "화이트",
            value: 1,
        },
        {
            label: "베이지",
            value: 2,
        },
    ],
    lookat: [
        {
            label: "비전시",
            value: 2,
        },
    ],
    filter2: [
        {
            label: "NEW",
            value: 2,
        },
        {
            label: "BEST",
            value: 3,
        },
    ],
    worker: [
        {
            label: "일반",
            value: 1,
        },
        {
            label: "반장",
            value: 2,
        },

        { label: "전체", value: 0 },
    ],
    worker2: [
        {
            label: "일반",
            value: 1,
        },
        {
            label: "반장",
            value: 2,
        },
    ],
    memoRadio: [
        {
            label: "일반",
            value: 1,
        },
        {
            label: "도배",
            value: 2,
        },
        {
            label: "장판",
            value: 3,
        },
        {
            label: "마루",
            value: 4,
        },
        {
            label: "특이사항",
            value: 5,
        },
    ],
    living: [
        {
            label: "아파트",
            value: 1,
        },
        {
            label: "오피스텔/빌라",
            value: 2,
        },
    ],
    BarandaExpansion2: [
        {
            label: "베란다 확장 없음",
            value: 2,
        },
        {
            label: "베란다 확장 있음",
            value: 1,
        },
    ],
    event: [
        {
            label: "없음",
            value: 0,
        },
        {
            label: "있음",
            value: 1,
        },
    ],

    sigongType: [
        {
            label: "도배",
            value: 1,
        },
        {
            label: "장판",
            value: 2,
        },
    ],
    Ceiling2: [
        {
            label: "천장미포함",
            value: 2,
        },
        {
            label: "천장포함",
            value: 1,
        },
    ],
    room: [
        {
            label: "전체",
            value: 1,
        },
        {
            label: "방만",
            value: 2,
        },
    ],
    material_badge: [
        { id: "materialNone", value: 0, label: "없음" },
        { id: "materialNew", value: 1, label: "NEW" },
        { id: "materialBest", value: 2, label: "BEST" },
        { id: "materialPremium", value: 3, label: "PREMIUM" },
        { id: "materialHighEnd", value: 4, label: "HIGH-END" },
    ],
};
export default exportData;
