import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../libs/routes";
import { es } from "date-fns/locale";
export const TabComponent = ({
    index,
    estimate_idx,
    sigonginfo_idx,
    order_idx,
    workend_idx,
    as_tbl,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [pathName, setPathName] = useState(location.pathname);

    useEffect(() => {
        setPathName(location.pathname);
    }, [pathName]);
    return (
        <div className="tabComponentWrap">
            <div className="tabComponentWrapper">
                <div
                    className={`tabComponent ${
                        index === null || index === undefined ? "disabled" : ""
                    } ${pathName == routes.constPreview1 ? "active" : ""}`}
                    onClick={() => {
                        if (index === null || index === undefined) {
                            return;
                        }
                        navigate(`${routes.constPreview1}?index=${index}`);
                    }}
                >
                    상담신청
                </div>
                <div
                    className={`tabComponent ${
                        estimate_idx === null || estimate_idx === undefined
                            ? "disabled"
                            : ""
                    } ${
                        pathName == routes.constPreview2 ||
                        pathName == routes.constRegst1
                            ? "active"
                            : ""
                    }`}
                    onClick={() => {
                        if (
                            estimate_idx === null ||
                            estimate_idx === undefined
                        ) {
                            return;
                        }
                        navigate(
                            `${routes.constPreview2}?index=${estimate_idx}`
                        );
                    }}
                >
                    견적상담
                </div>
                <div
                    className={`tabComponent ${
                        sigonginfo_idx === null || sigonginfo_idx === undefined
                            ? "disabled"
                            : ""
                    } ${
                        pathName == routes.constPreview3 ||
                        pathName == routes.constRegst2
                            ? "active"
                            : ""
                    }`}
                    onClick={() => {
                        if (
                            sigonginfo_idx === null ||
                            sigonginfo_idx === undefined
                        ) {
                            return;
                        }
                        navigate(
                            `${routes.constPreview3}?index=${sigonginfo_idx}`
                        );
                    }}
                >
                    시공정보
                </div>
                <div
                    className={`tabComponent ${
                        order_idx === null || order_idx === undefined
                            ? "disabled"
                            : ""
                    }${
                        pathName == routes.constPreview4 ||
                        pathName == routes.constRegst3
                            ? "active"
                            : ""
                    }`}
                    onClick={() => {
                        if (order_idx === null || order_idx === undefined) {
                            return;
                        }
                        navigate(`${routes.constPreview4}?index=${order_idx}`);
                    }}
                >
                    주문관리
                </div>
                <div
                    className={`tabComponent ${
                        workend_idx === null || workend_idx === undefined
                            ? "disabled"
                            : ""
                    }${
                        pathName == routes.constPreview5 ||
                        pathName == routes.constRegst4
                            ? "active"
                            : ""
                    }`}
                    onClick={() => {
                        if (workend_idx === null || workend_idx === undefined) {
                            return;
                        }
                        navigate(
                            `${routes.constPreview5}?index=${workend_idx}`
                        );
                    }}
                >
                    작업종료
                </div>
                <div
                    className={`tabComponent ${
                        as_tbl === null || as_tbl === undefined
                            ? "disabled"
                            : ""
                    }${
                        pathName == routes.constPreview6 ||
                        pathName == routes.constRegst5
                            ? " active"
                            : ""
                    }`}
                    onClick={() => {
                        if (as_tbl === null || as_tbl === undefined) {
                            return;
                        }
                        navigate(`${routes.constPreview6}?index=${as_tbl}`);
                    }}
                >
                    A/S관리
                </div>
            </div>
        </div>
    );
};
