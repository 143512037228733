import { useEffect, useState } from "react";
import React from "react";

import {
    BoxButtons,
    BoxContent,
    BoxContent2,
    BoxLookImg,
    BoxMemoInput,
    BoxProgress,
} from "../../../components/BoxInfo";
import { HistoryBox, HistoryMessageBox } from "../../../components/HistoryBox";
import {
    TableClassfiy,
    TableClassfiy2,
    TableClassfiy3,
    TableEndOtherCost,
    TableEndWallPaper,
    TableExpenseDetail2,
} from "../../../components/Tables";
import consts from "../../../libs/consts";
import routes from "../../../libs/routes";
import images from "../../../libs/images";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { usePostData } from "../../../utils/service";
import ApiData from "../../../libs/api";
import { toast } from "react-toastify";
import { TabComponent } from "../../../components/TabComponent";
const img = [
    `https://via.placeholder.com/150`,
    `https://via.placeholder.com/150`,
    `https://via.placeholder.com/150`,
];
function ASManagePreview() {
    const asInfoDetail = usePostData(ApiData.asInfoDetail);
    const asStatusUpdate = usePostData(ApiData.asStatusUpdate);
    const asGoMsg = usePostData(ApiData.asGoMsg);
    const asinfoMsgList = usePostData(ApiData.asinfoMsgList);
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const [ShowContent, setShowContent] = useState(true);
    const [ShowContent2, setShowContent2] = useState(true);
    const [ShowContent3, setShowContent3] = useState(true);
    const [ShowContent4, setShowContent4] = useState(true);
    const [stateValue, setStateValue] = useState(0);

    const [type, setType] = useState(1);
    const [customerName, setCustomerName] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");

    const [dobaeRange, setDobaeRange] = useState(0);
    const [dobaeRangeMemo, setDobaeRangeMemo] = useState("");
    const [zangpanRange, setZangpanRange] = useState(0);
    const [zangpanRangeMemo, setZangpanRangeMemo] = useState("");
    const [dobaeProduct, setDobaeProduct] = useState(1);
    const [dobaeCnt, setDobaeCnt] = useState(0);
    const [zangpanmm, setZangpanmm] = useState("");

    const [requestPath, setRequestPath] = useState("");
    const [requestDate, setRequestDate] = useState(new Date());

    const [dobaeAsManager, setDobaeAsManager] = useState("");
    const [zangpanAsManager, setZangpanAsManager] = useState("");
    const [sigongDobaeDate, setSigongDobaeDate] = useState(new Date());
    const [sigongZangpanDate, setSigongZangpanDate] = useState(new Date());
    const [zangpanMolding, setZangpanMolding] = useState(0);
    const [zangpanMoldingMemo, setZangpanMoldingMemo] = useState("");
    const [zangpanMoldingRange, setZangpanMoldingRange] = useState(0);
    const [zangpanMoldingRangeMemo, setZangpanMoldingRangeMemo] = useState("");
    const [dobaeWorkerMemo, setDobaeWorkerMemo] = useState("");
    const [zangpanWorkerMemo, setZangpanWorkerMemo] = useState("");
    const [dobaeImgSetting, setDobaeImgSetting] = useState([]);
    const [zangpanImgSetting, setZangpanImgSetting] = useState([]);

    const [asDobaechk, setAsDobaechk] = useState(0);
    const [asZangpanchk, setAsZangpanchk] = useState(0);
    const [dobaeDate, setDobaeDate] = useState(new Date());
    const [dobaeTime, setDobaeTime] = useState("");

    const [dobaeMemo, setDobaeMemo] = useState("");
    const [dobaeASPrice, setDobaeASPrice] = useState(0);
    const [dobaeAsSend, setDobaeAsSend] = useState(0);
    const [dobaeASSendCount, setDobaeASSendCount] = useState(0);
    const [dobaeMateList, setDobaeMateList] = useState([]);

    const [zangpanDate, setZangpanDate] = useState(new Date());
    const [zangpanTime, setZangpanTime] = useState("");

    const [zangpanAsSend, setZangpanAsSend] = useState(0);
    const [zangpanASSendCount, setZangpanASSendCount] = useState(0);
    const [zangpanMemo, setZangpanMemo] = useState("");
    const [zangpanASPrice, setZangpanASPrice] = useState(0);
    const [zangpanMateList, setZangpanMateList] = useState([]);
    const [zangpanMopMateList, setZangpanMopMateList] = useState([]);
    const [contentChange, setContentChange] = useState([]);
    const [history, setHistory] = useState([]);
    const [tap, setTap] = useState([]);
    const [workerMsg, setWorkerMsg] = useState([]);
    const [dobaeAdminMemo, setDobaeAdminMemo] = useState("");
    const [zangpanAdminMemo, setZangpanAdminMemo] = useState("");
    const [workerMsgPage, setWorkerMsgPage] = useState(1);
    const [workerMsgTotal, setWorkerMsgTotal] = useState(0);
    const navigate = useNavigate();
    const handleWorkerMsgPageChange = (pageNumber) => {
        setWorkerMsgPage(pageNumber);
    };
    const asMsgListApi = () => {
        asinfoMsgList
            .mutateAsync({ as_idx: index, workerPage: workerMsgPage - 1 })
            .then(({ data }) => {
                setWorkerMsgTotal(data?.workerMsg.count);
                setWorkerMsg(data?.workerMsg.rows);
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    useEffect(() => {
        asMsgListApi();
    }, [workerMsgPage]);

    const SendMsgClk = (type) => {
        asGoMsg
            .mutateAsync({ as_idx: index, msgType: type })
            .then(({ data }) => {
                toast.success("성공적으로 발송되었습니다.");
                asInfoDetailApi();
                asMsgListApi();
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };
    const statusUpdate = () => {
        asStatusUpdate
            .mutateAsync({ asIdx: index, status: stateValue })
            .then(({ data }) => {
                toast.success("성공적으로 변경되었습니다.");
                asInfoDetailApi();
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };

    const asInfoDetailApi = () => {
        asInfoDetail
            .mutateAsync({ asIdx: index })
            .then(({ data }) => {
                // console.log("data", data);

                setAsDobaechk(data.detail.dobae_as_chk);
                setAsZangpanchk(data.detail.zangpan_as_chk);
                setHistory(data.history);
                setStateValue(data.detail.status);
                setType(data.detail.type);
                setCustomerName(data.sigonginfo.cutomer_name);
                setCustomerPhone(data.sigonginfo.mb_phone);

                setDobaeRange(data.sigonginfo.dobae_range);
                setDobaeRangeMemo(data.sigonginfo.dobae_range_memo);
                setZangpanRange(data.sigonginfo.zangpan_range);
                setZangpanRangeMemo(data.sigonginfo.zangpan_range_memo);
                setDobaeProduct(data.sigonginfo.dobae_product);
                setDobaeCnt(data.sigonginfo.dobae_cnt);
                setZangpanmm(data.sigonginfo.zangpan_mm);

                setRequestPath(data.detail.request_path);
                setRequestDate(data.detail.create_dt);

                setDobaeDate(data.detail.dobae_as_date);
                setDobaeTime(data.detail.dobae_as_time);
                setDobaeMemo(data.detail.dobae_as_memo);
                setDobaeASPrice(data.detail.dobae_as_price);
                setDobaeMateList(data.dobae_mate);
                setDobaeAsSend(data.detail.dobae_as_send);
                setDobaeASSendCount(data.detail.dobae_as_send_count);
                setZangpanDate(data.detail.zangpan_as_date);
                setZangpanTime(data.detail.zangpan_as_time);
                setZangpanAsSend(data.detail.zangpan_as_send);
                setZangpanASSendCount(data.detail.zangpan_as_count);
                setZangpanMemo(data.detail.zangpan_as_memo);
                setZangpanASPrice(data.detail.zangpan_as_price);
                setZangpanMateList(data.zangpan_mate);
                setZangpanMopMateList(data.zangpan_sub_mate);
                setTap(data.tap);
                setDobaeImgSetting(
                    data.file
                        .filter((item, i) => item.type === 1)
                        .map((item, i) => item.path)
                );
                setZangpanImgSetting(
                    data.file
                        .filter((item, i) => item.type === 2)
                        .map((item, i) => item.path)
                );
                setDobaeAsManager(data.detail.dobae_banzang);
                setZangpanAsManager(data.detail.zangpan_banzang);
                setSigongDobaeDate(data.sigonginfo.dobae_date);
                setSigongZangpanDate(data.sigonginfo.zangpan_date);
                setZangpanMolding(data.sigonginfo.zangpan_molding);
                setZangpanMoldingMemo(data.sigonginfo.zangpan_molding_memo);
                setZangpanMoldingRange(data.sigonginfo.zangpan_molding_range);
                setZangpanMoldingRangeMemo(
                    data.sigonginfo.zangpan_molding_range_memo
                );
                setDobaeWorkerMemo(data.detail.dobae_worker_memo);
                setZangpanWorkerMemo(data.detail.zangpan_worker_memo);
                setDobaeAdminMemo(data.detail.dobae_admin_memo);
                setZangpanAdminMemo(data.detail.zangpan_admin_memo);
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };
    const OpenUrl = ({ SendType, idx, type }) => {
        if (SendType === 4) {
            window.open(
                `https://worker.8amdobae.co.kr/WorkOrder?sigongIdx=${idx}&sigongType=${type}&Looking=true`
            );
        }
    };
    useEffect(() => {
        if (contentChange.length !== 0) {
            setDobaeImgSetting(
                contentChange.file
                    .filter((item, i) => item.type === 1)
                    .map((item, i) => item.path)
            );
            setZangpanImgSetting(
                contentChange.file
                    .filter((item, i) => item.type === 2)
                    .map((item, i) => item.path)
            );

            setDobaeMemo(contentChange.dobae_as_memo);
            setDobaeDate(contentChange.dobae_as_date);
            setDobaeTime(contentChange.dobae_as_time);
            setDobaeWorkerMemo(contentChange.dobae_worker_memo);
            setDobaeASPrice(contentChange.dobae_as_price);
            setDobaeAdminMemo(contentChange.dobae_admin_memo);
            setZangpanAdminMemo(contentChange.zangpan_admin_memo);
            setDobaeMateList(
                contentChange?.mate.filter((c) => {
                    return c.material_type === 1;
                })
            );
            setDobaeAsSend(contentChange.dobae_as_send);
            setDobaeASSendCount(contentChange.dobae_as_send_count);
            setZangpanMemo(contentChange.zangpan_as_memo);
            setZangpanDate(contentChange.zangpan_as_date);
            setZangpanTime(contentChange.zangpan_as_time);
            setZangpanAsSend(contentChange.zangpan_as_send);
            setZangpanASSendCount(contentChange.zangpan_as_send_count);
            setZangpanWorkerMemo(contentChange.zangpan_worker_memo);
            setZangpanASPrice(contentChange.zangpan_as_price);

            setZangpanMateList(
                contentChange?.mate.filter((c) => {
                    return c.material_type === 2;
                })
            );
            setZangpanMopMateList(
                contentChange?.mate.filter((c) => {
                    return c.material_type === 4;
                })
            );

            // console.log("contentChange", contentChange);
        }
    }, [contentChange]);
    useEffect(() => {
        asInfoDetailApi();
        asMsgListApi();
    }, [index]);
    return (
        <div className="content ">
            <TabComponent
                index={tap?.counseling_idx}
                estimate_idx={tap?.estimate_idx}
                sigonginfo_idx={tap?.sigonginfo_idx}
                order_idx={tap?.order_idx}
                workend_idx={tap?.sigonginfo_idx}
                as_tbl={index}
            />
            <div>
                <BoxProgress
                    Radioes={consts.status3}
                    value={stateValue}
                    setValue={setStateValue}
                    isAs={true}
                    onClk={statusUpdate}
                />
                <HistoryBox
                    Info={history}
                    setContentChange={setContentChange}
                />
                <div className="BorderBox">
                    <div
                        className="title HistoryBox"
                        style={{ borderBottom: "1px solid #ccc" }}
                        onClick={() => setShowContent(!ShowContent)}
                    >
                        <div className="left">
                            <h1>본 시공 정보</h1>
                        </div>
                        <div className="right">
                            <svg
                                style={{
                                    transform: ShowContent
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                }}
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                    fill="#333333"
                                />
                            </svg>
                        </div>
                    </div>
                    {ShowContent && (
                        <div className="HistoryBoxcontent">
                            <div className="contentBorderWrap">
                                <BoxContent
                                    title="고객명"
                                    content={customerName || "-"}
                                />
                                <BoxContent
                                    title="연락처"
                                    content={customerPhone || "-"}
                                />
                                {asDobaechk == 1 && (
                                    <BoxContent
                                        title="도배 반장"
                                        content={dobaeAsManager || "-"}
                                    />
                                )}
                                {asZangpanchk == 1 && (
                                    <BoxContent
                                        title="장판 반장"
                                        content={zangpanAsManager || "-"}
                                    />
                                )}
                            </div>
                            {asDobaechk == 1 && (
                                <div className="title_color_box">
                                    <div
                                        className="box_title"
                                        style={{
                                            backgroundColor: "#41b06e",
                                            color: "white",
                                        }}
                                    >
                                        <h1>도배</h1>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="contentBorderWrap">
                                            <BoxContent
                                                title="시공일"
                                                content={sigongDobaeDate}
                                            />

                                            <BoxContent
                                                title="시공범위"
                                                content={`${
                                                    dobaeRange == 1
                                                        ? "전체"
                                                        : "일부"
                                                } ${
                                                    dobaeRangeMemo
                                                        ? `(${dobaeRangeMemo})`
                                                        : ""
                                                }`}
                                            />
                                            {/* <BoxContent
                                                    title="천장"
                                                    content={
                                                        dobaeCeiling == 1
                                                            ? "천장도배포함"
                                                            : "천장도배없음"
                                                    }
                                                /> */}

                                            <BoxContent
                                                title="벽지 종류"
                                                content={
                                                    dobaeProduct == 1
                                                        ? "실크"
                                                        : dobaeProduct == 2
                                                        ? "합지"
                                                        : "혼합"
                                                }
                                            />
                                            <BoxContent
                                                title="벽지 수"
                                                content={`${dobaeCnt}개`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {asZangpanchk == 1 && (
                                <div className="title_color_box">
                                    <div
                                        className="box_title"
                                        style={{
                                            backgroundColor: "#fb8500",
                                            color: "white",
                                        }}
                                    >
                                        <h1>장판</h1>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="contentBorderWrap">
                                            <BoxContent
                                                title="시공일"
                                                content={sigongZangpanDate}
                                            />

                                            <BoxContent
                                                title="시공범위"
                                                content={`${
                                                    zangpanRange == 1
                                                        ? "전체"
                                                        : "일부"
                                                } ${
                                                    zangpanRangeMemo
                                                        ? `(${zangpanRangeMemo})`
                                                        : ""
                                                }`}
                                            />

                                            <BoxContent
                                                title="장판"
                                                content={`${zangpanmm}`}
                                            />
                                            <BoxContent2
                                                title="하단몰딩"
                                                content1={`종류 : ${
                                                    zangpanMolding == 0
                                                        ? "없음"
                                                        : zangpanMolding == 1
                                                        ? zangpanMoldingMemo
                                                        : zangpanMolding == 2
                                                        ? "굽도리"
                                                        : "걸레받이"
                                                }`}
                                                content2={`범위 : ${
                                                    parseInt(
                                                        zangpanMoldingRange
                                                    ) == 2
                                                        ? "전체"
                                                        : zangpanMoldingRangeMemo
                                                        ? zangpanMoldingRangeMemo
                                                        : "-"
                                                }`}
                                            />

                                            {/* <BoxContent
                                                    title="문턱"
                                                    content={
                                                        zangpanThreshold == 1
                                                            ? "시공"
                                                            : "시공없음"
                                                    }
                                                /> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="BorderBox">
                    <div
                        className="title HistoryBox"
                        style={{ borderBottom: "1px solid #ccc" }}
                        onClick={() => setShowContent2(!ShowContent2)}
                    >
                        <div className="left">
                            <h1>A/S 요청 정보</h1>
                        </div>
                        <div className="right">
                            <svg
                                style={{
                                    transform: ShowContent2
                                        ? "rotate(0deg)"
                                        : "rotate(180deg)",
                                }}
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                    fill="#333333"
                                />
                            </svg>
                        </div>
                    </div>
                    {ShowContent2 && (
                        <div className="HistoryBoxcontent">
                            <div className="contentBorderWrap">
                                <BoxContent
                                    title="요청 경로"
                                    content={
                                        requestPath === 0
                                            ? "고객 a/s 접수페이지 요청"
                                            : "관리자 페이지 요청건"
                                    }
                                />

                                <BoxContent
                                    title="요청일시"
                                    content={
                                        requestDate
                                            ? `${moment(requestDate).format(
                                                  "YYYY-MM-DD"
                                              )}(${
                                                  consts.dayText[
                                                      moment(requestDate).day()
                                                  ]
                                              })`
                                            : "-"
                                    }
                                />

                                <BoxContent
                                    title="시공구분"
                                    content={
                                        asDobaechk === 1 && asZangpanchk
                                            ? "도배 장판"
                                            : asDobaechk === 1
                                            ? "도배"
                                            : "장판"
                                    }
                                />
                                {history?.reduce((acc, x, i) => {
                                    if (i === history.length - 1) return acc; // 마지막 요소는 제외
                                    acc.push(
                                        <React.Fragment key={i}>
                                            {asDobaechk === 1 && (
                                                <BoxMemoInput
                                                    title={`도배 요청내용 ${moment(
                                                        x.create_dt
                                                    ).format(
                                                        "YYYY-MM-DD HH:mm"
                                                    )}`}
                                                    value={
                                                        x.dobae_as_memo || ""
                                                    }
                                                    readOnly={true}
                                                />
                                            )}
                                            {asZangpanchk === 1 && (
                                                <BoxMemoInput
                                                    title={`장판 요청내용 ${moment(
                                                        x.create_dt
                                                    ).format(
                                                        "YYYY-MM-DD HH:mm"
                                                    )}`}
                                                    value={
                                                        x.zangpan_as_memo || ""
                                                    }
                                                    readOnly={true}
                                                />
                                            )}
                                            {asDobaechk === 1 && (
                                                <div className="ImgInputWrap border-bot">
                                                    <div className="Imgtitle">
                                                        {`도배 이미지 업로드 ${moment(
                                                            x.create_dt
                                                        ).format(
                                                            "YYYY-MM-DD HH:mm"
                                                        )}`}
                                                    </div>
                                                    <div className="sub flex-al-center">
                                                        <div
                                                            style={{
                                                                display: "grid",
                                                                gridTemplateColumns:
                                                                    "repeat(4,1fr)",
                                                                gap: "10px",
                                                                justifyItems:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            {x.file
                                                                .filter(
                                                                    (item) =>
                                                                        item.type ===
                                                                        1
                                                                )
                                                                .map(
                                                                    (
                                                                        item,
                                                                        j
                                                                    ) => (
                                                                        <img
                                                                            style={{
                                                                                marginRight:
                                                                                    "10px",
                                                                                maxWidth:
                                                                                    "350px",
                                                                            }}
                                                                            key={
                                                                                j
                                                                            }
                                                                            src={`${consts.ImgState}${item.path}`}
                                                                        />
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {asZangpanchk === 1 && (
                                                <div className="ImgInputWrap border-bot">
                                                    <div className="Imgtitle">
                                                        {`장판 이미지 업로드 ${moment(
                                                            x.create_dt
                                                        ).format(
                                                            "YYYY-MM-DD HH:mm"
                                                        )}`}
                                                    </div>
                                                    <div className="sub flex-al-center">
                                                        <div
                                                            style={{
                                                                display: "grid",
                                                                gridTemplateColumns:
                                                                    "repeat(4,1fr)",
                                                                gap: "10px",
                                                                justifyItems:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            {x.file
                                                                .filter(
                                                                    (item) =>
                                                                        item.type ===
                                                                        2
                                                                )
                                                                .map(
                                                                    (
                                                                        item,
                                                                        j
                                                                    ) => (
                                                                        <img
                                                                            style={{
                                                                                marginRight:
                                                                                    "10px",
                                                                                maxWidth:
                                                                                    "350px",
                                                                            }}
                                                                            key={
                                                                                j
                                                                            }
                                                                            src={`${consts.ImgState}${item.path}`}
                                                                        />
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                    return acc;
                                }, [])}
                                {asDobaechk === 1 && (
                                    <BoxMemoInput
                                        title="도배 요청내용"
                                        value={dobaeMemo || ""}
                                        readOnly={true}
                                    />
                                )}
                                {asZangpanchk === 1 && (
                                    <BoxMemoInput
                                        title="장판 요청내용"
                                        value={zangpanMemo || ""}
                                        readOnly={true}
                                    />
                                )}
                                {asDobaechk === 1 && (
                                    <div
                                        className="ImgInputWrap border-bot"
                                        // style={{ border: "none", borderBottom: "1px solid #ddd" }}
                                    >
                                        <div className="Imgtitle">
                                            도배 이미지 업로드
                                        </div>
                                        <div
                                            className="sub flex-al-center"
                                            // style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <div
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns:
                                                        "repeat(4,1fr)",
                                                    gap: "10px",
                                                    justifyItems: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {dobaeImgSetting?.map(
                                                    (x, i) => (
                                                        <img
                                                            style={{
                                                                marginRight:
                                                                    "10px",
                                                                maxWidth:
                                                                    "350px",
                                                            }}
                                                            key={i}
                                                            src={`${consts.ImgState}${x}`}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {asZangpanchk === 1 && (
                                    <div
                                        className="ImgInputWrap border-bot"
                                        // style={{ border: "none", borderBottom: "1px solid #ddd" }}
                                    >
                                        <div className="Imgtitle">
                                            장판 이미지 업로드
                                        </div>
                                        <div
                                            className="sub flex-al-center"
                                            // style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <div
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns:
                                                        "repeat(4,1fr)",
                                                    gap: "10px",
                                                    justifyItems: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {zangpanImgSetting?.map(
                                                    (x, i) => (
                                                        <img
                                                            style={{
                                                                marginRight:
                                                                    "10px",
                                                                maxWidth:
                                                                    "350px",
                                                            }}
                                                            key={i}
                                                            src={`${consts.ImgState}${x}`}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="BorderBox">
                    <div
                        className="title HistoryBox"
                        style={{ borderBottom: "1px solid #ccc" }}
                        onClick={() => setShowContent3(!ShowContent3)}
                    >
                        <div className="left">
                            <h1>A/S 작업정보</h1>
                        </div>
                        <div className="right">
                            <svg
                                style={{
                                    transform: ShowContent3
                                        ? "rotate(0deg)"
                                        : "rotate(180deg)",
                                }}
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                    fill="#333333"
                                />
                            </svg>
                        </div>
                    </div>
                    {ShowContent3 && (
                        <div className="HistoryBoxcontent">
                            <div className="contentBorderWrap">
                                {asDobaechk === 1 && (
                                    <div className="title_color_box">
                                        <div
                                            className="box_title"
                                            style={{
                                                backgroundColor: "#41b06e",
                                                color: "white",
                                            }}
                                        >
                                            <h1>도배</h1>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="subTitleCircle">
                                                <div className="GreenCircle"></div>
                                                <h3>시공일 및 인력배정</h3>
                                            </div>
                                            <div className="contentBorderWrap">
                                                <BoxContent
                                                    title="시공일"
                                                    content={
                                                        moment(
                                                            dobaeDate
                                                        ).format(
                                                            "YYYY-MM-DD"
                                                        ) || "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="시공시간"
                                                    content={dobaeTime}
                                                />
                                                <BoxButtons
                                                    title="작업지시서"
                                                    IsSend={
                                                        dobaeAsSend === 0
                                                            ? "미확인"
                                                            : "확인"
                                                    }
                                                    OpenUrl={() =>
                                                        OpenUrl({
                                                            SendType: 4,
                                                            idx: tap.idx,
                                                            type: 1,
                                                        })
                                                    }
                                                    gbtn={true}
                                                    bbtn={true}
                                                    bbtnNum={
                                                        dobaeASSendCount || 0
                                                    }
                                                    bbtnClk={() =>
                                                        SendMsgClk(1)
                                                    }
                                                />
                                                <BoxContent
                                                    title="A/S 비용"
                                                    content={dobaeASPrice.toLocaleString()}
                                                />
                                                <BoxMemoInput
                                                    title="A/S작업자 전달사항"
                                                    value={dobaeWorkerMemo}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <p
                                                style={{
                                                    textDecoration: "underline",
                                                    fontWeight: "700",
                                                    marginTop: "20px ",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                A/S 작업시 자재 주문이 필요한
                                                경우에만 자재와 수량을 입력해
                                                주세요.
                                            </p>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="subTitleCircle">
                                                <div className="GreenCircle"></div>
                                                <h3>선택벽지</h3>
                                            </div>
                                            <TableClassfiy
                                                list={dobaeMateList}
                                            />
                                            <div
                                                className="contentBorderWrap"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <BoxMemoInput
                                                    title="A/S 도배 메모"
                                                    value={dobaeAdminMemo}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {asZangpanchk === 1 && (
                                    <div className="title_color_box">
                                        <div
                                            className="box_title"
                                            style={{
                                                backgroundColor: "#fb8500",
                                                color: "white",
                                            }}
                                        >
                                            <h1>장판</h1>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="subTitleCircle">
                                                <div className="GreenCircle"></div>
                                                <h3>시공일 및 인력배정</h3>
                                            </div>
                                            <div className="contentBorderWrap">
                                                <BoxContent
                                                    title="시공일"
                                                    content={
                                                        moment(
                                                            zangpanDate
                                                        ).format(
                                                            "YYYY-MM-DD"
                                                        ) || "-"
                                                    }
                                                />
                                                <BoxContent
                                                    title="시공시간"
                                                    content={zangpanTime}
                                                />
                                                <BoxButtons
                                                    title="작업지시서"
                                                    IsSend={
                                                        zangpanAsSend === 0
                                                            ? "미확인"
                                                            : "확인"
                                                    }
                                                    OpenUrl={() =>
                                                        OpenUrl({
                                                            SendType: 4,
                                                            idx: tap.idx,
                                                            type: 2,
                                                        })
                                                    }
                                                    gbtn={true}
                                                    bbtn={true}
                                                    bbtnNum={
                                                        zangpanASSendCount || 0
                                                    }
                                                    bbtnClk={() =>
                                                        SendMsgClk(2)
                                                    }
                                                />
                                                <BoxContent
                                                    title="A/S 비용"
                                                    content={zangpanASPrice.toLocaleString()}
                                                />
                                                <BoxMemoInput
                                                    title="A/S작업자 전달사항"
                                                    value={zangpanWorkerMemo}
                                                    readOnly={true}
                                                />
                                            </div>
                                            <p
                                                style={{
                                                    textDecoration: "underline",
                                                    fontWeight: "700",
                                                    marginTop: "20px ",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                A/S 작업시 자재 주문이 필요한
                                                경우에만 자재와 수량을 입력해
                                                주세요.
                                            </p>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="subTitleCircle">
                                                <div className="GreenCircle"></div>
                                                <h3>선택 제품</h3>
                                            </div>
                                            <TableClassfiy3
                                                list={zangpanMateList}
                                            />
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="subTitleCircle">
                                                <div className="GreenCircle"></div>
                                                <h3>걸레받이</h3>
                                            </div>
                                            <TableClassfiy2
                                                list={zangpanMopMateList}
                                            />
                                            <div
                                                className="contentBorderWrap"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <BoxMemoInput
                                                    title="A/S 장판 메모"
                                                    value={zangpanAdminMemo}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                        margin: "20px 0",
                    }}
                >
                    <button
                        className="ChangeBtn"
                        onClick={() => {
                            navigate(`${routes.constRegst5}?index=${index}`);
                        }}
                    >
                        변경
                    </button>
                </div>
                {/* <HistoryMessageBox /> */}
                <HistoryMessageBox
                    IsWorker={true}
                    Info={workerMsg}
                    page={workerMsgPage}
                    handlePageChange={handleWorkerMsgPageChange}
                    totalCount={workerMsgTotal}
                />
            </div>
        </div>
    );
}

export default ASManagePreview;
