import { useEffect, useState } from "react";
import Input from "../../components/Input";
import { useGetData, usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import { toast } from "react-toastify";
import { allSetValue } from "../../utils/utils";
import InputFile from "../../components/InputFile";
import images from "../../libs/images";
import useLoadingStore from "../../zustand/Loading";
const ImgState = "https://8amdobaebucket.s3.ap-northeast-2.amazonaws.com/";
function ExhibitionBanner() {
    const [list, setlist] = useState([]);
    const [deleteidxs, setdeleteidxs] = useState([]);

    const { data, isLoading, isError } = useGetData(ApiData.BannerList);
    // const { loadingStart, loadingEnd } = useLoadingStore();

    const BannerDelete = usePostData(ApiData.BannerDelete);
    const BannerRegUpdate = usePostData(ApiData.BannerRegUpdate);

    useEffect(() => {
        // isLoading ? loadingStart() : loadingEnd();
        setlist(data?.data);
    }, [data]);

    const submitAlert = (idx) => {
        function isValidUrl(url) {
            const pattern = new RegExp(
                "^(https?:\\/\\/)?" + // protocol
                    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
                    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                    "(\\:\\d+)?" + // port
                    "(\\/[-a-z\\d%_.~+]*)*" + // path
                    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                    "(\\#[-a-z\\d_]*)?$",
                "i"
            ); // fragment locator
            return !!pattern.test(url);
        }
        const urls = list
            ?.filter((item) => item.idx === idx)
            .map((item) => item.url);
        const validUrls = urls.filter(isValidUrl);

        const imgsUrl = list
            ?.filter((item) => item.idx === idx)
            .map((item) => item.img_url);
        let sender = {};
        if (
            !urls || // urls가 undefined, null, 또는 빈 배열인 경우
            (urls.length === 1 && urls[0] === "") // urls가 하나의 빈 문자열을 포함하는 배열인 경우
        ) {
            toast.error("연결URL을 입력해 주세요.");
            return;
        }
        if (validUrls.length === 0) {
            toast.error("올바른 URL을 입력해 주세요.");
            return;
        }
        if (
            !imgsUrl || // imgsUrl이 undefined, null, 또는 빈 배열인 경우
            (imgsUrl.length === 1 &&
                (imgsUrl[0] === "" || imgsUrl[0] === undefined)) // imgsUrl이 하나의 요소를 가지고, 그 요소가 빈 문자열 또는 undefined인 경우
        ) {
            toast.error("이미지를 첨부해 주세요.");
            return;
        }
        if (imgsUrl[0].includes("banner")) {
            sender = {
                url: urls[0],
                imgType: "",
                bannerImg: imgsUrl[0],
            };
        } else {
            sender = {
                url: urls[0],
                imgType: 1,
                bannerImg: imgsUrl[0],
            };
        }
        if (idx) {
            sender.bannerIdx = idx;
        }
        // console.log("sender", sender);
        BannerRegUpdate.mutateAsync(sender)
            .then(({ data }) => {
                // console.log("성공..");
            })
            .catch((e) => {
                // console.log(e);
                toast.error(e.response.data);
            });

        // if (list?.filter((item) => !item.url).length > 0) {
        //     toast.error("연결URL을 입력해 주세요.");
        //     return;
        // }
        // if (list?.filter((item) => !item?.pc_path).length > 0) {
        //     toast.error("PC이미지를 첨부해 주세요.");
        //     return;
        // }
        // if (list?.filter((item) => !item?.m_path).length > 0) {
        //     toast.error("모바일이미지를 첨부해 주세요.");
        //     return;
        // }
    };
    const zoneAppend = (type) => {
        //리스트의 제일 마지막 idx값을 가져온다.
        // const idx = list?.length > 0 ? list[list.length - 1].idx + 1 : 1;

        setlist([...list, { url: "" }]);
    };
    const zoneDelete = (i, idx) => {
        BannerDelete.mutateAsync({ bannerIdx: idx })
            .then(({ data }) => {
                // console.log("성공..");
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
        setlist(list?.filter((item, index) => index !== i));
        if (idx) {
            setdeleteidxs([...deleteidxs, idx]);
        }
    };

    const zoneSetValue = (i, e, val) => {
        setlist(allSetValue(i, e, val, list));
    };

    const tableUpdateData = (idx, key, value) => {
        // console.log(value);
        setlist(
            list?.map((x, i) => {
                if (i !== idx) return x;
                return { ...x, [key]: value };
            })
        );
    };

    return (
        <>
            <div className="content ">
                <p className="title">기획전베너관리</p>
                <div className="title_container"></div>
                <div className="grid_container">
                    <div className="multi_table_detail">
                        <div className="theme_multi_info_box">
                            {list?.map((x, i) => {
                                return (
                                    <div className="casezone_box" key={i}>
                                        <div className="titleBox">
                                            <p className="title">
                                                배너 {i + 1}
                                            </p>

                                            <div className="BtnWrap">
                                                <button
                                                    className="btn btn9"
                                                    onClick={() => {
                                                        submitAlert(x.idx);
                                                    }}
                                                >
                                                    저장하기
                                                </button>
                                                <button
                                                    type="button"
                                                    className="trash_btn_red"
                                                    onClick={() =>
                                                        zoneDelete(
                                                            i,
                                                            x.idx || false
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="one_line half_two">
                                            <Input
                                                className="input_text"
                                                type="text"
                                                placeholder="내용을 입력해 주세요."
                                                name="url"
                                                value={x.url}
                                                onChange={zoneSetValue}
                                                index={i}
                                                label="연결URL"
                                            />
                                        </div>

                                        <div className="one_line">
                                            <InputFile
                                                label={"PC 1200*400px"}
                                                type="file"
                                                idx={i}
                                                name={"img_url"}
                                                value={
                                                    x.img_url &&
                                                    (x.img_url.includes(
                                                        "banner"
                                                    )
                                                        ? `${ImgState}${x.img_url}`
                                                        : `${x.img_url}`)
                                                }
                                                alt={"Banner"}
                                                onChange={tableUpdateData}
                                                valid="image"
                                                style={{ flex: 1.68 }}
                                                imgStyle={{
                                                    aspectRatio: "1200/400",
                                                }}
                                            />
                                            {/* <InputFile
                                                label={"Mobile 750*420px"}
                                                type="file"
                                                idx={i}
                                                name={"m_path"}
                                                value={x.m_path}
                                                onChange={tableUpdateData}
                                                valid="image"
                                                imgStyle={{
                                                    aspectRatio: "750/420",
                                                }}
                                            /> */}
                                        </div>
                                    </div>
                                );
                            })}

                            <button
                                type="button"
                                className="case_add_btn"
                                onClick={() => zoneAppend()}
                            >
                                <img
                                    src={images.add}
                                    alt="추가하기"
                                    className="plus_img"
                                />
                                배너 추가하기
                            </button>
                        </div>
                    </div>
                </div>

                {/* {data?.data?.map((x, i) => {
                    {
                        console.log(x.url);
                    }
                    return (
                        <div key={i} className="bannerWrap">
                            <div className="bannerUrl">
                                <div className="title">배너 {i + 1}</div>
                                <div className="BannerInput">
                                    <Input
                                        className="input_text"
                                        type="text"
                                        placeholder="연결할 URL "
                                        name="stx"
                                        value={urls[i]}
                                        setValue={(value) => {
                                            const newUrls = [...urls];
                                            newUrls[i] = value;
                                            setUrls(newUrls);
                                        }}
                                    />
                                </div>
                                <div className="BannerBtn">
                                    <button className="btn btn9">저장</button>
                                    <button className="btn btn8">삭제</button>
                                </div>
                            </div>
                            <div className="bannerImg">
                                <img src={`${ImgState}${x.img}`} alt="banner" />
                            </div>
                        </div>
                    );
                })}

                <div style={{ justifyContent: "center" }} className="BtnEnd">
                    <button className="btn btn9">추가</button>
                </div> */}
            </div>
        </>
    );
}

export default ExhibitionBanner;
