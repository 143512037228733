const exportData = {
    constr1: "/ReqforConsult",
    constr2: "/EstimateReq",
    constr3: "/ConstrManagement",
    constr4: "/OrderManagement",
    constr5: "/JobFinished",
    constr6: "/ASManagement",
    constr7: "/ConstrCalendar",
    constr8: "/VisitCalendar",
    constr9: "/DeleteList",
    constr10: "/ConsultDetail",

    constRegst1: "/EstimateReqResgister",
    constRegst2: "/ConstrManagementRegister",
    constRegst3: "/OrderManageRegister",
    constRegst4: "/JobFinishedRegister",
    constRegst5: "/ASManageRegister",

    constPreview1: "/ReqForConsultPreview",
    constPreview2: "/EstimateReqPreview",
    constPreview3: "/ConstrManagPreview",
    constPreview4: "/OrderManagePreview",
    constPreview5: "/JobFinishedPreview",
    constPreview6: "/ASManagePreview",

    material1: "/InventManagement",
    material2: "/WInventManagement",
    material3: "/ReturnManagement",
    material4: "/AgencyMaterialManagement",
    material5: "/MaterialListManagement",
    material6: "/MaterialClassifManagement",
    material7: "/AgencyManagement",

    material2_1: "/LookInventory",
    material2_2: "/LogSearch",

    material4_1: "/AgencyMaterialDetail",

    material5_1: "/MaterialListManageDetail",

    material7_1: "/AgencyManageDetail",

    worker: "/WorkerManagement",
    workerDetail: "/WorkerManageDetail",

    message1: "/OutgoingList",
    message2: "/IncomingList",
    message3: "/CharacterTemplate",
    message4: "/SystemMessManagement",

    management1: "/ExhibitionBanner",
    management2: "/FAQManagement",
    management3: "/ManegerAccManagement",
    management4: "/PriceSetting",

    managementDetail1: "/FAQManagementDetail",
    managementDetail2: "/ManagerAccDetail",

    login: "/login",

    main: "/",
};

export default exportData;
