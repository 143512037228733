import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import routes from "../libs/routes";
import images from "../libs/images";
import consts from "../libs/consts";
import { useEffect, useState } from "react";
import Button from "./Button";
import LeftNav from "./LeftNav";
import { useUser } from "../zustand/Login";
import Input from "./Input";
import { toast } from "react-toastify";
import { usePostData } from "../utils/service";
import ApiData from "../libs/api";

const initialMenu = [
    {
        route: routes.constr1,
        level: 1,
        title: "시공 현황",
        valueMulti: [
            routes.constr1,
            routes.constr2,
            routes.constr3,
            routes.constr4,
            routes.constr5,
            routes.constr6,
            routes.constr7,
            routes.constr8,
            routes.constr9,
            routes.constr10,
            routes.constRegst1,
            routes.constRegst2,
            routes.constRegst3,
            routes.constRegst4,
            routes.constRegst5,
            routes.constPreview1,
            routes.constPreview2,
            routes.constPreview3,
            routes.constPreview4,
            routes.constPreview5,
            routes.constPreview6,
        ],
        leftNav: [
            {
                route: routes.constr1,
                title: "상담신청",
                valueMulti: ["/", routes.constr1, routes.constPreview1],
            },
            {
                route: routes.constr2,
                title: "견적상담",
                valueMulti: [
                    routes.constr2,
                    routes.constRegst1,
                    routes.constPreview2,
                ],
            },
            {
                route: routes.constr3,
                title: "시공관리",
                valueMulti: [
                    routes.constr3,
                    routes.constRegst2,
                    ,
                    routes.constPreview3,
                ],
            },
            {
                route: routes.constr4,
                title: "주문관리",
                valueMulti: [
                    routes.constr4,
                    routes.constRegst3,
                    ,
                    routes.constPreview4,
                ],
            },
            {
                route: routes.constr5,
                title: "작업종료",
                valueMulti: [
                    routes.constr5,
                    routes.constRegst4,
                    ,
                    routes.constPreview5,
                ],
            },
            {
                route: routes.constr6,
                title: "A/S관리",
                valueMulti: [
                    routes.constr6,
                    routes.constRegst5,
                    ,
                    routes.constPreview6,
                ],
            },
            {
                route: routes.constr7,
                title: "시공캘린더",
                valueMulti: [routes.constr7],
            },
            {
                route: routes.constr8,
                title: "방문캘린더",
                valueMulti: [routes.constr8],
            },
            {
                route: routes.constr9,
                title: "삭제목록",
                valueMulti: [routes.constr9],
            },
        ],
    },
    {
        route: routes.material1,
        level: 2,
        title: "자재",
        valueMulti: [
            routes.material1,
            routes.material2,
            routes.material3,
            routes.material4,
            routes.material5,
            routes.material6,
            routes.material7,
            routes.material2_1,
            routes.material2_2,
            routes.material4_1,
            routes.material5_1,
            routes.material7_1,
        ],
        leftNav: [
            {
                route: routes.material1,
                title: "자재재고관리",
                valueMulti: [routes.material1],
            },
            {
                route: routes.material2,
                title: "작업자재고관리",
                valueMulti: [
                    routes.material2,
                    routes.material2_1,
                    routes.material2_2,
                ],
            },
            {
                route: routes.material3,
                title: "반품관리",
                valueMulti: [routes.material3],
            },
            {
                route: routes.material4,
                title: "대리점자재관리",
                valueMulti: [routes.material4, routes.material4_1],
            },
            {
                route: routes.material5,
                title: "자재목록관리",
                valueMulti: [routes.material5, routes.material5_1],
            },
            {
                route: routes.material6,
                title: "자재분류관리",
                valueMulti: [routes.material6],
            },
            {
                route: routes.material7,
                title: "대리점관리",
                valueMulti: [routes.material7, routes.material7_1],
            },
        ],
    },
    {
        route: routes.worker,
        level: 3,
        title: "작업자",
        valueMulti: [routes.worker, routes.workerDetail],
        leftNav: [
            {
                route: routes.worker,
                title: "작업자 관리",
                valueMulti: [routes.worker, routes.workerDetail],
            },
        ],
    },
    {
        route: routes.message1,
        level: 4,
        title: "문자",
        valueMulti: [
            routes.message1,
            routes.message2,
            routes.message3,
            routes.message4,
        ],
        leftNav: [
            {
                route: routes.message1,
                title: "발신목록",
                valueMulti: [routes.message1],
            },
            {
                route: routes.message2,
                title: "수신목록",
                valueMulti: [routes.message2],
            },
            {
                route: routes.message3,
                title: "문자템플릿",
                valueMulti: [routes.message3],
            },
            {
                route: routes.message4,
                title: "시스템문자관리",
                valueMulti: [routes.message4],
            },
        ],
    },
    {
        route: routes.management1,
        level: 5,
        title: "관리",
        valueMulti: [
            routes.management1,
            routes.management2,
            routes.management3,
            routes.management4,
            routes.managementDetail1,
            routes.managementDetail2,
        ],
        leftNav: [
            {
                route: routes.management1,
                title: "기획전베너관리",
                valueMulti: [routes.management1],
            },
            {
                route: routes.management2,
                title: "FAQ관리",
                valueMulti: [routes.management2, routes.managementDetail1],
            },
            {
                route: routes.management3,
                title: "담당자계정관리",
                valueMulti: [routes.management3, routes.managementDetail2],
            },
            {
                route: routes.management4,
                title: "가격설정",
                valueMulti: [routes.management4],
            },
        ],
    },
];

export default function Header() {
    const [searchOpen, setsearchOpen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const [menu, setMenu] = useState(initialMenu);
    // const [firstLevel, setFirstLevel] = useState(1);
    const { mbData, ResetMbData } = useUser();
    const [searchVal, setSearchVal] = useState("");

    const searchValApi = usePostData(ApiData.searchVal);

    useEffect(() => {
        const mb_level = mbData?.mb_level?.map((c) => parseInt(c)).sort();
        const firstLevel = mb_level?.[0];

        const updatedMenu = menu?.map((item) => {
            if (item.level === firstLevel) {
                // item을 복사하여 수정
                const newItem = { ...item };
                newItem.valueMulti = [
                    "/",
                    ...newItem.valueMulti.filter((v) => v !== "/"),
                ];

                // leftNav의 첫 번째 항목을 복사하여 수정
                newItem.leftNav = newItem.leftNav.map((navItem, index) => {
                    if (index === 0) {
                        return {
                            ...navItem,
                            valueMulti: [
                                "/",
                                ...navItem.valueMulti.filter((v) => v !== "/"),
                            ],
                        };
                    }
                    return navItem;
                });

                return newItem;
            }
            return item;
        });
        // console.log("updatedMenu", updatedMenu);
        setMenu(updatedMenu); // 상태로 설정
        // console.log("mbData", mbData);
    }, [mbData]);

    const linkFunc = (link) => {
        // dispatch(reset());
        if (link) {
            // dispatch( setPopupStorage({ key: 'cadastral', value: false }) );
            // dispatch( setPopupStorage({ key: 'drawing', value: false }) );
            // dispatch(
            //     searchSetData({
            //         key: "sc",
            //         value: 0,
            //     })
            // );

            navigate(link);
        }
    };
    const handleLogout = () => {
        ResetMbData();
        setMenu(initialMenu); // menu 초기화
        // console.log("로그아웃", initialMenu);
    };
    const SearchClk = () => {
        let sender = {
            searchVal: searchVal,
        };
        if (!searchVal) {
            toast.error("값 입력해주세요.");
            return;
        }
        // else if (searchVal.includes("-")) {
        //     sender.searchVal = searchVal.replace(/-/g, "");
        // } else if (searchVal.includes(" ")) {
        //     sender.searchVal = searchVal.replace(/ /g, "");
        // } else if (!consts.phonePattern.test(searchVal)) {
        //     toast.error("휴대폰 번호를 제대로 입력해주세요.");
        //     return;
        // }
        // console.log("sender", sender);
        searchValApi
            .mutateAsync(sender)
            .then((data) => {
                // console.log("Da!!!!!!", data);
                if (
                    data?.data?.workend_idx !== null &&
                    data?.data?.order_idx !== null
                ) {
                    navigate(
                        `${routes.constPreview5}?index=${data.data.workend_idx}`
                    );
                    return;
                } else if (data?.data?.order_idx !== null) {
                    navigate(
                        `${routes.constPreview4}?index=${data.data.order_idx}`
                    );
                    return;
                } else if (data?.data?.sigonginfo_idx !== null) {
                    navigate(
                        `${routes.constPreview3}?index=${data.data.sigonginfo_idx}`
                    );
                    return;
                } else if (data?.data?.estimate_idx !== null) {
                    navigate(
                        `${routes.constPreview2}?index=${data.data.estimate_idx}`
                    );
                    return;
                } else if (data?.data?.counseling_idx !== null) {
                    navigate(
                        `${routes.constPreview1}?index=${data.data.counseling_idx}`
                    );
                    return;
                }
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };

    return (
        <>
            <div
                className={`header  ${"dark"}`}
                // style={
                //     headerHide?.includes(location.pathname)
                //         ? { display: "none" }
                //         : {}
                // }
            >
                <div className="header_logo_box">
                    <Link to={routes.main} className="header_logo">
                        <p style={{ fontSize: "18px", width: "190px" }}>
                            8AMDOBAE 여덟시도배
                        </p>
                    </Link>
                    {/* <button
                        className={`input_search_button_toggle ${
                            searchOpen ? "searchOpen" : ""
                        } ${stx ? "active" : ""}`}
                        // onClick={() => setsearchOpen(!searchOpen)}
                    /> */}
                </div>
                <div className="header_box">
                    <div className="header_menu">
                        {menu?.map((x, i) => {
                            return (
                                <button
                                    className={`header_menu_one ${
                                        x.valueMulti.includes(location.pathname)
                                            ? "active"
                                            : ""
                                    } ${
                                        mbData.mb_level
                                            ?.map((c) => parseInt(c))
                                            .includes(x.level)
                                            ? ""
                                            : "disabled"
                                    }`}
                                    key={i}
                                    onClick={() =>
                                        mbData.mb_level
                                            ?.map((c) => parseInt(c))
                                            .includes(x.level)
                                            ? linkFunc(x.route)
                                            : {}
                                    }
                                >
                                    {x.title}
                                </button>
                            );
                        })}
                        <div className="header_search" style={{}}>
                            <input
                                type="text"
                                value={searchVal}
                                onChange={(e) => setSearchVal(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        SearchClk();
                                    }
                                }}
                            />
                            <p
                                style={{
                                    color: "#fff",
                                    margin: " 0 40px ",
                                    cursor: "pointer",
                                }}
                                onClick={SearchClk}
                            >
                                검색
                            </p>
                        </div>
                    </div>
                    <div
                        className={`header_search ${
                            searchOpen ? "searchOpen" : ""
                        }`}
                    >
                        <div className="header_buttons animate__animated animate__fadeIn">
                            {/* <Button type="1_small" title="지적편집도" disable={cadastral ? 'cadastral' : ''} image={images.cadastral_map} imgStyle={{ width: 20, height: 20 }} onClick={() => mapOptionFunc(1)}/> */}
                            {/* <Button type="1_small" title="반경" disable={drawing ? 'cadastral' : ''} image={images.circle_line} imgStyle={{ width: 20, height: 20 }} onClick={() => mapOptionFunc(2)}/> */}

                            <p className="profile_name">{mbData.mb_name}님</p>
                            <Button
                                type="1_small"
                                title="로그아웃"
                                image={images.logout}
                                onClick={handleLogout}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <LeftNav
                menu={menu?.find((x) =>
                    x.valueMulti.includes(location.pathname)
                )}
            />
        </>
    );
}
