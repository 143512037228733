import { useNavigate, useSearchParams } from "react-router-dom";
import {
    BoxArrayContent,
    BoxContent,
    BoxContent2,
    BoxResvResult,
    BoxTotalPrice,
} from "../../../components/BoxInfo";
import images from "../../../libs/images";
import {
    HistoryBox,
    HistoryMessageBox,
    HistorySusinBox,
} from "../../../components/HistoryBox";
import { MemoBox } from "../../../components/MemoBox";
import { TableMemo } from "../../../components/Tables";
import routes from "../../../libs/routes";
import { usePostData, useSusinPostData } from "../../../utils/service";
import ApiData from "../../../libs/api";
import { useEffect, useState } from "react";
import moment from "moment";
import consts from "../../../libs/consts";
import { useUser } from "../../../zustand/Login";
import { TabComponent } from "../../../components/TabComponent";
import { PopupCalendar, PopupComp } from "../../../components/PopUp";
import { toast } from "react-toastify";
import useLoadingStore from "../../../zustand/Loading";
const InfoTable = [
    {
        classify: "도배 > 견적서",
        deliveryDate: "2024.09.24 12:00",
        manager: "홍길동",
        sendNum: "상담신청",
        receiveNum: "010-1234-5678",
        content:
            "안녕하세요 상담내용을 토대로 한 견적서를 발송해 드립니다. 토대로 한 견적서 발송해 드립니다.",
    },
    ,
    {
        classify: "도배 > 견적서",
        deliveryDate: "2024.09.24 12:00",
        manager: "홍길동",
        sendNum: "상담신청",
        receiveNum: "010-1234-5678",
        content:
            "안녕하세요 상담내용을 토대로 한 견적서를 발송해 드립니다. 토대로 한 견적서 발송해 드립니다.",
    },
];

function EstimateReqPreview() {
    const navigate = useNavigate();
    const SusinConnect = useSusinPostData(ApiData.apiConnect);
    const msgReceiveList = usePostData(ApiData.msgReceiveList);
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const [registerPopup, setRegisterPopup] = useState(false);
    const EstimateDetail = usePostData(ApiData.EstimateDetail);
    const EstimateInfoMsgList = usePostData(ApiData.EstimateInfoMsgList);
    const memoList = usePostData(ApiData.memoEstimateList);
    const memoDelete = usePostData(ApiData.memoEstimateDelete);
    const memoRegister = usePostData(ApiData.memoEstimateRegister);
    const EstimateGoNext = usePostData(ApiData.EstimateGoNext);
    const EstimateSendMsg = usePostData(ApiData.EstimateSendMsg);

    const historyLook = false;

    const [manager, setManager] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [phone, setPhone] = useState("");
    const [sigong, setSigong] = useState([]);
    const [dobaeOn, setDobaeOn] = useState(0);
    const [jangpanOn, setJangpanOn] = useState(0);
    const [maruOn, setMaruOn] = useState(0);
    const [address, setAddress] = useState("");
    const [adressDetail, setAdressDetail] = useState("");
    const [area, setArea] = useState(0);
    const [areaPyeong, setAreaPyeong] = useState(0);

    const [planUrl, setPlanUrl] = useState("");
    const [planImg, setPlanImg] = useState("");

    const [todayStatus, setTodayStatus] = useState(0);
    const [todayMemo, setTodayMemo] = useState("");
    const [veranda, setVeranda] = useState(0);
    const [verandaMemo, setVerandaMemo] = useState("");

    const [dobaeDate, setDobaeDate] = useState(new Date());
    const [dobaeTime, setDobaeTime] = useState("");
    const [dobaeRange, setDobaeRange] = useState(0);
    const [dobaeRangeMemo, setDobaeRangeMemo] = useState("");
    const [dobaeCeiling, setDobaeCeiling] = useState(0);
    const [dobaeCreateCompanyName, setDobaeCreateCompanyName] = useState("");
    const [dobaeProduct, setDobaeProduct] = useState(0);
    const [dobaeCnt, setDobaeCnt] = useState(0);
    const [dobaePrice, setDobaePrice] = useState(0);

    const [zangpanDate, setZangpanDate] = useState(new Date());
    const [zangpanTime, setZangpanTime] = useState("");

    const [zangpanRange, setZangpanRange] = useState(0);
    const [zangpanRangeMemo, setZangpanRangeMemo] = useState("");
    const [zangpanCreateCompanyName, setZangpanCreateCompanyName] =
        useState("");
    const [zangpanMM, setZangpanMM] = useState("");
    const [zangpanMolding, setZangpanMolding] = useState(0);
    const [zangpanMoldingMemo, setZangpanMoldingMemo] = useState("");
    const [zangpanMoldingRange, setZangpanMoldingRange] = useState(1);
    const [zangpanMoldingRangeMemo, setZangpanMoldingRangeMemo] = useState("");
    const [zangpanThreshold, setZangpanThreshold] = useState(2);
    const [zangpanPrice, setZangpanPrice] = useState(0);

    const [maruDate, setMaruDate] = useState(new Date());
    const [maruTime, setMaruTime] = useState("");

    const [maruRange, setMaruRange] = useState(0);
    const [maruRangeMemo, setMaruRangeMemo] = useState("");
    const [maruCreateCompanyName, setMaruCreateCompanyName] = useState("");
    const [maruFlooring, setMaruFlooring] = useState(1);
    const [maruFlooringMemo, setMaruFlooringMemo] = useState("");

    const [maruOldProduct, setMaruOldProduct] = useState("");
    const [maruDownMolding, setMaruDownMolding] = useState(2);
    const [maruThreshold, setMaruThreshold] = useState(2);
    const [maruRepair, setMaruRepair] = useState(2);
    const [maruPrice, setMaruPrice] = useState(0);

    const [demolitionDate, setDemolitionDate] = useState(new Date());
    const [demolitionTime, setDemolitionTime] = useState("");

    const [demolitionRange, setDemolitionRange] = useState("");
    const [demolitionPrice, setDemolitionPrice] = useState(0);

    const [beforeMemo, setBeforeMemo] = useState("");
    const [beforeDate, setBeforeDate] = useState(new Date());

    const [beforeDateType, setBeforeDateType] = useState(0);

    const [priceAll, setPriceAll] = useState([]);

    const [tap, setTap] = useState({});

    const [sigongWant, setSigongWant] = useState("");
    const [createDt, setCreateDt] = useState(new Date());
    const [consultSummary, setConsultSummary] = useState("");
    const [ContentChange, setContentChange] = useState();
    const [ContentHistory, setContentHistory] = useState([]);
    const [isUpdateData, setIsUpdateData] = useState(false);

    const [memoWrite, setMemoWrite] = useState("");
    const [memoType, setMemoType] = useState(0);
    const [memoListData, setMemoListData] = useState([]);

    const [memoListCommon, setMemoListCommon] = useState([]);
    const [memoListDobae, setMemoListDobae] = useState([]);
    const [memoListZangpan, setMemoListZangpan] = useState([]);
    const [memoListMaru, setMemoListMaru] = useState([]);
    const [memoListSignificant, setMemoListSignificant] = useState([]);

    const [deletePop, setDeletePop] = useState(false);
    const [deleteIdx, setDeleteIdx] = useState(0);

    const [sendMsgList, setSendMsgList] = useState([]);

    const [isDelete, setIsDelete] = useState(true);
    const [page, setPage] = useState(1);
    const [totalSusinCount, setTotalSusinCount] = useState(0);
    const [susinData, setSusinData] = useState([]);
    const [customMsgPage, setCustomMsgPage] = useState(1);
    const [customMsgTotal, setCustomMsgTotal] = useState(0);
    const [customerDirectMemo, setCustomerDirectMemo] = useState("");

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    const handleCustomMsgPageChange = (pageNumber) => {
        setCustomMsgPage(pageNumber);
    };
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const SusinListApi = () => {
        let sender = {
            bizNum: "0515815258",
            messageType: "SMS",
            startDate: "",
            endDate: "",
            offset: page - 1,
            searchVal: phone || "",
            token: localStorage.getItem("tokensAccessMsg"),
        };
        // console.log("sender", sender);
        msgReceiveList
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log("data", data);
                setTotalSusinCount(data.totalCount);
                setSusinData(data.rows);
                // console.log(data);
            })
            .catch((error) => {});
    };

    const SusinConnectApi = () => {
        SusinConnect.mutateAsync({
            apiKey: "wlUq55z9Az",
            secret: "sH2RsJMzcwJyqaaV",
        })
            .then(({ data }) => {
                // console.log("data???", data);
                localStorage.setItem(
                    "tokensAccessMsg",
                    data.rows[0].accessToken
                );
                localStorage.setItem(
                    "tokensRefreshMsg",
                    data.rows[0].refreshToken
                );
                SusinListApi();
            })
            .catch((error) => {
                // RetryConnectApi();
            });
    };

    const EstimateInfoMsgListApi = () => {
        EstimateInfoMsgList.mutateAsync({
            estimateIdx: index,
            customPage: customMsgPage - 1,
        })
            .then(({ data }) => {
                // console.log("data", data);
                setSendMsgList(data.customerMsg.rows);
                setCustomMsgTotal(data.customerMsg.count);
                // console.log("data!@#!@#!@#!@#!@#!#", data);
            })
            .catch((e) => {
                toast.error("정보를 가져오지 못했습니다.");
            });
    };

    const EstimateDetailApi = () => {
        EstimateDetail.mutateAsync({ estimateIdx: index })
            .then(({ data }) => {
                // console.log("data", data);
                // loadingEnd();
                setContentHistory(data.history);
                setTap(data.tap);
                setCreateDt(data.tap.create_dt);
                setManager(data.adminInfo?.mb_name);
                setCustomerName(data.detail?.cutomer_name);
                setPhone(data.detail?.mb_phone);
                let sigongArray = [];
                if (data.detail.dobae === 1) {
                    sigongArray.push("도배");
                }
                if (data.detail.zangpan === 1) {
                    sigongArray.push("장판");
                }
                if (data.detail.maru === 1) {
                    sigongArray.push("마루");
                }
                setSigong(sigongArray);
                setDobaeOn(data.detail?.dobae);
                setJangpanOn(data.detail?.zangpan);
                setMaruOn(data.detail?.maru);
                setAddress(data.detail?.address);
                setAdressDetail(data.detail?.address_detail);
                setArea(data.detail?.area);
                setAreaPyeong(data.detail?.area_pyeong);
                setPlanUrl(data.detail?.plan_url);
                setPlanImg(data.detail?.plan_file);
                setTodayStatus(data.detail?.today_status);
                setTodayMemo(data.detail?.today_memo);
                setVeranda(data.detail?.veranda);
                setVerandaMemo(data.detail?.veranda_memo);
                setDobaeDate(data.detail?.dobae_date);
                setDobaeTime(data.detail?.dobae_time);
                setDobaeRange(data.detail?.dobae_range);
                setDobaeRangeMemo(data.detail?.dobae_range_memo);
                setDobaeCeiling(data.detail?.dobae_ceiling);
                setDobaeCreateCompanyName(data.detail?.dobae_company_name);
                setDobaeProduct(data.detail?.dobae_product);
                setDobaeCnt(data.detail?.dobae_cnt);
                setDobaePrice(data.detail?.dobae_price || 0);
                setZangpanDate(data.detail?.zangpan_date);
                setZangpanTime(data.detail?.zangpan_time);
                setZangpanRange(data.detail?.zangpan_range);
                setZangpanRangeMemo(data.detail?.zangpan_range_memo);
                setZangpanCreateCompanyName(data.detail?.zangpan_company_name);
                setZangpanMM(data.detail?.zangpan_mm);
                setZangpanMolding(data.detail?.zangpan_molding);
                setZangpanMoldingMemo(data.detail?.zangpan_molding_memo);
                setZangpanMoldingRange(data.detail?.zangpan_molding_range);
                setZangpanMoldingRangeMemo(
                    data.detail?.zangpan_molding_range_memo
                );
                setZangpanThreshold(data.detail?.zangpan_threshold);
                setZangpanPrice(data.detail?.zangpan_price || 0);

                setMaruDate(data.detail?.maru_date);
                setMaruTime(data.detail?.maru_time);
                setMaruRange(data.detail?.maru_range);
                setMaruRangeMemo(data.detail?.maru_range_memo);
                setMaruCreateCompanyName(data.detail?.maru_company_name);
                setMaruFlooring(data.detail?.maru_flooring);
                setMaruFlooringMemo(data.detail?.maru_flooring_memo);

                setMaruOldProduct(data.detail?.maru_ori_flooring);
                setMaruDownMolding(data.detail?.maru_down_molding);
                setMaruThreshold(data.detail?.maru_threshold);
                setMaruRepair(data.detail?.maru_cure);
                setMaruPrice(data.detail?.maru_price || 0);

                setDemolitionDate(data.detail?.demolition_date);
                setDemolitionTime(data.detail?.demolition_time);
                setDemolitionRange(data.detail?.demolition_range);
                setDemolitionPrice(data.detail?.demolition_price || 0);

                setBeforeMemo(data.detail?.before_memo);
                setBeforeDate(data.detail?.before_date);
                setBeforeDateType(data.detail?.before_date_type);
                setCustomerDirectMemo(data.detail?.customer_direct_memo);
                setSigongWant(`
            ${
                data?.detail?.dobae === 1
                    ? `도배 : ${moment(
                          data?.detail?.dobae_date
                              ? data?.detail?.dobae_date
                              : data?.detail?.create_dt
                      ).format("YYYY-MM-DD")}(${
                          consts.dayText[
                              moment(
                                  data?.detail?.dobae_date
                                      ? data?.detail?.dobae_date
                                      : data?.detail?.create_dt
                              ).day()
                          ]
                      }), `
                    : ""
            }
            ${
                data?.detail?.zangpan === 1
                    ? `장판 : ${moment(
                          data?.detail?.zangpan_date
                              ? data?.detail?.zangpan_date
                              : data?.detail?.create_dt
                      ).format("YYYY-MM-DD")}(${
                          consts.dayText[
                              moment(
                                  data?.detail?.zangpan_date
                                      ? data?.detail?.zangpan_date
                                      : data?.detail?.create_dt
                              ).day()
                          ]
                      }), `
                    : ""
            }
            ${
                data?.maru === 1
                    ? `마루 : ${moment(
                          data?.detail?.maru_date
                              ? data?.detail?.maru_date
                              : data?.detail?.create_dt
                      ).format("YYYY-MM-DD")}(${
                          consts.dayText[
                              moment(
                                  data?.detail?.maru_date
                                      ? data?.detail?.maru_date
                                      : data?.detail?.create_dt
                              ).day()
                          ]
                      }), `
                    : ""
            }`);
                let summary = "";

                if (data?.detail?.address || data?.detail?.address_detail) {
                    summary += `${
                        data?.detail?.address ? data?.detail?.address : ""
                    }${
                        data?.detail?.address_detail
                            ? data?.detail?.address_detail
                            : ""
                    }/ `;
                }

                summary += `${data?.detail?.area === 1 ? "공급" : "전용"}면적 ${
                    data?.detail?.area_pyeong || 0
                }평/ `;

                if (data?.detail?.veranda == 1) {
                    summary += `베란다 확장 있음${
                        data?.detail?.veranda_memo
                            ? `(${data?.detail?.veranda_memo})`
                            : ""
                    }/ `;
                } else {
                    summary += `베란다 확장 없음${
                        data?.detail?.veranda_memo
                            ? `(${data?.detail?.veranda_memo})`
                            : ""
                    }/ `;
                }

                summary += `${
                    data?.detail?.today_status == 1
                        ? "빈집 가구 없음 "
                        : data?.detail?.today_status == 2
                        ? "거주중 가구 있음"
                        : "이사 당일"
                }`;

                if (data?.detail?.today_memo) {
                    summary += `(${data?.detail?.today_memo})`;
                }

                setConsultSummary(summary);
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
            });
    };
    const saveMemo = () => {
        let sender = {
            estimateIdx: tap?.estimate_idx,
            memo_type: memoType,
            memo_content: memoWrite,
        };

        if (memoType === 0) {
            toast.error("메모 타입을 선택해주세요");
            return;
        } else if (memoWrite === "") {
            toast.error("메모를 입력해주세요");
            return;
        }

        memoRegister
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("저장되었습니다.");
                MemoListApi();
                setMemoWrite("");
                setMemoType(0);
            })
            .catch((e) => {
                // console.log(e);
                toast.error("저장에 실패했습니다.");
            });
    };
    const MemoListApi = () => {
        memoList
            .mutateAsync({ estimateIdx: tap?.estimate_idx })
            .then(({ data }) => {
                setMemoListData(data);
            })
            .catch((e) => {
                toast.error("정보를 가져오지 못했습니다.");
            });
    };

    const EstimateSend = () => {
        EstimateSendMsg.mutateAsync({ estimateIdx: tap?.estimate_idx })
            .then(({ data }) => {
                toast.success("견적서 발송에 성공하였습니다..");
                // EstimateGoNext.mutateAsync({ estimateIdx: index })
                //     .then(({ data }) => {

                //     })
                //     .catch((e) => {
                //         toast.error("견적서 발송에 슬패하였습니다..");
                //     });
            })
            .catch((e) => {
                toast.error("견적서 발송에 실패하였습니다..");
            });
    };
    const DeleteMemo = () => {
        memoDelete
            .mutateAsync({ memoIdx: deleteIdx })
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                MemoListApi();
            })
            .catch((e) => {
                toast.error("삭제에 실패했습니다.");
            });
    };
    const GoBack = () => {
        navigate(routes.constr2);
    };
    const ListGoback = () => {
        setIsDelete(false);
        setDeletePop(true);
    };
    const sigongGoNextPage = () => {
        if (manager === null) {
            toast.error("담당자를 선택해주세요.");
            return;
        }
        // if (customerName === null) {
        //     toast.error("고객명을 입력해주세요.");
        //     return;
        // }
        EstimateGoNext.mutateAsync({ estimateIdx: index })
            .then(({ data }) => {
                navigate(`${routes.constRegst2}?index=${data.sigonginfo_idx}`);
            })
            .catch((e) => {
                toast.error("이동에 실패하였습니다.");
            });
    };
    useEffect(() => {
        let newPriceAll = [...priceAll];

        const updateOrAdd = (title, price) => {
            const index = newPriceAll.findIndex((item) => item.title === title);
            if (index !== -1) {
                newPriceAll[index] = { title, price };
            } else {
                newPriceAll.push({ title, price });
            }
        };

        if (dobaePrice != 0) {
            updateOrAdd("도배", dobaePrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "도배");
        }

        if (zangpanPrice != 0) {
            updateOrAdd("장판", zangpanPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "장판");
        }

        if (maruPrice != 0) {
            updateOrAdd("마루", maruPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "마루");
        }

        if (demolitionPrice != 0 && demolitionPrice != null) {
            updateOrAdd("철거", demolitionPrice);
        } else {
            newPriceAll = newPriceAll.filter((item) => item.title !== "철거");
        }

        setPriceAll(newPriceAll);
    }, [dobaePrice, zangpanPrice, maruPrice, demolitionPrice]);

    useEffect(() => {
        if (index) {
            // loadingStart();
            EstimateDetailApi();
            EstimateInfoMsgListApi();
        }
    }, [index]);
    useEffect(() => {
        setManager(ContentChange?.admin_idx);
        setCreateDt(ContentChange?.create_dt);
        setCustomerName(ContentChange?.cutomer_name);
        setPhone(ContentChange?.mb_phone);
        setAddress(ContentChange?.address);
        setAdressDetail(ContentChange?.address_detail);
        setArea(ContentChange?.area);
        setAreaPyeong(parseInt(ContentChange?.area_pyeong));
        setPlanUrl(ContentChange?.plan_url);
        setPlanImg(ContentChange?.plan_file);
        setTodayStatus(parseInt(ContentChange?.today_status));
        setTodayMemo(ContentChange?.today_memo);
        setVeranda(parseInt(ContentChange?.veranda));
        setVerandaMemo(ContentChange?.veranda_memo);
        setDobaeOn(parseInt(ContentChange?.dobae));
        setJangpanOn(parseInt(ContentChange?.zangpan));
        setMaruOn(parseInt(ContentChange?.maru));
        setDobaeDate(
            ContentChange?.dobae_date == null
                ? new Date()
                : ContentChange?.dobae_date
        );
        setDobaeTime(ContentChange?.dobae_time);
        setDobaeRange(parseInt(ContentChange?.dobae_range));
        setDobaeRangeMemo(ContentChange?.dobae_range_memo);
        setDobaeCeiling(ContentChange?.dobae_ceiling);
        setDobaeCreateCompanyName(
            parseInt(ContentChange?.dobae_create_company_idx)
        );
        setDobaeProduct(parseInt(ContentChange?.dobae_product));
        setDobaeCnt(parseInt(ContentChange?.dobae_cnt));
        setDobaePrice(parseInt(ContentChange?.dobae_price));
        setZangpanDate(
            ContentChange?.zangpan_date == null
                ? new Date()
                : ContentChange?.zangpan_date
        );
        setZangpanTime(ContentChange?.zangpan_time);
        setZangpanRange(parseInt(ContentChange?.zangpan_range));
        setZangpanRangeMemo(ContentChange?.zangpan_range_memo);
        setZangpanCreateCompanyName(
            parseInt(ContentChange?.zangpan_create_company_idx)
        );
        setZangpanMM(ContentChange?.zangpan_mm);
        setZangpanMolding(parseInt(ContentChange?.zangpan_molding));
        setZangpanMoldingMemo(ContentChange?.zangpan_molding_memo);
        setZangpanMoldingRange(parseInt(ContentChange?.zangpan_molding_range));
        setZangpanMoldingRangeMemo(ContentChange?.zangpan_molding_range_memo);
        setZangpanThreshold(parseInt(ContentChange?.zangpan_threshold));
        setZangpanPrice(parseInt(ContentChange?.zangpan_price));
        setMaruDate(
            ContentChange?.maru_date == null
                ? new Date()
                : ContentChange?.maru_date
        );
        setMaruTime(ContentChange?.maru_time);
        setMaruRange(parseInt(ContentChange?.maru_range));
        setMaruRangeMemo(ContentChange?.maru_range_memo);
        setMaruFlooring(parseInt(ContentChange?.maru_flooring));
        setMaruFlooringMemo(ContentChange?.maru_flooring_memo);
        setMaruDownMolding(parseInt(ContentChange?.maru_molding));
        setMaruThreshold(parseInt(ContentChange?.maru_threshold));
        setMaruRepair(parseInt(ContentChange?.maru_cure));
        setMaruPrice(parseInt(ContentChange?.maru_price));
        setMaruOldProduct(ContentChange?.maru_ori_flooring);
        setMaruCreateCompanyName(parseInt(ContentChange?.maru_create));
        setDemolitionDate(
            ContentChange?.demolition_date == null
                ? new Date()
                : ContentChange?.demolition_date
        );
        setDemolitionTime(ContentChange?.demolition_time);
        setDemolitionRange(parseInt(ContentChange?.demolition_range));
        setDemolitionPrice(parseInt(ContentChange?.demolition_price));
        setCustomerDirectMemo(ContentChange?.customer_direct_memo);
        setSigongWant(`
                    ${
                        ContentChange?.dobae === 1
                            ? `도배 : ${moment(
                                  ContentChange?.dobae_date
                                      ? ContentChange?.dobae_date
                                      : ContentChange?.create_dt
                              ).format("YYYY-MM-DD")}(${
                                  consts.dayText[
                                      moment(
                                          ContentChange?.dobae_date
                                              ? ContentChange?.dobae_date
                                              : ContentChange?.create_dt
                                      ).day()
                                  ]
                              }), `
                            : ""
                    }
                    ${
                        ContentChange?.zangpan === 1
                            ? `장판 : ${moment(
                                  ContentChange?.zangpan_date
                                      ? ContentChange?.zangpan_date
                                      : ContentChange?.create_dt
                              ).format("YYYY-MM-DD")}(${
                                  consts.dayText[
                                      moment(
                                          ContentChange?.zangpan_date
                                              ? ContentChange?.zangpan_date
                                              : ContentChange?.create_dt
                                      ).day()
                                  ]
                              }), `
                            : ""
                    }
                    ${
                        ContentChange?.maru === 1
                            ? `마루 : ${moment(
                                  ContentChange?.maru_date
                                      ? ContentChange?.maru_date
                                      : ContentChange?.create_dt
                              ).format("YYYY-MM-DD")}(${
                                  consts.dayText[
                                      moment(
                                          ContentChange?.maru_date
                                              ? ContentChange?.maru_date
                                              : ContentChange?.create_dt
                                      ).day()
                                  ]
                              }), `
                            : ""
                    }`);
        let summary = "";

        if (ContentChange?.address || ContentChange?.address_detail) {
            summary += `${
                ContentChange?.address ? ContentChange?.address : ""
            }${
                ContentChange?.address_detail
                    ? ContentChange?.address_detail
                    : ""
            }/ `;
        }

        summary += `${ContentChange?.area === 1 ? "공급" : "전용"}면적 ${
            ContentChange?.area_pyeong || 0
        }평/ `;

        if (ContentChange?.veranda == 1) {
            summary += `베란다 확장 있음${
                ContentChange?.veranda_memo
                    ? `(${ContentChange?.veranda_memo})`
                    : ""
            }/ `;
        } else {
            summary += `베란다 확장 없음${
                ContentChange?.veranda_memo
                    ? `(${ContentChange?.veranda_memo})`
                    : ""
            }/ `;
        }

        summary += `${
            ContentChange?.today_status == 1
                ? "빈집 가구 없음 "
                : ContentChange?.today_status == 2
                ? "거주중 가구 있음"
                : "이사 당일"
        }`;

        if (ContentChange?.today_memo) {
            summary += `(${ContentChange?.today_memo})`;
        }

        setConsultSummary(summary);

        // setBeforeMemo(ContentChange.before_memo);
        // setBeforeDate(ContentChange?.before_date);
        // setBeforeDateType(ContentChange?.before_date_type);
    }, [ContentChange]);

    useEffect(() => {
        if (tap?.estimate_idx) {
            MemoListApi();
        }
    }, [tap]);
    useEffect(() => {
        const common = [];
        const dobae = [];
        const zangpan = [];
        const maru = [];
        const significant = [];

        memoListData?.forEach((item) => {
            switch (item.memo_type) {
                case 1:
                    common.push(item);
                    break;
                case 2:
                    dobae.push(item);
                    break;
                case 3:
                    zangpan.push(item);
                    break;
                case 4:
                    maru.push(item);
                    break;
                case 5:
                    significant.push(item);
                    break;
                default:
                    break;
            }
        });

        setMemoListCommon(common);
        setMemoListDobae(dobae);
        setMemoListZangpan(zangpan);
        setMemoListMaru(maru);
        setMemoListSignificant(significant);
    }, [memoListData]);

    useEffect(() => {
        if (phone) {
            SusinConnectApi();
        }
    }, [page, phone]);
    useEffect(() => {
        EstimateInfoMsgListApi();
    }, [customMsgPage]);
    return (
        <>
            <PopupCalendar
                OpenPop={registerPopup}
                setOpenPop={setRegisterPopup}
            />
            <PopupComp
                popTitle={`${isDelete ? "삭제" : "안내"}`}
                popContent={`${
                    isDelete
                        ? "정말로 삭제하시겠습니까?"
                        : "페이지 이동하시겠습니까?"
                }`}
                isYes={true}
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={isDelete ? DeleteMemo : GoBack}
            />

            <div className="content ">
                <TabComponent
                    index={tap?.counseling_idx}
                    estimate_idx={tap?.estimate_idx}
                    sigonginfo_idx={tap?.sigonginfo_idx}
                    order_idx={tap?.order_idx}
                    workend_idx={tap?.workend_idx}
                    as_tbl={tap?.as_tbl}
                />

                <div className="animate__animated animate__fadeIn">
                    <HistoryBox
                        Info={ContentHistory}
                        firstDate={createDt}
                        // adminName={mbData?.mb_name}
                        historyLook={historyLook}
                        setContentChange={setContentChange}
                    />
                    <div className="BorderBox">
                        <div className="title">
                            <h1>상담요약</h1>
                        </div>
                        <div className="contentWrap">
                            <BoxContent
                                title="시공희망일"
                                content={sigongWant}
                            />
                            <BoxContent title="내용" content={consultSummary} />
                        </div>
                    </div>
                    <div className="BorderBox">
                        <div className="title">
                            <h1>기본정보</h1>
                        </div>
                        <div className="contentWrap">
                            <BoxContent
                                title="담당자"
                                content={manager || "-"}
                            />
                            <BoxContent
                                title="고객명"
                                content={customerName || "-"}
                            />
                            <BoxContent title="연락처" content={phone || "-"} />
                            <BoxArrayContent
                                title="시공"
                                array={sigong || "-"}
                            />
                            <BoxContent
                                title="주소"
                                content={`${
                                    address !== null && adressDetail !== null
                                        ? address + " " + adressDetail
                                        : address !== null &&
                                          adressDetail === null
                                        ? address
                                        : address === null &&
                                          adressDetail !== null
                                        ? adressDetail
                                        : "-"
                                }`}
                            />
                            <BoxContent
                                title="면적"
                                content={`${areaPyeong ? areaPyeong : 0}평(${
                                    area == 1 ? "공급" : "전용"
                                })`}
                            />
                            {/*BoxShowImg로 바꿔야 함. */}
                            <div className="BoxInfo">
                                <div className="title">도면 URL</div>
                                <div className="sub">
                                    {planUrl ? (
                                        <a
                                            href={`${planUrl}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            도면보기
                                        </a>
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            </div>
                            <div className="BoxInfo">
                                <div className="title">도면파일</div>
                                <div className="sub">
                                    {planImg ? (
                                        <a
                                            href={`${consts.ImgState}${planImg}`}
                                            download="도면_파일"
                                            target="_blank"
                                        >
                                            도면
                                        </a>
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            </div>

                            {/*BoxShowImg로 바꿔야 함. */}
                            <BoxContent
                                title="시공당일 주거환경"
                                content={`${
                                    todayStatus == 1
                                        ? "빈집"
                                        : todayStatus == 2
                                        ? "거주중 가구 있음"
                                        : "이사 당일"
                                } ${todayMemo ? `(${todayMemo})` : ""}`}
                            />
                            <BoxContent
                                title="베란다 확장"
                                content={
                                    veranda == 1
                                        ? `베란다 확장 있음 ${
                                              verandaMemo
                                                  ? `(${verandaMemo})`
                                                  : ""
                                          }`
                                        : veranda === 3
                                        ? `베란다 확장 미정 ${
                                              verandaMemo
                                                  ? `(${verandaMemo})`
                                                  : ""
                                          }`
                                        : `베란다 확장 없음 ${
                                              verandaMemo
                                                  ? `(${verandaMemo})`
                                                  : ""
                                          }`
                                }
                            />
                        </div>
                    </div>
                    {dobaeOn == 1 && (
                        <div className="title_color_box">
                            <div
                                className="box_title"
                                style={{
                                    backgroundColor: "#41b06e",
                                    color: "white",
                                }}
                            >
                                <h1>도배</h1>
                            </div>
                            <div className="contentColorWrap">
                                <div className="contentBorderWrap">
                                    <BoxContent
                                        title="시공일"
                                        content={
                                            dobaeDate
                                                ? `${moment(dobaeDate).format(
                                                      "YYYY-MM-DD"
                                                  )}(${
                                                      consts.dayText[
                                                          moment(
                                                              dobaeDate
                                                          ).day()
                                                      ]
                                                  })`
                                                : "-"
                                        }
                                    />
                                    <BoxContent
                                        title="시공시간"
                                        content={dobaeTime || "-"}
                                    />
                                    <BoxContent
                                        title="시공범위"
                                        content={`${
                                            dobaeRange == 1 ? "전체" : "일부"
                                        } ${
                                            dobaeRangeMemo
                                                ? `(${dobaeRangeMemo})`
                                                : ""
                                        }`}
                                    />
                                    <BoxContent
                                        title="천장"
                                        content={
                                            dobaeCeiling == 1
                                                ? "천장도배포함"
                                                : "천장도배없음"
                                        }
                                    />
                                    <BoxContent
                                        title="제조사"
                                        content={dobaeCreateCompanyName || "-"}
                                    />
                                    <BoxContent
                                        title="벽지 종류"
                                        content={
                                            dobaeProduct == 1
                                                ? "실크"
                                                : dobaeProduct == 2
                                                ? "합지"
                                                : "혼합"
                                        }
                                    />
                                    <BoxContent
                                        title="벽지 수"
                                        content={`${dobaeCnt}개`}
                                    />
                                    <BoxContent
                                        title="견적금액"
                                        content={`${dobaePrice.toLocaleString()}원`}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {jangpanOn == 1 && (
                        <div className="title_color_box">
                            <div
                                className="box_title"
                                style={{
                                    backgroundColor: "#fb8500",
                                    color: "white",
                                }}
                            >
                                <h1>장판</h1>
                            </div>
                            <div className="contentColorWrap">
                                <div className="contentBorderWrap">
                                    <BoxContent
                                        title="시공일"
                                        content={
                                            zangpanDate
                                                ? `${moment(zangpanDate).format(
                                                      "YYYY-MM-DD"
                                                  )}(${
                                                      consts.dayText[
                                                          moment(
                                                              zangpanDate
                                                          ).day()
                                                      ]
                                                  })`
                                                : "-"
                                        }
                                    />
                                    <BoxContent
                                        title="시공시간"
                                        content={zangpanTime || "-"}
                                    />
                                    <BoxContent
                                        title="시공범위"
                                        content={`${
                                            zangpanRange == 1 ? "전체" : "일부"
                                        } ${
                                            zangpanRangeMemo
                                                ? `(${zangpanRangeMemo})`
                                                : ""
                                        }`}
                                    />
                                    <BoxContent
                                        title="제조사"
                                        content={
                                            zangpanCreateCompanyName || "-"
                                        }
                                    />
                                    <BoxContent
                                        title="장판"
                                        content={`${
                                            zangpanMM ? zangpanMM : "-"
                                        }`}
                                    />
                                    <BoxContent2
                                        title="하단몰딩"
                                        content1={`종류 : ${
                                            zangpanMolding == 0
                                                ? "없음"
                                                : zangpanMolding == 1
                                                ? zangpanMoldingMemo
                                                : zangpanMolding == 2
                                                ? "굽도리"
                                                : "걸레받이"
                                        }`}
                                        content2={`범위 : ${
                                            zangpanMoldingRange == 2
                                                ? "전체"
                                                : zangpanMoldingRangeMemo
                                                ? zangpanMoldingRangeMemo
                                                : "-"
                                        }`}
                                    />

                                    <BoxContent
                                        title="문턱"
                                        content={
                                            zangpanThreshold == 1
                                                ? "시공"
                                                : "시공없음"
                                        }
                                    />
                                    <BoxContent
                                        title="견적금액"
                                        content={`${zangpanPrice.toLocaleString()}원`}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {maruOn == 1 && (
                        <div className="title_color_box">
                            <div
                                className="box_title"
                                style={{ backgroundColor: "#caf0f8" }}
                            >
                                <h1>마루</h1>
                            </div>
                            <div className="contentColorWrap">
                                <div className="contentBorderWrap">
                                    <BoxContent
                                        title="시공일"
                                        content={
                                            maruDate
                                                ? `${moment(maruDate).format(
                                                      "YYYY-MM-DD"
                                                  )}(${
                                                      consts.dayText[
                                                          moment(maruDate).day()
                                                      ]
                                                  })`
                                                : "-"
                                        }
                                    />
                                    <BoxContent
                                        title="시공시간"
                                        content={maruTime || "-"}
                                    />
                                    <BoxContent
                                        title="시공범위"
                                        content={`${
                                            maruRange == 1 ? "전체" : "일부"
                                        } ${
                                            maruRangeMemo
                                                ? `(${maruRangeMemo})`
                                                : "-"
                                        }`}
                                    />
                                    <BoxContent
                                        title="제조사"
                                        content={maruCreateCompanyName || "-"}
                                    />
                                    <BoxContent
                                        title="바닥재"
                                        content={`${
                                            maruFlooring == 1
                                                ? "강마루"
                                                : maruFlooring == 2
                                                ? "강화마루"
                                                : maruFlooring == 3
                                                ? "강화마루"
                                                : "-"
                                        } (${maruFlooringMemo || "-"})`}
                                    />
                                    <BoxContent
                                        title="기존 바닥재"
                                        content={maruOldProduct || "-"}
                                    />
                                    <BoxContent
                                        title="하단몰딩"
                                        content={
                                            maruDownMolding == 1
                                                ? "시공"
                                                : "시공없음"
                                        }
                                    />
                                    <BoxContent
                                        title="문턱"
                                        content={
                                            maruThreshold == 1
                                                ? "시공"
                                                : "시공없음"
                                        }
                                    />
                                    <BoxContent
                                        title="보양작업"
                                        content={
                                            maruRepair == 1
                                                ? "시공"
                                                : "시공없음"
                                        }
                                    />
                                    <BoxContent
                                        title="견적금액"
                                        content={`${maruPrice.toLocaleString()}원`}
                                    />
                                </div>
                            </div>
                            <div className="title_color_box">
                                <div
                                    className="box_title"
                                    style={{ backgroundColor: "#ced4da" }}
                                >
                                    <h1>철거</h1>
                                </div>
                                <div className="contentColorWrap">
                                    <div className="contentBorderWrap">
                                        <BoxContent
                                            title="시공일"
                                            content={
                                                demolitionDate
                                                    ? `${moment(
                                                          demolitionDate
                                                      ).format("YYYY-MM-DD")}(${
                                                          consts.dayText[
                                                              moment(
                                                                  demolitionDate
                                                              ).day()
                                                          ]
                                                      })`
                                                    : "-"
                                            }
                                        />
                                        <BoxContent
                                            title="시공시간"
                                            content={demolitionTime || "-"}
                                        />
                                        <BoxContent
                                            title="범위"
                                            content={demolitionRange || "-"}
                                        />
                                        <BoxContent
                                            title="금액"
                                            content={`${demolitionPrice.toLocaleString()}원`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="BorderBox">
                        <div className="title">
                            <h1>부가정보</h1>
                        </div>
                        <div className="contentWrap">
                            <BoxContent
                                title="사전시공"
                                content={beforeMemo || "-"}
                            />
                            <BoxResvResult
                                registerPopup={registerPopup}
                                setRegisterPopup={setRegisterPopup}
                                content={
                                    beforeDate
                                        ? `${moment(beforeDate).format(
                                              "YYYY-MM-DD"
                                          )}(${
                                              consts.dayText[
                                                  moment(beforeDate).day()
                                              ]
                                          }) ${moment(beforeDate).format(
                                              "HH:mm"
                                          )} ${
                                              beforeDateType === 0
                                                  ? "방문없음"
                                                  : beforeDateType === 1
                                                  ? "미방문"
                                                  : "방문완료"
                                          }`
                                        : ""
                                }
                            />
                            <BoxTotalPrice Info={priceAll} />
                        </div>
                    </div>
                    <div className="MemoBoxWrap" style={{ margin: "40px 0px" }}>
                        <div className="title">
                            <img src={images.pencil} />
                            <p>고객메모/요청사항</p>
                        </div>

                        <div className="MemoWrap">
                            <textarea
                                readOnly={true}
                                className="MemoBox"
                                value={customerDirectMemo}
                                // onChange={(e) =>
                                //     setCustomerDirectMemo(e.target.value)
                                // }
                            ></textarea>
                        </div>
                    </div>
                    {tap?.estimate_idx && (
                        <>
                            <MemoBox
                                memoWrite={memoWrite}
                                setMemoWrite={setMemoWrite}
                                memoType={memoType}
                                setMemoType={setMemoType}
                                submitClk={saveMemo}
                            />
                            {memoListCommon.length > 0 && (
                                <TableMemo
                                    memotype={"일반"}
                                    Info={memoListCommon}
                                    deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    setDeleteIdx={setDeleteIdx}
                                    isDelete={isDelete}
                                    setIsDelete={setIsDelete}
                                />
                            )}
                            {memoListDobae.length > 0 && (
                                <TableMemo
                                    memotype={"도배"}
                                    Info={memoListDobae}
                                    deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    setDeleteIdx={setDeleteIdx}
                                    isDelete={isDelete}
                                    setIsDelete={setIsDelete}
                                />
                            )}

                            {memoListZangpan.length > 0 && (
                                <TableMemo
                                    memotype={"장판"}
                                    Info={memoListZangpan}
                                    deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    setDeleteIdx={setDeleteIdx}
                                    isDelete={isDelete}
                                    setIsDelete={setIsDelete}
                                />
                            )}
                            {memoListMaru.length > 0 && (
                                <TableMemo
                                    memotype={"마루"}
                                    Info={memoListMaru}
                                    deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    setDeleteIdx={setDeleteIdx}
                                    isDelete={isDelete}
                                    setIsDelete={setIsDelete}
                                />
                            )}
                            {memoListSignificant.length > 0 && (
                                <TableMemo
                                    memotype={"특이사항"}
                                    Info={memoListSignificant}
                                    deletePop={deletePop}
                                    setDeletePop={setDeletePop}
                                    isDelete={isDelete}
                                    setIsDelete={setIsDelete}
                                />
                            )}
                        </>
                    )}

                    <div className="twoBtn_wrap">
                        <div style={{ display: "flex", gap: "10px" }}>
                            <button
                                style={{
                                    background: "#FDFD96",
                                    border: "1px solid #FDFD96",
                                }}
                                className="btn btn8 btn150px"
                                onClick={() => {
                                    window.open(
                                        `https://order.8amdobae.co.kr/UserNewEstimate?sigongIdx=${tap.idx}&Looking=true`
                                    );
                                }}
                            >
                                견적서 보기
                            </button>
                            <button
                                style={{
                                    background: "#A8E6CF",
                                    border: "1px solid #A8E6CF",
                                }}
                                className="btn btn9 btn150px"
                                onClick={EstimateSend}
                            >
                                견적서 발송
                            </button>
                            <button
                                style={{
                                    background: "#A2D5F2",
                                    border: "1px solid #A2D5F2",
                                }}
                                className="btn btn9 btn150px"
                                onClick={() => {
                                    navigate(
                                        `${routes.constRegst1}?index=${tap?.estimate_idx}`
                                    );
                                }}
                            >
                                변경
                            </button>
                        </div>
                        <button
                            style={{
                                background: "#51baa4",
                                border: "1px solid #51baa4",
                            }}
                            className="btn btn9 btn150px"
                            onClick={sigongGoNextPage}
                        >
                            시공관리로 이동 {">"}
                        </button>
                    </div>
                    <HistoryMessageBox
                        Info={sendMsgList}
                        page={customMsgPage}
                        handlePageChange={handleCustomMsgPageChange}
                        totalCount={customMsgTotal}
                    />
                    {/* <HistoryMessageBox Info={sendMsgList} /> */}
                    <HistorySusinBox
                        page={page}
                        Info={susinData}
                        handlePageChange={handlePageChange}
                        totalCount={totalSusinCount}
                    />
                    <button
                        style={{ margin: "10px 0" }}
                        className="btn btn9"
                        onClick={() => ListGoback()}
                    >
                        목록
                    </button>
                </div>
            </div>
        </>
    );
}
export default EstimateReqPreview;
