import { useEffect, useState } from "react";
import Input from "../../components/Input";
import { InputCheckBox } from "../../components/InputCheckBox";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import { EstimateTable, SendHistory } from "../../components/Tables";
import consts from "../../libs/consts";
import images from "../../libs/images";
import ApiData from "../../libs/api";
import routes from "../../libs/routes";
import Pagination from "react-js-pagination";
import XLSX from "xlsx-js-style";
import { useGetData, usePostData } from "../../utils/service";

import moment from "moment";
import { toast } from "react-toastify";
import { PopupComp } from "../../components/PopUp";
import { useNavigate } from "react-router-dom";
import useLoadingStore from "../../zustand/Loading";

function EstimateReq() {
    const {
        data: ManagerData,
        isLoading,
        isError,
    } = useGetData(ApiData.adminIdList);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const navigate = useNavigate();

    const EstimateList = usePostData(ApiData.EstimateList);
    const EstimateListDelete = usePostData(ApiData.EstimateListDelete);
    const EstimateExcel = usePostData(ApiData.EstimateExcel);
    const [sigongListData, setSigongListData] = useState([]);
    const [sdate, setsdate] = useState("");
    const [edate, setedate] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [searchType, setSearchType] = useState([]);
    const [managerSetting, setManagerSetting] = useState([
        { value: "", text: "전체" },
    ]);
    const [admin, setAdmin] = useState(0);
    const [page, setPage] = useState(1);
    const [deletePop, setDeletePop] = useState(false);
    const [deleteIdx, setDeleteIdx] = useState(0);
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    useEffect(() => {
        ManagerData?.data?.map((item, index) => {
            setManagerSetting((prev) => [
                ...prev,
                { value: item.idx, text: item.mb_name },
            ]);
        });
        return () => {
            setManagerSetting([{ value: "", text: "전체" }]); // 상태를 초기화
        };
        // setManagerSetting();
    }, [ManagerData]);

    useEffect(() => {
        sigongListApi();
    }, [page]);
    const SearchClk = () => {
        // console.log(123123123);
        sigongListApi();
    };
    const handleReset = () => {
        setsdate("");
        setedate("");
        setSearchValue("");
        setSearchType([]);
        setAdmin(0);
        sigongListApi(true);
    };
    const deleteData = () => {
        let sender = {
            estimateIdx: deleteIdx,
        };
        // console.log("sender", sender);
        EstimateListDelete.mutateAsync(sender)
            .then(({ data }) => {
                sigongListApi();
            })
            .catch((e) => {
                toast.error("삭제를 하지 못했습니다.");
            });
    };
    const sigongListApi = (reset = false) => {
        let sender = {
            start_date: sdate,
            end_date: edate,
            searchVal: searchValue,
            zazaeType: searchType.map((item) => parseInt(item)),
            adminIdx: parseInt(admin),
            page: page - 1,
        };
        if (reset == true) {
            sender = {
                start_date: "",
                end_date: "",
                searchVal: "",
                zazaeType: [],
                adminIdx: 0,
                page: parseInt(0),
            };
        }
        // loadingStart();
        EstimateList.mutateAsync(sender)
            .then(({ data }) => {
                // console.log("data", data);
                // loadingEnd();
                setSigongListData(data);
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
            });
    };

    const excelDown = async () => {
        try {
            // 서버에서 직원 리스트 가져오기
            let excel = await EstimateExcel.mutateAsync()
                .then(({ data }) => {
                    return data;
                })
                .catch((e) => {
                    // console.log(e.response.data);
                });
            const excelData = excel;
            // console.log(excelData);

            // Excel 파일 생성 및 다운로드
            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: {
                    bold: true,
                    color: { rgb: "000000" },
                    name: "함초롱바탕",
                    sz: 13,
                },
                fill: { fgColor: { rgb: "f1faee" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };
            const dataStyle = {
                font: { color: { rgb: "000000" }, name: "함초롱바탕", sz: 11 },
                fill: { fgColor: { rgb: "ffffff" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };

            // 열의 폭을 정의
            const colWidths = [110, 80, 110, 110, 200, 80, 130, 80, 80];

            // cols 속성을 사용하여 각 열의 폭을 조절
            const cols = colWidths.map((width) => ({ wpx: width }));

            const headerRow = [
                { v: "등록일시", t: "s", s: headerStyle },
                { v: "담당자", t: "s", s: headerStyle },
                { v: "고객명", t: "s", s: headerStyle },
                { v: "연락처", t: "s", s: headerStyle },
                { v: "주소", t: "s", s: headerStyle },
                { v: "면적", t: "s", s: headerStyle },
                { v: "총액", t: "s", s: headerStyle },
                { v: "시공일", t: "s", s: headerStyle },
                { v: "제품", t: "s", s: headerStyle },
            ];
            const dataRows = excelData.rows.flatMap((emp) => {
                const commonData = [
                    {
                        v: `${moment(emp.create_dt).format(
                            "YYYY.MM.DD HH:mm"
                        )}`,
                        t: "s",
                        s: dataStyle,
                    }, //등록일시
                    {
                        v:
                            (
                                managerSetting.find(
                                    (item) => item.value === emp.admin_idx
                                ) || {}
                            ).text || "",
                        t: "s",
                        s: dataStyle,
                    }, // 담당자
                    {
                        v: `${emp.cutomer_name}`,
                        t: "s",
                        s: dataStyle,
                    }, // 고객명
                    {
                        v: `${emp.mb_phone}`,
                        t: "s",
                        s: dataStyle,
                    }, // 연락처
                    {
                        v: `${
                            emp.address !== null && emp.address_detail !== null
                                ? emp.address + " " + emp.address_detail
                                : emp.address !== null &&
                                  emp.address_detail === null
                                ? emp.address
                                : emp.address === null &&
                                  emp.address_detail !== null
                                ? emp.address_detail
                                : "-"
                        }`,
                        t: "s",
                        s: dataStyle,
                    }, // 주소
                    {
                        v: `${emp.area_pyeong ? emp.area_pyeong : 0}평(${
                            emp.area == 1 ? "공급" : "전용"
                        })`,
                        t: "s",
                        s: dataStyle,
                    }, // 면적
                    {
                        v: `${(
                            emp.maru_price +
                            emp.zangpan_price +
                            emp.dobae_price +
                            emp.demolition_price
                        ).toLocaleString()}원`,
                        t: "s",
                        s: dataStyle,
                    }, //총액
                ];
                const productData = [
                    {
                        product: "도배",
                        date: emp.dobae_date,
                        isProduct: emp.dobae === 1,
                    },
                    {
                        product: "장판",
                        date: emp.zangpan_date,
                        isProduct: emp.zangpan === 1,
                    },
                    {
                        product: "마루",
                        date: emp.maru_date,
                        isProduct: emp.maru === 1,
                    },
                    // {
                    //     product: "철거",
                    //     date: emp.demolition_date,
                    //     isProduct: emp.demolition_price !== 0,
                    // },
                ];

                return productData
                    .filter((pd) => pd.isProduct)
                    .map((pd) => [
                        ...commonData,
                        {
                            v: pd.date
                                ? moment(pd.date).format("YYYY.MM.DD")
                                : "",
                            t: "s",
                            s: dataStyle,
                        }, //시공일
                        {
                            v: pd.product,
                            t: "s",
                            s: dataStyle,
                        }, //제품
                    ]);
            });

            const rows = [headerRow, ...dataRows];

            // 새로운 Sheet 객체 생성
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws["!cols"] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, "상담신청");

            // 파일 다운로드
            XLSX.writeFile(wb, "EstimateReq.xlsx");

            console.log("Excel 파일 생성 및 다운로드 완료");
        } catch (error) {
            console.error("Error occurred while downloading Excel", error);
        }
    };
    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteData}
            />
            <div className="content ">
                <div className="title_container">
                    <p className="title">견적상담</p>
                    <button
                        className="btn btn9"
                        onClick={() => {
                            navigate(`${routes.constRegst1}`);
                        }}
                    >
                        견적 등록
                    </button>
                </div>

                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <InputDate
                                type="text"
                                placeholder="시작날짜"
                                placeholderEnd="종료날짜"
                                name="ipd1"
                                label="등록일"
                                dateValue={sdate}
                                setDateValue={setsdate}
                                dateValueEnd={edate}
                                setDateValueEnd={setedate}
                                multiple={true}
                            />
                        </div>
                        <div className="search_form">
                            <Input
                                className="input_text"
                                type="text"
                                placeholder="연락처,주소, 고객명"
                                name="stx"
                                value={searchValue}
                                setValue={setSearchValue}
                                label="검색"
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        SearchClk();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="search_box_wrap">
                        <InputCheckBox
                            title="자재"
                            Chkboxes={consts.constructionBox4}
                            setValue={setSearchType}
                            values={searchType}
                        />
                        <InputSelect
                            name="status"
                            label="담당자"
                            value={admin}
                            setValue={setAdmin}
                            sectionStyle={{ width: "25%" }}
                            option={managerSetting}
                        />
                    </div>
                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button className="btn btn8" onClick={handleReset}>
                                초기화
                            </button>
                            <button className="btn btn9" onClick={SearchClk}>
                                검색
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                        [오늘 : {sigongListData?.today}건, 검색결과 :{" "}
                        {sigongListData?.count}건]
                    </p>
                    <EstimateTable
                        Info={sigongListData?.rows}
                        setDeletePop={setDeletePop}
                        deletePop={deletePop}
                        setDeleteIdx={setDeleteIdx}
                    />
                    <div className="BottomContent">
                        <div className="ButtonContent"></div>
                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={page}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={sigongListData?.count || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <button
                                className="btn btn9"
                                onClick={() => {
                                    navigate(`${routes.constRegst1}`);
                                }}
                            >
                                등록
                            </button>
                            <div
                                style={{ cursor: "pointer" }}
                                className="excelContent"
                                onClick={() => excelDown()}
                            >
                                <img src={images.excel} alt="excel" />
                                <p>엑셀다운로드</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EstimateReq;
