export function InputCheckBox({ title, Chkboxes, values, setValue }) {
    const handleChange = (e) => {
        if (e.target.checked) {
            setValue([...values, e.target.value]);
        } else {
            setValue(values.filter((value) => value !== e.target.value));
        }
    };

    return (
        <div className="ChkBoxWrap search_form">
            {title && <div className="title">{title}</div>}
            <div className="ChkBox">
                {Chkboxes.map((x, i) => (
                    <div key={i} className="ChkBoxOne">
                        <input
                            checked={values
                                .map((c) => parseInt(c))
                                .includes(x.value)}
                            type="checkbox"
                            id={x.label}
                            name={x.label}
                            value={x.value}
                            onChange={handleChange}
                        />
                        <label htmlFor={x.label}>{x.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}
