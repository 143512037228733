import { useEffect, useState } from "react";
import {
    BoxDropdownInput,
    BoxSendContent,
    BoxTextInput,
} from "../../components/BoxInfo";
import Input from "../../components/Input";
import Pagination from "react-js-pagination";
import { useGetData, usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import consts from "../../libs/consts";
import moment from "moment";
import { PopupComp, PopupContentNImg } from "../../components/PopUp";

import InputFile from "../../components/InputFile";
import { toast } from "react-toastify";
import { useUser } from "../../zustand/Login";
import useLoadingStore from "../../zustand/Loading";

function OutgoingList() {
    const sendList = usePostData(ApiData.sendList);
    const goMsg = usePostData(ApiData.goingMsg);
    const {
        data: templateApi,
        isLoading: templateIsLoading,
        error: templateError,
    } = useGetData(ApiData.mailTempList);
    const {
        data: CallerListApi,
        isLoading: CallerListIsLoading,
        error: CallerListError,
    } = useGetData(ApiData.CallerList);
    const [CallerList, setCallerList] = useState([]);
    const [CallerDropdown, setCallerDropdown] = useState([]);
    const [CallerValue, setCallerValue] = useState(0);
    const [CallerPhone, setCallerPhone] = useState("");
    const [templateData, setTemplateData] = useState([]);
    const [templateDropdown, setTemplateDropdown] = useState([
        { value: 0, text: "없음" },
    ]);
    const [templateValue, setTemplateValue] = useState(0);
    const [page, setPage] = useState(1);
    const [openPop, setOpenPop] = useState(false);
    const [popTitle, setPopTitle] = useState("");
    const [popContent, setPopContent] = useState("");
    const [popimgUrl, setPopImgUrl] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [sendLevel, setSendLevel] = useState("");
    const [recevingPhone, setRecevingPhone] = useState("");
    const [sendListdata, setSendListdata] = useState([]);
    const [textareaContent, setTextareaContent] = useState("");
    const [templateImg, setTemplateImg] = useState("");
    const [imgFileType, setImgFileType] = useState("");
    const { mbData } = useUser();
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    useEffect(() => {
        sendListData();
    }, [page]);

    useEffect(() => {
        setCallerList(CallerListApi?.data);
        setCallerPhone(CallerListApi?.data[0]?.phone);
    }, [CallerListApi]);
    useEffect(() => {
        CallerList?.map((item, index) => {
            setCallerDropdown((prev) => [
                ...prev,
                { value: item.idx, text: item.phone },
            ]);
        });

        return () => {
            setCallerDropdown([]);
        };
    }, [CallerList]);
    useEffect(() => {
        let CallerInfo = CallerList?.filter((item) => item.idx === CallerValue);
        if (CallerInfo?.length > 0) {
            setCallerPhone(CallerInfo[0]?.phone);
        }
    }, [CallerValue]);

    useEffect(() => {
        // console.log("templateApi", templateApi);
        setTemplateData(templateApi?.data);
        setTemplateValue(0);
    }, [templateApi]);

    useEffect(() => {
        // console.log("templateData", templateData);
        templateData?.map((item, index) => {
            setTemplateDropdown((prev) => [
                ...prev,
                { value: item.idx, text: item.title },
            ]);
        });
        return () => {
            setTemplateDropdown([{ value: 0, text: "없음" }]);
        };
    }, [templateData]);

    useEffect(() => {
        // console.log("templateValue", templateValue);
        let tempSetting = templateData?.filter(
            (item) => item.idx === parseInt(templateValue)
        );
        if (parseInt(templateValue) === 0) {
            setTextareaContent("");
            setTemplateImg("");
        }
        if (tempSetting?.length > 0) {
            setTextareaContent(tempSetting[0]?.content);
            setTemplateImg(tempSetting[0]?.img_url);
        }
    }, [templateValue]);

    const sendListData = () => {
        let sender = {
            page: page - 1,
            status: sendLevel,
            searchVal: searchVal,
        };
        // loadingStart();
        sendList
            .mutateAsync(sender)
            .then(({ data }) => {
                // loadingEnd();
                // console.log(data);
                setSendListdata(data);
            })
            .catch((e) => {
                // loadingEnd();
                // console.log(e);
            });
    };
    const SendClk = () => {
        let sender = {
            admIdx: mbData.idx,
            senderPhone: CallerPhone,
            getPhone: recevingPhone,
            msgContent: textareaContent,
            temImg: templateImg,
        };
        if (recevingPhone === "") {
            toast.error("받는 번호를 입력해주세요.");
            return;
        } else if (recevingPhone.includes("-")) {
            sender.getPhone = recevingPhone.replace(/-/g, "");
        } else if (recevingPhone.includes(" ")) {
            sender.getPhone = recevingPhone.replace(/ /g, "");
        } else if (!consts.phonePattern.test(recevingPhone)) {
            toast.error("휴대폰 번호를 제대로 입력해주세요.");
            return;
        }
        if (templateImg) {
            if (templateImg.includes("template")) {
                sender.imgType = "";
            } else {
                sender.imgType = 1;
                sender.fileType = imgFileType.split("/")[1];
            }
        } else {
            // console.log(333);
        }
        // console.log("sender", sender);
        goMsg
            .mutateAsync(sender)
            .then(({ data }) => {
                sendListData();
                toast.success("발송되었습니다.");
            })
            .catch((e) => {
                // console.log(e);
            });
    };

    const ContentInfoClk = (content) => {
        setPopTitle("발송내용");
        setPopContent(content);
        setPopImgUrl("");
        setOpenPop(!openPop);
    };
    const ContentImgClk = (img) => {
        setPopTitle("이미지");
        setPopContent("");
        setPopImgUrl(img);
        setOpenPop(!openPop);
    };
    const OnChangeRadio = (e) => {
        setSendLevel(e.target.value === "" ? "" : parseInt(e.target.value));
    };
    return (
        <>
            <PopupContentNImg
                popTitle={popTitle}
                popContent={popContent}
                popImg={popimgUrl}
                OpenPop={openPop}
                setOpenPop={setOpenPop}
            />
            <div className="content ">
                <p className="title">발신목록</p>
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className="BorderBox"
                >
                    <div className="leftContent">
                        <div className="title">
                            <h1>문자 발송</h1>
                        </div>
                        <div className="contentWrap">
                            <BoxDropdownInput
                                title="발송 번호"
                                width={50}
                                Dropdowns={CallerDropdown}
                                value={CallerValue}
                                setValue={setCallerValue}
                                defaultValue={"없음"}
                            />
                            <BoxTextInput
                                title="받는 번호"
                                inputWidth={60}
                                value={recevingPhone}
                                setValue={setRecevingPhone}
                                placeholder="번호 입력"
                            />
                            <BoxDropdownInput
                                title="템플릿"
                                width={60}
                                value={templateValue}
                                setValue={setTemplateValue}
                                Dropdowns={templateDropdown}
                                defaultValue="없음"
                            />
                        </div>
                        <div className="BtnEnd">
                            <button className="btn btn9" onClick={SendClk}>
                                발송하기
                            </button>
                        </div>
                    </div>
                    <div className="rightContent">
                        <div className="contentWrap">
                            <BoxSendContent
                                value={textareaContent}
                                setValue={setTextareaContent}
                            />
                            <div className="ImgInputWrap">
                                <div className="Imgtitle">이미지</div>
                                <div className="sub">
                                    <InputFile
                                        type="file"
                                        name={"img_url"}
                                        value={
                                            templateImg &&
                                            (templateImg.includes("template")
                                                ? `${consts.ServerImgFolder}${templateImg}`
                                                : `${templateImg}`)
                                        }
                                        alt={"Template"}
                                        onChange={(idx, name, result) => {
                                            setTemplateImg(result);
                                        }}
                                        valid="image"
                                        // style={{ flex: 1.68 }}
                                        imgStyle={{
                                            width: "40%",
                                        }}
                                        setFileType={(type) => {
                                            setImgFileType(type);
                                        }}
                                    />
                                    <button
                                        type="button"
                                        style={{
                                            fontSize: "12px",
                                            position: "absolute",
                                            bottom: "10px",
                                            right: "10px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setTemplateImg("")}
                                    >
                                        삭제하기
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="OutcomingSearch">
                    <div className="RadioWrap">
                        {consts.sendLevel?.map((item, index) => {
                            return (
                                <div key={index} className="RadioBoxOne">
                                    <input
                                        checked={sendLevel === item.value}
                                        className="radio"
                                        type="radio"
                                        id={`outComing${index}`}
                                        name="radioGroup"
                                        value={item.value}
                                        onChange={OnChangeRadio}
                                    />
                                    <label htmlFor={`outComing${index}`}>
                                        {item.label}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    <div className="SearchBoxWrap">
                        <Input
                            className="input_text"
                            type="text"
                            placeholder="발신자, 발신번호, 받는번호"
                            value={searchVal}
                            setValue={setSearchVal}
                            name="stx"

                            // label="검색"
                        />
                        <button className="btn btn9" onClick={sendListData}>
                            검색
                        </button>
                    </div>
                </div>
                <div className="table_scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>발신자</th>
                                <th>발신번호</th>
                                <th>받는번호</th>
                                <th>발송시간</th>
                                <th>발송내용</th>
                                <th>이미지</th>
                                <th>상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sendListdata?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.adm_name}</td>
                                        <td>{item.sender_phone}</td>
                                        <td>{item.get_phone}</td>
                                        <td>
                                            {moment(item.create_dt).format(
                                                "YYYY-MM-DD HH:mm"
                                            )}
                                        </td>
                                        <td
                                        // style={{
                                        //     cursor: "pointer",
                                        //     color: "#007bff",
                                        // }}
                                        // onClick={() => {
                                        //     ContentInfoClk(
                                        //         item.msg_content
                                        //     );
                                        // }}
                                        >
                                            {item.msg_content}
                                        </td>
                                        <td
                                            style={{
                                                cursor: "pointer",
                                                color: "#007bff",
                                            }}
                                            onClick={() => {
                                                if (item.msg_img !== null) {
                                                    ContentImgClk(item.msg_img);
                                                }
                                            }}
                                        >
                                            {item.msg_img == null
                                                ? ""
                                                : "이미지"}
                                        </td>
                                        <td>
                                            {item.status == 1 ? (
                                                <p>발송성공</p>
                                            ) : (
                                                <p style={{ color: "red" }}>
                                                    발송실패
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    className="my-pagination"
                    // 현제 보고있는 페이지
                    activePage={page}
                    // 한페이지에 출력할 아이템수
                    itemsCountPerPage={20}
                    // 총 아이템수
                    totalItemsCount={sendListdata?.length || 0}
                    // 표시할 페이지수
                    pageRangeDisplayed={10}
                    // 함수
                    onChange={handlePageChange}
                    nextPageText=""
                    prevPageText=""
                    firstPageText=""
                    lastPageText=""
                ></Pagination>
            </div>
        </>
    );
}

export default OutgoingList;
