import React, { useEffect, useState, useRef } from "react";

import moment from "moment";
import useLoadingStore from "../zustand/Loading";

export default function Loading({}) {
    const { open } = useLoadingStore();

    return (
        <>
            <div className={open ? "loading show" : "loading"}>
                <div className="loading_spin"></div>
            </div>
        </>
    );
}
