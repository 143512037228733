import { useEffect, useState } from "react";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import { TableOrderManagement } from "../../components/Tables";
import Pagination from "react-js-pagination";
import images from "../../libs/images";
import XLSX from "xlsx-js-style";
import { usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import { useNavigate } from "react-router-dom";
import routes from "../../libs/routes";
import { PopupComp } from "../../components/PopUp";
import { toast } from "react-toastify";
import moment from "moment";
import useLoadingStore from "../../zustand/Loading";
function OrderManagement() {
    const navigate = useNavigate();
    const orderListApi = usePostData(ApiData.orderList);
    const orderDeleteApi = usePostData(ApiData.orderDelete);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const [sdate, setsdate] = useState("");
    const [edate, setedate] = useState("");
    const [agency, setaAgency] = useState("");
    const [agencyList, setAgencyList] = useState([{ value: 0, text: "전체" }]);
    const [recipient, setRecipient] = useState("");
    const [recipientList, setRecipientList] = useState([
        { value: "", text: "전체" },
    ]);
    const [orderList, setOrderList] = useState([]);
    const [AllList, setAllList] = useState([]);
    const [deletePop, setDeletePop] = useState(false);
    const [deleteIdx, setDeleteIdx] = useState(0);

    const [page, setPage] = useState(1);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const resetBtn = () => {
        setsdate("");
        setedate("");
        setaAgency("");
        setRecipient("");
        setPage(1);
        getOrderListApi(true);
    };
    const getOrderListApi = (reset = false) => {
        let sender = {
            start_date: sdate,
            end_date: edate,
            agencyIdx: agency === 0 ? "" : agency,
            recipientName: recipient,
            page: page - 1,
        };
        if (reset === true) {
            sender = {
                start_date: "",
                end_date: "",
                agencyIdx: "",
                recipientName: "",
                page: 0,
            };
        }
        // loadingStart();
        orderListApi
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log("data", data);
                // loadingEnd();
                setAllList(data);
                setOrderList(data.rows);
            })
            .catch((error) => {
                // loadingEnd();
                toast.error(error.response.data);
                // console.log(error);
            });
    };
    const deleteData = () => {
        // console.log("!!!!!!");
        let sender = {
            deleteIdx: deleteIdx,
        };

        orderDeleteApi
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("삭제 성공했습니다.");
                getOrderListApi();
            })
            .catch((e) => {
                // console.log(e.response.data);
                toast.error("삭제를 하지 못했습니다.");
            });
    };
    useEffect(() => {
        getOrderListApi();
    }, [page]);

    useEffect(() => {
        AllList?.agencyList?.map((item) => {
            setAgencyList((prev) => [
                ...prev,
                { value: item.agency_idx, text: item.agencyName },
            ]);
        });
        AllList?.recipientNameList?.map((item) => {
            setRecipientList((prev) => [
                ...prev,
                { value: item.recipient_name, text: item.recipient_name },
            ]);
        });

        return () => {
            setAgencyList([{ value: 0, text: "전체" }]);
            setRecipientList([{ value: "", text: "전체" }]);
        };
    }, [AllList]);

    const excelDown = async () => {
        try {
            let sender = {
                start_date: "",
                end_date: "",
                agencyIdx: "",
                recipientName: "",
                excelDown: 1,
            };
            // 서버에서 주문관리 리스트 가져오기
            let excel = await orderListApi
                .mutateAsync(sender)
                .then(({ data }) => {
                    return data;
                })
                .catch((e) => {
                    // console.log(e.response.data);
                });
            const excelData = excel;
            // console.log(excelData);

            // Excel 파일 생성 및 다운로드
            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: {
                    bold: true,
                    color: { rgb: "000000" },
                    name: "함초롱바탕",
                    sz: 13,
                },
                fill: { fgColor: { rgb: "f1faee" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };
            const dataStyle = {
                font: { color: { rgb: "000000" }, name: "함초롱바탕", sz: 11 },
                fill: { fgColor: { rgb: "ffffff" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };

            // 열의 폭을 정의
            const colWidths = [110, 110, 200, 80, 80, 130, 80, 80, 80, 80];

            // cols 속성을 사용하여 각 열의 폭을 조절
            const cols = colWidths.map((width) => ({ wpx: width }));

            const headerRow = [
                { v: "배송일", t: "s", s: headerStyle },
                { v: "대리점", t: "s", s: headerStyle },
                { v: "배송지", t: "s", s: headerStyle },
                { v: "수령자", t: "s", s: headerStyle },
                { v: "분류", t: "s", s: headerStyle },
                { v: "금액", t: "s", s: headerStyle },
                { v: "시공", t: "s", s: headerStyle },
                { v: "퀵배송", t: "s", s: headerStyle },
                { v: "A/S", t: "s", s: headerStyle },
                { v: "내사", t: "s", s: headerStyle },
            ];
            const dataRows = excelData.list.map((emp) => {
                const commonData = [
                    {
                        v: `${moment(emp.delivery_date).format(
                            "YYYY.MM.DD HH:mm"
                        )}`,
                        t: "s",
                        s: dataStyle,
                    }, //배송일
                    {
                        v: `${
                            agencyList.find(
                                (c) => c.value === emp.delivery_date
                            )?.text || "-"
                        }`,
                        t: "s",
                        s: dataStyle,
                    }, // 대리점
                    {
                        v: `${
                            emp.delivery_address !== null &&
                            emp.delivery_address_detail !== null
                                ? emp.delivery_address +
                                  " " +
                                  emp.delivery_address_detail
                                : emp.delivery_address !== null &&
                                  emp.delivery_address_detail === null
                                ? emp.delivery_address
                                : emp.delivery_address === null &&
                                  emp.delivery_address_detail !== null
                                ? emp.delivery_address_detail
                                : "-"
                        }`,
                        t: "s",
                        s: dataStyle,
                    }, // 배송지
                    {
                        v: `${emp.recipient_name || "-"}`,
                        t: "s",
                        s: dataStyle,
                    }, // 수령자
                    {
                        v: `${
                            emp.dobae === 0 &&
                            emp.zangpan === 0 &&
                            emp.maru === 0
                                ? "-"
                                : (emp.dobae === 1 ? "도배" : "") +
                                  (emp.zangpan === 1 ? "장판" : "") +
                                  (emp.maru === 1 ? "마루" : "")
                        }`,
                        t: "s",
                        s: dataStyle,
                    }, //분류
                    {
                        v: `${
                            emp.delivery_price
                                ? emp.delivery_price.toLocaleString()
                                : 0
                        }원`,
                        t: "s",
                        s: dataStyle,
                    }, // 내사
                    {
                        v: `${emp.use_type === 1 ? "O" : "X"}`,
                        t: "s",
                        s: dataStyle,
                    }, // 시공
                    {
                        v: `${emp.use_type === 2 ? "O" : "X"}`,
                        t: "s",
                        s: dataStyle,
                    }, // 퀵배송
                    {
                        v: `${emp.use_type === 3 ? "O" : "X"}`,
                        t: "s",
                        s: dataStyle,
                    }, // a/s
                    {
                        v: `${emp.use_type === 4 ? "O" : "X"}`,
                        t: "s",
                        s: dataStyle,
                    }, // 내사
                ];
                return commonData;
            });

            const rows = [headerRow, ...dataRows];

            // 새로운 Sheet 객체 생성
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws["!cols"] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, "상담신청");

            // 파일 다운로드
            XLSX.writeFile(wb, "OrderManagement.xlsx");

            console.log("Excel 파일 생성 및 다운로드 완료");
        } catch (error) {
            console.error("Excel 파일 생성 및 다운로드 실패", error);
        }
    };
    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteData}
            />
            <div className="content ">
                <div className="title_container">
                    <p className="title">주문관리</p>
                    <button
                        className="btn btn9"
                        onClick={() => {
                            navigate(`${routes.constRegst3}`);
                        }}
                    >
                        등록
                    </button>
                </div>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <InputDate
                                type="text"
                                placeholder="시작날짜"
                                placeholderEnd="종료날짜"
                                name="ipd1"
                                label="등록일"
                                dateValue={sdate}
                                setDateValue={setsdate}
                                dateValueEnd={edate}
                                setDateValueEnd={setedate}
                                multiple={true}
                            />
                        </div>
                        <div className="search_form">
                            <InputSelect
                                name="status"
                                label="대리점"
                                value={agency}
                                setValue={setaAgency}
                                option={agencyList}
                            />
                        </div>
                    </div>
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <InputSelect
                                name="status"
                                label="수령자"
                                value={recipient}
                                setValue={setRecipient}
                                option={recipientList}
                            />
                        </div>
                    </div>
                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button
                                className="btn btn8"
                                onClick={() => {
                                    resetBtn();
                                }}
                            >
                                초기화
                            </button>
                            <button
                                className="btn btn9"
                                onClick={getOrderListApi}
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                        [오늘 : {AllList?.today}건, 검색결과 : {AllList?.count}
                        건]
                    </p>
                    <TableOrderManagement
                        list={orderList}
                        agencyList={agencyList}
                        setDeletePop={setDeletePop}
                        deletePop={deletePop}
                        setDeleteIdx={setDeleteIdx}
                    />
                    <div className="BottomContent">
                        <div className="ButtonContent"></div>
                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={page}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={AllList?.count || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <button
                                className="btn btn9"
                                onClick={() => {
                                    navigate(`${routes.constRegst3}`);
                                }}
                            >
                                등록
                            </button>
                            <div
                                className="excelContent"
                                style={{ cursor: "pointer" }}
                                onClick={excelDown}
                            >
                                <img src={images.excel} alt="excel" />
                                <p>엑셀다운로드</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderManagement;
