import { useEffect, useState } from "react";
import InputDate from "../../components/InputDate";
import { useNavigate } from "react-router-dom";
import { usePostData } from "../../utils/service";
import routes from "../../libs/routes";
import images from "../../libs/images";
import Pagination from "react-js-pagination";
import ApiData from "../../libs/api";
import { toast } from "react-toastify";
import moment from "moment";
import XLSX from "xlsx-js-style";
import useLoadingStore from "../../zustand/Loading";

function AgencyMaterialManagement() {
    const date = new Date();
    const [sdate, setsdate] = useState(
        moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
            "YYYY-MM-DD"
        )
    );
    const [edate, setedate] = useState(
        moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format(
            "YYYY-MM-DD"
        )
    );

    const agencyMaterialList = usePostData(ApiData.agencyMaterialList);
    const [list, setList] = useState([]);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    // const { loadingStart, loadingEnd } = useLoadingStore();
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    const resetBtn = () => {
        setsdate(
            moment(new Date(date.getFullYear(), date.getMonth() - 1, 1)).format(
                "YYYY-MM-DD"
            )
        );
        setedate(
            moment(new Date(date.getFullYear(), date.getMonth(), 0)).format(
                "YYYY-MM-DD"
            )
        );
        setPage(1);
        agencyMaterialListApi(true);
    };
    const excelDown = async () => {
        try {
            // 서버에서 직원 리스트 가져오기

            let sender = {
                start_date: sdate,
                end_date: edate,
                excelDown: 1,
            };

            let excel = await agencyMaterialList
                .mutateAsync(sender)
                .then(({ data }) => {
                    return data;
                })
                .catch((e) => {
                    // console.log(e.response.data);
                });
            const excelData = excel;
            // console.log(excelData);

            // Excel 파일 생성 및 다운로드
            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: {
                    bold: true,
                    color: { rgb: "000000" },
                    name: "함초롱바탕",
                    sz: 13,
                },
                fill: { fgColor: { rgb: "f1faee" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };
            const dataStyle = {
                font: { color: { rgb: "000000" }, name: "함초롱바탕", sz: 11 },
                fill: { fgColor: { rgb: "ffffff" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };

            // 열의 폭을 정의
            const colWidths = [200, 120, 120, 120, 120, 120];

            // cols 속성을 사용하여 각 열의 폭을 조절
            const cols = colWidths.map((width) => ({ wpx: width }));

            const headerRow = [
                { v: "대리점", t: "s", s: headerStyle },
                { v: "자재매입", t: "s", s: headerStyle },
                { v: "부자재매입", t: "s", s: headerStyle },
                { v: "매입합계", t: "s", s: headerStyle },
                { v: "대리점부담(배송비)", t: "s", s: headerStyle },
                { v: "자재반품", t: "s", s: headerStyle },
            ];

            const dataRows = excelData?.map((emp) => [
                {
                    v: emp.agency_name || "-",
                    t: "s",
                    s: dataStyle,
                }, // 대리점
                {
                    v: `${(emp.dobae_price || 0).toLocaleString()}원`,
                    t: "s",
                    s: dataStyle,
                }, // 자재매입
                {
                    v: `${(emp.sub_price || 0).toLocaleString()}원`,
                    t: "s",
                    s: dataStyle,
                }, // 부자재매입
                {
                    v: `${(
                        (emp.dobae_price || 0) + (emp.sub_price || 0)
                    ).toLocaleString()}원`,
                    t: "s",
                    s: dataStyle,
                }, // 부자재매입

                {
                    v: `${(emp.delivery_price || 0).toLocaleString()}원`,
                    t: "s",
                    s: dataStyle,
                }, // 대리점부담
                {
                    v: `${(emp.return_price || 0).toLocaleString()}원`,
                    t: "s",
                    s: dataStyle,
                }, // 자재반품
            ]);

            const rows = [headerRow, ...dataRows];

            // 새로운 Sheet 객체 생성
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws["!cols"] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, "상담신청");

            // 파일 다운로드
            XLSX.writeFile(wb, "AgencyMaterialManagement.xlsx");

            console.log("Excel 파일 생성 및 다운로드 완료");
        } catch (error) {
            console.error("Error occurred while downloading Excel", error);
        }
    };
    const agencyMaterialListApi = (reset = false) => {
        let sender = {
            start_date: sdate,
            end_date: edate,
            page: page - 1,
        };
        if (reset) {
            sender = {
                start_date: moment(
                    new Date(date.getFullYear(), date.getMonth(), 1)
                ).format("YYYY-MM-DD"),
                end_date: moment(
                    new Date(date.getFullYear(), date.getMonth() + 1, 0)
                ).format("YYYY-MM-DD"),
                page: 0,
            };
        }
        // console.log("sender", sender);
        // loadingStart();
        agencyMaterialList
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log("data", data);
                // loadingEnd();
                setList(data);
            })
            .catch((error) => {
                // loadingEnd();
                toast.error(error.response.data);
            });
    };

    useEffect(() => {
        agencyMaterialListApi();
        // console.log(list?.count);
    }, [page]);
    return (
        <>
            <div className="content ">
                <p className="title">대리점자재관리</p>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <InputDate
                                type="text"
                                placeholder="시작날짜"
                                placeholderEnd="종료날짜"
                                name="ipd1"
                                label="등록일"
                                dateValue={sdate}
                                setDateValue={setsdate}
                                dateValueEnd={edate}
                                setDateValueEnd={setedate}
                                multiple={true}
                            />
                        </div>
                    </div>

                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button className="btn btn8" onClick={resetBtn}>
                                초기화
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() => {
                                    agencyMaterialListApi();
                                }}
                            >
                                검색
                            </button>
                        </div>
                    </div>
                </div>
                <div className="table_scroll">
                    <table>
                        <thead>
                            <tr>
                                <th rowSpan={2}>대리점</th>
                                <th colSpan={3}>매입</th>
                                <th>배송비</th>
                                <th rowSpan={2}>반품</th>
                                <th rowSpan={2}>관리</th>
                            </tr>
                            <tr>
                                <th
                                    style={{
                                        borderTop: "none",
                                        borderLeft: "1px solid #ddd",
                                    }}
                                >
                                    자재
                                </th>
                                <th style={{ borderTop: "none" }}>부자재</th>
                                <th style={{ borderTop: "none" }}>합계</th>

                                <th
                                    style={{
                                        borderTop: "none",
                                    }}
                                >
                                    자재
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {list?.rows?.map((c, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{c.agency_name || "-"}</td>
                                        <td>
                                            {(
                                                c.dobae_price || 0
                                            ).toLocaleString()}
                                            원
                                        </td>
                                        <td>
                                            {(
                                                c.sub_price || 0
                                            ).toLocaleString()}
                                            원
                                        </td>
                                        <td>
                                            {(
                                                (c.dobae_price || 0) +
                                                (c.sub_price || 0)
                                            ).toLocaleString()}
                                            원
                                        </td>
                                        <td>
                                            {(
                                                c.delivery_price || 0
                                            ).toLocaleString()}
                                            원
                                        </td>
                                        <td>
                                            {(
                                                c.return_price || 0
                                            ).toLocaleString()}
                                            원
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    navigate(
                                                        `${routes.material4_1}?index=${c.agency_idx}`,
                                                        {
                                                            state: {
                                                                agency_name:
                                                                    c.agency_name,
                                                            },
                                                        }
                                                    );
                                                }}
                                                style={{
                                                    padding: "5px 10px",
                                                    backgroundColor: "#f2f2f2",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "3px",
                                                    color: "#333",
                                                    fontSize: "12px",
                                                    cursor: "pointer",
                                                    width: "70px",
                                                }}
                                            >
                                                보기
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                            {list?.count !== 0 && list?.count !== undefined && (
                                <tr>
                                    <td>합계</td>
                                    <td>
                                        {list?.rows
                                            ?.reduce((acc, cur) => {
                                                return acc + cur.dobae_price;
                                            }, 0)
                                            .toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {list?.rows
                                            ?.reduce((acc, cur) => {
                                                return acc + cur.sub_price;
                                            }, 0)
                                            .toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {list?.rows
                                            ?.reduce((acc, cur) => {
                                                return (
                                                    acc +
                                                    cur.dobae_price +
                                                    cur.sub_price
                                                );
                                            }, 0)
                                            .toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {list?.rows
                                            ?.reduce((acc, cur) => {
                                                return acc + cur.delivery_price;
                                            }, 0)
                                            .toLocaleString()}
                                        원
                                    </td>
                                    <td>
                                        {list?.rows
                                            ?.reduce((acc, cur) => {
                                                return acc + cur.return_price;
                                            }, 0)
                                            .toLocaleString()}
                                        원
                                    </td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="BottomContent">
                    <div className="ButtonContent"></div>
                    <div className="PaginationContent">
                        <Pagination
                            className="my-pagination"
                            // 현제 보고있는 페이지
                            activePage={page}
                            // 한페이지에 출력할 아이템수
                            itemsCountPerPage={20}
                            // 총 아이템수
                            totalItemsCount={list?.count || 0}
                            // 표시할 페이지수
                            pageRangeDisplayed={10}
                            // 함수
                            onChange={handlePageChange}
                            nextPageText=""
                            prevPageText=""
                            firstPageText=""
                            lastPageText=""
                        ></Pagination>
                    </div>
                    <div
                        className="excelContent"
                        style={{ cursor: "pointer" }}
                        onClick={excelDown}
                    >
                        <img src={images.excel} alt="excel" />
                        <p>엑셀다운로드</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AgencyMaterialManagement;
