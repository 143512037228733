import { useEffect, useState } from "react";
import Input from "./Input";
import { read } from "xlsx-js-style";
import { set } from "lodash";
import ApiData from "../libs/api";
import { useGetData, usePostData } from "../utils/service";
import { toast } from "react-toastify";
import { PopupComp } from "./PopUp";
const Dropdowns = [
    {
        value: 0,
        text: "test1",
    },
    {
        value: 1,
        text: "test1",
    },
    {
        value: 2,
        text: "test2",
    },
];
const test = [
    {
        id: 1,
        choose: "1",
        state: 0,
        construction: "홍길동3",
        contact: "010-1234-5678",
        hopeDate: "2021-10-01",
        area: "100",
        material: "타일",
        regDate: "2021-09-01",
        manage: "",
        test: ["hello", "world"],
        having: true,
        product: [
            {
                title: "도배",
                having: true,
            },
            {
                title: "장판",
                having: true,
            },
            {
                title: "마루",
                having: false,
            },
            {
                title: "철거",
                having: true,
            },
        ],
    },
    {
        id: 2,
        choose: "1",
        state: 1,
        construction: "홍길동4",
        contact: "010-1234-5678",
        hopeDate: "2021-10-01",
        area: "100",
        material: "타일",
        regDate: "2021-09-01",
        manage: "",
        test: ["hello", "world"],
        product: [
            {
                title: "도배",
                having: true,
            },
            {
                title: "장판",
                having: true,
            },
            {
                title: "마루",
                having: true,
            },
            {
                title: "철거",
                having: true,
            },
        ],
    },
    {
        id: 3,
        choose: "1",
        state: 2,
        construction: "홍길동2",
        contact: "010-1234-5678",
        hopeDate: "2021-10-01",
        area: "100",
        material: "타일",
        regDate: "2021-09-01",
        manage: "",
        test: ["hello", "world"],
        product: [
            {
                title: "도배",
                having: false,
            },
            {
                title: "장판",
                having: true,
            },
            {
                title: "마루",
                having: false,
            },
            {
                title: "철거",
                having: true,
            },
        ],
    },
    {
        id: 4,
        choose: "1",
        state: 0,
        construction: "홍길동1",
        contact: "010-1234-5678",
        hopeDate: "2021-10-01",
        area: "100",
        material: "타일",
        regDate: "2021-09-01",
        manage: "",
        test: ["hello", "world"],
        product: [
            {
                title: "도배",
                having: false,
            },
            {
                title: "장판",
                having: false,
            },
            {
                title: "마루",
                having: false,
            },
            {
                title: "철거",
                having: true,
            },
        ],
    },
];
export function ModelNumTableEdit({
    list,
    setList,
    ClkBtn,
    setFlag,
    setDeleteIdx,
    setDeletePop,
    deletePop,
    FlagValue,
}) {
    const deleteClk = (i) => {
        setDeleteIdx(i);
        setDeletePop(!deletePop);
        setFlag(FlagValue);
    };
    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    return (
        <>
            <div style={{ width: "200px", paddingBottom: "20px" }}>
                <button type="button" className="btn btn9" onClick={ClkBtn}>
                    + &nbsp; 등록
                </button>
            </div>

            <div className="table_scroll" style={{ marginBottom: "20px" }}>
                <table>
                    <thead>
                        <tr>
                            <th>모델번호</th>
                            <th>1차분류</th>
                            <th>2차분류</th>
                            <th>수량</th>
                            <th>메모</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((x, i) => {
                            return (
                                <tr key={i}>
                                    <td>{x.material_number}</td>
                                    <td>{x.material_class01}</td>
                                    <td>{x.material_class02}</td>
                                    <td>
                                        <select
                                            style={{
                                                width: "50px",
                                                border: "none",
                                            }}
                                            value={x.count}
                                            onChange={(e) =>
                                                updateDataList(
                                                    i,
                                                    "count",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            {[...Array(50).keys()].map(
                                                (value) => (
                                                    <option
                                                        key={value}
                                                        value={value}
                                                    >
                                                        {value}롤
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </td>
                                    <td>
                                        <div className="tableInputWrap">
                                            <input
                                                type="text"
                                                name="title"
                                                value={x.memo || ""}
                                                index={i}
                                                onChange={(e) => {
                                                    updateDataList(
                                                        i,
                                                        "memo",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="trash_btn_red"
                                            onClick={() => deleteClk(i)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export function ModelNumTableEdit2({
    list,
    setList,
    ClkBtn,
    setFlag,
    setDeleteIdx,
    setDeletePop,
    deletePop,
    FlagValue,
}) {
    const deleteClk = (i) => {
        setDeleteIdx(i);
        setDeletePop(!deletePop);
        setFlag(FlagValue);
    };

    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    return (
        <>
            <div style={{ width: "200px", paddingBottom: "20px" }}>
                <button type="button" className="btn btn9" onClick={ClkBtn}>
                    + &nbsp; 등록
                </button>
            </div>

            <div className="table_scroll">
                <table>
                    <thead>
                        <tr>
                            <th>모델번호</th>
                            <th>1차분류</th>
                            <th>2차분류</th>
                            <th>길이</th>
                            <th>메모</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((x, i) => {
                            return (
                                <tr key={i}>
                                    <td>{x.material_number}</td>
                                    <td>{x.material_class01}</td>
                                    <td>{x.material_class02}</td>
                                    <td>
                                        <div className="tableInputWrap">
                                            <input
                                                type="number"
                                                name="title"
                                                value={x.length}
                                                index={i}
                                                onChange={(e) => {
                                                    updateDataList(
                                                        i,
                                                        "length",
                                                        parseInt(e.target.value)
                                                    );
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="tableInputWrap">
                                            <input
                                                type="text"
                                                name="title"
                                                value={x.memo || ""}
                                                index={i}
                                                onChange={(e) => {
                                                    updateDataList(
                                                        i,
                                                        "memo",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="trash_btn_red"
                                            onClick={() => deleteClk(i)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export function ModelNumTableEdit3({
    list,
    setList,
    ClkBtn,
    setFlag,
    setDeleteIdx,
    setDeletePop,
    deletePop,
    FlagValue,
}) {
    const deleteClk = (i) => {
        setDeleteIdx(i);
        setDeletePop(!deletePop);
        setFlag(FlagValue);
    };
    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    return (
        <>
            <div style={{ width: "200px", paddingBottom: "20px" }}>
                <button type="button" className="btn btn9" onClick={ClkBtn}>
                    + &nbsp; 등록
                </button>
            </div>

            <div className="table_scroll">
                <table>
                    <thead>
                        <tr>
                            <th>모델번호</th>
                            <th>1차분류</th>
                            <th>2차분류</th>
                            <th>메모</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((x, i) => {
                            return (
                                <tr key={i}>
                                    <td>{x.material_number}</td>
                                    <td>{x.material_class01}</td>
                                    <td>{x.material_class02}</td>
                                    <td>
                                        <div className="tableInputWrap">
                                            <input
                                                type="text"
                                                name="title"
                                                value={x.memo}
                                                index={i}
                                                onChange={(e) => {
                                                    updateDataList(
                                                        i,
                                                        "memo",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="trash_btn_red"
                                            onClick={() => deleteClk(i)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export function TableEditClasfy({
    list,
    setList,
    ClkBtn,
    setFlag,
    setDeleteIdx,
    setDeletePop,
    deletePop,
    FlagValue,
}) {
    const deleteClk = (i) => {
        setDeleteIdx(i);
        setDeletePop(!deletePop);
        setFlag(FlagValue);
    };
    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    return (
        <>
            <div style={{ width: "200px", paddingBottom: "20px" }}>
                <button type="button" className="btn btn9" onClick={ClkBtn}>
                    + &nbsp; 등록
                </button>
            </div>

            <div className="table_scroll" style={{ marginBottom: "20px" }}>
                <table>
                    <thead>
                        <tr>
                            <th>1차분류</th>
                            <th>2차분류</th>
                            <th>수량</th>
                            <th>메모</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((x, i) => {
                            return (
                                <tr key={i}>
                                    <td>{x.material_class01}</td>
                                    <td>{x.material_class02}</td>
                                    <td>
                                        <select
                                            style={{
                                                width: "50px",
                                                border: "none",
                                            }}
                                            value={x.count}
                                            onChange={(e) =>
                                                updateDataList(
                                                    i,
                                                    "count",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            {[...Array(50).keys()].map(
                                                (value) => (
                                                    <option
                                                        key={value}
                                                        value={value}
                                                    >
                                                        {value}개
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </td>
                                    <td>
                                        <div className="tableInputWrap">
                                            <input
                                                type="text"
                                                name="title"
                                                value={x.memo || ""}
                                                index={i}
                                                onChange={(e) => {
                                                    updateDataList(
                                                        i,
                                                        "memo",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            className="trash_btn_red"
                                            onClick={() => deleteClk(i)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}
export function TableOrderEditClasfy({
    list,
    setList,
    ClkBtn,
    setDeleteIdx,
    setDeletePop,
    deletePop,
    setFlag,
    FlagValue,
    setIsDelete,
}) {
    const deleteClk = (i) => {
        setDeleteIdx(i);
        setDeletePop(!deletePop);
        setFlag(FlagValue);
        setIsDelete(true);
    };
    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    return (
        <>
            <div style={{ width: "200px", paddingBottom: "20px" }}>
                <button
                    type="button"
                    className="btn btn9"
                    onClick={() => {
                        ClkBtn();
                    }}
                >
                    + &nbsp; 등록
                </button>
            </div>
            <div className="table_scroll">
                <table>
                    <thead>
                        <tr>
                            <th>모델번호</th>
                            <th>1차분류</th>
                            <th>2차분류</th>
                            <th>필요수량</th>
                            <th>재고</th>
                            <th>주문수량</th>
                            <th>단가</th>
                            <th>합계</th>
                            <th>메모</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list
                            ?.filter((x, i) => x.material_type === 1)
                            .map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{x.material_number || "-"}</td>
                                        <td>{x.material_class01 || "-"}</td>
                                        <td>{x.material_class02 || "-"}</td>
                                        <td>{x.count || 0}롤</td>
                                        <td>{x.inventory_count || 0}롤</td>
                                        <td>
                                            <select
                                                style={{
                                                    width: "50px",
                                                    border: "none",
                                                }}
                                                value={x.order_count || 0}
                                                onChange={(e) =>
                                                    updateDataList(
                                                        i,
                                                        "order_count",
                                                        parseInt(
                                                            e.target.value || 0
                                                        )
                                                    )
                                                }
                                            >
                                                {[...Array(51).keys()].map(
                                                    (value) => (
                                                        <option
                                                            key={value}
                                                            value={value}
                                                        >
                                                            {value}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                            롤
                                        </td>
                                        <td>
                                            <div
                                                className="tableInputWrap FlexCenter"
                                                style={{ gap: "10px" }}
                                            >
                                                <input
                                                    type="text"
                                                    name="title"
                                                    value={
                                                        Number(
                                                            x.order_price || 0
                                                        ).toLocaleString() ||
                                                        "0"
                                                    }
                                                    index={i}
                                                    onChange={(e) => {
                                                        let val =
                                                            e.target.value;
                                                        val =
                                                            parseInt(
                                                                e.target.value.replace(
                                                                    /[^0-9]/g,
                                                                    ""
                                                                )
                                                            ) || 0;
                                                        updateDataList(
                                                            i,
                                                            "order_price",
                                                            val
                                                        );
                                                    }}
                                                />
                                                <p>원</p>
                                            </div>
                                        </td>
                                        <td>
                                            {Number(
                                                (x.order_count || 0) *
                                                    (x.order_price || 0)
                                            ).toLocaleString()}
                                            원
                                        </td>
                                        <td>
                                            <div className="tableInputWrap ">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    value={x.memo || ""}
                                                    index={i}
                                                    onChange={(e) => {
                                                        updateDataList(
                                                            i,
                                                            "memo",
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                className="trash_btn_red"
                                                onClick={() => deleteClk(i)}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                    <tbody>
                        {list?.filter((x, i) => x.material_type === 1)?.length >
                            0 && (
                            <tr>
                                <td
                                    style={{ background: "#ccc" }}
                                    colSpan={2}
                                ></td>
                                <td style={{ background: "#ccc" }}>총합</td>
                                <td>
                                    {list
                                        ?.filter(
                                            (x, i) => x.material_type === 1
                                        )
                                        .reduce((acc, c) => {
                                            return acc + parseInt(c.count || 0);
                                        }, 0)}
                                    롤
                                </td>
                                <td
                                    style={{ background: "#ccc" }}
                                    colSpan={3}
                                ></td>
                                <td>
                                    {list
                                        ?.filter(
                                            (x, i) => x.material_type === 1
                                        )
                                        .reduce((acc, c) => {
                                            return (
                                                acc +
                                                parseInt(
                                                    (c.order_count || 0) *
                                                        (c.order_price || 0)
                                                )
                                            );
                                        }, 0)
                                        .toLocaleString()}
                                    원
                                </td>
                                <td
                                    style={{ background: "#ccc" }}
                                    colSpan={2}
                                ></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}
export function TableOrderEditClasfy2({
    list,
    setList,
    ClkBtn,
    setDeleteIdx,
    setDeletePop,
    deletePop,
    setFlag,
    FlagValue,
    setIsDelete,
}) {
    const deleteClk = (i) => {
        setDeleteIdx(i);
        setDeletePop(!deletePop);
        setFlag(FlagValue);
        setIsDelete(true);
    };
    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    return (
        <>
            <div style={{ width: "200px", paddingBottom: "20px" }}>
                <button
                    type="button"
                    className="btn btn9"
                    onClick={() => {
                        ClkBtn();
                    }}
                >
                    + &nbsp; 등록
                </button>
            </div>
            <div className="table_scroll">
                <table>
                    <thead>
                        <tr>
                            <th>1차분류</th>
                            <th>2차분류</th>
                            <th>수량</th>
                            <th>단가</th>
                            <th>합계</th>
                            <th>메모</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list
                            ?.filter((x, i) => x.material_type === 5)
                            .map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{x.material_class01 || 0}</td>
                                        <td>{x.material_class02 || 0}</td>
                                        <td>
                                            <select
                                                style={{
                                                    width: "50px",
                                                    border: "none",
                                                }}
                                                value={x.count || 0}
                                                onChange={(e) =>
                                                    updateDataList(
                                                        i,
                                                        "count",
                                                        parseInt(e.target.value)
                                                    )
                                                }
                                            >
                                                {[...Array(51).keys()].map(
                                                    (value) => (
                                                        <option
                                                            key={value}
                                                            value={value}
                                                        >
                                                            {value}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                            개
                                        </td>
                                        <td>
                                            <div
                                                className="tableInputWrap FlexCenter"
                                                style={{ gap: "10px" }}
                                            >
                                                <input
                                                    type="text"
                                                    name="title"
                                                    value={
                                                        Number(
                                                            x.order_price || 0
                                                        ).toLocaleString() ||
                                                        "0"
                                                    }
                                                    index={i}
                                                    onChange={(e) => {
                                                        let val =
                                                            e.target.value;
                                                        val =
                                                            parseInt(
                                                                e.target.value.replace(
                                                                    /[^0-9]/g,
                                                                    ""
                                                                )
                                                            ) || 0;
                                                        updateDataList(
                                                            i,
                                                            "order_price",
                                                            val
                                                        );
                                                    }}
                                                />
                                                <p>원</p>
                                            </div>
                                        </td>
                                        <td>
                                            {" "}
                                            {Number(
                                                (x.count || 0) *
                                                    (x.order_price || 0)
                                            ).toLocaleString()}
                                            원
                                        </td>
                                        <td>
                                            <div className="tableInputWrap ">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    value={x.memo || ""}
                                                    index={i}
                                                    onChange={(e) => {
                                                        updateDataList(
                                                            i,
                                                            "memo",
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                className="trash_btn_red"
                                                onClick={() => deleteClk(i)}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                    <tbody>
                        {list?.filter((x, i) => x.material_type === 5)?.length >
                            0 && (
                            <tr>
                                <td style={{ background: "#ccc" }}></td>
                                <td style={{ background: "#ccc" }}>총합</td>
                                <td>
                                    {" "}
                                    {list
                                        ?.filter(
                                            (x, i) => x.material_type === 5
                                        )
                                        ?.reduce((acc, c) => {
                                            return acc + parseInt(c.count || 0);
                                        }, 0)}
                                    개
                                </td>
                                <td style={{ background: "#ccc" }}></td>
                                <td>
                                    {list
                                        ?.filter(
                                            (x, i) => x.material_type === 5
                                        )
                                        ?.reduce((acc, c) => {
                                            return (
                                                acc +
                                                parseInt(
                                                    (c.count || 0) *
                                                        (c.order_price || 0)
                                                )
                                            );
                                        }, 0)
                                        .toLocaleString()}
                                    원
                                </td>
                                <td
                                    style={{ background: "#ccc" }}
                                    colSpan={2}
                                ></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export function TableExpenseRegister({
    list,
    setList,
    setDeleteidx,
    setDeletePop,
    setDeletePopFlag,
    flagNum,
    duty = 3.3,
    banzang,
}) {
    const { data: workerList } = useGetData(ApiData.workerList);
    // const BanzangWorkerList = usePostData(ApiData.workerList);
    const [workerListData, setWorkerListData] = useState([
        { value: 0, text: "선택" },
    ]);

    // const workerListApi = () => {
    //     let sender = {
    //         // searchVal: banzang?.belong,    //     };
    //     BanzangWorkerList.mutateAsync(sender)
    //         .then((data) => {
    //             console.log("data workerList", data);
    //         })
    //         .catch((e) => {
    //             toast.error(e.response.data);
    //         });
    // };
    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    const addDataList = () => {
        setList([
            ...list,
            {
                worker_name: "",
                personnel_expenses: 0,
                meal_price: 0,
                worker_idx: 0,
            },
        ]);
    };
    const deleteClk = (i) => {
        setDeleteidx(i);
        setDeletePopFlag(flagNum);
        setDeletePop(true);
    };
    useEffect(() => {
        workerList?.data
            ?.filter((c) => c.belong === banzang?.banzang_belong)
            ?.map((x) => {
                setWorkerListData((prev) => {
                    // Check if the item already exists in the array
                    if (!prev.some((item) => item.value === x.idx)) {
                        return [...prev, { value: x.idx, text: x.name }];
                    }
                    // If the item already exists, return the previous state
                    return prev;
                });
            });
        return () => {
            setWorkerListData([{ value: 0, text: "선택" }]);
        };
    }, [workerList]);

    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>작업자</th>
                        <th>인건비</th>
                        <th>식대</th>
                        <th>합계</th>
                        <th
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={addDataList}
                        >
                            + 추가하기
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, index) => {
                        return (
                            <tr key={index}>
                                {index === 0 ? (
                                    <td>{item.worker_name}</td>
                                ) : (
                                    <td>
                                        {workerListData?.length > 1 ? (
                                            <select
                                                style={{
                                                    width: "60%",
                                                    height: "44px",
                                                    padding: "10px",
                                                }}
                                                value={item.worker_idx}
                                                onChange={(e) =>
                                                    updateDataList(
                                                        index,
                                                        "worker_idx",
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {workerListData?.map((x, i) => (
                                                    <option
                                                        key={i}
                                                        value={x.value}
                                                    >
                                                        {x.text}
                                                    </option>
                                                ))}
                                            </select>
                                        ) : (
                                            workerListData[0].text
                                        )}
                                    </td>
                                )}

                                <td>
                                    <div
                                        className="tableInputWrap FlexCenter"
                                        style={{ gap: "10px" }}
                                    >
                                        <input
                                            type="text"
                                            name="title"
                                            value={
                                                Number(
                                                    item.personnel_expenses || 0
                                                ).toLocaleString() || "0"
                                            }
                                            index={index}
                                            onChange={(e) => {
                                                let val = e.target.value;
                                                val =
                                                    parseInt(
                                                        e.target.value.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                        )
                                                    ) || 0;
                                                updateDataList(
                                                    index,
                                                    "personnel_expenses",
                                                    val
                                                );
                                            }}
                                        />
                                        <p>원</p>
                                    </div>
                                </td>
                                <td>
                                    <div
                                        className="tableInputWrap FlexCenter"
                                        style={{ gap: "10px" }}
                                    >
                                        <input
                                            type="text"
                                            name="title"
                                            value={
                                                Number(
                                                    item.meal_price || 0
                                                ).toLocaleString() || "0"
                                            }
                                            index={index}
                                            onChange={(e) => {
                                                let val = e.target.value;
                                                val =
                                                    parseInt(
                                                        e.target.value.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                        )
                                                    ) || 0;
                                                updateDataList(
                                                    index,
                                                    "meal_price",
                                                    val
                                                );
                                            }}
                                        />
                                        <p>원</p>
                                    </div>
                                </td>
                                <td>
                                    {(
                                        (item.personnel_expenses || 0) +
                                        (item.meal_price || 0)
                                    ).toLocaleString()}
                                    원
                                </td>
                                <td>
                                    {index === 0 ? null : (
                                        <button
                                            type="button"
                                            className="trash_btn_red"
                                            onClick={() => deleteClk(index)}
                                        />
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td>소계</td>
                        <td>
                            {list
                                ?.reduce((acc, cur) => {
                                    return acc + (cur.personnel_expenses || 0);
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                        <td>
                            {" "}
                            {list
                                ?.reduce((acc, cur) => {
                                    return acc + (cur.meal_price || 0);
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                        <td>
                            {list
                                ?.reduce((acc, cur) => {
                                    return (
                                        acc +
                                        (cur.personnel_expenses || 0) +
                                        (cur.meal_price || 0)
                                    );
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan="2"></td>
                        <td>세금</td>
                        <td>
                            {" "}
                            {list
                                ?.reduce((acc, cur) => {
                                    return parseInt(
                                        acc +
                                            (((cur.personnel_expenses || 0) +
                                                (cur.meal_price || 0)) *
                                                duty) /
                                                100
                                    );
                                }, 0)
                                .toLocaleString()}
                            원{" "}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan="2"></td>
                        <td>합계</td>
                        <td>
                            {" "}
                            {list
                                ?.reduce((acc, cur) => {
                                    return parseInt(
                                        acc +
                                            ((cur.personnel_expenses || 0) +
                                                (cur.meal_price || 0)) +
                                            (((cur.personnel_expenses || 0) +
                                                (cur.meal_price || 0)) *
                                                duty) /
                                                100
                                    );
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
export function TableEditASUsed() {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>모델번호</th>
                        <th>1차분류</th>
                        <th>2차분류</th>
                        <th>단가</th>
                        <th>수량</th>
                        <th>메모</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>
                    {test.map((item, index) => (
                        <tr key={index}>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                            <td>{item.contact}</td>
                            <td>
                                <select
                                    style={{
                                        width: "50px",
                                        border: "none",
                                    }}
                                >
                                    {[...Array(20).keys()].map((value) => (
                                        <option key={value} value={value}>
                                            {value}개
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>{item.contact}</td>
                            <td>
                                <button
                                    type="button"
                                    className="trash_btn_red"
                                    // onClick={() =>
                                    //     zoneDelete(
                                    //         i,
                                    //         x.idx ? x.idx : ""
                                    //     )
                                    // }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function TableChangeIndex({
    setDeleteKey,
    title = "테스팅!",
    unit,
    list,
    setlist,
    setIdx,
    OnlyOneIdx,
    setOnlyOneIdx,
    clkOperation,
    setClkOperation,
    deletePop,
    setDeletePop,
    content,
    setContent,
    unitContent,
    setUnitContent,
}) {
    const materialList = usePostData(ApiData.materialList);

    const [dataName, setDataName] = useState("");

    useEffect(() => {
        if (title === "제조사") {
            setDataName("company_name");
        } else if (title === "1차 분류") {
            setDataName("class1_name");
        } else if (title === "2차 분류") {
            setDataName("class2_name");
        } else if (title === "색상") {
            setDataName("material_color");
        } else if (title === "패턴") {
            setDataName("material_pattern");
        }
    }, [title]);

    const OnChangeContent = (e) => {
        setContent(e.target.value);
    };
    const OnChangeUnitContent = (e) => {
        setUnitContent(e.target.value);
    };

    const zoneAppend = (type) => {
        if (content === "") {
            toast.error("내용을 입력해주세요.");
            return;
        }
        if (unit && unitContent === "") {
            toast.error("단위를 입력해주세요.");
            return;
        }
        setClkOperation("Append");
        setIdx(-1);
        // setlist([...list, { [dataName]: addThing, readOnly: true }]);
    };
    const zoneMove = (move, i, idx) => {
        if (move == -1) {
            setClkOperation("moveUp");
            setIdx(idx);
        } else {
            setClkOperation("moveDown");
            setIdx(idx);
        }
        // setlist(changeArrayOrder(list, i, move));
    };
    const zoneDelete = (Itemidx, i) => {
        setDeleteKey(title);
        setIdx(Itemidx);
        setDeletePop(true);
    };
    const zoneValueChange = (item, index) => {
        setlist(
            list?.map((x, i) => (i === index ? { ...x, readOnly: false } : x))
        );
    };
    const zoneValueSave = (item, index) => {
        setIdx(item.idx);
        setClkOperation("Save");
    };
    const zoneSetValue = (index, event) => {
        setlist((prev) =>
            prev.map((x, i) =>
                i === index ? { ...x, [dataName]: event.target.value } : x
            )
        );
    };
    const zoneSetValueUnit = (index, event) => {
        setlist((prev) =>
            prev.map((x, i) =>
                i === index ? { ...x, class2_unit: event.target.value } : x
            )
        );
    };
    // function changeArrayOrder(array, index, move) {
    //     const targetIndex = index + move;
    //     if (targetIndex < 0 || targetIndex >= array.length) return array;

    //     const newArray = [...array];
    //     const temp = newArray[targetIndex];
    //     newArray[targetIndex] = newArray[index];
    //     newArray[index] = temp;
    //     return newArray;
    // }

    const LevelClk = (i) => {
        setOnlyOneIdx(i);
        setIdx(i);
        setClkOperation("levelUp");
    };

    return (
        <div className="grid_container">
            <div className="ContainerWrap">
                <div className="FlexCenter">
                    <p className="count">{title}</p>
                </div>
                <div className="buttonWrap">
                    <div className="tableInputWrap">
                        <input value={content} onChange={OnChangeContent} />
                    </div>
                    {unit && (
                        <div
                            className="tableInputWrap"
                            style={{ width: "20%" }}
                        >
                            <input
                                value={unitContent}
                                onChange={OnChangeUnitContent}
                            />
                        </div>
                    )}

                    <div className="btn_box">
                        <button
                            style={{ padding: "0px" }}
                            type="button"
                            className="btn btn4"
                            onClick={() => {
                                zoneAppend();
                            }}
                        >
                            추가
                        </button>
                    </div>
                </div>
            </div>

            <div className="multi_table_detail">
                <div className="table_scroll">
                    <table className="">
                        <thead>
                            <tr>
                                <th className="s">순서변경</th>
                                <th className="xl">분류</th>
                                {unit ? <th className="m">단위</th> : null}
                                <th className="s">수정</th>
                                <th className="s">삭제</th>
                            </tr>
                        </thead>

                        <tbody>
                            {list?.map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            <div className="arrow_btn_box">
                                                <button
                                                    type="button"
                                                    className="top"
                                                    onClick={() =>
                                                        zoneMove(-1, i, x.idx)
                                                    }
                                                />
                                                <button
                                                    type="button"
                                                    className="down"
                                                    onClick={() =>
                                                        zoneMove(1, i, x.idx)
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td onClick={() => LevelClk(x.idx)}>
                                            <Input
                                                className="input_text"
                                                type="text"
                                                placeholder="내용을 입력해 주세요."
                                                name="title"
                                                value={
                                                    x.company_name ||
                                                    x.class1_name ||
                                                    x.class2_name ||
                                                    x.material_color ||
                                                    x.material_pattern
                                                }
                                                onChange={zoneSetValue}
                                                index={i}
                                                readOnly={x.readOnly}
                                                labelStyle={{ display: "none" }}
                                            />
                                        </td>
                                        {unit ? (
                                            <td>
                                                <Input
                                                    className="input_text"
                                                    type="text"
                                                    placeholder="단위"
                                                    name="title"
                                                    value={x.class2_unit}
                                                    onChange={zoneSetValueUnit}
                                                    index={i}
                                                    readOnly={x.readOnly}
                                                    labelStyle={{
                                                        display: "none",
                                                    }}
                                                />
                                            </td>
                                        ) : null}
                                        <td>
                                            {x.readOnly ? (
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        zoneValueChange(x, i);
                                                    }}
                                                >
                                                    수정
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        zoneValueSave(x, i);
                                                    }}
                                                >
                                                    저장
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    zoneDelete(x.idx, i)
                                                }
                                            >
                                                삭제
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export function TableASRegister() {
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th rowSpan="2">모델번호</th>
                        <th rowSpan="2">1차분류</th>
                        <th rowSpan="2">2차분류</th>
                        <th rowSpan="2">수량</th>
                        <th rowSpan="2">단가</th>
                        <th colSpan="3">투입</th>
                        <th rowSpan="2"></th>
                        <th colSpan="2">사용</th>
                    </tr>
                    <tr>
                        <th
                            style={{
                                borderTop: "none",
                                borderLeft: "1px solid #ddd",
                            }}
                        >
                            신규
                        </th>
                        <th style={{ borderTop: "none" }}>재고</th>
                        <th style={{ borderTop: "none" }}>합계</th>
                        <th style={{ borderTop: "none" }}>수량</th>
                        <th style={{ borderTop: "none" }}>금액</th>
                    </tr>
                </thead>
                <tbody>
                    {test.map((item, index) => (
                        <tr key={index}>
                            <td>20901-1</td>
                            <td>실크</td>
                            <td></td>
                            <td>1개</td>
                            <td>10,000원</td>
                            <td>
                                <Input
                                    readOnly
                                    className="input_text"
                                    type="text"
                                    name="title"
                                    // value={x.title}
                                    // onChange={zoneSetValue}
                                    index={index + 1}
                                    labelStyle={{ display: "none" }}
                                />
                            </td>
                            <td>
                                <Input
                                    readOnly
                                    className="input_text"
                                    type="text"
                                    name="title"
                                    // value={x.title}
                                    // onChange={zoneSetValue}
                                    index={index + 1}
                                    labelStyle={{ display: "none" }}
                                />
                            </td>
                            <td>6개</td>
                            <td>1개</td>
                            <td>
                                <Input
                                    readOnly
                                    className="input_text"
                                    type="text"
                                    name="title"
                                    // value={x.title}
                                    // onChange={zoneSetValue}
                                    index={index + 1}
                                    labelStyle={{ display: "none" }}
                                />
                            </td>
                            <td>50,000원</td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan="5"></td>
                        <td>1개</td>
                        <td>2개 </td>
                        <td>3개</td>
                        <td>4개</td>
                        <td>5개</td>
                        <td>50,000원</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
export function TableEndOtherCostEdit({
    list,
    setList,
    setDeleteidx,
    setDeletePop,
    setDeletePopFlag,
    flagNum,
}) {
    const updateDataList = (i, key, value) => {
        setList(
            list?.map((item, index) =>
                index === i ? { ...item, [key]: value } : item
            )
        );
    };
    const addDataList = () => {
        setList([...list, { content: "", price: 0 }]);
    };
    const deleteClk = (i) => {
        setDeleteidx(i);
        setDeletePopFlag(flagNum);
        setDeletePop(true);
    };
    return (
        <div className="table_scroll">
            <table>
                <thead>
                    <tr>
                        <th>내역</th>
                        <th>금액</th>
                        <th
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={addDataList}
                        >
                            + 추가하기
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <div className="tableInputWrap">
                                    <input
                                        type="text"
                                        name="title"
                                        value={item.content || ""}
                                        index={index}
                                        onChange={(e) => {
                                            updateDataList(
                                                index,
                                                "content",
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </td>
                            <td>
                                <div
                                    className="tableInputWrap FlexCenter"
                                    style={{ gap: "10px" }}
                                >
                                    <input
                                        type="text"
                                        name="title"
                                        value={
                                            Number(
                                                item.price || 0
                                            ).toLocaleString() || "0"
                                        }
                                        index={index}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            val =
                                                parseInt(
                                                    e.target.value.replace(
                                                        /[^0-9]/g,
                                                        ""
                                                    )
                                                ) || 0;
                                            updateDataList(index, "price", val);
                                        }}
                                    />
                                    <p>원</p>
                                </div>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    className="trash_btn_red"
                                    onClick={() => deleteClk(index)}
                                ></button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td style={{ textAlign: "right", padding: "10px" }}>
                            합계
                        </td>
                        <td style={{ textAlign: "right", padding: "10px" }}>
                            {list
                                ?.reduce((acc, cur) => {
                                    return acc + (cur.price || 0);
                                }, 0)
                                .toLocaleString()}
                            원
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
