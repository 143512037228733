import React, { useEffect, useState } from "react";
import {
    BoxButtons,
    BoxContent,
    BoxContent2,
    BoxDateInput,
    BoxDropdownInput,
    BoxDropdownTime,
    BoxLookImg,
    BoxMemoInput,
    BoxProgress,
    BoxTextInput,
} from "../../../components/BoxInfo";
import { HistoryBox, HistoryMessageBox } from "../../../components/HistoryBox";

import consts from "../../../libs/consts";
import images from "../../../libs/images";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../../libs/routes";
import moment from "moment";
import { set } from "lodash";
import { PopupComp } from "../../../components/PopUp";
import {
    ModelNumTableEdit,
    ModelNumTableEdit2,
    ModelNumTableEdit3,
} from "../../../components/TableEdit";
import { toast } from "react-toastify";
import { usePostData } from "../../../utils/service";
import ApiData from "../../../libs/api";
import { resizeFile } from "../../../utils/utils";
import { useUser } from "../../../zustand/Login";
import { TabComponent } from "../../../components/TabComponent";
import Input from "../../../components/Input";
import Pagination from "react-js-pagination";

function ASManageRegister() {
    const [searchParams, setSearchParams] = useSearchParams();
    const index = searchParams.get("index");
    const asinfoMsgList = usePostData(ApiData.asinfoMsgList);
    const sigongPopupFilter = usePostData(ApiData.sigongPopupFilter);
    const asInfoDetail = usePostData(ApiData.asInfoDetail);
    const asStatusUpdate = usePostData(ApiData.asStatusUpdate);
    const asInfoIn = usePostData(ApiData.asInfoIn);
    const asGoMsg = usePostData(ApiData.asGoMsg);
    const [materialSearchVal, setMaterialSearchVal] = useState("");
    const [ShowContent, setShowContent] = useState(true);
    const [ShowContent2, setShowContent2] = useState(true);
    const [ShowContent3, setShowContent3] = useState(true);
    // const [ShowContent4, setShowContent4] = useState(true);
    const [stateValue, setStateValue] = useState(0);

    const [asDobaechk, setAsDobaechk] = useState(0);
    const [asZangpanchk, setAsZangpanchk] = useState(0);
    const [customerName, setCustomerName] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [sigongDobaeDate, setSigongDobaeDate] = useState(new Date());
    const [sigongZangpanDate, setSigongZangpanDate] = useState(new Date());
    const [dobaeWorkerMemo, setDobaeWorkerMemo] = useState("");
    const [zangpanWorkerMemo, setZangpanWorkerMemo] = useState("");
    const [dobaeAsManager, setDobaeAsManager] = useState("");
    const [zangpanAsManager, setZangpanAsManager] = useState("");

    const [dobaeRange, setDobaeRange] = useState(0);
    const [dobaeRangeMemo, setDobaeRangeMemo] = useState("");
    const [zangpanRange, setZangpanRange] = useState(0);
    const [zangpanRangeMemo, setZangpanRangeMemo] = useState("");
    const [dobaeProduct, setDobaeProduct] = useState(1);
    const [dobaeCnt, setDobaeCnt] = useState(0);
    const [zangpanmm, setZangpanmm] = useState("");
    const [zangpanMolding, setZangpanMolding] = useState(0);
    const [zangpanMoldingMemo, setZangpanMoldingMemo] = useState("");
    const [zangpanMoldingRange, setZangpanMoldingRange] = useState(0);
    const [zangpanMoldingRangeMemo, setZangpanMoldingRangeMemo] = useState("");

    const [createManager, setCreateManager] = useState("");
    const [requestPath, setRequestPath] = useState("");
    const [requestDate, setRequestDate] = useState(new Date());
    const [requestContent, setRequestContent] = useState("");
    const [deletePop, setDeletePop] = useState(false);
    const [popUpFlag, setPopupFlag] = useState(1);
    const [dobaeImgSetting, setDobaeImgSetting] = useState([]);
    const [zangpanImgSetting, setZangpanImgSetting] = useState([]);
    const [ImgDobaeDelete, setImgDobaeDelete] = useState([]);
    const [ImgZangpanDelete, setImgZangpanDelete] = useState([]);

    const [dobaeDate, setDobaeDate] = useState(new Date());
    const [dobaeTime, setDobaeTime] = useState("");
    const [dobaeTimeDinput, setDobaeTimeDinput] = useState("");
    const [dobaeMemo, setDobaeMemo] = useState("");
    const [dobaeASPrice, setDobaeASPrice] = useState(0);
    const [dobaeAsSend, setDobaeAsSend] = useState(0);
    const [dobaeASSendCount, setDobaeASSendCount] = useState(0);

    const [zangpanDate, setZangpanDate] = useState(new Date());
    const [zangpanTime, setZangpanTime] = useState("");
    const [zangpanTimeDinput, setZangpanTimeDinput] = useState("");
    const [zangpanAsSend, setZangpanAsSend] = useState(0);
    const [zangpanASSendCount, setZangpanASSendCount] = useState(0);
    const [zangpanMemo, setZangpanMemo] = useState("");
    const [zangpanASPrice, setZangpanASPrice] = useState(0);

    //팝업관련
    const [OpenPop, setOpenPop] = useState(false);

    const [innerType, setInnerType] = useState(1);
    const [innerSelectCompany, setInnerSelectCompany] = useState("제조사");
    const [innerSelectFirstCategory, setInnerSelectFirstCategory] =
        useState("1차분류");
    const [innerSelectSecondCategory, setInnerSelectSecondCategory] =
        useState("2차분류");
    const [filterPopupList, setFilterPopupList] = useState([]);
    const [companyList, setCompanyList] = useState(["제조사"]);
    const [firstCategory, setFirstCategory] = useState(["1차분류"]);
    const [secondCategory, setSecondCategory] = useState(["2차분류"]);
    const [agencyValue, setAgencyValue] = useState([]);
    const [agencyMateListData, setAgencyMateListData] = useState([]);
    const [openPopRegFlag, setOpenPopRegFlag] = useState(0);

    const [materialDeleteIdx, setMaterialDeleteIdx] = useState(0);

    const [dobaeMateList, setDobaeMateList] = useState([]);
    const [dobaeDeleteMateList, setDobaeDeleteMateList] = useState([]);

    const [zangpanMateList, setZangpanMateList] = useState([]);
    const [zangpanDeleteMateList, setZangpanDeleteMateList] = useState([]);
    const [zangpanMopMateList, setZangpanMopMateList] = useState([]);
    const [zangpanMopDeleteMateList, setZangpanMopDeleteMateList] = useState(
        []
    );
    const [tap, setTap] = useState([]);
    const [history, setHistory] = useState([]);
    const [contentChange, setContentChange] = useState([]);
    const { mb_name } = useUser((state) => state.mbData);
    const [dobaeAdminMemo, setDobaeAdminMemo] = useState("");
    const [zangpanAdminMemo, setZangpanAdminMemo] = useState("");
    const [innerPage, setInnerPage] = useState(1);
    const [agencyListCount, setAgencyListCount] = useState(0);
    const [workerMsg, setWorkerMsg] = useState([]);
    const [workerMsgPage, setWorkerMsgPage] = useState(1);
    const [workerMsgTotal, setWorkerMsgTotal] = useState(0);
    const handlePageChange = (pageNumber) => {
        setInnerPage(pageNumber);
    };
    const handleWorkerMsgPageChange = (pageNumber) => {
        setWorkerMsgPage(pageNumber);
    };
    const navigate = useNavigate();
    const asMsgListApi = () => {
        asinfoMsgList
            .mutateAsync({ as_idx: index, workerPage: workerMsgPage - 1 })
            .then(({ data }) => {
                setWorkerMsgTotal(data?.workerMsg.count);
                setWorkerMsg(data?.workerMsg.rows);
            })
            .catch((e) => {
                toast.error(e.response.data);
            });
    };
    useEffect(() => {
        asMsgListApi();
    }, [workerMsgPage]);

    const SendMsgClk = (type) => {
        asGoMsg
            .mutateAsync({ as_idx: index, msgType: type })
            .then(({ data }) => {
                toast.success("성공적으로 발송되었습니다.");
                asInfoDetailApi();
                asMsgListApi();
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };

    const statusUpdate = () => {
        asStatusUpdate
            .mutateAsync({ asIdx: index, status: stateValue })
            .then(({ data }) => {
                toast.success("성공적으로 변경되었습니다.");
                asInfoDetailApi();
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };
    const SubmitClk = () => {
        let sender = {
            asIdx: index,

            admin_name: mb_name,
            image_data_dobae: dobaeImgSetting?.filter((item, i) =>
                item.includes("data:image")
            ),
            image_data_dobae_del: ImgDobaeDelete?.filter((item, i) =>
                item.includes("as_img/")
            ),
            image_data_zangpan: zangpanImgSetting?.filter((item, i) =>
                item.includes("data:image")
            ),
            image_data_zangpan_del: ImgZangpanDelete?.filter((item, i) =>
                item.includes("as_img/")
            ),
            dobae_as_date: dobaeDate,
            dobae_as_time: dobaeTime === "--:--" ? dobaeTimeDinput : dobaeTime,
            dobae_worker_memo: dobaeWorkerMemo,
            dobae_as_price: dobaeASPrice,
            dobae_mateList: dobaeMateList,
            dobae_del_mateList: dobaeDeleteMateList,

            zangpan_as_date: zangpanDate,
            zangpan_as_time:
                zangpanTime === "--:--" ? zangpanTimeDinput : zangpanTime,
            zangpan_worker_memo: zangpanWorkerMemo,
            zangpan_as_price: zangpanASPrice,
            zangpan_mateList: zangpanMateList,
            zangpan_del_mateList: zangpanDeleteMateList,
            zangpan_sub_mateList: zangpanMopMateList,
            zangpan_sub_del_mateList: zangpanMopDeleteMateList,
            dobae_admin_memo: dobaeAdminMemo,
            zangpan_admin_memo: zangpanAdminMemo,
        };
        // console.log("sender", sender);

        asInfoIn
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("성공적으로 등록되었습니다.");
                navigate(`${routes.constPreview6}?index=${index}`);
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };
    const asInfoDetailApi = () => {
        asInfoDetail
            .mutateAsync({ asIdx: index })
            .then(({ data }) => {
                // console.log("data", data);
                setAsDobaechk(data.detail.dobae_as_chk);
                setAsZangpanchk(data.detail.zangpan_as_chk);
                setTap(data.tap);
                setHistory(data.history);
                setStateValue(data.detail.status);

                setCustomerName(data.sigonginfo.cutomer_name);
                setCustomerPhone(data.sigonginfo.mb_phone);
                setSigongDobaeDate(data.sigonginfo.dobae_date);
                setSigongZangpanDate(data.sigonginfo.zangpan_date);

                setDobaeRange(data.sigonginfo.dobae_range);
                setDobaeRangeMemo(data.sigonginfo.dobae_range_memo);
                setZangpanRange(data.sigonginfo.zangpan_range);
                setZangpanRangeMemo(data.sigonginfo.zangpan_range_memo);
                setDobaeProduct(data.sigonginfo.dobae_product);
                setDobaeCnt(data.sigonginfo.dobae_cnt);
                setZangpanmm(data.sigonginfo.zangpan_mm);
                setDobaeAsManager(data.sigonginfo.dobae_banzang);
                setZangpanAsManager(data.sigonginfo.zangpan_banzang);

                setRequestPath(data.detail.request_path);
                setRequestDate(data.detail.create_dt);
                setDobaeWorkerMemo(data.detail.dobae_worker_memo);
                setZangpanWorkerMemo(data.detail.zangpan_worker_memo);

                setDobaeDate(data.detail.dobae_as_date);
                setDobaeTime(data.detail.dobae_as_time);
                setDobaeMemo(data.detail.dobae_as_memo);
                setDobaeASPrice(data.detail.dobae_as_price);
                setDobaeMateList(data.dobae_mate);
                setDobaeAsSend(data.detail.dobae_as_send);
                setDobaeASSendCount(data.detail.dobae_as_send_count);
                setZangpanMolding(data.sigonginfo.zangpan_molding);
                setZangpanMoldingMemo(data.sigonginfo.zangpan_molding_memo);
                setZangpanMoldingRange(data.sigonginfo.zangpan_molding_range);
                setZangpanMoldingRangeMemo(
                    data.sigonginfo.zangpan_molding_range_memo
                );
                setZangpanDate(data.detail.zangpan_as_date);
                setZangpanTime(data.detail.zangpan_as_time);
                setZangpanAsSend(data.detail.zangpan_as_send);
                setZangpanASSendCount(data.detail.zangpan_as_count);
                setZangpanMemo(data.detail.zangpan_as_memo);
                setZangpanASPrice(data.detail.zangpan_as_price);
                setZangpanMateList(data.zangpan_mate);
                setZangpanMopMateList(data.zangpan_sub_mate);
                setDobaeImgSetting(
                    data.file
                        .filter((item, i) => item.type === 1)
                        .map((item, i) => item.path)
                );
                setZangpanImgSetting(
                    data.file
                        .filter((item, i) => item.type === 2)
                        .map((item, i) => item.path)
                );
                setDobaeAdminMemo(data.detail.dobae_admin_memo);
                setZangpanAdminMemo(data.detail.zangpan_admin_memo);
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };
    const ClosePop = () => {
        setOpenPop(!OpenPop);
        setMaterialSearchVal("");
        setInnerSelectCompany("제조사");
        setInnerSelectFirstCategory("1차분류");
        setInnerSelectSecondCategory("2차분류");
        setAgencyValue([]);
    };
    const handleChange = (e) => {
        if (e.target.checked) {
            setAgencyValue([...agencyValue, parseInt(e.target.value)]);
        } else {
            setAgencyValue(
                agencyValue.filter(
                    (value) => value !== parseInt(e.target.value)
                )
            );
        }
    };
    const materialListSave = () => {
        const flagToFunctionMap = {
            1: setDobaeMateList,
            2: setZangpanMateList,
            3: setZangpanMopMateList,
        };

        const updateMateList = (updateFunction) => {
            updateFunction((prev = []) => {
                // console.log("prev", prev);
                // console.log("agencyValue", agencyValue);
                return prev.concat(
                    agencyValue.flatMap((num) =>
                        agencyMateListData
                            ?.map((c) => {
                                const { idx, ...rest } = c;
                                return parseInt(c.idx) === parseInt(num)
                                    ? rest
                                    : null;
                            })
                            .filter(Boolean)
                    )
                );
            });
        };

        if (flagToFunctionMap.hasOwnProperty(openPopRegFlag)) {
            updateMateList(flagToFunctionMap[openPopRegFlag]);
        }

        setOpenPop(!OpenPop);
        setAgencyValue([]);
    };
    // useEffect(() => {
    //     console.log("dobaeMateList", dobaeMateList);
    // }, [dobaeMateList]);

    const deleteImg = (idx, type) => {
        if (type === 1) {
            setDobaeImgSetting((prev) =>
                prev.filter((item, index) => {
                    return index !== idx;
                })
            );
            setImgDobaeDelete((prev) => {
                return [...prev, dobaeImgSetting[idx]];
            });
        } else {
            setZangpanImgSetting((prev) =>
                prev.filter((item, index) => {
                    return index !== idx;
                })
            );
            setImgZangpanDelete((prev) => {
                return [...prev, zangpanImgSetting[idx]];
            });
        }
    };
    const deleteDobaeMate = () => {
        setDobaeMateList((prev) =>
            prev.filter((item, index) => {
                return index !== materialDeleteIdx;
            })
        );
        setDobaeDeleteMateList((prev) => {
            return [...prev, dobaeMateList[materialDeleteIdx].idx];
        });
    };
    const deleteZangpanMate = () => {
        setZangpanMateList((prev) =>
            prev.filter((item, index) => {
                return index !== materialDeleteIdx;
            })
        );
        setZangpanDeleteMateList((prev) => {
            return [...prev, zangpanMateList[materialDeleteIdx].idx];
        });
    };
    const deleteZangpanSubMate = () => {
        setZangpanMopMateList((prev) =>
            prev.filter((item, index) => {
                return index !== materialDeleteIdx;
            })
        );
        setZangpanMopDeleteMateList((prev) => {
            return [...prev, zangpanMopMateList[materialDeleteIdx].idx];
        });
    };
    const AddMaterial = () => {
        let sender = {
            material_company:
                innerSelectCompany === "제조사" ? "" : innerSelectCompany,
            material_class01:
                innerSelectFirstCategory === "1차분류"
                    ? ""
                    : innerSelectFirstCategory,
            material_class02:
                innerSelectSecondCategory === "2차분류"
                    ? ""
                    : innerSelectSecondCategory,
            material_type: innerType,
            searchVal: materialSearchVal,
            page: innerPage - 1,
        };
        if (innerSelectCompany === "제조사") {
            sender.material_class01 = "";
            setInnerSelectFirstCategory("1차분류");
        }
        if (innerSelectFirstCategory === "1차분류") {
            sender.material_class01 = "";
            setInnerSelectSecondCategory("2차분류");
        }
        sigongPopupFilter
            .mutateAsync(sender)
            .then(({ data }) => {
                setAgencyListCount(data?.list?.count);
                setAgencyMateListData(data?.list?.rows);
                setFilterPopupList(data?.filter);
            })
            .catch((e) => {
                toast.error("서버에서 응답을 받지 못했습니다.");
            });
    };
    const btnDobaePop = () => {
        setInnerType(1);
        setOpenPop(!OpenPop);
        setOpenPopRegFlag(1);
    };
    const btnZangpanPop = () => {
        setInnerType(2);
        setOpenPop(!OpenPop);
        setOpenPopRegFlag(2);
    };
    const btnZangpanBaseBoardPop = () => {
        setInnerType(4);
        setOpenPop(!OpenPop);
        setOpenPopRegFlag(3);
    };

    const ImgChange = async (value) => {
        var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
        let imgVal = "";

        if (value.name.toLowerCase().match(reg)) {
            imgVal = await resizeFile(value, 500, 500);
            // console.log('base64 ==> ', imgVal);
            return imgVal;
        } else {
            // console.log("이미지 파일만 업로드 가능합니다,");
        }
    };
    const handleGalleryClick = (type) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = async () => {
                const resizedImageUrl = await ImgChange(file);
                if (type === 1) {
                    setDobaeImgSetting((prevImages) => [
                        ...prevImages,
                        resizedImageUrl,
                    ]);
                } else {
                    setZangpanImgSetting((prevImages) => [
                        ...prevImages,
                        resizedImageUrl,
                    ]);
                }
            };
            reader.readAsDataURL(file);
        };
        input.click();
    };
    const OpenUrl = ({ SendType, idx, type }) => {
        if (SendType === 4) {
            window.open(
                `https://worker.8amdobae.co.kr/WorkOrder?sigongIdx=${idx}&sigongType=${type}&Looking=true`
            );
        }
    };
    useEffect(() => {
        if (OpenPop) {
            document.getElementsByTagName("HTML")[0]["style"]["overflow-y"] =
                "hidden";
        }

        return () => {
            document.getElementsByTagName("HTML")[0]["style"]["overflow-y"] =
                "scroll";
        };
    }, [OpenPop]);
    useEffect(() => {
        if (contentChange.length !== 0) {
            // console.log("contentChange", contentChange);
            setDobaeImgSetting(
                contentChange.file
                    .filter((item, i) => item.type === 1)
                    .map((item, i) => item.path)
            );
            setZangpanImgSetting(
                contentChange.file
                    .filter((item, i) => item.type === 2)
                    .map((item, i) => item.path)
            );
            setDobaeMemo(contentChange.dobae_as_memo);
            setDobaeDate(contentChange.dobae_as_date);
            setDobaeTime(contentChange.dobae_as_time);
            setDobaeWorkerMemo(contentChange.dobae_worker_memo);
            setDobaeASPrice(contentChange.dobae_as_price);
            setDobaeAdminMemo(contentChange.dobae_admin_memo);
            setZangpanAdminMemo(contentChange.zangpan_admin_memo);
            setDobaeMateList(
                contentChange?.mate.filter((c) => {
                    return c.material_type === 1;
                })
            );
            setDobaeAsSend(contentChange.dobae_as_send);
            setDobaeASSendCount(contentChange.dobae_as_send_count);
            setZangpanMemo(contentChange.zangpan_as_memo);
            setZangpanDate(contentChange.zangpan_as_date);
            setZangpanTime(contentChange.zangpan_as_time);
            setZangpanAsSend(contentChange.zangpan_as_send);
            setZangpanASSendCount(contentChange.zangpan_as_send_count);
            setZangpanWorkerMemo(contentChange.zangpan_worker_memo);
            setZangpanASPrice(contentChange.zangpan_as_price);
            setZangpanMateList(
                contentChange?.mate.filter((c) => {
                    return c.material_type === 2;
                })
            );
            setZangpanMopMateList(
                contentChange?.mate.filter((c) => {
                    return c.material_type === 4;
                })
            );

            // console.log("contentChange", contentChange);
        }
    }, [contentChange]);
    useEffect(() => {
        AddMaterial();
    }, [
        innerType,
        innerSelectCompany,
        innerSelectFirstCategory,
        innerSelectSecondCategory,
        materialSearchVal,
        innerPage,
        OpenPop,
    ]);
    useEffect(() => {
        filterPopupList?.map((item, index) => {
            setCompanyList((prev) =>
                prev.includes(item.material_company)
                    ? prev
                    : [...prev, item.material_company]
            );
        });

        filterPopupList?.map((item, index) => {
            setFirstCategory((prev) =>
                prev.includes(item.material_class01)
                    ? prev
                    : [...prev, item.material_class01]
            );
        });
        filterPopupList?.map((item, index) => {
            setSecondCategory((prev) =>
                prev.includes(item.material_class02)
                    ? prev
                    : [...prev, item.material_class02]
            );
        });

        return () => {
            setCompanyList(["제조사"]);
            setFirstCategory(["1차분류"]);
            setSecondCategory(["2차분류"]);
        };
    }, [filterPopupList]);
    useEffect(() => {
        asInfoDetailApi();
        asMsgListApi();
    }, [index]);

    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={
                    popUpFlag === 1
                        ? deleteDobaeMate
                        : popUpFlag === 2
                        ? deleteZangpanMate
                        : popUpFlag === 3
                        ? deleteZangpanSubMate
                        : null
                }
            />
            {OpenPop && (
                <div style={{ overflow: "auto" }} className={`popup show`}>
                    <div
                        className="popup_container"
                        style={{
                            width: "1000px",
                            minHeight: "600px",
                            overflow: "hidden",

                            justifyContent: "flex-start",
                        }}
                    >
                        <div
                            className="popup_title"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "0 20px",
                            }}
                        >
                            <p>자재 목록</p>
                            <div className="exit_btn" onClick={ClosePop}></div>
                        </div>
                        <div style={{ padding: "10px" }} className="popup_msg">
                            <div className="SelectBoxWrap">
                                <select
                                    // style={{ width: `${width}%` }}
                                    value={innerSelectCompany}
                                    onChange={(e) => {
                                        setInnerSelectCompany(e.target.value);
                                    }}
                                >
                                    {companyList?.map((x, i) => (
                                        <option key={i} value={x}>
                                            {x}
                                        </option>
                                    ))}
                                </select>
                                {innerSelectCompany !== "제조사" ? (
                                    <select
                                        // style={{ width: `${width}%` }}
                                        value={innerSelectFirstCategory}
                                        onChange={(e) => {
                                            setInnerSelectFirstCategory(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {firstCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}
                                {innerSelectFirstCategory !== "1차분류" ? (
                                    <select
                                        // style={{ width: `${width}%` }}
                                        value={innerSelectSecondCategory}
                                        onChange={(e) => {
                                            setInnerSelectSecondCategory(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {secondCategory?.map((x, i) => (
                                            <option key={i} value={x}>
                                                {x}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}
                                <Input
                                    className="input_text"
                                    type="text"
                                    placeholder="모델번호, 제품명"
                                    name="stx"
                                    value={materialSearchVal}
                                    setValue={setMaterialSearchVal}
                                    // style={{ width: "20%" }}
                                />
                                <button
                                    className="btn btn9"
                                    onClick={materialListSave}
                                >
                                    저장
                                </button>
                            </div>
                            <div
                                style={{ marginTop: "20px" }}
                                className="table_scroll"
                            >
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="FlexCenter">
                                                    <div
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            border: "1px solid #ddd",
                                                        }}
                                                    ></div>
                                                </div>
                                            </th>
                                            <th>종류</th>
                                            <th>제조사</th>
                                            <th>1차분류</th>
                                            <th>2차분류</th>
                                            <th>모델번호</th>
                                            <th>제품명</th>
                                            <th>제품이미지</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agencyMateListData?.map((x, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <input
                                                            checked={agencyValue
                                                                .map((c) =>
                                                                    parseInt(c)
                                                                )
                                                                .includes(
                                                                    x.idx
                                                                )}
                                                            value={x.idx}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            id={x.idx}
                                                            className="OneChkBox"
                                                            type="checkbox"
                                                        />
                                                        <label
                                                            htmlFor={x.idx}
                                                        />
                                                    </td>
                                                    <td>
                                                        {x.material_type === 1
                                                            ? "벽지"
                                                            : x.material_type ===
                                                              2
                                                            ? "장판"
                                                            : x.material_type ===
                                                              3
                                                            ? "마루"
                                                            : x.material_type ===
                                                              4
                                                            ? "걸레받이"
                                                            : x.material_type ===
                                                              5
                                                            ? "부자재"
                                                            : null}
                                                    </td>
                                                    <td>
                                                        {x.material_company}
                                                    </td>
                                                    <td>
                                                        {x.material_class01}
                                                    </td>
                                                    <td>
                                                        {x.material_class02}
                                                    </td>
                                                    <td>{x.material_number}</td>
                                                    <td>{x.material_name}</td>
                                                    <td>
                                                        {x.material_img ? (
                                                            <img
                                                                style={{
                                                                    height: "50px",
                                                                }}
                                                                src={`${consts.ImgState}${x.material_img}`}
                                                            />
                                                        ) : (
                                                            <p>이미지 없음</p>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={innerPage}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={agencyListCount || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                    </div>
                </div>
            )}
            <div className="content ">
                <TabComponent
                    index={tap?.counseling_idx}
                    estimate_idx={tap?.estimate_idx}
                    sigonginfo_idx={tap?.sigonginfo_idx}
                    order_idx={tap?.order_idx}
                    workend_idx={tap?.sigonginfo_idx}
                    as_tbl={index}
                />

                <BoxProgress
                    Radioes={consts.status3}
                    value={stateValue}
                    setValue={setStateValue}
                    isAs={true}
                    onClk={statusUpdate}
                />
                <HistoryBox
                    Info={history}
                    setContentChange={setContentChange}
                />

                <div className="BorderBox">
                    <div
                        className="title HistoryBox"
                        style={{ borderBottom: "1px solid #ccc" }}
                        onClick={() => setShowContent(!ShowContent)}
                    >
                        <div className="left">
                            <h1>본 시공 정보</h1>
                        </div>
                        <div className="right">
                            <svg
                                style={{
                                    transform: ShowContent
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                }}
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                    fill="#333333"
                                />
                            </svg>
                        </div>
                    </div>
                    {ShowContent && (
                        <div className="HistoryBoxcontent">
                            <div className="contentBorderWrap">
                                <BoxContent
                                    title="고객명"
                                    content={customerName || "-"}
                                />
                                <BoxContent
                                    title="연락처"
                                    content={customerPhone || "-"}
                                />
                                {asDobaechk == 1 && (
                                    <BoxContent
                                        title="도배 반장"
                                        content={dobaeAsManager || "-"}
                                    />
                                )}
                                {asZangpanchk == 1 && (
                                    <BoxContent
                                        title="장판 반장"
                                        content={zangpanAsManager || "-"}
                                    />
                                )}
                            </div>
                            {asDobaechk == 1 && (
                                <div className="title_color_box">
                                    <div
                                        className="box_title"
                                        style={{
                                            backgroundColor: "#41b06e",
                                            color: "white",
                                        }}
                                    >
                                        <h1>도배</h1>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="contentBorderWrap">
                                            <BoxContent
                                                title="시공일"
                                                content={sigongDobaeDate}
                                            />

                                            <BoxContent
                                                title="시공범위"
                                                content={`${
                                                    dobaeRange == 1
                                                        ? "전체"
                                                        : "일부"
                                                } ${
                                                    dobaeRangeMemo
                                                        ? `(${dobaeRangeMemo})`
                                                        : ""
                                                }`}
                                            />
                                            {/* <BoxContent
                                                    title="천장"
                                                    content={
                                                        dobaeCeiling == 1
                                                            ? "천장도배포함"
                                                            : "천장도배없음"
                                                    }
                                                /> */}

                                            <BoxContent
                                                title="벽지 종류"
                                                content={
                                                    dobaeProduct == 1
                                                        ? "실크"
                                                        : dobaeProduct == 2
                                                        ? "합지"
                                                        : "혼합"
                                                }
                                            />
                                            <BoxContent
                                                title="벽지 수"
                                                content={`${dobaeCnt}개`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {asZangpanchk == 1 && (
                                <div className="title_color_box">
                                    <div
                                        className="box_title"
                                        style={{
                                            backgroundColor: "#fb8500",
                                            color: "white",
                                        }}
                                    >
                                        <h1>장판</h1>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="contentBorderWrap">
                                            <BoxContent
                                                title="시공일"
                                                content={sigongZangpanDate}
                                            />

                                            <BoxContent
                                                title="시공범위"
                                                content={`${
                                                    zangpanRange == 1
                                                        ? "전체"
                                                        : "일부"
                                                } ${
                                                    zangpanRangeMemo
                                                        ? `(${zangpanRangeMemo})`
                                                        : ""
                                                }`}
                                            />

                                            <BoxContent
                                                title="장판"
                                                content={`${zangpanmm}`}
                                            />
                                            <BoxContent2
                                                title="하단몰딩"
                                                content1={`종류 : ${
                                                    zangpanMolding == 0
                                                        ? "없음"
                                                        : zangpanMolding == 1
                                                        ? zangpanMoldingMemo
                                                        : zangpanMolding == 2
                                                        ? "굽도리"
                                                        : "걸레받이"
                                                }`}
                                                content2={`범위 : ${
                                                    parseInt(
                                                        zangpanMoldingRange
                                                    ) == 2
                                                        ? "전체"
                                                        : zangpanMoldingRangeMemo
                                                        ? zangpanMoldingRangeMemo
                                                        : "-"
                                                }`}
                                            />

                                            {/* <BoxContent
                                                    title="문턱"
                                                    content={
                                                        zangpanThreshold == 1
                                                            ? "시공"
                                                            : "시공없음"
                                                    }
                                                /> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="BorderBox">
                    <div
                        className="title HistoryBox"
                        style={{ borderBottom: "1px solid #ccc" }}
                        onClick={() => setShowContent2(!ShowContent2)}
                    >
                        <div className="left">
                            <h1>A/S 요청 정보</h1>
                        </div>
                        <div className="right">
                            <svg
                                style={{
                                    transform: ShowContent2
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                }}
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                    fill="#333333"
                                />
                            </svg>
                        </div>
                    </div>
                    {ShowContent2 && (
                        <div className="HistoryBoxcontent">
                            <div className="contentBorderWrap">
                                <BoxContent
                                    title="요청 경로"
                                    content={
                                        requestPath === 0
                                            ? "고객 a/s 접수페이지 요청"
                                            : "관리자 페이지 요청건"
                                    }
                                />

                                <BoxContent
                                    title="요청일시"
                                    content={
                                        requestDate
                                            ? `${moment(requestDate).format(
                                                  "YYYY-MM-DD"
                                              )}(${
                                                  consts.dayText[
                                                      moment(requestDate).day()
                                                  ]
                                              })`
                                            : "-"
                                    }
                                />

                                <BoxContent
                                    title="시공구분"
                                    content={
                                        asDobaechk === 1 && asZangpanchk
                                            ? "도배 장판"
                                            : asDobaechk === 1
                                            ? "도배"
                                            : "장판"
                                    }
                                />
                                {history?.reduce((acc, x, i) => {
                                    if (i === history.length - 1) return acc; // 마지막 요소는 제외
                                    acc.push(
                                        <React.Fragment key={i}>
                                            {asDobaechk === 1 && (
                                                <BoxMemoInput
                                                    title={`도배 요청내용 ${moment(
                                                        x.create_dt
                                                    ).format(
                                                        "YYYY-MM-DD HH:mm"
                                                    )}`}
                                                    value={
                                                        x.dobae_as_memo || ""
                                                    }
                                                    readOnly={true}
                                                />
                                            )}
                                            {asZangpanchk === 1 && (
                                                <BoxMemoInput
                                                    title={`장판 요청내용 ${moment(
                                                        x.create_dt
                                                    ).format(
                                                        "YYYY-MM-DD HH:mm"
                                                    )}`}
                                                    value={
                                                        x.zangpan_as_memo || ""
                                                    }
                                                    readOnly={true}
                                                />
                                            )}
                                            {asDobaechk === 1 && (
                                                <div className="ImgInputWrap border-bot">
                                                    <div className="Imgtitle">
                                                        {`도배 이미지 업로드 ${moment(
                                                            x.create_dt
                                                        ).format(
                                                            "YYYY-MM-DD HH:mm"
                                                        )}`}
                                                    </div>
                                                    <div className="sub flex-al-center">
                                                        <div
                                                            style={{
                                                                display: "grid",
                                                                gridTemplateColumns:
                                                                    "repeat(4,1fr)",
                                                                gap: "10px",
                                                                justifyItems:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            {x.file
                                                                .filter(
                                                                    (item) =>
                                                                        item.type ===
                                                                        1
                                                                )
                                                                .map(
                                                                    (
                                                                        item,
                                                                        j
                                                                    ) => (
                                                                        <img
                                                                            style={{
                                                                                marginRight:
                                                                                    "10px",
                                                                                maxWidth:
                                                                                    "350px",
                                                                            }}
                                                                            key={
                                                                                j
                                                                            }
                                                                            src={`${consts.ImgState}${item.path}`}
                                                                        />
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {asZangpanchk === 1 && (
                                                <div className="ImgInputWrap border-bot">
                                                    <div className="Imgtitle">
                                                        {`장판 이미지 업로드 ${moment(
                                                            x.create_dt
                                                        ).format(
                                                            "YYYY-MM-DD HH:mm"
                                                        )}`}
                                                    </div>
                                                    <div className="sub flex-al-center">
                                                        <div
                                                            style={{
                                                                display: "grid",
                                                                gridTemplateColumns:
                                                                    "repeat(4,1fr)",
                                                                gap: "10px",
                                                                justifyItems:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            {x.file
                                                                .filter(
                                                                    (item) =>
                                                                        item.type ===
                                                                        2
                                                                )
                                                                .map(
                                                                    (
                                                                        item,
                                                                        j
                                                                    ) => (
                                                                        <img
                                                                            style={{
                                                                                marginRight:
                                                                                    "10px",
                                                                                maxWidth:
                                                                                    "350px",
                                                                            }}
                                                                            key={
                                                                                j
                                                                            }
                                                                            src={`${consts.ImgState}${item.path}`}
                                                                        />
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                    return acc;
                                }, [])}
                                {asDobaechk === 1 && (
                                    <BoxMemoInput
                                        title="도배 요청내용"
                                        value={dobaeMemo || ""}
                                        readOnly={true}
                                    />
                                )}
                                {asZangpanchk === 1 && (
                                    <BoxMemoInput
                                        title="장판 요청내용"
                                        value={zangpanMemo || ""}
                                        readOnly={true}
                                    />
                                )}
                                {asDobaechk === 1 && (
                                    <div
                                        className="ImgInputWrap border-bot"
                                        // style={{ border: "none", borderBottom: "1px solid #ddd" }}
                                    >
                                        <div
                                            onClick={() =>
                                                handleGalleryClick(1)
                                            }
                                            className="Imgtitle"
                                            style={{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                                color: "blue",
                                            }}
                                        >
                                            도배 이미지 업로드
                                        </div>
                                        <div
                                            className="sub flex-al-center"
                                            // style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <div className="contentPic">
                                                {dobaeImgSetting?.map(
                                                    (item, i) => (
                                                        <div
                                                            className="imgWrap"
                                                            key={i}
                                                        >
                                                            <img
                                                                className="uploadImg"
                                                                src={
                                                                    !item?.startsWith(
                                                                        "data:"
                                                                    )
                                                                        ? `${consts.ImgState}${item}`
                                                                        : item
                                                                }
                                                                alt="pic"
                                                            />
                                                            <img
                                                                className="del"
                                                                src={
                                                                    images.ImgDel
                                                                }
                                                                alt="delete"
                                                                onClick={() => {
                                                                    deleteImg(
                                                                        i,
                                                                        1
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {asZangpanchk === 1 && (
                                    <div
                                        className="ImgInputWrap border-bot"
                                        // style={{ border: "none", borderBottom: "1px solid #ddd" }}
                                    >
                                        <div
                                            onClick={() =>
                                                handleGalleryClick(2)
                                            }
                                            className="Imgtitle"
                                            style={{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                                color: "blue",
                                            }}
                                        >
                                            장판 이미지 업로드
                                        </div>
                                        <div
                                            className="sub flex-al-center"
                                            // style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <div className="contentPic">
                                                {zangpanImgSetting?.map(
                                                    (item, i) => (
                                                        <div
                                                            className="imgWrap"
                                                            key={i}
                                                        >
                                                            <img
                                                                className="uploadImg"
                                                                src={
                                                                    !item?.startsWith(
                                                                        "data:"
                                                                    )
                                                                        ? `${consts.ImgState}${item}`
                                                                        : item
                                                                }
                                                                alt="pic"
                                                            />
                                                            <img
                                                                className="del"
                                                                src={
                                                                    images.ImgDel
                                                                }
                                                                alt="delete"
                                                                onClick={() => {
                                                                    deleteImg(
                                                                        i,
                                                                        2
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="BorderBox">
                    <div
                        className="title HistoryBox"
                        style={{ borderBottom: "1px solid #ccc" }}
                        onClick={() => setShowContent3(!ShowContent3)}
                    >
                        <div className="left">
                            <h1>A/S 작업정보</h1>
                        </div>
                        <div className="right">
                            <svg
                                style={{
                                    transform: ShowContent3
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                }}
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                    fill="#333333"
                                />
                            </svg>
                        </div>
                    </div>
                    {ShowContent3 && (
                        <div className="HistoryBoxcontent">
                            <div className="contentBorderWrap">
                                {asDobaechk === 1 && (
                                    <div className="title_color_box">
                                        <div
                                            className="box_title"
                                            style={{
                                                backgroundColor: "#41b06e",
                                                color: "white",
                                            }}
                                        >
                                            <h1>도배</h1>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="subTitleCircle">
                                                <div className="GreenCircle"></div>
                                                <h3>시공일 및 인력 배정</h3>
                                            </div>
                                            <div className="contentBorderWrap">
                                                <BoxDateInput
                                                    startvalue={dobaeDate}
                                                    setStartValue={setDobaeDate}
                                                    title="시공일"
                                                />
                                                <BoxDropdownTime
                                                    title="시공시간"
                                                    value={dobaeTime}
                                                    setValue={setDobaeTime}
                                                    directInput={
                                                        dobaeTimeDinput
                                                    }
                                                    setDirectInput={
                                                        setDobaeTimeDinput
                                                    }
                                                />

                                                <BoxButtons
                                                    title="작업지시서"
                                                    IsSend={
                                                        dobaeAsSend === 0
                                                            ? "미확인"
                                                            : "확인"
                                                    }
                                                    OpenUrl={() =>
                                                        OpenUrl({
                                                            SendType: 4,
                                                            idx: tap.idx,
                                                            type: 1,
                                                        })
                                                    }
                                                    gbtn={true}
                                                    bbtn={true}
                                                    bbtnNum={
                                                        dobaeASSendCount || 0
                                                    }
                                                    bbtnClk={() =>
                                                        SendMsgClk(1)
                                                    }
                                                />
                                                <BoxMemoInput
                                                    title="A/S 작업자 전달사항"
                                                    value={dobaeWorkerMemo}
                                                    setValue={
                                                        setDobaeWorkerMemo
                                                    }
                                                />
                                                {/* <BoxTextInput
                                                    title="A/S 작업자 전달사항"
                                                    value={dobaeMemo}
                                                    setValue={setDobaeMemo}
                                                    inputWidth={80}
                                                /> */}
                                                <BoxTextInput
                                                    title="A/S 비용"
                                                    value={dobaeASPrice}
                                                    setValue={setDobaeASPrice}
                                                    inputWidth={20}
                                                    unit="원"
                                                />
                                            </div>
                                            <p
                                                style={{
                                                    textDecoration: "underline",
                                                    fontWeight: "700",
                                                    marginTop: "20px ",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                A/S 작업시 자재 주문이 필요한
                                                경우에만 자재와 수량을 입력해
                                                주세요.
                                            </p>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="subTitleCircle">
                                                <div className="GreenCircle"></div>
                                                <h3>선택벽지</h3>
                                            </div>

                                            <ModelNumTableEdit
                                                list={dobaeMateList}
                                                setList={setDobaeMateList}
                                                ClkBtn={btnDobaePop}
                                                setFlag={setPopupFlag}
                                                FlagValue={1}
                                                setDeleteIdx={
                                                    setMaterialDeleteIdx
                                                }
                                                setDeletePop={setDeletePop}
                                                deletePop={deletePop}
                                            />
                                            <div
                                                className="contentBorderWrap"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <BoxMemoInput
                                                    title="A/S 도배 메모"
                                                    value={dobaeAdminMemo}
                                                    setValue={setDobaeAdminMemo}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {asZangpanchk === 1 && (
                                    <div className="title_color_box">
                                        <div
                                            className="box_title"
                                            style={{
                                                backgroundColor: "#fb8500",
                                                color: "white",
                                            }}
                                        >
                                            <h1>장판</h1>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="subTitleCircle">
                                                <div className="GreenCircle"></div>
                                                <h3>시공일 및 인력 배정</h3>
                                            </div>
                                            <div className="contentBorderWrap">
                                                <BoxDateInput
                                                    startvalue={zangpanDate}
                                                    setStartValue={
                                                        setZangpanDate
                                                    }
                                                    title="시공일"
                                                />
                                                <BoxDropdownTime
                                                    title="시공시간"
                                                    value={zangpanTime}
                                                    setValue={setZangpanTime}
                                                    directInput={
                                                        zangpanTimeDinput
                                                    }
                                                    setDirectInput={
                                                        setZangpanTimeDinput
                                                    }
                                                />

                                                <BoxButtons
                                                    title="작업지시서"
                                                    IsSend={
                                                        zangpanAsSend === 0
                                                            ? "미확인"
                                                            : "확인"
                                                    }
                                                    OpenUrl={() =>
                                                        OpenUrl({
                                                            SendType: 4,
                                                            idx: tap.idx,
                                                            type: 2,
                                                        })
                                                    }
                                                    gbtn={true}
                                                    bbtn={true}
                                                    bbtnNum={
                                                        zangpanASSendCount || 0
                                                    }
                                                    bbtnClk={() =>
                                                        SendMsgClk(2)
                                                    }
                                                />
                                                <BoxTextInput
                                                    title="A/S 비용"
                                                    value={zangpanASPrice}
                                                    setValue={setZangpanASPrice}
                                                    inputWidth={20}
                                                    unit="원"
                                                />
                                                <BoxMemoInput
                                                    title="A/S 작업자 전달사항"
                                                    value={zangpanWorkerMemo}
                                                    setValue={
                                                        setZangpanWorkerMemo
                                                    }
                                                />
                                                {/* <BoxTextInput
                                                    title="A/S작업자 전달사항"
                                                    value={zangpanMemo}
                                                    setValue={setZangpanMemo}
                                                    inputWidth={80}
                                                /> */}
                                            </div>
                                            <p
                                                style={{
                                                    textDecoration: "underline",
                                                    fontWeight: "700",
                                                    marginTop: "20px ",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                A/S 작업시 자재 주문이 필요한
                                                경우에만 자재와 수량을 입력해
                                                주세요.
                                            </p>
                                        </div>
                                        <div className="contentColorWrap">
                                            <div className="subTitleCircle">
                                                <div className="GreenCircle"></div>
                                                <h3>선택 제품</h3>
                                            </div>
                                            <ModelNumTableEdit2
                                                list={zangpanMateList}
                                                setList={setZangpanMateList}
                                                ClkBtn={btnZangpanPop}
                                                setFlag={setPopupFlag}
                                                FlagValue={2}
                                                setDeleteIdx={
                                                    setMaterialDeleteIdx
                                                }
                                                setDeletePop={setDeletePop}
                                                deletePop={deletePop}
                                            />
                                            <div className="subTitleCircle">
                                                <div className="GreenCircle"></div>
                                                <h3>걸레받이</h3>
                                            </div>
                                            <ModelNumTableEdit3
                                                list={zangpanMopMateList}
                                                setList={setZangpanMopMateList}
                                                ClkBtn={btnZangpanBaseBoardPop}
                                                setFlag={setPopupFlag}
                                                FlagValue={3}
                                                setDeleteIdx={
                                                    setMaterialDeleteIdx
                                                }
                                                setDeletePop={setDeletePop}
                                                deletePop={deletePop}
                                            />
                                            <div
                                                className="contentBorderWrap"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <BoxMemoInput
                                                    title="A/S 장판 메모"
                                                    value={zangpanAdminMemo}
                                                    setValue={
                                                        setZangpanAdminMemo
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* <div className="title_color_box">
                                    <div
                                        className="box_title"
                                        style={{
                                            backgroundColor: "#caf0f8",
                                        }}
                                    >
                                        <h1>마루</h1>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>시공일 및 인력 배정</h3>
                                        </div>
                                        <div className="contentBorderWrap">
                                            <BoxDateInput
                                                startvalue={maruDate}
                                                setStartValue={setMaruDate}
                                                title="시공일"
                                            />
                                            <BoxDropdownTime
                                                title="시공시간"
                                                value={maruTime}
                                                setValue={setMaruTime}
                                                directInput={maruTimeDinput}
                                                setDirectInput={
                                                    setMaruTimeDinput
                                                }
                                            />
                                            <BoxButtons
                                                title="작업지시서"
                                                IsSend="미발송"
                                                gbtn={true}
                                                bbtn={1}
                                                sbtn={true}
                                            />

                                            <BoxTextInput
                                                title="작업자 전달사항"
                                                value={maruMemo}
                                                setValue={setMaruMemo}
                                                inputWidth={80}
                                            />
                                        </div>
                                        <p
                                            style={{
                                                textDecoration: "underline",
                                                fontWeight: "700",
                                                marginTop: "20px ",
                                                fontSize: "18px",
                                            }}
                                        >
                                            A/S 작업시 자재 주문이 필요한
                                            경우에만 자재와 수량을 입력해
                                            주세요.
                                        </p>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>바닥재</h3>
                                        </div>
                                        <ModelNumTableEdit2
                                            list={maruMateList}
                                            setList={setMaruMateList}
                                            ClkBtn={btnMaruPop}
                                            setFlag={setPopupFlag}
                                            FlagValue={7}
                                            setDeleteIdx={setMaterialDeleteIdx}
                                            setDeletePop={setDeletePop}
                                            deletePop={deletePop}
                                        />
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>걸레받이</h3>
                                        </div>
                                        <ModelNumTableEdit3
                                            list={maruMopMateList}
                                            setList={setMaruMopMateList}
                                            ClkBtn={btnMaruBaseBoardPop}
                                            setFlag={setPopupFlag}
                                            FlagValue={8}
                                            setDeleteIdx={setMaterialDeleteIdx}
                                            setDeletePop={setDeletePop}
                                            deletePop={deletePop}
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    )}
                </div>
                {/* <div className="BorderBox">
                    <div
                        className="title HistoryBox"
                        style={{ borderBottom: "1px solid #ccc" }}
                        onClick={() => setShowContent4(!ShowContent4)}
                    >
                        <div className="left">
                            <h1>A/S 작업 처리 정보</h1>
                        </div>
                        <div className="right">
                            <svg
                                style={{
                                    transform: ShowContent4
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                }}
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.46967 9.46967C6.76256 9.17678 7.23744 9.17678 7.53033 9.46967L12 13.9393L16.4697 9.46967C16.7626 9.17678 17.2374 9.17678 17.5303 9.46967C17.8232 9.76256 17.8232 10.2374 17.5303 10.5303L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.46967 10.5303C6.17678 10.2374 6.17678 9.76256 6.46967 9.46967Z"
                                    fill="#333333"
                                />
                            </svg>
                        </div>
                    </div>
                    {ShowContent4 && (
                        <div className="HistoryBoxcontent">
                            <div className="contentBorderWrap">
                                <div className="title_color_box">
                                    <div
                                        className="box_title"
                                        style={{
                                            backgroundColor: "#41b06e",
                                            color: "white",
                                        }}
                                    >
                                        <h1>도배</h1>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="contentBorderWrap">
                                            <BoxContent
                                                title="시공일"
                                                content={
                                                    dobaeSigongDate
                                                        ? `${moment(
                                                              dobaeSigongDate
                                                          ).format(
                                                              "YYYY-MM-DD"
                                                          )}(${
                                                              consts.dayText[
                                                                  moment(
                                                                      dobaeSigongDate
                                                                  ).day()
                                                              ]
                                                          })`
                                                        : "-"
                                                }
                                            />
                                            <BoxButtons
                                                title="종료리포트"
                                                gbtn={true}
                                                bbtn={1}
                                            />
                                        </div>
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>지출내역</h3>
                                        </div>
                                        <TableExpenseRegister
                                            list={dobaeExpenseDetail}
                                            setList={setDobaeExpenseDetail}
                                            setDeleteidx={
                                                setDobaeDeleteExpenseThing
                                            }
                                            setDeletePop={setDeletePop}
                                            setPopupFlag={setPopupFlag}
                                            flagNum={1}
                                        />
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>작업리포트</h3>
                                        </div>
                                        <div className="contentBorderWrap">
                                            <BoxContent
                                                title="작업자"
                                                content={`${
                                                    dobaeEndWorker?.length
                                                }명 (${dobaeEndWorker?.join(
                                                    ", "
                                                )})`}
                                            />
                                            <BoxContent
                                                title="제출시간"
                                                content={
                                                    dobaeEndDate
                                                        ? `${moment(
                                                              dobaeEndDate
                                                          ).format(
                                                              "YYYY-MM-DD HH:mm"
                                                          )}(변경시간 : ${dobaeChangeTime.map(
                                                              (c) => {
                                                                  return `${moment(
                                                                      c
                                                                  ).format(
                                                                      "YYYY-MM-DD HH:mm"
                                                                  )}`;
                                                              }
                                                          )})`
                                                        : "-"
                                                }
                                            />
                                            <BoxContent
                                                title="제출시간"
                                                content={
                                                    dobaeEndDate
                                                        ? `${moment(
                                                              dobaeEndDate
                                                          ).format(
                                                              "YYYY-MM-DD"
                                                          )}(${
                                                              consts.dayText[
                                                                  moment(
                                                                      dobaeEndDate
                                                                  ).day()
                                                              ]
                                                          })`
                                                        : "-"
                                                }
                                            />
                                            <BoxLookImg
                                                title="완료 사진"
                                                img={dobaeCompleteImg}
                                            />
                                            <BoxLookImg
                                                title="영수증 사진"
                                                img={dobaeReciptImg}
                                            />
                                            <BoxContent
                                                title="작업자 메모"
                                                content={dobaeEndMemo || "-"}
                                            />
                                        </div>
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>벽지</h3>
                                        </div>
                                        <TableEndWallPaper
                                            list={dobaeWallpaperlist}
                                            setList={setDobaeWallpaperlist}
                                            isASmanage={false}
                                        />
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>기타비용</h3>
                                        </div>
                                        <TableEndOtherCostEdit
                                            list={dobaeOtherCostList}
                                            setList={setDobaeOtherCostList}
                                            setDeleteidx={
                                                setDobaeOtherDeleteIdx
                                            }
                                            setDeletePop={setDeletePop}
                                            setPopupFlag={setPopupFlag}
                                            flagNum={2}
                                        />
                                    </div>
                                </div>

                                <div className="title_color_box">
                                    <div
                                        className="box_title"
                                        style={{
                                            backgroundColor: "#fb8500",
                                            color: "white",
                                        }}
                                    >
                                        <h1>장판</h1>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="contentBorderWrap">
                                            <BoxContent
                                                title="시공일"
                                                content={
                                                    zangpanSigongDate
                                                        ? `${moment(
                                                              zangpanSigongDate
                                                          ).format(
                                                              "YYYY-MM-DD"
                                                          )}(${
                                                              consts.dayText[
                                                                  moment(
                                                                      zangpanSigongDate
                                                                  ).day()
                                                              ]
                                                          })`
                                                        : "-"
                                                }
                                            />
                                            <BoxButtons
                                                title="종료리포트"
                                                gbtn={true}
                                                bbtn={1}
                                            />
                                        </div>
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>지출내역</h3>
                                        </div>
                                        <TableExpenseRegister
                                            list={zangpanExpenseDetail}
                                            setList={setzangpanExpenseDetail}
                                            setDeleteidx={
                                                setZangpanDeleteExpenseThing
                                            }
                                            setDeletePop={setDeletePop}
                                            setPopupFlag={setPopupFlag}
                                            flagNum={3}
                                        />
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>작업리포트</h3>
                                        </div>
                                        <div className="contentBorderWrap">
                                            <BoxContent
                                                title="작업자"
                                                content={`${
                                                    zangpanEndWorker?.length
                                                }명 (${zangpanEndWorker?.join(
                                                    ", "
                                                )})`}
                                            />
                                            <BoxContent
                                                title="제출시간"
                                                content={
                                                    zangpanEndDate
                                                        ? `${moment(
                                                              zangpanEndDate
                                                          ).format(
                                                              "YYYY-MM-DD HH:mm"
                                                          )}(변경시간 : ${zangpanChangeTime.map(
                                                              (c) => {
                                                                  return `${moment(
                                                                      c
                                                                  ).format(
                                                                      "YYYY-MM-DD HH:mm"
                                                                  )}`;
                                                              }
                                                          )})`
                                                        : "-"
                                                }
                                            />

                                            <BoxLookImg
                                                title="완료 사진"
                                                img={zangpanCompleteImg}
                                            />
                                            <BoxLookImg
                                                title="영수증 사진"
                                                img={zangpanReciptImg}
                                            />
                                            <BoxContent
                                                title="작업자 메모"
                                                content={zangpanEndMemo || "-"}
                                            />
                                        </div>
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>장판</h3>
                                        </div>
                                        <TableEndFlooring
                                            list={zangpanList}
                                            setList={setZangpanList}
                                        />
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>걸래받이</h3>
                                        </div>
                                        <TableEndBaseboard
                                            list={zangpanBaseboardList}
                                            setList={setZangpanBaseboardList}
                                        />
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>기타비용</h3>
                                        </div>
                                        <TableEndOtherCostEdit
                                            list={zangpanOtherCostList}
                                            setList={setZangpanOtherCostList}
                                            setDeleteidx={
                                                setZangpanOtherDeleteIdx
                                            }
                                            setDeletePop={setDeletePop}
                                            setPopupFlag={setPopupFlag}
                                            flagNum={4}
                                        />
                                    </div>
                                </div>
                                <div className="title_color_box">
                                    <div
                                        className="box_title"
                                        style={{
                                            backgroundColor: "#caf0f8",
                                        }}
                                    >
                                        <h1>마루</h1>
                                    </div>
                                    <div className="contentColorWrap">
                                        <div className="contentBorderWrap">
                                            <BoxContent
                                                title="시공일"
                                                content={
                                                    maruSigongDate
                                                        ? `${moment(
                                                              maruSigongDate
                                                          ).format(
                                                              "YYYY-MM-DD"
                                                          )}(${
                                                              consts.dayText[
                                                                  moment(
                                                                      maruSigongDate
                                                                  ).day()
                                                              ]
                                                          })`
                                                        : "-"
                                                }
                                            />
                                            <BoxButtons
                                                title="종료리포트"
                                                gbtn={true}
                                                bbtn={1}
                                            />
                                        </div>
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>지출내역</h3>
                                        </div>
                                        <TableExpenseRegister
                                            list={maruExpenseDetail}
                                            setList={setMaruExpenseDetail}
                                            setDeleteidx={
                                                setMaruDeleteExpenseThing
                                            }
                                            setDeletePop={setDeletePop}
                                            setPopupFlag={setPopupFlag}
                                            flagNum={4}
                                        />
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>작업리포트</h3>
                                        </div>
                                        <div className="contentBorderWrap">
                                            <BoxContent
                                                title="작업자"
                                                content={`${
                                                    maruEndWorker?.length
                                                }명 (${maruEndWorker?.join(
                                                    ", "
                                                )})`}
                                            />
                                            <BoxContent
                                                title="제출시간"
                                                content={
                                                    maruEndDate
                                                        ? `${moment(
                                                              maruEndDate
                                                          ).format(
                                                              "YYYY-MM-DD HH:mm"
                                                          )}(변경시간 : ${maruChangeTime.map(
                                                              (c) => {
                                                                  return `${moment(
                                                                      c
                                                                  ).format(
                                                                      "YYYY-MM-DD HH:mm"
                                                                  )}`;
                                                              }
                                                          )})`
                                                        : "-"
                                                }
                                            />
                                            <BoxLookImg
                                                title="완료 사진"
                                                img={maruCompleteImg}
                                            />
                                            <BoxLookImg
                                                title="영수증 사진"
                                                img={maruReciptImg}
                                            />
                                            <BoxContent
                                                title="작업자 메모"
                                                content={maruEndMemo || "-"}
                                            />
                                        </div>
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>마루</h3>
                                        </div>
                                        <TableEndFlooring
                                            list={maruList}
                                            setList={setMaruList}
                                        />
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>걸래받이</h3>
                                        </div>
                                        <TableEndBaseboard
                                            list={maruBaseboardList}
                                            setList={setMaruBaseboardList}
                                        />
                                        <div className="subTitleCircle">
                                            <div className="GreenCircle"></div>
                                            <h3>기타비용</h3>
                                        </div>
                                        <TableEndOtherCostEdit
                                            list={maruOtherCostList}
                                            setList={setMaruOtherCostList}
                                            setDeleteidx={setMaruOtherDeleteIdx}
                                            setDeletePop={setDeletePop}
                                            setPopupFlag={setPopupFlag}
                                            flagNum={5}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div> */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                        margin: "20px 0",
                    }}
                >
                    <button className="btn btn9" onClick={SubmitClk}>
                        변경
                    </button>
                </div>
                <HistoryMessageBox
                    IsWorker={true}
                    Info={workerMsg}
                    page={workerMsgPage}
                    handlePageChange={handleWorkerMsgPageChange}
                    totalCount={workerMsgTotal}
                />
            </div>
        </>
    );
}
export default ASManageRegister;
