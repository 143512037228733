import { useEffect, useState } from "react";
import {
    ConstManageTable,
    EstimateTable,
    ReqConsultTable,
    TableASManagement,
    TableJobFinished,
    TableOrderManagement,
} from "../../components/Tables";
import { usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import { set } from "lodash";
import { PopupComp } from "../../components/PopUp";

function DeleteList() {
    const counselingDeleteList = usePostData(ApiData.counselingDeleteList);
    const counselingRestore = usePostData(ApiData.counselingRestore);
    const counselingClear = usePostData(ApiData.counselingClear);

    const estimateDeleteList = usePostData(ApiData.estimateDeleteList);
    const estimateRestore = usePostData(ApiData.estimateRestore);
    const estimateClear = usePostData(ApiData.estimateClear);

    const sigongDeleteInfoList = usePostData(ApiData.sigongDeleteInfoList);
    const sigongRestore = usePostData(ApiData.sigongRestore);
    const sigongClear = usePostData(ApiData.sigongClear);

    const orderDeleteList = usePostData(ApiData.orderDeleteList);
    const OrderRestore = usePostData(ApiData.OrderRestore);
    const OrderClear = usePostData(ApiData.OrderClear);

    const asInfoDeleteList = usePostData(ApiData.asInfoDeleteList);
    const asInfoRestore = usePostData(ApiData.asInfoRestore);
    const asInfoClear = usePostData(ApiData.asInfoClear);

    const [counselingData, setCounselingData] = useState([]);
    const [counselingValues, setCounselingValues] = useState([]);

    const [estimateData, setEstimateData] = useState([]);
    const [estimateValues, setEstimateValues] = useState([]);

    const [sigongData, setSigongData] = useState([]);
    const [sigongValues, setSigongValues] = useState([]);

    const [orderData, setOrderData] = useState([]);
    const [orderValues, setOrderValues] = useState([]);

    const [asInfoData, setAsInfoData] = useState([]);
    const [asInfoValues, setAsInfoValues] = useState([]);

    const [openPop, setOpenPop] = useState(false);
    const [page, setPage] = useState(1);
    const [popupType, setPopupType] = useState(1);
    const [submitType, setSubmitType] = useState(1);

    const [step, setStep] = useState(1);
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    //상담신청 api
    const counselingDeleteListApi = () => {
        let sender = {
            page: page - 1,
        };
        counselingDeleteList
            .mutateAsync(sender)
            .then(({ data }) => {
                setCounselingData(data);
            })
            .catch((error) => {});
    };
    const counselingRestoreApi = () => {
        let sender = {
            counselingIdx: counselingValues,
        };
        counselingRestore
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("복원되었습니다.");
                counselingDeleteListApi();
                setCounselingValues([]);
            })
            .catch((error) => {
                toast.error("복원에 실패했습니다.");
            });
    };
    const counselingClearApi = () => {
        let sender = {
            counselingIdx: counselingValues,
        };
        counselingClear
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                counselingDeleteListApi();
                setCounselingValues([]);
            })
            .catch((error) => {
                toast.error("삭제에 실패했습니다.");
            });
    };
    // 견적상담 api

    const estimateDeleteListApi = () => {
        let sender = {
            page: page - 1,
        };
        estimateDeleteList
            .mutateAsync(sender)
            .then(({ data }) => {
                setEstimateData(data);
                // console.log(data);
            })
            .catch((error) => {});
    };

    const estimateRestoreApi = () => {
        let sender = {
            estimateIdx: estimateValues,
        };
        // console.log("sender", sender);
        estimateRestore
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("복원되었습니다.");
                estimateDeleteListApi();
                setEstimateValues([]);
            })
            .catch((error) => {
                toast.error("복원에 실패했습니다.");
            });
    };
    const estimateClearApi = () => {
        let sender = {
            estimateIdx: estimateValues,
        };
        estimateClear
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                estimateDeleteListApi();
                setEstimateValues([]);
            })
            .catch((error) => {
                toast.error("삭제에 실패했습니다.");
            });
    };

    //시공관리
    const sigongDeleteInfoListApi = () => {
        let sender = {
            page: page - 1,
        };
        sigongDeleteInfoList
            .mutateAsync(sender)
            .then(({ data }) => {
                setSigongData(data);
            })
            .catch((error) => {});
    };
    const sigongRestoreApi = () => {
        let sender = {
            sigongInfoIdx: sigongValues,
        };
        // console.log("sender", sender);
        sigongRestore
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("복원되었습니다.");
                sigongDeleteInfoListApi();
                setSigongValues([]);
            })
            .catch((error) => {
                toast.error("복원에 실패했습니다.");
            });
    };
    const sigongClearApi = () => {
        let sender = {
            sigongInfoIdx: sigongValues,
        };
        sigongClear
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                sigongDeleteInfoListApi();
                setSigongValues([]);
            })
            .catch((error) => {
                toast.error("삭제에 실패했습니다.");
            });
    };

    //주문관리
    const orderDeleteListApi = () => {
        let sender = {
            page: page - 1,
        };
        orderDeleteList
            .mutateAsync(sender)
            .then(({ data }) => {
                setOrderData(data);
            })
            .catch((error) => {});
    };
    const OrderRestoreApi = () => {
        let sender = {
            orderIdx: orderValues,
        };
        OrderRestore.mutateAsync(sender)
            .then(({ data }) => {
                toast.success("복원되었습니다.");
                orderDeleteListApi();
                setOrderValues([]);
            })
            .catch((error) => {
                toast.error("복원에 실패했습니다.");
            });
    };
    const OrderClearApi = () => {
        let sender = {
            orderIdx: orderValues,
        };
        OrderClear.mutateAsync(sender)
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                orderDeleteListApi();
                setOrderValues([]);
            })
            .catch((error) => {
                toast.error("삭제에 실패했습니다.");
            });
    };

    const asInfoDeleteListApi = () => {
        let sender = {
            page: page - 1,
        };
        asInfoDeleteList
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log(data);
                setAsInfoData(data);
            })
            .catch((error) => {});
    };
    const asInfoRestoreApi = () => {
        let sender = {
            asIdx: asInfoValues,
        };
        asInfoRestore
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("복원되었습니다.");
                asInfoDeleteListApi();
                setAsInfoValues([]);
            })
            .catch((error) => {
                toast.error("복원에 실패했습니다.");
            });
    };
    const asInfoClearApi = () => {
        let sender = {
            asIdx: asInfoValues,
        };
        asInfoClear
            .mutateAsync(sender)
            .then(({ data }) => {
                toast.success("삭제되었습니다.");
                asInfoDeleteListApi();
                setAsInfoValues([]);
            })
            .catch((error) => {
                toast.error("삭제에 실패했습니다.");
            });
    };

    const OnRecoverClk = (type) => {
        if (type === 1) {
            if (counselingValues.length === 0) {
                toast.error("복원할 항목을 선택해주세요.");
                return;
            }
            setPopupType(1);
            setSubmitType(type);
            setOpenPop(true);
        } else if (type === 3) {
            if (estimateValues.length === 0) {
                toast.error("복원할 항목을 선택해주세요.");
                return;
            }
            setPopupType(1);
            setSubmitType(type);
            setOpenPop(true);
        } else if (type === 5) {
            if (sigongValues.length === 0) {
                toast.error("복원할 항목을 선택해주세요.");
                return;
            }
            setPopupType(1);
            setSubmitType(type);
            setOpenPop(true);
        } else if (type === 7) {
            if (orderValues.length === 0) {
                toast.error("복원할 항목을 선택해주세요.");
                return;
            }
            setPopupType(1);
            setSubmitType(type);
            setOpenPop(true);
        } else if (type === 9) {
            if (asInfoValues.length === 0) {
                toast.error("복원할 항목을 선택해주세요.");
                return;
            }
            setPopupType(1);
            setSubmitType(type);
            setOpenPop(true);
        }
    };
    const OnDeleteClk = (type) => {
        if (type === 2) {
            if (counselingValues.length === 0) {
                toast.error("삭제할 항목을 선택해주세요.");
                return;
            }
            setPopupType(2);
            setSubmitType(type);
            setOpenPop(true);
        } else if (type === 4) {
            if (estimateValues.length === 0) {
                toast.error("삭제할 항목을 선택해주세요.");
                return;
            }
            setPopupType(2);
            setSubmitType(type);
            setOpenPop(true);
        } else if (type === 6) {
            if (sigongValues.length === 0) {
                toast.error("삭제할 항목을 선택해주세요.");
                return;
            }
            setPopupType(2);
            setSubmitType(type);
            setOpenPop(true);
        } else if (type === 8) {
            if (orderValues.length === 0) {
                toast.error("삭제할 항목을 선택해주세요.");
                return;
            }
            setPopupType(2);
            setSubmitType(type);
            setOpenPop(true);
        } else if (type === 10) {
            if (asInfoValues.length === 0) {
                toast.error("삭제할 항목을 선택해주세요.");
                return;
            }
            setPopupType(2);
            setSubmitType(type);
            setOpenPop(true);
        }
    };
    const SubmitData = (type) => {
        if (type === 1) {
            counselingRestoreApi();
            setOpenPop(false);
        } else if (type === 2) {
            counselingClearApi();
            setOpenPop(false);
        } else if (type === 3) {
            estimateRestoreApi();
            setOpenPop(false);
        } else if (type === 4) {
            estimateClearApi();
            setOpenPop(false);
        } else if (type === 5) {
            sigongRestoreApi();
            setOpenPop(false);
        } else if (type === 6) {
            sigongClearApi();
            setOpenPop(false);
        } else if (type === 7) {
            OrderRestoreApi();
            setOpenPop(false);
        } else if (type === 8) {
            OrderClearApi();
            setOpenPop(false);
        } else if (type === 9) {
            asInfoRestoreApi();
            setOpenPop(false);
        } else if (type === 10) {
            asInfoClearApi();
            setOpenPop(false);
        }
    };
    useEffect(() => {
        counselingDeleteListApi();
        estimateDeleteListApi();
        sigongDeleteInfoListApi();
        orderDeleteListApi();
        asInfoDeleteListApi();
    }, [page, step]);

    // useEffect(() => {
    //     console.log("sigongValues", sigongValues);
    // }, [sigongValues]);
    return (
        <>
            <PopupComp
                popTitle={popupType === 1 ? "복원" : "삭제"}
                popContent={
                    popupType === 1
                        ? "복원하시겠습니까?"
                        : "삭제하시면 복원이 불가합니다.\n그래도 삭제하시겠습니까?"
                }
                OpenPop={openPop}
                setOpenPop={setOpenPop}
                SubmitClk={() => SubmitData(submitType)}
            />
            <div className="content ">
                <p className="title">삭제목록</p>
                <div className="pop_progress">
                    <p
                        className={step === 1 ? "active" : ""}
                        onClick={() => {
                            setPage(1);
                            setStep(1);
                        }}
                    >
                        상담신청
                    </p>
                    <p
                        className={step === 2 ? "active" : ""}
                        onClick={() => {
                            setPage(1);
                            setStep(2);
                        }}
                    >
                        견적상담
                    </p>
                    <p
                        className={step === 3 ? "active" : ""}
                        onClick={() => {
                            setPage(1);
                            setStep(3);
                        }}
                    >
                        시공관리
                    </p>
                    <p
                        className={step === 4 ? "active" : ""}
                        onClick={() => {
                            setPage(1);
                            setStep(4);
                        }}
                    >
                        주문관리
                    </p>

                    <p
                        className={step === 5 ? "active" : ""}
                        onClick={() => {
                            setPage(1);
                            setStep(5);
                        }}
                    >
                        AS관리
                    </p>
                </div>
                {step === 1 && (
                    <div>
                        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                            [{counselingData?.count}건]
                        </p>
                        <ReqConsultTable
                            isDelete={true}
                            Data={counselingData?.rows}
                            values={counselingValues}
                            setValues={setCounselingValues}
                            setDeletePop={setOpenPop}
                            deletePop={openPop}
                            setPopupType={setPopupType}
                        />
                        <div className="DeleteBtnWrap">
                            <div
                                className="ReviveBtn FlexCenter"
                                onClick={() => {
                                    OnRecoverClk(1);
                                }}
                            >
                                복원하기
                            </div>
                            <div
                                className="DeleteBtn FlexCenter"
                                onClick={() => {
                                    OnDeleteClk(2);
                                }}
                            >
                                삭제하기
                            </div>
                        </div>

                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={page}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={counselingData?.count || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div>
                        {" "}
                        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                            [{estimateData?.count || 0}건]
                        </p>
                        <EstimateTable
                            isDelete={true}
                            Info={estimateData.rows}
                            values={estimateValues}
                            setValues={setEstimateValues}
                        />
                        <div className="DeleteBtnWrap">
                            <div
                                className="ReviveBtn FlexCenter"
                                onClick={() => {
                                    OnRecoverClk(3);
                                }}
                            >
                                복원하기
                            </div>
                            <div
                                className="DeleteBtn FlexCenter"
                                onClick={() => {
                                    OnDeleteClk(4);
                                }}
                            >
                                삭제하기
                            </div>
                        </div>
                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={page}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={estimateData?.count || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div>
                        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                            [{sigongData?.count || 0}건]
                        </p>
                        <ConstManageTable
                            isDelete={true}
                            dataList={sigongData.rows}
                            values={sigongValues}
                            setValues={setSigongValues}
                        />
                        <div className="DeleteBtnWrap">
                            <div
                                className="ReviveBtn FlexCenter"
                                onClick={() => {
                                    OnRecoverClk(5);
                                }}
                            >
                                복원하기
                            </div>
                            <div
                                className="DeleteBtn FlexCenter"
                                onClick={() => {
                                    OnDeleteClk(6);
                                }}
                            >
                                삭제하기
                            </div>
                        </div>
                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={page}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={sigongData?.count || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div>
                        {" "}
                        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                            [{orderData?.count || 0}건]
                        </p>
                        <TableOrderManagement
                            isDelete={true}
                            list={orderData?.rows}
                            values={orderValues}
                            setValues={setOrderValues}
                            agencyList={orderData?.agencyList}
                        />
                        <div className="DeleteBtnWrap">
                            <div
                                className="ReviveBtn FlexCenter"
                                onClick={() => {
                                    OnRecoverClk(7);
                                }}
                            >
                                복원하기
                            </div>
                            <div
                                className="DeleteBtn FlexCenter"
                                onClick={() => {
                                    OnDeleteClk(8);
                                }}
                            >
                                삭제하기
                            </div>
                        </div>
                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={page}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={orderData?.count || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                    </div>
                )}

                {step === 5 && (
                    <div>
                        {" "}
                        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                            [{asInfoData.count || 0}건]
                        </p>
                        <TableASManagement
                            isDelete={true}
                            List={asInfoData?.rows}
                            values={asInfoValues}
                            setValues={setAsInfoValues}
                        />
                        <div className="DeleteBtnWrap">
                            <div
                                className="ReviveBtn FlexCenter"
                                onClick={() => {
                                    OnRecoverClk(9);
                                }}
                            >
                                복원하기
                            </div>
                            <div
                                className="DeleteBtn FlexCenter"
                                onClick={() => {
                                    OnDeleteClk(10);
                                }}
                            >
                                삭제하기
                            </div>
                        </div>
                        <div className="PaginationContent">
                            <Pagination
                                className="my-pagination"
                                // 현제 보고있는 페이지
                                activePage={page}
                                // 한페이지에 출력할 아이템수
                                itemsCountPerPage={20}
                                // 총 아이템수
                                totalItemsCount={asInfoData?.count || 0}
                                // 표시할 페이지수
                                pageRangeDisplayed={10}
                                // 함수
                                onChange={handlePageChange}
                                nextPageText=""
                                prevPageText=""
                                firstPageText=""
                                lastPageText=""
                            ></Pagination>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default DeleteList;
