import { useNavigate } from "react-router-dom";

import routes from "../../libs/routes";
import { useGetData } from "../../utils/service";
import ApiData from "../../libs/api";
import useLoadingStore from "../../zustand/Loading";
import { useEffect } from "react";
const dummyData = [
    {
        date: "2021-09-01",
        model: "model1",
        reason: "도배",
    },
];

function FAQManagement() {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetData(ApiData.FaQList);
    // const { loadingStart, loadingEnd } = useLoadingStore();

    // useEffect(() => {
    //     isLoading ? loadingStart() : loadingEnd();
    // }, [isLoading]);

    return (
        <>
            <div className="content ">
                <div className="title_container">
                    <p className="title">FAQ관리</p>
                    <button
                        className="btn btn9"
                        onClick={() => {
                            navigate(routes.managementDetail1, {});
                        }}
                    >
                        등록하기
                    </button>
                </div>
                <div className="table_scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>no</th>
                                <th>질문</th>
                                <th>조회수</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data?.map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td
                                            style={{
                                                cursor: "pointer",
                                                color: "#007bff",
                                            }}
                                            onClick={() => {
                                                navigate(
                                                    `${routes.managementDetail1}?index=${x.idx}`
                                                );
                                            }}
                                        >
                                            {x.title}
                                        </td>
                                        <td>{x.view_count}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default FAQManagement;
