import { useEffect, useState } from "react";
import InputDate from "../../components/InputDate";
import Input from "../../components/Input";
import { InputCheckBox } from "../../components/InputCheckBox";
import { ReqConsultTable } from "../../components/Tables";
import Pagination from "react-js-pagination";
import { usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import consts from "../../libs/consts";
import images from "../../libs/images";
import XLSX from "xlsx-js-style";
import moment from "moment";
import { PopupComp } from "../../components/PopUp";
import { toast } from "react-toastify";
import useLoadingStore from "../../zustand/Loading";

function ReqForConsult() {
    const [page, setPage] = useState(1);
    const [sdate, setsdate] = useState("");
    const [edate, setedate] = useState("");
    const [reqConsultData, setReqConsultData] = useState([]);
    const [requestChangeData, setRequestChangeData] = useState([]);

    const [searchType, setSearchType] = useState([]);
    const [phoneNum, setPhoneNum] = useState("");

    const [deletePop, setDeletePop] = useState(false);
    const [deleteIdx, setDeleteIdx] = useState(0);

    // const { loadingStart, loadingEnd } = useLoadingStore();
    //api 호출
    const signCounseling = usePostData(ApiData.signCounseling);
    const changeStateContent = usePostData(ApiData.signCounselingUpdate);
    const counselingExcel = usePostData(ApiData.counselingExcel);
    const counselingDelete = usePostData(ApiData.counselingDelete);
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };
    const handleReset = () => {
        setsdate("");
        setedate("");
        setPhoneNum("");
        setSearchType([]);
        dataList(true);
    };

    useEffect(() => {
        dataList();
        // console.log("!!!!!");
    }, [page]);
    //상태변경 api
    const changeState = (type) => {
        let sender = {
            counselingArr: requestChangeData?.map((c) => parseInt(c)),
            counseling_type: type,
        };
        changeStateContent
            .mutateAsync(sender)
            .then(({ data }) => {
                dataList();
                setRequestChangeData([]);
            })
            .catch((e) => {
                // console.log(e.response.data);
            });

        // console.log("sender", sender);
    };
    //데이터 불러오기 api

    const dataList = (reset = false) => {
        let sender = {
            page: page - 1,
            start_date: sdate,
            end_date: edate,
            searchVal: phoneNum,
            searchType: searchType.map((x) => parseInt(x)),
        };
        if (reset === true) {
            sender = {
                page: 0,
                start_date: "",
                end_date: "",
                searchVal: "",
                searchType: [],
            };
        }
        // loadingStart();
        signCounseling
            .mutateAsync(sender)
            .then(({ data }) => {
                // console.log("data", data);
                setReqConsultData(data);
                // loadingEnd();
            })
            .catch((e) => {
                // loadingEnd();
                toast.error(e.response.data);
                // console.log(e.response.data);
            });
    };

    //excel 다운 api
    const excelDown = async () => {
        try {
            // 서버에서 직원 리스트 가져오기
            let excel = await counselingExcel
                .mutateAsync({})
                .then(({ data }) => {
                    return data;
                })
                .catch((e) => {
                    // console.log(e.response.data);
                });
            const excelData = excel;
            // console.log(excelData);

            // Excel 파일 생성 및 다운로드
            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: {
                    bold: true,
                    color: { rgb: "000000" },
                    name: "함초롱바탕",
                    sz: 13,
                },
                fill: { fgColor: { rgb: "f1faee" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };
            const dataStyle = {
                font: { color: { rgb: "000000" }, name: "함초롱바탕", sz: 11 },
                fill: { fgColor: { rgb: "ffffff" } },
                alignment: { horizontal: "center", vertical: "center" },
                border: {
                    left: { style: "thin", color: { auto: 1 } },
                    right: { style: "thin", color: { auto: 1 } },
                    top: { style: "thin", color: { auto: 1 } },
                    bottom: { style: "thin", color: { auto: 1 } },
                },
            };

            // 열의 폭을 정의
            const colWidths = [80, 80, 120, 120, 80, 80, 130];

            // cols 속성을 사용하여 각 열의 폭을 조절
            const cols = colWidths.map((width) => ({ wpx: width }));

            const headerRow = [
                { v: "상태", t: "s", s: headerStyle },
                { v: "시공", t: "s", s: headerStyle },
                { v: "연락처", t: "s", s: headerStyle },
                { v: "희망일", t: "s", s: headerStyle },
                { v: "면적", t: "s", s: headerStyle },
                { v: "자재", t: "s", s: headerStyle },
                { v: "등록일시", t: "s", s: headerStyle },
            ];

            const dataRows = excelData?.rows.map((emp) => [
                {
                    v:
                        emp.counseling_type === 1
                            ? "확인"
                            : emp.counseling_type === 2
                            ? "미확인"
                            : "부재중",
                    t: "s",
                    s: dataStyle,
                }, // 사원번호
                {
                    v: emp.sigong_type === 1 ? "도배" : "장판",
                    t: "s",
                    s: dataStyle,
                }, // 사원명
                {
                    v: emp.mb_phone.replace(
                        /(\d{3})(\d{4})(\d{4})/,
                        "$1-$2-$3"
                    ),
                    t: "s",
                    s: dataStyle,
                }, // 현황
                {
                    v: `${emp.sigong_date}(${
                        consts.dayText[moment(emp.sigong_date).day()]
                    })`,
                    t: "s",
                    s: dataStyle,
                }, // 직급
                {
                    v: `${emp.area_pyeong}(${
                        emp.area === 1 ? "공급" : "전용"
                    })`,
                    t: "s",
                    s: dataStyle,
                }, // 전화번호
                {
                    v: `${
                        emp.dobae_product === 1
                            ? "실크"
                            : emp.dobae_product === 2
                            ? "합지"
                            : "혼합"
                    }`,
                    t: "s",
                    s: dataStyle,
                }, // 전화번호
                {
                    v: `${moment(emp.create_dt).format("YYYY.MM.DD HH:mm")}`,
                    t: "s",
                    s: dataStyle,
                }, // 전화번호
            ]);

            const rows = [headerRow, ...dataRows];

            // 새로운 Sheet 객체 생성
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws["!cols"] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, "상담신청");

            // 파일 다운로드
            XLSX.writeFile(wb, "ConsultRequest.xlsx");

            console.log("Excel 파일 생성 및 다운로드 완료");
        } catch (error) {
            console.error("Error occurred while downloading Excel", error);
        }
    };
    //상담신청 삭제 api
    const deleteData = () => {
        let sender = {
            counselingIdx: deleteIdx,
        };
        counselingDelete
            .mutateAsync(sender)
            .then(({ data }) => {
                dataList();
                setDeletePop(false);
            })
            .catch((e) => {
                // console.log(e.response.data);
            });
    };

    return (
        <>
            <PopupComp
                popTitle="삭제"
                popContent="삭제하시겠습니까?"
                OpenPop={deletePop}
                setOpenPop={setDeletePop}
                SubmitClk={deleteData}
            />
            <div className="content ">
                <p className="title">상담신청</p>
                <div className="search_box">
                    <div className="search_box_wrap">
                        <div className="search_form">
                            <InputDate
                                type="text"
                                placeholder="시작날짜"
                                placeholderEnd="종료날짜"
                                name="ipd1"
                                label="등록일"
                                dateValue={sdate}
                                setDateValue={setsdate}
                                dateValueEnd={edate}
                                setDateValueEnd={setedate}
                                multiple={true}
                            />
                        </div>
                        <div className="search_form">
                            <Input
                                className="input_text"
                                type="text"
                                placeholder="연락처"
                                name="stx"
                                value={phoneNum}
                                setValue={setPhoneNum}
                                label="검색"
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        dataList();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="search_box_wrap">
                        <InputCheckBox
                            title="상태"
                            Chkboxes={consts.Confirm}
                            setValue={setSearchType}
                            values={searchType}
                        />
                    </div>
                    <div className="search_box_wrap_center">
                        <div className="btn_wrap">
                            <button onClick={handleReset} className="btn btn8">
                                초기화
                            </button>
                            <button onClick={dataList} className="btn btn9">
                                검색
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <p style={{ fontSize: "18px", marginBottom: "20px" }}>
                            [오늘 : {reqConsultData?.today}건, 검색결과 :
                            {reqConsultData?.count}건]
                        </p>

                        <ReqConsultTable
                            Data={reqConsultData?.rows}
                            values={requestChangeData}
                            setValues={setRequestChangeData}
                            setDeletePop={setDeletePop}
                            deletePop={deletePop}
                            setDeleteIdx={setDeleteIdx}
                        />
                        <div className="BottomContent">
                            <div className="ButtonContent">
                                <button
                                    style={{ background: "#f00" }}
                                    onClick={() => changeState(2)}
                                >
                                    미확인으로 변경
                                </button>
                                <button
                                    style={{ background: "#5C96ED" }}
                                    onClick={() => changeState(3)}
                                >
                                    부재중으로 변경
                                </button>
                                <button
                                    style={{ background: "#CCC" }}
                                    onClick={() => changeState(1)}
                                >
                                    확인으로 변경
                                </button>
                            </div>
                            <div className="PaginationContent">
                                <Pagination
                                    className="my-pagination"
                                    // 현제 보고있는 페이지
                                    activePage={page}
                                    // 한페이지에 출력할 아이템수
                                    itemsCountPerPage={20}
                                    // 총 아이템수
                                    totalItemsCount={reqConsultData?.count || 0}
                                    // 표시할 페이지수
                                    pageRangeDisplayed={10}
                                    // 함수
                                    onChange={handlePageChange}
                                    nextPageText=""
                                    prevPageText=""
                                    firstPageText=""
                                    lastPageText=""
                                ></Pagination>
                            </div>
                            <div
                                className="excelContent"
                                onClick={() => excelDown()}
                            >
                                <img src={images.excel} alt="excel" />
                                <p>엑셀다운로드</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReqForConsult;

//상담신청..
