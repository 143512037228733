import { useNavigate } from "react-router-dom";
import routes from "../../libs/routes";
import { useGetData } from "../../utils/service";
import ApiData from "../../libs/api";
import { useEffect, useState } from "react";
import useLoadingStore from "../../zustand/Loading";

function WInventManagement() {
    const {
        data: workerListApi,
        isloading,
        error,
    } = useGetData(ApiData.workerMateList);
    const navigate = useNavigate();
    const [dataList, setDataList] = useState([]);
    // const { loadingStart, loadingEnd } = useLoadingStore();

    useEffect(() => {
        // isloading ? loadingStart() : loadingEnd();
        setDataList(workerListApi?.data);
    }, [workerListApi]);
    return (
        <>
            <div className="content ">
                <p className="title">작업자재고관리</p>
                <div style={{ marginTop: "20px" }} className="table_scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>작업자</th>
                                <th>미회수</th>
                                <th>재고현황</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataList?.map((x, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{x.name}</td>
                                        <td>{x.count}</td>
                                        <td>
                                            <div
                                                className="TableBtns FlexCenter "
                                                style={{ gap: "10px" }}
                                            >
                                                <button
                                                    style={{ width: "100px" }}
                                                    onClick={() => {
                                                        navigate(
                                                            `${routes.material2_1}?index=${x.idx}`,
                                                            {
                                                                state: {
                                                                    data: x.name,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                >
                                                    재고관리
                                                </button>

                                                <button
                                                    onClick={() => {
                                                        navigate(
                                                            `${routes.material2_2}?index=${x.idx}`,
                                                            {
                                                                state: {
                                                                    data: x.name,
                                                                },
                                                            }
                                                        );
                                                    }}
                                                    style={{ width: "100px" }}
                                                >
                                                    로그조회
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td>합계</td>
                                <td>
                                    {dataList
                                        ? dataList
                                              .map((c) => c.count)
                                              .reduce((prev, curr) => {
                                                  return prev + curr;
                                              }, 0)
                                        : 0}
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default WInventManagement;
