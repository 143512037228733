import { BoxSendContent, BoxTextInput } from "../../components/BoxInfo";
import { useGetData, usePostData } from "../../utils/service";
import ApiData from "../../libs/api";
import consts from "../../libs/consts";
import { useEffect, useState } from "react";
import InputFile from "../../components/InputFile";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import useLoadingStore from "../../zustand/Loading";

function CharacterTemplate() {
    const mailReg = usePostData(ApiData.mailTempReg);
    const mailDel = usePostData(ApiData.mailTempDelete);
    const queryClient = useQueryClient();
    const { data, isLoading, isError } = useGetData(ApiData.mailTempList);
    const [templateName, setTemplateName] = useState("");
    const [textareaContent, setTextareaContent] = useState("");
    const [tempList, setTempList] = useState([]);
    const [imgUrl, setImgUrl] = useState("");
    const [imgFileType, setImgFileType] = useState("");
    const [idx, setIdx] = useState(0);
    // const { loadingStart, loadingEnd } = useLoadingStore();

    const DeleteImg = () => {
        setImgUrl("");
        setImgFileType("");
    };
    useEffect(() => {
        // isLoading ? loadingStart() : loadingEnd();
        setTempList(data?.data);
        //console.log(data?.data);
    }, [data]);

    const TemplateClk = (x) => {
        setTemplateName(x.title);
        setTextareaContent(x.content);
        setImgUrl(x.img_url);
        setIdx(x.idx);
        setImgFileType("");
    };
    const SubmitClk = (index) => {
        let sender = {
            title: templateName,
            content: textareaContent,
            temImg: imgUrl,
        };
        if (index) {
            sender.temIdx = idx;
        }

        if (imgUrl) {
            if (imgUrl.includes("template")) {
                sender.imgType = "";
            } else {
                sender.imgType = 1;
                sender.fileType = imgFileType.split("/")[1];
            }
        } else {
            //console.log(333);
        }

        //console.log("sender", sender);
        if (templateName === "") {
            toast.error("템플릿명을 입력해주세요.");
            return;
        } else if (textareaContent === "") {
            toast.error("내용을 입력해주세요.");
            return;
        }

        mailReg
            .mutateAsync(sender)
            .then(({ data }) => {
                queryClient.invalidateQueries(ApiData.mailTempList);
                setTemplateName("");
                setTextareaContent("");
                setImgUrl("");
                setImgFileType("");
            })
            .catch((e) => {
                //console.log(e);
                toast.error(e.response.data);
            });
    };
    // useEffect(() => {
    //     //console.log("imgFileType", imgFileType);
    // }, [imgFileType]);
    const deleteClk = (idx) => {
        if (idx) {
            mailDel
                .mutateAsync({ temIdx: idx })
                .then(({ data }) => {
                    queryClient.invalidateQueries(ApiData.mailTempList);
                    // console.log("성공..");
                })
                .catch((e) => {
                    // console.log(e);
                    toast.error(e.response.data);
                });
        } else {
            toast.error("삭제할 템플릿을 선택해주세요.");
        }
    };
    return (
        <>
            <div className="content ">
                <p className="title">문자템플릿</p>
                <div className="TemplateWrap">
                    <div style={{ width: "49%" }}>
                        <div className="BorderBox">
                            <div className="title">
                                <h1>템플릿</h1>
                            </div>
                            <div className="contentWrap">
                                <BoxTextInput
                                    title="템플릿명"
                                    value={templateName}
                                    setValue={setTemplateName}
                                />
                                <BoxSendContent
                                    value={textareaContent}
                                    setValue={setTextareaContent}
                                />
                                <div className="ImgInputWrap">
                                    <div className="Imgtitle">이미지</div>
                                    <div className="sub">
                                        <InputFile
                                            type="file"
                                            name={"img_url"}
                                            value={
                                                imgUrl &&
                                                (imgUrl.includes("template")
                                                    ? `${consts.ServerImgFolder}${imgUrl}`
                                                    : `${imgUrl}`)
                                            }
                                            alt={"Template"}
                                            onChange={(idx, name, result) => {
                                                setImgUrl(result);
                                            }}
                                            valid="image"
                                            // style={{ flex: 1.68 }}
                                            imgStyle={{
                                                width: "40%",
                                            }}
                                            setFileType={(type) => {
                                                setImgFileType(type);
                                            }}
                                        />
                                        <button
                                            type="button"
                                            style={{
                                                fontSize: "12px",
                                                position: "absolute",
                                                bottom: "10px",
                                                right: "10px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => DeleteImg()}
                                        >
                                            삭제하기
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="BtnEnd">
                                <button
                                    className="btn btn9"
                                    onClick={() => SubmitClk()}
                                >
                                    등록하기
                                </button>
                            </div>
                        </div>

                        <div className="twoBtn_wrap">
                            <button
                                className="btn btn8"
                                onClick={() => deleteClk(idx)}
                            >
                                삭제하기
                            </button>
                            <button
                                className="btn btn9"
                                onClick={() => SubmitClk(idx)}
                            >
                                저장하기
                            </button>
                        </div>
                    </div>
                    <div
                        style={{ width: "49%", paddingTop: "20px" }}
                        className="table_scroll"
                    >
                        <table>
                            <thead>
                                <tr>
                                    <th>템플릿명</th>
                                    <th>내용</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tempList?.map((x, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{x.title}</td>
                                            <td
                                                style={{
                                                    cursor: "pointer",
                                                    color: "#007bff",
                                                }}
                                                onClick={() => TemplateClk(x)}
                                            >
                                                {x.content}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CharacterTemplate;
